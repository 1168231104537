import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import Dropdown from "../forms/Dropdown.react";

const assigneeStyle = {
	width: "calc(100% - 16px)",
	maxWidth: "300px"
};

function get_zendesk_users(client) {
	var integration = find_integration(client);
	var clientAgents = [];

	if (integration.id !== undefined) {
		for (var i = 0; i < integration.clientAgents.length; i++) {
			clientAgents.push({
				text: integration.clientAgents[i].isGroup
					? integration.clientAgents[i].name
					: integration.clientAgents[i].default_group_name + "/" + integration.clientAgents[i].name,
				value: integration.clientAgents[i].isGroup
					? integration.clientAgents[i].id
					: integration.clientAgents[i].default_group_id + "/" + integration.clientAgents[i].id
			});
		}
	}

	return clientAgents;
}

function find_integration(client, ticket) {
	if (client.integrations !== undefined) {
		for (var i = 0; i < client.integrations.length; i++) {
			if (client.integrations[i].type === "zendesk") return client.integrations[i];
		}
	}

	return {};
}

class ReassignCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = {
			clientAgents: get_zendesk_users(this.props.client),
			value: this.props.item.value || "",
			nextPath: 0
		};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const rowStyle = {
			width: "100%",
			textAlign: "center",
			padding: "16px 0px",
			fontSize: this.props.agentMode ? "40px" : "inherit"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
				nextPath={this.state.nextPath}
			>
				<div style={rowStyle}>
					{this.props.agentMode ? (
						<div>Reassign Ticket</div>
					) : (
						<Dropdown
							id={"assignee-dropdown-" + this.props.item.id}
							field="value"
							label="Assignee"
							value={this.state.value}
							items={this.state.clientAgents}
							location={[this.props.item.id]}
							updateFn={this._updateFn}
							style={assigneeStyle}
						/>
					)}
				</div>
			</Card>
		);
	}
}

export default ReassignCard;
