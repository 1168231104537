import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SmallLoading from "../_common/SmallLoading.react";

// Summary parts
import SummaryRow from "../_common/summary/SummaryRow.react";
import Name from "../_common/summary/Name.react";
import PictureBadge from "../_common/summary/PictureBadge.react";
import Active from "../_common/summary/Active.react";
import Statistic from "../_common/summary/Statistic.react";

// Headers
import H2 from "../_common/headers/H2.react";

// Form items
import Button from "../_common/buttons/Button.react";
import FAB from "../_common/buttons/FAB.react";
import Dropdown from "../_common/forms/Dropdown.react";

import * as list from "../../functions/list.js";
import * as time from "../../functions/time.js";
import url_creator from "../../functions/url_creator.js";

class Row extends React.Component {
	render() {
		const loggedInStyle = {
			width: "10px",
			height: "10px",
			borderRadius: "50%",
			display: "inline-block",
			verticalAlign: "middle",
			backgroundColor: this.props.item.loggedIn ? "green" : "lightgrey",
			boxShadow: this.props.item.loggedIn ? "rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px" : "",
			marginRight: "16px",
			transition: "all .3s ease"
		};

		var eventsN = 0;
		var eventsDuration = 0;
		var escalated = 0;

		var today = new Date();

		if (
			this.props.item.analytics !== undefined &&
			this.props.item.analytics.id === today.getUTCMonth() + 1 + "." + today.getUTCFullYear() &&
			this.props.item.analytics.days[today.getUTCDate()] !== undefined
		) {
			eventsN = this.props.item.analytics.days[today.getUTCDate()].n;
			eventsDuration = this.props.item.analytics.days[today.getUTCDate()].duration;
			escalated = this.props.item.analytics.days[today.getUTCDate()].escalations;
		}

		return (
			<SummaryRow href={url_creator("gurus", this.props.item.id)}>
				<div style={loggedInStyle} />
				<PictureBadge item={this.props.item} />
				<Name item={this.props.item} />
				<Active item={this.props.item} />
				<Statistic text={eventsN} label="Events today" width="120px" />
				<Statistic text={escalated} label="Escalations" width="120px" />
				<Statistic
					text={time.parse_diff(eventsDuration / (1000 * (eventsN || 1)))}
					label="Average Event Time"
					width="120px"
				/>
			</SummaryRow>
		);
	}
}

class AgentsSummary extends React.Component {
	constructor(props) {
		super(props);
		this._onNewAgent = this._onNewAgent.bind(this);
		this._onOrder = this._onOrder.bind(this);
		this.state = {
			order: "name",
			summary: list.order(this.props.summary, "name")
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ summary: list.order(nextProps.summary, this.state.order) });
	}

	_onNewAgent() {
		UIActions.showOverlay("new guru");
	}

	_onOrder(field, location, value) {
		this.setState({ order: value, summary: list.order(this.props.summary, value) });
	}

	render() {
		const vertAlignStyle = {
			verticalAlign: "middle",
			display: "inline-block",
			width: "0px",
			height: "70vh"
		};

		const emptyStyle = {
			textAlign: "center",
			display: "inline-block",
			verticalAlign: "middle"
		};

		const emptyTextStyle = {
			fontSize: "40px",
			fontWeight: "400",
			marginBottom: "20px"
		};

		// Order options
		const orderStyle = {
			width: "250px"
		};

		const orderOptions = [
			{
				text: "Name",
				value: "name"
			},
			{
				text: "Email",
				value: "email"
			},
			{
				text: "Signed in",
				value: "signed in"
			},
			{
				text: "Latest login",
				value: "last login"
			},
			{
				text: "Date Created (newest first)",
				value: "created newest"
			},
			{
				text: "Date Created (oldest first)",
				value: "created oldest"
			},
			{
				text: "Last updated (newest first)",
				value: "updated newest"
			},
			{
				text: "Last updated (oldest first)",
				value: "updated oldest"
			}
		];

		var toRender = [];
		if (this.props.status.indexOf("getting summary") > -1) {
			toRender.push(<SmallLoading key="loading" />);
		} else if (this.state.summary.length === 0) {
			toRender.push(
				<div key="empty-clients" style={emptyStyle}>
					<div style={emptyTextStyle}>There are currently no gurus</div>
					<Button text="Add New Agent" onClick={this._onNewAgent} />
				</div>
			);
			toRender.push(<div key="vertical-aligner-clients" style={vertAlignStyle} />);
		} else {
			toRender.push(
				<H2 key="agent-header" text="Our gurus" textAlign="left" margin="24px" width="calc(100% - 300px)" />
			);
			toRender.push(
				<Dropdown
					key="order"
					id={"order"}
					label="Order"
					value={this.state.order}
					items={orderOptions}
					field="summary"
					updateFn={this._onOrder}
					style={orderStyle}
				/>
			);
			this.state.summary.map((item, index, arr) => {
				toRender.push(<Row key={"agent-" + index} item={item} />);
				return true;
			});

			toRender.push(<FAB key="fab-clients" onClick={this._onNewAgent} />);
		}

		return (
			<OuterScaffold spaceForHeader={true}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top">
					{toRender}
				</Container>
			</OuterScaffold>
		);
	}
}

export default AgentsSummary;
