import React from "react";

import Ink from "react-ink";
import MDSpinner from "react-md-spinner";

// Functions
import m from "../../../functions/m";

class Button extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	componentDidMount() {
		this.setState({ width: this.button.clientWidth - 32 + "px" });
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	render() {
		const loading = this.props.loading || false;

		const primaryColor = this.props.dark ? "rgba(0, 0, 0, 0.87)" : "white";
		const invertColor = this.props.dark ? "white" : "rgba(0, 0, 0, 0.87)";
		const backgroundColor = this.props.dark ? "rgba(255, 255, 255, 0.62)" : "rgba(0, 0, 0, 0.62)";

		const primaryStyle = {
			display: "inline-block",
			padding: "11px 16px",
			verticalAlign: "middle",
			borderRadius: "2px",
			fontSize: "14px",
			cursor: "pointer",
			width: loading ? this.state.width : "auto",
			backgroundColor: this.state.hover || loading || this.props.type === "flat" ? "transparent" : backgroundColor,
			color: this.state.hover || loading || this.props.type === "flat" ? invertColor : primaryColor,
			border: (this.state.hover || loading) && this.props.type !== "flat" ? "1px solid black" : "1px solid transparent",
			transition: ".2s all ease",
			position: "relative",
			boxShadow:
				this.props.type === "flat"
					? ""
					: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
			textAlign: "center",
			fontWeight: "400",
			fontFamily: "Roboto"
		};

		return (
			<div
				ref={input => {
					this.button = input;
				}}
				style={m(primaryStyle, this)}
				onClick={this.props.onClick}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				{loading ? <MDSpinner size={14} singleColor="rgb(70, 180, 175)" /> : this.props.text}
				<Ink />
			</div>
		);
	}
}

export default Button;
