import Reflux from "reflux";

var AgentActions = Reflux.createActions([
    "loadSummary",
    "loadSummaryCompleted",
    "loadSummaryFailed",
    "new",
    "newCompleted",
    "newFailed",
    "load",
    "loadCompleted",
    "loadFailed",
    "loadSubcollection",
    "loadTimechart",
    "loadTimechartCompleted",
    "save",
    "saveCompleted",
    "saveFailed",
    "delete",
    "deleteCompleted",
    "deleteFailed",
    "getSingle",
    "add",
    "remove",
    "setField",
    "upload",
    "uploadCompleted",
    "uploadFailed",
    "toggleActive",
    "toggleActiveCompleted",
    "toggleActiveFailed",
    "fieldCheckCompleted",
    "fieldCheckFailed",
    "roleChange",
    "roleChangeCompleted",
    "roleChangeFailed",
    "phoneChange",
    "addClient",
    "removeClient",
    "updatePermissions",
    "runAnalytics",
    "runAnalyticsCompleted",
    "groupChange",
    "unsubscribe",
    "close",
    "update",
    "get"
]);

export default AgentActions;
