import React from "react";

// Our actions
import WorkflowActions from "../../actions/WorkflowActions";

// Scaffolding
import SettingsScaffold from "../_common/SettingsScaffold.react";
import H2 from "../_common/headers/H2.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import ToggleWithLabel from "../_common/forms/ToggleWithLabel.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Workflow from "./AgentWorkflow.react";

const tierOptions = [
	{
		text: "Tier 1: Basic",
		value: 1
	},
	{
		text: "Tier 2: Moderate",
		value: 2
	},
	{
		text: "Tier 3: Advanced",
		value: 3
	}
];

const basicPrimaryStyle = {
	width: "600px",
	maxWidth: "100%",
	display: "inline-block",
	textAlign: "left",
	padding: "16px 0px"
};

const tierStyle = {
	width: "300px",
	marginRight: "300px"
};

class Basic extends React.Component {
	_onUpdateFn(field, location, value) {
		WorkflowActions.setField(field, location, value, true);
	}

	_onLoadVersion(field, location, value) {
		WorkflowActions.loadVersion(value);
	}

	render() {
		var versionOptions = [];
		for (var i = 0; i < (this.props.item.maxVersion || 1); i++) {
			versionOptions.push({ text: "Version " + (i + 1), value: i + 1 });
		}

		return (
			<div style={basicPrimaryStyle}>
				<H2 text={"Workflow Description"} />
				<InputMultiline
					field="description"
					value={this.props.item.description || ""}
					onBlur={this._onUpdateFn}
					label="Description"
				/>
				<H2 text={"Workflow Tier"} />
				<Dropdown
					id="workflow-settings-tier"
					field="tier"
					value={this.props.item.tier}
					updateFn={this._onUpdateFn}
					label={"Tier"}
					items={tierOptions}
					style={tierStyle}
				/>
				<H2 text={"Modes"} />
				<ToggleWithLabel
					id="workflow-settings-tickets-mode"
					field="forTickets"
					value={this.props.item.forTickets || false}
					updateFn={this._onUpdateFn}
					text={"Works with tickets"}
					style={tierStyle}
				/>
				<br /> <br />
				<ToggleWithLabel
					id="workflow-settings-ticketless-mode"
					field="forTicketless"
					value={this.props.item.forTicketless || false}
					updateFn={this._onUpdateFn}
					text={"Works with ticketless"}
					style={tierStyle}
				/>
				<br /> <br />
				<H2 text={"Versions"} />
				<Dropdown
					id="workflow-current-version"
					field="currentVersion"
					value={this.props.item.currentVersion}
					updateFn={this._onUpdateFn}
					label={"Active version"}
					items={versionOptions}
					style={tierStyle}
					helpText="This is the version agents get"
				/>
				<Dropdown
					id="workflow-editing-version"
					field="editingVersion"
					value={this.props.item.editingVersion}
					updateFn={this._onLoadVersion}
					label={"Editing Version"}
					items={versionOptions}
					style={tierStyle}
					helpText="This is the version you are currently editing"
				/>
			</div>
		);
	}
}

const menuOptions = [
	{
		text: "Basic",
		value: "basic"
	}
];

class WorkflowSettings extends React.Component {
	constructor(props) {
		super(props);
		this._onSectionChange = this._onSectionChange.bind(this);
		this.state = { section: "basic" };
	}

	_onSectionChange(section) {
		this.setState({ section });
	}

	render() {
		var toRender = [];
		if (this.state.section === "basic") toRender = <Basic item={this.props.item} />;

		return (
			<SettingsScaffold
				menuOptions={menuOptions}
				section={this.state.section}
				onSectionChange={this._onSectionChange}
				actions={WorkflowActions}
				save={false}
			>
				{toRender}
			</SettingsScaffold>
		);
	}
}

export default WorkflowSettings;
