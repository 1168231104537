import React from "react";
import shortid from "shortid";

// Actions
import ClientActions from "../../../actions/ClientActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import Container from "../../_common/Container.react";
import OuterScaffold from "../../_common/OuterScaffold.react";
import SectionCard from "../../_common/SectionCard.react";
import Search from "../../_common/Search.react";
import ExternalUsersResult from "../../_common/search/ExternalUsersResult.react";

// Headers
import H2 from "../../_common/headers/H2.react";

// Form items
import Input from "../../_common/forms/Input.react";
import Button from "../../_common/buttons/Button.react";
import Tokenizer from "../../_common/forms/Tokenizer.react";

// Zendesk
import Filter from "./Filter.react";
import Channels from "./Channels.react";
import ClientAgentRow from "./ClientAgentRow.react";
import Signature from "./Signature.react";

// Functions
import * as objects from "../../../functions/objects";
import * as searchHelper from "../../../functions/search";

const textStyle = {
	width: "600px"
};

const filterContainerStyle = {
	width: "calc(100% - 16px)",
	padding: "0px 0px 0px 16px"
};

const filterButtonContainerStyle = {
	margin: "16px 0px",
	width: "100%",
	textAlign: "center"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const emptyAgentsTextStyle = {
	fontSize: "24px",
	fontWeight: "400"
};

const searchStyle = {
	width: "calc(100% -  32px)"
};

const agentsContainerStyle = {
	width: "100%",
	margin: "32px 0px"
};

const webhookTargetStyle = {
	display: "inline-block",
	padding: "16px 32px",
	backgroundColor: "lightgoldenrodyellow",
	borderRadius: "50px",
	margin: "0px 16px 16px"
};

const integrationIdStyle = {
	display: "inline-block",
	marginTop: "32px",
	padding: "0px 0px",
	width: "100%",
	textAlign: "left",
	color: "grey",
	fontSize: "14px"
};

class APIConnector extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);

		var state = {};

		for (var i = 0; i < this.props.scaffold.items.length; i++) {
			if (this.props.integration[this.props.scaffold.items[i].field] !== undefined)
				state[this.props.scaffold.items[i].field] = this.props.integration[this.props.scaffold.items[i].field];
		}

		this.state = state;
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		ClientActions.setField(field, location, value, true);
	}

	render() {
		return (
			<div>
				{this.props.scaffold.items.map((item, index) => {
					if (item.type === "text") {
						return (
							<Input
								key={"integration-" + this.props.integration.id + "-" + item.field}
								id={"integration-" + this.props.integration.id + "-" + item.field}
								style={textStyle}
								value={this.state[item.field] || ""}
								field={item.field}
								location={[this.props.integration.id]}
								label={item.label}
								onBlur={this._updateFn}
								validation={item.validation}
								onReturn={this._onEmailReturn}
							/>
						);
					} else if (item.type === "webhook url") {
						return (
							<div>
								<H2 text="Webhook Target" margin="16px 16px" />
								<div style={webhookTargetStyle}>
									{document.location.origin.replace("https://", "https://api.").replace("app.", "")}
									/webhook/tickets/
									{this.props.integration.type}/{this.props.client.id}/{this.props.integration.id}
								</div>
							</div>
						);
					} else return "";
				})}
			</div>
		);
	}
}

class Filters extends React.Component {
	constructor(props) {
		super(props);
		this._onAddNewOrder = this._onAddNewOrder.bind(this);
	}

	_onAddNewOrder() {
		ClientActions.add("filters", [this.props.integration.id], {
			rules: [{ type: "assignee name", state: "is", value: "Hioperator" }]
		});

		UIActions.update();
	}

	render() {
		// Sets filters
		var filters = Array.isArray(this.props.integration.filters) ? this.props.integration.filters : [];

		return (
			<div style={filterContainerStyle}>
				{filters.map((item, index, arr) => (
					<Filter
						key={this.props.integration.id + "-" + index}
						item={item}
						index={index}
						last={index === arr.length - 1}
						location={[this.props.integration.id]}
						type={this.props.integration.type}
					/>
				))}
				{filters.length === 0 ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>There are currently no ticket filters</div>
						<Button text="Add Order" onClick={this._onAddNewOrder} />
					</div>
				) : (
					<div style={filterButtonContainerStyle}>
						<Button text="Add OR Clause" onClick={this._onAddNewOrder} />
					</div>
				)}
			</div>
		);
	}
}

class AutoWorkflows extends React.Component {
	constructor(props) {
		super(props);
		this._onAddNewAutoWorkflow = this._onAddNewAutoWorkflow.bind(this);
	}

	_onAddNewAutoWorkflow() {
		ClientActions.add("autoworkflows", [this.props.integration.id], {
			rules: [{ type: "tag", state: "is", value: "hio" }]
		});
	}

	render() {
		var autoworkflows = Array.isArray(this.props.integration.autoworkflows) ? this.props.integration.autoworkflows : [];
		var workflowOptions = [];
		if (this.props.workflows !== undefined) {
			for (var i = 0; i < this.props.workflows.length; i++)
				workflowOptions.push({ text: this.props.workflows[i].name, value: this.props.workflows[i].id });
		}

		return (
			<div style={filterContainerStyle}>
				{autoworkflows.map((item, index, arr) => (
					<Filter
						key={this.props.integration.id + "-" + index}
						item={item}
						index={index}
						last={index === arr.length - 1}
						location={[this.props.integration.id]}
						workflows={workflowOptions}
						type={this.props.integration.type}
					/>
				))}
				{autoworkflows.length === 0 ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>There are currently no automatic workflow rules</div>
						<Button text="Add New Rule" onClick={this._onAddNewAutoWorkflow} />
					</div>
				) : (
					<div style={filterButtonContainerStyle}>
						<Button text="Add OR Clause" onClick={this._onAddNewAutoWorkflow} />
					</div>
				)}
			</div>
		);
	}
}

class SLAS extends React.Component {
	constructor(props) {
		super(props);
		this._onAddNewSLA = this._onAddNewSLA.bind(this);
	}

	_onAddNewSLA() {
		ClientActions.add("sla", [this.props.integration.id], {
			rules: [{ type: "tag", state: "is", value: "hio" }]
		});
	}

	render() {
		var sla = Array.isArray(this.props.integration.sla) ? this.props.integration.sla : [];

		return (
			<div style={filterContainerStyle}>
				{sla.map((item, index, arr) => (
					<Filter
						key={this.props.integration.id + "-" + index}
						item={item}
						index={index}
						last={index === arr.length - 1}
						location={[this.props.integration.id]}
						sla={true}
						type={this.props.integration.type}
					/>
				))}
				{sla.length === 0 ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>There are currently no SLAs</div>
						<Button text="Add New SLA" onClick={this._onAddNewSLA} />
					</div>
				) : (
					<div style={filterButtonContainerStyle}>
						<Button text="Add OR Clause" onClick={this._onAddNewSLA} />
					</div>
				)}
			</div>
		);
	}
}

class Users extends React.Component {
	constructor(props) {
		super(props);
		this._addUser = this._addUser.bind(this);
	}

	_addUser(obj) {
		if (obj.email.indexOf("hioperator") > -1) obj.hioperatorAgent = true;
		obj.id = obj.userId;
		delete obj.userId;

		ClientActions.add("clientAgents", [this.props.integration.type], obj);
	}

	render() {
		var clientAgents =
			this.props.component !== null && Array.isArray(this.props.component.clientAgents)
				? this.props.component.clientAgents
				: [];

		return (
			<div style={filterContainerStyle}>
				<Search
					placeholder="Search agents/groups to add to reassigning..."
					id={"user-search-" + this.props.integration.id}
					type="external_agents"
					onSelect={this._addUser}
					fields={["name", "email"]}
					style={searchStyle}
					preload={true}
					resultComponent={ExternalUsersResult}
					where={[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }]}
					min={2}
				/>
				{clientAgents.length === 0 ? (
					<div style={emptyStyle}>
						<div style={emptyAgentsTextStyle}>
							There are currently no client agents/groups that tickets can be reassigned to
						</div>
					</div>
				) : (
					<div style={agentsContainerStyle}>
						{clientAgents.map((item, index, arr) => (
							<ClientAgentRow
								key={"client-agents-" + item.id}
								item={item}
								index={index}
								last={index === arr.length - 1}
								location={[this.props.integration.type, "clientAgents", item.id]}
								type={this.props.integration.type}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}

var get_icon = type => {
	if (type === "subject") return "text_format";
	else if (type === "description") return "text_format";
	else if (type === "status") return "done_all";
	else if (type === "tickettype") return "list";
	else if (type === "priority") return "list";
	else if (type === "group") return "supervisor_account";
	else if (type === "assignee") return "perm_identity";
	else if (type === "text") return "text_format";
	else if (type === "tagger") return "label";
	else if (type === "checkbox") return "check_box";
	else if (type === "multiselect") return "toggle_on";
	else if (type === "date") return "calendar_today";
	else if (type === "decimal") return "exposure_plus_1";
	else if (type === "integer") return "exposure_plus_1";
	else if (type === "regexp") return "code";
	return "build";
};

class Rules extends React.Component {
	constructor(props) {
		super(props);
		this._onSelectField = this._onSelectField.bind(this);
		this._onAddRule = this._onAddRule.bind(this);
	}

	_onSelectField(field) {
		ClientActions.get(Client => {
			var obj = objects.find(Client.working, null, [this.props.integration.id, field.id].concat());
			if (obj.obj !== undefined)
				UIActions.showOverlay("rules editor", {
					integrationId: this.props.integration.id,
					rule: obj.obj,
					rules: this.props.integration.rules
				});
		});
	}

	_onAddRule() {
		var newObj = {
			name: "",
			stopper: false,
			active: true,
			conditions: [{ all: [{ type: "assignee name", state: "is", value: "Hioperator" }] }],
			actions: [{ action: "deleted", value: true }]
		};
		ClientActions.add("rules", [this.props.integration.id], newObj);

		UIActions.showOverlay("rules editor", {
			integrationId: this.props.integration.id,
			rule: newObj,
			rules: this.props.integration.rules
		});
	}

	render() {
		var rules = this.props.integration.rules || [];

		return (
			<div>
				{rules.map((item, index) => (
					<SingleRule key={item.id} item={item} index={index} updateFn={this._onSelectField} />
				))}
				<div style={buttonRow}>
					<Button text="Add rule" onClick={this._onAddRule} />
				</div>
			</div>
		);
	}
}

class SingleRule extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.updateFn(this.props.item);
	}

	render() {
		const primaryStyle = {
			borderBottom: "1px solid lightgrey",
			padding: "16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : "transparent",
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		const singleFieldRequiredStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "150px",
			color: this.props.item.stopper ? "grey" : "lightgrey",
			textAlign: "center"
		};

		const singleFieldActiveStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "150px",
			color: this.props.item.active ? "grey" : "lightgrey",
			textAlign: "center"
		};

		return (
			<div
				index={this.props.index}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={primaryStyle}
				onClick={this._onClick}
			>
				<div style={singleRuleTitleStyle}>{this.props.item.name}</div>
				<div style={singleFieldRequiredStyle}>{this.props.item.stopper ? "Stops cascade" : "Does not stop"}</div>
				<div style={singleFieldActiveStyle}>{this.props.item.active ? "Active" : "Inactive"}</div>
				<i style={arrowIconStyle} className="material-icons">
					keyboard_arrow_right
				</i>
			</div>
		);
	}
}

class TicketFields extends React.Component {
	constructor(props) {
		super(props);
		this._onSelectField = this._onSelectField.bind(this);
	}

	_onSelectField(field) {
		ClientActions.get(Client => {
			var obj = objects.find(Client.working, null, [this.props.integration.type, field.id].concat());
			if (obj.obj !== undefined)
				UIActions.showOverlay("ticket field editor", {
					field: obj.obj,
					ticket_fields: this.props.component.ticket_fields
				});
		});
	}

	render() {
		var ticketFields = this.props.component.ticket_fields || [];

		return (
			<div>
				{ticketFields.map((item, index) => (
					<SingleTicketField
						key={item.id}
						item={item}
						index={index}
						icon={get_icon(item.type)}
						updateFn={this._onSelectField}
					/>
				))}
			</div>
		);
	}
}

const singleFieldTitleStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 380px)"
};

const singleRuleTitleStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 324px)"
};

const arrowIconStyle = {
	display: "inline-block",
	verticalAlign: "middle"
};

class SingleTicketField extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.updateFn(this.props.item);
	}

	render() {
		const primaryStyle = {
			borderBottom: "1px solid lightgrey",
			padding: "16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : "transparent",
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		const subsectionIconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 24px 0px 8px",
			color: this.props.item.active ? "rgb(70, 180, 175)" : "grey"
		};

		const singleFieldRequiredStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "150px",
			color: this.props.item.required ? "grey" : "lightgrey",
			textAlign: "center"
		};

		const singleFieldActiveStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "150px",
			color: this.props.item.active ? "grey" : "lightgrey",
			textAlign: "center"
		};

		return (
			<div
				index={this.props.index}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={primaryStyle}
				onClick={this._onClick}
			>
				<i style={subsectionIconStyle} className="material-icons">
					{this.props.icon}
				</i>
				<div style={singleFieldTitleStyle}>{this.props.item.title}</div>
				<div style={singleFieldRequiredStyle}>{this.props.item.required ? "Required" : "Not required"}</div>
				<div style={singleFieldActiveStyle}>{this.props.item.active ? "Active" : "Inactive"}</div>
				<i style={arrowIconStyle} className="material-icons">
					keyboard_arrow_right
				</i>
			</div>
		);
	}
}

const buttonRow = {
	width: "100%",
	padding: "24px 0px 0px"
};

class Blacklist extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { items: this.props.component.blacklist || [] };
	}

	_updateFn(field, location, value) {
		this.setState({ items: value });
		ClientActions.setField("blacklist", [this.props.component.id], value, true);
	}

	render() {
		return (
			<div style={filterContainerStyle}>
				<Tokenizer
					value={this.state.items}
					field="blacklist"
					tokenField="text"
					updateFn={this._updateFn}
					label="Enter blacklisted word/expression"
					breakCharacters={["return"]}
				/>
			</div>
		);
	}
}

class Tags extends React.Component {
	constructor(props) {
		super(props);
		this._onPreloadResult = this._onPreloadResult.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this._onSave = this._onSave.bind(this);

		searchHelper.preload(
			"tags",
			[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }],
			this._onPreloadResult
		);
		this.state = { items: [], simpleItems: [] };
	}

	_onPreloadResult(items) {
		var simpleItems = [];
		for (var i = 0; i < items.length; i++) simpleItems.push(items[i].text);
		this.setState({ items, simpleItems });
	}

	_updateFn(field, location, value) {
		var j, found;
		var items = [];
		for (var i = 0; i < value.length; i++) {
			found = false;

			for (j = 0; j < this.state.items.length; j++) {
				if (this.state.items[j].text === value[i]) {
					found = true;
					items.push(this.state.items[j]);
					break;
				}
			}

			if (found === false) {
				items.push({ text: value[i], tagId: shortid.generate() });
			}
		}

		this.setState({ simpleItems: value, items });
	}

	_onSave() {
		ClientActions.saveTags(this.state.items);
	}

	render() {
		return (
			<div style={filterContainerStyle}>
				<Tokenizer
					value={this.state.simpleItems}
					tokenField="text"
					updateFn={this._updateFn}
					label="Enter tag"
					breakCharacters={["return"]}
				/>
				<div style={buttonRow}>
					<Button text="Save tags" onClick={this._onSave} />
				</div>
			</div>
		);
	}
}

class GenericCRM extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onBack() {
		UIActions.show("clients_settings");
	}

	render() {
		// Finds integration
		var i;
		var integration = this.props.integration;
		var component = this.props.component;
		for (i = 0; i < this.props.client.integrations.length; i++) {
			if (this.props.client.integrations[i].id === integration.id) {
				integration = this.props.client.integrations[i];
				break;
			}
		}

		if (component !== undefined) {
			for (i = 0; i < this.props.client.components.length; i++) {
				if (this.props.client.components[i].id === component.id) {
					component = this.props.client.components[i];
					break;
				}
			}
		}

		var subsection = [];
		switch (this.props.scaffold.type) {
			case "api connector":
				subsection = (
					<APIConnector client={this.props.client} integration={integration} scaffold={this.props.scaffold} />
				);
				break;
			case "rules":
				subsection = <Rules integration={integration} />;
				break;
			case "filters":
				subsection = <Filters integration={integration} />;
				break;
			case "automatic workflows":
				subsection = <AutoWorkflows integration={integration} workflows={this.props.client.workflows} />;
				break;
			case "slas":
				subsection = <SLAS integration={integration} />;
				break;
			case "channels":
				subsection = <Channels type={integration.type} integration={integration} component={component} />;
				break;
			case "users":
				subsection = (
					<Users integration={integration} component={component} users={component.users} client={this.props.client} />
				);
				break;
			case "ticket fields":
				subsection = <TicketFields integration={integration} component={component} users={component.users} />;
				break;
			case "signature":
				subsection = <Signature integration={integration} component={component} />;
				break;
			case "tags":
				subsection = <Tags client={this.props.client} />;
				break;
			case "blacklist":
				subsection = <Blacklist client={this.props.client} component={component} />;
				break;
			default:
				subsection = "";
				break;
		}

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title={this.props.scaffold.type.toUpperCase()} onBack={this._onBack}>
						{subsection}
						<div style={integrationIdStyle}>
							<b>Integration ID </b> {integration.id}
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default GenericCRM;
