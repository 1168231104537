import React from "react";
import brace from "brace";
import AceEditor from "react-ace";

import "brace/mode/html";
import "brace/theme/github";

// Actions
import ClientActions from "../../../actions/ClientActions";

// Form items
import InputMultiline from "../../_common/forms/InputMultiline.react";
import NavHorizontal from "../../_common/forms/NavHorizontal.react";

const filterContainerStyle = {
	width: "calc(100% - 16px)",
	padding: "0px 0px 0px 16px"
};

const typeOptions = [
	{
		text: "Rich text",
		value: "rte"
	},
	{
		text: "HTML",
		value: "html"
	}
];

const navStyle = {
	borderBottom: "1px solid lightgrey"
};

class Signature extends React.Component {
	constructor(props) {
		super(props);
		this._onUpdateType = this._onUpdateType.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onBlurAce = this._onBlurAce.bind(this);

		this.state = { mode: "rte", text: this.props.component.signature || "" };
	}

	_onUpdateType(field, location, value) {
		this.setState({ mode: value });
	}

	_updateFn(value) {
		this.setState({ text: value });
	}

	_onBlur(field, location, value) {
		this.setState({ text: value });
		ClientActions.setField("signature", [this.props.component.id], value, true);
	}

	_onBlurAce(value) {
		ClientActions.setField("signature", [this.props.component.id], this.state.text, true);
	}

	render() {
		return (
			<div style={filterContainerStyle}>
				<NavHorizontal
					id="response-nav"
					items={typeOptions}
					value={this.state.mode}
					updateFn={this._onUpdateType}
					style={navStyle}
				/>
				{this.state.mode === "rte" ? (
					<InputMultiline
						value={this.state.text}
						field="signature"
						placeholder="Enter signature text"
						onBlur={this._onBlur}
					/>
				) : (
					<AceEditor
						mode="html"
						theme="github"
						name="signature-ace-react"
						onChange={this._updateFn}
						onBlur={this._onBlurAce}
						fontSize={12}
						height="400px"
						width="100%"
						wrapEnabled={true}
						showPrintMargin={true}
						showGutter={true}
						highlightActiveLine={true}
						value={this.state.text}
						style={{
							zIndex: 0
						}}
						setOptions={{
							enableBasicAutocompletion: true,
							enableLiveAutocompletion: true,
							enableSnippets: false,
							showLineNumbers: true,
							tabSize: 4
						}}
					/>
				)}
			</div>
		);
	}
}

export default Signature;
