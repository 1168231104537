import Reflux from "reflux";

var WorkflowActions = Reflux.createActions([
	"loadSummary",
	"new",
	"load",
	"loadVersion",
	"save",
	"saveCompleted",
	"saveFailed",
	"delete",
	"deleteCompleted",
	"deleteFailed",
	"setField",
	"setFieldResponse",
	"add",
	"addCard",
	"addPath",
	"remove",
	"removeCard",
	"connectCard",
	"disconnectCard",
	"removePath",
	"setTicketWorflow",
	"setAttachment",
	"nextCard",
	"continueNextCard",
	"previousCard",
	"unsubscribe",
	"stopWorkflow",
	"close",
	"get",
	"update",
	"error"
]);

export default WorkflowActions;
