import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Menu from "../Menu.react";

import Button from "../buttons/Button.react";

// Form items
import Input from "../forms/Input.react";
import InputMultiline from "../forms/InputMultiline.react";

// Functions
import validate from "../../../functions/validate";

const questionStyle = {
	marginBottom: "16px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const rowStyle = {
	width: "100%",
	textAlign: "left",
	margin: "16px 0px 0px 0px"
};

const formItemContainerStyle = {
	display: "inline-block",
	marginRight: "16px",
	maxWidth: "calc(100% - 16px)"
};

const formItemOptions = [
	{
		text: "Edit",
		value: "edit"
	},
	{
		text: "Delete",
		value: "delete"
	}
];

class FormItem extends React.Component {
	constructor(props) {
		super(props);
		this._onMenuClick = this._onMenuClick.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { value: this.props.value[this.props.item.field] || "" };
	}

	_updateFn(field, location, value) {
		this.setState({ value: value });
	}

	_onBlur(field, location, value) {
		this.setState({ value: value });
		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	_onMenuClick(field, location, value) {
		if (value === "edit") {
			UIActions.showOverlay("form item builder", this.props.item);
		} else if (value === "delete") {
			WorkflowActions.remove(this.props.location.concat(this.props.item.id));
		}
	}

	render() {
		const formItemStyle = {
			width: this.props.item.width || "40%",
			maxWidth: "calc(100% - 32px)",
			verticalAlign: "middle"
		};

		return (
			<div style={formItemContainerStyle}>
				<Input
					id={"form-" + this.props.item.id}
					label={this.props.item.label || ""}
					value={this.state.value}
					field={"value." + this.props.item.field}
					location={this.props.location}
					updateFn={this._updateFn}
					onBlur={this._onBlur}
					colored={true}
					style={formItemStyle}
				/>
				{!this.props.agentMode ? (
					<Menu id={"menu-" + this.props.id} items={formItemOptions} updateFn={this._onMenuClick} />
				) : (
					""
				)}
			</div>
		);
	}
}

class FormCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onReturn = this._onReturn.bind(this);
		this._onAdd = this._onAdd.bind(this);
		this._onCloseBuilder = this._onCloseBuilder.bind(this);

		var value = this.props.response !== undefined ? this.props.response.value || "" : this.props.item.value || "";
		var checkValid = validate(this.props.item.validation || [], value);

		this.state = {
			text: this.props.item.prompts[0].text || "",
			value,
			valid: checkValid === "",
			nextPath: checkValid === "" ? 0 : 1,
			builder: false
		};
	}

	componentDidMount() {
		if ([undefined, null, ""].indexOf(this.state.value) === -1 && this.props.agentMode)
			this._onBlur("value", this.props.agentMode ? [this.props.response.id] : [this.props.item.id], this.state.value);
	}

	_updateFn(field, location, value) {
		var checkValid = validate(this.props.item.validation || [], value);
		this.setState({ [field]: value, valid: checkValid === "", nextPath: checkValid === "" ? 0 : 1 });
	}

	_onBlur(field, location, value) {
		var checkValid = validate(this.props.item.validation || [], value);
		this.setState({ [field]: value, valid: checkValid === "" });

		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	_onReturn() {
		this._onBlur("value", this.props.agentMode ? [this.props.response.id] : [this.props.item.id], this.state.value);
		WorkflowActions.nextCard(this.state.valid);
	}

	_onAdd() {
		var obj = {
			location: [this.props.item.id],
			label: "",
			field: "",
			width: "244px",
			required: false
		};

		WorkflowActions.add("items", [this.props.item.id], obj);
		UIActions.showOverlay("form item builder", obj);
	}

	_onCloseBuilder() {
		this.setState({ builder: false });
	}

	_onSaveBuilder(item) {}

	render() {
		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				nextPath={this.state.nextPath}
			>
				<InputMultiline
					id={this.props.item.prompts[0].id}
					placeholder="Enter question text here..."
					value={this.state.text}
					field="text"
					location={[this.props.item.id, this.props.item.prompts[0].id]}
					onBlur={this._onBlur}
					noLine={true}
					style={questionStyle}
					readOnly={this.props.agentMode}
				/>
				{this.props.item.items.map((item, index) => (
					<FormItem
						key={"form-item-" + item.id}
						id={"form-item-" + item.id}
						item={item}
						value={this.props.item.value || {}}
						location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
						agentMode={this.props.agentMode}
					/>
				))}
				{!this.props.agentMode ? (
					<div style={rowStyle}>
						<Button text="Add Item" style={buttonStyle} onClick={this._onAdd} />
					</div>
				) : (
					""
				)}
			</Card>
		);
	}
}

export default FormCard;
