function scroll(id, duration = 200, easing = "linear", callback) {
	// Calculate how far and how fast to scroll
	var startLocation = window.pageYOffset;
	var endLocation = document.getElementById("other-tickets").offsetTop - 100;
	var distance = endLocation - startLocation;
	var increments = distance / (duration / 16);
	var stopAnimation;

	// Scroll the page by an increment, and check if it's time to stop
	var animateScroll = function() {
		window.scrollBy(0, increments);
		stopAnimation();
	};

	// If scrolling down
	if (increments >= 0) {
		// Stop animation when you reach the anchor OR the bottom of the page
		stopAnimation = function() {
			var travelled = window.pageYOffset;
			if (travelled >= endLocation - increments || window.innerHeight + travelled >= document.body.offsetHeight) {
				clearInterval(runAnimation);
			}
		};
	} else {
		// If scrolling up
		// Stop animation when you reach the anchor OR the top of the page
		stopAnimation = function() {
			var travelled = window.pageYOffset;
			if (travelled <= (endLocation || 0)) {
				clearInterval(runAnimation);
			}
		};
	}

	// Loop the animation function
	var runAnimation = setInterval(animateScroll, 16);
}

export default scroll;
