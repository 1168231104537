function find_priority(value) {
	if (value === undefined || value === null) return 0;

	value = value.toLowerCase();
	if (value === "low") return 1;
	else if (value === "normal") return 2;
	else if (value === "high") return 3;
	else if (value === "urgent") return 4;
	else return 0;
}

function find_priority_reverse(value) {
	if (value === 0) return null;

	if (value === 1) return "low";
	else if (value === 2) return "normal";
	else if (value === 3) return "high";
	else if (value === 4) return "urgent";
	else return null;
}

function find_status(value) {
	value = value.toLowerCase();
	if (value === "new") return 1;
	else if (value === "open") return 2;
	else if (value === "pending") return 3;
	else if (value === "hold") return 4;
	else if (value === "solved") return 5;
	else if (value === "closed") return 6;
	else return 0;
}

function find_status_reverse(value) {
	if (value === 1) return "new";
	else if (value === 2) return "open";
	else if (value === 3) return "pending";
	else if (value === 4) return "hold";
	else if (value === 5) return "solved";
	else if (value === 6) return "closed";
	else return "";
}

export { find_priority, find_priority_reverse, find_status, find_status_reverse };
