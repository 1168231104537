import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import Input from "../forms/Input.react";
import InputMultiline from "../forms/InputMultiline.react";

// Functions
import validate from "../../../functions/validate";

class NumericCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onReturn = this._onReturn.bind(this);

		var value = this.props.response !== undefined ? this.props.response.value || "" : this.props.item.value || "";
		var checkValid = validate(this.props.item.validation || [], value);

		this.state = {
			text: this.props.item.prompts[0].text || "",
			value,
			valid: checkValid === "",
			nextPath: checkValid === "" ? 0 : 1
		};
	}

	componentDidMount() {
		if (this.props.agentMode) document.getElementById("response-" + this.props.item.prompts[0].id).focus();
	}

	_updateFn(field, location, value) {
		var checkValid = validate(this.props.item.validation || [], value);
		this.setState({ [field]: value, valid: checkValid === "", nextPath: checkValid === "" ? 0 : 1 });
	}

	_onBlur(field, location, value) {
		var checkValid = validate(this.props.item.validation || [], value);
		this.setState({ [field]: value, valid: checkValid === "", nextPath: checkValid === "" ? 0 : 1 });

		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	_onReturn() {
		this._onBlur("value", this.props.agentMode ? [this.props.response.id] : [this.props.item.id], this.state.value);
		WorkflowActions.nextCard(this.state.valid);
	}

	render() {
		const questionStyle = {
			marginBottom: "0px"
		};

		const responseStyle = {
			borderRadius: "10px",
			width: "calc(100% - 48px - 32px)",
			marginLeft: "24px"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				nextPath={this.state.nextPath}
			>
				<InputMultiline
					id={this.props.item.prompts[0].id}
					placeholder="Enter question text here..."
					value={this.state.text}
					field="text"
					location={[this.props.item.id, this.props.item.prompts[0].id]}
					onBlur={this._onBlur}
					noLine={true}
					style={questionStyle}
					readOnly={this.props.agentMode}
				/>
				<Input
					id={"response-" + this.props.item.prompts[0].id}
					label={this.props.item.concept || ""}
					value={this.state.value}
					field="value"
					location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
					updateFn={this._updateFn}
					onBlur={this._onBlur}
					onReturn={this.props.agentMode ? this._onReturn : undefined}
					colored={true}
					style={responseStyle}
					type="number"
					validation={this.props.item.validation || []}
				/>
			</Card>
		);
	}
}

export default NumericCard;
