import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Input from "../forms/Input.react";
import Dropdown from "../forms/Dropdown.react";

const checkingStyle = {
	width: "100%",
	padding: "0px 0px 16px",
	textAlign: "center"
};

const actionTypes = [
	{
		text: "Add Rows",
		value: "add rows"
	},
	{
		text: "Update Row",
		value: "update row"
	},
	{
		text: "Get Rows",
		value: "get rows"
	}
];

const tableStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	borderTop: "1px solid cadetblue",
	minWidth: "100%",
	marginBottom: "16px"
};

const primaryStyle = {
	display: "inline-block",
	width: "100%",
	textAlign: "left"
};

const columnControlStyle = {
	display: "inline-block",
	width: "100%",
	textAlign: "right",
	padding: "8px 0px"
};

const rowControlStyle = {
	display: "inline-block",
	width: "100%",
	textAlign: "left",
	padding: "8px 0px"
};

const controlStyle = {
	display: "inline-block",
	margin: "0px 2px",
	fontSize: "30px",
	cursor: "pointer",
	color: "rgba(0, 0, 0, 0.64)"
};

const rowStyle = {
	display: "inline-block",
	borderBottom: "1px solid lightgrey",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.87)",
	cursor: "pointer",
	transition: "background-color .3s ease"
};

const cellStyle = {
	width: "250px",
	marginBottom: "0px"
};

class Cell extends React.Component {
	render() {
		return (
			<Input
				value={this.props.item.text}
				noLine={true}
				thin={true}
				field="text"
				placeholder="Value"
				style={cellStyle}
				location={this.props.index}
				onBlur={this.props.updateFn}
			/>
		);
	}
}
class Row extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
	}

	_updateFn(field, location, value) {
		var item = this.props.item;
		item.cells[location].text = value;
		this.props.updateFn("cells", this.props.index, item);
	}

	render() {
		return (
			<div style={rowStyle}>
				{this.props.item.cells.map((item, index, arr) => (
					<Cell item={item} index={index} updateFn={this._updateFn} />
				))}
			</div>
		);
	}
}

class AddRow extends React.Component {
	constructor(props) {
		super(props);
		this._onRemoveColumn = this._onRemoveColumn.bind(this);
		this._onAddColumn = this._onAddColumn.bind(this);
		this._onRemoveRow = this._onRemoveRow.bind(this);
		this._onAddRow = this._onAddRow.bind(this);
		this._updateFn = this._updateFn.bind(this);
	}

	componentDidMount() {
		if (this.props.item.data === undefined || this.props.item.data.length === 0)
			WorkflowActions.setField("data", [this.props.item.id], [{ cells: [{ text: "" }] }], true);
	}

	_updateFn(field, location, value) {
		var data = this.props.item.data;
		data[location] = value;
		WorkflowActions.setField("data", [this.props.item.id], data, true);
	}

	_onRemoveColumn() {
		var data = this.props.item.data;
		if (data[0].cells.length > 1) {
			for (var i = 0; i < data.length; i++) data[i].cells.splice(data[i].cells.length - 1, 1);
		}

		WorkflowActions.setField("data", [this.props.item.id], data, true);
	}

	_onAddColumn() {
		var data = this.props.item.data;
		for (var i = 0; i < data.length; i++) data[i].cells.push({ text: "" });
		WorkflowActions.setField("data", [this.props.item.id], data, true);
	}

	_onRemoveRow() {
		var data = this.props.item.data;
		if (data.length > 1) {
			data.splice(data.length - 1, 1);
			WorkflowActions.setField("data", [this.props.item.id], data, true);
		}
	}

	_onAddRow() {
		var data = this.props.item.data;
		var cellN = data[0].cells.length;
		var newRow = { cells: [] };
		for (var i = 0; i < cellN; i++) newRow.cells.push({ text: "" });

		data.push(newRow);
		WorkflowActions.setField("data", [this.props.item.id], data, true);
	}

	render() {
		const data = this.props.item.data || [];
		return (
			<div style={primaryStyle}>
				<div style={columnControlStyle}>
					<i style={controlStyle} className="material-icons" onClick={this._onRemoveColumn}>
						remove_circle_outline
					</i>
					<i style={controlStyle} className="material-icons" onClick={this._onAddColumn}>
						add_circle_outline
					</i>
				</div>
				<div style={tableStyle}>
					{data.map((item, index, arr) => (
						<Row item={item} index={index} updateFn={this._updateFn} />
					))}
				</div>
				<div style={rowControlStyle}>
					<i style={controlStyle} className="material-icons" onClick={this._onRemoveRow}>
						remove_circle_outline
					</i>
					<i style={controlStyle} className="material-icons" onClick={this._onAddRow}>
						add_circle_outline
					</i>
				</div>
			</div>
		);
	}
}

class GoogleSheetsCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = {
			valid: true,
			nextPath: 0
		};
	}

	componentDidMount() {
		if (this.props.direction === "back") setTimeout(WorkflowActions.previousCard, 500);
	}
	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const directionControlsStyle = {
			width: "100%",
			display: "inline-block",
			marginTop: "0px",
			marginBottom: "-24px",
			textAlign: "center"
		};

		var toDisplay = [];

		if (["add rows", "update row"].indexOf(this.props.item.action) > -1) {
			toDisplay = <AddRow item={this.props.item} updateFn={this._updateFn} />;
		}

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				noNextButton={true}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={directionControlsStyle}>
						<Dropdown
							id={"action-" + this.props.item.id}
							items={actionTypes}
							field={"action"}
							location={[this.props.item.id]}
							updateFn={this._updateFn}
							value={this.props.item.action}
							label="Action type"
						/>
						<Input
							id={"sheet-" + this.props.item.id}
							field={"sheet"}
							location={[this.props.item.id]}
							updateFn={this._updateFn}
							value={this.props.item.sheet}
							label="Spreadsheet ID"
							helpText="Make sure the sheet is shared with serverless@hioperator-prod.iam.gserviceaccount.com"
						/>
						<Input
							id={"sheet-" + this.props.item.id}
							field={"range"}
							location={[this.props.item.id]}
							updateFn={this._updateFn}
							value={this.props.item.range}
							label="Sheet ID"
							helpText="e.g, Sheet1"
						/>
						{toDisplay}
					</div>
				) : (
					<div>
						<Loading height="200px" />
						<div style={checkingStyle}>Accessing Google Sheet...</div>
					</div>
				)}
			</Card>
		);
	}
}

export default GoogleSheetsCard;
