import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import ClientActions from "../../../actions/ClientActions";

// Scaffolding
import Card from "../../_common/Card.react";
import Container from "../../_common/Container.react";
import Overlay from "../../_common/Overlay.react";
import SettingsRow from "../../_common/SettingsRow.react";

// Form items
import Input from "../../_common/forms/Input.react";
import Button from "../../_common/buttons/Button.react";
import Dropdown from "../../_common/forms/Dropdown.react";
import Tokenizer from "../../_common/forms/Tokenizer.react";

// Headers
import H1 from "../../_common/headers/H1.react";
import SectionLabel from "../../_common/SectionLabel.react";

import Toggle from "../../_common/forms/Toggle.react";
import ToggleWithLabel from "../../_common/forms/ToggleWithLabel.react";

// Styles
const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px 0px 24px 24px",
	textAlign: "left",
	maxWidth: "1200px"
};

const rowStyle = {
	width: "calc(100% - 8px)",
	textAlign: "right",
	margin: "0px 0px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const filterButtonContainerStyle = {
	margin: "16px 0px",
	width: "100%",
	textAlign: "center"
};

const bigInputStyle = {
	marginBottom: "0px"
};

const deleteButtonStyle = {
	color: "indianred"
};

const saveButtonStyle = {
	color: "rgb(70, 180, 175)"
};

const zendeskOptions = [
	{
		text: "Assignee Name",
		value: "assignee name"
	},
	{
		text: "Group",
		value: "group"
	},
	{
		text: "Tag",
		value: "tag"
	},
	{
		text: "Status",
		value: "status"
	},
	{
		text: "Priority",
		value: "priority"
	},
	{
		text: "Type",
		value: "type"
	},
	{
		text: "Channel",
		value: "channel"
	},
	{
		text: "Subject",
		value: "subject"
	},
	{
		text: "Description",
		value: "description"
	}
];

const frontOptions = [
	{
		text: "Assignee Name",
		value: "assignee name"
	},
	{
		text: "Tag",
		value: "tag"
	},
	{
		text: "Status",
		value: "status"
	},
	{
		text: "Subject",
		value: "subject"
	},
	{
		text: "Type",
		value: "type"
	},
	{
		text: "Description",
		value: "description"
	},
	{
		text: "Mailbox address",
		value: "mailbox_address"
	},
	{
		text: "Inbox",
		value: "inbox"
	}
];

const zendeskStatusOptions = [
	{
		text: "New",
		value: 1
	},
	{
		text: "Open",
		value: 2
	},
	{
		text: "Pending",
		value: 3
	},
	{
		text: "On hold",
		value: 4
	},
	{
		text: "Solved",
		value: 5
	},
	{
		text: "Closed",
		value: 6
	}
];

const frontStatusOptions = [
	{
		text: "New",
		value: 1
	},
	{
		text: "Open",
		value: 2
	},
	{
		text: "Archived",
		value: 5
	},
	{
		text: "Deleted",
		value: 7
	}
];

class ConditionRow extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onRemoveRule = this._onRemoveRule.bind(this);

		this.state = {
			type: this.props.item.type || "",
			state: this.props.item.state || "",
			value: this.props.item.value || ""
		};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		ClientActions.setField(field, location, value, true);
	}

	_onRemoveRule() {
		if (this.props.totalRules === 1) {
			ClientActions.remove(this.props.location);
		} else {
			console.log("REMOVING ");
			console.log(this.props.location.concat(this.props.item.id));

			ClientActions.remove(this.props.location.concat(this.props.item.id));
		}
	}

	render() {
		const primaryStyle = {
			width: "100%",
			display: "inline-block",
			padding: "8px 0px"
		};

		const typeStyle = {
			width: "200px",
			marginBottom: "0px",
			marginRight: "16px"
		};

		const leftColumnStyle = {
			display: "inline-block",
			width: "calc(100% - 70px)",
			verticalAlign: "middle"
		};

		const closeIconStyle = {
			marginLeft: "16px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		// Figures out state options
		var stateOptions = [];
		var valueOptions = [];

		if (["mailbox_address", "assignee name", "group", "tag", "inbox"].indexOf(this.state.type) > -1) {
			stateOptions = [
				{
					text: "Is",
					value: "is"
				},
				{
					text: "Is not",
					value: "is not"
				},
				{
					text: "Contains",
					value: "contains"
				},
				{
					text: "Does not contain",
					value: "does not contain"
				}
			];
		} else if (this.state.type === "status") {
			stateOptions = [
				{
					text: "Is",
					value: "is"
				},
				{
					text: "Is not",
					value: "is not"
				},
				{
					text: "Less than",
					value: "less than"
				},
				{
					text: "Greater than",
					value: "greater than"
				}
			];

			valueOptions = this.props.type === "zendesk" ? zendeskStatusOptions : frontStatusOptions;
		} else if (this.state.type === "priority") {
			stateOptions = [
				{
					text: "Is",
					value: "is"
				},
				{
					text: "Is not",
					value: "is not"
				},
				{
					text: "Less than",
					value: "less than"
				},
				{
					text: "Greater than",
					value: "greater than"
				}
			];

			valueOptions = [
				{
					text: "Low",
					value: 1
				},
				{
					text: "Normal",
					value: 2
				},
				{
					text: "High",
					value: 3
				},
				{
					text: "Urgent",
					value: 4
				}
			];
		} else if (["subject", "description", "type"].indexOf(this.state.type) > -1) {
			stateOptions = [
				{
					text: "Is",
					value: "is"
				},
				{
					text: "Is not",
					value: "is not"
				},
				{
					text: "Contains",
					value: "contains"
				},
				{
					text: "Does not contain",
					value: "does not contain"
				}
			];
		} else if (this.state.type === "channel") {
			stateOptions = [
				{
					text: "Is",
					value: "is"
				},
				{
					text: "Is not",
					value: "is not"
				}
			];

			valueOptions = [
				{
					text: "Web form",
					value: "web"
				},
				{
					text: "Email",
					value: "email"
				},
				{
					text: "Chat",
					value: "chat"
				},
				{
					text: "Twitter",
					value: "twitter"
				},
				{
					text: "Twitter DM",
					value: "twitter_dm"
				},
				{
					text: "Twitter like",
					value: "twitter_favorite"
				},
				{
					text: "Voicemail",
					value: "voicemail"
				},
				{
					text: "Phone call (incoming)",
					value: "phone_call_inbound"
				},
				{
					text: "Phone call (outbound)",
					value: "phone_call_outbound"
				},
				{
					text: "CTI* voicemail",
					value: "api_voicemail"
				},
				{
					text: "CTI phone call (inbound)",
					value: "api_phone_call_inbound"
				},
				{
					text: "CTI phone call (outbound)",
					value: "api_phone_call_outbound"
				},
				{
					text: "SMS",
					value: "sms"
				},
				{
					text: "Get Satisfaction",
					value: "get_satisfaction"
				},
				{
					text: "Web Widget",
					value: "web_widget"
				},
				{
					text: "Mobile SDK",
					value: "mobile_sdk"
				},
				{
					text: "Mobile",
					value: "mobile"
				},
				{
					text: "Help Center post",
					value: "helpcenter"
				},
				{
					text: "Web service (API)",
					value: "web_service"
				},
				{
					text: "Trigger, automation",
					value: "rule"
				},
				{
					text: "Closed ticket",
					value: "closed_ticket"
				},
				{
					text: "Ticket sharing",
					value: "ticket_sharing"
				},
				{
					text: "Facebook post",
					value: "facebook_post"
				},
				{
					text: "Facebook private message",
					value: "facebook_message"
				},
				{
					text: "Satisfaction prediction",
					value: "satisfaction_prediction"
				},
				{
					text: "Channel framework",
					value: "any_channel"
				}
			];
		}

		// Figures out state options
		var location = this.props.location.concat(this.props.item.id);

		return (
			<div style={primaryStyle}>
				<div style={leftColumnStyle}>
					<Dropdown
						id={"filter-type-" + this.props.parentIndex + "-" + this.props.index}
						style={typeStyle}
						value={this.state.type}
						field="type"
						location={location}
						label="Field"
						items={this.props.type === "zendesk" ? zendeskOptions : frontOptions}
						updateFn={this._onBlur}
						searchable={true}
						noHelp={true}
					/>
					<Dropdown
						id={"filter-state-" + this.props.parentIndex + "-" + this.props.index}
						style={typeStyle}
						value={this.state.state}
						field="state"
						location={location}
						label="State"
						items={stateOptions}
						updateFn={this._onBlur}
						searchable={true}
						noHelp={true}
					/>
					{valueOptions.length > 0 ? (
						<Dropdown
							id={"filter-value-" + this.props.parentIndex + "-" + this.props.index}
							style={typeStyle}
							value={this.state.value}
							field="value"
							location={location}
							label="Value"
							items={valueOptions}
							updateFn={this._onBlur}
							searchable={true}
							noHelp={true}
						/>
					) : (
						<Input
							id={"filter-value-" + this.props.parentIndex + "-" + this.props.index}
							style={typeStyle}
							value={this.state.value || this.props.item.value}
							field="value"
							location={location}
							label="Value"
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							noHelp={true}
						/>
					)}
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemoveRule}>
					close
				</i>
			</div>
		);
	}
}

class Condition extends React.Component {
	constructor(props) {
		super(props);
		this._onAddOr = this._onAddOr.bind(this);
	}

	_onAddOr() {
		ClientActions.add("all", this.props.location.concat(this.props.item.id), {
			type: "tag",
			state: "is",
			value: ""
		});
	}

	render() {
		const outerStyle = {
			width: "100%",
			display: "inline-block",
			margin: "16px 0px 8px"
		};

		const primaryStyle = {
			display: "inline-block",
			width: "calc(100% - 16px)",
			backgroundColor: "rgba(0, 0, 0, 0.1)",
			borderRadius: "4px",
			padding: "8px",
			marginBottom: this.props.last ? "0px" : "16px"
		};

		const labelStyle = {
			width: "100%",
			textAlign: "center",
			fontSize: "20px",
			margin: "8px 0px",
			fontWeight: "700"
		};

		var rows = Array.isArray(this.props.item.all) && this.props.item.all.length > 0 ? this.props.item.all : [];
		return (
			<div style={outerStyle}>
				<div style={primaryStyle}>
					{rows.map((item, index, arr) => (
						<ConditionRow
							key={this.props.item.id + "-" + item.id}
							item={item}
							index={index}
							parentIndex={this.props.index}
							location={this.props.location.concat(this.props.item.id)}
							totalRules={arr.length}
							ticket_fields={this.props.ticket_fields}
							ticket_fields_full={this.props.ticket_fields_full}
							target={this.props.target}
							rule={this.props.item}
						/>
					))}
					<div style={filterButtonContainerStyle}>
						<Button text="Add AND Clause" onClick={this._onAddOr} />
					</div>
				</div>

				{!this.props.last ? <div style={labelStyle}>OR</div> : ""}
			</div>
		);
	}
}

class Action extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onRemoveRule = this._onRemoveRule.bind(this);

		this.state = {
			action: this.props.item.action || "",
			field: this.props.item.field || "",
			value: this.props.item.value || ""
		};
	}

	_updateFn(field, location, value) {
		if (field === "action") this.setState({ [field]: value, field: "", value: "" });
		else if (field === "field") this.setState({ [field]: value, value: "" });
		else this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		if (field === "action") this.setState({ [field]: value, field: "", value: "" });
		else if (field === "field") this.setState({ [field]: value, value: "" });
		else this.setState({ [field]: value });
		ClientActions.setField(field, location, value, true);
	}

	_onRemoveRule() {
		if (this.props.totalRules === 1) {
			ClientActions.remove(this.props.location);
		} else {
			console.log("REMOVING ");
			console.log(this.props.location.concat(this.props.item.id));

			ClientActions.remove(this.props.location.concat(this.props.item.id));
		}
	}

	render() {
		const primaryStyle = {
			width: "100%",
			display: "inline-block",
			padding: "8px 0px"
		};

		const typeStyle = {
			width: "200px",
			marginBottom: "0px",
			marginRight: "16px"
		};

		const tagStyle = {
			width: "400px",
			marginBottom: "0px",
			marginRight: "16px"
		};

		const leftColumnStyle = {
			display: "inline-block",
			width: "calc(100% - 70px)",
			verticalAlign: "middle"
		};

		const closeIconStyle = {
			marginLeft: "16px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		// Figures out state options
		var actionOptions = [
			{
				text: "Set ticket field",
				value: "set field"
			},
			{
				text: "Change escalation",
				value: "escalate"
			},
			{
				text: "Filter out",
				value: "deleted"
			},
			{
				text: "Add workflow",
				value: "add workflow"
			},
			{
				text: "Add tags",
				value: "add tags"
			},
			{
				text: "Remove tags",
				value: "remove tags"
			},
			{
				text: "Set SLA",
				value: "sla"
			}
		];

		var fieldOptions = [];
		var valueOptions = [];
		var showFields = false;
		var showValues = false;
		var showTags = false;
		var showToggle = false;
		var valueLabel = "Value";

		// Figures out field options
		if (["set field"].indexOf(this.state.action) > -1) {
			showFields = true;
			fieldOptions = [
				{
					text: "Character limit",
					value: "characterLimit"
				}
			];
		}

		// Figures out value options;
		if (["characterLimit"].indexOf(this.state.field) > -1) {
			showValues = true;
			valueLabel = "Max number";
		} else if (["sla"].indexOf(this.state.action) > -1) {
			showValues = true;
			valueLabel = "SLA Hours";
		} else if (["add tags", "remove tags"].indexOf(this.state.action) > -1) showTags = true;
		else if (["escalate"].indexOf(this.state.action) > -1) {
			showValues = true;
			valueOptions = [
				{
					text: "Internal",
					value: "internal"
				},
				{
					text: "External",
					value: "external"
				},
				{
					text: "De-escalate",
					value: "deescalate"
				}
			];
		} else if (["deleted"].indexOf(this.state.action) > -1) {
			showToggle = true;
		} else if (["add workflow"].indexOf(this.state.action) > -1) {
			showValues = true;
			valueOptions = this.props.workflowOptions || [];
		}

		// Figures out state options
		var location = this.props.location.concat(this.props.item.id);

		return (
			<div style={primaryStyle}>
				<div style={leftColumnStyle}>
					<Dropdown
						id={"action-" + this.props.item.id + "-"}
						style={typeStyle}
						value={this.state.action}
						field="action"
						location={location}
						label="Action"
						items={actionOptions}
						updateFn={this._onBlur}
						searchable={true}
						noHelp={true}
					/>
					{showFields ? (
						<Dropdown
							id={"field-" + this.props.item.id}
							style={typeStyle}
							value={this.state.field}
							field="field"
							location={location}
							label="Field"
							items={fieldOptions}
							updateFn={this._onBlur}
							searchable={true}
							noHelp={true}
						/>
					) : (
						""
					)}
					{showValues && valueOptions.length > 0 ? (
						<Dropdown
							id={"value-" + this.props.item.id}
							style={typeStyle}
							value={this.state.value}
							field="value"
							location={location}
							label="Value"
							items={valueOptions}
							updateFn={this._onBlur}
							searchable={true}
							noHelp={true}
						/>
					) : (
						""
					)}
					{showValues && valueOptions.length === 0 ? (
						<Input
							id={"value-" + this.props.item.id}
							style={typeStyle}
							value={this.state.value}
							field="value"
							location={location}
							label={valueLabel}
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							noHelp={true}
						/>
					) : (
						""
					)}
					{showTags ? (
						<Tokenizer
							id={"value-" + this.props.item.id}
							style={tagStyle}
							value={this.state.value || []}
							field="value"
							location={location}
							label="Tags"
							updateFn={this._onBlur}
							noHelp={true}
						/>
					) : (
						""
					)}
					{showToggle ? (
						<ToggleWithLabel
							id={"value-" + this.props.item.id}
							style={tagStyle}
							value={this.state.value}
							field="value"
							text={this.state.value ? "True" : "False"}
							location={location}
							updateFn={this._onBlur}
						/>
					) : (
						""
					)}
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemoveRule}>
					close
				</i>
			</div>
		);
	}
}

class Row extends React.Component {
	render() {
		const rowStyle = {
			display: "inline-block",
			width: "calc(100% - 48px)",
			padding: "16px 32px 16px 16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			borderBottom: this.props.noBottom ? "" : "1px solid lightgrey"
		};

		return <div style={rowStyle}>{this.props.children}</div>;
	}
}

const actionsBackground = {
	display: "inline-block",
	width: "calc(100% - 16px)",
	backgroundColor: "rgba(0, 0, 0, 0.1)",
	borderRadius: "4px",
	padding: "8px"
};

class RulesEditorOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onAddCondition = this._onAddCondition.bind(this);
		this._onAddAction = this._onAddAction.bind(this);
		this._onDeleteRule = this._onDeleteRule.bind(this);
		this._onDeleteRuleConfirmed = this._onDeleteRuleConfirmed.bind(this);

		this.state = { active: this.props.item.rule.active || false, stopper: this.props.item.rule.stopper || false };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		ClientActions.setField(field, [this.props.item.integrationId, this.props.item.rule.id], value, true);
	}

	_onClose() {
		UIActions.showOverlay("");
		ClientActions.update();
	}

	_onAddCondition() {
		ClientActions.add("conditions", [this.props.item.integrationId, this.props.item.rule.id], {
			all: [{ type: "assignee name", state: "is", value: "Hioperator" }]
		});
	}

	_onAddAction() {
		ClientActions.add("actions", [this.props.item.integrationId, this.props.item.rule.id], {
			action: "set field",
			field: "isEscalated",
			value: true
		});
	}

	_onDeleteRule() {
		UIActions.showConfirmation({
			title: "Delete rule",
			text: "Are you sure you want to delete this rule?",
			buttonText: "Delete rule",
			callback: this._onDeleteRuleConfirmed
		});
	}

	_onDeleteRuleConfirmed() {
		ClientActions.remove([this.props.item.integrationId, this.props.item.rule.id]);
		this._onClose();
	}

	render() {
		var workflowOptions = [];
		if (this.props.workflows !== undefined) {
			for (var i = 0; i < this.props.workflows.length; i++)
				workflowOptions.push({ text: this.props.workflows[i].name, value: this.props.workflows[i].id });
		}

		return (
			<Overlay>
				<Container maxWidth="848px" key="ticket-field-editor-container" marginBottom="0px">
					<Card style={cardStyle}>
						<H1 text="Rule" textAlign="center" />
						<Row index={0} noBottom={true}>
							<Input
								value={this.props.item.rule.name || ""}
								onBlur={this._onBlur}
								location={[this.props.item.integrationId, this.props.item.rule.id]}
								placeholder={"Rule Name"}
								field="name"
								colored={false}
								style={bigInputStyle}
								noLine={true}
								padding="0px"
								noInputClass={true}
							/>
						</Row>
						<SettingsRow index={0} icon="visibility" active={this.state.active}>
							<div>Field is active (will appear for agents)</div>
							<Toggle
								value={this.state.active}
								field="active"
								updateFn={this._onBlur}
								location={[this.props.item.integrationId, this.props.item.rule.id]}
							/>
						</SettingsRow>
						<SettingsRow index={1} icon="stop" active={this.state.stopper}>
							<div>Does the rule stop all subsequent rules?</div>
							<Toggle
								value={this.state.stopper}
								field="stopper"
								updateFn={this._onBlur}
								location={[this.props.item.integrationId, this.props.item.rule.id]}
							/>
						</SettingsRow>
						<Row index={2}>
							<SectionLabel text="Conditions" />
							{this.props.item.rule.conditions !== undefined && this.props.item.rule.conditions.length > 0 ? (
								<div>
									{this.props.item.rule.conditions.map((item, index) => (
										<Condition
											key={"condition-" + item.id}
											item={item}
											index={index}
											target={this.props.item.rule}
											location={[this.props.item.integrationId, this.props.item.rule.id]}
										/>
									))}
									<div style={filterButtonContainerStyle}>
										<Button text="Add OR clause" onClick={this._onAddCondition} />
									</div>
								</div>
							) : (
								<div style={emptyStyle}>
									<div style={emptyTextStyle}>There are currently no conditions</div>
									<Button text="Add conditions" onClick={this._onAddCondition} />
								</div>
							)}
						</Row>
						<Row index={3} noBottom={true}>
							<SectionLabel text="Actions" />
							{this.props.item.rule.actions !== undefined && this.props.item.rule.actions.length > 0 ? (
								<div>
									<div style={actionsBackground}>
										{this.props.item.rule.actions.map((item, index) => (
											<Action
												key={"action-" + item.id}
												item={item}
												index={index}
												location={[this.props.item.integrationId, this.props.item.rule.id]}
												workflowOptions={workflowOptions}
											/>
										))}
									</div>
									<div style={filterButtonContainerStyle}>
										<Button text="Add Action" onClick={this._onAddAction} />
									</div>
								</div>
							) : (
								<div style={emptyStyle}>
									<div style={emptyTextStyle}>There are currently no actions</div>
									<Button text="Add Action" onClick={this._onAddAction} />
								</div>
							)}
						</Row>
						<div style={rowStyle}>
							<Button text="Delete rule" onClick={this._onDeleteRule} type="flat" style={deleteButtonStyle} />
							<Button text="Save rule" style={saveButtonStyle} type="flat" onClick={this._onClose} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default RulesEditorOverlay;
