import ensure_ids from "./ensure_ids";

function find(obj, parent, address, index) {
	var myReturn;
	if (Array.isArray(obj)) {
		for (var i = 0; i < obj.length; i++) {
			if (obj[i].id === address[0]) return find(obj[i], obj, address, i);
			else if (Array.isArray(obj[i])) {
				myReturn = find(obj[i], obj, address, i);
				if (myReturn !== null) return myReturn;
			}
		}
	} else if (typeof obj === "object" && obj !== null) {
		if (obj.id === address[0]) address.splice(0, 1);

		if (address.length === 0) return { obj, parent, index };
		if (obj[address[0]] !== undefined) {
			var prop = address.splice(0, 1)[0];
			return find(obj[prop], obj, address);
		}

		var keys = Object.keys(obj);

		for (var j = 0; j < keys.length; j++) {
			myReturn = find(obj[keys[j]], obj, address);
			if (myReturn !== null) return myReturn;
		}
	}

	return null;
}

function find_deep(obj, parent, id, extraField) {
	var myReturn, i, j;
	if (Array.isArray(obj)) {
		for (j = 0; j < obj.length; j++) {
			if (typeof obj[j] === "object") {
				myReturn = find_deep(obj[j], obj, id, extraField);
				if (myReturn !== null) return myReturn;
			}
		}

		return null;
	} else if (typeof obj === "object") {
		if (obj.id === id) return { obj, parent };
		else if (extraField !== undefined && obj[extraField] === id) return { obj, parent };

		var keys = Object.keys(obj);

		for (i = 0; i < keys.length; i++) {
			if (Array.isArray(obj[keys[i]])) {
				for (j = 0; j < obj[keys[i]].length; j++) {
					if (typeof obj[keys[i]][j] === "object" || Array.isArray(obj[keys[i]][j])) {
						myReturn = find_deep(obj[keys[i]][j], obj, id, extraField);
						if (myReturn !== null) return myReturn;
					}
				}
			}
		}
	}

	return null;
}

export default find;

function add(target, field, location, obj) {
	ensure_ids(target, obj);

	if (location !== undefined && Array.isArray(location) && location.length > 0) {
		target = find(target, null, location.concat()).obj;
	}

	if (target[field] === undefined || !Array.isArray(target[field])) {
		target[field] = [];
	}

	// Checks to see if object already exists; if it does, replaces it
	for (var i = 0; i < target[field].length; i++) {
		if (target[field][i].id === obj.id) {
			target[field][i] = obj;
			return;
		}
	}

	target[field].push(obj);
}

function remove(target, address) {
	var result = find(target, null, address);

	if (result !== null && result.parent !== undefined && result.index !== undefined)
		result.parent.splice(result.index, 1);
}

function clone(obj) {
	return JSON.parse(JSON.stringify(obj));
}

var iterate_object = (target, item) => {
	var temp;
	if (target === undefined) return "";

	if (target.indexOf("[") > -1 && (target.indexOf("[") < target.indexOf(".") || target.indexOf(".") === -1)) {
		var single = target.substr(0, target.indexOf("]") + 1);
		temp = single.substr(0, single.indexOf("["));
		if (item[temp] !== undefined) {
			if (Array.isArray(item[temp])) {
				single = target.substr(target.indexOf("[") + 1, target.indexOf("]") - target.indexOf("[") - 1);

				if (item[temp][Number(single)] === undefined) return "";
				else if (target.indexOf(".") > -1)
					return iterate_object(target.substr(target.indexOf(".") + 1), item[temp][Number(single)]);
				else return item[temp][Number(single)];
			} else {
				if (target.indexOf(".") > -1) return iterate_object(target.substr(target.indexOf(".") + 1), item[temp]);
				else return item[temp];
			}
		} else return undefined;
	} else if (target.indexOf(".") > -1) {
		temp = target.substr(0, target.indexOf("."));
		if (item[temp] !== undefined) return iterate_object(target.substr(target.indexOf(".") + 1), item[temp]);
		else return undefined;
	} else {
		return [null, undefined, ""].indexOf(item) === -1 ? item[target] : "";
	}
};

export { find, find_deep, add, remove, clone, iterate_object };
