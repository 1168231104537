import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Input from "../forms/Input.react";
import Dropdown from "../forms/Dropdown.react";

const checkingStyle = {
    width: "100%",
    padding: "0px 0px 16px",
    textAlign: "center",
};

class MakeComemntPrivateCard extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this.state = {
            valid: true,
            nextPath: 0,
        };
    }

    componentDidUpdate() {}

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
        WorkflowActions.setField(field, location, value, true);
    }

    render() {
        const directionControlsStyle = {
            width: "100%",
            marginTop: "0px",
            marginBottom: "24px",
            textAlign: "center",
        };

        return (
            <Card
                id={this.props.item.id}
                item={this.props.item}
                picture={this.props.client !== undefined ? this.props.client.picture : ""}
                name={this.props.client !== undefined ? this.props.client.name : ""}
                first={this.props.first}
                onMoveView={this.props.onMoveView}
                targetFocus={this.props.targetFocus}
                agentMode={this.props.agentMode}
                onCardConnect={this.props.onCardConnect}
                connecting={this.props.connecting}
                valid={this.state.valid}
                noNextButton={true}
                nextPath={this.state.nextPath}
            >
                {!this.props.agentMode ? (
                    <div style={directionControlsStyle}>
                        <Input
                            id={"commentId-" + this.props.item.id}
                            field={"value"}
                            location={[this.props.item.id]}
                            onBlur={this._updateFn}
                            value={this.props.item.value}
                            label={"Comment ID"}
                        />
                    </div>
                ) : (
                    <div>
                        <Loading height="200px" />
                        <div style={checkingStyle}>Making comment private...</div>
                    </div>
                )}
            </Card>
        );
    }
}

export default MakeComemntPrivateCard;
