import React from "react";

import * as time from "../../../functions/time";

class UserResult extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onClick(this.props.item);
	}

	render() {
		const primaryStyle = {
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			padding: "16px"
		};

		const imageStyle = {
			height: "40px",
			width: "40px",
			display: "inline-block",
			verticalAlign: "middle",
			marginRight: "16px",
			borderRadius: "50%",
			cursor: "pointer",
			fontWeight: "900",
			fontFamily: "Roboto",
			fontSize: "36px",
			textAlign: "center"
		};

		const imageTextStyle = {
			fontSize: "20px",
			height: "20px",
			width: "40px",
			textAlign: "center",
			padding: "10px 0px",
			backgroundColor: "rgba(0, 0, 0, 0.62)",
			color: "white",
			cursor: "pointer",
			borderRadius: "50%",
			verticalAlign: "middle",
			display: "inline-block",
			lineHeight: "20px",
			marginRight: "16px"
		};

		const nameStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "16px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "500",
			cursor: "pointer",
			width: "300px",
			textAlign: "left"
		};

		const createdStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "400",
			width: "200px",
			textAlign: "center"
		};

		const roleStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "20px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "400",
			width: "200px",
			textAlign: "center"
		};

		const labelStyle = {
			width: "100%",
			display: "inline-block",
			marginTop: "4px",
			fontSize: "12px",
			fontWeight: "400",
			color: "rgba(0, 0, 0, 0.87)"
		};

		const activeStyle = {
			fontSize: "16px",
			padding: "2px",
			borderRadius: "50%",
			color: this.props.item.active ? "rgb(70, 180, 175)" : "rgba(0, 0, 0, 0.52)",
			border: "1px solid"
		};

		const name = this.props.item.name || this.props.item.email;

		return (
			<li style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.item.picture !== undefined ? (
					<img style={imageStyle} src={this.props.item.picture} alt={name.substr(0, 1)} />
				) : (
					<div style={imageTextStyle}>{name.toUpperCase().substr(0, 1)}</div>
				)}
				<div style={nameStyle}>{this.props.item.email || this.props.item.name}</div>
				<div style={createdStyle}>
					<i style={activeStyle} className="material-icons">
						check
					</i>
					<span style={labelStyle}>{this.props.item.active ? "Active" : "Inactive"}</span>
				</div>
				<div style={roleStyle}>{this.props.item.role.charAt(0).toUpperCase() + this.props.item.role.slice(1)}</div>
			</li>
		);
	}
}

export default UserResult;
