import React from "react";

// Actions
import ClientActions from "../../../actions/ClientActions";
import Dropdown from "../../_common/forms/Dropdown.react";

// Form items
import Input from "../../_common/forms/Input.react";
import Tokenizer from "../../_common/forms/Tokenizer.react";
import Button from "../../_common/buttons/Button.react";

const channelOptions = [
	{
		text: "Email",
		value: "email"
	},
	{
		text: "API",
		value: "api"
	}
];

const primaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "8px 0px"
};

const typeStyle = {
	width: "200px",
	marginBottom: "0px",
	marginRight: "16px",
	verticalAlign: "top"
};

const leftColumnStyle = {
	display: "inline-block",
	width: "calc(100% - 70px)",
	verticalAlign: "middle"
};

const closeIconStyle = {
	marginLeft: "16px",
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer"
};

const filterContainerStyle = {
	width: "calc(100% - 16px)",
	padding: "0px 0px 0px 16px"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const filterButtonContainerStyle = {
	margin: "16px 0px",
	width: "100%",
	textAlign: "center"
};

class Channel extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onRemoveRule = this._onRemoveRule.bind(this);

		this.state = {
			type: this.props.item.type || "",
			label: this.props.item.label || "",
			length: this.props.item.length || "",
			emails: this.props.item.emails || []
		};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		console.log(field);
		console.log(location);
		console.log(value);
		ClientActions.setField(field, location, value, true);
	}

	_onRemoveRule() {
		if (this.props.totalRules === 1) {
			ClientActions.remove(this.props.location);
		} else {
			ClientActions.remove(this.props.location.concat(this.props.item.id));
		}
	}

	render() {
		var location = this.props.location.concat(this.props.item.id);

		return (
			<div style={primaryStyle}>
				<div style={leftColumnStyle}>
					<Dropdown
						id={"channel-type-" + this.props.parentIndex + "-" + this.props.index}
						style={typeStyle}
						value={this.state.type}
						field="type"
						location={location}
						label="Type"
						items={channelOptions}
						updateFn={this._onBlur}
					/>
					<Input
						id={"channel-label-" + this.props.parentIndex + "-" + this.props.index}
						style={typeStyle}
						value={this.state.label}
						field="label"
						location={location}
						label="Label"
						updateFn={this._updateFn}
						onBlur={this._onBlur}
					/>
					<Input
						id={"channel-length-" + this.props.parentIndex + "-" + this.props.index}
						style={typeStyle}
						value={this.state.length}
						field="length"
						location={location}
						type="numeric"
						label="Maximum length"
						updateFn={this._updateFn}
						onBlur={this._onBlur}
					/>
					{this.state.type === "email" ? (
						<Tokenizer
							id={"channel-emails-" + this.props.parentIndex + "-" + this.props.index}
							style={typeStyle}
							value={this.state.emails}
							field="emails"
							location={location}
							label="Emails"
							updateFn={this._onBlur}
						/>
					) : (
						""
					)}
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemoveRule}>
					close
				</i>
			</div>
		);
	}
}

class Channels extends React.Component {
	constructor(props) {
		super(props);
		this._onAddNewChannel = this._onAddNewChannel.bind(this);
	}

	_onAddNewChannel() {
		ClientActions.add("channels", [this.props.type], { type: "email", label: "Email", length: "" });
	}

	render() {
		var channels =
			[null, undefined].indexOf(this.props.component) === -1 && this.props.component.channels
				? this.props.component.channels
				: [];

		return (
			<div style={filterContainerStyle}>
				{channels.map((item, index, arr) => (
					<Channel
						key={this.props.integration.id + "-" + index}
						item={item}
						index={index}
						last={index === arr.length - 1}
						location={[this.props.type]}
						type={this.props.type}
					/>
				))}
				{channels.length === 0 ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>There are currently no channels</div>
						<Button text="Add New Channel" onClick={this._onAddNewChannel} />
					</div>
				) : (
					<div style={filterButtonContainerStyle}>
						<Button text="Add New Channel" onClick={this._onAddNewChannel} />
					</div>
				)}
			</div>
		);
	}
}

export default Channels;
