import React from "react";
import brace from "brace";
import AceEditor from "react-ace";

import "brace/mode/jsx";
import "brace/mode/json";
import "brace/theme/monokai";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Styles
const mainBodyStyle = {
	width: "100%",
	padding: "16px 0px",
	display: "inline-block",
	verticalAlign: "top",
	textAlign: "center"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "left",
	maxWidth: "1100px"
};

const rowStyle = {
	width: "calc(100% + 16px)",
	textAlign: "right",
	margin: "0px -24px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const topRowStyle = {
	width: "calc(100% + 48px)",
	textAlign: "right",
	margin: "-24px -24px 0px -24px",
	borderBottom: "1px solid lightgrey",
	padding: "0px"
};

const buttonStyle = {
	marginLeft: "8px"
};

class JavascriptOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { code: this.props.item.javascript || "" };
	}

	_updateFn(value) {
		console.log(value);
		this.setState({ code: value });
	}

	_onBlur(value) {
		this.props.actions.setField("javascript", [this.props.item.id], this.state.code);
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<div style={topRowStyle}>
							<H1 text="Setup Javascript" textAlign="center" />
						</div>
						<div style={mainBodyStyle}>
							<AceEditor
								mode="jsx"
								theme="monokai"
								name="question-type-ace-react"
								onChange={this._updateFn}
								onBlur={this._onBlur}
								fontSize={12}
								height="400px"
								width="100%"
								wrapEnabled={true}
								showPrintMargin={true}
								showGutter={true}
								highlightActiveLine={true}
								value={this.state.code}
								style={{
									zIndex: 0
								}}
								setOptions={{
									enableBasicAutocompletion: true,
									enableLiveAutocompletion: true,
									enableSnippets: false,
									showLineNumbers: true,
									tabSize: 4
								}}
							/>
						</div>
						<div style={rowStyle}>
							<Button text="Save" style={buttonStyle} onClick={this._onClose} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default JavascriptOverlay;
