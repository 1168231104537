import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Dropdown from "../forms/Dropdown.react";

const options = [
	{
		text: "Submit as open",
		value: "open"
	},
	{
		text: "Submit as pending",
		value: "pending"
	},
	{
		text: "Submit as on hold",
		value: "hold"
	},
	{
		text: "Submit as solved",
		value: "solved"
	}
];

const submitStyle = {
	width: "300px",
	display: "inline-block"
};

const checkingStyle = {
	width: "100%",
	padding: "0px 0px 16px",
	textAlign: "center"
};

const rowStyle = {
	width: "calc(100% - 16px)",
	display: "inline-block",
	textAlign: "center",
	padding: "0px 8px"
};

class EmailSetStatus extends React.Component {
	constructor(props) {
		super(props);
		this._onOpenReply = this._onOpenReply.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { nextPath: 0 };
	}

	_onOpenReply() {
		UIActions.showOverlay("reply editor", this.props.item);
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
				noNextButton={true}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={rowStyle}>
						<Dropdown
							id={"email-status-dropdown-" + this.props.item.id}
							field="external_status"
							label="Submit As"
							location={[this.props.item.id]}
							value={this.props.item.external_status || ""}
							items={options}
							updateFn={this._onBlur}
							style={submitStyle}
						/>
					</div>
				) : (
					<div>
						<Loading height="200px" />
						<div style={checkingStyle}>Setting ticket status...</div>
					</div>
				)}
			</Card>
		);
	}
}

export default EmailSetStatus;
