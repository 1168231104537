import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import TicketField from "../../tickets/TicketField.react";

// Form items
import Dropdown from "../forms/Dropdown.react";
import InputMultiline from "../forms/InputMultiline.react";

// Functions
import * as client_components from "../../../functions/client_components";

const questionStyle = {
    marginBottom: "0px",
};

function get_ticket_fields(client) {
    var ticketFields = client_components.find(client.components || [], "zendesk");
    if (ticketFields !== null && ticketFields.ticket_fields !== undefined) ticketFields = ticketFields.ticket_fields;
    return ticketFields;
}

function get_options(fields) {
    var options = [];
    for (var i = 0; i < fields.length; i++) {
        options.push({
            text: fields[i].raw_title,
            value: fields[i].id,
        });
    }

    return options;
}

function get_target(fields, value) {
    if (fields === null) return null;

    for (var i = 0; i < fields.length; i++) {
        if (fields[i].id === value) {
            return fields[i];
        }
    }

    return null;
}

class ZendeskField extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._updateZendeskField = this._updateZendeskField.bind(this);
        this._updateFnSystem = this._updateFnSystem.bind(this);

        var fields = get_ticket_fields(this.props.client);

        this.state = {
            valid: [undefined, null, ""].indexOf(this.props.item.value) === -1,
            nextPath: [undefined, null, ""].indexOf(this.props.item.value) === -1 ? 0 : 1,
            fields: fields,
            options: get_options(fields || []),
            target: get_target(fields, this.props.item.field),
            value: this.props.item.value,
            errors: [],
            text: this.props.item.prompts[0].text || "",
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.target === null) {
            var fields = get_ticket_fields(nextProps.client);
            this.setState({ target: get_target(fields, nextProps.item.field) });
        }
    }

    _updateFn(field, location, value) {
        if (field === "field") {
            var target = get_target(this.state.fields, value);

            this.setState({ [field]: value, target, value: undefined });
            if (target !== undefined) WorkflowActions.setField("concept", location, target.raw_title, false);
            WorkflowActions.setField("value", location, undefined, false);
        } else {
            this.setState({ [field]: value });
        }

        WorkflowActions.setField(field, location, value, true);
    }

    _updateZendeskField(obj) {
        this.setState({ value: obj.value, valid: true, nextPath: 0 });

        if (this.props.agentMode) WorkflowActions.setFieldResponse("value", [this.props.response.id], obj.value, true);
        else WorkflowActions.setField("value", [this.props.item.id], obj.value, true);
    }

    _updateFnSystem(field, location, value) {
        this.setState({ value: value, valid: true, nextPath: 0 });

        if (this.props.agentMode) WorkflowActions.setFieldResponse("value", [this.props.response.id], value, true);
        else WorkflowActions.setField("value", [this.props.item.id], value, true);
    }

    render() {
        var reply;
        if ([null, undefined, ""].indexOf(this.state.value) === -1 && [null, undefined, ""].indexOf(this.state.target) === -1) {
            reply = {};
            if (
                ["tagger", "text", "textarea", "integer", "decimal", "regexp", "checkbox", "date", "multiselect"].indexOf(
                    this.state.target.type
                ) > -1
            ) {
                reply.custom_fields = [{ id: this.state.target.id, value: this.state.value }];
            } else if (this.state.target.type === "tickettype") reply.external_type = this.state.value;
            else if (this.state.target.type === "priority") reply.external_priority = this.state.value;
        }

        return (
            <Card
                id={this.props.item.id}
                item={this.props.item}
                picture={this.props.client !== undefined ? this.props.client.picture : ""}
                name={this.props.client !== undefined ? this.props.client.name : ""}
                first={this.props.first}
                onMoveView={this.props.onMoveView}
                targetFocus={this.props.targetFocus}
                agentMode={this.props.agentMode}
                onCardConnect={this.props.onCardConnect}
                connecting={this.props.connecting}
                valid={this.state.valid}
                nextPath={this.state.nextPath}
                noNextButton={
                    [null, undefined].indexOf(this.state.target) === -1 &&
                    this.state.target.required &&
                    [null, undefined, ""].indexOf(this.state.value) > -1
                        ? true
                        : false
                }
            >
                <InputMultiline
                    id={this.props.item.prompts[0].id}
                    placeholder="Enter question text here..."
                    value={this.state.text}
                    field="text"
                    location={[this.props.item.id, this.props.item.prompts[0].id]}
                    onBlur={this._updateFn}
                    noLine={true}
                    style={questionStyle}
                    readOnly={this.props.agentMode}
                />
                {!this.props.agentMode ? (
                    <Dropdown
                        id={"field-" + this.props.item.id}
                        items={this.state.options}
                        field={"field"}
                        location={[this.props.item.id]}
                        updateFn={this._updateFn}
                        value={this.props.item.field}
                        label="Field"
                    />
                ) : (
                    ""
                )}
                {[undefined, null].indexOf(this.state.target) === -1 ? (
                    <TicketField
                        item={this.state.target}
                        key={this.props.item.id + "-ticketfield"}
                        ticket_id={this.props.item.id}
                        error={this.state.errors.indexOf(this.state.target.id) > -1}
                        updateFn={this._updateZendeskField}
                        updateFnSystem={this._updateFnSystem}
                        reply={reply}
                        skipDisplayCheck={true}
                    />
                ) : (
                    ""
                )}
            </Card>
        );
    }
}

export default ZendeskField;
