export default {
	name: "Generic Login",
	type: "generic login",
	category: "Other",
	icon: "open_in_browser",
	apiFields: [
		{
			field: "username",
			label: "Username",
			type: "text"
		},
		{
			field: "password",
			label: "Password",
			type: "text"
		}
	],
	cardFields: [
		{
			field: "blank",
			label: "blank",
			type: "text"
		}
	]
};
