import React from "react";

import integrations from "../../integrations";
import crms from "../../crms";

// Actions
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import SettingsRow from "../_common/SettingsRow.react";

// Form items
import Input from "../_common/forms/Input.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Button from "../_common/buttons/Button.react";

// Functions
import * as client_components from "../../functions/client_components";
import UIActions from "../../actions/UIActions";

// Sets icon finder function
var get_icon = type => {
	for (var i = 0; i < integrations.length; i++) {
		if (integrations[i].type === type) return integrations[i].icon;
	}

	return "build";
};

const delayStyle = {
	maxWidth: "300px",
	marginBottom: "0px"
};

const subsectionNameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 90px)"
};

const arrowIconStyle = {
	display: "inline-block",
	verticalAlign: "middle"
};

class Row extends React.Component {
	render() {
		const rowStyle = {
			borderBottom: "1px solid lightgrey",
			padding: "16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative",
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		return <div style={rowStyle}>{this.props.children}</div>;
	}
}

class Icon extends React.Component {
	render() {
		const subsectionIconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 24px 0px 8px",
			color: this.props.active ? "rgb(70, 180, 175)" : "grey"
		};

		return (
			<i style={subsectionIconStyle} className="material-icons">
				{this.props.icon}
			</i>
		);
	}
}

class SubsectionSelector extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	_onClick() {
		UIActions.show(this.props.view, null, this.props.data);
		//this.props.updateFn(this.props.subsection, this.props.integration);
	}

	render() {
		const color = this.props.color || "transparent";

		const primaryStyle = {
			borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
			padding: "16px",
			borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		const subsectionIconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 24px 0px 8px",
			color: this.props.active ? "rgb(70, 180, 175)" : "grey"
		};

		return (
			<div
				index={this.props.index}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={primaryStyle}
				onClick={this._onClick}
			>
				<i style={subsectionIconStyle} className="material-icons">
					{this.props.icon}
				</i>
				<div style={subsectionNameStyle}>{this.props.text}</div>
				<i style={arrowIconStyle} className="material-icons">
					keyboard_arrow_right
				</i>
			</div>
		);
	}
}

const logoLabelStyle = {
	height: "30px",
	margin: "0px 0px 24px 0px",
	display: "inline-block"
};

const refreshRow = {
	width: "calc(100% - 32px)",
	padding: "32px 16px"
};

const refreshDropdownStyle = {
	width: "200px",
	marginBottom: "0px"
};

const describeLabelStyle = {
	width: "calc(100% - 400px)",
	display: "inline-block",
	verticalAlign: "middle",
	marginLeft: "16px"
};

const integrationsContainerStyle = {
	width: "100%",
	borderTop: "1px solid lightgrey",
	display: "inline-block",
	marginTop: "16px"
};

const simpleContainerStyle = {
	marginTop: "16px",
	width: "100%",
	display: "inline-block",
	position: "relative"
};

const addButtonRowStyle = {
	width: "100%",
	textAlign: "center",
	padding: "16px 0px"
};

const deleteIntegrationSimpleButtonStyle = {
	position: "absolute",
	top: "0px",
	right: "16px"
};

const ticketSourceDropdownStyle = {
	width: "200px"
};

// Creates CRM list
var crmList = [];
var q;
for (q = 0; q < crms.length; q++) crmList.push(crms[q].type);

var ticketSourceFields = [];
for (q = 0; q < crms.length; q++) ticketSourceFields.push({ text: crms[q].name, value: crms[q].type });

const activeButtonStyle = {
	marginLeft: "16px"
};

class ActivateButton extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		ClientActions.checkIntegration([this.props.integration.id], true);
	}

	render() {
		return (
			<Button
				style={activeButtonStyle}
				text={this.props.integration.valid ? "Refresh Integration" : "Activate Integration"}
				onClick={this._onClick}
				loading={
					this.props.status !== undefined &&
					this.props.status.indexOf("checking integration " + this.props.integration.id) > -1
				}
				type="flat"
			/>
		);
	}
}

class DeleteButton extends React.Component {
	constructor(props) {
		super(props);
		this._onDeleteIntegration = this._onDeleteIntegration.bind(this);
		this._onDeleteIntegrationConfirmed = this._onDeleteIntegrationConfirmed.bind(this);
	}

	_onDeleteIntegration() {
		UIActions.showConfirmation({
			title: "Delete integration",
			text: "Are you sure you want to delete this integration?",
			buttonText: "Delete integration",
			callback: this._onDeleteIntegrationConfirmed
		});
	}

	_onDeleteIntegrationConfirmed() {
		ClientActions.removeIntegration([this.props.id]);
	}

	render() {
		return (
			<Button
				text="Delete integration"
				onClick={this._onDeleteIntegration}
				style={deleteIntegrationSimpleButtonStyle}
				type="flat"
			/>
		);
	}
}

var crm_validity_checker = (rules, integration, component) => {
	var target;
	for (var i = 0; i < rules.length; i++) {
		if (rules[i].target === "integration") target = integration;
		else if (rules[i].target === "component") target = component;
		else target = {};

		if (rules[i].type === "arrayCheck" && !Array.isArray(target[rules[i].field])) return false;
		else if (rules[i].type === "length-greater-than" && target[rules[i].field].length <= rules[i].value) return false;
		else if (rules[i].type === "exists" && [null, undefined, ""].indexOf(target[rules[i].field]) > -1) return false;
		else if (rules[i].type === undefined && target[rules[i].field] !== rules[i].value) return false;
	}

	return true;
};

const podStyle = {
	width: "200px",
	marginBottom: "0px"
};

const groupOptions = [
	{
		text: "Group 1",
		value: 1
	},
	{
		text: "Group 2",
		value: 2
	},
	{
		text: "Group 3",
		value: 3
	},
	{
		text: "Group 4",
		value: 4
	}
];

class ClientSettings extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onSubsectionSelect = this._onSubsectionSelect.bind(this);
		this._onCloseSubsection = this._onCloseSubsection.bind(this);
		this._onRefreshField = this._onRefreshField.bind(this);
		this._onDeleteIntegration = this._onDeleteIntegration.bind(this);
		this._onDeleteIntegrationConfirmed = this._onDeleteIntegrationConfirmed.bind(this);
		this._onGroupChange = this._onGroupChange.bind(this);

		this.state = { subsection: "", ticketDelay: this.props.item.ticketDelay || "" };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(nextProps) {
		var refreshing = nextProps.status.indexOf("refreshing ticket source field") > -1 ? true : false;
		this.setState({ refreshing });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value, validate: false });
		ClientActions.setField(field, location, value, true);
	}

	_onSubsectionSelect(subsection, subsectionIntegration) {
		if (subsection === undefined || typeof subsection !== "string") {
			this.setState({ subsection: "", subsectionIntegration });
			var offset = this.state.pageOffset || 0;
			setTimeout(function() {
				window.scrollTo(0, offset);
			}, 10);
		} else {
			this.setState({ subsection, subsectionIntegration, pageOffset: window.pageYOffset });
			window.scrollTo(0, 0);
		}
	}

	_onCloseSubsection() {
		this.setState({ subsection: "", subsectionIntegration: null });
	}

	_onRefreshField(field, location, value) {
		ClientActions.ticketSourceSectionRefresh(location, value);
		this.setState({ refreshing: true });
	}

	_onAddIntegrationClick() {
		UIActions.showOverlay("add integration");
	}

	_onAddSlack() {
		ClientActions.add("integrations", [], { type: "slack", channel: "" });
	}

	_onAddTicketSource(field, location, value) {
		ClientActions.add("integrations", [], { type: value });
	}

	_onDeleteIntegration() {
		UIActions.showConfirmation({
			title: "Delete integration",
			text: "Are you sure you want to delete this integration?",
			buttonText: "Delete integration",
			callback: this._onDeleteIntegrationConfirmed
		});
	}

	_onDeleteIntegrationConfirmed() {
		this.setState({ subsection: "" });
		ClientActions.removeIntegration([this.state.subsectionIntegration.id]);
		this._onSubsectionSelect();
	}

	_onGroupChange(field, location, value) {
		this.setState({ [field]: value, validate: false });
		ClientActions.groupChange(field, location, value);
	}

	render() {
		var ticketSources = [];
		var crmScaffold = { subsections: [] };
		var slackIntegration, crmIntegration, crmComponent, i;
		var otherIntegrations = [];
		if (this.props.item.integrations !== undefined) {
			for (i = 0; i < this.props.item.integrations.length; i++) {
				if (crmList.indexOf(this.props.item.integrations[i].type) > -1) {
					ticketSources.push(this.props.item.integrations[i].type);
					crmIntegration = this.props.item.integrations[i];
					crmComponent = client_components.find(this.props.item.components || [], this.props.item.integrations[i].type);
				} else if (this.props.item.integrations[i].type === "slack") slackIntegration = this.props.item.integrations[i];
				else otherIntegrations.push(this.props.item.integrations[i]);
			}

			if (crmIntegration !== undefined) {
				for (i = 0; i < crms.length; i++) {
					if (crms[i].type === crmIntegration.type) {
						crmScaffold = crms[i];
						break;
					}
				}
			}
		}

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="TICKETS">
						{crmScaffold.logo !== undefined ? (
							<div style={simpleContainerStyle}>
								<img src={crmScaffold.logo} style={logoLabelStyle} alt={crmScaffold.name} />
								<DeleteButton id={crmIntegration.id} />
								{crmScaffold.subsections.map((item, index) => (
									<SubsectionSelector
										key={"crm-" + item.name}
										text={item.name}
										icon={item.icon}
										view="clients_generic_crm"
										data={{ integration: crmIntegration, component: crmComponent, scaffold: item }}
										index={index}
										active={crm_validity_checker(item.activeRules || [], crmIntegration || {}, crmComponent || {})}
									/>
								))}

								<div style={refreshRow}>
									<Dropdown
										id={"refresh-" + crmIntegration.id}
										items={crmScaffold.refreshableFields}
										location={[crmIntegration.id]}
										button={true}
										updateFn={this._onRefreshField}
										loading={this.state.refreshing}
										style={refreshDropdownStyle}
										text={"Refresh"}
									/>
									<ActivateButton integration={crmIntegration} status={this.props.status} />
								</div>
							</div>
						) : (
							""
						)}
						{crmIntegration === undefined ? (
							<div style={addButtonRowStyle}>
								<Dropdown
									id={"add-ticket-source"}
									field="type"
									text="Add Ticket Source"
									items={ticketSourceFields}
									updateFn={this._onAddTicketSource}
									button={true}
									style={ticketSourceDropdownStyle}
								/>
							</div>
						) : (
							""
						)}
					</SectionCard>
					<SectionCard title="GROUP">
						<SettingsRow index={0} icon="group_work" active={true}>
							<div>Client group</div>
							<Dropdown
								id={"client-group"}
								style={podStyle}
								value={this.props.item.group}
								field="group"
								items={groupOptions}
								updateFn={this._onGroupChange}
								thin={true}
							/>
						</SettingsRow>
					</SectionCard>
					<SectionCard title="QUEUE ORDERING">
						<div style={simpleContainerStyle}>
							<Row index={0}>
								<Icon icon="timer" active={this.state.ticketDelay !== undefined && this.state.ticketDelay.length > 0} />
								<Input
									id="ticket-delay"
									placeholder="Ticket Delay (in minutes)"
									field="ticketDelay"
									updateFn={this._updateFn}
									onBlur={this._onBlur}
									value={this.state.ticketDelay || ""}
									type={"number"}
									style={delayStyle}
									colored={true}
									thin={true}
								/>
								<div
									style={describeLabelStyle}
									dangerouslySetInnerHTML={{
										__html:
											this.state.ticketDelay !== undefined && this.state.ticketDelay.length > 0
												? "Tickets enter queue <b>" + this.state.ticketDelay + "</b> minutes after being created."
												: ""
									}}
								/>
							</Row>
							<SubsectionSelector
								text="Queue order rules"
								icon="reorder"
								subsection="queue order rules"
								view="clients_queue_order"
								index={1}
								updateFn={this._onSubsectionSelect}
								active={Array.isArray(this.props.item.queueRules) && this.props.item.queueRules.length > 0}
							/>
						</div>
					</SectionCard>
					<SectionCard title="COMMUNICATION">
						{slackIntegration !== undefined ? (
							<div style={simpleContainerStyle}>
								<img src={"/images/logos/slack_logo.png"} style={logoLabelStyle} alt="Slack" />
								<DeleteButton id={slackIntegration.id} />
								<Row index={0}>
									<Icon
										icon="chat"
										active={[undefined, ""].indexOf(this.state.channel) === -1 || slackIntegration.channel !== ""}
									/>
									<Input
										id="slack-channel"
										placeholder="Slack Channel"
										field="channel"
										updateFn={this._updateFn}
										onBlur={this._onBlur}
										value={this.state.channel || slackIntegration.channel}
										location={[slackIntegration.id]}
										style={delayStyle}
										colored={true}
										thin={true}
									/>
									<div
										style={describeLabelStyle}
										dangerouslySetInnerHTML={{
											__html:
												[undefined, ""].indexOf(this.state.channel) === -1 || slackIntegration.channel !== ""
													? "Automated messages will go to HiOperator slack channel <b>" +
													  (this.state.channel || slackIntegration.channel) +
													  "</b>."
													: ""
										}}
									/>
								</Row>
							</div>
						) : (
							<div style={addButtonRowStyle}>
								<Button text="Add Slack" onClick={this._onAddSlack} />
							</div>
						)}
					</SectionCard>
					<SectionCard title="INTEGRATIONS">
						{otherIntegrations.length > 0 ? (
							<div style={integrationsContainerStyle}>
								{otherIntegrations.map((item, index, arr) => {
									if (["zendesk", "front", "slack"].indexOf(item.type) === -1)
										return (
											<SubsectionSelector
												key={"integration-" + item.id}
												text={item.type.charAt(0).toUpperCase() + item.type.substr(1, 1000)}
												icon={get_icon(item.type)}
												view="clients_generic_integration"
												data={{ item: item }}
												index={index + 1}
												updateFn={this._onSubsectionSelect}
												active={item.active || false}
											/>
										);

									return "";
								})}
							</div>
						) : (
							""
						)}
						<div style={addButtonRowStyle}>
							<Button text="Add Integration" onClick={this._onAddIntegrationClick} />
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}
export default ClientSettings;
