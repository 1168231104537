export default {
	name: "Returnly",
	type: "returnly",
	category: "Store Fronts",
	icon: "backspace",
	apiFields: [
		{
			field: "apiKey",
			label: "API Key",
			type: "text"
		},
		{
			field: "importsToZD",
			label: "Automate imports into Zendesk",
			type: "toggle",
			actions: [
				{
					type: "time-based automation",
					value: "returnly to zendesk"
				}
			]
		}
	],
	cardFields: []
};
