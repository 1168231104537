import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timelinePlugin from "@fullcalendar/timeline";

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timeline/main.css";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import Card from "../_common/Card.react";
import Dropdown from "../_common/forms/Dropdown.react";

// Functions
import api from "../../functions/api";
import * as list from "../../functions/list";

const cardStyle = {
    marginTop: "16px",
    padding: "16px"
};

const selectViewStyle = {
    width: "400px"
};

var make_dates = date => {
    var cleanDate = new Date(date.getMonth() + 1 + "/1/" + date.getFullYear());
    var start = new Date(cleanDate.setMonth(cleanDate.getMonth() - 1));
    var end = new Date(cleanDate.setMonth(cleanDate.getMonth() + 3));

    return { start, end };
};

var create_dummies = events => {
    var dummies = [];
    events.forEach((item, index) => {
        if (index === 0 && item.start.getHours() > 8) {
            dummies.push({
                start: new Date(item.start.toLocaleDateString() + " 8:00"),
                end: item.start,
                backgroundColor: "lightgrey",
                groupId: item.userId,
                title: ""
            });
        } else if (
            item.start.getHours() > 8 &&
            (events[index - 1].start.toLocaleDateString() !== item.start.toLocaleDateString() ||
                events[index - 1].userId !== item.userId)
        ) {
            dummies.push({
                start: new Date(item.start.toLocaleDateString() + " 8:00"),
                end: item.start,
                backgroundColor: "lightgrey",
                groupId: item.userId,
                title: ""
            });
        } else if (
            item.start.getHours() > 8 &&
            events[index - 1].start.toLocaleDateString() === item.start.toLocaleDateString() &&
            events[index - 1].userId === item.userId
        ) {
            dummies.push({
                start: events[index - 1].end,
                end: item.start,
                backgroundColor: "lightgrey",
                groupId: item.userId,
                title: ""
            });
        }

        if (index === events.length - 1 && item.start.getHours() < 23) {
            dummies.push({
                start: item.end,
                end: new Date(item.start.toLocaleDateString() + " 23:00"),
                backgroundColor: "lightgrey",
                groupId: item.userId,
                title: ""
            });
        } else if (
            item.start.getHours() < 23 &&
            events[index + 1].start.getDate() !== item.end.getDate() &&
            events[index + 1].userId === item.userId
        ) {
            dummies.push({
                start: item.end,
                end: new Date(item.end.toLocaleDateString() + " 23:00"),
                backgroundColor: "lightgrey",
                groupId: item.userId,
                title: ""
            });
        }
    });

    return dummies;
};

var get_title = item => {
    if (item.type === "shift") return "Shift for " + item.name;
    else if (item.type === "worked") return "Worked for " + item.name;

    return "";
};

var get_color = item => {
    if (item.type === "shift") return "rgb(70, 180, 175)";
    else if (item.type === "worked") return "orange";

    return "lightgrey";
};

class SchedulePage extends React.Component {
    constructor(props) {
        super(props);
        this._onGetSchedule = this._onGetSchedule.bind(this);
        this._onGetScheduleCompleted = this._onGetScheduleCompleted.bind(this);
        this._onDateClick = this._onDateClick.bind(this);
        this._onEventClick = this._onEventClick.bind(this);
        this._onAddEvent = this._onAddEvent.bind(this);
        this._onDatesRender = this._onDatesRender.bind(this);
        this._onChangeView = this._onChangeView.bind(this);
        this._onCalendarView = this._onCalendarView.bind(this);
        this.calendarRef = React.createRef();

        var { start, end } = make_dates(new Date());
        this.state = {
            calendarEvents: [],
            dummyEvents: [],
            calendarWeekends: true,
            calendarView: "dayGridMonth",
            start,
            end,
            view: "all"
        };
    }

    componentDidMount() {
        this._onGetSchedule();
        var buttons = document.getElementsByClassName("fc-button-primary");
        console.log(buttons);
        console.log("GOT BUTTONS");
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener("click", this._onCalendarView);
        }
        /*
		buttons.forEach(item => {
			item
        });
        */
    }

    _onGetSchedule(view) {
        var obj = {
            start: this.state.start,
            end: this.state.end,
            view: view || this.state.view
        };

        api(
            "post",
            "/users/schedule",
            obj,
            this._onGetScheduleCompleted,
            this._onGetScheduleCompleted,
            {},
            "getting schedule"
        );
    }

    _onGetScheduleCompleted(results) {
        var allEvents = results.scheduled.map(item => {
            item.type = "shift";
            item.name = this.props.gurus.find(g => g.id === item.userId).name;
            item.start = new Date(item.start);
            item.end = new Date(item.end);
            return item;
        });

        var dummyEvents = [];
        if (this.state.view === "all" || this.state.view.indexOf("group") > -1) {
            allEvents = list.order(allEvents, "start oldest");
            allEvents = list.order(allEvents, "userId asc");
            allEvents = list.order(allEvents, "type asc");

            dummyEvents = create_dummies(allEvents);
        } else {
            allEvents = allEvents.concat(
                results.timesheets.map(item => {
                    item.type = "worked";
                    item.start = new Date(item.start);
                    item.end = new Date(item.end);
                    item.name = this.props.gurus.find(g => g.id === item.userId).name;
                    return item;
                })
            );
        }

        var calendarEvents = allEvents.map(item => {
            return {
                id: item.id,
                groupId: item.userId,
                title: get_title(item),
                start: new Date(item.start),
                end: new Date(item.end),
                backgroundColor: get_color(item),
                order: "title,start"
            };
        });

        this.setState({ calendarEvents, allEvents, dummyEvents });
    }

    _onDateClick(arg) {
        var calendarRef = this.calendarRef.current.getApi();
        calendarRef.changeView("timeGridDay", arg.dateStr);
    }

    _onEventClick(arg) {
        arg = arg.event;

        if (this.props.UserStore.canEditSchedule || this.props.UserStore.role === "admin") {
            var event = this.state.allEvents.find(item => item.id === arg.id);
            UIActions.showOverlay("schedule edit", {
                event,
                refetch: this._onGetSchedule
            });
        } else {
            var calendarRef = this.calendarRef.current.getApi();
            var day = arg.start.getFullYear() + "-" + (arg.start.getMonth() + 1) + "-" + arg.start.getDate();
            calendarRef.changeView("timeGridDay", day);
        }
    }

    _onAddEvent(field, location, value) {
        var event = {
            userId: this.props.item.id,
            type: value
        };

        UIActions.showOverlay("schedule edit", {
            event,
            refetch: this._onGetSchedule
        });
    }

    _onDatesRender(info) {
        var { start, end } = make_dates(new Date(info.view.currentStart));
        this.setState({ start, end });
        this._onGetSchedule();
    }

    _onChangeView(field, location, value) {
        this.setState({ view: value });
        this._onGetSchedule(value);
    }

    _onCalendarView(event) {
        console.log("CURRENT VIEW IS ");
        if (event.srcElement === undefined) return;

        console.log(event.srcElement.className);
        var myClass = event.srcElement.className;
        if (myClass.indexOf("dayGridMonth") > -1) this.setState({ calendarView: "dayGridMonth" });
        else if (myClass.indexOf("timeGridWeek") > -1) this.setState({ calendarView: "timeGridWeek" });
        else if (myClass.indexOf("timeGridDay") > -1) this.setState({ calendarView: "timeGridDay" });
        else if (myClass.indexOf("listWeek") > -1) this.setState({ calendarView: "listWeek" });
        else if (myClass.indexOf("timeline") > -1) this.setState({ calendarView: "timeline" });

        //console.log(info.view.type);
        //this.setState({ calendarView: info.view.type });
    }

    render() {
        var viewOptions = [
            {
                text: "All",
                value: "all"
            },
            {
                text: "Group 1",
                value: "group 1"
            },
            {
                text: "Group 2",
                value: "group 2"
            },
            {
                text: "Group 3",
                value: "group 3"
            },
            {
                text: "Group 4",
                value: "group 4"
            },
            {
                divider: true
            }
        ];

        this.props.gurus.forEach(item => {
            viewOptions.push({
                text: item.name,
                value: item.id
            });
        });

        return (
            <OuterScaffold spaceForHeader={true}>
                <Container maxWidth="1000px" menuSpace={true} verticalAlign="top">
                    <Card style={cardStyle}>
                        <Dropdown
                            id="create-schedule-event"
                            ref="create-schedule-event"
                            updateFn={this._onChangeView}
                            items={viewOptions}
                            value={this.state.view}
                            label="View"
                            style={selectViewStyle}
                            searchable={true}
                        />
                        <FullCalendar
                            ref={this.calendarRef}
                            defaultView="dayGridMonth"
                            header={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,timeline,listWeek"
                            }}
                            views={{
                                timeGridDay: { slotEventOverlap: false, allDaySlot: false }
                            }}
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, timelinePlugin]}
                            weekends={this.state.calendarWeekends}
                            eventOrder={"groupId,start"}
                            events={
                                ["timeline", "timeGridDay", "timeGridWeek"].indexOf(this.state.calendarView) > -1
                                    ? this.state.calendarEvents.concat(this.state.dummyEvents)
                                    : this.state.calendarEvents
                            }
                            firstDay={1}
                            datesDestroy={this._onCalendarView}
                            dateClick={this._onDateClick}
                            eventClick={this._onEventClick}
                            datesRender={this._onDatesRender}
                        />
                    </Card>
                </Container>
            </OuterScaffold>
        );
    }
}

export default SchedulePage;
