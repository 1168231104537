import React from "react";

import integrations from "../../../integrations";

// Actions
import ClientActions from "../../../actions/ClientActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import Container from "../../_common/Container.react";
import OuterScaffold from "../../_common/OuterScaffold.react";
import SectionCard from "../../_common/SectionCard.react";
import Button from "../../_common/buttons/Button.react";

// Form items
import Input from "../../_common/forms/Input.react";
import Dropdown from "../../_common/forms/Dropdown.react";
import Toggle from "../../_common/forms/Toggle.react";

// Functions
import * as displaylogic from "../../../functions/displaylogic";

const primaryStyle = {
	width: "100%",
	display: "inline-block"
};

const emailStyle = {
	width: "600px"
};

const labelStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 64px)",
	padding: "16px"
};

const deleteIntegrationButtonStyle = {
	position: "absolute",
	top: "32px",
	right: "16px"
};

class GenericField extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = { value: this.props.value || "" };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) this.setState({ value: nextProps.value });
	}

	_updateFn(field, location, value) {
		this.setState({ value });
	}

	_onBlur(field, location, value) {
		this.setState({ value });
		ClientActions.setField(field, location, value, true);

		if (this.props.item.actions !== undefined) {
			this.props.parent[field] = value;
			ClientActions.runIntegrationActions(this.props.item.actions, this.props.parent, value);
		}
	}

	render() {
		if (!displaylogic.check(this.props.item.displaylogic, this.props.parent, this.props.ticket)) return "";

		if (this.props.item.type === "dropdown")
			return (
				<Dropdown
					id={"integration-" + this.props.parent.id + "-" + this.props.item.field}
					items={this.props.item.items}
					field={this.props.item.field}
					location={this.props.location}
					updateFn={this._onBlur}
					value={this.state.value}
					label={this.props.item.label}
					style={emailStyle}
					helpText={this.props.item.helpText || ""}
				/>
			);
		else if (this.props.item.type === "text")
			return (
				<Input
					id={"integration-" + this.props.parent.id + "-" + this.props.item.field}
					style={emailStyle}
					value={this.state.value}
					field={this.props.item.field}
					location={this.props.location}
					label={this.props.item.label}
					updateFn={this._updateFn}
					onBlur={this._onBlur}
					helpText={this.props.item.helpText || ""}
				/>
			);
		else if (this.props.item.type === "toggle")
			return (
				<div style={emailStyle}>
					<div style={labelStyle}>{this.props.item.label}</div>
					<Toggle
						id={"integration-" + this.props.parent.id + "-" + this.props.item.field}
						value={this.state.value}
						field={this.props.item.field}
						location={this.props.location}
						updateFn={this._onBlur}
					/>
				</div>
			);

		return "";
	}
}

const integrationIdStyle = {
	display: "inline-block",
	marginTop: "32px",
	padding: "0px 0px",
	width: "100%",
	textAlign: "left",
	color: "grey",
	fontSize: "14px"
};

class GenericIntegration extends React.Component {
	constructor(props) {
		super(props);
		this._onDeleteIntegration = this._onDeleteIntegration.bind(this);
		this._onDeleteIntegrationConfirmed = this._onDeleteIntegrationConfirmed.bind(this);

		var myIntegration, i;

		for (i = 0; i < integrations.length; i++) {
			if (integrations[i].type === this.props.item.type) {
				myIntegration = integrations[i];
				break;
			}
		}
		var myState = { integration: myIntegration || {} };
		this.state = myState;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.state[this.state.integration.apiFields[0].field] === "")
			document
				.getElementById("integration-" + this.props.item.id + "-" + this.state.integration.apiFields[0].field)
				.focus();
	}

	_onDeleteIntegration() {
		UIActions.showConfirmation({
			title: "Delete integration",
			text: "Are you sure you want to delete this integration?",
			buttonText: "Delete integration",
			callback: this._onDeleteIntegrationConfirmed
		});
	}

	_onDeleteIntegrationConfirmed() {
		ClientActions.removeIntegration([this.props.item.id]);
		UIActions.show("clients_settings");
	}

	_onBack() {
		UIActions.show("clients_settings");
	}

	render() {
		// Finds integration
		var i;
		var integration = this.props.item;
		for (i = 0; i < this.props.client.integrations.length; i++) {
			if (this.props.client.integrations[i].id === integration.id) {
				integration = this.props.client.integrations[i];
				break;
			}
		}

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title={integration.type.toUpperCase()} onBack={this._onBack}>
						<Button
							text="Delete integration"
							onClick={this._onDeleteIntegration}
							style={deleteIntegrationButtonStyle}
							type="flat"
						/>
						<div style={primaryStyle}>
							{this.state.integration.apiFields.map((item, index) => (
								<GenericField
									key={"integration-" + integration.id + "-" + item.field}
									value={integration[item.field]}
									item={item}
									index={index}
									location={[integration.id]}
									integration={this.state.integration}
									parent={integration}
								/>
							))}
						</div>
						<div style={integrationIdStyle}>
							<b>Integration ID </b> {integration.id}
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default GenericIntegration;
