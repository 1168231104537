import React from "react";

// Headers
import H1 from "./headers/H1.react";
import H2 from "./headers/H2.react";

// Scaffolding
import Card from "./Card.react";

const backStyle = {
	fontSize: "40px",
	cursor: "pointer",
	margin: "0px 16px 0px 0px",
	verticalAlign: "middle"
};

const titleStyle = {
	verticalAlign: "middle",
	width: "calc(100% - 70px)"
};

class SectionCard extends React.Component {
	render() {
		const cardStyle = {
			width: this.props.width || "calc(100% - 48px)",
			maxWidth: this.props.maxWidth || "952px",
			padding: "16px 0px 16px 48px",
			minHeight: "200px",
			margin: this.props.margin || "8px 0px",
			textAlign: "left"
		};

		return (
			<Card id={this.props.id} style={cardStyle}>
				{this.props.onBack !== undefined ? (
					<i className="material-icons" style={backStyle} onClick={this.props.onBack}>
						arrow_back
					</i>
				) : (
					""
				)}
				<H1 text={this.props.title} style={titleStyle} />
				{this.props.subtext !== undefined ? (
					<H2 text={this.props.subtext} margin="8px 0px 16px 0px" width="calc(100% - 8px)" />
				) : (
					""
				)}
				{this.props.children}
			</Card>
		);
	}
}

export default SectionCard;
