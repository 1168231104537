import React from "react";

// USer actions
import UserActions from "../../actions/UserActions";

// Scaffolding
import OuterScaffold from "./OuterScaffold.react";
import Button from "./buttons/Button.react";

const timeStyle = {
	borderRadius: "50%",
	width: "200px",
	padding: "97.5px 15px",
	fontSize: "30px",
	display: "inline-block",
	marginBottom: "24px",
	border: "3px solid lightgrey"
};

class ClockIn extends React.Component {
	componentDidMount() {
		this.mounted = true;
		this._tick();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	_tick() {
		if (this.mounted) {
			this.setState({ now: new Date() });
			var obj = this;

			setTimeout(function() {
				obj._tick();
			}, 1000);
		}
	}

	render() {
		const primaryStyle = {
			height: "150px",
			marginBottom: "50px",
			display: "inline-block"
		};

		const containerStyle = {
			width: "100%",
			display: "inline-block",
			textAlign: "center",
			verticalAlign: "middle"
		};

		return (
			<OuterScaffold>
				<div style={containerStyle}>
					<duv style={timeStyle}>{new Date().toLocaleTimeString()}</duv>
					<div style={containerStyle}>
						<Button text="Clock in" onClick={UserActions.clockIn} />
					</div>
				</div>
			</OuterScaffold>
		);
	}
}

export default ClockIn;
