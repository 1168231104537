import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import crms from "../../crms";

// Actions
import TicketActions from "../../actions/TicketActions";
import UIActions from "../../actions/UIActions";
import UserActions from "../../actions/UserActions";

// Scaffolding
import Card from "../_common/Card.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import Cell from "../_common/Cell.react";
import Input from "../_common/forms/Input.react";

// Search
import Search from "../_common/Search.react";
import TagResult from "../_common/search/TagResult.react";

// Form items
import Dropdown from "../_common/forms/Dropdown.react";
import Tokenizer from "../_common/forms/Tokenizer.react";
import Toggle from "../_common/forms/Toggle.react";

// Email scaffold
import Email from "../_common/Email.react";

// Ticket parts
import TicketRow from "./TicketRow.react";
import TicketField from "./TicketField.react";
import TicketWorkflowRow from "./TicketWorkflowRow.react";

// Functions
import * as search from "../../functions/search";
import * as list from "../../functions/list.js";
import * as client_components from "../../functions/client_components";
import * as displaylogic from "../../functions/displaylogic";
import * as permissions from "../../functions/permissions";

const headerStyle = {
	display: "inline-block",
	borderBottom: "1px solid cadetblue",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 1)",
	padding: "8px 0px"
};

const cellTinyStyle = {
	width: "60px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellSmallStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellLargeStyle = {
	width: "300px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const currentAssignedStyle = {
	width: "calc(100% - 40px)",
	padding: "8px 16px",
	borderRadius: "4px",
	backgroundColor: "rgba(0, 0, 0, 0.12)",
	display: "inline-block",
	verticalAlign: "middle",
	marginBottom: "8px"
};

const floatingLabelStyle = {
	fontSize: "12px",
	width: "100%",
	display: "inline-block",
	color: "rgba(0, 0, 0, 0.38)",
	marginBottom: "8px",
	fontWeight: "400"
};

const descriptionStyle = {
	position: "absolute",
	top: "calc(100% + 8px)",
	fontSize: "14px",
	borderRadius: "2px",
	color: "white",
	backgroundColor: "rgb(97, 97, 97)",
	padding: "9px 16px",
	textAlign: "left",
	zIndex: "10",
	maxWidth: "calc(100% - 64px)"
};

const profileContainerStyle = {
	width: "100%",
	textAlign: "center",
	display: "inline-block"
};

const profileLogoStyle = {
	fontSize: "80px",
	color: "orange"
};

const profileNameStyle = {
	fontSize: "24px",
	fontWeight: "700",
	display: "inline-block",
	width: "100%",
	wordBreak: "break-all"
};

const profileEmailStyle = {
	fontSize: "16px",
	display: "inline-block",
	width: "100%",
	padding: "8px 0px 16px",
	borderBottom: "1px solid lightgrey",
	wordBreak: "break-all"
};

const rowStyle = {
	width: "calc(100% - 32px)",
	padding: "16px",
	borderBottom: "1px solid lightgrey",
	display: "inline-block",
	textAlign: "left"
};

class SearchRow extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onClick(this.props.item);
	}

	render() {
		const primaryStyle = {
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			padding: "16px",
			borderBottom: !this.props.last ? "1px solid rgba(0, 0, 0, 0.12)" : "",
			position: "relative"
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.item.name || this.props.item.title}
				{this.state.hover && [null, undefined, ""].indexOf(this.props.item.description) === -1 ? (
					<div
						style={descriptionStyle}
						dangerouslySetInnerHTML={{
							__html: this.props.item.description
						}}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}

function get_ticket_fields(client, ticket) {
	var componentFields = client_components.find(client.components || [], "zendesk");
	var ticketFields = [];
	var field;

	if (
		client !== undefined &&
		componentFields !== null &&
		componentFields.ticket_fields !== undefined &&
		ticket !== undefined
	) {
		componentFields = componentFields.ticket_fields;

		for (var j = 0; j < componentFields.length; j++) {
			field = componentFields[j];
			if (["subject", "description", "status", "group", "assignee"].indexOf(field.type) === -1 && field.active)
				ticketFields.push(field);
		}
	}

	return ticketFields;
}

function get_client_agents(client, ticket) {
	var componentUsers = client_components.find(client.components || [], ticket.source);
	var clientAgents = [];

	if (componentUsers !== null && componentUsers.clientAgents !== undefined) {
		componentUsers = componentUsers.clientAgents;

		for (var i = 0; i < componentUsers.length; i++) {
			clientAgents.push({
				text: componentUsers[i].name,
				value: componentUsers[i].id
			});
		}
	}

	return clientAgents;
}

var get_current_assigned = (ticket, agents) => {
	for (var i = 0; i < agents.length; i++) {
		if (String(agents[i].value) === String(ticket.external_assignee_id)) return agents[i].text;
	}

	return "";
};

var get_channel = (client, ticket) => {
	var component = client_components.find(client.components || [], ticket.source);
	if ([null, undefined, ""].indexOf(component) > -1 || component.channels === undefined) return null;

	for (var i = 0; i < component.channels.length; i++) {
		if (component.channels[i].type === ticket.via) return component.channels[i];
	}

	return null;
};

const toggleOpenStyle = {
	position: "absolute",
	right: "16px",
	top: "16px",
	cursor: "pointer"
};

const showStyleText = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 30px)"
};

const leftControlPanelStyle = {
	width: "254px",
	overflowY: "auto",
	padding: "16px 0px 32px 16px",
	display: "inline-block",
	verticalAlign: "top"
};

const centerContainerStyle = {
	width: "calc(100% - 572px)",
	padding: "16px 16px 16px 16px",
	display: "inline-block",
	verticalAlign: "top",
	textAlign: "center"
};

const rightControlPanelStyle = {
	width: "254px",
	padding: "32px 16px 0px 0px",
	display: "inline-block",
	verticalAlign: "top"
};

const controlInnerStyle = {
	display: "inline-block",
	paddingBottom: "300px"
};

const controlHeaderStyle = {
	width: "calc(100% - 16px)",
	textAlign: "center",
	fontSize: "24px",
	fontFamily: "Roboto",
	fontWeight: "900",
	padding: "32px 8px 16px"
};

const searchStyle = {
	marginBottom: "24px"
};

const controlCardStyle = {
	width: "100%"
};

const otherTicketsInnerStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px 96px 0px",
	display: "inline-block",
	minHeight: "400px"
};

const toggleRowStyle = {
	textAlign: "left",
	display: "inline-block",
	width: "calc(100% - 16px)",
	padding: "8px"
};

const tokenizerStyle = {
	marginTop: "16px"
};

const customFieldsStyle = {
	textAlign: "left",
	width: "100%",
	display: "inline-block"
};

const assigneeStyle = {
	width: "calc(100% - 16px)",
	maxWidth: "300px",
	marginBottom: "8px"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	width: "100%",
	maxWidth: "800px"
};

const emptyTextStyle = {
	fontSize: "40px",
	fontWeight: "400",
	marginBottom: "20px"
};

class Ticket extends React.Component {
	constructor(props) {
		super(props);
		this._onMacroClick = this._onMacroClick.bind(this);
		this._onWorkflowClick = this._onWorkflowClick.bind(this);
		this._onRunTriageActions = this._onRunTriageActions.bind(this);
		this._onOrder = this._onOrder.bind(this);
		this._onUpdateTokens = this._onUpdateTokens.bind(this);
		this._onSend = this._onSend.bind(this);
		this._onAssignAgent = this._onAssignAgent.bind(this);
		this._onTagSearchSelect = this._onTagSearchSelect.bind(this);
		this._onToggleOtherTickets = this._onToggleOtherTickets.bind(this);
		this._onSearchFocus = this._onSearchFocus.bind(this);
		this._onSearchBlur = this._onSearchBlur.bind(this);
		this._searchResultOver = this._searchResultOver.bind(this);
		this._searchResultOut = this._searchResultOut.bind(this);
		this._onUpdateState = this._onUpdateState.bind(this);

		this.state = {
			search: "",
			searchFocus: false,
			searchHover: false,
			showEvents: false,
			errors: [],
			workflows: search.find_items("", this.props.client !== undefined ? this.props.client.workflows : [], ["name"]),
			macros: search.find_items("", this.props.client !== undefined ? this.props.client.macros : [], ["title", "body"]),
			filter: "date newest",
			events: this.props.forManagers ? list.order(this.props.ticket.events, "date newest") : [],
			ticket_fields:
				this.props.client !== undefined ? get_ticket_fields(this.props.client, this.props.ticket || {}) : [],
			clientAgents:
				this.props.client !== undefined ? get_client_agents(this.props.client, this.props.ticket || {}) : [],
			channel: this.props.client !== undefined ? get_channel(this.props.client, this.props.ticket || {}) : {},
			permission:
				this.props.client !== undefined && this.props.permissions !== undefined
					? permissions.find(this.props.client.id, this.props.permissions)
					: {},
			loading: false,
			showOtherTickets: true,
			triageWorkflowMode: true
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(nextProps) {
		var property;
		if (this.state.filter.indexOf("duration") > -1) property = "value";

		var loading = nextProps.status.indexOf("submitting ticket") > -1;

		if (nextProps.ticket === undefined || nextProps.ticket.id === undefined) {
			this.setState({
				events: [],
				ticket_fields: [],
				clientAgents: [],
				loading
			});
		} else if (
			nextProps.client !== undefined &&
			nextProps.ticket !== undefined &&
			nextProps.ticket.id !== undefined &&
			(this.props.ticket === undefined || this.props.ticket.id === undefined)
		) {
			window.scrollTo(0, 0);
			this.setState({
				search: nextProps.ticket.search,
				workflows: search.find_items(nextProps.ticket.search || "", nextProps.client.workflows || [], ["name"]),
				macros: search.find_items(nextProps.ticket.search || "", nextProps.client.macros || [], ["title", "body"]),
				ticket_fields: get_ticket_fields(nextProps.client, nextProps.ticket),
				clientAgents: get_client_agents(nextProps.client, nextProps.ticket),
				channel: get_channel(nextProps.client, nextProps.ticket || {}),
				events: nextProps.forManagers ? list.order(nextProps.ticket.events, this.state.filter, property) : [],
				permission:
					nextProps.client !== undefined && nextProps.permissions !== undefined
						? permissions.find(nextProps.client.id, nextProps.permissions)
						: {},
				loading
			});
		} else if (
			nextProps.client !== undefined &&
			nextProps.ticket !== undefined &&
			nextProps.ticket.id !== undefined &&
			this.props.ticket.id !== nextProps.ticket.id
		) {
			window.scrollTo(0, 0);
			this.setState({
				search: nextProps.ticket.search,
				workflows: search.find_items(nextProps.ticket.search || "", nextProps.client.workflows || [], ["name"]),
				macros: search.find_items(nextProps.ticket.search || "", nextProps.client.macros || [], ["title", "body"]),
				ticket_fields: get_ticket_fields(nextProps.client, nextProps.ticket),
				clientAgents: get_client_agents(nextProps.client, nextProps.ticket),
				channel: get_channel(nextProps.client, nextProps.ticket || {}),
				events: nextProps.forManagers ? list.order(nextProps.ticket.events, this.state.filter, property) : [],
				permission:
					nextProps.client !== undefined && nextProps.permissions !== undefined
						? permissions.find(nextProps.client.id, nextProps.permissions)
						: {},
				loading
			});
		} else if (
			nextProps.client !== undefined &&
			this.props.ticket !== undefined &&
			this.state.search !== nextProps.ticket.search
		) {
			this.setState({
				search: nextProps.ticket.search,
				workflows: search.find_items(nextProps.ticket.search || "", nextProps.client.workflows || [], ["name"]),
				macros: search.find_items(nextProps.ticket.search || "", nextProps.client.macros || [], ["title", "body"]),
				permission:
					nextProps.client !== undefined && nextProps.permissions !== undefined
						? permissions.find(nextProps.client.id, nextProps.permissions)
						: {},
				loading
			});
		} else if (nextProps.client !== undefined && nextProps.ticket !== undefined) {
			this.setState({
				search: nextProps.ticket.search,
				workflows: search.find_items(nextProps.ticket.search || "", nextProps.client.workflows || [], ["name"]),
				macros: search.find_items(nextProps.ticket.search || "", nextProps.client.macros || [], ["title", "body"]),
				ticket_fields: get_ticket_fields(nextProps.client, nextProps.ticket),
				clientAgents: get_client_agents(nextProps.client, nextProps.ticket),
				channel: get_channel(nextProps.client, nextProps.ticket || {}),
				events: nextProps.forManagers ? list.order(nextProps.ticket.events, this.state.filter, property) : [],
				permission:
					nextProps.client !== undefined && nextProps.permissions !== undefined
						? permissions.find(nextProps.client.id, nextProps.permissions)
						: {},
				loading
			});
		} else if (this.state.loading !== loading) {
			this.setState({ loading });
		}
	}

	_onTagSearchSelect(value) {
		var tags = this.props.reply.tags || [];
		if (tags.indexOf(value.text) === -1) tags.push(value.text);

		TicketActions.setField("tags", undefined, tags, true, true);
	}

	_onUpdateTokens(field, location, value) {
		TicketActions.setField(field, location, value, true, true);
	}

	_onWorkflowClick(obj) {
		TicketActions.addWorkflow(obj);
		this.setState({ searchFocus: false, searchHover: false });
	}

	_onMacroClick(obj) {
		UserActions.addEvent(
			{
				clientId: this.props.client.id,
				ticketId: this.props.ticket.id,
				action: "use macro",
				status: this.props.ticket.external_status,
				start: new Date(),
				value: obj.id
			},
			0
		);

		TicketActions.setReply(obj, false, true);
		var id = this.props.ticket.id + "-response-box";
		setTimeout(function() {
			var el = document.getElementById(id);
			if ([null, undefined].indexOf(el) === -1)
				window.scroll({ top: el.getBoundingClientRect().top + window.pageYOffset - 200, left: 0, behavior: "smooth" });
		}, 100);
		this.setState({ searchFocus: false, searchHover: false });
	}

	_onRunTriageActions(actions){
		actions.forEach(item => {
			if (item.type === "apply macro") {
				var myMacro = this.props.client.macros.find(m => m.id === item.value);
				if (myMacro) this._onMacroClick(myMacro);
			} else if (item.type === "apply workflow"){
				var myWorkflow = this.props.client.workflows.find(m => m.id === item.value);
				if (myWorkflow) this._onWorkflowClick(myWorkflow);
			}
		})
		this.setState({triageWorkflowMode: false})
	}

	_onOrder(field, location, value) {
		var property;
		if (value.indexOf("duration") > -1) property = "value";

		this.setState({ filter: value, events: list.order(this.props.ticket.events, value, property) });
	}

	_onUpdate(field, location, value) {
		TicketActions.setField(field, location, value, true, true);
		if (field === "chosen-client") TicketActions.getTicket("triage");
	}

	_onAssignAgent(field, location, value) {
		TicketActions.assignAgent(value);
	}

	_onToggleOtherTickets() {
		this.setState({ showOtherTickets: this.state.showOtherTickets ? false : true });
	}

	_onSearchFocus() {
		this.setState({ searchFocus: true });
	}

	_onSearchBlur() {
		this.setState({ searchFocus: false });
	}

	_searchResultOver() {
		this.setState({ searchHover: true });
	}

	_searchResultOut() {
		this.setState({ searchHover: false });
	}

	_onUpdateState(field, location, value) {
		this.setState({ [field]: value });
	}

	_onSend(field, location, value) {
		// Validates custom fields
		var i, j;
		var errors = [];
		for (i = 0; i < this.state.ticket_fields.length; i++) {
			if (
				this.state.ticket_fields[i].required &&
				displaylogic.check(this.state.ticket_fields[i].displaylogic, this.props.reply, this.props.ticket)
			) {
				if (this.state.ticket_fields[i].type === "tickettype") {
					if (this.props.reply.external_type === undefined || this.props.reply.external_type === null)
						errors.push(this.state.ticket_fields[i].id);
				} else if (this.state.ticket_fields[i].type === "priority") {
					if (this.props.reply.external_priority === undefined || this.props.reply.external_priority === null) {
						errors.push(this.state.ticket_fields[i].id);
					}
				} else {
					for (j = 0; j < this.props.reply.custom_fields.length; j++) {
						if (
							this.props.reply.custom_fields[j].id === this.state.ticket_fields[i].id &&
							this.props.reply.custom_fields[j].value !== null &&
							this.props.reply.custom_fields[j].value !== "" &&
							this.props.reply.custom_fields[j].value !== false
						)
							break;
						else if (j === this.props.reply.custom_fields.length - 1) errors.push(this.state.ticket_fields[i].id);
					}
				}
			}
		}

		// Now checks for blacklist
		if (errors.length === 0) {
			console.log("CHECKING blacklist");
			var component = client_components.find(this.props.client.components || [], this.props.ticket.source);
			console.log(component);
			if (component.blacklist !== undefined) {
				for (i = 0; i < component.blacklist.length; i++) {
					console.log("checking " + component.blacklist[i]);
					if (this.props.reply.text.indexOf(component.blacklist[i]) > -1) {
						UIActions.addMessage(
							"Your response cannot contain the expression '" + component.blacklist[i] + "'.",
							"error"
						);
						return;
					}
				}
			}
		}

		// Checks length
		if (!isNaN(this.props.ticket.characterLimit)) {
			var newDiv = document.createElement("div");
			newDiv.innerHTML = this.props.reply.text;
			if (newDiv.innerText.length > Number(this.props.ticket.characterLimit)) {
				UIActions.addMessage(
					"Your response is too long. Maximum number of characters allowed is " + this.props.ticket.characterLimit,
					"error"
				);
				return;
			}
		}

		if (["solved", "archived"].indexOf(value) > -1 && [null, undefined, ""].indexOf(this.props.reply.text) > -1) {
			UIActions.addMessage(
				"You cannot submit a ticket as solved with no message. Please enter either a public response or internal note.",
				"error"
			);
			return;
		}

		if (errors.length === 0 || ["stay", "new", "open", "pending", "hold"].indexOf(value) > -1) {
			this.setState({ errors, loading: true });
			TicketActions.submit(value, "triage", document.location.href.indexOf("tickettriage") > -1 && value !== "stay");
		} else this.setState({ errors });
	}

	render() {
		const otherTicketsContainerStyle = {
			position: "fixed",
			right: "14px",
			bottom: "8px",
			maxHeight: "600px",
			overflowY: "auto",
			width: this.state.showOtherTickets ? "952px" : "238px",
			zIndex: "2",
			padding: "0px 0px 0px 48px",
			backgroundColor: this.state.showOtherTickets ? "white" : "lightyellow",
			boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
		};

		const otherTicketsHeaderStyle = {
			width: "calc(100% + 16px)",
			display: "inline-block",
			cursor: "pointer",
			position: "relative",
			margin: "0px 0px 0px -48px",
			fontSize: this.state.showOtherTickets ? "24px" : "16px",
			padding: "16px 16px",
			color: "white",
			backgroundColor: "purple"
		};

		const ticketExists = this.props.ticket === undefined || this.props.ticket.id === undefined ? false : true;

		// Generates hioperator assignee options
		var hioperators = [{ text: "(Unassign)", value: "unassign" }, { divider: true }];
		var i;
		if (this.props.client !== undefined && this.props.client.agents !== undefined) {
			for (i = 0; i < this.props.client.agents.length; i++) {
				hioperators.push({
					text: this.props.client.agents[i].name,
					value: this.props.client.agents[i].id
				});
			}
		}

		// Figures out workflows applied to ticket
		var workflows = [];
		if (this.props.ticket !== undefined && this.props.ticket.workflows !== undefined) {
			var myKeys = Object.keys(this.props.ticket.workflows);
			for (i = 0; i < myKeys.length; i++) {
				this.props.ticket.workflows[myKeys[i]].id = myKeys[i];
				workflows.push(this.props.ticket.workflows[myKeys[i]]);
			}
		}

		// Show search
		const showSearch = this.state.searchFocus || this.state.searchHover;

		// Find scaffold
		var crmScaffold = {};
		if (this.props.ticket !== undefined && this.props.ticket.source !== undefined) {
			for (i = 0; i < crms.length; i++) {
				if (crms[i].type === this.props.ticket.source) {
					crmScaffold = crms[i];
					break;
				}
			}
		}

		console.log("BUILDINGING TICKET");
		console.log(crmScaffold);
		return (
			<OuterScaffold spaceForHeader={true} align={ticketExists ? "left" : "center"}>
				{!this.props.forManagers && this.props.clientSelected === undefined ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>Please choose a client.</div>
						<Dropdown
							id={"client-dropdown"}
							field="chosen-client"
							label="Client to work on"
							value={this.props.clientSelected || ""}
							items={this.props.clientOptions || []}
							updateFn={this._onUpdate}
							style={assigneeStyle}
							searchable={true}
						/>
					</div>
				) : (
					""
				)}
				{!ticketExists && this.props.clientSelected !== undefined ? (
					<div style={emptyStyle}>
						<div style={emptyTextStyle}>
							No tickets to triage! Will automatically update once there are new tickets in the queue.
						</div>
						<Dropdown
							id={"client-dropdown"}
							field="chosen-client"
							label="Client to work on"
							value={this.props.clientSelected || ""}
							items={this.props.clientOptions || []}
							updateFn={this._onUpdate}
							style={assigneeStyle}
							searchable={true}
						/>
					</div>
				) : (
					""
				)}
				{ticketExists && this.props.client !== undefined ? (
					<div style={leftControlPanelStyle} className="scrollable">
						<div style={controlInnerStyle}>
							{!this.props.forManagers ? (
								<Dropdown
									id={"client-dropdown"}
									field="chosen-client"
									label="Client to work on"
									value={this.props.clientSelected}
									items={this.props.clientOptions}
									updateFn={this._onUpdate}
									style={assigneeStyle}
									searchable={true}
								/>
							) : (
								""
							)}
							{["admin", "manager"].indexOf(this.props.user.role) > -1 || this.props.user.canAssign ? (
								<Dropdown
									id={"hioperators-dropdown"}
									field="active_agent"
									label="Assigned Hioperator Agent"
									value={this.props.ticket.active_agent}
									items={hioperators}
									updateFn={this._onAssignAgent}
									style={assigneeStyle}
									searchable={true}
								/>
							) : (
								""
							)}
							<div style={currentAssignedStyle}>
								<div style={floatingLabelStyle}>Current Assignee</div>
								{get_current_assigned(this.props.ticket, this.state.clientAgents)}
							</div>
							<Dropdown
								id={"assignee-dropdown"}
								field="assignee_id"
								label="New Assignee"
								value={this.props.reply.assignee_id}
								items={this.state.clientAgents}
								updateFn={this._onUpdate}
								style={assigneeStyle}
								searchable={true}
							/>
							<div style={toggleRowStyle}>
								<Search
									placeholder="Search tags..."
									id={"tags"}
									type="tags"
									fields={["text"]}
									resultComponent={TagResult}
									onSelect={this._onTagSearchSelect}
									where={[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }]}
									min={2}
								/>
								<Tokenizer
									field="tags"
									value={this.props.reply.tags || []}
									id="reply-tags"
									label="Add tag"
									updateFn={this._onUpdateTokens}
									style={tokenizerStyle}
									noEntry={true}
								/>
							</div>
							{this.state.ticket_fields !== undefined ? (
								<div style={customFieldsStyle}>
									{this.state.ticket_fields.map((item, index) => (
										<TicketField
											item={item}
											key={item.id}
											error={this.state.errors.indexOf(item.id) > -1}
											ticket_id={this.props.ticket.id}
											ticket={this.props.ticket}
											reply={this.props.reply}
										/>
									))}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				) : (
					""
				)}
				<div style={centerContainerStyle}>
					<ReactCSSTransitionGroup
						transitionName="triage"
						transitionAppear={true}
						transitionLeave={true}
						transitionEnter={true}
						transitionAppearTimeout={300}
						transitionEnterTimeout={300}
						transitionLeaveTimeout={300}
					>
						{ticketExists && this.state.client !== null ? (
							<Email
								key={this.props.ticket.id}
								ticket={this.props.ticket}
								client={this.props.client}
								user={this.props.user}
								permission={this.state.permission || {}}
								reply={this.props.reply}
								status={this.props.status}
								ticket_fields={this.state.ticket_fields}
								onSend={this._onSend}
								assignee={this.props.reply.assignee_id}
								loading={this.state.loading}
								forManagers={this.props.forManagers}
								channel={this.state.channel}
								showEvents={this.state.showEvents}
								onRunTriageActions={this._onRunTriageActions}
								triageWorkflowMode={this.state.triageWorkflowMode}
							/>
						) : (
							""
						)}

						{ticketExists &&
						this.props.ticket.requesterTickets !== undefined &&
						this.props.ticket.requesterTickets.length > 0 ? (
							<Card style={otherTicketsContainerStyle}>
								<div style={otherTicketsHeaderStyle} onClick={this._onToggleOtherTickets}>
									Other Tickets
									<i style={toggleOpenStyle} className="material-icons">
										{this.state.showOtherTickets ? "keyboard_arrow_up" : "keyboard_arrow_down"}
									</i>
								</div>
								{this.state.showOtherTickets ? (
									<div style={otherTicketsInnerStyle}>
										<div style={headerStyle}>
											<Cell size="xs" width="50px">
												SLA
											</Cell>
											<Cell size="s" width="90px">
												External #
											</Cell>
											<Cell size="s" width="70px">
												Status{" "}
											</Cell>
											<Cell size="l">Subject</Cell>
											<Cell size="m">Last Updated </Cell>
										</div>
										{this.props.ticket.requesterTickets.map((item, index, arr) => (
											<TicketRow
												item={item}
												index={index}
												key={item.id}
												history={true}
												clientId={this.props.client.id}
												newTab={true}
												otherTicket={true}
											/>
										))}
									</div>
								) : (
									""
								)}
							</Card>
						) : (
							""
						)}
					</ReactCSSTransitionGroup>
				</div>
				{ticketExists &&
				this.props.client !== undefined &&
				this.state.workflows !== undefined &&
				this.state.macros !== undefined ? (
					<div style={rightControlPanelStyle}>
						<Search
							placeholder="Search..."
							id={"ticket"}
							actions={TicketActions}
							style={searchStyle}
							hideResults={true}
							onFocus={this._onSearchFocus}
							onBlur={this._onSearchBlur}
						/>
						{!showSearch ? (
							<div style={profileContainerStyle}>
								<i style={profileLogoStyle} className="material-icons">
									account_circle
								</i>
								<div style={profileNameStyle}>
									{crmScaffold.ticketOptions.editRequester ? (
										<Input
											value={this.props.reply.requester_name || this.props.ticket.requester_name}
											onBlur={event => {}}
											noLine={true}
											field="requester_name"
											updateFn={this._onUpdate}
											placeholder="Requester Name"
											style={{
												fontSize: "inherit",
												padding: "0px",
												margin: "0px"
											}}
											inputStyle={{
												fontSize: "inherit",
												padding: "0px",
												margin: "0px",
												textAlign: "center",
												fontWeight: "700"
											}}
										/>
									) : (
										this.props.ticket.requester_name
									)}
								</div>
								<div style={profileEmailStyle}>
									{crmScaffold.ticketOptions.editRequester ? (
										<Input
											value={this.props.reply.requester_email || this.props.ticket.requester_email}
											onBlur={event => {}}
											noLine={true}
											field="requester_email"
											updateFn={this._onUpdate}
											placeholder="Requester Email"
											style={{
												fontSize: "inherit",
												padding: "0px",
												margin: "0px"
											}}
											inputStyle={{
												fontSize: "inherit",
												padding: "0px",
												margin: "0px",
												textAlign: "center"
											}}
										/>
									) : (
										this.props.ticket.requester_email
									)}
								</div>
								<div style={rowStyle}>
									<span style={showStyleText}>Show events:</span>
									<Toggle value={this.state.showEvents || false} field="showEvents" updateFn={this._onUpdateState} />
								</div>
							</div>
						) : (
							""
						)}
						{workflows.length > 0 && !showSearch ? <div style={controlHeaderStyle}>Attached Workflows</div> : ""}
						{ticketExists && workflows.length > 0 && this.state.workflows !== undefined && !showSearch ? (
							<div>
								{workflows.map((item, index, arr) => (
									<TicketWorkflowRow
										item={item}
										index={index}
										key={"tw-" + item.id}
										workflows={this.state.workflows}
										user={this.props.user || {}}
										hioperators={hioperators}
									/>
								))}
							</div>
						) : (
							""
						)}

						{this.state.workflows.length > 0 && showSearch ? <div style={controlHeaderStyle}>Workflows</div> : ""}
						{this.state.workflows.length > 0 && showSearch ? (
							<Card style={controlCardStyle} onMouseOver={this._searchResultOver} onMouseOut={this._searchResultOut}>
								{this.state.workflows.map((item, index, arr) => (
									<SearchRow
										key={"search-result-" + item.id}
										item={item}
										last={index === arr.length - 1}
										onClick={this._onWorkflowClick}
									/>
								))}
							</Card>
						) : (
							""
						)}
						{this.state.macros.length > 0 && showSearch ? (
							<div style={controlHeaderStyle}>Preset email responses</div>
						) : (
							""
						)}
						{this.state.macros.length > 0 && showSearch ? (
							<Card style={controlCardStyle} onMouseOver={this._searchResultOver} onMouseOut={this._searchResultOut}>
								{this.state.macros.map((item, index, arr) => (
									<SearchRow
										key={"search-result-" + item.id}
										item={item}
										last={index === arr.length - 1}
										onClick={this._onMacroClick}
									/>
								))}
							</Card>
						) : (
							""
						)}
					</div>
				) : (
					""
				)}
			</OuterScaffold>
		);
	}
}

export default Ticket;
