import React from "react";

import WorkflowActions from "../../actions/WorkflowActions";

// Scaffolding
import OuterScaffold from "../_common/OuterScaffold.react";

// Headers
import H1 from "../_common/headers/H1.react";
import Button from "../_common/buttons/Button.react";

// Creator tools
import CreatorDropdown from "./CreatorDropdown.react";
import WorkflowQuestion from "./WorkflowQuestion.react";

// Functions
import get_card from "../../functions/get_card";

const workflowMapStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	position: "relative",
	transition: "all .3s ease-in-out",
	textAlign: "center",
	maxWidth: "calc(100% - 8px)",
	padding: "300px 300px"
};

const emptyContainerStyle = {
	textAlign: "center",
	verticalAlign: "middle",
	display: "inline-block"
};

const coverStyle = {
	width: "100%",
	height: "100vh",
	position: "fixed",
	top: "0px",
	left: "0px",
	backgroundColor: "rgb(240, 240, 240)",
	overflowY: "auto",
	zIndex: "4"
};

const backToMapStyle = {
	position: "fixed",
	left: "16px",
	top: "16px"
};

const workflowRowStyle = {
	display: "inline-block",
	minWidth: "100%",
	whiteSpace: "nowrap",
	textAlign: "left",
	height: "124px"
};

const rowVertAlignStyle = {
	verticalAlign: "middle",
	display: "inline-block",
	width: "0px",
	height: "124px"
};

const dummyDivStyle = {
	width: "1px",
	height: "124px"
};

const vertAlignStyle = {
	verticalAlign: "middle",
	display: "inline-block",
	width: "0px",
	height: "90vh"
};

class WorkflowCard extends React.Component {
	render() {
		var MyCard = get_card(this.props.item.type);

		return (
			<MyCard
				key={this.props.item.id}
				item={this.props.item}
				client={this.props.client}
				targetFocus={this.props.targetFocus}
				first={this.props.first}
				last={this.props.last}
				status={[]}
				onMoveView={this.props.onMoveView}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
			/>
		);
	}
}

var simple_client = client => {
	const simpleClient = {
		id: client.id,
		name: client.name,
		picture: client.picture
	};

	return simpleClient;
};

class WorkflowRow extends React.Component {
	render() {
		return (
			<div style={workflowRowStyle}>
				<div style={rowVertAlignStyle} />
				{this.props.children}
			</div>
		);
	}
}

class WorkflowMap extends React.Component {
	constructor(props) {
		super(props);
		this._onMoveView = this._onMoveView.bind(this);
		this._onUnfocus = this._onUnfocus.bind(this);
		this._onConnectCard = this._onConnectCard.bind(this);

		this.state = { client: simple_client(this.props.client || {}), target: "", connecting: false };
	}

	componentWillUnmount() {
		document.documentElement.style.overflow = "auto";
	}

	_onMoveView(target) {
		if (!this.state.connecting) {
			var itemIndex;
			for (var i = 0; i < this.props.items.length; i++) {
				if (this.props.items[i].id === target) {
					itemIndex = i;
					break;
				}
			}
			this.setState({ target, itemIndex });
			document.documentElement.style.overflow = "hidden";
		} else if (target !== this.state.connecting) {
			WorkflowActions.connectCard(this.state.connecting, this.state.connectingIndex, target);
			this.setState({ connecting: false, connectingIndex: null });
			document.documentElement.style.overflow = "auto";
		}
	}

	_onUnfocus() {
		document.documentElement.style.overflow = "auto";
		//WorkflowActions.save();
		this.setState({ target: "" });
	}

	_onConnectCard(location, index) {
		this.setState({ connecting: location[0], connectingIndex: index });
	}

	render() {
		var rows = [];
		var rowIndex = this.props.items[0].coordinates.y;
		var singleRow = [];
		var item;
		var firstInRow = true;
		var j;
		for (var i = 0; i < this.props.items.length; i++) {
			item = this.props.items[i];

			if (item.coordinates.y !== rowIndex) {
				rows.push(singleRow);
				if (item.coordinates.y !== rowIndex + 1 && this.state.target === "") {
					for (j = 0; j < item.coordinates.y - rowIndex - 1; j++) rows.push([<div style={dummyDivStyle} />]);
				}

				singleRow = [];
				rowIndex = item.coordinates.y;
				firstInRow = true;
			}

			singleRow.push(
				<WorkflowQuestion
					id={"outer-card-" + item.id}
					key={"outer-card-" + item.id}
					item={item}
					client={this.state.client}
					first={firstInRow}
					status={[]}
					targetFocus={this.state.target}
					onMoveView={this._onMoveView}
					onCardConnect={this._onConnectCard}
					connecting={this.state.connecting}
				/>
			);

			firstInRow = false;
		}

		rows.push(singleRow);

		return (
			<div key="workflow-map-zoom" style={workflowMapStyle}>
				{rows.map((item, index) => (
					<WorkflowRow key={"workflow-row-" + index}>{item}</WorkflowRow>
				))}
				{[null, undefined, ""].indexOf(this.state.target) === -1 ? (
					<div style={coverStyle}>
						<Button style={backToMapStyle} text="Back to map" onClick={this._onUnfocus} />
						<div style={vertAlignStyle} />
						<WorkflowCard
							id={this.props.items[this.state.itemIndex].id}
							key={"card-" + this.props.items[this.state.itemIndex].id}
							item={this.props.items[this.state.itemIndex]}
							client={this.props.client}
							onMoveView={this._onMoveView}
						/>
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

class Workflow extends React.Component {
	constructor(props) {
		super(props);

		this.state = { name: this.props.item.name || "", zoom: false, mounted: false };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		//document.body.style.overflow = "hidden";
		this.setState({ mounted: true });
	}

	componentWillUnmount() {
		//document.body.style.overflow = "";
	}

	render() {
		const cards = this.props.item.cards || [];
		return (
			<OuterScaffold spaceForHeader={true} verticalAlign={true} verticalHeight="70vh" align={"center"}>
				{cards.length > 0 ? (
					<WorkflowMap
						maxOffsets={this.props.item.maxOffsets}
						items={this.props.item.cards}
						client={this.props.client}
					/>
				) : (
					<div style={emptyContainerStyle}>
						<H1 textAlign="center" margin="0px 0px 32px 0px" text="Get started by adding a card!" />
						<CreatorDropdown id={0} coordinates={{ x: 0, y: 0 }} />
					</div>
				)}
			</OuterScaffold>
		);
	}
}

export default Workflow;
