import React from "react";

// Scaffolding
import Row from "../_common/Row.react";
import Cell from "../_common/Cell.react";
import Viewer from "../_common/Viewer.react";

// Functions
import * as time from "../../functions/time";

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

const claimedStyle = {
	color: "cadetblue",
	border: "1px solid cadetblue",
	borderRadius: "4px",
	padding: "5px",
	fontSize: "12px",
	lineHeight: "12px",
	marginRight: "8px",
	display: "inline-block"
};

const previewStyle = {
	position: "absolute",
	top: "calc(100% + 16px)",
	left: "100px",
	width: "400px",
	backgroundColor: "white",
	zIndex: "10",
	padding: "16px",
	boxShadow:
		"0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
	borderRadius: "2px",
	overflowX: "hidden"
};

const sandboxStyle = {
	borderRadius: "50%",
	backgroundColor: "navy",
	color: "white",
	fontSize: "12px",
	lineHeight: "12px",
	padding: "5px 0px",
	width: "22px",
	marginRight: "8px",
	display: "inline-block",
	textAlign: "center"
};

class SLA extends React.Component {
	render() {
		const now = new Date();

		const primaryStyle = {
			display: "inline-block",
			color: now < this.props.time ? "green" : "indianred",
			fontWeight: "700",
			width: "50px",
			padding: "0px 8px"
		};

		var slaTime = "";
		if (this.props.time !== undefined) {
			slaTime = Math.round((this.props.time - now) / 60000);
			if (Math.abs(slaTime) < 60) slaTime = String(slaTime) + "m";
			else slaTime = String(Math.ceil(slaTime / 60)) + "h";
		}

		return <div style={primaryStyle}>{slaTime}</div>;
	}
}

class TicketRow extends React.Component {
	constructor(props) {
		super(props);
		this._onHoverChange = this._onHoverChange.bind(this);
		this.state = { hover: false };
	}

	_onHoverChange(hover) {
		this.setState({ hover });
	}

	render() {
		var statusColor = "#F5CA00";
		if (this.props.item.external_status === "pending") statusColor = "#59BBE0";
		else if (["assigned", "open"].indexOf(this.props.item.external_status) > -1) statusColor = "#E82A2A";
		else if (["archived", "solved"].indexOf(this.props.item.external_status) > -1) statusColor = "#828282";
		else if (["closed", "deleted"].indexOf(this.props.item.external_status) > -1) statusColor = "black";
		else if (this.props.item.external_status === "hold") statusColor = "black";

		const statusStyle = {
			color: statusColor,
			fontWeight: "400"
		};

		const escalateStyle = {
			borderRadius: "50%",
			backgroundColor: this.props.item.escalate === "internal" ? "indianred" : "purple",
			color: "white",
			fontSize: "12px",
			lineHeight: "12px",
			padding: "5px 0px",
			width: "22px",
			marginRight: "8px",
			display: "inline-block",
			textAlign: "center"
		};

		const workflowStyle = {
			borderRadius: "50%",
			backgroundColor: this.props.item.workflowsStatus === "complete" ? "darkgreen" : "orange",
			color: "white",
			fontSize: "12px",
			lineHeight: "12px",
			padding: "5px 0px",
			width: "22px",
			marginRight: "8px",
			display: "inline-block",
			textAlign: "center"
		};

		var viewing = this.props.item.viewing || [];

		var color = "white";
		var sla;
		if (
			([null, undefined].indexOf(this.props.item.sla) === -1 ||
				[null, undefined].indexOf(this.props.item.external_sla) === -1) &&
			["new", "open"].indexOf(this.props.item.external_status) > -1
		) {
			var now = new Date();
			now.setMinutes(now.getMinutes() + 30);

			if ([null, undefined].indexOf(this.props.item.sla) === -1) sla = new Date(this.props.item.sla.seconds * 1000);
			else if ([null, undefined].indexOf(this.props.item.external_sla) === -1)
				sla = new Date(this.props.item.external_sla.seconds * 1000);

			if (sla !== undefined && now > sla) color = "yellow";
		}

		return (
			<Row
				index={this.props.index}
				href={"#clients/" + this.props.clientId + "/tickets/" + this.props.item.id}
				newTab={this.props.newTab || false}
				style={rowStyle}
				color={color}
				hoverChange={this._onHoverChange}
			>
				<SLA time={sla} />
				<Cell size="s" width="90px">
					{this.props.item.external_id}
				</Cell>
				<Cell size="s" width="70px">
					<div style={statusStyle}>{this.props.item.external_status.toUpperCase()}</div>
				</Cell>
				{!this.props.otherTicket ? <Cell size="m">{this.props.item.requester_email}</Cell> : ""}
				<Cell size="l">{this.props.item.title !== "" ? this.props.item.title : "No title"}</Cell>

				<Cell size="m">{time.parse_time(this.props.item.external_requester_updated)}</Cell>

				{this.props.item.sandboxAwaiting ? <div style={sandboxStyle}>S</div> : ""}
				{["", undefined, null].indexOf(this.props.item.escalate) === -1 ? (
					<div style={escalateStyle}>{this.props.item.escalate === "internal" ? "I" : "E"}</div>
				) : (
					""
				)}
				{[undefined, null, ""].indexOf(this.props.item.workflowsStatus) === -1 ? (
					<div style={workflowStyle}>W</div>
				) : (
					""
				)}
				{this.props.item.active_agent ? <div style={claimedStyle}>Claimed</div> : ""}
				{viewing.map((item, index, arr) => (
					<Viewer item={item} ticketId={this.props.item.id} key={this.props.item.id + "-viewer-" + index} />
				))}
				{this.state.hover ? (
					<div style={previewStyle} dangerouslySetInnerHTML={{ __html: this.props.item.description }} />
				) : (
					""
				)}
			</Row>
		);
	}
}

export default TicketRow;
