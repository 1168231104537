import Reflux from "reflux";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import * as async from "async";

import config from "../config.public";

// Actions
import UserActions from "../actions/UserActions";
import UIActions from "../actions/UIActions";
import ClientActions from "../actions/ClientActions";
import TicketActions from "../actions/TicketActions";

// Functions
import * as status from "../functions/status";
import * as firestore from "../functions/firestore";
import * as objects from "../functions/objects";
import * as list from "../functions/list";
import * as permissions from "../functions/permissions";

import api from "../functions/api";
import * as viewing from "../functions/viewing";

// Defines db for firestore
var db = firebase.firestore();

// Makes store scaffold
var User = {
    loggedIn: false,
    errors: [],
    status: [],
    wired: false,
    verificationId: null,
};

var resolver;

// Handles errors
function add_error(error) {
    if (!Array.isArray(User.errors)) User.errors = [];

    error = String(error);

    if (error === "There is no user record corresponding to this identifier. The user may have been deleted.")
        User.errors.push({ id: "email", text: "User does not exist." });
    else if (error === "The password is invalid or the user does not have a password.")
        User.errors.push({ id: "password", text: "Password was incorrect." });
    else if (error === "The user account has been disabled by an administrator.")
        User.errors.push({ id: "email", text: "Your account is disabled. Please contact the system admin." });
    else if (error === "Username/client id combination not found.")
        User.errors.push({ id: "email", text: "Can't find your account. Please double check the spelling of your email." });
    else if (error === "Password does not conform to policy: Password must have symbol characters")
        User.errors.push({ id: "password", text: "Password must have symbol characters" });
    else if (error === "Password does not conform to policy: Password must have uppercase characters")
        User.errors.push({ id: "password", text: "Password must have uppercase characters" });
    else if (error === "Password does not conform to policy: Password must have numeric characters")
        User.errors.push({ id: "password", text: "Password must have numeric characters" });
    else if (error === "Password does not conform to policy: Password must have lowercase characters")
        User.errors.push({ id: "password", text: "Password must have lowercase characters" });
    else if (error === "Too many unsuccessful login attempts. Please try again later.")
        User.errors.push({ id: "email", text: "Too many unsuccessful login attempts. Please try again later." });
    else if (error === "There is no user record corresponding to this identifier. The user may have been deleted.")
        User.errors.push({ id: "email", text: "Can't find user with this email." });
    else if (error === "The email address is badly formatted.")
        User.errors.push({ id: "email", text: "The email address is badly formatted." });
    else if (error === "We have blocked all requests from this device due to unusual activity. Try again later.")
        User.errors.push({
            id: "email",
            text: "We have blocked all requests from this device due to unusual activity. Try again later.",
        });
    else if (
        error ===
        "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user."
    )
        User.errors.push({ id: "twofactor", text: "Incorrect verification code." });
}

////////////////////////////////////////////////////
// Logining in, authentication
////////////////////////////////////////////////////

firebase.auth().onAuthStateChanged(function (user) {
    console.log("AUTH CHANGE!");
    console.log(user);
    status.remove(User, "logging in");
    if (user) {
        UserActions.loginCompleted(user);
    } else {
        User.loggedIn = false;
        UIActions.show("login");
    }
});

function login(data) {
    status.add(User, "logging in");
    firebase
        .auth()
        .signInWithEmailAndPassword(data.email.toLowerCase(), data.password)
        .then(() => true)
        .catch((error) => {
            console.log("HIT ERROR ON AUTHENTICATION!!!!");
            console.log(error);

            if (error.code === "auth/multi-factor-auth-required") {
                resolver = error.resolver;
                // Ask user which second factor to use.
                var selectedIndex = 0;

                if (resolver.hints[selectedIndex].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                    var phoneInfoOptions = {
                        multiFactorHint: resolver.hints[selectedIndex],
                        session: resolver.session,
                    };

                    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                    var appVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", { size: "invisible" });

                    // Send SMS verification code
                    //phoneInfoOptions
                    return phoneAuthProvider
                        .verifyPhoneNumber(phoneInfoOptions, appVerifier)
                        .then(function (verificationId) {
                            console.log("CODE SENT!");
                            console.log(resolver);
                            console.log(verificationId);
                            //return { twofactor: "Boba" };
                            User.verificationId = verificationId;
                            status.remove(User, "logging in");
                            UserActions.update();
                        })
                        .catch((error) => {
                            console.log("SENDING OUT PHONE AUTH ERROR!");
                            console.log(error);
                            UserActions.loginFailed(error);
                        });
                } else {
                    console.log("UNSUPPORTED SECOND FACTOR");
                    // Unsupported second factor.
                }
            } else {
                // Handle other errors such as wrong password.
                UserActions.loginFailed(error);
            }
        });
}

const verify_code = async (data) => {
    status.add(User, "logging in");
    var cred = firebase.auth.PhoneAuthProvider.credential(User.verificationId, data.verificationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return await resolver.resolveSignIn(multiFactorAssertion).catch((error) => {
        UserActions.loginFailed(error);
    });
};

function get_user(user) {
    db.collection("users")
        .where("uid", "==", user.uid)
        .get()
        .then(function (querySnapshot) {
            var data = firestore.extract(querySnapshot);
            if (data.length > 0) {
                setup_session(data[0]);
            } else {
                get_user_by_email(user);
            }
        })
        .catch(UserActions.authenticateFailed);
}

function get_user_by_email(user) {
    db.collection("users")
        .where("email", "==", user.email.toLowerCase())
        .get()
        .then(function (querySnapshot) {
            var data = firestore.extract(querySnapshot);

            if (data.length > 0) {
                set_uid(user, data[0]);
            } else {
                UserActions.authenticateFailed("Can't find user. Please contact the adminsitrator.");
            }
        })
        .catch(UserActions.authenticateFailed);
}

function set_uid(fbUser, myUser) {
    db.collection("users")
        .doc(myUser.id)
        .update({ uid: fbUser.uid })
        .then(function () {
            setup_session(myUser);
        })
        .catch(UserActions.authenticateFailed);
}

function setup_live_updaters() {
    if (User.update === undefined) {
        User.update = db
            .collection("users")
            .doc(User.me.id)
            .onSnapshot(function (snapshot) {
                User.me = firestore.extract_single(snapshot);
                UserActions.update();
            });

        if (["admin", "manager"].indexOf(User.me.role) > -1) {
            User.events = { log: [], tracking: [] };
            db.collection("events")
                .orderBy("start", "desc")
                .limit(25)
                .onSnapshot(function (snapshot) {
                    User.events = firestore.extract(snapshot);
                    UserActions.update();
                });
        }

        // Sets up permissions
        db.collection("permissions")
            .where("userId", "==", User.me.id)
            .onSnapshot(function (snapshot) {
                User.permissions = firestore.extract(snapshot);
                User.pages = [];

                for (var i = 0; i < User.permissions.length; i++) check_permissions(User.permissions[i]);

                ClientActions.loadSummary(User.permissions);
                UserActions.loadUsersClients();

                console.log("authentication completed");
                if (!User.wired) {
                    UIActions.monitorRoutes();
                    UIActions.route();
                    User.wired = true;
                }

                UserActions.update();
            });

        // Sets up flagged tickets to review
        setTimeout(function () {
            db.collection("flaggedTickets")
                .where("userId", "==", User.me.id)
                .where("userReviewed", "==", false)
                .onSnapshot((snapshot) => {
                    User.me.flaggedTickets = firestore.extract(snapshot);

                    if (User.me.flaggedTickets.length > 0) {
                        User.me.flaggedTickets = [User.me.flaggedTickets[0]];
                        ensure_clients("flaggedTickets");
                        UserActions.update();
                        UIActions.showOverlay("flag ticket acknowledge");
                    }
                });
        }, 3000);
    }
}

function ensure_clients(field) {
    if (User.me[field] !== undefined) {
        async.waterfall(
            [
                function (callback) {
                    viewing.ensure_clients(User.me[field], [], "clientId", "client", callback);
                },
            ],
            function (err, results) {
                User.me[field] = results;
                UserActions.update();
            }
        );
    }
}

var check_permissions = (permission) => {
    var i, j;
    for (i = 0; i < config.permissions.length; i++) {
        for (j = 0; j < config.permissions[i].items.length; j++) {
            if (permission[config.permissions[i].items[j].value]) {
                if (config.permissions[i].items[j].page !== undefined && User.pages.indexOf(config.permissions[i].items[j].page) === -1)
                    User.pages.push(config.permissions[i].items[j].page);

                if (config.permissions[i].page !== undefined && User.pages.indexOf(config.permissions[i].page) === -1)
                    User.pages.push(config.permissions[i].page);
            }
        }
    }

    for (i = 0; i < config.pages.length; i++) {
        if (config.pages[i].globalPermissions !== undefined) {
            for (j = 0; j < config.pages[i].globalPermissions.length; j++) {
                if (User.me[config.pages[i].globalPermissions[j]] === true && User.pages.indexOf(config.pages[i].value) === -1)
                    User.pages.push(config.pages[i].value);
            }
        }
    }
};

/*
var live_update_escalations = client => {
	User.events.clients[client] = [];
	var first = true;
	db.collection("clients")
		.doc(client)
		.collection("tickets")
		.where("external_status_num", "<", 3)
		.where("escalate", "==", "internal")
		.onSnapshot(function(snapshot) {
			User.events.clients[client] = firestore.extract(snapshot);
			harmonize_escalations(first);
			first = false;
		});
};
*/

/*
var harmonize_escalations = first => {
	var myClients = Object.keys(User.events.clients);
	User.events.log = [];
	for (var i = 0; i < myClients.length; i++)
		User.events.log = User.events.log.concat(User.events.clients[myClients[i]]);

	for (var j = 0; j < User.events.log.length; j++) {
		if (User.events.tracking.indexOf(User.events.log[j].id) === -1) {
			if (!first) {
				UIActions.addFeedUpdate({
					clientId: User.events.log[j].clientId,
					url: "#clients/" + User.events.log[j].clientId + "/tickets/" + User.events.log[j].id,
					text: "New escalation"
				});
			}
			User.events.tracking.push(User.events.log[j].id);
		}
	}

	User.events.log = list.order(User.events.log, "external_updated newest");
	UserActions.update();
};
*/

function setup_session(myUser) {
    var now = new Date();
    async.waterfall(
        [
            function (callback) {
                var ref = db.collection("users").doc(myUser.id).collection("sessions").doc();

                User.sessionId = ref.id;

                db.collection("users")
                    .doc(myUser.id)
                    .collection("sessions")
                    .doc(ref.id)
                    .set({
                        start: now,
                    })
                    .then(function () {
                        callback(null);
                    })
                    .catch(callback);
            },
            function (callback) {
                db.collection("activeSessions")
                    .doc(User.sessionId)
                    .set({
                        userId: myUser.id,
                        start: new Date(),
                    })
                    .then(function () {
                        callback(null);
                    })
                    .catch(callback);
            },
            function (callback) {
                // Sets up presence
                var userStatusDatabaseRef = firebase.database().ref("/status/" + User.sessionId);

                // We'll create two constants which we will write to
                var isOfflineForDatabase = {
                    state: "offline",
                    last_changed: firebase.database.ServerValue.TIMESTAMP,
                    id: myUser.id,
                };

                var isOnlineForDatabase = {
                    state: "online",
                    last_changed: firebase.database.ServerValue.TIMESTAMP,
                    id: myUser.id,
                };

                // Create a reference to the special '.info/connected' path in
                firebase
                    .database()
                    .ref(".info/connected")
                    .on("value", function (snapshot) {
                        console.log("connection state is " + snapshot.val());
                        if (snapshot.val() === false && User.loggedIn) User.disconnected = true;

                        User.loggedIn = snapshot.val();
                        UserActions.update();

                        if (snapshot.val() === false) return;
                        if (User.disconnected) {
                            console.log("NEED TO RELOAD!");
                            delete User.disconnected;
                            get_user({ uid: User.me.uid });
                            return;
                        }

                        userStatusDatabaseRef
                            .onDisconnect()
                            .set(isOfflineForDatabase)
                            .then(function () {
                                userStatusDatabaseRef.set(isOnlineForDatabase);
                                setup_global_session_and_login();
                            });
                    });

                callback(null);
            },
        ],
        function (err) {
            UserActions.authenticateCompleted(myUser);
        }
    );
}

function setup_global_session_and_login(master_callback) {
    var now = new Date();
    User.loggedIn = true;

    async.waterfall(
        [
            function (callback) {
                if (User.me.loggedIn === false || User.me.globalSession === undefined) {
                    var ref = db.collection("users").doc(User.me.id).collection("sessions").doc();

                    User.me.globalSession = ref.id;

                    db.collection("users")
                        .doc(User.me.id)
                        .collection("sessions")
                        .doc(ref.id)
                        .set({
                            start: now,
                            global: true,
                        })
                        .then(function () {
                            callback(null);
                        })
                        .catch(callback);
                } else callback(null);
            },
            function (callback) {
                var update = {
                    loggedIn: true,
                    globalSession: User.me.globalSession,
                };

                if (!User.me.loggedIn) update.lastLogin = now;

                db.collection("users")
                    .doc(User.me.id)
                    .update(update)
                    .then(function () {
                        callback(null);
                    })
                    .catch(callback);
            },
        ],
        function (err) {
            if (err) console.log(err);
            if (master_callback !== undefined) master_callback();
        }
    );
}

function logout() {
    api("get", "/users/logout", null, null, null, User, "logout");
    clearout_user();
}

function clearout_user() {
    firebase
        .auth()
        .signOut()
        .then(
            function () {
                window.location.href = "/";
                // Sign-out successful.
            },
            function (error) {
                // An error happened.
            }
        );
}

function ping() {
    setTimeout(ping, 30000);

    if (User.me !== undefined && User.me.id !== undefined) {
        api("get", "/users/" + User.me.id + "/connected", null, null, null, User, "pinging");
    }
}

ping();

////////////////////////////////////////////////////
// Password resets
////////////////////////////////////////////////////

function reset_password(email) {
    if (!status.has(User, "resetting password")) {
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(function () {
                // Email sent.
                UserActions.resetPasswordCompleted();
            })
            .catch(function (error) {
                // An error happened.
                UserActions.resetPasswordFailed(error.message);
            });
    }
}

////////////////////////////////////////////////////
// Analytics event
////////////////////////////////////////////////////

function add_analytics(event) {
    var now = new Date();
    var split = now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }).split("/");
    var myMonth = split[0] + "." + split[2].substr(0, 4);
    var duration = now - event.start;
    var targetMonth = { days: {}, month: new Date(split[0] + "/1/" + split[2].substr(0, 4)), new: true };

    // Figures out if there is the relevant record
    for (var i = 0; i < User.analytics.length; i++) {
        if (User.analytics[i].id === myMonth) {
            targetMonth = User.analytics[i];
            break;
        }
    }

    if (targetMonth.new) {
        delete targetMonth.new;
        if (!Array.isArray(User.analytics)) User.analytics = [];
        User.analytics.push(targetMonth);
    }

    if (targetMonth.days[split[1]] === undefined) {
        targetMonth.days[split[1]] = { n: 0, duration: 0, escalations: 0, clients: {} };
    }

    targetMonth.days[split[1]].n++;
    targetMonth.days[split[1]].duration += duration;

    if (targetMonth.days[split[1]].clients[event.clientId] === undefined) {
        targetMonth.days[split[1]].clients[event.clientId] = {
            n: 0,
            duration: 0,
            escalations: 0,
        };
    }

    targetMonth.days[split[1]].clients[event.clientId].n++;
    targetMonth.days[split[1]].clients[event.clientId].duration += duration;

    if (["escalate", "escalate external"].indexOf(event.action) > -1) {
        targetMonth.days[split[1]].escalations++;
        targetMonth.days[split[1]].clients[event.clientId].escalations++;
    }

    db.collection("users").doc(User.me.id).collection("analytics").doc(myMonth).set(targetMonth, { merge: true });
}

////////////////////////////////////////////////////
// Analytics
////////////////////////////////////////////////////

var parse_analytics_manager = (data) => {
    console.log(data);
    // Totals
    const totals = {
        created: data.totalCreated[0].Created,
        solved: data.totalSolved[0].Solved,
        submits: data.totalSubmits[0].Submits,
        duration: data.totalSolved[0].duration / (60 * 1000),
    };

    var timeChart, timeDurationChart, i, j, temp;

    if (data.type === "24h") {
        // Time Chart
        timeChart = [["Hour", "Created", "Submits", "Solved"]];
        timeDurationChart = [["Hour", "Average Duration"]];
        var timeArray = [];
        var now = new Date();
        now = now.setDate(now.getDate() - 1);
        now = new Date(now);

        for (i = 0; i < 25; i++) {
            timeChart.push([now.getHours() + ":00", 0, 0, 0]);
            timeDurationChart.push([now.getHours() + ":00", 0]);

            timeArray.push(
                now.getFullYear() +
                    "-" +
                    (now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1) +
                    "-" +
                    (now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate()) +
                    " " +
                    (now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours())
            );

            now = now.setHours(now.getHours() + 1);
            now = new Date(now);
        }

        for (i = 0; i < data.createdByDate.length; i++) {
            timeChart[timeArray.indexOf(data.createdByDate[i].ForDate) + 1][1] = data.createdByDate[i].Created;
        }

        for (i = 0; i < data.solvedByDate.length; i++) {
            timeChart[timeArray.indexOf(data.solvedByDate[i].ForDate) + 1][3] = data.solvedByDate[i].Solved;
            timeDurationChart[timeArray.indexOf(data.solvedByDate[i].ForDate) + 1][1] = data.solvedByDate[i].duration / (60 * 1000);
        }

        for (i = 0; i < data.submitsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][2] = data.submitsByDate[i].Submits;
        }

        console.log(timeChart);
        console.log(timeDurationChart);
    } else {
        // Time Chart
        timeChart = [["Day", "Created", "Submits", "Solved"]];

        var day, simpleDay;
        for (i = 0; i < data.createdByDate.length; i++) {
            temp = [];
            day = data.createdByDate[i].ForDate;
            simpleDay = new Date(day);

            temp.push(simpleDay.getUTCMonth() + 1 + "/" + simpleDay.getUTCDate() + "/" + simpleDay.getUTCFullYear());
            temp.push(data.createdByDate[i].Created);

            for (j = 0; j < data.submitsByDate.length; j++) {
                if (data.submitsByDate[j].ForDate === day) {
                    temp.push(data.submitsByDate[j].Submits);
                    break;
                } else if (j === data.submitsByDate.length - 1) temp.push(null);
            }

            for (j = 0; j < data.solvedByDate.length; j++) {
                if (data.solvedByDate[j].ForDate === day) {
                    temp.push(data.solvedByDate[j].Solved);
                    break;
                } else if (j === data.solvedByDate.length - 1) temp.push(null);
            }

            timeChart.push(temp);
        }

        timeDurationChart = [["Day", "Average Duration"]];
        for (i = 0; i < data.solvedByDate.length; i++) {
            temp = [];
            day = data.solvedByDate[i].ForDate;
            simpleDay = new Date(day);
            temp.push(simpleDay.toLocaleDateString());
            temp.push(data.solvedByDate[i].duration / (60 * 1000));

            timeDurationChart.push(temp);
        }
    }

    // HiOperator vesus not chart
    var hioperatorVersus = [["Who", "Tickets Solved"]];

    if (data.solvedByWho[0].HiOperator === 0) {
        hioperatorVersus.push(["Other", data.solvedByWho[1].Solved]);
        hioperatorVersus.push(["HiOperator", data.solvedByWho[0].Solved]);
    } else {
        hioperatorVersus.push(["Other", data.solvedByWho[0].Solved]);
        hioperatorVersus.push(["HiOperator", data.solvedByWho[1].Solved]);
    }

    // Agent Chart
    var orderedUsers = list.order(data.submitByUser, "Submits biggest");

    var submitByUser = [["Agent", "Tickets Submitted"]];
    for (i = 0; i < orderedUsers.length; i++) {
        submitByUser.push([orderedUsers[i].name, orderedUsers[i].Submits]);
    }

    orderedUsers = list.order(orderedUsers, "duration smallest");

    var timeByUser = [["Agent", "Average Duration"]];
    for (i = 0; i < orderedUsers.length; i++) {
        timeByUser.push([orderedUsers[i].name, orderedUsers[i].duration / (60 * 1000)]);
    }

    var orderedClients = list.order(data.submitByClient, "Submits biggest");
    var orderedSolvedClients = list.order(data.solvedByClient, "Solved biggest");
    var orderedCreatedClients = list.order(data.createdByClient, "Solved biggest");

    var solvedByUser = [["Agent", "Submits", "Tickets Touched", "Effective Solved"]];
    var orderedSolvedByUser = list.order(data.solvedByUser, "effectiveTickets biggest");

    for (i = 0; i < orderedUsers.length; i++) {
        solvedByUser.push([
            orderedSolvedByUser[i].name,
            orderedSolvedByUser[i].eventCount,
            orderedSolvedByUser[i].tickets,
            Math.round(orderedSolvedByUser[i].effectiveTickets),
        ]);
    }
    var submitByClient = [["Client", "Created", "Submitted", "Solved"]];

    for (i = 0; i < orderedCreatedClients.length; i++) {
        temp = [orderedCreatedClients[i].name, orderedCreatedClients[i].Created, 0, 0];
        for (j = 0; j < orderedSolvedClients.length; j++) {
            if (orderedCreatedClients[i].clientId === orderedSolvedClients[j].clientId) {
                temp[3] = orderedSolvedClients[j].Solved;
                break;
            }
        }

        for (j = 0; j < orderedClients.length; j++) {
            if (orderedCreatedClients[i].clientId === orderedClients[j].clientId) {
                temp[2] = orderedClients[j].Submits;
                break;
            }
        }
        submitByClient.push(temp);
    }

    orderedClients = list.order(orderedClients, "duration smallest");

    var timeByClient = [["Client", "Submits Duration", "Solved Duration"]];
    for (i = 0; i < orderedClients.length; i++) {
        temp = [orderedClients[i].name, orderedClients[i].duration / (60 * 1000), null];
        for (j = 0; j < orderedSolvedClients.length; j++) {
            if (orderedClients[i].clientId === orderedSolvedClients[j].clientId) {
                temp[2] = orderedSolvedClients[j].duration / (60 * 1000);
                break;
            }
        }
        timeByClient.push(temp);
    }

    User.customAnalytics = {
        totals,
        timeChart,
        timeDurationChart,
        hioperatorVersus,
        submitByUser,
        timeByUser,
        solvedByUser,
        submitByClient,
        timeByClient,
    };

    UserActions.update();
};

var parse_analytics_agent = (data) => {
    // Totals
    const totals = {
        submitted: data.totalSubmits[0].Submits,
        submittedDuration: data.totalSubmits[0].duration / (60 * 1000),
        escalated: data.totalEscalations[0].Submits,
        escalatedDuration: data.totalEscalations[0].duration / (60 * 1000),
        submittedPerUnit: data.totalSubmits[0].Submits / data.submitsByDate.length,
        addWorkflows: data.totalAddWorkflows[0].Submits,
        addWorkflowsDuration: data.totalAddWorkflows[0].duration / (60 * 1000),
        removeWorkflows: data.totalRemoveWorkflows[0].Submits,
        removeWorkflowsDuration: data.totalRemoveWorkflows[0].duration / (60 * 1000),
    };

    var temp, timeChart, timeDurationChart, i, j;

    if (data.type === "24h") {
        // Time Chart
        timeChart = [["Hour", "Solved", "Escalated"]];
        timeDurationChart = [["Hour", "Solved Average Duration", "Escalated Average Duration"]];
        var timeArray = [];
        var now = new Date();
        now = now.setDate(now.getDate() - 1);
        now = new Date(now);

        for (i = 0; i < 25; i++) {
            timeChart.push([now.getHours() + ":00", 0, 0]);
            timeDurationChart.push([now.getHours() + ":00", 0, 0]);
            timeArray.push(
                now.getFullYear() +
                    "-" +
                    (now.getUTCMonth() + 1) +
                    "-" +
                    now.getUTCDate() +
                    " " +
                    (now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours())
            );

            now = now.setHours(now.getHours() + 1);
            now = new Date(now);
        }

        for (i = 0; i < data.submitsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][1] = data.submitsByDate[i].Submits;
            timeDurationChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][1] = data.submitsByDate[i].duration / (60 * 1000);
        }

        for (i = 0; i < data.escalationsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.escalationsByDate[i].ForDate) + 1][2] = data.escalationsByDate[i].Submits;
            timeDurationChart[timeArray.indexOf(data.escalationsByDate[i].ForDate) + 1][2] =
                data.escalationsByDate[i].duration / (60 * 1000);
        }
    } else {
        // Time Chart
        timeChart = [["Day", "Solved", "Escalated"]];
        timeDurationChart = [["Day", "Solved Average Duration", "Escalated Average Duration"]];

        var tempDuration, day, simpleDay;
        for (i = 0; i < data.submitsByDate.length; i++) {
            temp = [];
            tempDuration = [];
            day = data.submitsByDate[i].ForDate;
            simpleDay = new Date(day);

            temp = [simpleDay.toLocaleDateString(), data.submitsByDate[i].Submits, 0];
            tempDuration = [simpleDay.toLocaleDateString(), data.submitsByDate[i].duration / (60 * 1000), 0];

            for (j = 0; j < data.escalationsByDate.length; j++) {
                if (data.escalationsByDate[j].ForDate === day) {
                    temp[2] = data.escalationsByDate[j].Submits;
                    tempDuration[2] = data.escalationsByDate[j].duration / (60 * 1000);
                    break;
                }
            }

            timeChart.push(temp);
            timeDurationChart.push(tempDuration);
        }
    }

    // HiOperator vesus not chart
    var submitsVersusEscalations = [
        ["Type", "Total"],
        ["Submitted", data.totalSubmits[0].Submits],
        ["Escalated", data.totalEscalations[0].Submits],
    ];

    // HiOperator splits
    var eventSplit = [
        ["Type", "Total time"],
        ["Submitted", data.totalSubmits[0].Submits],
        ["Escalated", data.totalEscalations[0].Submits],
        ["Add Workflows", data.totalAddWorkflows[0].Submits],
        ["Remove Workflows", data.totalRemoveWorkflows[0].Submits],
    ];

    var timeSplit = [
        ["Type", "Total time"],
        ["Submitted", (data.totalSubmits[0].Submits * data.totalSubmits[0].duration) / (60 * 1000 * 60)],
        ["Escalated", (data.totalEscalations[0].Submits * data.totalEscalations[0].duration) / (60 * 1000 * 60)],
        ["Add Workflows", (data.totalAddWorkflows[0].Submits * data.totalAddWorkflows[0].duration) / (60 * 1000 * 60)],
        ["Remove Workflows", (data.totalRemoveWorkflows[0].Submits * data.totalRemoveWorkflows[0].duration) / (60 * 1000 * 60)],
    ];

    // Agent Chart
    var orderedClients = list.order(data.submitByClient, "Submits biggest");

    var submitByClient = [["Client", "Tickets Submitted"]];
    for (i = 0; i < orderedClients.length; i++) {
        submitByClient.push([orderedClients[i].name, orderedClients[i].Submits]);
    }

    orderedClients = list.order(orderedClients, "duration smallest");

    var timeByClient = [["Client", "Average Duration"]];
    for (i = 0; i < orderedClients.length; i++) {
        timeByClient.push([orderedClients[i].name, orderedClients[i].duration / (60 * 1000)]);
    }

    User.customAnalytics = {
        totals,
        timeChart,
        timeDurationChart,
        submitsVersusEscalations,
        submitByClient,
        timeByClient,
        timeSplit,
        eventSplit,
    };

    UserActions.update();
};

////////////////////////////////////////////////////
// Actual store
////////////////////////////////////////////////////

class UserStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { UserStore: User };
        this.listenToMany(UserActions);
    }

    onLogin(data) {
        login(data);
    }

    onLoginCompleted(user) {
        status.remove(User, "logging in");
        User.auth = user;
        api("get", "/users/login", null, null, null, User, "login");
        get_user(user);
    }

    onLoginFailed(error) {
        status.remove(User, "logging in");
        add_error(String(error).substr(String(error).indexOf(":") + 2, 10000));
        UIActions.show("login");
        this.notify();
    }

    onLoginWithGoogle() {
        var provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            login_hint: "Must be @hioperator.com email",
        });

        firebase.auth().signInWithPopup(provider);
    }

    onVerifyCode(data) {
        verify_code(data);
    }

    onAuthenticateCompleted(result) {
        User.me = result;
        User.loggedIn = true;

        this.notify();

        api("get", "/users/" + User.me.id + "/connected", null, null, null, User, "pinging");
        setup_live_updaters();
        // Figures out if any preloads should occur
        //AgentActions.loadSummary();
        //ClientActions.loadSummary();
        //WorkflowActions.loadSummary();
    }

    onAuthenticateFailed(result) {
        add_error(result.error);
        UIActions.show("login");
        this.notify();
    }

    onResetPassword(obj) {
        reset_password(obj.email);
        this.notify();
    }

    onResetPasswordCompleted(result) {
        status.remove(User, "resetting password");
        UIActions.addMessage("We just emailed you a password reset link. Check your email.", "success");
        UIActions.show("login");
        this.notify();
    }

    onResetPasswordFailed(error) {
        status.remove(User, "resetting password");
        add_error(error);
        this.notify();
    }

    onLogout() {
        logout();
    }

    onStartViewing(data) {
        if (User.sessionId !== undefined) {
            db.collection("activeSessions").doc(User.sessionId).update({ viewing: data });
        }
    }

    onStopViewing() {
        if (User.sessionId !== undefined) {
            db.collection("activeSessions").doc(User.sessionId).update({ viewing: firebase.firestore.FieldValue.delete() });
        }
    }

    onAddEvent(event, ticketDuration) {
        if (User.me !== undefined) {
            ClientActions.get(function (Client) {
                event.userId = User.me.id;
                event.duration = new Date() - event.start;
                event.duration = event.duration > 0 ? event.duration : 0;
                event.queueCount = Client.working.queueCount;

                ticketDuration += event.duration;
                var ref = db.collection("events").doc();

                ref.set(event)
                    .then(function () {
                        event.eventId = ref.id;
                        api("post", "/agents/events", event, null, null, User, "adding event");
                    })
                    .catch(UserActions.error);

                if (
                    [undefined, null, "", 0].indexOf(ticketDuration) === -1 &&
                    [null, undefined, ""].indexOf(event.clientId) === -1 &&
                    [null, undefined, ""].indexOf(event.ticketId) === -1
                ) {
                    db.collection("clients")
                        .doc(event.clientId)
                        .collection("tickets")
                        .doc(event.ticketId)
                        .update({ duration: ticketDuration })
                        .then(function () {
                            console.log("Updated event duration");
                        })
                        .catch(UserActions.error);
                }
            });
        } else
            setup_global_session_and_login(function () {
                UserActions.addEvent(event, ticketDuration);
            });
    }

    onAddAnalytics(event) {
        //add_analytics(event);
    }

    onRunAnalytics(message) {
        const statusCode = "running analytics";

        if (!status.has(User, statusCode))
            api(
                "post",
                User.me.companyHome ? "/users/" + User.me.id + "/analytics" : "/agents/" + User.me.id + "/analytics",
                message,
                UserActions.runAnalyticsCompleted,
                UserActions.error,
                User,
                statusCode
            );
    }

    onRunAnalyticsCompleted(results) {
        if (User.me.companyHome) parse_analytics_manager(results);
        else parse_analytics_agent(results);
    }

    onLoadUsersClients() {
        var clients = [];
        for (var i = 0; i < User.permissions.length; i++) clients.push(User.permissions[i].clientId);

        const statusCode = "getting user clients";

        if (clients.length > 0) {
            api("post", "/users/myclients", { clients }, UserActions.loadUsersClientsCompleted, UserActions.error, User, statusCode);
        }
    }

    onLoadUsersClientsCompleted(results) {
        var triageClients = [];
        var workflowClients = [];
        for (var i = 0; i < results.length; i++) {
            if (permissions.check_client(User, "tickettriage", results[i].id))
                triageClients.push({ text: results[i].name, value: results[i].id });
            if (permissions.check_client(User, "ticketworkflows", results[i].id))
                workflowClients.push({ text: results[i].name, value: results[i].id });
        }

        TicketActions.setClientOptions(triageClients, workflowClients);
    }

    onGet(callback) {
        callback(User);
    }

    onGetApi(callback) {
        firebase
            .auth()
            .currentUser.getIdToken()
            .then(function (token) {
                User.token = token;
                callback(User);
            });
    }

    onClockIn() {
        const statusCode = "clock in";

        api("get", "/users/clockin", null, UserActions.loadUsersClientsCompleted, UserActions.error, User, statusCode);
    }

    onClockOut() {
        const statusCode = "clock in";

        api("get", "/users/clockout", null, UserActions.loadUsersClientsCompleted, UserActions.error, User, statusCode);
    }

    onUpdate() {
        this.notify();
    }

    onError(error) {
        UIActions.addMessage(error, "error");
    }

    notify() {
        console.log("notify user");
        var me = User.me !== undefined ? objects.clone(User.me) : undefined;
        var events = User.events !== undefined ? objects.clone(User.events) : {};

        this.setState({
            UserStore: {
                status: User.status,
                me,
                loggedIn: User.loggedIn,
                disconnected: User.disconnected,
                sessionId: User.sessionId,
                events: events,
                customAnalytics: User.customAnalytics,
                permissions: User.permissions || [],
                pages: User.pages || [],
                verificationId: User.verificationId || null,
            },
        });
    }
}

export default UserStore;
