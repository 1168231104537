import React from "react";

import integrations from "../../integrations";

// Actions
import ClientActions from "../../actions/ClientActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "left",
	maxWidth: "1000px"
};

const rowStyle = {
	width: "calc(100% + 16px)",
	textAlign: "right",
	margin: "0px -24px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const sectionStyle = {
	width: "100%",
	display: "inline-block"
};

const titleStyle = {
	padding: "16px 0px",
	width: "100%",
	fontWeight: "700",
	fontSize: "16px"
};

var options = [];
var i, j, singleIntegration, added;
for (i = 0; i < integrations.length; i++) {
	singleIntegration = {
		text: integrations[i].name,
		value: integrations[i].type,
		icon: integrations[i].icon,
		fields: integrations[i].apiFields
	};

	added = false;

	for (j = 0; j < options.length; j++) {
		if (options[j].title === integrations[i].category) {
			options[j].items.push(singleIntegration);
			added = true;
			break;
		}
	}

	if (!added) options.push({ title: integrations[i].category, items: [singleIntegration] });
}

class CardType extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick(event) {
		var newIntegration = { type: this.props.item.value };
		for (var i = 0; i < this.props.item.fields.length; i++) {
			newIntegration[this.props.item.fields[i].field] = "";
		}

		ClientActions.add("integrations", [], newIntegration);
		UIActions.showOverlay("");
	}

	render() {
		const primaryStyle = {
			padding: "16px",
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "200px",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			wordWrap: "break-word",
			position: "relative",
			display: "inline-block"
		};

		const iconStyle = {
			verticalAlign: "middle",
			marginRight: "16px",
			color: this.props.item.iconColor !== undefined ? this.props.item.iconColor : "inherit",
			fontSize: "24px",
			transform: this.props.item.iconRotation !== undefined ? "rotate(" + this.props.item.iconRotation + "deg)" : ""
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				<i style={iconStyle} className="material-icons">
					{this.props.item.icon}
				</i>
				{this.props.item.text || ""}
			</div>
		);
	}
}

class Section extends React.Component {
	render() {
		return (
			<div style={sectionStyle}>
				<div style={titleStyle}>{this.props.item.title}</div>
				{this.props.item.items.map((item, index, arr) => (
					<CardType key={"card-type-" + this.props.index + "-" + index} item={item} />
				))}
			</div>
		);
	}
}

class AddIntegrationOverlay extends React.Component {
	_onClose() {
		UIActions.showOverlay("");
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="1000px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<H1 text="Choose Integration" textAlign="center" />
						{options.map((item, index, arr) => (
							<Section key={"add-card-section-" + index} index={index} item={item} />
						))}
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default AddIntegrationOverlay;
