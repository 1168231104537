export default {
    name: "Stripe",
    type: "stripe",
    category: "Other",
    icon: "attach_money",
    apiFields: [
        {
            field: "apiKey",
            label: "Secret API Key",
            type: "text"
        }
    ],
    cardFields: [
        {
            field: "action",
            label: "Stripe Action",
            type: "dropdown",
            items: [
                {
                    text: "Get payments",
                    value: "get payments"
                },
                {
                    text: "Get customer",
                    value: "get customer"
                },
                {
                    text: "Get connected account",
                    value: "get connected account"
                },
                {
                    text: "Refund charges",
                    value: "refund charges"
                },
                {
                    text: "Cancel subscription",
                    value: "cancel subscription"
                },
                {
                    text: "Update subscription",
                    value: "update subscription"
                }
            ]
        },
        {
            field: "customerId",
            label: "Customer ID",
            type: "text",
            helpText: "e.g., cus_DKPvtrYuP83e2U",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get payments"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get customer"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get connected account"
                        }
                    ]
                }
            ]
        },
        {
            field: "reason",
            label: "Reason for refund",
            type: "dropdown",
            items: [
                {
                    text: "Duplicate",
                    value: "duplicate"
                },
                {
                    text: "Fraudulent",
                    value: "fraudulent"
                },
                {
                    text: "Requested by customer",
                    value: "requested_by_customer"
                }
            ],
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        }
                    ]
                }
            ]
        },
        {
            field: "refund_multiple",
            label: "Refund one charge or multiple",
            type: "dropdown",
            items: [
                {
                    text: "Single charges",
                    value: "single"
                },
                {
                    text: "Multiple charges",
                    value: "multiple"
                }
            ],
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        }
                    ]
                }
            ]
        },
        {
            field: "note",
            label: "Refund Note",
            helpText: "e.g., Customer asked for refund; ZD 202",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        }
                    ]
                }
            ]
        },
        {
            field: "amount",
            label: "Refund Amount",
            helpText: "e.g., 10.50",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        },
                        {
                            if: "refund_multiple",
                            state: "is",
                            value: "single"
                        }
                    ]
                }
            ]
        },
        {
            field: "chargeId",
            label: "Charge ID",
            helpText: "e.g., ch_1Ctl87LxBB5ACPV8KPBrWGGs",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        },
                        {
                            if: "refund_multiple",
                            state: "is",
                            value: "single"
                        }
                    ]
                }
            ]
        },
        {
            field: "charges",
            label: "Charges field",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund charges"
                        },
                        {
                            if: "refund_multiple",
                            state: "is",
                            value: "multiple"
                        }
                    ]
                }
            ]
        },
        {
            field: "subscriptionId",
            label: "Subscription ID",
            helpText: "e.g., sub_88viPvjFrsS91d",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update subscription"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "cancel subscription"
                        }
                    ]
                }
            ]
        },
        {
            field: "arguments",
            label: "Arguments",
            helpText: "JSON object e.g., {'prorate': false}",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update subscription"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "cancel subscription"
                        }
                    ]
                }
            ]
        }
    ]
};
