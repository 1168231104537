import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Row from "../_common/Row.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";
import CardTag from "../_common/CardTag.react";

// Forms
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";

// Buttons
import Button from "../_common/buttons/Button.react";
import FAB from "../_common/buttons/FAB.react";
import ProductActions from "../../actions/ProductActions";
import DocumentActions from "../../actions/DocumentActions";

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const buttonRowStyle = {
	width: "100%",
	marginTop: "32px",
	textAlign: "left"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const linkStyle = {
	width: "600px",
	marginBottom: "8px"
};

const descriptionStyle = {
	width: "800px",
	marginBottom: "8px"
};

const closeIconStyle = {
	position: "absolute",
	right: "16px",
	top: "16px",
	cursor: "pointer"
};

const buttonBottomRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const macroStyle = {
	width: "400px",
	marginRight: "16px",
	marginBottom: "8px"
};

const buttonStyle = {
	margin: "0px 8px"
};

class Link extends React.Component {
	constructor(props) {
		super(props);
		this._onRemove = this._onRemove.bind(this);
	}

	_onRemove() {
		DocumentActions.remove([this.props.item.id]);
	}

	render() {
		const rowStyle = {
			color: "rgba(0, 0, 0, 0.87)",
			padding: "8px 0px 8px 16px",
			fontSize: "14px",
			width: "calc(100% - 16px)",
			borderBottom: "1px solid lightgrey",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative"
		};

		return (
			<div style={rowStyle}>
				<Input
					field="url"
					label="URL"
					value={this.props.item.url}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={linkStyle}
				/>
				<InputMultiline
					field="description"
					label="Description"
					value={this.props.item.description}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={descriptionStyle}
				/>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
					close
				</i>
			</div>
		);
	}
}

class FAQ extends React.Component {
	constructor(props) {
		super(props);
		this._onRemove = this._onRemove.bind(this);
	}

	_onRemove() {
		DocumentActions.remove([this.props.item.id]);
	}

	render() {
		const rowStyle = {
			color: "rgba(0, 0, 0, 0.87)",
			padding: "8px 0px 8px 16px",
			fontSize: "14px",
			width: "calc(100% - 16px)",
			borderBottom: "1px solid lightgrey",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative"
		};

		return (
			<div style={rowStyle}>
				<Input
					field="question"
					label="Question"
					value={this.props.item.question}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={linkStyle}
				/>
				<Input
					field="macro"
					label="Macro"
					value={this.props.item.macro}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={macroStyle}
				/>
				<Input
					field="workflow"
					label="Workflow"
					value={this.props.item.workflow}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={macroStyle}
				/>
				<InputMultiline
					field="answer"
					label="Answer"
					value={this.props.item.answer}
					onBlur={DocumentActions.setField}
					location={[this.props.item.id]}
					style={descriptionStyle}
				/>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
					close
				</i>
			</div>
		);
	}
}

class Documents extends React.Component {
	constructor(props) {
		super(props);
		this._onNewLink = this._onNewLink.bind(this);
		this._onNewFAQ = this._onNewFAQ.bind(this);

		this._onNextLinkPage = this._onNextLinkPage.bind(this);
		this._onPreviousLinkPage = this._onPreviousLinkPage.bind(this);

		this._onNextFAQPage = this._onNextFAQPage.bind(this);
		this._onPreviousFAQPage = this._onPreviousFAQPage.bind(this);

		this.state = { linksPage: 0, faqPage: 0 };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNewLink() {
		DocumentActions.add("links", [], {
			url: "",
			description: ""
		});
	}

	_onNewFAQ() {
		DocumentActions.add("faq", [], {
			question: "",
			answer: "",
			macro: "",
			workflow: ""
		});
	}

	_onNextLinkPage() {
		this.setState({ linksPage: this.state.linksPage + 1 });
		window.scrollTo(0, 0);
	}

	_onPreviousLinkPage() {
		this.setState({ linksPage: this.state.linksPage - 1 });
		window.scrollTo(0, 0);
	}

	_onNextFAQPage() {
		this.setState({ faqPage: this.state.faqPage + 1 });
		window.scrollTo(0, 0);
	}

	_onPreviousFAQPage() {
		this.setState({ faqPage: this.state.faqPage - 1 });
		window.scrollTo(0, 0);
	}

	render() {
		const links = (this.props.item.links || []).slice(this.state.linksPage * 25, 25 * (this.state.linksPage + 1));
		const faq = (this.props.item.faq || []).slice(this.state.faqPage * 25, 25 * (this.state.faqPage + 1));

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="LINKS" subtext="Links to google sheets and external websites.">
						{links.length === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no links</div>
								<Button text="Add Link" onClick={this._onNewLink} />
							</div>
						) : (
							<div style={integrationsStyle}>
								{links.map((item, index) => (
									<Link key={"document-link-" + item.id} item={item} index={index} />
								))}
								<div key="workflow-button-row" style={buttonRowStyle}>
									<Button text="Add Link" onClick={this._onNewLink} />
								</div>
								<div style={buttonBottomRow}>
									{this.state.linksPage > 0 ? (
										<Button text={"Previous Page"} onClick={this._onPreviousLinkPage} style={buttonStyle} />
									) : (
										""
									)}
									{links.length === 25 ? (
										<Button text={"Next Page"} onClick={this._onNextLinkPage} style={buttonStyle} />
									) : (
										""
									)}
								</div>
							</div>
						)}
						<CardTag text={"Page " + (this.state.linksPage + 1)} color="orange" />
					</SectionCard>
					<SectionCard title="FAQ" subtext="Frequently asked questions and answers for client.">
						{faq.length === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no FAQ items</div>
								<Button text="Add FAQ" onClick={this._onNewFAQ} />
							</div>
						) : (
							<div style={integrationsStyle}>
								{faq.map((item, index) => (
									<FAQ key={"document-faq-" + item.id} item={item} index={index} />
								))}
								<div key="workflow-button-row" style={buttonRowStyle}>
									<Button text="Add FAQ" onClick={this._onNewFAQ} />
								</div>
							</div>
						)}
						<div style={buttonBottomRow}>
							{this.state.faqPage > 0 ? (
								<Button text={"Previous Page"} onClick={this._onPreviousFAQPage} style={buttonStyle} />
							) : (
								""
							)}
							{faq.length === 25 ? <Button text={"Next Page"} onClick={this._onNextFAQPage} style={buttonStyle} /> : ""}
						</div>
						<CardTag text={"Page " + (this.state.faqPage + 1)} color="orange" />
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default Documents;
