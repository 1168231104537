import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import Card from "../Card.react";
import Menu from "../Menu.react";

// Form items
import Button from "../buttons/Button.react";

import m from "../../../functions/m";
import * as objects from "../../../functions/objects";

const menuOptions = [
	{
		text: "Validation",
		value: "validation"
	},
	{
		text: "Resources",
		value: "resources"
	},
	{
		text: "Javascript",
		value: "javascript"
	},
	{
		divider: true
	},
	{
		text: "Delete card",
		value: "delete"
	}
];

const agentMenuOptions = [
	{
		text: "Escalate",
		value: "escalate"
	},
	{
		text: "Internal note",
		value: "internal note"
	},
	{
		divider: true
	},
	{
		text: "Wrong workflow",
		value: "wrong workflow"
	}
];

const primaryStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	position: "relative",
	minHeight: "124px"
};

const cardStyle = {
	width: "568px",
	padding: "16px",
	margin: "32px 0px"
};

const headerStyle = {
	paddingBottom: "16px",
	width: "100%"
};

const leftHeaderStyle = {
	width: "calc(100% - 50px)",
	display: "inline-block",
	verticalAlign: "middle"
};

const rightHeaderStyle = {
	width: "50px",
	display: "inline-block",
	verticalAlign: "middle",
	textAlign: "right"
};

const imageStyle = {
	height: "40px",
	width: "40px",
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "16px",
	borderRadius: "50%",
	fontWeight: "900",
	fontFamily: "Roboto",
	fontSize: "36px",
	textAlign: "center"
};

const conceptStyle = {
	fontSize: "24px",
	lineHeight: "28px",
	color: "rgba(0, 0, 0, 0.87)",
	fontFamily: "Roboto",
	fontWeight: "400",
	display: "inline-block",
	verticalAlign: "middle",
	width: "430px",
	border: "none",
	outline: "none",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const buttonRowStyle = {
	textAlign: "center",
	width: "100%",
	display: "inline-block",
	marginTop: "16px"
};

class QuestionCardScaffold extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onConceptChange = this._onConceptChange.bind(this);
		this._onDeleteCard = this._onDeleteCard.bind(this);
		this._onNext = this._onNext.bind(this);

		this.state = {
			concept: this.props.item.concept
		};
	}

	_onDeleteCard() {
		this.props.onMoveView("");
		WorkflowActions.removeCard([this.props.id]);
	}

	_onConceptChange(event) {
		this.setState({ concept: event.currentTarget.value });
		WorkflowActions.setField("concept", [this.props.id], event.currentTarget.value, true);
	}

	_onNext() {
		if (this.props.item.type === "escalate" && this.props.item.value === "internal")
			this._updateFn("value", null, "escalate");
		else if (this.props.item.type === "email") {
			var valid = this.props.valid;
			UIActions.showConfirmation({
				title: "Send response",
				text: "Are you sure you want to submit this response?",
				buttonText: "Send response",
				callback: function() {
					WorkflowActions.nextCard(valid);
				}
			});
		} else WorkflowActions.nextCard(this.props.nextPath || 0);
	}

	_updateFn(field, location, value) {
		var agentMode = this.props.agentMode;
		location = [this.props.item.id];

		if (value === "delete") {
			if (this.props.item.to !== undefined && this.props.item.to.length > 1)
				UIActions.addMessage("You can only delete a card if it has 0 or 1 branches. You currently have 2.", "error");
			else {
				UIActions.showConfirmation({
					title: "Delete card",
					text: "Are you sure you want to delete this card?",
					buttonText: "Delete Card",
					callback: this._onDeleteCard
				});
			}
		} else if (["validation", "resources", "javascript"].indexOf(value) > -1) {
			WorkflowActions.get(function(Workflow) {
				var obj;
				if (agentMode) obj = Workflow.active.card;
				else {
					obj = objects.find(Workflow.working, null, location);
					if (obj !== null && obj.obj !== undefined) obj = obj.obj;
				}

				if ([undefined, null].indexOf(obj) === -1) UIActions.showOverlay(value, obj);
				else UIActions.addMessage("Something went wrong :(", "error");
			});
		} else if (value === "internal note") {
			UIActions.showOverlay("internal note", { text: "" });
		} else if (value === "escalate") {
			UIActions.showOverlay("internal note", {
				escalate: true,
				workflow: true,
				text:
					"What my issue is: <br><br> What I have tried: <br><br> Proposed solution: <br><br>Is this in the ticket guide/what row in the ticket guide did you get stuck on?"
			});
		} else if (value === "wrong workflow") {
			UIActions.showOverlay("internal note", {
				eject: true,
				workflow: true,
				text: "Why is this the wrong workflow?"
			});
		}
	}

	render() {
		return (
			<div style={primaryStyle}>
				<Card id={this.props.id} key={this.props.id} style={m(cardStyle, this)}>
					<div style={headerStyle}>
						<div style={leftHeaderStyle}>
							{this.props.picture !== undefined ? (
								<img
									style={imageStyle}
									src={this.props.picture}
									alt={this.props.name !== undefined ? this.props.name.substr(0, 1) : ""}
								/>
							) : (
								""
							)}
							<input
								style={conceptStyle}
								value={this.state.concept}
								onChange={this._onConceptChange}
								placeholder="Central information"
								readOnly={this.props.agentMode}
							/>
						</div>
						<div style={rightHeaderStyle}>
							<Menu
								id={"menu-" + this.props.id}
								items={this.props.agentMode ? agentMenuOptions : menuOptions}
								updateFn={this._updateFn}
							/>
						</div>
					</div>
					{this.props.children}
					{this.props.agentMode && !this.props.noNextButton ? (
						<div style={buttonRowStyle}>
							<Button
								text={this.props.valid ? "NEXT" : "I don't have it"}
								type={this.props.valid ? "" : "flat"}
								onClick={this._onNext}
							/>
						</div>
					) : (
						""
					)}
				</Card>
			</div>
		);
	}
}

export default QuestionCardScaffold;
