import React from "react";
import ReactDOM from "react-dom";
import { Editor, getEventTransfer } from "slate-react";
import Html from "slate-html-serializer";
import { isKeyHotkey } from "is-hotkey";
//import SoftBreak from "slate-soft-break";
import PasteLinkify from "slate-paste-linkify";
import isUrl from "is-url";

// Functions
import m from "../../../functions/m";
import validate from "../../../functions/validate";
import errors from "../../../functions/errors";
import * as predictive from "../../../functions/predictive";
import * as dom from "../../../functions/dom";

const plugins = [PasteLinkify()]; //SoftBreak(),

const BLOCK_TAGS = {
	p: "paragraph",
	div: "div",
	li: "list-item",
	ul: "bulleted-list",
	ol: "numbered-list",
	quote: "quote",
	blockquote: "block-quote",
	pre: "code",
	h1: "heading-one",
	h2: "heading-two",
	link: "link",
	image: "img"
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
	strong: "bold",
	b: "bold",
	em: "italic",
	u: "underlined",
	s: "strikethrough",
	code: "code"
};

const rules = [
	{
		deserialize(el, next) {
			const type = BLOCK_TAGS[el.tagName.toLowerCase()];
			if (type) {
				return {
					object: "block",
					type: type,
					data: {
						className: el.getAttribute("class"),
						hidden: el.getAttribute("hidden")
					},
					nodes: next(el.childNodes)
				};
			}
		},
		serialize(obj, children) {
			if (obj.object === "block") {
				switch (obj.type) {
					case "code":
						return (
							<pre>
								<code>{children}</code>
							</pre>
						);
					case "paragraph":
						return (
							<p className={obj.data.get("className")} hidden={obj.data.get("hidden") || false}>
								{children}
							</p>
						);
					case "div":
						return <div className={obj.data.get("className")}>{children}</div>;
					case "quote":
						return <blockquote>{children}</blockquote>;
					case "heading-one":
						return <h1>{children}</h1>;
					case "heading-two":
						return <h2>{children}</h2>;
					case "list-item":
						return <li>{children}</li>;
					case "numbered-list":
						return <ol>{children}</ol>;
					case "bulleted-list":
						return <ul>{children}</ul>;
					case "block-quote":
						return <blockquote>{children}</blockquote>;
					default:
						return { children } || "";
				}
			}
		}
	},
	// Add a new rule that handles marks...
	{
		deserialize(el, next) {
			const type = MARK_TAGS[el.tagName.toLowerCase()];

			if (el.childNodes.length === 1 && el.childNodes[0].tagName === "BR")
				return {
					object: "text",
					text: "\n"
				};
			else if (type) {
				var myNodes = {
					object: "mark",
					type: type,
					nodes: next(el.childNodes)
				};

				for (var i = 0; i < myNodes.nodes.length; i++) {
					if (myNodes.nodes[i].text === "\n") {
						return {
							object: "text",
							text: "\n"
						};
					}
				}

				return myNodes;
			}
		},
		serialize(obj, children) {
			if (obj.object === "mark") {
				switch (obj.type) {
					case "bold":
						return <strong>{children}</strong>;
					case "italic":
						return <em>{children}</em>;
					case "underlined":
						return <u>{children}</u>;
					case "code":
						return (
							<pre>
								<code>{children}</code>
							</pre>
						);
					default:
						return { children };
				}
			}
		}
	},
	{
		// Special case for links, to grab their href.
		deserialize(el, next) {
			if (el.tagName.toLowerCase() === "a") {
				return {
					object: "inline",
					type: "link",
					nodes: next(el.childNodes),
					data: {
						href: el.getAttribute("href")
					}
				};
			}
		},
		serialize(obj, children) {
			if (obj.type === "link") {
				return <a href={obj.data.get("href")}>{children}</a>;
			}
		}
	},
	{
		// Special case for images, to grab their href.
		deserialize(el, next) {
			if (el.tagName.toLowerCase() === "img") {
				return {
					object: "inline",
					type: "image",
					nodes: next(el.childNodes),
					data: {
						src: el.getAttribute("src"),
						alt: el.getAttribute("alt")
					}
				};
			}
		},
		serialize(obj, children) {
			if (obj.type === "image") {
				return <img src={obj.data.get("src")} alt={obj.data.get("alt")} />;
			}
		}
	},
	// Rule handling line breaks
	{
		deserialize(el) {
			if (el.tagName.toLowerCase() !== "br") return;

			return {
				object: "text",
				text: "\n"
			};
		},
		serialize(obj, children) {
			if (obj.type === "text" && children === "\n") return <br />;
		}
	}
];

const html = new Html({ rules });

// Sets up hotkeys
const isBoldHotkey = isKeyHotkey("mod+b");
const isItalicHotkey = isKeyHotkey("mod+i");
const isUnderlinedHotkey = isKeyHotkey("mod+u");
const isCodeHotkey = isKeyHotkey("mod+`");

const hoverMenuStyle = {
	position: "absolute",
	zIndex: "100",
	top: "-10000px",
	left: "-10000px",
	marginTop: "-6px",
	opacity: "0",
	backgroundColor: "#222",
	borderRadius: "4px",
	transition: "opacity 0.3s",
	display: "flex"
};

var wrapLink = (editor, href) => {
	editor.wrapInline({
		type: "link",
		data: { href }
	});

	editor.moveToEnd();
};

var unwrapLink = editor => {
	editor.unwrapInline("link");
};

const blocks = ["block-quote"];

class MenuButton extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick(event) {
		const { editor } = this.props;
		event.preventDefault();
		if (this.props.type === "link") {
			const href = window.prompt("Enter the URL of the link:");
			if (href === null) {
				return;
			}

			editor.command(wrapLink, href);
		} else if (blocks.indexOf(this.props.type) === -1) {
			editor.toggleMark(this.props.type);
		} else {
			// Handle everything but list buttons.
			const isActive = this.props.hasBlock(this.props.type);
			editor.setBlocks(isActive ? "div" : this.props.type);
		}
	}

	render() {
		var backgroundColor = "transparent";
		if (this.props.selected) backgroundColor = "white";
		else if (this.state.hover) backgroundColor = "grey";

		const mainStyle = {
			backgroundColor: backgroundColor,
			border: "none",
			color: this.props.selected ? "black" : "white",
			cursor: "pointer",
			display: "inline-block",
			verticalAlign: "middle",
			transition: "all .2s ease"
		};

		const iconStyle = {
			marginTop: "3px",
			fontSize: "18px"
		};

		const textIconStyle = {
			fontSize: "18px"
		};

		return (
			<button
				style={mainStyle}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				onMouseDown={this._onClick}
			>
				{this.props.html ? (
					<div dangerouslySetInnerHTML={{ __html: this.props.icon }} style={textIconStyle} />
				) : (
					<i style={iconStyle} className="material-icons">
						{this.props.icon}
					</i>
				)}
			</button>
		);
	}
}

class HoverMenu extends React.Component {
	render() {
		const root = window.document.getElementById("root");

		return ReactDOM.createPortal(
			<div style={hoverMenuStyle} ref={this.props.innerRef}>
				<MenuButton type="bold" icon="format_bold" editor={this.props.editor} hasBlock={this.props.hasBlock} />
				<MenuButton type="italic" icon="format_italic" editor={this.props.editor} hasBlock={this.props.hasBlock} />
				<MenuButton
					type="underlined"
					icon="format_underlined"
					editor={this.props.editor}
					hasBlock={this.props.hasBlock}
				/>
				<MenuButton type="code" icon="code" editor={this.props.editor} hasBlock={this.props.hasBlock} />
				<MenuButton type="block-quote" icon="format_quote" editor={this.props.editor} hasBlock={this.props.hasBlock} />
				<MenuButton type="link" icon="link" editor={this.props.editor} hasBlock={this.props.hasBlock} />
			</div>,
			root
		);
	}
}

const predictBreaks = ["\n", ". ", "! ", "? "];

function getInput(value) {
	if (!value.startText) {
		return null;
	}

	if (value.selection.end.offset < value.startText.text.length) return null;

	const startOffset = value.selection.start.offset;
	const textBefore = value.startText.text.slice(0, startOffset);
	var lastBreak = -1;
	var adjustBreak = 2;

	var tempBreak;
	for (var i = 0; i < predictBreaks.length; i++) {
		tempBreak = textBefore.lastIndexOf(predictBreaks[i]);
		if (tempBreak > lastBreak) {
			lastBreak = tempBreak;
			if (predictBreaks[i] === "\n") adjustBreak = 1;
			else adjustBreak = 2;
		}
	}

	if (lastBreak === -1) return textBefore;

	var index = lastBreak + adjustBreak;

	return textBefore.substr(index, value.selection.end.offset);
}

class InputMultiline extends React.Component {
	constructor(props) {
		super(props);
		this._onFocus = this._onFocus.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onKeyDown = this._onKeyDown.bind(this);
		this._onLabelClick = this._onLabelClick.bind(this);
		this._onPaste = this._onPaste.bind(this);
		this._updateMenu = this._updateMenu.bind(this);

		this.state = {
			focus: false,
			prediction: "",
			value: html.deserialize(this.props.value || ""),
			htmlValue: this.props.value || "",
			error: errors(this.props.errors, this.props.id) || ""
		};
	}

	componentWillReceiveProps(nextProps) {
		var setValue = false;
		var setError = false;
		var htmlValue = "";

		if (
			nextProps.value !== undefined &&
			nextProps.value !== this.state.htmlValue &&
			nextProps.value !== this.props.value
		) {
			htmlValue = nextProps.value || "";
			setValue = html.deserialize(nextProps.value || "");
		}

		if (nextProps.validate && nextProps.validation !== undefined && Array.isArray(nextProps.validation))
			setError = validate(nextProps.validation, this.state.value) || "";

		if (nextProps.errors !== undefined && nextProps.errors.length > 0)
			setError = errors(nextProps.errors, nextProps.id) || setError;

		if (setValue !== false && setError !== false)
			this.setState({ value: setValue, htmlValue: htmlValue, error: setError });
		else if (setValue !== false) this.setState({ value: setValue, htmlValue: htmlValue });
		else if (setError !== false) this.setState({ error: setError });
	}

	componentDidMount = () => {
		this._updateMenu();
	};

	componentDidUpdate = () => {
		this._updateMenu();
	};

	_onFocus(event, editor, next) {
		//this.setState({ focus: true });
		//editor.focus();
	}

	_onBlur(event, editor, next) {
		editor.blur();
		this._onChange({ value: editor.value });

		var value = html.serialize(this.state.value);

		var error =
			this.props.validation !== undefined && Array.isArray(this.props.validation)
				? validate(this.props.validation, value)
				: "";

		this.setState({ focus: false, prediction: "", error: error });
		if (this.props.onBlur !== undefined) this.props.onBlur(this.props.field, this.props.location, value);
		this._updateMenu();
	}

	_onChange = change => {
		var value = change.value;
		const htmlValue = html.serialize(value);

		if (this.props.predict) {
			const lastSentence = getInput(change.value);
			if (lastSentence !== null) {
				const prediction = lastSentence !== null && lastSentence.length > 2 ? predictive.query(lastSentence) : null;

				if (prediction) {
					this.setState({
						value: value,
						htmlValue: htmlValue,
						focus: value.selection.isFocused,
						prediction: prediction
					});
					return;
				}
			}
		}

		this.setState({ value: value, htmlValue: htmlValue, focus: value.selection.isFocused, prediction: "" });
		if (this.props.updateFn !== undefined) this.props.updateFn(this.props.field, this.props.location, htmlValue);
	};

	_onKeyDown(event, editor, next) {
		let mark;

		if (isBoldHotkey(event)) {
			mark = "bold";
		} else if (isItalicHotkey(event)) {
			mark = "italic";
		} else if (isUnderlinedHotkey(event)) {
			mark = "underlined";
		} else if (isCodeHotkey(event)) {
			mark = "code";
		}

		if (mark !== undefined) {
			editor.toggleMark(mark);
			event.preventDefault();
		} else if (this.props.keyDown === undefined) {
			var code = event.keyCode ? event.keyCode : event.which;

			if (code === 9 && ["", null, undefined].indexOf(this.state.prediction) === -1) {
				event.preventDefault();
				editor.insertText(this.state.prediction).focus();
			} else if (code === 13 && this.props.onReturn !== undefined && !event.shiftKey) {
				event.preventDefault();
				this.props.onReturn(this.props.field, this.props.location, this.state.htmlValue);
			} else if (
				(code === 8 || code === 46) &&
				["", "<p></p>", "<div></div>"].indexOf(this.state.htmlValue) > -1 &&
				this.props.onBack !== undefined &&
				!event.shiftKey
			)
				this.props.onBack(this.props.field, this.props.location, event.currentTarget.value);
			else return next();
		} else {
			this.props.keyDown(event);
		}
	}

	_onLabelClick() {
		if (!this.state.focus && this.state.value === "" && this.props.id !== undefined)
			document.getElementById(this.props.id).focus();
	}

	_onPaste(event, editor, next) {
		const transfer = getEventTransfer(event);
		const { type, text } = transfer;

		if (type === "html") {
			var { document } = html.deserialize(
				transfer.html.toLowerCase().indexOf("<!doctype") === -1
					? dom.fix_bad_html(transfer.html)
					: dom.fix_bad_html(transfer.text)
			);

			const final = html.deserialize(dom.fix_bad_html(html.serialize({ document })));
			editor.insertFragment(final.document);
		} else if (isUrl(text)) {
			if (editor.value.selection.isCollapsed) return next();

			if (this._hasLinks()) {
				editor.command(unwrapLink);
			}

			editor.command(wrapLink, text);
		} else return next();
	}

	_hasMark = type => {
		const { value } = this.state;
		return value.activeMarks.some(mark => mark.type === type);
	};

	_hasBlock = type => {
		const { value } = this.state;
		return value.blocks.some(node => node.type === type);
	};

	_hasLinks = () => {
		const { value } = this.state;
		return value.inlines.some(inline => inline.type === "link");
	};

	ref = editor => {
		this.editor = editor;
		if (this.props.editorRefs !== undefined) this.props.editorRefs[this.props.id] = editor;
	};

	renderNode = (props, editor, next) => {
		const { attributes, children, node } = props;

		switch (node.type) {
			case "paragraph":
				return (
					<p
						{...attributes}
						data-prediction={props.isSelected && this.state.prediction !== "" ? this.state.prediction : ""}
					>
						{children}
					</p>
				);
			case "div":
				return (
					<div
						{...attributes}
						data-prediction={props.isSelected && this.state.prediction !== "" ? this.state.prediction : ""}
					>
						{children}
					</div>
				);
			case "span":
				return <span {...attributes}>{children}</span>;
			case "block-quote":
				return <blockquote {...attributes}>{children}</blockquote>;
			case "bulleted-list":
				return <ul {...attributes}>{children}</ul>;
			case "heading-one":
				return <h1 {...attributes}>{children}</h1>;
			case "heading-two":
				return <h2 {...attributes}>{children}</h2>;
			case "list-item":
				return <li {...attributes}>{children}</li>;
			case "numbered-list":
				return <ol {...attributes}>{children}</ol>;
			case "link": {
				const { data } = node;
				const href = data.get("href");
				return (
					<a {...attributes} href={href}>
						{children}
					</a>
				);
			}
			case "image": {
				const { data } = node;
				const src = data.get("src");
				const alt = data.get("alt");

				return <img {...attributes} src={src} alt={alt || ""} />;
			}
			default:
				return next();
		}
	};

	renderMark = (props, editor, next) => {
		const { children, mark, attributes } = props;

		switch (mark.type) {
			case "bold":
				return <strong {...attributes}>{children}</strong>;
			case "code":
				return <code {...attributes}>{children}</code>;
			case "italic":
				return <em {...attributes}>{children}</em>;
			case "underlined":
				return <u {...attributes}>{children}</u>;
			default:
				return next();
		}
	};

	renderEditor = (props, editor, next) => {
		const children = next();
		return (
			<React.Fragment>
				{children}
				<HoverMenu innerRef={menu => (this.menu = menu)} editor={editor} hasBlock={this._hasBlock} />
			</React.Fragment>
		);
	};

	onClickMark = (event, type) => {
		event.preventDefault();
		this.editor.toggleMark(type);
	};

	_updateMenu = () => {
		const menu = this.menu;
		if (!menu) return;

		const { value } = this.state;
		const { fragment, selection } = value;

		if (selection.isBlurred || selection.isCollapsed || fragment.text === "" || !this.state.focus) {
			menu.style.top = "-1000%";
			menu.style.opacity = 0;
			return;
		}

		const native = window.getSelection();
		const range = native.getRangeAt(0);
		const rect = range.getBoundingClientRect();
		menu.style.opacity = 1;
		menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;
		menu.style.left = `${rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2}px`;
	};

	render() {
		const primaryStyle = {
			width: "100%",
			display: "inline-block",
			fontSize: "16px",
			position: "relative",
			textAlign: "left"
		};

		var labelColor = "rgba(0, 0, 0, 0.54)";
		if (this.state.error !== "") labelColor = "red";
		else if (this.state.focus || this.state.htmlValue) labelColor = "rgba(0, 0, 0, 0.38)";

		const labelStyle = {
			fontSize: this.state.focus || ["", "<p></p>"].indexOf(this.state.htmlValue) === -1 ? "12px" : "16px",
			color: labelColor,
			position: "absolute",
			transition: "0.2s all ease",
			left: "16px",
			top: this.state.focus || ["", "<p></p>"].indexOf(this.state.htmlValue) === -1 ? "8px" : "20px",
			fontWeight: "400"
		};

		const underlineColor = this.props.noLine
			? "linear-gradient(rgb(70, 180, 175),rgb(70, 180, 175)),linear-gradient(transparent,transparent)"
			: "linear-gradient(rgb(70, 180, 175),rgb(70, 180, 175)),linear-gradient(#D2D2D2,#D2D2D2)";

		const inputStyle = {
			padding: this.props.thin ? "8px 16px" : "28px 16px 8px",
			borderRadius: "4px",
			border: "none",
			fontSize: "16px",
			lineHeight: "24px",
			minHeight: "19px",
			fontFamily: "Roboto",
			fontWeight: "400",
			color: "rgba(0, 0, 0, 0.87)",
			width: "calc(100% - 32px)",
			backgroundColor: this.props.colored ? "rgba(0, 0, 0, 0.06)" : "transparent",
			backgroundImage:
				this.state.error !== "" ? "linear-gradient(red,red),linear-gradient(#D2D2D2,#D2D2D2)" : underlineColor,
			height: "auto"
		};

		const underStyle = {
			display: "inline-block",
			fontSize: "12px",
			marginTop: "8px",
			marginLeft: "16px",
			color: this.state.error === "" ? "rgba(0, 0, 0, 0.54)" : "red",
			fontWeight: "400",
			transition: "0.2s all ease",
			opacity: this.state.error === "" && this.props.helpText === undefined ? "0" : "1"
		};

		return (
			<div style={m(primaryStyle, this)} onClick={this.props.onClick} className="slate">
				{this.props.label ? (
					<span style={labelStyle} onClick={this._onLabelClick}>
						{this.props.label}
					</span>
				) : (
					""
				)}
				<Editor
					spellCheck
					readOnly={this.props.readOnly}
					id={String(this.props.id)}
					plugins={plugins}
					style={m(inputStyle, this, "inputStyle")}
					className={this.props.class || "mui-underline"}
					ref={this.ref}
					value={this.state.value}
					onChange={this._onChange}
					onKeyDown={this._onKeyDown}
					placeholder={this.props.placeholder || ""}
					renderNode={this.renderNode}
					renderMark={this.renderMark}
					renderEditor={this.renderEditor}
					onFocus={this._onFocus}
					onBlur={this._onBlur}
					onPaste={this._onPaste}
				/>
				{!this.props.noHelp && !this.props.thin ? (
					<div style={underStyle}>
						{this.state.error === "" && this.props.helpText ? this.props.helpText : this.state.error}
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

export default InputMultiline;
