import Reflux from "reflux";

// Actions
import TriageOptionsActions from "../actions/TriageOptionsActions";
import UIActions from "../actions/UIActions";
import ClientActions from "../actions/ClientActions";
import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";
import ensure_ids from "../functions/ensure_ids";

// Defines db for firestore
var db = firebase.firestore();

var TriageOptions = {
    status: []
};

// Figures out if inside of client
function client_check() {
    const myRoute = document.location.hash.replace("#", "").split("/");
    if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
    else return null;
}

function get_url() {
    const client = client_check();
    var url;
    if (client !== null) url = "clients/" + client + "/products";
    else url = "products";

    return url;
}

class TriageOptionsStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { TriageOptionsStore: TriageOptions };
        this.listenToMany(TriageOptionsActions);
    }

    onNew() {
        const data = {
            options: []
        };

        data.created = new Date();

        const clientId = url.get_client();

        var ref = db
            .collection("clients")
            .doc(clientId)
            .collection("components")
            .doc("triageoptions");

        ref.set(data)
            .then(function() {
                data.id = "triageoptions";
                TriageOptions.working = data;
                TriageOptionsActions.update();
            })
            .catch(TriageOptionsActions.loadFailed);
    }

    onLoad() {
        const clientId = url.get_client();

        db.collection("clients")
            .doc(clientId)
            .collection("components")
            .doc("triageoptions")
            .get()
            .then(function(snapshot) {
                TriageOptions.working = firestore.extract_single(snapshot);

                if (TriageOptions.working === undefined) TriageOptionsActions.new();
                else TriageOptionsActions.update();
            })
            .catch(TriageOptionsActions.error);
    }

    onSetWorking(data) {
        TriageOptions.working = data;
        this.notify();
    }

    onSave(type) {
        TriageOptions.working.updated = new Date();

        var temp = objects.clone(TriageOptions.working);
        delete temp.id;

        if (["new", "template"].indexOf(type) > -1) temp.created = new Date();

        const clientId = url.get_client();

        var ref = db
            .collection("clients")
            .doc(clientId)
            .collection("components")
            .doc("triageoptions");

        ref.set(temp, { merge: true })
            .then(function() {
                UIActions.addMessage("Triage options saved!", "success");
            })
            .catch(TriageOptionsActions.error);
    }

    onDelete() {
        /*
		const clientId = url.get_client();
		const id = TriageOptions.working.id;
		delete TriageOptions.working;

		db.collection("clients")
			.doc(clientId)
			.collection("products")
			.doc(id)
			.delete()
			.then(function() {
				UIActions.addMessage("TriageOptions catalog deleted!", "success");
			})
			.catch(TriageOptionsActions.error);

		UIActions.showRouter(get_url());
        this.notify();
        */
    }

    onSetField(field, location, value, shouldNotify) {
        console.log("updating faq " + field);
        console.log(value);
        setField(TriageOptions.working, field, location, value, true);
        if (shouldNotify) this.notify();
    }

    onAdd(field, location, obj) {
        ensure_ids(obj);
        obj.created = new Date();
        obj.updated = new Date();

        objects.add(TriageOptions.working, field, location, obj);
        this.notify();
    }

    onRemove(location) {
        objects.remove(TriageOptions.working, location.concat());
        this.notify();
    }

    onClose() {
        TriageOptions.loadedParts = [];
        TriageOptions.allLoaded = false;
        delete TriageOptions.working;
        this.notify();
    }

    onGet(callback) {
        callback(TriageOptions, this.notify);
    }

    onUpdate() {
        this.notify();
    }

    onError(error) {
        UIActions.addMessage(error, "error");
    }

    notify() {
        console.log("notify triage options");
        var working = TriageOptions.working !== undefined ? objects.clone(TriageOptions.working) : undefined;
        this.setState({ TriageOptionsStore: { status: TriageOptions.status, working } });
    }
}

export default TriageOptionsStore;
