import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import TicketActions from "../../actions/TicketActions";

// Scaffolding
import Overlay from "./Overlay.react";
import Card from "./Card.react";
import Container from "./Container.react";

// Form items
import InputMultiline from "./forms/InputMultiline.react";
import Button from "./buttons/Button.react";

// Headers
import H1 from "./headers/H1.react";

const cardStyle = {
	width: "calc(100% - 100px)",
	padding: "24px",
	textAlign: "center",
	maxWidth: "800px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const buttonRowStyle = {
	marginTop: "16px",
	textAlign: "right"
};

const textStyle = {
	textAlign: "left"
};

class InternalNoteOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
		this._onSend = this._onSend.bind(this);

		this._onBlur = this._onBlur.bind(this);

		this.state = {
			text: this.props.item !== undefined && this.props.item.text !== undefined ? this.props.item.text || "" : ""
		};
	}

	_onClose() {
		UIActions.showOverlay("", {});
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
	}

	_onSend() {
		TicketActions.addInternalNote({
			text: this.state.text,
			escalate: this.props.item !== undefined ? this.props.item.escalate : false,
			workflow: this.props.item !== undefined ? this.props.item.workflow : false,
			eject: this.props.item !== undefined ? this.props.item.eject : false
		});

		UIActions.showOverlay("", {});
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="1100px">
					<Card style={cardStyle}>
						<H1
							text={this.props.item !== undefined && this.props.item.escalate ? "Escalation" : "Internal Note"}
							textAlign="center"
						/>
						<InputMultiline
							id={"response-box"}
							field="text"
							value={this.state.text}
							label="Body"
							class=""
							noLine={false}
							onBlur={this._onBlur}
							style={textStyle}
							pasteCorrect={true}
						/>
						<div style={buttonRowStyle}>
							<Button text="Close" style={buttonStyle} type="flat" onClick={this._onClose} />
							<Button text="Send" style={buttonStyle} onClick={this._onSend} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default InternalNoteOverlay;
