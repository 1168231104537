import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Functions
import api from "../../functions/api";

const cardStyle = {
    width: "900px",
    padding: "24px",
    textAlign: "center",
    maxWidth: "calc(100% - 100px)"
};

const rowStyle = {
    width: "calc(100% + 16px)",
    textAlign: "right",
    margin: "0px -24px -24px -24px",
    borderTop: "1px solid lightgrey",
    padding: "16px"
};

class ScheduleOverlay extends React.Component {
    constructor(props) {
        super(props);
        this._onGetSchedule = this._onGetSchedule.bind(this);
        this._onGetScheduleCompleted = this._onGetScheduleCompleted.bind(this);
        this._onDateClick = this._onDateClick.bind(this);
        this._onEventClick = this._onEventClick.bind(this);
        this.calendarRef = React.createRef();

        this.state = { text: "", guru: "", opened: false, calendarEvents: [], calendarWeekends: true };
    }

    componentDidMount() {
        this._onGetSchedule();
    }

    _onGetSchedule() {
        api(
            "get",
            "/users/" + this.props.user.id + "/schedule",
            null,
            this._onGetScheduleCompleted,
            this._onGetScheduleCompleted,
            {},
            "getting schedule"
        );
    }

    _onGetScheduleCompleted(results) {
        var calendarEvents = results.scheduled.map(item => {
            return {
                title: "Shift",
                start: new Date(item.start),
                end: new Date(item.end),
                backgroundColor: "rgb(70, 180, 175)"
            };
        });

        var timesheets = results.timesheets.map(item => {
            return {
                title: "Worked",
                start: new Date(item.start),
                end: new Date(item.end),
                backgroundColor: "orange"
            };
        });

        calendarEvents = calendarEvents.concat(timesheets);

        this.setState({ calendarEvents });
    }

    _onClose() {
        UIActions.showOverlay("");
    }

    _onDateClick(arg) {
        var calendarRef = this.calendarRef.current.getApi();
        calendarRef.changeView("timeGridDay", arg.dateStr);
    }

    _onEventClick(arg) {
        arg = arg.event;
        var calendarRef = this.calendarRef.current.getApi();
        var day = arg.start.getFullYear() + "-" + (arg.start.getMonth() + 1) + "-" + arg.start.getDate();
        calendarRef.changeView("timeGridDay", day);
    }

    render() {
        return (
            <Overlay>
                <Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
                    <Card style={cardStyle}>
                        <H1 text={"My Schedule"} textAlign="center" />
                        <FullCalendar
                            ref={this.calendarRef}
                            defaultView="dayGridMonth"
                            header={{
                                left: "prev,next today",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                            }}
                            firstDay={1}
                            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                            weekends={this.state.calendarWeekends}
                            events={this.state.calendarEvents}
                            dateClick={this._onDateClick}
                            eventClick={this._onEventClick}
                        />
                        <div style={rowStyle}>
                            <Button text="Close" onClick={this._onClose} type="flat" />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default ScheduleOverlay;
