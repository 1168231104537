import Reflux from "reflux";

// Actions
import DocumentActions from "../actions/DocumentActions";
import UIActions from "../actions/UIActions";
import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";
import ensure_ids from "../functions/ensure_ids";
import ClientActions from "../actions/ClientActions";

// Defines db for firestore
var db = firebase.firestore();

var Document = {
	status: []
};

// Figures out if inside of client
function client_check() {
	const myRoute = document.location.hash.replace("#", "").split("/");
	if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
	else return null;
}

function get_url() {
	const client = client_check();
	var url;
	if (client !== null) url = "clients/" + client + "/products";
	else url = "products";

	return url;
}

class DocumentStore extends Reflux.Store {
	constructor() {
		super();
		this.notify = this.notify.bind(this);
		this.state = { DocumentStore: Document };
		this.listenToMany(DocumentActions);
	}

	onNew() {
		const data = {
			links: [],
			faq: []
		};

		data.created = new Date();

		const clientId = url.get_client();

		var ref = db
			.collection("clients")
			.doc(clientId)
			.collection("components")
			.doc("documentation");

		ref
			.set(data)
			.then(function() {
				data.id = "documentation";
				Document.working = data;
				DocumentActions.update();
			})
			.catch(DocumentActions.loadFailed);
	}

	onLoad() {
		const clientId = url.get_client();

		db.collection("clients")
			.doc(clientId)
			.collection("components")
			.doc("documentation")
			.get()
			.then(function(snapshot) {
				Document.working = firestore.extract_single(snapshot);

				if (Document.working === undefined) DocumentActions.new();
				else DocumentActions.update();
			})
			.catch(DocumentActions.error);
	}

	onSetWorking(data) {
		Document.working = data;
		this.notify();
	}

	onSave(type) {
		Document.working.updated = new Date();

		var temp = objects.clone(Document.working);
		delete temp.id;

		if (["new", "template"].indexOf(type) > -1) temp.created = new Date();

		const clientId = url.get_client();

		var ref = db
			.collection("clients")
			.doc(clientId)
			.collection("components")
			.doc("documentation");

		ref
			.set(temp, { merge: true })
			.then(function() {
				UIActions.addMessage("Documentation saved!", "success");
			})
			.catch(DocumentActions.error);
	}

	onDelete() {
		/*
		const clientId = url.get_client();
		const id = Document.working.id;
		delete Document.working;

		db.collection("clients")
			.doc(clientId)
			.collection("products")
			.doc(id)
			.delete()
			.then(function() {
				UIActions.addMessage("Document catalog deleted!", "success");
			})
			.catch(DocumentActions.error);

		UIActions.showRouter(get_url());
        this.notify();
        */
	}

	onSetField(field, location, value, shouldNotify) {
		console.log("updating faq " + field);
		console.log(value);
		setField(Document.working, field, location, value);
		if (shouldNotify) this.notify();
	}

	onAdd(field, location, obj) {
		objects.add(Document.working, field, location, obj);
		this.notify();
	}

	onRemove(location) {
		objects.remove(Document.working, location.concat());
		this.notify();
	}

	onClose() {
		Document.loadedParts = [];
		Document.allLoaded = false;
		delete Document.working;
		this.notify();
	}

	onUpdate() {
		this.notify();
	}

	onError(error) {
		UIActions.addMessage(error, "error");
	}

	notify() {
		console.log("notify documentation");
		var working = Document.working !== undefined ? objects.clone(Document.working) : undefined;
		this.setState({ DocumentStore: { status: Document.status, working } });
	}
}

export default DocumentStore;
