import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Overlay from "./Overlay.react";
import Container from "./Container.react";

// Buttons
import Button from "./buttons/Button.react";

class ConfirmationBox extends React.Component {
	constructor(props) {
		super(props);
		this._onConfirmedClick = this._onConfirmedClick.bind(this);
		this._onCancelClick = this._onCancelClick.bind(this);
	}

	_onConfirmedClick() {
		UIActions.removeConfirmation();
		this.props.data.callback();
	}

	_onCancelClick() {
		UIActions.removeConfirmation();
		if (this.props.data.cancelCallback !== undefined) this.props.data.cancelCallback();
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px 24px 8px"
		};

		const titleStyle = {
			marginBottom: "20px",
			fontSize: "22px",
			fontWeight: "400",
			fontFamily: "Roboto",
			lineHeight: "28px"
		};

		const bodyStyle = {
			marginBottom: "24px",
			fontSize: "16px",
			fontWeight: "400",
			color: "rgba(0, 0, 0, 0.56)"
		};

		const buttonRowStyle = {
			textAlign: "right",
			marginLeft: "-24px",
			width: "calc(100% + 36px)"
		};

		const cancelStyle = {
			color: "indianred"
		};

		const buttonStyle = {
			marginLeft: "8px",
			color: "rgb(70, 180, 175)"
		};

		return (
			<Overlay>
				<Container maxWidth="600px">
					<Card style={cardStyle}>
						<div style={titleStyle}>{this.props.data.title}</div>
						<div style={bodyStyle}>{this.props.data.text}</div>
						<div style={buttonRowStyle}>
							<Button
								text={this.props.data.cancelButtomText || "Nevermind"}
								onClick={this._onCancelClick}
								type="flat"
								style={cancelStyle}
							/>
							<Button
								type="flat"
								text={this.props.data.buttonText}
								style={buttonStyle}
								onClick={this._onConfirmedClick}
							/>
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ConfirmationBox;
