import React from "react";
import Ink from "react-ink";

// Actions
import UIActions from "../../actions/UIActions";

// Form items
import Toggle from "./forms/Toggle.react";

import Button from "./buttons/Button.react";

// Components
import Menu from "./Menu.react";
import MenuItem from "./MenuItem.react";
import UserActions from "../../actions/UserActions";

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this._onFocus = this._onFocus.bind(this);
        this._onClick = this._onClick.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onChange = this._onChange.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);

        this.state = { focus: false, hover: false };
    }

    _onFocus() {
        this.setState({ focus: true });
    }

    _onClick() {
        document.getElementById(this.props.id).focus();
    }

    _onBlur() {
        this.setState({ focus: false });
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onChange(value) {
        this.setState({ focus: false, hover: false });

        if (value === "logout") UserActions.logout();
        else if (value === "clockout") UserActions.clockOut();
        else if (value === "schedule") UIActions.showOverlay("schedule");
        else if (value === "new notification") UIActions.showOverlay("new notification");
    }

    render() {
        const open = this.state.focus || this.state.hover ? true : false;

        const primaryStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: "16px"
        };

        const nameStyle = {
            fontSize: "20px",
            height: "20px",
            width: "40px",
            textAlign: "center",
            padding: "10px 0px",
            backgroundColor: "rgba(0, 0, 0, 0.62)",
            color: "white",
            cursor: "pointer",
            borderRadius: "50%",
            verticalAlign: "middle",
            display: "inline-block",
            lineHeight: "20px"
        };

        const imageStyle = {
            height: "40px",
            width: "40px",
            display: "inline-block",
            verticalAlign: "middle",
            borderRadius: "50%",
            cursor: "pointer",
            fontFamily: "Roboto",
            fontSize: "36px",
            textAlign: "center"
        };

        const buttonStyle = {
            padding: "0px",
            border: "none",
            color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "transparent",
            textAlign: "left",
            cursor: "pointer",
            outline: "none"
        };

        const listStyle = {
            display: open ? "inline-block" : "none",
            position: "absolute",
            top: "100%",
            right: "56px",
            zIndex: "4",
            padding: "0px",
            margin: "0px",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            backgroundColor: "white",
            borderRadius: "2px",
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)",
            maxHeight: "40vh",
            overflowY: "auto",
            width: "200px"
        };

        var options = [];

        if (this.props.UserStore.role === "admin" || this.props.UserStore.canNotify) {
            options = options.concat([{ text: "Add Notification", value: "new notification" }, { divider: true }]);
        }

        options = options.concat([
            { text: "My schedule", value: "schedule" },
            { text: "Clock out", value: "clockout" },
            { divider: true },
            { text: "Logout", value: "logout" }
        ]);

        return (
            <div style={primaryStyle}>
                <button
                    id={this.props.id}
                    style={buttonStyle}
                    onFocus={this._onFocus}
                    onBlur={this._onBlur}
                    onClick={this._onClick}
                >
                    {this.props.UserStore.picture !== undefined ? (
                        <img
                            style={imageStyle}
                            src={this.props.UserStore.picture}
                            onClick={this._onClick}
                            alt={this.props.UserStore.email.substr(0, 1).toUpperCase()}
                        />
                    ) : (
                        <div style={nameStyle}>{this.props.UserStore.email.substr(0, 1).toUpperCase()}</div>
                    )}
                </button>
                <ul style={listStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
                    {options.map((item, index, arr) => (
                        <MenuItem item={item} updateFn={this._onChange} key={"user-menu-" + index} />
                    ))}
                </ul>
            </div>
        );
    }
}

function get_label(target) {
    if (target === "clients") return "client";
    else if (target === "workflows") return "workflow";
    else if (target === "macros") return "preset email response";
    else if (target === "products") return "product";
    else return "";
}

const queueCountStyle = {
    marginLeft: "16px",
    display: "inline-block",
    verticalAlign: "middle",
    color: "cadetblue"
};

const leftContainerStyle = {
    width: "calc(100% - 560px)",
    display: "inline-block",
    verticalAlign: "middle"
};

const rightContainerStyle = {
    width: "520px",
    textAlign: "right",
    verticalAlign: "middle",
    display: "inline-block"
};

const toggleContainerStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "16px"
};

const toggleLabelStyle = {
    fontSize: "12px",
    marginRight: "8px",
    display: "inline-block",
    verticalAlign: "middle",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "4px"
};

const playButtonStyle = {
    display: "inline-block",
    marginRight: "16px",
    padding: "4px 8px",
    cursor: "pointer",
    position: "relative",
    verticalAlign: "middle"
};

const playIconStyle = {
    verticalAlign: "middle",
    marginLeft: "4px",
    marginTop: "-4px"
};

const notificationTriggerStyle = {
    verticalAlign: "middle",
    display: "inline-block",
    marginLeft: "8px",
    marginRight: "4px",
    fontSize: "28px",
    cursor: "pointer",
    color: "rgb(70, 180, 175)"
};

const activeVersionStyle = {
    display: "inline-block",
    fontSize: "14px",
    marginRight: "16px",
    backgroundColor: "rgba(75,0,130, 0.7)",
    color: "white",
    padding: "8px",
    borderRadius: "4px",
    verticalAlign: "middle"
};
const editingVersionStyle = {
    display: "inline-block",
    fontSize: "14px",
    marginRight: "32px",
    backgroundColor: "rgba(255,69,0, 0.7)",
    color: "white",
    padding: "8px",
    borderRadius: "4px",
    verticalAlign: "middle"
};

class AppBar extends React.Component {
    constructor(props) {
        super(props);
        this._onScroll = this._onScroll.bind(this);
        this._onMenuSelect = this._onMenuSelect.bind(this);
        this._updateFn = this._updateFn.bind(this);
        this._onBackClick = this._onBackClick.bind(this);
        this._onBack = this._onBack.bind(this);
        this._onSaveAndBack = this._onSaveAndBack.bind(this);
        this._onNameChange = this._onNameChange.bind(this);

        this.state = {
            top: true,
            scroll: 0,
            label: get_label(this.props.target),
            text: this.props.text,
            active: this.props.active
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this._onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScroll);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ text: nextProps.text, active: nextProps.active, label: get_label(nextProps.target) });
    }

    _onScroll(event) {
        if (window.scrollY > 0 && this.state.top) this.setState({ top: false, scroll: window.scrollY / 60 });
        else if (window.scrollY === 0 && !this.state.top) this.setState({ top: true, scroll: window.scrollY / 60 });
        else if (window.scrollY < 60) this.setState({ scroll: window.scrollY / 60 });
        else if (this.state.scroll < 60) this.setState({ scroll: 1 });
    }

    _onMenuSelect(field, location, value) {
        if (value === "delete") {
            UIActions.showConfirmation({
                title: "Delete " + this.state.label,
                text: "Are you sure you want to delete this " + this.state.label + "?",
                buttonText: "Delete " + this.state.label,
                callback: this.props.actions.delete
            });
        } else if (value === "save") this.props.actions.save();
        else if (value === "save as new") this.props.actions.save("new");
        else if (value === "save as template") this.props.actions.save("template");
        else if (value === "save as new version") {
            var actions = this.props.actions;
            UIActions.showConfirmation({
                title: "New version",
                text: "Would you like the gurus to use the new version or the current one?",
                buttonText: "Switch workflow to new version",
                cancelButtomText: "Keep workflow on current version",
                callback: function() {
                    actions.save("", true, true);
                },
                cancelCallback: function() {
                    actions.save("", true, false);
                }
            });
        } else if (value === "workflow settings") UIActions.showOverlay("workflow settings");
        else if (value === "macro settings") UIActions.showOverlay("macro settings");
        else if (value === "about client") UIActions.showOverlay("about client");
    }

    _onNameChange(event) {
        this.setState({ text: event.currentTarget.value });
        this.props.actions.setField(this.props.field || "name", [], event.currentTarget.value, true);
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
        if (field === "active" && this.props.actions.toggleActive)
            this.props.actions.toggleActive(field, location, value, true);
        else this.props.actions.setField(field, location, value, true);
    }

    _onBackClick() {
        if (this.props.backSaveCheck) {
            UIActions.showConfirmation({
                title: "Save " + this.state.label,
                text: "Would you like to save before closing?",
                buttonText: "Save & Close",
                cancelButtomText: "Just Close",
                callback: this._onSaveAndBack,
                cancelCallback: this._onBack
            });
        } else {
            this._onBack();
        }
        //if (!this.props.noSave) this.props.actions.save();
        //UIActions.showRouter(this.props.target);
    }

    _onSaveAndBack() {
        this.props.actions.save();
        this._onBack();
    }

    _onBack() {
        if (this.props.backView !== undefined) UIActions.show(this.props.backView);
        else if (this.props.backURL !== undefined) UIActions.showRouter(this.props.backURL);
        else UIActions.goBack();
    }

    _onOpenNotifications() {
        UIActions.showOverlay("events feed");
    }

    _onAboutClient() {
        UIActions.showOverlay("about client");
    }

    render() {
        var opacity = 0;
        if (this.props.dynamic) opacity = 1 * this.state.scroll;
        const boxShadow =
            this.state.top || this.state.scroll < 1
                ? "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                : "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)";

        const primaryStyle = {
            height: "40x",
            color: "rgba(0, 0, 0, 0.62)",
            fontSize: "20px",
            padding: "12px 22px",
            width: "calc(100% - 44px)",
            position: "fixed",
            top: "0px",
            left: "0px",
            boxShadow: this.props.dynamic && this.state.top ? "" : boxShadow,
            backgroundColor: this.props.dynamic ? "rgba(255, 255, 255, " + opacity + ")" : "white",
            transition: "background-color .3s ease-in-out, box-shadow .3s cubic-bezier(.25,.8,.25,1)",
            zIndex: "4"
        };

        const nameStyle = {
            fontSize: "36px",
            lineHeight: "36px",
            height: "40px",
            color: "rgba(0, 0, 0, 0.62)",
            fontWeight: "500",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginLeft: "16px",
            width: "calc(100% - 280px)",
            display: this.props.dynamic && this.state.scroll < 1 ? "none" : "inline-block",
            verticalAlign: "middle"
        };

        const aboutStyle = {
            display: this.props.dynamic && this.state.scroll < 1 ? "none" : "inline-block",
            verticalAlign: "middle",
            marginLeft: "16px"
        };

        const sectionNameStyle = {
            paddingLeft: "30px",
            display: this.props.dynamic && this.state.scroll < 1 ? "none" : "inline-block",
            verticalAlign: "middle",
            lineHeight: "20px",
            letterSpacing: "0.03em",
            fontWeight: "400"
        };

        const arrowStyle = {
            color: this.state.scroll < 0.8 && this.props.dynamic ? "white" : "rgba(0, 0, 0, 0.62)",
            textShadow:
                this.state.scroll < 0.8 && this.props.dynamic
                    ? "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
                    : "",
            fontSize: "40px",
            display: "inline-block",
            verticalAlign: "middle",
            cursor: "pointer"
        };

        const logoStyle = {
            height: ["", undefined].indexOf(this.props.picture) === -1 ? "40px" : "20px",
            borderRadius: ["", undefined].indexOf(this.props.picture) === -1 ? "50%" : "0px",
            verticalAlign: "middle",
            display: "inline-block"
        };

        return (
            <div style={primaryStyle}>
                <div style={leftContainerStyle}>
                    {this.props.target !== undefined || this.props.goBack ? (
                        <i className="material-icons" style={arrowStyle} onClick={this._onBackClick}>
                            arrow_back
                        </i>
                    ) : (
                        <a href="#home">
                            <img style={logoStyle} src={this.props.picture || "/images/logo_white_bg@2x.png"} alt="" />
                        </a>
                    )}
                    {this.props.target !== undefined && this.props.text !== undefined ? (
                        <input
                            style={nameStyle}
                            value={this.state.text}
                            field={this.props.field || "name"}
                            onChange={this._onNameChange}
                            placeholder={this.state.label.charAt().toUpperCase() + this.state.label.slice(1) + " name"}
                        />
                    ) : (
                        <div style={sectionNameStyle}>{this.props.text || ""}</div>
                    )}
                    {["", undefined].indexOf(this.props.queueCount) === -1 ? (
                        <div style={queueCountStyle}>{this.props.queueCount} tickets in queue</div>
                    ) : (
                        ""
                    )}
                    {this.props.showAbout && this.props.clientLoaded ? (
                        <Button text="About Client" onClick={this._onAboutClient} type="flat" style={aboutStyle} />
                    ) : (
                        ""
                    )}
                </div>
                <div style={rightContainerStyle}>
                    {/* 
					{this.props.mode !== undefined ? (
						<div style={playButtonStyle} onClick={this.props.changeMode}>
							{this.props.mode === "play" ? "Pause" : "Continue"}
							<i style={playIconStyle} className="material-icons">
								{this.props.mode === "play" ? "pause" : "play_arrow"}
							</i>
							<Ink />
						</div>
					) : (
						""
					)}
                        
                    */}

                    {this.props.target === "workflows" &&
                    this.props.data !== undefined &&
                    this.props.data.currentVersion !== undefined ? (
                        <div style={activeVersionStyle}>Active version: {this.props.data.currentVersion}</div>
                    ) : (
                        ""
                    )}
                    {this.props.target === "workflows" &&
                    this.props.data !== undefined &&
                    this.props.data.currentVersion !== undefined ? (
                        <div style={editingVersionStyle}>Editing version: {this.props.data.editingVersion}</div>
                    ) : (
                        ""
                    )}

                    {this.props.active !== undefined ? (
                        <div style={toggleContainerStyle}>
                            <div style={toggleLabelStyle}>{this.props.active ? "Active" : "Inactive"}</div>
                            <Toggle
                                id={"active-" + this.props.target}
                                field="active"
                                updateFn={this._updateFn}
                                value={this.props.active || false}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    {this.props.target !== undefined &&
                    !this.props.noDropdown &&
                    this.props.menuOptions !== undefined ? (
                        <Menu id={"menu-app-bar"} items={this.props.menuOptions} updateFn={this._onMenuSelect} />
                    ) : (
                        ""
                    )}
                    {["admin", "manager"].indexOf(this.props.UserStore.role) > -1 ? (
                        <i
                            onClick={this._onOpenNotifications}
                            style={notificationTriggerStyle}
                            className="material-icons"
                        >
                            event_note
                        </i>
                    ) : (
                        ""
                    )}
                    <UserMenu UserStore={this.props.UserStore} id="user-menu" />
                </div>
            </div>
        );
    }
}

export default AppBar;
