import React from "react";

// Actions
import TicketActions from "../../actions/TicketActions";

// Scaffolding
import Dropdown from "../_common/forms/Dropdown.react";
import Menu from "../_common/Menu.react";

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	width: "100%",
	textAlign: "left"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

const detailsStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "100px",
	textAlign: "center"
};

const leftStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 48px)"
};

const closeIconStyle = {
	display: "inline-block",
	verticalAlign: "top",
	cursor: "pointer",
	marginLeft: "16px"
};

const assigneeStyle = {
	width: "100%",
	maxWidth: "200px",
	marginBottom: "0px"
};

const openStyle = {
	textDecoration: "none",
	display: "inline-block",
	color: "rgb(70, 180, 175)"
};

const menuOptions = [
	{
		text: "Open workflow",
		value: "open"
	},
	{
		text: "Assign",
		value: "assign"
	},
	{ divider: true },
	{
		text: "Delete",
		value: "delete"
	}
];

class TicketWorkflowRow extends React.Component {
	constructor(props) {
		super(props);
		this._onAssignAgent = this._onAssignAgent.bind(this);
		this._menuClick = this._menuClick.bind(this);

		this.state = {
			details: false
		};
	}

	_onAssignAgent(field, location, value) {
		TicketActions.assignAgent(value, this.props.item.id);
	}

	_menuClick(field, location, value) {
		if (value === "assign") this.setState({ details: true });
		else if (value === "open") {
			var win = window.open("#ticketworkflows/" + this.props.item.id, "_blank");
			win.focus();
		} else if (value === "delete") TicketActions.removeWorkflow(this.props.item);
	}

	render() {
		const primaryStyle = {
			borderBottom: "1px solid lightgrey",
			padding: "16px 0px 16px 16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative",
			transition: "background-color .3s ease",
			display: "inline-block",
			width: "calc(100% - 16px)",
			backgroundColor: "white"
		};

		var workflow = {};
		for (var i = 0; i < this.props.workflows.length; i++) {
			if (this.props.workflows[i].id === this.props.item.workflowId) {
				workflow = this.props.workflows[i];
				break;
			}
		}

		return (
			<div style={primaryStyle}>
				<div style={leftStyle}>
					<div style={nameStyle}>
						{workflow.name}
						<div style={labelStyle}>
							{this.props.item.status.charAt(0).toUpperCase() + this.props.item.status.substr(1, 1000)}
						</div>
					</div>

					{this.state.details ? (
						<Dropdown
							id={"hioperators-dropdown"}
							field="active_agent"
							label="Assigned Agent"
							value={this.props.item.active_agent}
							items={this.props.hioperators}
							updateFn={this._onAssignAgent}
							style={assigneeStyle}
							searchable={true}
						/>
					) : (
						""
					)}
				</div>
				<div style={closeIconStyle}>
					<Menu id={"wf-menu-" + this.props.item.id} items={menuOptions} updateFn={this._menuClick} menuWidth="210px" />
				</div>
			</div>
		);
	}
}

export default TicketWorkflowRow;
