import Reflux from "reflux";
import Papa from "papaparse";

// Actions
import ProductActions from "../actions/ProductActions";
import UIActions from "../actions/UIActions";
import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";
import ensure_ids from "../functions/ensure_ids";

// Defines db for firestore
var db = firebase.firestore();

var Product = {
	status: []
};

// Handles data import
var parse_csv = file => {
	Papa.parse(file, {
		complete: function(results) {
			var data = results.data;
			var obj, single, subitem, j, fields;

			for (var i = 0; i < data.length; i++) {
				single = data[i];
				if (single[0] !== "") {
					obj = {};
					ensure_ids(Product.working, obj);
					subitem = {};
				} else {
					subitem = {};
					ensure_ids(Product.working, subitem);
					single.splice(0, 1);
				}

				console.log(single);

				fields = subitem.id !== undefined ? Product.working.subfields : Product.working.fields;
				for (j = 0; j < fields.length; j++) {
					if (subitem.id !== undefined && [undefined, null].indexOf(single[j]) === -1)
						subitem[fields[j].value] = single[j];
					else if ([undefined, null].indexOf(single[j]) === -1) obj[fields[j].value] = single[j];
				}

				if (subitem.id !== undefined) {
					if (obj.subitems === undefined) obj.subitems = [];
					obj.subitems.push(subitem);
				} else {
					if (Product.working.items === undefined) Product.working.items = [];
					Product.working.items.push(obj);
				}
			}

			UIActions.showOverlay("");
			ProductActions.update();
		}
	});
};

// Figures out if inside of client
function client_check() {
	const myRoute = document.location.hash.replace("#", "").split("/");
	if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
	else return null;
}

function get_url() {
	const client = client_check();
	var url;
	if (client !== null) url = "clients/" + client + "/products";
	else url = "products";

	return url;
}

class ProductStore extends Reflux.Store {
	constructor() {
		super();
		this.notify = this.notify.bind(this);
		this.state = { ProductStore: Product };
		this.listenToMany(ProductActions);
	}

	onNew(data) {
		data.active = true;
		data.created = new Date();

		const clientId = url.get_client();

		var ref = db
			.collection("clients")
			.doc(clientId)
			.collection("products")
			.doc();

		ref
			.set(data)
			.then(function() {
				// Does initial load
				UIActions.showOverlay("");
				UIActions.showRouter(get_url() + "/" + ref.id);
			})
			.catch(ProductActions.loadFailed);
	}

	onLoad(id) {
		console.log("LOADING PRODUCT");
		const clientId = url.get_client();

		db.collection("clients")
			.doc(clientId)
			.collection("products")
			.doc(id)
			.get()
			.then(function(snapshot) {
				console.log("got here");
				Product.working = firestore.extract_single(snapshot);
				ProductActions.update();
			})
			.catch(ProductActions.error);
	}

	onSave(type) {
		Product.working.updated = new Date();

		var temp = objects.clone(Product.working);
		delete temp.id;

		if (["new", "template"].indexOf(type) > -1) temp.created = new Date();

		const clientId = url.get_client();

		var ref;
		if (["new"].indexOf(type) > -1)
			ref = db
				.collection("clients")
				.doc(clientId)
				.collection("products")
				.doc();
		else
			ref = db
				.collection("clients")
				.doc(clientId)
				.collection("products")
				.doc(Product.working.id);

		ref
			.set(temp, { merge: true })
			.then(function() {
				Product.working.id = ref.id;
				UIActions.showRouter(get_url() + "/" + ref.id);
				UIActions.addMessage("Product catalog saved!", "success");
			})
			.catch(ProductActions.error);
	}

	onDelete() {
		const clientId = url.get_client();
		const id = Product.working.id;
		delete Product.working;

		db.collection("clients")
			.doc(clientId)
			.collection("products")
			.doc(id)
			.delete()
			.then(function() {
				UIActions.addMessage("Product catalog deleted!", "success");
			})
			.catch(ProductActions.error);

		UIActions.showRouter(get_url());
		this.notify();
	}

	onSetField(field, location, value, shouldNotify) {
		console.log("updating product " + field);
		console.log(value);
		setField(Product.working, field, location, value);
		if (shouldNotify) this.notify();
	}

	onAdd(field, location, obj) {
		objects.add(Product.working, field, location, obj);
		this.notify();
	}

	onRemove(location) {
		objects.remove(Product.working, location.concat());
		this.notify();
	}

	onParseCSV(file) {
		parse_csv(file);
	}

	onClose() {
		Product.loadedParts = [];
		Product.allLoaded = false;
		delete Product.working;
		this.notify();
	}

	onUpdate() {
		this.notify();
	}

	onError(error) {
		UIActions.addMessage(error, "error");
	}

	notify() {
		console.log("notify product");
		var working = Product.working !== undefined ? objects.clone(Product.working) : undefined;
		this.setState({ ProductStore: { status: Product.status, working } });
	}
}

export default ProductStore;
