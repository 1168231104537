import React from "react";

const primaryStyle = {
	display: "inline-block",
	borderBottom: "1px solid cadetblue",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 1)",
	padding: "8px 0px",
	textAlign: "left"
};

class HeaderRow extends React.Component {
	render() {
		return <div style={primaryStyle}>{this.props.children}</div>;
	}
}

export default HeaderRow;
