import React from "react";

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

class Statistic extends React.Component {
	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "top",
			fontSize: "16px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "500",
			cursor: "pointer",
			width: this.props.width || "100px",
			textAlign: "center",
			marginLeft: "16px"
		};

		return (
			<div style={primaryStyle}>
				{this.props.text || 0}
				<span style={labelStyle}>{this.props.label}</span>
			</div>
		);
	}
}

export default Statistic;
