import api from "./api";
import ClientActions from "../actions/ClientActions";

function send(message, clientId) {
	ClientActions.get(function(Client) {
		var integrationId;
		if (
			Client.working !== undefined &&
			Client.working.id === clientId &&
			Client.working.integrationsList !== undefined
		) {
			for (var i = 0; i < Client.working.integrationsList.length; i++) {
				if (Client.working.integrationsList[i].type === "slack") {
					integrationId = Client.working.integrationsList[i].integrationId;
					break;
				}
			}

			if (message !== undefined) message = message.replace(/<br>/g, "\n");
			if ([null, undefined].indexOf(integrationId) === -1)
				api(
					"post",
					"/slack/message",
					{ clientId: Client.working.id, message, integrationId },
					complete,
					failed,
					{},
					""
				);
		}
	});
}

function complete() {}

function failed(error) {
	console.log(error);
}

export { send };
