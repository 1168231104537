import React from "react";

import m from "../../functions/m";

class Row extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	render() {
		const color = this.props.color || "transparent";

		const primaryStyle = {
			borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
			padding: "16px",
			borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		return (
			<a
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={m(primaryStyle, this)}
				href={this.props.href || undefined}
				target={this.props.newTab ? "_blank" : ""}
			>
				{this.props.children}
			</a>
		);
	}
}

export default Row;
