import React from "react";

// Actions
import TicketActions from "../../actions/TicketActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Container from "./Container.react";
import Overlay from "./Overlay.react";

// Form items
import Input from "./forms/Input.react";
import Button from "./buttons/Button.react";

// Headers
import H1 from "./headers/H1.react";

// Functions
import validate from "../../functions/validate";

// Sets validation
const urlValidation = [
	{
		type: "contains",
		value: "http"
	}
];

class NewHyperlink extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { text: "", url: "", validate: false };
	}

	componentDidMount() {
		document.getElementById("text").focus();
	}

	_onClick() {
		var urlError = validate(urlValidation, this.state.url);

		if (urlError === "") {
			TicketActions.setReply({ body: '<a href="' + this.state.url + '">' + this.state.text + "</a>" }, false);
			UIActions.showOverlay("");
		} else this.setState({ validate: true });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onTextReturn() {
		document.getElementById("url").focus();
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text="Insert Link" textAlign="center" />
						<Input id="text" label="Link text" field="text" updateFn={this._updateFn} onReturn={this._onTextReturn} />
						<Input
							id="url"
							label="Link URL"
							field="url"
							updateFn={this._updateFn}
							onReturn={this._onClick}
							validate={this.state.validate}
							validation={urlValidation}
						/>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="INSERT" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default NewHyperlink;
