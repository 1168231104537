import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import Overlay from "./Overlay.react";
import Card from "./Card.react";
import Container from "./Container.react";

import Button from "./buttons/Button.react";

class ImageOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
		this._onFileReceived = this._onFileReceived.bind(this);

		if (
			this.props.item.image.url !== undefined &&
			this.props.item.image.url.indexOf("front") > -1 &&
			this.props.item.image.content_url === undefined
		)
			ClientActions.getFrontDownload(this.props.item, this._onFileReceived);

		var url = this.props.item.image.content_url || this.props.item.image.url;
		this.state = { url: url || "" };
	}

	_onClose() {
		UIActions.showOverlay("", {});
	}

	_onFileReceived(result) {
		this.setState({ url: result.url });
	}

	render() {
		const cardStyle = {
			width: "auto",
			padding: "24px",
			textAlign: "center",
			maxWidth: "1100px"
		};

		const primaryStyle = {
			width: "auto",
			maxWidth: "1100px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		const buttonRowStyle = {
			marginTop: "16px",
			textAlign: "right"
		};

		return (
			<Overlay>
				<Container maxWidth="">
					<Card style={cardStyle}>
						<img style={primaryStyle} src={this.state.url} alt={""} />
						<div style={buttonRowStyle}>
							<Button text="Close" style={buttonStyle} onClick={this._onClose} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ImageOverlay;
