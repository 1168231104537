import React from "react";
import shortid from "shortid";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import H2 from "../headers/H2.react";

// Form items
import InputMultiline from "../forms/InputMultiline.react";
import Input from "../forms/Input.react";
import Dropdown from "../forms/Dropdown.react";
import Pill from "../forms/Pill.react";
import Cell from "../Cell.react";

// Functions
import * as list from "../../../functions/list";
import * as time from "../../../functions/time";
import * as objects from "../../../functions/objects";

const tableStyle = {
    width: "100%",
    display: "inline-block"
};

const headerStyle = {
    display: "inline-block",
    borderBottom: "1px solid cadetblue",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 1)"
};

const cellStyle = {
    padding: "8px",
    width: "80px",
    display: "inline-block",
    verticalAlign: "middle"
};

const bigCellStyle = {
    padding: "8px",
    width: "180px",
    display: "inline-block",
    verticalAlign: "middle"
};

const veryBigCellStyle = {
    padding: "8px",
    width: "260px",
    display: "inline-block",
    verticalAlign: "middle"
};

const tagContainerStyle = {
    width: "100%",
    display: "inline-block"
};

const subsectionTitleStyle = {
    width: "calc(100% - 64px)",
    padding: "4px 0px 4px 64px",
    display: "inline-block",
    fontWeight: "700"
};

const subsectionRowStyle = {
    width: "100%",
    display: "inline-block"
};

const shopifyCustomers = {
    columns: [
        {
            text: "ID",
            size: "m",
            value: "id"
        },
        {
            text: "NAME",
            size: "l",
            value: "name"
        },
        {
            text: "EMAIL",
            size: "l",
            value: "email"
        }
    ],
    idColumn: "id",
    order: "email asc",
    max: 1
};

const stripeCharges = {
    columns: [
        {
            text: "AMOUNT",
            size: "s",
            value: "amount_string",
            prefix: "$"
        },
        {
            text: "DESCRIPTION",
            size: "m",
            value: "description",
            tags: [
                { value: "amount_refunded_string", prefix: "Refunded $" },
                { value: "failed", backgroundColor: "indianred" }
            ]
        },
        {
            text: "DATE",
            size: "m",
            value: "created",
            isDate: true,
            width: "150px"
        }
    ],
    idColumn: "id"
};

const skipRentals = {
    columns: [
        {
            text: "VEHICLE ID",
            size: "m",
            value: "vehicleId"
        },
        {
            text: "STARTED",
            size: "m",
            value: "startedAt",
            isDate: true
        },
        {
            text: "ENDED",
            size: "m",
            value: "endedAt",
            isDate: true
        },
        {
            text: "USER ID",
            size: "xl",
            value: "userId"
        }
    ],
    order: "startedAt newest",
    idColumn: "id"
};

const fieldTypes = [
    {
        text: "Stripe charges",
        value: "stripe charges"
    },
    {
        text: "Shopify customers",
        value: "shopify customers"
    },
    {
        text: "Shopify orders",
        value: "shopify orders"
    },
    {
        text: "Spree orders",
        value: "spree orders"
    },
    {
        text: "Spree line items",
        value: "spree line items"
    },
    {
        text: "Skip rentals",
        value: "skip rentals"
    },
    {
        text: "Shipwire skus",
        value: "shipwire skus"
    },
    {
        text: "Fulfil.io orders",
        value: "fulfil orders"
    },
    {
        text: "Fulfil.io line items",
        value: "fulfil line items"
    },
    {
        text: "Custom",
        value: "custom"
    }
];

// Mapppings
const shipwireSkus = {
    columns: [
        {
            text: "SKU",
            size: "m",
            value: "sku"
        },
        {
            text: "Description",
            size: "xl",
            value: "description"
        }
    ],
    order: "sku asc",
    idColumn: "id"
};

const shopifyOrders = {
    columns: [
        {
            text: "ORDER NUMBER",
            size: "m",
            value: "name"
        },
        {
            text: "PRICE",
            size: "m",
            value: "total_price",
            prefix: "$"
        },
        {
            text: "DATE",
            size: "xl",
            value: "created_at",
            isDate: "true"
        }
    ],
    subsection: {
        title: "Line items",
        titleStyle: {
            paddingLeft: "124px",
            width: "calc(100% - 124px)",
            fontWeight: "700",
            display: "inline-block"
        },
        value: "line_items",
        columns: [
            {
                size: "m"
            },
            {
                size: "m",
                value: "price",
                prefix: "$"
            },
            {
                size: "m",
                value: "name"
            }
        ]
    },
    idColumn: "id",
    order: "created_at newest"
};

const spreeOrders = {
    columns: [
        {
            text: "ORDER NUMBER",
            size: "s",
            value: "number"
        },
        {
            text: "PRICE",
            size: "s",
            value: "total",
            prefix: "$"
        },
        {
            text: "DATE",
            size: "m",
            value: "created_at",
            isDate: "true"
        }
    ],
    idColumn: "id",
    order: "created_at newest"
};

const spreeLineItems = {
    columns: [
        {
            text: "ITEM",
            size: "xl",
            value: "accurate_name"
        },
        {
            text: "PICE",
            size: "l",
            value: "display_amount"
        }
    ],
    order: "accurate_name asc",
    idColumn: "id"
};

const fulfilOrders = {
    columns: [
        {
            text: "NUMBER",
            size: "m",
            value: "number"
        },
        {
            text: "AMOUNT",
            size: "m",
            value: "total_amount",
            prefix: "$"
        },
        {
            text: "DATE",
            size: "l",
            value: "create_date",
            isDate: "true"
        }
    ],
    order: "create_date newest",
    idColumn: "id"
};

const fulfilLineItems = {
    columns: [
        {
            text: "ITEM",
            size: "xl",
            value: "rec_name"
        },
        {
            text: "QUANTITY",
            size: "m",
            value: "quantity"
        }
    ],
    order: "rec_name asc",
    idColumn: "id"
};

/*
class Cell extends React.Component {
	render() {
		var style = {};
		if (this.props.item.style === "medium") style = cellStyle;
		else if (this.props.item.style === "big") style = bigCellStyle;
		else if (this.props.item.style === "xl") style = veryBigCellStyle;

		return (
			<div style={style}>
				{this.props.value}
				{this.props.children}
			</div>
		);
	}
}
*/

class Tag extends React.Component {
    render() {
        const tagStyle = {
            marginLeft: "16px",
            padding: "4px 6px",
            fontSize: "12px",
            color: "white",
            borderRadius: "16px",
            margin: "4px",
            backgroundColor: this.props.tag.backgroundColor || "purple",
            textAlign: "center",
            display: "inline-block",
            verticalAlign: "middle"
        };

        return <div style={tagStyle}>{get_value(this.props.tag, this.props.item)}</div>;
    }
}

class Tags extends React.Component {
    render() {
        var toRender = [];
        var tags = [];
        for (var i = 0; i < this.props.tags.length; i++) {
            if (this.props.item[this.props.tags[i].field] !== undefined) tags.push(this.props.tags[i]);
        }

        toRender = tags.map((item, index, arr) => <Tag tag={item} item={this.props.item} />);

        if (toRender.length === 0) return "";
        else return <div style={tagContainerStyle}>{toRender}</div>;
    }
}

function get_value(mapping, item) {
    var value = objects.iterate_object(mapping.value, item);

    if (mapping.isDate || mapping.type === "date") value = time.parse_time(value);

    if (mapping.prefix !== undefined) value = mapping.prefix + value;

    return value;
}

class SubsectionRow extends React.Component {
    render() {
        return (
            <div style={subsectionRowStyle}>
                {this.props.mappings.subsection.columns.map((item, index, arr) => (
                    <Cell item={item} size={item.size} padding="8px" width={item.width}>
                        {get_value(item, this.props.item)}
                        {item.tags !== undefined ? <Tags tags={item.tags} item={this.props.item} /> : ""}
                    </Cell>
                ))}
            </div>
        );
    }
}
class Subsection extends React.Component {
    render() {
        return (
            <div>
                {this.props.mappings.subsection.title !== undefined ? (
                    <div style={this.props.mappings.subsection.titleStyle || subsectionTitleStyle}>
                        {this.props.mappings.subsection.title}
                    </div>
                ) : (
                    ""
                )}
                {this.props.item[this.props.mappings.subsection.value].map((item, index, arr) => (
                    <SubsectionRow item={item} mappings={this.props.mappings} />
                ))}
            </div>
        );
    }
}

class Row extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);

        this.state = { hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onClick() {
        var value = this.props.response.value || [];
        var add = true;
        for (var i = 0; i < value.length; i++) {
            if (value[i][this.props.mappings.idColumn] === this.props.item[this.props.mappings.idColumn]) {
                value.splice(i, 1);
                add = false;
                break;
            }
        }

        if (add) value.push(this.props.item);
        if (this.props.mappings.max !== undefined && value.length > this.props.mappings.max) value.splice(0, 1);

        WorkflowActions.setFieldResponse("value", [this.props.response.id], value, true);
    }

    render() {
        var selected = false;
        if ([undefined, null].indexOf(this.props.response.value) === -1) {
            for (var i = 0; i < this.props.response.value.length; i++) {
                if (this.props.response.value[i][this.props.mappings.idColumn] === this.props.item[this.props.mappings.idColumn]) {
                    selected = true;
                    break;
                }
            }
        }

        const backgroundColor = selected ? "lightblue" : "transparent";

        const rowStyle = {
            display: "inline-block",
            borderBottom: "1px solid lightgrey",
            backgroundColor: this.state.hover && !selected ? "rgb(220, 220, 220)" : backgroundColor,
            width: "100%",
            fontWeight: "400",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.87)",
            cursor: "pointer",
            transition: "background-color .3s ease"
        };

        return (
            <div style={rowStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
                {this.props.mappings.columns.map((item, index, arr) => (
                    <Cell key={"cell-" + this.props.item.id + "-" + index} item={item} size={item.size} padding="8px" width={item.width}>
                        {get_value(item, this.props.item)}
                        {item.tags !== undefined ? <Tags tags={item.tags} item={this.props.item} /> : ""}
                    </Cell>
                ))}
                {this.props.mappings.subsection !== undefined && this.props.item[this.props.mappings.subsection.value] !== undefined ? (
                    <Subsection item={this.props.item} mappings={this.props.mappings} />
                ) : (
                    ""
                )}
            </div>
        );
    }
}

class Header extends React.Component {
    render() {
        return (
            <div style={headerStyle}>
                {this.props.mappings.columns.map((item, index, arr) => (
                    <Cell key={"header-row-" + index} item={item} size={item.size} padding="8px" width={item.width}>
                        {item.text}{" "}
                    </Cell>
                ))}
            </div>
        );
    }
}

class Table extends React.Component {
    render() {
        const items = this.props.mappings.order !== undefined ? list.order(this.props.items, this.props.mappings.order) : this.props.items;
        return (
            <div style={tableStyle}>
                <Header mappings={this.props.mappings} />
                {items.map((item, index, arr) => (
                    <Row key={"row-" + item.id} item={item} mappings={this.props.mappings} response={this.props.response} />
                ))}
            </div>
        );
    }
}

const addStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.62)",
    fontSize: "30px"
};

const pillStyle = {
    cursor: "pointer"
};

class CustomFieldPill extends React.Component {
    constructor(props) {
        super(props);
        this._onClick = this._onClick.bind(this);
    }

    _onClick() {
        UIActions.showOverlay("table field", { obj: this.props.item, location: this.props.location });
    }

    render() {
        return <Pill text={this.props.item.text} onClick={this._onClick} style={pillStyle} />;
    }
}

const orderDirectionOptions = [
    {
        text: "Asc",
        value: "asc"
    },
    {
        text: "Desc",
        value: "desc"
    }
];

const orderFieldStyle = {
    width: "calc(100% - 140px)"
};

const orderDirectionStyle = {
    width: "124px",
    marginLeft: "16px"
};

class TableCard extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onAddField = this._onAddField.bind(this);

        const valid = this.props.agentMode
            ? [null, undefined].indexOf(this.props.response.value) === -1 && this.props.response.value.length > 0
            : true;

        this.state = {
            text: this.props.item.prompts[0].text || "",
            value: this.props.item.value || "",
            field: this.props.item.field || "",
            valid,
            nextPath: valid ? 0 : 1
        };
    }

    componentWillReceiveProps(nextProps) {
        var valid = this.props.agentMode
            ? [null, undefined].indexOf(nextProps.response.value) === -1 && nextProps.response.value.length > 0
            : true;
        if (valid !== this.state.valid) this.setState({ valid, nextPath: valid ? 0 : 1 });
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        var valid = this.state.valid;
        this.setState({ [field]: value, valid, nextPath: valid ? 0 : 1 });

        if (this.props.agentMode) {
            WorkflowActions.setFieldResponse(field, location, value, true);
            WorkflowActions.nextCard(valid);
        } else WorkflowActions.setField(field, location, value, true);
    }

    _onAddField() {
        var obj = {
            text: "",
            value: "",
            type: "text",
            size: "m"
        };

        WorkflowActions.add("customMap", [this.props.item.id], obj);
        UIActions.showOverlay("table field", { obj, location: [this.props.item.id] });
    }

    render() {
        const questionStyle = {
            marginBottom: "0px"
        };

        // Figures out rows to display
        var toRender = [];
        var orderFieldOptions = [];

        if (this.props.agentMode) {
            var myMapping;

            if (this.props.item.fieldType === "stripe charges") myMapping = stripeCharges;
            else if (this.props.item.fieldType === "shopify customers") myMapping = shopifyCustomers;
            else if (this.props.item.fieldType === "shopify orders") myMapping = shopifyOrders;
            else if (this.props.item.fieldType === "spree orders") myMapping = spreeOrders;
            else if (this.props.item.fieldType === "spree line items") myMapping = spreeLineItems;
            else if (this.props.item.fieldType === "skip rentals") myMapping = skipRentals;
            else if (this.props.item.fieldType === "shipwire skus") myMapping = shipwireSkus;
            else if (this.props.item.fieldType === "fulfil orders") myMapping = fulfilOrders;
            else if (this.props.item.fieldType === "fulfil line items") myMapping = fulfilLineItems;
            else if (this.props.item.fieldType === "custom")
                myMapping = {
                    columns: this.props.item.customMap || [],
                    order: this.props.item.orderField + " " + (this.props.item.orderDirection || "asc"),
                    idColumn: "id"
                };

            // Ensures IDs
            if (this.props.item.responses !== undefined) {
                this.props.item.responses.forEach(item => {
                    if (item.id === undefined) item.id = shortid.generate();
                });
            }

            if (myMapping !== undefined)
                toRender = <Table items={this.props.item.responses} mappings={myMapping} response={this.props.response} />;
        } else {
            if (this.props.item.fieldType === "custom" && Array.isArray(this.props.item.customMap)) {
                orderFieldOptions = this.props.item.customMap.map(item => ({ text: item.text, value: item.value }));
            }
        }

        return (
            <Card
                id={this.props.item.id}
                item={this.props.item}
                picture={this.props.client !== undefined ? this.props.client.picture : ""}
                name={this.props.client !== undefined ? this.props.client.name : ""}
                first={this.props.first}
                onMoveView={this.props.onMoveView}
                targetFocus={this.props.targetFocus}
                agentMode={this.props.agentMode}
                onCardConnect={this.props.onCardConnect}
                connecting={this.props.connecting}
                valid={this.state.valid}
                noNextButton={false}
                nextPath={this.state.nextPath}
            >
                {!this.props.agentMode ? (
                    <Dropdown
                        id={"map-type-" + this.props.item.id}
                        items={fieldTypes}
                        field={"fieldType"}
                        location={[this.props.item.id]}
                        updateFn={this._onBlur}
                        value={this.props.item.fieldType}
                        label={"Type of table"}
                        searchable={true}
                    />
                ) : (
                    ""
                )}
                {this.props.item.fieldType === "custom" && !this.props.agentMode ? (
                    <div>
                        <H2 text="Items" margin="16px 0px" />
                        {(this.props.item.customMap || []).map((item, index) => (
                            <CustomFieldPill key={"field-" + item.id} item={item} location={[this.props.item.id]} />
                        ))}
                        <i className="material-icons" onClick={this._onAddField} style={addStyle}>
                            add_circle
                        </i>
                        <Dropdown
                            id={"custom-mapping-orderField-" + this.props.item.id}
                            items={orderFieldOptions}
                            style={orderFieldStyle}
                            field={"orderField"}
                            location={[this.props.item.id]}
                            updateFn={this._onBlur}
                            value={this.props.item.orderField}
                            label={"Field to sort by"}
                            searchable={true}
                        />
                        <Dropdown
                            id={"custom-mapping-orderDirection-" + this.props.item.id}
                            style={orderDirectionStyle}
                            items={orderDirectionOptions}
                            field={"orderDirection"}
                            location={[this.props.item.id]}
                            updateFn={this._onBlur}
                            value={this.props.item.orderDirection || "asc"}
                            label={"Sort direction"}
                        />
                    </div>
                ) : (
                    ""
                )}
                <InputMultiline
                    id={this.props.item.prompts[0].id}
                    placeholder="Enter question text here..."
                    value={this.state.text}
                    field="text"
                    location={[this.props.item.id, this.props.item.prompts[0].id]}
                    onBlur={this._onBlur}
                    noLine={true}
                    style={questionStyle}
                    onReturn={this._onPromptReturn}
                    readOnly={this.props.agentMode}
                />

                {!this.props.agentMode ? (
                    <Input
                        id={"map-" + this.props.item.id}
                        label={"Field to map to table"}
                        value={this.state.field}
                        field="field"
                        location={[this.props.item.id]}
                        updateFn={this._updateFn}
                        onBlur={this._onBlur}
                        colored={true}
                        helpText="Don't include {{ }} around the field name"
                    />
                ) : (
                    ""
                )}
                {toRender}
            </Card>
        );
    }
}

export default TableCard;
