export default {
	name: "Custom Email",
	type: "custom email",
	category: "Other",
	icon: "alternate_email",
	apiFields: [
		{
			field: "service",
			label: "Service",
			type: "dropdown",
			items: [
				{
					text: "Gmail",
					value: "gmail"
				}
			]
		},
		{
			field: "user",
			label: "Username",
			type: "text"
		},
		{
			field: "password",
			label: "Password",
			type: "text"
		},
		{
			field: "sender",
			label: "Sender",
			helptext: "e.g. 'Joe Operator' <joeoperator@hioperator.com>",
			type: "text"
		}
	],
	cardFields: []
};
