import Reflux from "reflux";

var TicketActions = Reflux.createActions([
    "loadClients",
    "loadClientsCompleted",
    "loadClientsFailed",
    "load",
    "loadCompleted",
    "loadFailed",
    "loadActive",
    "loadActiveCompleted",
    "releaseTicket",
    "releaseTicketCompleted",
    "getTicket",
    "getTicketCompleted",
    "getTicketFailed",
    "save",
    "saveCompleted",
    "saveFailed",
    "delete",
    "deleteCompleted",
    "saveAppliedWorkflow",
    "stopWorkflow",
    "setField",
    "setCustomField",
    "setSystemField",
    "loadForTriage",
    "loadForTriageCompleted",
    "loadForTriageFailed",
    "setReply",
    "setFullReply",
    "submit",
    "submitCompleted",
    "submitFailed",
    "escalate",
    "merge",
    "mergeCompleted",
    "mergeFailed",
    "spam",
    "spamCompleted",
    "addWorkflow",
    "removeWorkflow",
    "addAttachments",
    "addAttachmentsCompleted",
    "addAttachmentsFailed",
    "addInternalNote",
    "stopViewing",
    "stopViewingCompleted",
    "stopViewingFailed",
    "assignAgent",
    "assignAgentCompleted",
    "assignAgentFailed",
    "remove",
    "setClientOptions",
    "ticketCheck",
    "changeMode",
    "setCSATCategory",
    "setSandboxResponse",
    "refresh",
    "refreshCompleted",
    "flagTicket",
    "acknowledgeFlagTicket",
    "unsubscribe",
    "unsubscribeActive",
    "reset",
    "close",
    "get",
    "update",
    "error"
]);

export default TicketActions;
