import React from "react";

// Scaffolding
import Row from "../_common/Row.react";
import Cell from "../_common/Cell.react";

// Functions
import url_creator from "../../functions/url_creator";
import * as time from "../../functions/time";

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class WorkflowRow extends React.Component {
	render() {
		const activeStyle = {
			fontSize: "16px",
			padding: "2px",
			borderRadius: "50%",
			color: this.props.item.active ? "rgb(70, 180, 175)" : "rgba(0, 0, 0, 0.52)",
			border: "1px solid"
		};

		return (
			<Row index={this.props.index} href={url_creator("workflows", this.props.item.id)} style={rowStyle}>
				<Cell size="m">{this.props.item.name}</Cell>
				<Cell size="s" center={true}>
					<i style={activeStyle} className="material-icons">
						check
					</i>
				</Cell>
				<Cell size="m">{time.parse_time(this.props.item.created)}</Cell>
				<Cell size="m">{time.parse_time(this.props.item.updated)}</Cell>
			</Row>
		);
	}
}

export default WorkflowRow;
