import Reflux from "reflux";

var MacroActions = Reflux.createActions([
	"new",
	"newCompleted",
	"newFailed",
	"load",
	"loadCompleted",
	"loadFailed",
	"save",
	"saveCompleted",
	"saveFailed",
	"delete",
	"deleteCompleted",
	"deleteFailed",
	"setField",
	"add",
	"unsubscribe",
	"close",
	"update"
]);

export default MacroActions;
