import crms from "../crms";

var crmOptions = [];

for (var i = 0; i < crms.length; i++) {
	crmOptions.push(crms[i].type);
}

var find = (obj, target) => {
	for (var i = 0; i < obj.length; i++) {
		if (obj[i].id === target) return obj[i];
		else if (crmOptions.indexOf(obj[i].id) > -1) return obj[i];
	}

	return null;
};

export { find };
