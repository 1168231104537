import React from "react";

// Actions
import MacroActions from "../../actions/MacroActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import NavHorizontal from "../_common/forms/NavHorizontal.react";

// Search
import Search from "../_common/Search.react";
import TagResult from "../_common/search/TagResult.react";

// Form items
import InputMultiline from "../_common/forms/InputMultiline.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Tokenizer from "../_common/forms/Tokenizer.react";

// Ticket parts
import TicketField from "../tickets/TicketField.react";

import * as client_components from "../../functions/client_components";

const autoFills = [
    {
        text: "Ticket title",
        value: "{{ticket.title}}"
    },
    {
        text: "Ticket description",
        value: "{{ticket.description}}"
    },
    {
        text: "Ticket url",
        value: "{{ticket.url}}"
    },
    {
        text: "Ticket id",
        value: "{{ticket.id}}"
    },
    {
        text: "Ticket source",
        value: "{{ticket.via}}"
    },
    {
        text: "Ticket status",
        value: "{{ticket.status}}"
    },
    {
        text: "Ticket priority",
        value: "{{ticket.priority}}"
    },
    {
        text: "Ticket type",
        value: "{{ticket.ticket_type}}"
    },
    {
        text: "Group name",
        value: "{{ticket.group.name}}"
    },
    {
        text: "Assignee email",
        value: "{{ticket.assignee.email}}"
    },
    {
        text: "Assignee name",
        value: "{{ticket.assignee.name}}"
    },
    {
        text: "Assignee first name",
        value: "{{ticket.assignee.first_name}}"
    },
    {
        text: "Assignee last name",
        value: "{{ticket.assignee.last_name}}"
    },
    {
        text: "Requester email",
        value: "{{ticket.requester.email}}"
    },
    {
        text: "Requester name",
        value: "{{ticket.requester.name}}"
    },
    {
        text: "Requester first name",
        value: "{{ticket.requester.first_name}}"
    },
    {
        text: "Requester last name",
        value: "{{ticket.requester.last_name}}"
    },
    {
        text: "Ticket tags",
        value: "{{ticket.tags}}"
    }
];

const leftControlPanelStyle = {
    width: "254px",
    maxHeight: "calc(100% - 66px - 66px)",
    overflowY: "auto",
    padding: "16px 0px 32px 16px",
    display: "inline-block",
    verticalAlign: "top"
};

const rightContainerStyle = {
    width: "calc(100% - 332px)",
    overflowY: "auto",
    padding: "16px 16px 16px 16px",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center"
};

const controlInnerStyle = {
    display: "inline-block",
    paddingBottom: "300px",
    width: "100%"
};

const assigneeStyle = {
    width: "calc(100% - 16px)",
    maxWidth: "300px",
    marginBottom: "8px"
};

const toggleRowStyle = {
    textAlign: "left",
    display: "inline-block",
    width: "calc(100% - 16px)",
    padding: "8px"
};

const tokenizerStyle = {
    marginTop: "16px"
};

const customFieldsStyle = {
    textAlign: "left",
    width: "100%",
    display: "inline-block"
};

var get_client_agents = client => {
    var componentUsers = client_components.find(client.components || []);
    var clientAgents = [];

    if (componentUsers !== null && componentUsers.clientAgents !== undefined) {
        componentUsers = componentUsers.clientAgents;

        for (var i = 0; i < componentUsers.length; i++) {
            clientAgents.push({
                text: componentUsers[i].name,
                value: componentUsers[i].id
            });
        }
    }

    return clientAgents;
};

var get_ticket_fields = (client, ticket) => {
    var componentFields = client_components.find(client.components || []);
    var ticketFields = [];
    var field;

    if (client !== undefined && componentFields !== null && componentFields.ticket_fields !== undefined) {
        componentFields = componentFields.ticket_fields;

        for (var j = 0; j < componentFields.length; j++) {
            field = componentFields[j];
            if (["subject", "description", "status", "group", "assignee"].indexOf(field.type) === -1 && field.active)
                ticketFields.push(field);
        }
    }

    return ticketFields;
};

class Macro extends React.Component {
    constructor(props) {
        super(props);
        this._onNameChange = this._onNameChange.bind(this);
        this._onUpdate = this._onUpdate.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onAddAutofill = this._onAddAutofill.bind(this);
        this._onTagSearchSelect = this._onTagSearchSelect.bind(this);
        this._onUpdateTokens = this._onUpdateTokens.bind(this);
        this._onReplace = this._onReplace.bind(this);
        this._updateFnSystem = this._updateFnSystem.bind(this);
        this._onUpdateType = this._onUpdateType.bind(this);

        this.state = {
            title: this.props.item.title || "",
            body: this.props.item.body.replace(/\n/g, "<br />"),
            clientAgents: this.props.client !== undefined ? get_client_agents(this.props.client) : [],
            ticket_fields: this.props.client !== undefined ? get_ticket_fields(this.props.client) : []
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            clientAgents: get_client_agents(nextProps.client),
            ticket_fields: get_ticket_fields(nextProps.client)
        });
    }

    _onUpdate(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        MacroActions.setField(field, location, value, true);
    }

    _onNameChange(event) {
        this.setState({ title: event.currentTarget.value });
        MacroActions.setField("title", [], event.currentTarget.value, true);
    }

    _onAddAutofill(field, location, value) {
        this.setState({ body: this.state.body + " " + value });
    }

    _onTagSearchSelect(value) {
        var tags = this.props.item.tags || [];
        if (tags.indexOf(value.text) === -1) tags.push(value.text);

        MacroActions.setField("tags", [], tags, true);
    }

    _onUpdateTokens(field, location, value) {
        MacroActions.setField(field, location, value, true);
    }

    _onReplace(obj) {
        MacroActions.add("custom_fields", [], obj);
    }

    _updateFnSystem(field, ticketField, value) {
        MacroActions.setField(field, [], value, true);
    }

    _onUpdateType(field, location, value) {
        if (value === "public") {
            MacroActions.setField("public", location, true, false, true);
            MacroActions.setField("internal", location, false, true, true);
        } else if (value === "hioperator") {
            MacroActions.setField("public", location, false, false, true);
            MacroActions.setField("internal", location, true, true, true);
        } else {
            MacroActions.setField("public", location, false, false, true);
            MacroActions.setField("internal", location, false, true, true);
        }
    }

    render() {
        const inputStyle = {
            width: "calc(100% - 32px)"
        };

        const autofillStyle = {
            width: "200px"
        };

        // Generates hioperator assignee options
        var hioperators = [{ text: "(Unassign)", value: "unassign" }, { divider: true }];
        var i;
        if (this.props.client !== undefined && this.props.client.agents !== undefined) {
            for (i = 0; i < this.props.client.agents.length; i++) {
                hioperators.push({
                    text: this.props.client.agents[i].name,
                    value: this.props.client.agents[i].id
                });
            }
        }

        const typeOptions = [
            {
                text: "Public response",
                value: "public"
            },
            {
                text: "Internal note (CRM)",
                value: "internal"
            },
            {
                text: "Internal note (HiOperator only)",
                value: "hioperator"
            }
        ];

        var messageType = "internal";
        if (this.props.item) {
            if (this.props.item.public || this.props.item.public === undefined) messageType = "public";
            else if (this.props.item.internal) messageType = "hioperator";
        } else {
            if (this.props.item.public || this.props.item.public === undefined) messageType = "public";
            else if (this.props.item.internal) messageType = "hioperator";
        }

        const isPublic =
            this.props.item !== undefined && (this.props.item.public || this.props.item.public === undefined)
                ? true
                : false;

        var backgroundColor = "white";

        if (!isPublic)
            backgroundColor = this.props.item.internal ? "rgba(244, 134, 66, 0.3)" : "rgba(135, 206, 250, 0.3)";

        const textStyle = {
            backgroundColor
        };

        return (
            <OuterScaffold spaceForHeader={true} verticalAlign={false} align={"left"}>
                <div style={leftControlPanelStyle} className="scrollable">
                    <div style={controlInnerStyle}>
                        <Dropdown
                            id={"assignee-dropdown"}
                            field="assignee_id"
                            label="New Assignee"
                            value={this.props.item.assignee_id}
                            items={this.state.clientAgents}
                            updateFn={this._onBlur}
                            style={assigneeStyle}
                            searchable={true}
                        />
                        <div style={toggleRowStyle}>
                            <Search
                                placeholder="Search tags..."
                                id={"tags"}
                                type="tags"
                                fields={["text"]}
                                resultComponent={TagResult}
                                onSelect={this._onTagSearchSelect}
                                where={[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }]}
                                min={2}
                            />
                            <Tokenizer
                                field="tags"
                                value={this.props.item.tags || []}
                                id="reply-tags"
                                label="Add tag"
                                updateFn={this._onUpdateTokens}
                                style={tokenizerStyle}
                                noEntry={true}
                            />
                        </div>
                        {this.state.ticket_fields !== undefined ? (
                            <div style={customFieldsStyle}>
                                {this.state.ticket_fields.map((item, index) => (
                                    <TicketField
                                        item={item}
                                        id={item.id}
                                        key={"ticket-field-" + item.id}
                                        reply={this.props.item}
                                        ticket={{}}
                                        updateFn={this._onReplace}
                                        updateFnSystem={this._updateFnSystem}
                                    />
                                ))}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div style={rightContainerStyle}>
                    <SectionCard title="Email Body">
                        <div style={textStyle}>
                            <NavHorizontal
                                id="response-nav"
                                items={typeOptions}
                                value={messageType}
                                updateFn={this._onUpdateType}
                            />
                            <InputMultiline
                                id="macro-body"
                                label="Body"
                                field="body"
                                onBlur={this._onBlur}
                                value={this.state.body}
                                style={inputStyle}
                            />
                        </div>

                        <Dropdown
                            id={"macro-autocompletes"}
                            text="Add autocompletes"
                            items={autoFills}
                            button={true}
                            style={autofillStyle}
                            updateFn={this._onAddAutofill}
                        />
                    </SectionCard>
                </div>
            </OuterScaffold>
        );
    }
}

export default Macro;
