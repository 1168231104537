import React from "react";

import Input from "../forms/Input.react";
import Button from "../buttons/Button.react";

// Functions
import validate from "../../../functions/validate";

// Sets validation
const urlValidation = [
	{
		type: "contains",
		value: "http"
	}
];

class HyperlinkButton extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._updateFn = this._updateFn.bind(this);

		this._onFocus = this._onFocus.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { focus: false, hover: false, text: "", url: "", validate: false };
	}

	_onClick() {
		var urlError = validate(urlValidation, this.state.url);

		if (urlError === "" && this.props.updateFn) {
			this.props.updateFn('<a href="' + this.state.url + '">' + this.state.text + "</a>");
			this.setState({ text: "", url: "", hover: false, focus: false, validate: false });
		} else this.setState({ validate: true });
	}

	_onFocus() {
		this.setState({ focus: true });
	}

	_onBlur() {
		this.setState({ focus: false });
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onTextReturn() {
		document.getElementById("url").focus();
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			marginRight: "8px",
			position: "relative"
		};

		const iconStyle = {
			fontSize: "20px",
			cursor: "pointer",
			display: "inline-block",
			color: "rgba(0, 0, 0, 0.62)"
		};

		const overlayStyle = {
			display: this.state.focus || this.state.hover ? "inline-block" : "none",
			position: "absolute",
			bottom: "calc(100% + 8px)",
			left: "-50px",
			width: "400px",
			backgroundColor: "black",
			color: "white",
			borderRadius: "4px",
			padding: "8px"
		};

		const buttonStyle = {
			padding: "none",
			border: "0px",
			backgroundColor: "transparent",
			outline: "none"
		};

		const inputStyle = {
			marginBottom: "8px"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const insertButtonStyle = {
			marginLeft: "8px"
		};

		return (
			<div style={primaryStyle}>
				<button style={buttonStyle} onFocus={this._onFocus} onBlur={this._onBlur}>
					<i style={iconStyle} className="material-icons">
						link
					</i>
				</button>
				<div style={overlayStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
					<Input
						id="text"
						label="Link text"
						field="text"
						updateFn={this._updateFn}
						onReturn={this._onTextReturn}
						dark={true}
						onFocus={this._onFocus}
						onBlur={this._onBlur}
						style={inputStyle}
					/>
					<Input
						id="url"
						label="Link URL"
						field="url"
						updateFn={this._updateFn}
						onReturn={this._onClick}
						validate={this.state.validate}
						validation={urlValidation}
						dark={true}
						onFocus={this._onFocus}
						onBlur={this._onBlur}
						style={inputStyle}
					/>
					<div style={rowStyle}>
						<Button text="INSERT" style={insertButtonStyle} onClick={this._onClick} dark={true} type="flat" />
					</div>
				</div>
			</div>
		);
	}
}

export default HyperlinkButton;
