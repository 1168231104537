import React from "react";
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Overlay from "../Overlay.react";
import Container from "../Container.react";
import Card from "../Card.react";

import H1 from "../headers/H1.react";
import Button from "../buttons/Button.react";

// Form items
import Input from "../forms/Input.react";
import InputMultiline from "../forms/InputMultiline.react";
import Toggle from "../forms/Toggle.react";

// Functions
import validate from "../../../functions/validate";

const toggleLabelStyle = {
	fontSize: "12px",
	marginRight: "8px",
	display: "inline-block",
	verticalAlign: "middle",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)",
	marginTop: "4px"
};

const toggleRowStyle = {
	width: "calc(100% - 16px)",
	display: "inline-block",
	textAlign: "right",
	padding: "0px 8px 16px"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "center"
};

const buttonStyle = {
	marginLeft: "8px"
};

const rowStyle = {
	width: "100%",
	textAlign: "right",
	margin: "16px 0px 0px 0px"
};

class FormItemBuilder extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClose = this._onClose.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = {
			field: this.props.item.field || "",
			label: this.props.item.label || "",
			width: this.props.item.width || "",
			required: this.props.item.required || ""
		};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value, validate: false });
		WorkflowActions.setField(field, this.props.item.location.concat(this.props.item.id), value, false);
	}

	_onClose() {
		WorkflowActions.update();
		UIActions.showOverlay("");
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text={"Setup Field"} textAlign="center" />
						<Input
							id="new-form-label"
							label={"Label"}
							field="label"
							value={this.state.label}
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							helpText={"E.g., Product SKU"}
						/>
						<Input
							id="new-form-field"
							label={"Field"}
							field="field"
							value={this.state.field}
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							helpText={"E.g., product_sku"}
						/>
						<Input
							id="new-form-width"
							label={"Width"}
							field="width"
							value={this.state.width}
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							helpText={"E.g., 200px"}
						/>
						<div style={toggleRowStyle}>
							<div style={toggleLabelStyle}>Required</div>
							<Toggle
								id={"new-form-required"}
								field="required"
								updateFn={this._onBlur}
								value={this.state.required || false}
							/>
						</div>

						<div style={rowStyle}>
							<Button text="Close" style={buttonStyle} onClick={this._onClose} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default FormItemBuilder;
