export default {
    name: "Shopify",
    type: "shopify",
    category: "Store Fronts",
    icon: "shopping_cart",
    apiFields: [
        {
            field: "name",
            label: "Store name",
            type: "text"
        },
        {
            field: "apiKey",
            label: "apiKey",
            type: "text"
        },
        {
            field: "password",
            label: "Password",
            type: "text"
        }
    ],
    cardFields: [
        {
            field: "action",
            label: "Shopify Action",
            type: "dropdown",
            items: [
                {
                    text: "Get customer",
                    value: "get customer"
                },
                {
                    text: "Get customer orders",
                    value: "get customer orders"
                },
                {
                    text: "Get order",
                    value: "get order"
                },
                {
                    text: "Update order",
                    value: "update order"
                },
                {
                    text: "Cancel order",
                    value: "cancel order"
                },
                {
                    text: "Refund order",
                    value: "refund order"
                },
                {
                    text: "Get order transactions",
                    value: "get transactions"
                },
                {
                    text: "Create gift card",
                    value: "create gift card"
                },
                {
                    text: "Get product",
                    value: "get product"
                }
            ]
        },
        {
            field: "fieldType",
            label: "Field to use",
            type: "dropdown",
            items: [
                {
                    text: "Customer ID",
                    value: "id"
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Name",
                    value: "name"
                }
            ],
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get customer"
                        }
                    ]
                }
            ]
        },
        {
            field: "value",
            label: "Value",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get customer"
                        }
                    ]
                }
            ]
        },
        {
            field: "value",
            label: "Customer ID",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get customer orders"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "create gift card"
                        }
                    ]
                }
            ]
        },
        {
            field: "value",
            label: "Order Number",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get order"
                        }
                    ]
                }
            ]
        },
        {
            field: "value",
            label: "Product ID",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get product"
                        }
                    ]
                }
            ]
        },

        {
            field: "updateJSON",
            label: "Update JSON",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update order"
                        }
                    ]
                }
            ]
        },
        {
            field: "value",
            label: "Order ID",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "cancel order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get transactions"
                        }
                    ]
                }
            ]
        },
        {
            field: "transaction",
            label: "Transaction ID",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund order"
                        }
                    ]
                }
            ]
        },
        {
            field: "amount",
            label: "Refund amount",
            type: "number",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "cancel order"
                        }
                    ]
                }
            ]
        },
        {
            field: "amount",
            label: "Amount",
            type: "number",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "create gift card"
                        }
                    ]
                }
            ]
        },
        {
            field: "note",
            label: "Note",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "refund order"
                        }
                    ]
                }
            ]
        }
    ]
};
