export default {
	name: "Shipwire",
	type: "shipwire",
	category: "Shipping",
	icon: "local_shipping",
	apiFields: [
		{
			field: "username",
			label: "Username",
			type: "text"
		},
		{
			field: "password",
			label: "Password",
			type: "text"
		}
	],
	cardFields: [
		{
			field: "action",
			label: "Shipwire Action",
			type: "dropdown",
			items: [
				{
					text: "Get SKUs",
					value: "get skus"
				}
			]
		}
	]
};
