import React from "react";
import integrations from "../../../integrations";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Input from "../forms/Input.react";
import Dropdown from "../forms/Dropdown.react";

// Functions
import * as displaylogic from "../../../functions/displaylogic";

class IntegrationField extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = { value: this.props.value || "" };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ value: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		if (!displaylogic.check(this.props.item.displaylogic, this.props.parent, this.props.ticket)) return "";

		if (this.props.item.type === "dropdown")
			return (
				<Dropdown
					id={"fieldType-" + this.props.item.field}
					items={this.props.item.items}
					field={this.props.item.field}
					location={this.props.location}
					updateFn={this._onBlur}
					value={this.state.value}
					label={this.props.item.label}
					helpText={this.props.item.helpText || ""}
				/>
			);
		else if (this.props.item.type === "text")
			return (
				<Input
					id={"fieldType-" + this.props.item.field}
					field={this.props.item.field}
					location={this.props.location}
					updateFn={this._updateFn}
					onBlur={this._onBlur}
					value={this.state.value}
					label={this.props.item.label}
					helpText={this.props.item.helpText || ""}
				/>
			);
		else if (this.props.item.type === "number")
			return (
				<Input
					id={"fieldType-" + this.props.item.field}
					field={this.props.item.field}
					location={this.props.location}
					updateFn={this._updateFn}
					onBlur={this._onBlur}
					value={this.state.value}
					label={this.props.item.label}
					type={"number"}
					helpText={this.props.item.helpText || ""}
				/>
			);
		else return "";
	}
}

const directionControlsStyle = {
	width: "100%",
	marginTop: "0px",
	marginBottom: "24px",
	textAlign: "center",
	display: "inline-block"
};

const checkingStyle = {
	width: "100%",
	padding: "0px 0px 16px",
	textAlign: "center"
};

class IntegrationCard extends React.Component {
	constructor(props) {
		super(props);

		var myIntegration, i;
		for (i = 0; i < integrations.length; i++) {
			if (
				integrations[i].type === this.props.item.type ||
				(this.props.item.type === "database" && integrations[i].type === "firebase")
			) {
				myIntegration = integrations[i];
				break;
			}
		}
		var myState = {
			integration: myIntegration,
			valid: true,
			nextPath: 0
		};

		this.state = myState;
	}

	componentDidMount() {
		if (this.props.direction === "back") setTimeout(WorkflowActions.previousCard, 500);
	}

	render() {
		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				noNextButton={true}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={directionControlsStyle}>
						{this.state.integration.cardFields.map((item, index) => (
							<IntegrationField
								key={"integration-" + this.props.item.id + "-" + index}
								value={this.props.item[item.field]}
								item={item}
								index={index}
								location={[this.props.item.id]}
								integration={this.state.integration}
								parent={this.props.item}
							/>
						))}
					</div>
				) : (
					<div>
						<Loading height="200px" />
						<div style={checkingStyle}>
							Interacting with {this.state.integration.name}
							...
						</div>
					</div>
				)}
			</Card>
		);
	}
}

export default IntegrationCard;
