import React from "react";
import Dropzone from "react-dropzone";

import crms from "../../crms";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";
import TicketActions from "../../actions/TicketActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import InputMultiline from "../_common/forms/InputMultiline.react";
import Button from "../_common/buttons/Button.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Tokenizer from "../_common/forms/Tokenizer.react";

// Fields controls
import TicketField from "../tickets/TicketField.react";

// Search
import Search from "../_common/Search.react";
import TagResult from "../_common/search/TagResult.react";

// Headers
import H1 from "../_common/headers/H1.react";
import H2 from "../_common/headers/H2.react";

// Functions
import * as client_components from "../../functions/client_components";

const autoFills = [
	{
		text: "Ticket title",
		value: "{{ticket.title}}"
	},
	{
		text: "Ticket description",
		value: "{{ticket.description}}"
	},
	{
		text: "Ticket url",
		value: "{{ticket.url}}"
	},
	{
		text: "Ticket id",
		value: "{{ticket.id}}"
	},
	{
		text: "Ticket source",
		value: "{{ticket.via}}"
	},
	{
		text: "Ticket status",
		value: "{{ticket.status}}"
	},
	{
		text: "Ticket priority",
		value: "{{ticket.priority}}"
	},
	{
		text: "Ticket type",
		value: "{{ticket.ticket_type}}"
	},
	{
		text: "Group name",
		value: "{{ticket.group.name}}"
	},
	{
		text: "Assignee email",
		value: "{{ticket.assignee.email}}"
	},
	{
		text: "Assignee name",
		value: "{{ticket.assignee.name}}"
	},
	{
		text: "Assignee first name",
		value: "{{ticket.assignee.first_name}}"
	},
	{
		text: "Assignee last name",
		value: "{{ticket.assignee.last_name}}"
	},
	{
		text: "Requester email",
		value: "{{ticket.requester.email}}"
	},
	{
		text: "Requester name",
		value: "{{ticket.requester.name}}"
	},
	{
		text: "Requester first name",
		value: "{{ticket.requester.first_name}}"
	},
	{
		text: "Requester last name",
		value: "{{ticket.requester.last_name}}"
	},
	{
		text: "Ticket tags",
		value: "{{ticket.tags}}"
	}
];

// Styles
const leftControlPanelStyle = {
	width: "400px",
	maxWidth: "25%",
	left: "0px",
	maxHeight: "calc(100vh - 150px)",
	overflowY: "auto",
	display: "inline-block",
	verticalAlign: "top",
	borderRight: "1px solid lightgrey",
	marginTop: "-24px",
	paddingTop: "24px"
};

const rightControlPanelStyle = {
	width: "calc(100% - 300px)",
	maxWidth: "800px",
	padding: "8px 0px 0px 8px",
	display: "inline-block",
	verticalAlign: "top"
};

const innerControlStyle = {
	width: "calc(100% - 8px)",
	display: "inline-block",
	paddingBottom: "400px",
	paddingLeft: "8px"
};

const cardStyle = {
	width: "calc(100%)",
	padding: "24px 0px",
	textAlign: "left",
	maxWidth: "1200px"
};

const rowStyle = {
	width: "calc(100% - 32px)",
	textAlign: "right",
	margin: "0px 0px -24px 0px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const controlsRowStyle = {
	display: "inline-block",
	padding: "8px",
	marginTop: "8px"
};

const autofillStyle = {
	width: "200px"
};

const toggleRowStyle = {
	textAlign: "left",
	display: "inline-block",
	width: "calc(100% - 16px)",
	padding: "8px 0px"
};

const tokenizerStyle = {
	marginTop: "4px"
};

const customFieldsStyle = {
	textAlign: "left",
	width: "100%",
	display: "inline-block"
};

const responseBoxStyle = {
	marginBottom: "0px"
};

const viaTypeStyle = {
	display: "inline-block",
	width: "268px",
	padding: "8px 16px",
	fontWeight: "700"
};

const dropZoneStyle = {
	marginTop: "-4px",
	display: "inline-block",
	verticalAlign: "middle"
};

const iconStyle = {
	fontSize: "20px",
	cursor: "pointer",
	display: "inline-block",
	marginRight: "8px",
	color: "rgba(0, 0, 0, 0.62)",
	verticalAlign: "middle"
};

const attachmentsStyle = {
	width: "calc(100% - 16px)",
	textAlign: "left",
	padding: "8px"
};

function get_ticket_fields(client) {
	var source = tickets_type(client);

	var componentFields = client_components.find(client.components || [], source);
	var ticketFields = [];
	var field;

	if (client !== undefined && componentFields !== null && componentFields.ticket_fields !== undefined) {
		componentFields = componentFields.ticket_fields;

		for (var j = 0; j < componentFields.length; j++) {
			field = componentFields[j];
			if (["subject", "description", "status", "group", "assignee"].indexOf(field.type) === -1 && field.active)
				ticketFields.push(field);
		}
	}

	return ticketFields;
}

function get_client_agents(client) {
	if ([null, undefined].indexOf(client) > -1) return [];

	var source = tickets_type(client);
	var componentUsers = client_components.find(client.components || [], source);
	var clientAgents = [];

	if (componentUsers !== null && componentUsers.clientAgents !== undefined) {
		componentUsers = componentUsers.clientAgents;

		for (var i = 0; i < componentUsers.length; i++) {
			clientAgents.push({
				text: componentUsers[i].name,
				value: componentUsers[i].id
			});
		}
	}

	return clientAgents;
}

var tickets_type = client => {
	if ([null, undefined].indexOf(client) > -1) return "zendesk";

	for (var i = 0; i < client.integrationsList.length; i++) {
		if (client.integrationsList[i].type === "zendesk") return "zendesk";
		else if (client.integrationsList[i].type === "front") return "front";
		else if (client.integrationsList[i].type === "liveagent") return "liveagent";
		else if (client.integrationsList[i].type === "desk") return "desk";
		else if (client.integrationsList[i].type === "gorgias") return "gorgias";
		else if (client.integrationsList[i].type === "kana") return "kana";
	}

	return null;
};

var get_channel = (client, ticket) => {
	if ([null, undefined].indexOf(client) > -1) return null;

	var component = client_components.find(client.components || [], ticket.source);
	if ([null, undefined, ""].indexOf(component) > -1 || component.channels === undefined) return null;

	for (var i = 0; i < component.channels.length; i++) {
		if (component.channels[i].type === ticket.via) return component.channels[i];
	}

	return null;
};

var crm_type = client => {
	for (var i = 0; i < client.integrationsList.length; i++) {
		if (client.integrationsList[i].type === "zendesk") return "zendesk";
		else if (client.integrationsList[i].type === "front") return "front";
		else if (client.integrationsList[i].type === "liveagent") return "liveagent";
		else if (client.integrationsList[i].type === "gmail") return "gmail";
		else if (client.integrationsList[i].type === "freshdesk") return "freshdesk";
		else if (client.integrationsList[i].type === "gorgias") return "gorgias";
		else if (client.integrationsList[i].type === "desk") return "desk";
	}
};

class Attachment extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onRemove = this._onRemove.bind(this);
	}

	_onClick() {
		UIActions.showOverlay("image overlay", {
			image: this.props.item,
			ticket: this.props.ticketId,
			comment: this.props.commentId
		});
	}

	_onRemove() {
		TicketActions.remove([this.props.item.id]);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "8px",
			textAlign: "center"
		};

		const imageStyle = {
			height: "60px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		const urlStyle = {
			fontSize: "12px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		const removeStyle = {
			display: "block",
			marginTop: "6px",
			fontSize: "12px",
			cursor: "pointer"
		};

		var source = "";

		if (this.props.item.content_type.indexOf("image") > -1) {
			if (this.props.item.thumbnails !== undefined && this.props.item.thumbnails.length > 0)
				source = this.props.item.thumbnails[0].content_url;
			else source = this.props.item.content_url || this.props.item.url;
		}

		var filename = this.props.item.file_name || this.props.item.filename;

		return (
			<div style={primaryStyle}>
				{this.props.item.content_type.indexOf("image") > -1 ? (
					<img style={imageStyle} src={source} onClick={this._onClick} alt={filename || "Attachment"} />
				) : (
					<a style={urlStyle} href={this.props.item.mapped_content_url || this.props.item.url} target="_blank">
						{filename || "Attachment"}
					</a>
				)}
				{this.props.inReply ? (
					<div style={removeStyle} onClick={this._onRemove}>
						Remove
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

const headerLeftStyle = {
	width: "100%",
	display: "inline-block",
	verticalAlign: "top",
	borderBottom: "1px solid lightgrey",
	marginBottom: "32px",
	padding: "16px 0px"
};

const addressRowStyle = {
	width: "100%",
	fontSize: "14px",
	marginBottom: "8px"
};

const addressLabelStyle = {
	color: "rgba(0, 0, 0, 0.67)",
	marginRight: "8px"
};

const addressTokenizerStyle = {
	verticalAlign: "middle",
	width: "calc(100% - 42px)"
};

class ReplyEditorOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
		this._onInsertHyperlink = this._onInsertHyperlink.bind(this);
		this._onAddAutofill = this._onAddAutofill.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onReplace = this._onReplace.bind(this);
		this._updateFnSystem = this._updateFnSystem.bind(this);
		this._onInputChange = this._onInputChange.bind(this);
		this._onCheckErrors = this._onCheckErrors.bind(this);
		this._onDropItem = this._onDropItem.bind(this);
		this._onTagSearchSelect = this._onTagSearchSelect.bind(this);

		// Gets character count
		var characterCount = 0;
		if ([undefined, null, ""].indexOf(this.props.item.text) === -1) {
			var tempDiv = document.createElement("div");
			tempDiv.innerHTML = this.props.item.text;
			characterCount = tempDiv.innerText.length;
		}

		this.state = {
			clientAgents: get_client_agents(this.props.client),
			text: this.props.item.text || "",
			tags: this.props.item.tags || [],
			ticket_fields:
				this.props.client !== undefined ? get_ticket_fields(this.props.client, this.props.ticket || {}) : [],
			errors: [],
			characterCount: characterCount,
			channel: this.props.client !== undefined ? get_channel(this.props.client, this.props.ticket || {}) : {}
		};
	}

	componentDidMount() {
		if (this.props.item.assignee_id === undefined) {
			/*
			var source = tickets_type(this.props.client);
			var componentUsers = client_components.find(this.props.client.components || [], source);
			componentUsers = componentUsers.clientAgents || [];

			for (var i = 0; i < componentUsers.length; i++) {
				if (componentUsers[i].hioperatorAgent) {
					var assignee;
					if (source === "zendesk") {
						assignee = componentUsers[i].default_group_id + "/" + componentUsers[i].id;
					} else if (source === "front") {
						assignee = componentUsers[i].id;
					}

					console.log("The assignee is " + assignee);
					this._onBlur("assignee_id", [this.props.item.id], assignee);
					break;
				}
            }
            */
		}
	}

	componentWillReceiveProps(nextProps) {
		//this.setState({ channel: get_channel(nextProps.client, nextProps.ticket || {}) });
	}

	_onDropItem(accepted, rejected) {
		if (accepted.length > 0) {
			this.setState({ uploading: true });
			TicketActions.addAttachments(accepted, true);
		} else {
			UIActions.addMessage(
				"Sorry, none of your files can be uploaded. Please ensure that none of the files are bigger 100MB.",
				"error"
			);
		}
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, [this.props.item.id], value);
	}

	_onClose() {
		UIActions.showOverlay("");
		WorkflowActions.update();
	}

	_onInputChange(field, location, value) {
		var el = document.getElementById("response-box");
		this.setState({ characterCount: value === "" ? 0 : el.innerText.length - 1 });
	}

	_onSubmit() {
		var errors = this._onCheckErrors();
		if (errors.length === 0) errors = this._onCheckBlacklist();

		if (errors.length === 0) {
			this.setState({ errors });
			TicketActions.setFullReply(this.props.item);
			TicketActions.submit("open", "workflows", false);
			WorkflowActions.setField("text", [this.props.item.id], "");
			this._onClose();
		} else this.setState({ errors });
	}

	_onClick() {
		var errors = this._onCheckErrors();
		if (errors.length === 0) errors = this._onCheckBlacklist();

		if (errors.length === 0) {
			this.setState({ errors });
			this._onClose();
		} else this.setState({ errors });
	}

	_onCheckErrors() {
		// Validates custom fields
		var i, j;
		var errors = [];
		for (i = 0; i < this.state.ticket_fields.length; i++) {
			if (this.state.ticket_fields[i].required) {
				if (this.state.ticket_fields[i].type === "tickettype") {
					if (this.props.item.external_type === undefined || this.props.item.external_type === null)
						errors.push(this.state.ticket_fields[i].id);
				} else if (this.state.ticket_fields[i].type === "priority") {
					if (this.props.item.external_priority === undefined || this.props.item.external_priority === null)
						errors.push(this.state.ticket_fields[i].id);
				} else {
					for (j = 0; j < this.props.item.custom_fields.length; j++) {
						if (
							this.props.item.custom_fields[j].id === this.state.ticket_fields[i].id &&
							this.props.item.custom_fields[j].value !== null &&
							this.props.item.custom_fields[j].value !== "" &&
							this.props.item.custom_fields[j].value !== false
						)
							break;
						else if (j === this.props.item.custom_fields.length - 1) errors.push(this.state.ticket_fields[i].id);
					}
				}
			}
		}

		// Checks length
		if (this.props.item.public && !isNaN(this.props.ticket.characterLimit)) {
			var newDiv = document.createElement("div");
			newDiv.innerHTML = this.props.item.text;
			if (newDiv.innerText.length > Number(this.props.ticket.characterLimit)) {
				UIActions.addMessage(
					"Your response is too long. Maximum number of characters allowed is " + this.props.ticket.characterLimit,
					"error"
				);

				errors.push("Too long");
			}
		}

		return errors;
	}

	_onCheckBlacklist() {
		var source = tickets_type(this.props.client);
		var component = client_components.find(this.props.client.components || [], source);

		if (component.blacklist !== undefined) {
			for (var i = 0; i < component.blacklist.length; i++) {
				if (this.state.text.indexOf(component.blacklist[i]) > -1) {
					UIActions.addMessage(
						"Your response cannot contain the expression '" + component.blacklist[i] + "'.",
						"error"
					);
					return [{}];
				}
			}
		}

		return [];
	}

	_onInsertHyperlink(value) {
		this.setState({ text: this.state.text + " " + value });
	}

	_onAddAutofill(field, location, value) {
		this.setState({ text: this.state.text + " " + value });
	}

	_onReplace(obj) {
		WorkflowActions.add("custom_fields", [this.props.item.id], obj);
	}

	_updateFnSystem(field, ticketField, value) {
		WorkflowActions.setField(field, [this.props.item.id], value);
		if (this.props.agentMode) TicketActions.setSystemField(field, ticketField, value);
	}

	_onTagSearchSelect(value) {
		var tags = this.props.item.tags || [];
		if (tags.indexOf(value.text) === -1) tags.push(value.text);

		WorkflowActions.setField("tags", [this.props.item.id], tags, true);
	}

	render() {
		var ticketAssignee = "";
		const characterCountStyle = {
			textAlign: "right",
			fontSize: "14px",
			padding: "8px 0px",
			width: "calc(100% - 300px)",
			display: "inline-block",
			color:
				!isNaN(this.props.ticket.characterLimit) && this.state.characterCount > Number(this.props.ticket.characterLimit)
					? "red"
					: "inherit"
		};

		var crmType = crm_type(this.props.client);
		var crmScaffold = {};
		var i;
		for (i = 0; i < crms.length; i++) {
			if (crms[i].type === crmType) {
				crmScaffold = crms[i];
				break;
			}
		}

		return (
			<Overlay>
				<Container maxWidth="1200px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<div style={leftControlPanelStyle} className="scrollable">
							<div style={innerControlStyle}>
								<H2 text="Ticket Fields" textAlign="center" />
								<div style={toggleRowStyle}>
									<Dropdown
										id={"assignee-dropdown"}
										field="assignee_id"
										label="Assignee"
										value={this.props.item.assignee_id || ticketAssignee}
										items={this.state.clientAgents}
										updateFn={this._onBlur}
										style={tokenizerStyle}
										searchable={true}
									/>
									<Search
										placeholder="Search tags..."
										id={"tags"}
										type="tags"
										fields={["text"]}
										resultComponent={TagResult}
										onSelect={this._onTagSearchSelect}
										where={[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }]}
										min={2}
									/>
									<Tokenizer
										field="tags"
										value={this.state.tags || []}
										id="reply-tags"
										label="Add tag"
										updateFn={this._onBlur}
										style={tokenizerStyle}
									/>
								</div>
								{this.state.ticket_fields !== undefined ? (
									<div style={customFieldsStyle}>
										{this.state.ticket_fields.map((item, index) => (
											<TicketField
												id={item.id}
												item={item}
												key={"ticket-field-" + item.id}
												ticket_id={this.props.ticket !== undefined ? this.props.ticket.id || index : index}
												error={this.state.errors.indexOf(item.id) > -1}
												reply={this.props.item}
												ticket={this.props.ticket || {}}
												updateFn={this._onReplace}
												updateFnSystem={this._updateFnSystem}
												allowCustom={this.props.agentMode ? false : true}
											/>
										))}
									</div>
								) : (
									""
								)}
							</div>
						</div>
						<div style={rightControlPanelStyle}>
							<H1 text="Setup Reply to Customer" textAlign="center" />
							{this.props.item.public ? (
								<div style={headerLeftStyle}>
									<div style={addressRowStyle}>
										<span style={addressLabelStyle}>Cc</span>
										{crmScaffold.ticketOptions.editCC ? (
											<Tokenizer
												id={"reply-cc"}
												value={this.props.item.cc || []}
												updateFn={this._onBlur}
												field="cc"
												style={addressTokenizerStyle}
												label="Add cc"
											/>
										) : (
											<span>{(this.props.item || []).cc || ""}</span>
										)}
									</div>
									<div style={addressRowStyle}>
										<span style={addressLabelStyle}>Bcc</span>
										{crmScaffold.ticketOptions.editBCC ? (
											<Tokenizer
												id={"reply-bcc"}
												value={this.props.item.bcc || []}
												updateFn={this._onBlur}
												field="bcc"
												style={addressTokenizerStyle}
												label="Add bcc"
											/>
										) : (
											<span>{(this.props.item || []).bcc || ""}</span>
										)}
									</div>
								</div>
							) : (
								""
							)}
							<InputMultiline
								id={"response-box"}
								field="text"
								value={this.state.text}
								label="Body"
								style={responseBoxStyle}
								class=""
								noLine={false}
								onBlur={this._onBlur}
								updateFn={this._onInputChange}
								pasteCorrect={false}
							/>
							<div style={viaTypeStyle}>{this.state.channel !== null ? "Via " + this.state.channel.label : ""}</div>
							<div style={characterCountStyle}>
								{this.state.characterCount} characters long{" "}
								{!isNaN(this.props.ticket.characterLimit) &&
								this.state.characterCount > Number(this.props.ticket.characterLimit)
									? ". Maximum number allowed is " + this.props.ticket.characterLimit
									: ""}
							</div>
							<div style={controlsRowStyle}>
								<Dropzone style={dropZoneStyle} onDrop={this._onDropItem} maxSize={10000000} multiple={true}>
									<i style={iconStyle} className="material-icons">
										attach_file
									</i>
								</Dropzone>
								{this.props.ticket === undefined ? (
									<Dropdown
										id={"macro-autocompletes"}
										text="Add autocompletes"
										items={autoFills}
										button={true}
										style={autofillStyle}
										updateFn={this._onAddAutofill}
										searchable={true}
									/>
								) : (
									""
								)}
							</div>
							{this.props.item !== undefined &&
							this.props.item.attachments !== undefined &&
							this.props.item.attachments.length > 0 ? (
								<div style={attachmentsStyle}>
									{this.props.item.attachments.map((item, index, arr) => (
										<Attachment
											item={item}
											key={this.props.item.id + "-" + index}
											ticketId={this.props.ticket.id}
											commentId={this.props.item.id}
										/>
									))}
								</div>
							) : (
								""
							)}
						</div>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							{[undefined, null].indexOf(this.state.channel) === -1 &&
							!isNaN(this.state.channel.length) &&
							this.state.channel.length > 0 &&
							this.props.ticket !== undefined &&
							this.props.item.public ? (
								<Button
									text="Submit as Open and Stay on Card"
									style={buttonStyle}
									onClick={this._onSubmit}
									loading={this.state.loading}
									type="flat"
								/>
							) : (
								""
							)}
							<Button text="Save" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ReplyEditorOverlay;
