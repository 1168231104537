import React from "react";

const imageStyle = {
	height: "40px",
	width: "40px",
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "16px",
	borderRadius: "50%",
	cursor: "pointer",
	fontWeight: "900",
	fontFamily: "Roboto",
	fontSize: "36px",
	textAlign: "center"
};

const imageTextStyle = {
	fontSize: "20px",
	height: "20px",
	width: "40px",
	textAlign: "center",
	padding: "10px 0px",
	backgroundColor: "rgba(0, 0, 0, 0.62)",
	color: "white",
	cursor: "pointer",
	borderRadius: "50%",
	verticalAlign: "middle",
	display: "inline-block",
	lineHeight: "20px",
	marginRight: "16px"
};

class PictureBadge extends React.Component {
	render() {
		const name = this.props.item.name || this.props.item.email;

		if (this.props.item.picture !== undefined)
			return <img style={imageStyle} src={this.props.item.picture} alt={name.toUpperCase().substr(0, 1)} />;
		else return <div style={imageTextStyle}>{name.toUpperCase().substr(0, 1)}</div>;
	}
}

export default PictureBadge;
