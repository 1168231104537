export default {
	name: "Firebase",
	type: "firebase",
	category: "Database",
	icon: "save",
	apiFields: [
		{
			field: "projectId",
			label: "Project Id",
			type: "text"
		},
		{
			field: "clientEmail",
			label: "Client Email",
			type: "text"
		},
		{
			field: "privateKey",
			label: "Private Key",
			type: "text"
		},
		{
			field: "privateKey",
			label: "Private Key",
			type: "text"
		},
		{
			field: "databaseURL",
			label: "Database URL",
			type: "text"
		}
	],
	cardFields: [
		{
			field: "subtype",
			label: "Firebase service",
			type: "dropdown",
			items: [
				{
					text: "Authentication",
					value: "authentication"
				},
				{
					text: "Firestore",
					value: "firestore"
				}
			]
		},
		{
			field: "fieldType",
			label: "Field",
			type: "dropdown",
			items: [
				{
					text: "ID",
					value: "id"
				},
				{
					text: "Email",
					value: "email"
				}
			],
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "subtype",
							state: "is",
							value: "authentication"
						}
					]
				}
			]
		},
		{
			field: "fieldType",
			label: "Field Type",
			type: "dropdown",
			items: [
				{
					text: "ID",
					value: "id"
				},
				{
					text: "Query",
					value: "query"
				}
			],
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "subtype",
							state: "is",
							value: "firestore"
						}
					]
				}
			]
		},
		{
			field: "collection",
			label: "Collection",
			type: "text",
			helpText: "e.g., users, users/askdkkasjsjsjs/emails",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "subtype",
							state: "is",
							value: "firestore"
						}
					]
				}
			]
		},
		{
			field: "value",
			label: "Value",
			type: "text",
			helpText: 'e.g., id="hello world" AND location="New York"',
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "fieldType",
							state: "is not",
							value: "query"
						}
					]
				}
			]
		},
		{
			field: "query",
			label: "Query",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "fieldType",
							state: "is",
							value: "query"
						}
					]
				}
			]
		},
		{
			field: "order",
			label: "Order",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "fieldType",
							state: "is",
							value: "query"
						}
					]
				}
			]
		},
		{
			field: "limit",
			label: "Limit",
			type: "number",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "fieldType",
							state: "is",
							value: "query"
						}
					]
				}
			]
		}
	]
};
