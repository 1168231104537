import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import ReactJson from "react-json-view";

// Actions
import TicketActions from "../../actions/TicketActions";
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import CardCollapsable from "../_common/CardCollapsable.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import H2 from "../_common/headers/H2.react";

import Email from "../_common/Email.react";

// Form items
import Dropdown from "../_common/forms/Dropdown.react";
import Button from "../_common/buttons/Button.react";

// Functions
import get_card from "../../functions/get_card";

const headerStyle = {
    display: "inline-block",
    borderBottom: "1px solid cadetblue",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 1)"
};

const bigCellStyle = {
    padding: "8px",
    width: "200px",
    display: "inline-block",
    verticalAlign: "middle"
};

const veryBigCellStyle = {
    padding: "8px",
    width: "300px",
    display: "inline-block",
    verticalAlign: "middle"
};

const conceptRowStyle = {
    display: "inline-block",
    borderBottom: "1px solid lightgrey",
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "background-color .3s ease"
};

const ruleSummaryPrimaryStyle = {
    width: "100%",
    display: "inline-block",
    padding: "16px 0px",
    textAlign: "center"
};

const summaryNameStyle = {
    width: "100%",
    display: "inline-block",
    textAlign: "left"
};

const summaryDescriptionStyle = {
    width: "100%",
    display: "inline-block",
    fontSize: "12px",
    textAlign: "left"
};

const summaryLinkStyle = {
    color: "rgb(70, 180, 175)",
    pointer: "cursor",
    display: "inline-block"
};

const mediaStyle = {
    maxWidth: "100%",
    maxHeight: "300px",
    display: "inline-block",
    margin: "8px 0px",
    textAlign: "left",
    cursor: "pointer"
};

class Resource extends React.Component {
    constructor(props) {
        super(props);
        this._onClick = this._onClick.bind(this);
    }

    componentDidMount() {
        if (this.props.item.type === "link" && this.props.item.autoopen) {
            window.open(this.props.item.link, "_blank");
            setTimeout(function() {
                window.focus();
            }, 1000);
        }
    }

    _onClick() {
        UIActions.showOverlay("image overlay", { image: { url: this.props.item.link } });
    }

    render() {
        return (
            <div style={ruleSummaryPrimaryStyle}>
                <div style={summaryNameStyle}>
                    {this.props.index + 1}
                    {") "}
                    {this.props.item.type === "link" ? (
                        <a style={summaryLinkStyle} href={this.props.item.link} target="_blank">
                            {this.props.item.name}
                        </a>
                    ) : (
                        this.props.item.name
                    )}
                </div>
                {this.props.item.type === "image" ? (
                    <img
                        src={this.props.item.link}
                        alt={this.props.item.name}
                        style={mediaStyle}
                        onClick={this._onClick}
                    />
                ) : (
                    ""
                )}
                {this.props.item.type === "video" ? (
                    <video
                        src={this.props.item.link}
                        alt={this.props.item.name}
                        style={mediaStyle}
                        controls
                        controlsList="nodownload"
                    />
                ) : (
                    ""
                )}

                <div
                    style={summaryDescriptionStyle}
                    dangerouslySetInnerHTML={{ __html: this.props.item.description }}
                />
            </div>
        );
    }
}

class ResourcesDisplay extends React.Component {
    render() {
        return (
            <CardCollapsable title={"Resources"}>
                {this.props.items.map((item, index, arr) => (
                    <Resource item={item} index={index} key={"resource-" + index} />
                ))}
            </CardCollapsable>
        );
    }
}

const workflowDesciptionStyle = {
    display: "inline-block",
    marginLeft: "16px"
};

class WorkflowDetails extends React.Component {
    render() {
        return (
            <CardCollapsable title={"Workflow Details"}>
                <div
                    style={workflowDesciptionStyle}
                    dangerouslySetInnerHTML={{
                        __html: this.props.workflow.description
                    }}
                />
            </CardCollapsable>
        );
    }
}

class ConceptDisplay extends React.Component {
    constructor(props) {
        super(props);
        this._onToggleOpen = this._onToggleOpen.bind(this);
        this.state = { open: true };
    }

    _onToggleOpen() {
        this.setState({ open: this.state.open ? false : true });
    }

    render() {
        var myKeys = Object.keys(this.props.items);

        return (
            <CardCollapsable title={"Ticket Concepts"}>
                <div style={headerStyle}>
                    <div style={bigCellStyle}>CONCEPT</div>
                    <div style={veryBigCellStyle}>VALUE</div>
                </div>
                {myKeys.map((item, index, arr) => (
                    <div key={"concept-" + index} style={conceptRowStyle}>
                        <div style={bigCellStyle}>
                            {item.substr(0, 1).toUpperCase() + item.substr(1, item.length).replace(/_/g, " ")}
                        </div>
                        <div style={veryBigCellStyle}>
                            {typeof this.props.items[item] === "object" ? (
                                <ReactJson
                                    src={this.props.items[item]}
                                    collapsed={true}
                                    enableClipboard={false}
                                    displayObjectSize={false}
                                    displayDataTypes={false}
                                /> //<JSONTree data={this.props.items[item]} theme={jsonTreeTheme} invertTheme={true} hideRoot={true} />
                            ) : (
                                this.props.items[item]
                            )}
                        </div>
                    </div>
                ))}
            </CardCollapsable>
        );
    }
}

class QuestionCardDisplay extends React.Component {
    render() {
        var MyCard = get_card(this.props.card.type);

        return (
            <MyCard
                key={this.props.id}
                item={this.props.card}
                client={this.props.client}
                agentMode={true}
                response={this.props.response}
                status={this.props.status}
                direction={this.props.direction}
            />
        );
    }
}

const leftColumnStyle = {
    width: "50%",
    display: "inline-block",
    textAlign: "center",
    height: "calc(100vh - 70px)",
    borderRight: "1px solid lightgrey",
    overflow: "auto",
    position: "relative"
};

const rightColumnStyle = {
    width: "calc(50% - 48px)",
    display: "inline-block",
    textAlign: "center",
    marginLeft: "-1px",
    height: "calc(100vh - 70px - 48px)",
    overflow: "auto",
    padding: "24px"
};

const vertAlignStyle = {
    verticalAlign: "middle",
    display: "inline-block",
    width: "0px",
    height: "90vh"
};

const shellStyle = {
    heigth: "calc(100vh - 66px)",
    width: "100%",
    display: "inline-block"
};

const emptyStyle = {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    width: "100%",
    maxWidth: "800px"
};

const emptyTextStyle = {
    fontSize: "40px",
    fontWeight: "400",
    marginBottom: "20px"
};

const assigneeStyle = {
    width: "calc(100% - 16px)",
    maxWidth: "300px"
};

const containerStyle = {
    width: "100%",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "top"
};

const idLabel = {
    width: "calc(100% - 16px)",
    textAlign: "left",
    padding: "0px 8px 16px"
};

const stemUpStyle = {
    position: "absolute",
    top: "0%",
    left: "calc(50% - 2px)",
    width: "4px",
    height: "50vh",
    backgroundColor: "rgb(70, 180, 175)",
    zIndex: "-1"
};

const stemDownStyle = {
    position: "absolute",
    bottom: "0%",
    left: "calc(50% - 2px)",
    width: "4px",
    height: "50vh",
    backgroundColor: "rgb(70, 180, 175)",
    zIndex: "-1"
};

const lineUpButtonStyle = {
    position: "absolute",
    top: "16px",
    left: "calc(50% + 16px)"
};

class Workflow extends React.Component {
    constructor(props) {
        super(props);
        this._onUpdate = this._onUpdate.bind(this);
    }

    componentDidMount() {
        console.log("WORKFLOW MOUNTED " + new Date().toISOString());
    }

    _onUpdate(field, location, value) {
        TicketActions.setField(field, location, value, true, true);
        if (field === "chosen-client") TicketActions.getTicket("workflows");
    }

    _onPrevious() {
        WorkflowActions.previousCard();
    }

    render() {
        var allReady =
            this.props.ticket !== undefined &&
            this.props.workflow !== undefined &&
            this.props.ticketWorkflow !== undefined &&
            this.props.card !== undefined &&
            this.props.response !== undefined
                ? true
                : false;

        return (
            <OuterScaffold spaceForHeader={true} align={allReady ? "left" : "center"}>
                {this.props.clientSelected === undefined ? (
                    <div style={emptyStyle}>
                        <div style={emptyTextStyle}>Please choose a client.</div>
                        <Dropdown
                            id={"client-dropdown"}
                            field="chosen-client"
                            label="Client to work on"
                            value={this.props.clientSelected || ""}
                            items={this.props.clientOptions || []}
                            updateFn={this._onUpdate}
                            style={assigneeStyle}
                            searchable={true}
                        />
                    </div>
                ) : (
                    ""
                )}
                {!allReady && this.props.clientSelected !== undefined ? (
                    <div style={emptyStyle}>
                        <div style={emptyTextStyle}>
                            No tickets to do! Will automatically update once there are new tickets in the queue.
                        </div>
                        <Dropdown
                            id={"client-dropdown"}
                            field="chosen-client"
                            label="Client to work on"
                            value={this.props.clientSelected || ""}
                            items={this.props.clientOptions || []}
                            updateFn={this._onUpdate}
                            style={assigneeStyle}
                            searchable={true}
                        />
                    </div>
                ) : (
                    ""
                )}

                <div style={containerStyle}>
                    {allReady ? (
                        <div key={"outer-left-" + this.props.ticket.id} style={leftColumnStyle}>
                            <ReactCSSTransitionGroup
                                transitionName={this.props.direction === "forward" ? "card" : "reverse"}
                                transitionAppear={true}
                                transitionLeave={true}
                                transitionEnter={true}
                                transitionAppearTimeout={300}
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300}
                            >
                                <div style={shellStyle} key={"shell-display-" + this.props.card.id}>
                                    <div style={vertAlignStyle} />
                                    <QuestionCardDisplay
                                        key={"car-display-" + this.props.card.id}
                                        card={this.props.card}
                                        client={this.props.client}
                                        response={this.props.response}
                                        status={this.props.status || []}
                                        direction={this.props.direction}
                                    />
                                    {this.props.card.from !== undefined && this.props.card.from.length > 0 ? (
                                        <div style={stemUpStyle} />
                                    ) : (
                                        ""
                                    )}
                                    {this.props.card.from !== undefined && this.props.card.from.length > 0 ? (
                                        <Button
                                            type="flat"
                                            text="Back"
                                            style={lineUpButtonStyle}
                                            onClick={this._onPrevious}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {this.props.card.to !== undefined && this.props.card.to.length > 0 ? (
                                        <div style={stemDownStyle} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </ReactCSSTransitionGroup>
                        </div>
                    ) : (
                        ""
                    )}
                    {allReady ? (
                        <div style={rightColumnStyle}>
                            {[null, undefined, ""].indexOf(this.props.workflow.description) === -1 ? (
                                <WorkflowDetails workflow={this.props.workflow} />
                            ) : (
                                ""
                            )}

                            <H2 text="Ticket Details and Resources" textAlign="center" margin="8px 0px 24px 0px" />
                            <div style={idLabel}>
                                <b>ZD #</b>: {this.props.ticket.external_id}, <b>HiO #</b>: {this.props.ticket.id}
                            </div>

                            <Email
                                displayOnly={true}
                                collapsable={true}
                                key={this.props.ticket.id}
                                ticket={this.props.ticket}
                                client={this.props.client}
                                user={this.props.user}
                                status={this.props.status}
                                showEvents={false}
                            />
                            {this.props.card.resources !== undefined && this.props.card.resources.length > 0 ? (
                                <ResourcesDisplay
                                    key={"resources-" + this.props.card.id}
                                    items={this.props.card.resources}
                                />
                            ) : (
                                ""
                            )}

                            <ConceptDisplay items={this.props.ticketWorkflow.concepts} />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </OuterScaffold>
        );
    }
}

export default Workflow;
