import React from "react";

const arrowIconStyle = {
    display: "inline-block",
    verticalAlign: "middle"
};

class SectionSelector extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);

        this.state = { hover: false };
    }

    _onMouseOver() {
        if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
        this.setState({ hover: true });
    }

    _onMouseOut() {
        if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
        this.setState({ hover: false });
    }

    _onClick() {
        this.props.onClick(this.props.field, this.props.location, this.props.value);
        //this.props.updateFn(this.props.subsection, this.props.integration);
    }

    render() {
        const color = this.props.color || "transparent";

        const primaryStyle = {
            borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
            padding: "16px",
            borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
            position: "relative",
            backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
            transition: "background-color .3s ease",
            cursor: "pointer",
            display: "inline-block",
            width: "calc(100% - 32px)"
        };

        const subsectionIconStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0px 24px 0px 8px",
            color: this.props.active ? "rgb(70, 180, 175)" : "grey"
        };
        const subsectionNameStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            width: this.props.icon !== undefined ? "calc(100% - 90px)" : "calc(100% - 34px)"
        };

        const updatedTag = {
            fontSize: "10px",
            padding: "4px",
            borderRadius: "4px",
            backgroundColor: "lightgreen",
            border: "1px solid grey",
            marginLeft: "8px",
            verticalAlign: "middle"
        };

        return (
            <div
                index={this.props.index}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                style={primaryStyle}
                onClick={this._onClick}
            >
                {this.props.reverseArrow ? (
                    <i style={arrowIconStyle} className="material-icons">
                        keyboard_arrow_left
                    </i>
                ) : (
                    ""
                )}
                {this.props.icon !== undefined ? (
                    <i style={subsectionIconStyle} className="material-icons">
                        {this.props.icon}
                    </i>
                ) : (
                    ""
                )}
                <div style={subsectionNameStyle}>
                    {this.props.text} {this.props.recentlyUpdated ? <span style={updatedTag}>Updated</span> : ""}{" "}
                </div>
                {!this.props.reverseArrow ? (
                    <i style={arrowIconStyle} className="material-icons">
                        keyboard_arrow_right
                    </i>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default SectionSelector;
