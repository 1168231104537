import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Row from "../_common/Row.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";

// Buttons
import Button from "../_common/buttons/Button.react";
import FAB from "../_common/buttons/FAB.react";
import ProductActions from "../../actions/ProductActions";

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const buttonRowStyle = {
	width: "100%",
	marginTop: "32px",
	textAlign: "left"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class ProductRow extends React.Component {
	render() {
		const itemCount = this.props.item.items !== undefined ? this.props.item.items.length : 0;
		return (
			<Row
				index={this.props.index}
				href={"#clients/" + this.props.clientId + "/products/" + this.props.item.id}
				newTab={this.props.newTab || false}
				style={rowStyle}
				hoverChange={this._onHoverChange}
			>
				<Cell size="m">{this.props.item.name}</Cell>
				<Cell size="s">{itemCount}</Cell>
			</Row>
		);
	}
}

class Products extends React.Component {
	constructor(props) {
		super(props);
		this._onNewClick = this._onNewClick.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNewClick() {
		UIActions.showOverlay("new object", {
			actions: ProductActions,
			type: "product",
			label: "Product",
			status: this.props.status || []
		});
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="INVENTORY CATALOGS" subtext="Setup and edit inventory catalogs for client.">
						{this.props.items === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no products</div>
								<Button text="Add Workflow" onClick={this._onNewClick} />
							</div>
						) : (
							<div style={integrationsStyle}>
								<HeaderRow>
									<Cell size="m">Name</Cell>
									<Cell size="s"># of items</Cell>
								</HeaderRow>
								{this.props.items.map((item, index, arr) => (
									<ProductRow key={item.id} index={index} item={item} clientId={this.props.clientId} />
								))}
								<div key="workflow-button-row" style={buttonRowStyle}>
									<Button text="Add Catalog" onClick={this._onNewClick} />
								</div>
							</div>
						)}
					</SectionCard>
				</Container>
				<FAB onClick={this._onNewWorkflow} />
			</OuterScaffold>
		);
	}
}

export default Products;
