import React from "react";
import Reflux from "reflux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import config from "./config.public";

// Actions
import MacroActions from "./actions/MacroActions";
import WorkflowActions from "./actions/WorkflowActions";
import TicketActions from "./actions/TicketActions";
import ProductActions from "./actions/ProductActions";

// Stores
import UIStore from "./stores/UIStore";
import UserStore from "./stores/UserStore";
import ClientStore from "./stores/ClientStore";
import AgentStore from "./stores/AgentStore";
import WorkflowStore from "./stores/WorkflowStore";
import MacroStore from "./stores/MacroStore";
import TicketStore from "./stores/TicketStore";
import ProductStore from "./stores/ProductStore";
import DocumentStore from "./stores/DocumentStore";
import TriageOptionsStore from "./stores/TriageOptionsStore";

// Login pieces
import Login from "./components/_common/login/Login.react";
import ResetPassword from "./components/_common/login/ResetPassword.react";
import NewPassword from "./components/_common/login/NewPassword.react";

// Generic pieces
import AppBar from "./components/_common/AppBar.react";
import SideNavigation from "./components/_common/SideNavigation.react";
import Loading from "./components/_common/Loading.react";
import ClockIn from "./components/_common/ClockIn.react";

// Home
import ManagerHome from "./components/home/ManagerHome.react";
import AgentHome from "./components/home/AgentHome.react";

// Clients
import ClientsSummary from "./components/clients/ClientsSummary.react";
import Client from "./components/clients/Client.react";

// Agents
import AgentsSummary from "./components/agents/AgentsSummary.react";
import Agent from "./components/agents/Agent.react";

// Workflows
import Workflow from "./components/workflows/Workflow.react";
import AgentWorkflow from "./components/workflows/AgentWorkflow.react";
import TicketlessWorkflow from "./components/workflows/TicketlessWorkflow.react";
import WorkflowsSummary from "./components/workflows/WorkflowsSummary.react";

// Macros
import Macro from "./components/macros/Macro.react";

// Tickets
import Ticket from "./components/tickets/Ticket.react";
import ActiveTickets from "./components/tickets/ActiveTickets.react";

// Products
import Catalog from "./components/products/Catalog.react";

// Schedule
import Schedule from "./components/schedule/SchedulePage.react";

// Overlays
import OverlayLayer from "./components/_common/OverlayLayer.react";

// Messaging
import Messages from "./components/_common/Messages.react";
import Snackbar from "./components/_common/Snackbar.react";

// Menu settings
const menuOptions = [
    {
        text: "Save",
        value: "save",
    },
    {
        text: "Save as New",
        value: "save as new",
    },
    {
        divider: true,
    },
    {
        text: "Delete",
        value: "delete",
    },
];

const workflowMenuOptions = [
    {
        text: "Save",
        value: "save",
    },
    {
        text: "Save as New",
        value: "save as new",
    },
    {
        text: "Save as New Version",
        value: "save as new version",
    },
    {
        text: "Save as Template",
        value: "save as template",
    },
    {
        text: "Settings",
        value: "workflow settings",
    },
    {
        divider: true,
    },
    {
        text: "Delete",
        value: "delete",
    },
];

const macroMenuOptions = [
    {
        text: "Save",
        value: "save",
    },
    {
        text: "Save as New",
        value: "save as new",
    },
    {
        text: "Settings",
        value: "macro settings",
    },
    {
        divider: true,
    },
    {
        text: "Delete",
        value: "delete",
    },
];

const primaryStyle = {
    width: "100%",
    display: "inline-block",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "rgba(0, 0, 0, 0.87)",
};

function get_url(target) {
    var url = "";
    const myRoute = document.location.hash.replace("#", "").split("/");
    if (myRoute.indexOf(target) > -1) {
        for (var i = 0; i < myRoute.length; i++) {
            if (myRoute[i] === target) {
                url += myRoute[i];
                break;
            } else url += myRoute[i] + "/";
        }
    }

    return url;
}

//var nonLoggin = ["login", "reset password", "new password", "loading"];

class App extends Reflux.Component {
    constructor(props) {
        super(props);
        this.stores = [
            UIStore,
            UserStore,
            ClientStore,
            AgentStore,
            WorkflowStore,
            MacroStore,
            TicketStore,
            ProductStore,
            DocumentStore,
            TriageOptionsStore,
        ];
    }

    componentDidUpdate() {
        console.log("did update");
    }

    render() {
        var toRender = [];
        var view = this.state.UIStore.view;

        //if (!this.state.UserStore.loggedIn && nonLoggin.indexOf(view) === -1) view = "loading";
        if (view.indexOf("clients_") > -1 && this.state.ClientStore.working === undefined) {
            view = "loading";
        } else if (view.indexOf("gurus_") > -1 && this.state.AgentStore.working === undefined) {
            view = "loading";
        } else if (view === "tickets_details" && this.state.TicketStore.allLoaded !== true) view = "loading";
        else if (view === "workflows_details" && this.state.WorkflowStore.working === undefined) view = "loading";
        else if (view === "macros_details" && this.state.MacroStore.working === undefined) view = "loading";
        else if (view === "products_details" && this.state.ProductStore.working === undefined) view = "loading";
        else if (
            view.indexOf("#ticket") > -1 &&
            (this.state.TicketStore.status.indexOf("getting ticket") > -1 ||
                this.state.TicketStore.status.indexOf("getting ticket from queue") > -1)
        )
            view = "loading";

        // Simplifies pages
        if (view.indexOf("clients_") > -1) view = "client";
        else if (view.indexOf("gurus_") > -1) view = "guru";

        if (this.state.UserStore.me !== undefined && this.state.UserStore.me.role === "agent" && this.state.UserStore.me.clockedIn !== true)
            view = "clockin";

        // Main layer
        switch (view) {
            case "loading":
                toRender.push(<Loading key="loading-view" />);
                break;
            case "clockin":
                toRender.push(<ClockIn key="clockin-view" />);
                break;
            case "login":
                toRender.push(
                    <Login
                        key="login-view"
                        UserStore={this.state.UserStore}
                        status={this.state.UserStore.status || []}
                        errors={this.state.UserStore.errors}
                    />
                );
                break;
            case "reset_password":
                toRender.push(
                    <ResetPassword
                        key="reset-password-view"
                        status={this.state.UserStore.status || []}
                        errors={this.state.UserStore.errors}
                    />
                );
                break;
            case "new_password":
                toRender.push(
                    <NewPassword key="new-password-view" status={this.state.UserStore.status || []} errors={this.state.UserStore.errors} />
                );
                break;
            case "home_manager":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Home" dynamic={true} />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <ManagerHome
                        key="manager-home-view"
                        user={this.state.UserStore.me}
                        status={this.state.UserStore.status || []}
                        errors={this.state.UserStore.errors}
                        item={this.state.UserStore}
                    />
                );
                break;
            case "home_guru":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Home" dynamic={true} />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <AgentHome
                        key="guru-home-view"
                        user={this.state.UserStore.me}
                        status={this.state.UserStore.status || []}
                        errors={this.state.UserStore.errors}
                        item={this.state.UserStore}
                    />
                );
                break;
            case "client":
                toRender.push(
                    <Client
                        key="client"
                        view={this.state.UIStore.view}
                        ClientStore={this.state.ClientStore}
                        UserStore={this.state.UserStore}
                        UIStore={this.state.UIStore}
                        AgentStore={this.state.AgentStore}
                        DocumentStore={this.state.DocumentStore}
                        ProductStore={this.state.ProductStore}
                        TriageOptionsStore={this.state.TriageOptionsStore}
                    />
                );
                break;
            case "clients":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Clients" />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <ClientsSummary
                        key="client-summary-view"
                        summary={this.state.ClientStore.summary}
                        status={this.state.ClientStore.status}
                    />
                );
                break;
            case "guru":
                toRender.push(
                    <Agent
                        key="guru"
                        view={this.state.UIStore.view}
                        UserStore={this.state.UserStore}
                        UIStore={this.state.UIStore}
                        AgentStore={this.state.AgentStore}
                        ClientStore={this.state.ClientStore}
                    />
                );
                break;
            case "gurus":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Gurus" />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <AgentsSummary key="guru-summary-view" summary={this.state.AgentStore.summary} status={this.state.AgentStore.status} />
                );
                break;
            case "workflows":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Workflows" />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <WorkflowsSummary
                        key="client-summary-view"
                        summary={this.state.WorkflowStore.summary}
                        status={this.state.WorkflowStore.status}
                    />
                );
                break;
            case "activetickets":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Active Tickets" />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <ActiveTickets
                        key="active-tickets-view"
                        tickets={this.state.TicketStore.active}
                        clients={this.state.ClientStore.summary}
                    />
                );
                break;
            case "schedule":
                toRender.push(<AppBar key="app-bar" UserStore={this.state.UserStore.me} text="Active Tickets" />);
                toRender.push(
                    <SideNavigation
                        key="side-navigation"
                        user={this.state.UserStore.me}
                        view={this.state.UIStore.view}
                        pages={config.pages}
                        userPages={this.state.UserStore.pages}
                    />
                );
                toRender.push(
                    <Schedule key="active-tickets-view" gurus={this.state.AgentStore.summary} UserStore={this.state.UserStore.me} />
                );
                break;
            case "workflows_details":
                toRender.push(
                    <AppBar
                        key={this.state.WorkflowStore.working === undefined ? "app-bar" : "app-bar-" + this.state.WorkflowStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={this.state.WorkflowStore.working.name}
                        dynamic={false}
                        actions={WorkflowActions}
                        target="workflows"
                        data={
                            this.state.WorkflowStore.working !== undefined
                                ? {
                                      maxVersion: this.state.WorkflowStore.working.maxVersion,
                                      currentVersion: this.state.WorkflowStore.working.currentVersion,
                                      editingVersion: this.state.WorkflowStore.working.editingVersion,
                                  }
                                : ""
                        }
                        active={this.state.WorkflowStore.working.active}
                        backURL={get_url("workflows")}
                        backSaveCheck={true}
                        menuOptions={workflowMenuOptions}
                    />
                );
                toRender.push(
                    <Workflow
                        key={
                            this.state.WorkflowStore.working === undefined
                                ? "workflow-details"
                                : "workflow-details-" + this.state.WorkflowStore.working.id
                        }
                        item={this.state.WorkflowStore.working}
                        client={this.state.ClientStore.working}
                        status={this.state.WorkflowStore.status}
                    />
                );
                break;
            case "macros_details":
                toRender.push(
                    <AppBar
                        key={this.state.MacroStore.working === undefined ? "app-bar" : "app-bar-" + this.state.MacroStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={this.state.MacroStore.working.title}
                        dynamic={false}
                        actions={MacroActions}
                        field="title"
                        target="macros"
                        active={this.state.MacroStore.working.active}
                        backURL={get_url("macros")}
                        backSaveCheck={true}
                        menuOptions={macroMenuOptions}
                    />
                );
                toRender.push(
                    <Macro
                        key={
                            this.state.MacroStore.working === undefined
                                ? "macro-details"
                                : "macro-details-" + this.state.MacroStore.working.id
                        }
                        item={this.state.MacroStore.working}
                        client={this.state.ClientStore.working}
                        status={this.state.MacroStore.status}
                    />
                );
                break;
            case "products_details":
                toRender.push(
                    <AppBar
                        key={this.state.ProductStore.working === undefined ? "app-bar" : "app-bar-" + this.state.ProductStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={this.state.ProductStore.working.name}
                        dynamic={false}
                        actions={ProductActions}
                        field="name"
                        target="products"
                        active={this.state.ProductStore.working.active}
                        backURL={get_url("products")}
                        menuOptions={menuOptions}
                    />
                );
                toRender.push(
                    <Catalog
                        key={
                            this.state.ProductStore.working === undefined
                                ? "product-details"
                                : "product-details-" + this.state.ProductStore.working.id
                        }
                        item={this.state.ProductStore.working}
                        status={this.state.ProductStore.status}
                    />
                );
                break;
            case "tickets_details":
                toRender.push(
                    <AppBar
                        key={this.state.TicketStore.working === undefined ? "app-bar" : "app-bar-" + this.state.TicketStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.name : "Tickets"}
                        picture={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.picture : ""}
                        dynamic={false}
                        goBack={true}
                        noSave={true}
                        noDropdown={true}
                    />
                );
                toRender.push(
                    <Ticket
                        key={
                            this.state.TicketStore.working === undefined
                                ? "ticket-view"
                                : "ticket-view-" + this.state.TicketStore.working.id
                        }
                        ticket={this.state.TicketStore.working}
                        reply={this.state.TicketStore.reply}
                        client={this.state.ClientStore.working}
                        user={this.state.UserStore.me}
                        permissions={this.state.UserStore.permissions}
                        status={this.state.TicketStore.status}
                        forManagers={true}
                    />
                );
                break;
            case "ticketworkflows":
            case "ticketworkflows_details":
                toRender.push(
                    <AppBar
                        key={this.state.TicketStore.working === undefined ? "app-bar" : "app-bar-" + this.state.TicketStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={
                            this.state.WorkflowStore.working !== undefined ? this.state.WorkflowStore.working.name || "Tickets" : "Tickets"
                        }
                        picture={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.picture : ""}
                        queueCount={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.workflowQueueCount : ""}
                        dynamic={false}
                        actions={TicketActions}
                        mode={this.state.TicketStore.mode}
                        changeMode={TicketActions.changeMode}
                        showAbout={true}
                        clientLoaded={this.state.ClientStore.working !== undefined}
                    />
                );
                if (this.state.TicketStore.mode === "play") {
                    toRender.push(
                        <AgentWorkflow
                            key={
                                this.state.TicketStore.working === undefined
                                    ? "ticket-guru-view"
                                    : "ticket-guru-view-" + this.state.TicketStore.working.id
                            }
                            ticket={this.state.TicketStore.working}
                            workflow={this.state.WorkflowStore.working}
                            ticketWorkflow={this.state.WorkflowStore.ticketWorkflow}
                            card={this.state.WorkflowStore.active.card}
                            response={this.state.WorkflowStore.active.response}
                            reply={this.state.TicketStore.reply}
                            client={this.state.ClientStore.working}
                            user={this.state.UserStore.me}
                            permissions={this.state.UserStore.permissions}
                            status={this.state.TicketStore.status || []}
                            clientOptions={this.state.TicketStore.workflowClients}
                            clientSelected={this.state.TicketStore.clientSelected}
                            mode={this.state.TicketStore.mode}
                            direction={this.state.WorkflowStore.direction || "forward"}
                        />
                    );
                }
                break;
            case "ticketlessworkflows":
                toRender.push(
                    <AppBar
                        key={this.state.TicketStore.working === undefined ? "app-bar" : "app-bar-" + this.state.TicketStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={"Ticketless Workflows"}
                        picture={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.picture : ""}
                        dynamic={false}
                        actions={TicketActions}
                        mode={this.state.TicketStore.mode}
                        changeMode={TicketActions.changeMode}
                        showAbout={true}
                        clientLoaded={this.state.ClientStore.working !== undefined}
                    />
                );
                if (this.state.TicketStore.mode === "play") {
                    toRender.push(
                        <TicketlessWorkflow
                            key={
                                this.state.TicketStore.working === undefined
                                    ? "ticketless-workflow-guru-view"
                                    : "ticketless-workflow-guru-view-" + this.state.TicketStore.working.id
                            }
                            ticket={this.state.TicketStore.working}
                            workflow={this.state.WorkflowStore.working}
                            ticketWorkflow={this.state.WorkflowStore.ticketWorkflow}
                            card={this.state.WorkflowStore.active.card}
                            response={this.state.WorkflowStore.active.response}
                            client={this.state.ClientStore.working}
                            user={this.state.UserStore.me}
                            permissions={this.state.UserStore.permissions}
                            status={this.state.TicketStore.status || []}
                            clientOptions={this.state.TicketStore.workflowClients}
                            mode={this.state.TicketStore.mode}
                            direction={this.state.WorkflowStore.direction || "forward"}
                        />
                    );
                }
                break;
            case "tickettriage":
                toRender.push(
                    <AppBar
                        key={this.state.TicketStore.working === undefined ? "app-bar" : "app-bar-" + this.state.TicketStore.working.id}
                        UserStore={this.state.UserStore.me}
                        text={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.name : "Tickets"}
                        picture={this.state.ClientStore.working !== undefined ? this.state.ClientStore.working.picture : ""}
                        queueCount={
                            this.state.ClientStore.working !== undefined
                                ? this.state.ClientStore.working.queueCount - this.state.ClientStore.working.workflowQueueCount
                                : ""
                        }
                        dynamic={false}
                        actions={TicketActions}
                        mode={this.state.TicketStore.mode}
                        changeMode={TicketActions.changeMode}
                        showAbout={true}
                        clientLoaded={this.state.ClientStore.working !== undefined}
                    />
                );
                if (this.state.TicketStore.mode === "play") {
                    toRender.push(
                        <Ticket
                            key={
                                this.state.TicketStore.working === undefined
                                    ? "ticket-manager-view"
                                    : "ticket-manager-view-" + this.state.TicketStore.working.id
                            }
                            ticket={this.state.TicketStore.working}
                            reply={this.state.TicketStore.reply}
                            client={this.state.ClientStore.working}
                            user={this.state.UserStore.me}
                            permissions={this.state.UserStore.permissions}
                            status={this.state.TicketStore.status}
                            clientOptions={this.state.TicketStore.triageClients}
                            clientSelected={this.state.TicketStore.clientSelected}
                            mode={this.state.TicketStore.mode}
                        />
                    );
                }
                break;
            default:
                toRender.push(<Loading key="loading-view" />);
                break;
        }

        // Handles messages
        //toRender.push(<Messages key="messages" items={this.state.UIStore.messages} />);
        var alerts = [];

        if (this.state.UIStore.messages.length > 0) alerts.push(<Snackbar key="snackbar" item={this.state.UIStore.messages[0]} />);

        // Handles events notifications
        if (this.state.UIStore.feed.updates !== undefined && this.state.UIStore.feed.updates.length > 0) {
            toRender.push(
                <Messages key="messages" items={this.state.UIStore.feed.updates} clients={this.state.ClientStore.summary || []} />
            );
        }

        return (
            <div style={primaryStyle}>
                {toRender}
                <ReactCSSTransitionGroup
                    key="overlay-transition-group"
                    transitionName="overlay"
                    transitionAppear={false}
                    transitionLeave={true}
                    transitionEnter={true}
                    transitionEnterTimeout={50}
                    transitionLeaveTimeout={100}
                >
                    <OverlayLayer
                        ClientStore={this.state.ClientStore}
                        UserStore={this.state.UserStore}
                        UIStore={this.state.UIStore}
                        AgentStore={this.state.AgentStore}
                        WorkflowStore={this.state.WorkflowStore}
                        MacroStore={this.state.MacroStore}
                        TicketStore={this.state.TicketStore}
                    />
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    key="alerts-transition-group"
                    transitionName="alerts"
                    transitionAppear={false}
                    transitionLeave={true}
                    transitionEnter={true}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={100}
                >
                    {alerts}
                </ReactCSSTransitionGroup>
            </div>
        );
    }
}

export default App;
