import React from "react";

import Row from "../_common/Row.react";
import Viewer from "../_common/Viewer.react";

// Functions
import * as time from "../../functions/time.js";

const viewerStyle = {
	width: "60px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px"
};

const cellSmallStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class TicketEventRow extends React.Component {
	constructor(props) {
		super(props);
		this._onToggleDetails = this._onToggleDetails.bind(this);
		this.state = { open: false };
	}

	_onToggleDetails() {
		this.setState({ open: this.state.open ? false : true });
	}

	render() {
		return (
			<Row
				index={this.props.index}
				style={rowStyle}
				href={
					this.props.showUrl
						? "#clients/" + this.props.item.clientId + "/tickets/" + this.props.item.ticketId
						: undefined
				}
				newTab={false}
			>
				<div style={viewerStyle}>
					<Viewer item={this.props.badge} />
				</div>
				<div style={cellSmallStyle}>{this.props.item.rating || "NA"}</div>
				<div style={cellMediumStyle}>{time.parse_time(this.props.item.date)}</div>
			</Row>
		);
	}
}

export default TicketEventRow;
