import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Search from "../_common/Search.react";
import HeaderRow from "../_common/HeaderRow.react";
import Cell from "../_common/Cell.react";

// Rows
import TicketRow from "./TicketRow.react";

// functions
import * as zendesk from "../../functions/zendesk";

const searchStyle = {
	width: "calc(100% - 32px)"
};

function sql_to_firebase_tickets(tickets) {
	for (var i = 0; i < tickets.length; i++) {
		tickets[i].id = tickets[i].ticketId;
		tickets[i].active_agent = tickets[i].claimed;
		tickets[i].external_status = zendesk.find_status_reverse(tickets[i].status);
		tickets[i].external_priority = zendesk.find_priority_reverse(tickets[i].priority);
		tickets[i].external_updated = tickets[i].updated;
		tickets[i].external_created = tickets[i].created;
		tickets[i].workflowsStatus = "";
		if (tickets[i].has_workflow === 1) tickets[i].workflowsStatus = "queued";
	}

	return tickets;
}

class ClientTickets extends React.Component {
	constructor(props) {
		super(props);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this._onSearchResults = this._onSearchResults.bind(this);
		this.state = { tickets: [], query: "" };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNextPage() {
		ClientActions.loadSubcollection(this.props.view.replace("clients_", ""), 1);
		window.scrollTo(0, 0);
	}

	_onPreviousPage() {
		ClientActions.loadSubcollection(this.props.view.replace("clients_", ""), -1);
		window.scrollTo(0, 0);
	}

	_onSearchResults(tickets) {
		console.log("got tckets");
		console.log(tickets);
		this.setState({ tickets: sql_to_firebase_tickets(tickets) });
	}

	render() {
		const integrationsStyle = {
			width: "100%",
			textAlign: "left",
			margin: "24px 0px",
			display: "inline-block"
		};

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container width="1200px" maxWidth="1200px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					{this.props.client === undefined ? (
						<div />
					) : (
						<SectionCard
							title={this.props.title || "TICKETS"}
							subtext={"Showing " + this.state.tickets.length + " tickets."}
							maxWidth="calc(100% - 48px)"
							width="calc(100% - 48px)"
						>
							<Search
								placeholder="Search..."
								id={"ticket"}
								type="tickets"
								hideResults={true}
								fields={["external_id", "requester_email", "title", "description"]}
								searchResults={this._onSearchResults}
								where={[{ field: "clientId", action: "=", value: '"' + this.props.client.id + '"' }]}
								min={1}
								style={searchStyle}
							/>

							{this.state.tickets === undefined || this.state.tickets.length === 0 ? (
								""
							) : (
								<div style={integrationsStyle}>
									<HeaderRow>
										<Cell size="xs" width="50px">
											SLA
										</Cell>
										<Cell size="s" width="90px">
											External #
										</Cell>
										<Cell size="s" width="70px">
											Status
										</Cell>
										<Cell size="m">Requester</Cell>
										<Cell size="l">Subject</Cell>
										<Cell size="m">Last Updated</Cell>
									</HeaderRow>

									{this.state.tickets.map((item, index, arr) => (
										<TicketRow key={item.id + "-" + index} index={index} clientId={this.props.client.id} item={item} />
									))}
								</div>
							)}
						</SectionCard>
					)}
				</Container>
			</OuterScaffold>
		);
	}
}

export default ClientTickets;
