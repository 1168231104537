import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import TicketActions from "../../actions/TicketActions";

// Scaffolding
import Overlay from "./Overlay.react";
import Card from "./Card.react";
import Container from "./Container.react";

// Form items
import InputMultiline from "./forms/InputMultiline.react";
import Dropdown from "./forms/Dropdown.react";
import Button from "./buttons/Button.react";

// Headers
import H1 from "./headers/H1.react";
import UserActions from "../../actions/UserActions";

const cardStyle = {
    width: "calc(100% - 100px)",
    padding: "24px",
    textAlign: "center",
    maxWidth: "800px"
};

const buttonStyle = {
    marginLeft: "8px"
};

const buttonRowStyle = {
    marginTop: "16px",
    textAlign: "right"
};

const textStyle = {
    textAlign: "left"
};

const selectViewStyle = {
    width: "400px"
};

class NewNotificationOverlay extends React.Component {
    constructor(props) {
        super(props);
        this._onClose = this._onClose.bind(this);
        this._onSend = this._onSend.bind(this);

        this._onBlur = this._onBlur.bind(this);

        this.state = {
            text: "",
            recipient: ""
        };
    }

    _onClose() {
        UIActions.showOverlay("", {});
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
    }

    _onSend() {
        UserActions.createNotification(this.state.text, this.state.recipient);
        UIActions.showOverlay("", {});
    }

    render() {
        var viewOptions = [
            {
                text: "All",
                value: "all"
            },
            {
                text: "Group 1",
                value: "group 1"
            },
            {
                text: "Group 2",
                value: "group 2"
            },
            {
                text: "Group 3",
                value: "group 3"
            },
            {
                text: "Group 4",
                value: "group 4"
            },
            {
                divider: true
            }
        ];

        this.props.gurus.forEach(item => {
            viewOptions.push({
                text: item.name,
                value: item.id
            });
        });

        return (
            <Overlay>
                <Container maxWidth="1100px">
                    <Card style={cardStyle}>
                        <H1 text={"New Notification"} textAlign="center" />
                        <Dropdown
                            id="notification-recipient"
                            ref="notification-recipient"
                            updateFn={this._onBlur}
                            field="recipient"
                            items={viewOptions}
                            value={this.state.recipient}
                            label="Recipient"
                            searchable={true}
                        />
                        <InputMultiline
                            id={"response-box"}
                            field="text"
                            value={this.state.text}
                            label="Body"
                            class=""
                            noLine={false}
                            onBlur={this._onBlur}
                            style={textStyle}
                            pasteCorrect={true}
                        />
                        <div style={buttonRowStyle}>
                            <Button text="Close" style={buttonStyle} type="flat" onClick={this._onClose} />
                            <Button text="Send" style={buttonStyle} onClick={this._onSend} />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default NewNotificationOverlay;
