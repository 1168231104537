import React from "react";

import Card from "./Card.react";

const cardStyle = {
	marginBottom: "24px",
	width: "1000px",
	maxWidth: "100%"
};

const subjectStyle = {
	width: "calc(100% - 32px)",
	display: "inline-block",
	padding: "16px",
	fontFamily: "Roboto",
	fontWeight: "400",
	fontSize: "20px",
	cursor: "pointer"
};

const subjectTextStyle = {
	width: "calc(100% - 40px)",
	display: "inline-block",
	verticalAlign: "middle"
};

const toggleContainerStyle = {
	width: "40px",
	display: "inline-block",
	verticalAlign: "middle",
	textAlign: "right"
};

const toggleStyle = {
	display: "inline-block",
	color: "rgba(0, 0, 0, 0.54)",
	fontSize: "30px",
	cursor: "pointer"
};

const detailsContainerStyle = {
	width: "calc(100% - 32px)",
	padding: "0px 16px 16px 16px",
	display: "inline-block"
};

class CardCollapsable extends React.Component {
	constructor(props) {
		super(props);
		this._onToggleOpen = this._onToggleOpen.bind(this);
		this.state = { open: true };
	}

	_onToggleOpen() {
		this.setState({ open: this.state.open ? false : true });
	}

	render() {
		return (
			<Card style={cardStyle}>
				<div style={subjectStyle}>
					<div style={subjectTextStyle}>{this.props.title}</div>
					<div style={toggleContainerStyle}>
						<i className="material-icons" style={toggleStyle} onClick={this._onToggleOpen}>
							{this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
						</i>
					</div>
				</div>
				{this.state.open ? <div style={detailsContainerStyle}>{this.props.children}</div> : ""}
			</Card>
		);
	}
}

export default CardCollapsable;
