import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";
import Filter from "../_common/Filter.react";
import CardTag from "../_common/CardTag.react";

// Buttons
import Button from "../_common/buttons/Button.react";
import FAB from "../_common/buttons/FAB.react";

// Rows
import MacroRow from "./MacroRow.react";

import * as firestore from "../../functions/firestore";

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const buttonRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const buttonStyle = {
	margin: "0px 8px"
};

class ClientMacros extends React.Component {
	constructor(props) {
		super(props);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this._onFilter = this._onFilter.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNextPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			query.startAfter = this.props.cursor.pointers[this.props.cursor.page];
			ClientActions.loadSubcollection(this.props.cursor.type, query, 0, 1);
			window.scrollTo(0, 0);
		}
	}

	_onPreviousPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			if (this.props.cursor.page === 1) delete query.startAfter;
			else query.startAfter = this.props.cursor.pointers[this.props.cursor.page - 2];
			ClientActions.loadSubcollection(this.props.cursor.type, query, 0, -1);
			window.scrollTo(0, 0);
		}
	}

	_onFilter(view, type) {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			delete query.startAfter;
			if (query.orderBy[0][0] === type) query.orderBy[0][1] = query.orderBy[0][1] === "desc" ? "asc" : "desc";
			else query.orderBy[0] = [type, "asc"];

			ClientActions.loadSubcollection(this.props.cursor.type, query, 0);
			window.scrollTo(0, 0);
		}
	}

	_onNewMacro() {
		UIActions.showOverlay("new macro");
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					{this.props.macros === undefined ? (
						<div />
					) : (
						<SectionCard title="MACRO RESPONSES" subtext={"Edit preset email responses (macros)."}>
							{this.props.macros === undefined || this.props.macros.length === 0 ? (
								<div key="empty-workflows" style={emptyStyle}>
									<div style={emptyTextStyle}>There are currently no preset email responses</div>
									<Button text="Add Preset Email Response" onClick={this._onNewMacro} />
								</div>
							) : (
								<div style={integrationsStyle}>
									<HeaderRow>
										<Cell size="m">
											Name{" "}
											<Filter
												actions={ClientActions}
												type="title"
												view={this.props.cursor.type}
												onClick={this._onFilter}
											/>
										</Cell>
										<Cell size="s" center={true}>
											Active{" "}
											<Filter
												actions={ClientActions}
												type="active"
												view={this.props.cursor.type}
												onClick={this._onFilter}
											/>
										</Cell>
										<Cell size="m">
											Created{" "}
											<Filter
												actions={ClientActions}
												type="created"
												view={this.props.cursor.type}
												onClick={this._onFilter}
											/>
										</Cell>
										<Cell size="m">
											Last Updated{" "}
											<Filter
												actions={ClientActions}
												type="updated"
												view={this.props.cursor.type}
												onClick={this._onFilter}
											/>
										</Cell>
									</HeaderRow>
									{this.props.macros.map((item, index, arr) => (
										<MacroRow key={item.id} index={index} item={item} />
									))}
									<div style={buttonRow}>
										{this.props.cursor.page > 0 ? (
											<Button text={"Previous Page"} onClick={this._onPreviousPage} style={buttonStyle} />
										) : (
											""
										)}
										{this.props.macros.length === 25 ? (
											<Button text={"Next Page"} onClick={this._onNextPage} style={buttonStyle} />
										) : (
											""
										)}
									</div>
									<CardTag text={"Page " + ((this.props.cursor.page || 0) + 1)} color="orange" />
								</div>
							)}
						</SectionCard>
					)}
				</Container>
				<FAB onClick={this._onNewMacro} />
			</OuterScaffold>
		);
	}
}

export default ClientMacros;
