import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";
import Row from "../_common/Row.react";
import Filter from "../_common/Filter.react";
import NavHorizontal from "../_common/forms/NavHorizontal.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Functions
import * as list from "../../functions/list";

const aboutSections = [
	{
		text: "About",
		value: "about"
	},
	{
		text: "Workflows",
		value: "workflows"
	},
	{
		text: "Macros",
		value: "macros"
	},
	{
		text: "Links",
		value: "links"
	},
	{
		text: "FAQ",
		value: "faq"
	}
];

const cardStyle = {
	width: "100%",
	textAlign: "left",
	maxWidth: "1000px"
};

const pictureStyle = {
	height: "96px",
	width: "96px",
	display: "inline-block",
	verticalAlign: "middle"
};

const nameStyle = {
	fontSize: "86px", //"calc(86px - 50px*" + this.state.scroll + ")",
	color: "white", //this.state.scroll < 0.8 ? "white" : "rgba(0, 0, 0, 0.62)",
	fontWeight: "500",
	textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black", //this.state.scroll < 0.8 ? "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black" : "",
	zIndex: "3",
	backgroundColor: "transparent",
	border: "none",
	outline: "none",
	display: "inline-block",
	verticalAlign: "middle",
	marginLeft: "32px"
};

const sectionStyle = {
	width: "calc(100% - 64px)",
	padding: "16px 32px 32px",
	display: "inline-block",
	lineHeight: "24px"
};

const paragraghStyle = {
	margin: "8px 0px",
	fontSize: "16px",
	lineHeight: "24px",
	display: "inline-block",
	width: "100%"
};

const tableStyle = {
	width: "100%",
	fontSize: "14px",
	lineHeight: "24px",
	right: "-32px",
	position: "relative",
	display: "inline-block"
};

const navContainerStyle = {
	width: "calc(100% - 64px)",
	padding: "0px 32px",
	backgroundColor: "rgba(0, 0, 0, 0.07)",
	display: "inline-block",
	textAlign: "left",
	position: "relative"
};

const refreshButtonStyle = {
	position: "absolute",
	right: "16px",
	top: "8px"
};

const navStyle = {
	width: "auto",
	margin: "0px",
	fontSize: "20px"
};

const examplesSectionStyle = {
	width: "calc(100% - 48px)",
	padding: "8px 0px 0px 48px",
	display: "inline-block",
	textAlign: "left"
};

const linkStyle = {
	width: "100%",
	display: "inline-block",
	margin: "4px 0px",
	color: "rgb(70, 180, 175)"
};

const rowStyle = {
	borderBottom: "1px solid lightgrey",
	padding: "16px",
	position: "relative",
	display: "inline-block",
	width: "calc(100% - 32px)",
	cursor: "pointer"
};

const toggleOpenStyle = {
	position: "absolute",
	right: "16px",
	top: "16px",
	cursor: "pointer"
};

var find_component = (client, component) => {
	if (client !== undefined && client.components !== undefined) {
		for (var i = 0; i < client.components.length; i++) {
			if (client.components[i].id === component) return client.components[i];
		}
	}

	return null;
};

const linkURLStyle = {
	display: "inline-block",
	marginBottom: "8px",
	width: "100%",
	color: "rgb(70, 180, 175)"
};

const linkDescriptionStyle = {
	width: "100%",
	display: "inline-block"
};

const faqQuestionStyle = {
	marginBottom: "16px",
	fontWeight: "500",
	width: "100%",
	display: "inline-block",
	fontSize: "20px"
};

const numberStyle = {
	width: "34px",
	fontSize: "24px",
	padding: "0px 16px",
	display: "inline-block",
	verticalAlign: "top"
};

const innerStyle = {
	width: "calc(100% - 66px)",
	display: "inline-block"
};

const faqMacroStyle = {
	margin: "0px 16px 16px",
	border: "50%",
	backgroundColor: "orange",
	color: "white",
	padding: "4px 16px",
	borderRadius: "16px",
	display: "inline-block",
	fontSize: "14px"
};

const faqWorkflowStyle = {
	margin: "0px 16px 16px",
	border: "50%",
	backgroundColor: "cornflowerblue",
	color: "white",
	padding: "4px 16px",
	borderRadius: "16px",
	display: "inline-block",
	fontSize: "14px"
};

class SingleFAQ extends React.Component {
	render() {
		const linkRowStyle = {
			display: "inline-block",
			padding: "16px 32px 16px 0px",
			borderBottom: "1px solid lightgrey",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			width: "100%"
		};

		return (
			<div style={linkRowStyle}>
				<div style={numberStyle}>{this.props.index + 1}.</div>
				<div style={innerStyle}>
					<div
						style={faqQuestionStyle}
						dangerouslySetInnerHTML={{
							__html: this.props.item.question
						}}
					/>
					{[undefined, null, ""].indexOf(this.props.item.macro) === -1 ? (
						<div style={faqMacroStyle}>
							<b>Macro: </b>
							{this.props.item.macro}
						</div>
					) : (
						""
					)}
					{[undefined, null, ""].indexOf(this.props.item.workflow) === -1 ? (
						<div style={faqWorkflowStyle}>
							<b>Workflow: </b>
							{this.props.item.workflow}
						</div>
					) : (
						""
					)}
					<div
						style={linkDescriptionStyle}
						dangerouslySetInnerHTML={{
							__html: this.props.item.answer
						}}
					/>
				</div>
			</div>
		);
	}
}

class FAQSection extends React.Component {
	render() {
		var component = find_component(this.props.client, "documentation");
		const faq = component !== null ? component.faq || [] : [];

		return (
			<div style={sectionStyle}>
				<H1 text={this.props.client.name + " FAQ"} />
				{faq.map((item, index) => (
					<SingleFAQ item={item} key={"about-faq-" + index} clientId={this.props.client.id} index={index} />
				))}
			</div>
		);
	}
}

class SingleLink extends React.Component {
	render() {
		const linkRowStyle = {
			display: "inline-block",
			padding: "16px 32px 16px 0px",
			borderBottom: "1px solid lightgrey",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			width: "100%"
		};

		return (
			<div style={linkRowStyle}>
				<div style={numberStyle}>{this.props.index + 1}.</div>
				<div style={innerStyle}>
					<a style={linkURLStyle} href={this.props.item.url} target="_blank">
						{this.props.item.url}
					</a>
					<div
						style={linkDescriptionStyle}
						dangerouslySetInnerHTML={{
							__html: this.props.item.description
						}}
					/>
				</div>
			</div>
		);
	}
}

class LinksSection extends React.Component {
	render() {
		var component = find_component(this.props.client, "documentation");
		const links = component !== null ? component.links || [] : [];

		return (
			<div style={sectionStyle}>
				<H1 text={this.props.client.name + " Links"} />
				{links.map((item, index) => (
					<SingleLink item={item} key={"about-link-" + index} clientId={this.props.client.id} index={index} />
				))}
			</div>
		);
	}
}

class SingleWorkflow extends React.Component {
	constructor(props) {
		super(props);
		this._onToggle = this._onToggle.bind(this);
		this.state = { open: false };
	}

	_onToggle() {
		this.setState({ open: this.state.open ? false : true });
	}

	render() {
		return (
			<div style={rowStyle} onClick={this._onToggle}>
				<Cell size="m" width="200px" wrap={true} verticalAlign="top" textAlign="right" weight="500">
					{this.props.item.name || this.props.item.title}
				</Cell>
				<Cell size="m" width="100px" wrap={true} verticalAlign="top" textAlign="center">
					{this.props.item.frequency || 0}
				</Cell>
				<Cell width="calc(100% - 382px)" wrap={true} verticalAlign="top">
					{this.props.item.description}
					<i style={toggleOpenStyle} className="material-icons">
						{this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
					</i>
					{this.props.item.examples !== undefined && this.state.open ? (
						<div style={examplesSectionStyle}>
							{this.props.item.examples.map((item, index) => (
								<a
									key={"example-for-" + this.props.item.id + "-" + index}
									style={linkStyle}
									href={"#clients/" + this.props.clientId + "/tickets/" + item}
									target="_blank"
								>
									Example ticket: {item}
								</a>
							))}
						</div>
					) : (
						""
					)}
				</Cell>
			</div>
		);
	}
}

class AboutSection extends React.Component {
	render() {
		return (
			<div style={sectionStyle}>
				<H1 text={"About " + this.props.client.name} />
				<div
					style={paragraghStyle}
					dangerouslySetInnerHTML={{
						__html: this.props.client.description
					}}
				/>
			</div>
		);
	}
}

class WorkflowsSection extends React.Component {
	constructor(props) {
		super(props);
		this._onFilter = this._onFilter.bind(this);
		this.state = { filter: "name asc" };
	}

	_onFilter(view, value) {
		if (value === "name" && view === "workflow") {
			if (this.state.filter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		} else if (value === "frequency" && view === "workflow") {
			if (this.state.filter.indexOf(value + " biggest") > -1) value += " smallest";
			else value += " biggest";
		} else if (value === "title" && view === "macro") {
			if (this.state.filter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		}

		this.setState({ filter: value });
	}

	render() {
		const workflows =
			this.props.client.workflows !== undefined ? list.order(this.props.client.workflows, this.state.filter) : [];

		return (
			<div style={sectionStyle}>
				<H1 text={this.props.client.name + " Workflows"} />
				<div style={tableStyle}>
					<HeaderRow>
						<Cell width="200px" textAlign="center">
							Name
							<Filter onClick={this._onFilter} type="name" view="workflow" />
						</Cell>
						<Cell width="100px" textAlign="center">
							Frequency
							<Filter onClick={this._onFilter} type="frequency" view="workflow" />
						</Cell>
						<Cell width="calc(100% - 352px)" center={true}>
							Description
						</Cell>
					</HeaderRow>
					{workflows.map((item, index) => (
						<SingleWorkflow item={item} key={"about-workflow-" + index} clientId={this.props.client.id} />
					))}
				</div>
			</div>
		);
	}
}

class MacrosSection extends React.Component {
	constructor(props) {
		super(props);
		this._onFilter = this._onFilter.bind(this);
		this.state = { filter: "title asc" };
	}

	_onFilter(view, value) {
		if (value === "name" && view === "workflow") {
			if (this.state.filter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		} else if (value === "frequency" && view === "workflow") {
			if (this.state.filter.indexOf(value + " biggest") > -1) value += " smallest";
			else value += " biggest";
		} else if (value === "title" && view === "macro") {
			if (this.state.filter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		}

		this.setState({ filter: value });
	}

	render() {
		const macros =
			this.props.client.macros !== undefined ? list.order(this.props.client.macros, this.state.filter) : [];

		return (
			<div style={sectionStyle}>
				<H1 text={this.props.client.name + " Macros"} />
				<div style={tableStyle}>
					<HeaderRow>
						<Cell width="200px" textAlign="center">
							Name
							<Filter onClick={this._onFilter} type="title" view="macro" />
						</Cell>
						<Cell width="100px" textAlign="center">
							Frequency
						</Cell>
						<Cell width="calc(100% - 352px)" center={true}>
							Description
						</Cell>
					</HeaderRow>
					{macros.map((item, index) => (
						<SingleWorkflow item={item} key={"about-macros-" + index} clientId={this.props.client.id} />
					))}
				</div>
			</div>
		);
	}
}

class AboutClient extends React.Component {
	constructor(props) {
		super(props);

		this._onChangeView = this._onChangeView.bind(this);

		this.state = { workflowFilter: "name asc", macroFilter: "title asc", view: "about" };
	}
	_onClose() {
		UIActions.showOverlay("");
	}

	_onRefreshExamples() {
		ClientActions.refreshExamples();
	}

	_onChangeView(filed, location, view) {
		console.log(view);
		this.setState({ view: view });
	}

	_onFilter(view, value) {
		if (value === "name" && view === "workflow") {
			if (this.state.workflowFilter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		} else if (value === "frequency" && view === "workflow") {
			if (this.state.workflowFilter.indexOf(value + " biggest") > -1) value += " smallest";
			else value += " biggest";
		} else if (value === "title" && view === "macro") {
			if (this.state.macroFilter.indexOf(value + " asc") > -1) value += " desc";
			else value += " asc";
		}

		this.setState({ [view + "Filter"]: value });
	}

	render() {
		var coverpicture =
			this.props.client === undefined || this.props.client.cover === undefined || this.props.client.cover === ""
				? "/images/default_cover.jpeg"
				: this.props.client.cover;

		const coverStyle = {
			width: "100%",
			backgroundImage: "url(" + coverpicture + ")",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			textAlign: "center",
			padding: "200px 0px",
			display: "inline-block"
		};

		var toRender = [];
		if (this.state.view === "about") toRender = <AboutSection client={this.props.client} />;
		else if (this.state.view === "workflows") toRender = <WorkflowsSection client={this.props.client} />;
		else if (this.state.view === "macros") toRender = <MacrosSection client={this.props.client} />;
		else if (this.state.view === "links") toRender = <LinksSection client={this.props.client} />;
		else if (this.state.view === "faq") toRender = <FAQSection client={this.props.client} />;

		return (
			<Overlay>
				<Container maxWidth="1000px" key="new-workflow-container" marginBottom="0px" verticalAlign="top">
					<Card style={cardStyle}>
						<div style={coverStyle}>
							{this.props.client.picture !== undefined ? (
								<img
									style={pictureStyle}
									src={this.props.client.picture || ""}
									alt={this.props.client.name.substr(0, 1)}
								/>
							) : (
								<div>{this.props.client.name.substr(0, 1).toUpperCase()}</div>
							)}
							<div style={nameStyle}>{this.props.client.name}</div>
						</div>
						<div style={navContainerStyle}>
							<NavHorizontal
								id="about-nav"
								items={aboutSections}
								value={this.state.view}
								updateFn={this._onChangeView}
								style={navStyle}
								itemPadding="16px 16px"
							/>
							{["admin", "manager"].indexOf(this.props.user.role) > -1 ? (
								<Button
									text="Refresh Examples"
									onClick={this._onRefreshExamples}
									type="flat"
									style={refreshButtonStyle}
								/>
							) : (
								""
							)}
						</div>
						{toRender}
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default AboutClient;
