import React from "react";

// Functions
import m from "../../../functions/m";

class Pill extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
	}

	_onClose() {
		this.props.onClose(this.props.field, this.props.location, this.props.value);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			borderRadius: "32px",
			fontSize: "12px",
			padding: "8px 12px",
			margin: "3px",
			color: "rgba(0, 0, 0, 0.87)",
			backgroundColor: "rgba(0, 0, 0, 0.12)"
		};

		const closeIconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "14px",
			marginLeft: "8px",
			cursor: "pointer",
			color: "inherit"
		};

		return (
			<div style={m(primaryStyle, this)} onClick={this.props.onClick || null}>
				{this.props.text}
				{this.props.onClose !== undefined ? (
					<i style={closeIconStyle} onClick={this._onClose} className="material-icons">
						close
					</i>
				) : (
					""
				)}
			</div>
		);
	}
}

export default Pill;
