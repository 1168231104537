import React from "react";

// Actions
import AgentActions from "../../../../actions/AgentActions";
import UIActions from "../../../../actions/UIActions";

// Scaffolding
import Card from "../../Card.react";
import Container from "../../Container.react";
import Overlay from "../../Overlay.react";

// Form items
import Input from "../../forms/Input.react";
import Button from "../../buttons/Button.react";

// Headers
import H1 from "../../headers/H1.react";

// Functions
import validate from "../../../../functions/validate";

// Sets validation
const nameValidation = [
	{
		type: "min-length",
		value: 4
	}
];

const emailValidation = [
	{
		type: "contains",
		value: "@hioperator.com"
	}
];

class NewAgent extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { email: "", name: "", validate: false };
	}

	componentDidMount() {
		document.getElementById("email").focus();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ loading: nextProps.status.indexOf("creating agent") > -1 });
	}

	_onClick() {
		var emailError = validate(emailValidation, this.state.email);
		var nameError = validate(nameValidation, this.state.name);

		if (emailError === "" && nameError === "") {
			this.setState({ validate: false, loading: true });
			AgentActions.new({ email: this.state.email, name: this.state.name, active: true, loggedIn: false });
		} else this.setState({ validate: true });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onEmailReturn() {
		document.getElementById("name").focus();
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text="Add new guru" textAlign="center" />
						<Input
							id="email"
							label="Hioperator email"
							field="email"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={emailValidation}
							onReturn={this._onEmailReturn}
							errors={this.props.errors}
						/>
						<Input
							id="name"
							label="Name"
							field="name"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={nameValidation}
							onReturn={this._onClick}
							errors={this.props.errors}
						/>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="NEXT" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default NewAgent;
