import React from "react";

class SummaryRow extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	render() {
		const color = this.props.color || "rgb(255, 255, 255)";
		const primaryStyle = {
			borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
			padding: "16px",
			borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
			transition: "background-color .3s ease",
			cursor: "pointer",
			display: "inline-block",
			width: "calc(100% - 32px)",
			boxShadow:
				"rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
			textAlign: "left",
			textDecoration: "none",
			color: "rgba(0, 0, 0, 0.87)"
		};

		return (
			<a
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				style={primaryStyle}
				href={this.props.href || undefined}
				target={this.props.newTab ? "_blank" : ""}
			>
				{this.props.children}
			</a>
		);
	}
}

export default SummaryRow;
