import React from "react";
import UIActions from "../../actions/UIActions";

import Button from "./buttons/Button.react";

class Snackbar extends React.Component {
	constructor(props) {
		super(props);
		this._onCloseMessage = this._onCloseMessage.bind(this);
	}

	componentDidMount() {
		if (this.props.item.type === "success") setTimeout(this._onCloseMessage, 5000);
	}

	_onCloseMessage() {
		UIActions.removeMessage(this.props.item.id);
	}

	render() {
		const primaryStyle = {
			position: "fixed",
			margin: "24px",
			bottom: "0px",
			left: "0px",
			textAlign: "left",
			zIndex: "4"
		};

		const containerStyle = {
			maxWidth: "640px",
			backgroundColor: "rgba(0, 0, 0, 0.87)",
			color: "white",
			display: "inline-block",
			borderRadius: "4px",
			boxShadow: "0 1px 3px 0 rgba(60,64,67,0.302), 0 4px 8px 3px rgba(60,64,67,0.149)"
		};

		const messageStyle = {
			padding: "16px 24px",
			fontSize: "14px",
			fontWeight: "400px",
			display: "inline-block",
			verticalAlign: "middle"
		};

		const flatButtonStyle = {
			color: this.props.item.type === "error" ? "indianred" : "#8ab4f8",
			verticalAlign: "middle"
		};

		return (
			<div style={primaryStyle}>
				<div style={containerStyle}>
					<div style={messageStyle}>{this.props.item.text}</div>
					{this.props.item.type !== "sticky" ? (
						<Button text="Close" style={flatButtonStyle} onClick={this._onCloseMessage} type="flat" />
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

export default Snackbar;
