import React from "react";

// Actions
import AgentActions from "../../actions/AgentActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import CardTag from "../_common/CardTag.react";
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";

// Form items
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";

// Functions
import * as list from "../../functions/list";
import * as analytics from "../../functions/analytics";
import * as time from "../../functions/time.js";
import * as state from "../../functions/state.js";
import validate from "../../functions/validate";

const emailValidation = [
    {
        type: "contains",
        value: "@hioperator.com"
    }
];

const phoneValidation = [
    {
        type: "contains",
        value: "+"
    },
    {
        type: "length",
        value: 12
    }
];

const stateFields = ["email", "address", "role", "phoneNumber"];

class AgentDetails extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onRoleChange = this._onRoleChange.bind(this);
        this._onPhoneChange = this._onPhoneChange.bind(this);
        this._onOrder = this._onOrder.bind(this);
        this._onDateRangeUpdated = this._onDateRangeUpdated.bind(this);

        var to = new Date();
        var from = new Date();
        from.setUTCDate(from.getUTCDate() - 1);

        var toMonth = to.getUTCMonth() + 1 > 9 ? to.getUTCMonth() + 1 : "0" + (to.getUTCMonth() + 1);
        var fromMonth = from.getUTCMonth() + 1 > 9 ? from.getUTCMonth() + 1 : "0" + (from.getUTCMonth() + 1);

        var toDay = to.getUTCDate() > 9 ? to.getUTCDate() : "0" + to.getUTCDate();
        var fromDay = from.getUTCDate() > 9 ? from.getUTCDate() : "0" + from.getUTCDate();

        to = to.getUTCFullYear() + "-" + toMonth + "-" + toDay;
        from = from.getUTCFullYear() + "-" + fromMonth + "-" + fromDay;

        var myState = {
            scroll: 0,
            eventsOrder: "start newest",
            events: list.order(this.props.item.events, "start newest"),
            analytics: {
                to: to,
                from: from,
                data: analytics.agents(this.props.item.analytics || [], to, from)
            }
        };

        myState = state.set(myState, stateFields, this.props.item);
        this.state = myState;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps) {
        var temp = this.state.analytics;
        temp.data = analytics.agents(nextProps.item.analytics || [], temp.to, temp.from);

        var myState = { events: list.order(nextProps.item.events, this.state.eventsOrder), analytics: temp };
        myState = state.update(myState, stateFields, this.props.item, nextProps.item, this.state);
        this.setState(myState);
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value, validate: false });
    }

    _onRoleChange(field, location, value) {
        this.setState({ [field]: value, validate: false });
        AgentActions.roleChange(field, location, value);
    }

    _onPhoneChange(field, location, value) {
        var verificationError = validate(phoneValidation, value);

        if (verificationError === "") {
            this.setState({ [field]: value, validate: false });
            AgentActions.phoneChange(field, location, value);
        } else this.setState({ validate: true });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value, validate: false });
        AgentActions.setField(field, location, value, true);
    }

    _onMoreSessions() {
        UIActions.show("gurus sessions", document.location.hash.replace("#", "") + "/sessions");
    }

    _onMoreEvents() {
        UIActions.show("gurus events", document.location.hash.replace("#", "") + "/events");
    }

    _onOrder(field, location, value) {
        var property;
        var data = this.state[field];

        this.setState({ [field + "Order"]: value, [field]: list.order(data, value, property) });
    }

    _onDateRangeUpdated(field, location, value) {
        if (field === "to")
            this.setState({
                analytics: {
                    to: value,
                    from: this.state.analytics.from,
                    data: analytics.agents(this.props.item.analytics || [], value, this.state.analytics.from)
                }
            });
        else
            this.setState({
                analytics: {
                    to: this.state.analytics.to,
                    from: value,
                    data: analytics.agents(this.props.item.analytics || [], this.state.analytics.to, value)
                }
            });
    }

    render() {
        var clients = [];
        if (this.props.clients !== undefined && this.props.clients.length > 0) {
            for (var i = 0; i < this.props.clients.length; i++) {
                clients.push({
                    id: this.props.clients[i].id,
                    name: this.props.clients[i].name,
                    picture: this.props.clients[i].picture
                });
            }
        }

        const inputStyle = {
            maxWidth: "400px",
            marginRight: "calc(100% - 500px)"
        };

        return (
            <OuterScaffold spaceForHeader={false} verticalAlign={false}>
                <Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
                    <SectionCard title="ABOUT" subtext={"Details about " + this.props.item.name}>
                        <Input
                            id="email"
                            label="Email"
                            field="email"
                            updateFn={this._updateFn}
                            onBlur={this._onBlur}
                            value={this.state.email}
                            style={inputStyle}
                            validation={emailValidation}
                            errors={this.props.errors}
                        />
                        <Input
                            id="twofactor"
                            label="Phone Number for Two Factor"
                            field="phoneNumber"
                            updateFn={this._updateFn}
                            onBlur={this._onPhoneChange}
                            value={this.state.phoneNumber}
                            style={inputStyle}
                            validation={[phoneValidation]}
                            helpText="E.g., +15105551234"
                            errors={this.props.errors}
                        />
                        <InputMultiline
                            id="address"
                            label="Address"
                            field="address"
                            onBlur={this._onBlur}
                            value={this.state.address}
                            style={inputStyle}
                        />
                        <CardTag
                            text={"A guru since " + time.parse_time(this.props.item.created, true).toLocaleDateString()}
                            color="orange"
                        />
                    </SectionCard>
                </Container>
            </OuterScaffold>
        );
    }
}

export default AgentDetails;
