export default {
	name: "Spree",
	type: "spree",
	category: "Store Fronts",
	icon: "shopping_cart",
	apiFields: [
		{
			field: "apiKey",
			label: "Api Key",
			type: "text"
		},
		{
			field: "url",
			label: "API Url",
			type: "text",
			helpText: "E.g., https://example.com/shop/api"
		}
	],
	cardFields: [
		{
			field: "action",
			label: "Spree Action",
			type: "dropdown",
			items: [
				{
					text: "Find orders",
					value: "find orders"
				},
				{
					text: "Get order",
					value: "get order"
				},
				{
					text: "Get products",
					value: "get products"
				},
				{
					text: "Update record",
					value: "update record"
				}
			]
		},
		{
			field: "fieldType",
			label: "Field to use",
			type: "dropdown",
			items: [
				{
					text: "Order number",
					value: "number"
				},
				{
					text: "Email",
					value: "email"
				}
			],
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "find orders"
						}
					]
				}
			]
		},
		{
			field: "recordType",
			label: "Type of Record",
			type: "dropdown",
			items: [
				{
					text: "Address",
					value: "address"
				}
			],
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "update record"
						}
					]
				}
			]
		},
		{
			field: "value",
			label: "Value",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "find orders"
						}
					]
				}
			]
		},
		{
			field: "value",
			label: "Order Number",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "get order"
						}
					]
				}
			]
		},
		{
			field: "recordId",
			label: "Record ID",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "update record"
						}
					]
				}
			]
		},
		{
			field: "value",
			label: "Update",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "update record"
						}
					]
				}
			]
		},
		{
			field: "value",
			label: "Product IDs",
			type: "text",
			displaylogic: [
				{
					action: "appear",
					rules: [
						{
							if: "action",
							state: "is",
							value: "get products"
						}
					]
				}
			]
		}
	]
};
