import Reflux from "reflux";
import async from "async";

import ClientActions from "../actions/ClientActions";
import UserActions from "../actions/UserActions";
import UIActions from "../actions/UIActions";
import TicketActions from "../actions/TicketActions";
import AgentActions from "../actions/AgentActions";
import DocumentActions from "../actions/DocumentActions";
import TriageOptionsActions from "../actions/TriageOptionsActions";

import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import api from "../functions/api";
import * as status from "../functions/status";
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as firestore from "../functions/firestore";
import * as storage from "../functions/storage";
import * as list from "../functions/list";
import * as workflows from "../functions/workflows";
import * as viewing from "../functions/viewing";
import * as search from "../functions/search";
import * as permissions from "../functions/permissions";
import * as pages from "../functions/pages";

// Defines db for firestore
var db = firebase.firestore();

var Client = {
    summary: [],
    status: [],
    extraLoaded: [],
    allLoaded: false,
    loadedParts: [],
    summaryIds: [],
    summaryUpdate: [],
    workingUpdate: {}
};

const subcollections = [
    "queued",
    "escalations",
    "tickets",
    "csats",
    "events",
    "exports",
    "integrations",
    "workflows",
    "agents",
    "macros",
    "zendesk_users",
    "activeTickets",
    "components",
    "products",
    "documents",
    "flaggedTickets",
    "sandboxRatings"
];

const simpleSubcollections = ["agents", "components", "workflows", "macros", "products"];
//var subcollections = ["integrations", "tickets", "macros", "workflows", "zendesk_users", "agents", "analytics"];

// Manages integrations
function add_integration(obj) {
    if (Client.working.integrations === undefined) Client.working.integrations = [];

    obj.created = new Date();

    // Creates db record for integration
    const ref = db
        .collection("clients")
        .doc(Client.working.id)
        .collection("integrations")
        .doc();

    db.collection("clients")
        .doc(Client.working.id)
        .collection("integrations")
        .doc(ref.id)
        .set(obj);
    obj.id = ref.id;

    if (["zendesk", "front", "liveagent", "jira"].indexOf(obj.type) > -1) {
        db.collection("clients")
            .doc(Client.working.id)
            .collection("components")
            .doc(obj.type)
            .set({}, { merge: true });
    }

    Client.working.integrations.push(obj);
}

function check_integration(obj, force, notify) {
    var pass = force || false;

    if (obj.lastWorking === undefined) pass = true;
    else {
        if (obj.type === "zendesk") {
            if (obj.lastWorking.email.toLowerCase() !== obj.email.toLowerCase()) pass = true;
            if (obj.lastWorking.url.toLowerCase() !== obj.url.toLowerCase()) pass = true;
            if (obj.lastWorking.apiKey !== obj.apiKey) pass = true;
        }
    }

    if (pass) {
        const statusCode = "checking integration " + obj.id;
        const payload = { integration: obj };

        if (!status.has(Client, statusCode))
            api(
                "post",
                "/clients/" + Client.working.id + "/integrations/" + obj.id,
                payload,
                ClientActions.checkIntegrationCompleted,
                ClientActions.checkIntegrationFailed,
                Client,
                statusCode,
                obj,
                notify
            );
    } else if (obj.lastWorking !== undefined) {
        obj.valid = true;
    }
}

function remove_integration(obj, notify) {
    const statusCode = "removing integration " + obj.id;
    const payload = { integration: obj };

    if (["zendesk"].indexOf(obj.type) === -1 || obj.lastWorking === undefined) {
        db.collection("clients")
            .doc(Client.working.id)
            .collection("integrations")
            .doc(obj.id)
            .delete()
            .then(function() {
                ClientActions.update();
            });
    } else {
        if (!status.has(Client, statusCode))
            api(
                "delete",
                "/clients/" + Client.working.id + "/integrations/" + obj.id,
                payload,
                ClientActions.removeIntegrationCompleted,
                ClientActions.removeIntegrationFailed,
                Client,
                statusCode,
                obj,
                notify
            );
    }
}

function setup_live_updaters() {
    // Sets up monitoring
    Client.workingUpdate.root = { query: {} };

    Client.workingUpdate.root.updater = db
        .collection("clients")
        .doc(Client.working.id)
        .onSnapshot(function(snapshot) {
            var newObj = firestore.extract_single(snapshot);
            for (var i = 0; i < subcollections.length; i++) {
                newObj[subcollections[i]] = Client.working[subcollections[i]];
            }
            newObj.viewing = Client.working.viewing;
            newObj.customAnalytics = Client.working.customAnalytics;

            Client.working = newObj;
            update_page_badges();
            if (Client.allLoaded) ClientActions.update();
        });

    /*
	var localCollection = document.location.hash.indexOf("clients/") > -1 ? subcollections : simpleSubcollections;

	// Sets up subcollection monitoring
    for (var j = 0; j < localCollection.length; j++) setup_live_updates_subcollections(localCollection[j]);
    */
}

var setup_working_subcollection = (type, query, pageChange) => {
    console.log("UPDATING " + type);
    if (Client.workingUpdate[type] !== undefined && Client.workingUpdate[type].updater !== undefined) Client.workingUpdate[type].updater();

    console.log(query);
    var pointers = Client.workingUpdate[type] !== undefined && query.limit !== undefined ? Client.workingUpdate[type].pointers || [] : [];

    // Sets page;
    var page = Client.workingUpdate[type] !== undefined ? Client.workingUpdate[type].page || 0 : 0;

    // Sets cursor
    Client.workingUpdate[type] = { query, pointers, type, page };

    // Updates page
    Client.workingUpdate[type].page =
        pageChange !== undefined ? (Client.workingUpdate[type].page || 0) + pageChange : Client.workingUpdate[type].page || 0;

    var ref = firestore.query_builder(query);

    var i;
    Client.workingUpdate[type].updater = ref.onSnapshot(function(snapshot) {
        console.log("UPDATED now " + type);
        if (Client.working === undefined) {
            Client.workingUpdate[type].updater();
            return;
        }

        if (type === "activetickets") {
            parse_active_tickets(firestore.extract(snapshot));
        } else if (type === "agents") {
            var permissions = firestore.extract(snapshot);
            Client.working.agents = [];

            for (i = 0; i < permissions.length; i++) get_agent_for_permissions(permissions[i], permissions.length);
        } else {
            Client.working[type] = firestore.extract(snapshot);

            if (["workflows", "macros", "exports"].indexOf(type) === -1) {
                Client.working[type] = workflows.status_parse(Client.working[type]);
                update_viewing_tickets();
            }

            if (type === "integrations") Client.working[type] = list.order(Client.working[type], "created oldest");
            if (["workflows", "products"].indexOf(type) > -1) Client.working[type] = list.order(Client.working[type], "name asc");
            if (["macros"].indexOf(type) > -1) Client.working[type] = list.order(Client.working[type], "title asc");

            if (type === "components") {
                var documentation;
                for (i = 0; i < Client.working[type].length; i++) {
                    if (Client.working[type][i].id === "documentation") {
                        documentation = Client.working[type][i];
                        break;
                    }
                }

                snapshot.docChanges().forEach(function(change) {
                    if (["added", "modified"].indexOf(change.type) > -1 && change.doc.id === "documentation") {
                        if (documentation !== undefined) DocumentActions.setWorking(documentation);
                        else DocumentActions.new();
                    }
                });

                TicketActions.ticketCheck();
            }

            if (type === "flaggedTickets") ensure_users("flaggedTickets");
            if (type === "events") ensure_users("events");
            if (type === "sandboxRatings") ensure_users("sandboxRatings");
        }

        if (query.limit !== undefined) {
            Client.workingUpdate[type].pointers[Client.workingUpdate[type].page] = snapshot.docs[snapshot.docs.length - 1];
        }

        ClientActions.update();
    });
};

function ensure_users(field) {
    if (Client.working[field] !== undefined) {
        async.waterfall(
            [
                function(callback) {
                    viewing.ensure_users(Client.working[field], [], "userId", "agent", callback);
                }
            ],
            function(err, results) {
                Client.working[field] = results;
                ClientActions.update();
            }
        );
    }
}

var get_agent_for_permissions = (permission, total) => {
    AgentActions.getSingle(permission.userId, function(Agent) {
        if ([null, undefined, ""].indexOf(Agent) === -1 && Agent.id !== undefined) {
            Agent.permissions = permission;
            Client.working.agents.push(Agent);
        }

        if (Client.working.agents.length === total) {
            ClientActions.update();
        }
    });
};

function setup_live_updaters_summary(userPermissions) {
    UserActions.get(function(User) {
        var ref = db.collection("clients");
        var i;
        if (User.me.role === "admin" && Client.summaryUpdate.length === 0) {
            Client.summaryUpdate.push(
                ref.onSnapshot(function(snapshot) {
                    var docs = firestore.extract(snapshot);
                    for (i = 0; i < docs.length; i++) {
                        if (Client.summaryIds.indexOf(docs[i].id) === -1) {
                            Client.summaryIds.push(docs[i].id);
                        }
                    }

                    Client.summary = docs;
                    ClientActions.update();

                    if (Client.working === undefined || Client.working.agents === undefined) TicketActions.update();
                })
            );
        } else if (userPermissions !== undefined && Array.isArray(userPermissions)) {
            for (i = 0; i < userPermissions.length; i++) {
                if (Client.summaryIds.indexOf(userPermissions[i].clientId) === -1 && permissions.check(userPermissions[i], "clients"))
                    setup_client_summary_single(ref.doc(userPermissions[i].clientId), userPermissions[i]);
            }
        }
    });
}

var setup_client_summary_single = (ref, permission) => {
    var j;
    Client.summaryUpdate.push(
        ref.onSnapshot(snapshot => {
            var doc = firestore.extract_single(snapshot);

            if (Client.summaryIds.indexOf(doc.id) === -1) {
                Client.summaryIds.push(doc.id);
                Client.summary.push(doc);
            } else {
                for (j = 0; j < Client.summary.length; j++) {
                    if (Client.summary[j].id === doc.id) {
                        Client.summary[j] = doc;
                        break;
                    }
                }
            }

            if (Client.working === undefined || Client.working.agents === undefined) TicketActions.update();
        })
    );
};

function check_all_loaded(subcollection) {
    var localCollection = document.location.hash.indexOf("clients/") > -1 ? subcollections : simpleSubcollections;
    if (Client.loadedParts.indexOf(subcollection) === -1) Client.loadedParts.push(subcollection);
    if (Client.loadedParts.length === localCollection.length) Client.allLoaded = true;
}

function update_page_badges() {
    if (Client.working !== undefined) {
        pages.find("clients queued").badge = Client.working.queueCount;
        pages.find("clients escalations").badge = Client.working.escalateCount;
        pages.find("clients activetickets").badge = Client.working.activeTicketsCount;
        pages.find("clients csat").badge = Client.working.csatCheckCount;
    }
}

function update_viewing_tickets() {
    if (Client.working !== undefined && Client.working.activeTickets !== undefined) {
        var j, i;
        const ticketSubcollections = ["tickets", "queued", "escalations"];
        for (var k = 0; k < ticketSubcollections.length; k++) {
            if (Client.working[ticketSubcollections[k]] !== undefined) {
                for (j = 0; j < Client.working[ticketSubcollections[k]].length; j++) {
                    Client.working[ticketSubcollections[k]][j].viewing = [];
                }

                for (i = 0; i < Client.working.activeTickets.length; i++) {
                    for (j = 0; j < Client.working[ticketSubcollections[k]].length; j++) {
                        if (Client.working.activeTickets[i].id === Client.working[ticketSubcollections[k]][j].id) {
                            Client.working[ticketSubcollections[k]][j].viewing = Client.working.activeTickets[i].viewing;
                            break;
                        }
                    }
                }
            }
        }
    }
}

function parse_active_tickets(tickets) {
    var newTickets;

    async.waterfall(
        [
            function(callback) {
                viewing.update_by_group(tickets, "ticketId", callback);
            },
            function(results, callback) {
                var ids = [];
                for (var i = 0; i < results.length; i++) ids.push(results[i].ticketId);
                newTickets = results;

                load_active_tickets(ids, [], callback);
            },
            function(results, callback) {
                var j;
                for (var i = 0; i < results.length; i++) {
                    for (j = 0; j < newTickets.length; j++) {
                        if (newTickets[j].ticketId === results[i].id) results[i].viewing = newTickets[j].users;
                    }
                }

                Client.working.activeTickets = results;
                Client.working.activeTicketsCount = results.length;
                callback(null);
            }
        ],
        function(err) {
            update_page_badges();
            update_viewing_tickets();

            check_all_loaded("activeTickets");
            ClientActions.update();
        }
    );
}

function load_active_tickets(ids, results, master_callback) {
    async.map(
        ids,
        function(id, callback) {
            db.collection("clients")
                .doc(Client.working.id)
                .collection("tickets")
                .doc(id)
                .get()
                .then(function(snapshot) {
                    var ticket = firestore.extract_single(snapshot);
                    if (ticket !== undefined) callback(null, ticket);
                    else callback(null);
                })
                .catch(callback);
        },
        function(err, results) {
            var final = [];
            for (var i = 0; i < results.length; i++) {
                if (results[i] !== undefined) final.push(results[i]);
            }

            master_callback(err, final);
        }
    );

    /*
	if (ids.length > 0) {
		var single = ids.splice(0, 1)[0];
		db.collection("clients")
			.doc(Client.working.id)
			.collection("tickets")
			.doc(single)
			.get()
			.then(function(snapshot) {
				var ticket = firestore.extract_single(snapshot);
				if (ticket !== undefined) results.push(ticket);
				load_active_tickets(ids, results, master_callback);
			})
			.catch(master_callback);
    } else master_callback(null, results);
    */
}

var parse_analytics = data => {
    // Totals
    const totals = {
        created: data.totalCreated[0].Created || 0,
        solved: data.totalSolved[0].Solved || 0,
        submits: data.totalSubmits[0].Submits || 0,
        duration: (data.totalSolved[0].duration || 0) / (60 * 1000)
    };

    var temp, timeChart, timeDurationChart, i, j;

    if (data.type === "24h") {
        // Time Chart
        timeChart = [["Hour", "Created", "Submits", "Solved"]];
        timeDurationChart = [["Hour", "Average Duration"]];
        var timeArray = [];
        var now = new Date();
        now = now.setDate(now.getDate() - 1);
        now = new Date(now);

        for (i = 0; i < 25; i++) {
            timeChart.push([now.getHours() + ":00", 0, 0, 0]);
            timeDurationChart.push([now.getHours() + ":00", null]);
            timeArray.push(
                now.getFullYear() +
                    "-" +
                    (now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1) +
                    "-" +
                    (now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate()) +
                    " " +
                    (now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours())
            );

            now = now.setHours(now.getHours() + 1);
            now = new Date(now);
        }

        for (i = 0; i < data.createdByDate.length; i++) {
            timeChart[timeArray.indexOf(data.createdByDate[i].ForDate) + 1][1] = data.createdByDate[i].Created || 0;
        }

        for (i = 0; i < data.solvedByDate.length; i++) {
            timeChart[timeArray.indexOf(data.solvedByDate[i].ForDate) + 1][3] = data.solvedByDate[i].Solved || 0;
            timeDurationChart[timeArray.indexOf(data.solvedByDate[i].ForDate) + 1][1] = data.solvedByDate[i].duration / (60 * 1000);
        }

        for (i = 0; i < data.submitsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][2] = data.submitsByDate[i].Submits || 0;
        }
    } else {
        // Time Chart
        timeChart = [["Day", "Created", "Submits", "Solved"]];

        var day, simpleDay;
        for (i = 0; i < data.createdByDate.length; i++) {
            temp = [];
            day = data.createdByDate[i].ForDate;
            simpleDay = new Date(day);
            temp.push(simpleDay.toLocaleDateString());
            temp.push(data.createdByDate[i].Created || 0);

            if (data.submitsByDate.length === 0) temp.push(0);
            for (j = 0; j < data.submitsByDate.length; j++) {
                if (data.submitsByDate[j].ForDate === day) {
                    temp.push(data.submitsByDate[j].Submits || 0);
                    break;
                } else if (j === data.submitsByDate.length - 1) temp.push(0);
            }

            if (data.solvedByDate.length === 0) temp.push(0);
            for (j = 0; j < data.solvedByDate.length; j++) {
                if (data.solvedByDate[j].ForDate === day) {
                    temp.push(data.solvedByDate[j].Solved || 0);
                    break;
                } else if (j === data.solvedByDate.length - 1) temp.push(0);
            }

            timeChart.push(temp);
        }

        timeDurationChart = [["Day", "Average Duration"]];
        if (data.solvedByDate.length === 0) {
            for (i = 0; i < data.createdByDate.length; i++) {
                temp = [];
                day = data.createdByDate[i].ForDate;
                simpleDay = new Date(day);
                temp.push(simpleDay.toLocaleDateString());
                temp.push(0);

                timeDurationChart.push(temp);
            }
        } else {
            for (i = 0; i < data.solvedByDate.length; i++) {
                temp = [];
                day = data.solvedByDate[i].ForDate;
                simpleDay = new Date(day);
                temp.push(simpleDay.toLocaleDateString());
                temp.push((data.solvedByDate[i].duration || 0) / (60 * 1000));

                timeDurationChart.push(temp);
            }
        }
    }

    // HiOperator vesus not chart
    var hioperatorVersus = [["Who", "Tickets Solved"]];

    if (data.solvedByWho[0].HiOperator === 0) {
        hioperatorVersus.push(["Other", (data.solvedByWho[1] || {}).Solved || 0]);
        hioperatorVersus.push(["HiOperator", data.solvedByWho[0].Solved]);
    } else {
        hioperatorVersus.push(["Other", data.solvedByWho[0].Solved]);
        hioperatorVersus.push(["HiOperator", (data.solvedByWho[1] || {}).Solved || 0]);
    }

    // Agent Chart
    var orderedUsers = list.order(data.submitByUser, "Submits biggest");
    var submitByUser = [["Agent", "Tickets Submitted"]];
    for (i = 0; i < orderedUsers.length; i++) {
        submitByUser.push([orderedUsers[i].name, orderedUsers[i].Submits]);
    }

    orderedUsers = list.order(orderedUsers, "duration smallest");

    var timeByUser = [["Agent", "Average Duration"]];
    for (i = 0; i < orderedUsers.length; i++) {
        timeByUser.push([orderedUsers[i].name, orderedUsers[i].duration / (60 * 1000)]);
    }

    Client.working.customAnalytics = {
        totals,
        timeChart,
        timeDurationChart,
        hioperatorVersus,
        submitByUser,
        timeByUser
    };

    console.log(Client.working.customAnalytics);

    ClientActions.update();
};

function get_integration_id(type) {
    for (var i = 0; i < Client.working.integrationsList.length; i++) {
        if (Client.working.integrationsList[i].type === type) {
            return Client.working.integrationsList[i].integrationId;
        }
    }

    return null;
}

var run_integration_actions = (actions, integration, activate) => {
    console.log("GOT HERE!!!");
    for (var i = 0; i < actions.length; i++) {
        if (actions[i].type === "time-based automation" && activate) activate_time_based_automation(actions[i], integration);
        else if (actions[i].type === "time-based automation" && !activate) deactivate_time_based_automation(actions[i], integration);
    }
};

var activate_time_based_automation = (action, integration) => {
    var ref;
    async.waterfall(
        [
            function(callback) {
                db.collection("timedAutomations")
                    .where("clientId", "==", Client.working.id)
                    .where("integrationId", "==", integration.id)
                    .get()
                    .then(snapshot => callback(null, firestore.extract(snapshot)))
                    .catch(Client.error);
            },
            function(results, callback) {
                if (results.length === 0) {
                    ref = db.collection("timedAutomations").doc();
                    ref.set({
                        clientId: Client.working.id,
                        integrationId: integration.id,
                        type: integration.type,
                        action: action.value,
                        date: new Date(),
                        keep: true
                    }).then(() => callback(null));
                } else callback(new Error("Already exists"));
            },
            function(callback) {
                var temp = objects.clone(integration);
                temp.automationID = ref.id;
                delete temp.id;

                db.collection("clients")
                    .doc(Client.working.id)
                    .collection("integrations")
                    .doc(integration.id)
                    .set(temp)
                    .then(() => callback(null));
            }
        ],
        function(err) {
            UIActions.addMessage("Automation added", "success");
        }
    );
};

var deactivate_time_based_automation = (action, integration) => {
    if (integration.automationID !== undefined) {
        async.waterfall(
            [
                function(callback) {
                    db.collection("timedAutomations")
                        .doc(integration.automationID)
                        .delete()
                        .then(() => callback(null))
                        .catch(ClientActions.error);
                },
                function(callback) {
                    var temp = objects.clone(integration);
                    delete temp.automationID;
                    delete temp.id;

                    db.collection("clients")
                        .doc(Client.working.id)
                        .collection("integrations")
                        .doc(integration.id)
                        .set(temp)
                        .then(() => callback(null))
                        .catch(ClientActions.error);
                }
            ],
            function(err) {
                UIActions.addMessage("Automation removed", "success");
            }
        );
    }
};

class ClientStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { ClientStore: Client };
        this.listenToMany(ClientActions);
    }

    onLoadSummary(userPermissions) {
        setup_live_updaters_summary(userPermissions);
    }

    onLoadSummaryCompleted(result) {
        Client.summary = result;
        this.notify();
    }

    onLoadSummaryFailed(result) {
        this.notify();
    }

    onNew(data) {
        const statusCode = "creating client";
        api("post", "/clients/new", data, ClientActions.newCompleted, ClientActions.loadFailed, Client, statusCode);
    }

    onNewCompleted(data) {
        UIActions.showRouter("clients/" + data.id);
    }

    onLoad(id, blockView) {
        //run_clearout();
        //run_deescalate();

        console.log("LOADING CLIENT: " + id);
        if (Client.working === undefined || Client.working.id !== id) {
            if (Client.working !== undefined) ClientActions.unsubscribe();

            Client.loadedParts = [];
            Client.allLoaded = false;

            db.collection("clients")
                .doc(id)
                .get()
                .then(function(snapshot) {
                    ClientActions.loadCompleted(firestore.extract_single(snapshot), blockView);
                })
                .catch(ClientActions.loadFailed);
        } else if (!blockView) {
            UIActions.showOverlay("");
        }
    }

    onLoadCompleted(result, blockView) {
        Client.working = result;
        update_page_badges();

        if (!blockView) {
            UIActions.showOverlay("");
        }

        if (Client.workingUpdate.root === undefined) setup_live_updaters();
        this.notify();
    }

    onLoadFailed(result) {
        UIActions.addMessage(result, "error");
        this.notify();
    }

    onLoadSubcollection(type, query, attempt, pageChange) {
        if (Client.working === undefined && (isNaN(attempt) || attempt < 10)) {
            setTimeout(function() {
                attempt = attempt || 0;
                ClientActions.loadSubcollection(type, query, ++attempt);
            }, 100 * attempt);
        } else if (
            Client.working !== undefined &&
            (Client.workingUpdate[type] === undefined ||
                !firestore.query_compare(Client.workingUpdate[type].query, query) ||
                pageChange !== undefined)
        ) {
            setup_working_subcollection(type, query, pageChange);
        }
    }

    onSave() {
        if (document.location.hash.indexOf("documentation") > -1) {
            DocumentActions.save();
            return;
        }

        if (document.location.hash.indexOf("triageoptions") > -1) {
            TriageOptionsActions.save();
            return;
        }

        var now = new Date();
        Client.working.updated = new Date();

        var temp = objects.clone(Client.working);
        temp.updated = now;
        var i;

        // Makes integrations list
        if (temp.integrations !== undefined) {
            temp.integrationsList = [];
            for (i = 0; i < temp.integrations.length; i++) {
                temp.integrationsList.push({
                    integrationId: temp.integrations[i].id,
                    type: temp.integrations[i].type
                });
            }
        }

        delete temp.id;
        delete temp.integrations;
        delete temp.workflows;
        delete temp.tickets;
        delete temp.products;
        delete temp.documents;
        delete temp.zendesk_users;
        delete temp.macros;
        delete temp.agents;
        delete temp.viewing;
        delete temp.analytics;
        delete temp.activeTickets;
        delete temp.activeTicketsCount;
        delete temp.components;
        delete temp.customAnalytics;
        delete temp.events;
        delete temp.escalations;
        delete temp.queued;

        for (i = 0; i < subcollections; i++) delete temp[subcollections[i]];

        db.collection("clients")
            .doc(Client.working.id)
            .set(temp, { merge: false })
            .then(ClientActions.saveCompleted)
            .catch(ClientActions.saveFailed);

        if (Client.working.integrations !== undefined)
            for (i = 0; i < Client.working.integrations.length; i++) {
                temp = objects.clone(Client.working.integrations[i]);
                delete temp.id;
                db.collection("clients")
                    .doc(Client.working.id)
                    .collection("integrations")
                    .doc(Client.working.integrations[i].id)
                    .set(temp, { merge: true })
                    .catch(ClientActions.error);
            }

        if (Client.working.components !== undefined)
            for (i = 0; i < Client.working.components.length; i++) {
                temp = objects.clone(Client.working.components[i]);
                delete temp.id;
                db.collection("clients")
                    .doc(Client.working.id)
                    .collection("components")
                    .doc(Client.working.components[i].id)
                    .set(temp, { merge: true })
                    .catch(ClientActions.error);
            }

        search.update("clients", {
            id: Client.working.id,
            name: Client.working.name || "",
            picture: Client.working.picture || "",
            active: Client.working.active || false
        });
    }

    onSaveCompleted(result) {
        UIActions.addMessage("Client saved!", "success");
        this.notify();
    }

    onSaveFailed(result) {
        UIActions.addMessage("Couldn't save client :(.", "error");
        this.notify();
    }

    onDelete() {
        ClientActions.unsubscribe();

        const statusCode = "deleting client";
        api(
            "delete",
            "/clients/" + Client.working.id,
            null,
            ClientActions.deleteCompleted,
            ClientActions.deleteFailed,
            Client,
            statusCode,
            this.notify
        );
        UIActions.showRouter("clients");
    }

    onDeleteCompleted(result) {
        UIActions.addMessage("Client deleted!", "success");
        this.notify();
    }

    onDeleteFailed(result) {
        UIActions.addMessage("Couldn't delete client :(.", "error");
        this.notify();
    }

    onGetSingle(id, callback) {
        var i;
        for (i = 0; i < Client.summary.length; i++) {
            if (Client.summary[i].id === id) {
                callback(Client.summary[i]);
                return;
            }
        }

        for (i = 0; i < Client.extraLoaded.length; i++) {
            if (Client.extraLoaded[i].id === id) {
                callback(Client.extraLoaded[i]);
                return;
            }
        }

        db.collection("clients")
            .doc(id)
            .get()
            .then(snapshot => {
                const single = firestore.extract_single(snapshot);
                Client.extraLoaded.push(single);
                callback(single);
            })
            .catch(ClientActions.error);
    }

    onAdd(field, location, obj) {
        if (field === "integrations") add_integration(obj);
        else objects.add(Client.working, field, location, obj);
        this.notify();
    }

    onRemove(location) {
        objects.remove(Client.working, location.concat());
        this.notify();
    }

    onSetField(field, location, value, shouldNotify) {
        setField(Client.working, field, location, value);
        if (shouldNotify) this.notify();
    }

    onUpload(files, type) {
        storage.upload(files, "images", type, [], ClientActions.uploadCompleted);
    }

    onUploadCompleted(result, type) {
        if (result[0] !== undefined) Client.working[type] = result[0].url;

        this.notify();
    }

    onUploadFailed() {
        UIActions.addMessage("Couldn't upload image :(. Try again.", "error");
        this.notify();
    }

    onCheckIntegration(location, force) {
        var result = objects.find(Client.working, null, location.concat());
        if (result.obj !== undefined && Client.working.active) check_integration(result.obj, force, this.notify);
    }

    onCheckIntegrationCompleted(result, obj) {
        this.notify();
    }

    onCheckIntegrationFailed(result, obj) {
        if (obj !== undefined && obj !== null) {
            UIActions.addMessage(result.error, "error");
            obj.valid = false;
            delete obj.lastWorking;
        }

        this.notify();
    }

    onRemoveIntegration(location) {
        var result = objects.find(Client.working, null, location.concat());
        if (result.obj !== undefined) remove_integration(result.obj, this.notify);
    }

    onRemoveIntegrationCompleted(result, obj) {
        UIActions.addMessage("Removed integration successfully", "success");
        this.notify();
    }

    onRemoveIntegrationFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onToggleActive(field, location, value, shouldNotify) {
        Client.working.active = value;
        const statusCode = "toggling active for client";
        if (!status.has(Client, statusCode))
            api(
                "put",
                "/clients/" + Client.working.id + "/" + (value ? "activate" : "deactivate"),
                null,
                ClientActions.toggleActiveCompleted,
                ClientActions.toggleActiveFailed,
                Client,
                statusCode,
                this.notify
            );
    }

    onToggleActiveCompleted(result) {
        UIActions.addMessage("Client " + result.status, "success");
        this.notify();
    }

    onToggleActiveFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onRunIntegrationActions(actions, integration, activate) {
        run_integration_actions(actions, integration, activate);
    }

    onAddAgent(obj) {
        db.collection("permissions")
            .doc()
            .set({ clientId: Client.working.id, userId: obj.id })
            .then(function() {
                console.log("agent added!");
            });

        /*
		db.collection("users")
			.doc(obj.id)
			.update({ ["clients." + Client.working.id]: true })
			.then(function() {
				console.log("agent added!");
			})
			.catch(function(error) {
				console.log("error on adding agent");
				console.log(error);
			});

		db.collection("clients")
			.doc(Client.working.id)
            .update({ ["access." + obj.id]: true });
        */
    }

    onRemoveAgent(id) {
        db.collection("users")
            .doc(id)
            .update({ ["clients." + Client.working.id]: firebase.firestore.FieldValue.delete() })
            .then(function() {
                console.log("removing agent!");
            })
            .catch(function(error) {
                console.log("error on removing agent");
                console.log(error);
            });

        db.collection("clients")
            .doc(Client.working.id)
            .update({ ["access." + id]: firebase.firestore.FieldValue.delete() });
    }

    onTicketSourceSectionRefresh(location, type) {
        var result = objects.find(Client.working, null, location.concat());
        ClientActions.save();

        if (result.obj !== undefined && Client.working.active) {
            const statusCode = "refreshing ticket source field";
            const payload = { integration: result.obj };

            if (!status.has(Client, statusCode))
                api(
                    "post",
                    "/clients/" + Client.working.id + "/integrations/" + result.obj.id + "/refresh/" + type,
                    payload,
                    ClientActions.zendeskSectionRefreshCompleted,
                    ClientActions.zendeskSectionRefreshFailed,
                    Client,
                    statusCode,
                    this.notify
                );
        }
    }

    onTicketSourceSectionRefreshCompleted(result) {
        this.notify();
    }

    onTicketSourceSectionRefreshFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onGetFrontDownload(data, callback) {
        const statusCode = "downloading data from front";
        api(
            "post",
            "/clients/" + Client.working.id + "/integrations/" + get_integration_id("front") + "/download/",
            { type: "front", data },
            ClientActions.getFrontDownloadCompleted,
            ClientActions.error,
            Client,
            statusCode,
            callback,
            null
        );
    }

    onGetFrontDownloadCompleted(result, callback) {
        console.log("here's my result!");
        console.log(result);
        callback(result);
    }

    onRunAnalytics(message) {
        const statusCode = "running analytics";

        if (!status.has(Client, statusCode))
            api(
                "post",
                "/clients/" + Client.working.id + "/analytics",
                message,
                ClientActions.runAnalyticsCompleted,
                ClientActions.error,
                Client,
                statusCode
            );
    }

    onRunAnalyticsCompleted(results) {
        parse_analytics(results);
    }

    onRefreshExamples() {
        const statusCode = "refresh examples";

        var myWorkflows = [];
        for (var i = 0; i < Client.working.workflows.length; i++) {
            myWorkflows.push(Client.working.workflows[i].id);
        }

        UIActions.addMessage("Refreshing examples", "success");

        if (!status.has(Client, statusCode))
            api(
                "post",
                "/clients/" + Client.working.id + "/examples/",
                { workflows: myWorkflows },
                ClientActions.update,
                ClientActions.error,
                Client,
                statusCode
            );
    }

    onExportTickets(message) {
        const statusCode = "running export";

        //if (!status.has(Client, statusCode))
        api("post", "/clients/" + Client.working.id + "/export", message, ClientActions.update, ClientActions.error, Client, statusCode);
    }

    onSaveTags(tags) {
        const statusCode = "saving tags";
        api("post", "/clients/" + Client.working.id + "/tags", { tags }, ClientActions.update, ClientActions.error, Client, statusCode);
    }

    onGroupChange(field, location, value) {
        setField(Client.working, field, location, value);
        const statusCode = "changing group";
        if (!status.has(Client, statusCode))
            api(
                "put",
                "/clients/" + Client.working.id + "/group",
                { group: value },
                ClientActions.update,
                ClientActions.error,
                Client,
                statusCode,
                this.notify
            );
    }

    onUnsubscribe() {
        if (Client.workingUpdate === undefined) return;

        var myKeys = Object.keys(Client.workingUpdate);
        for (var i = 0; i < myKeys.length; i++) {
            Client.workingUpdate[myKeys[i]].updater();
            delete Client.workingUpdate[myKeys[i]];
        }

        Client.workingUpdate = {};
    }

    onClose() {
        ClientActions.unsubscribe();
        Client.loadedParts = [];
        Client.allLoaded = false;
        delete Client.working;
        this.notify();
    }

    onGet(callback) {
        callback(Client, this.notify);
    }

    onUpdate() {
        this.notify();
    }

    onError(error) {
        UIActions.addMessage(error, "error");
    }

    notify() {
        console.log("notify client");
        var working = Client.working !== undefined ? objects.clone(Client.working) : undefined;

        this.setState({
            ClientStore: {
                summary: Client.summary,
                status: Client.status,
                workingUpdate: Client.workingUpdate,
                working
            }
        });
    }
}

export default ClientStore;
