import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

// must manually import the stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Dropdown from "../_common/forms/Dropdown.react";

// Functions
import api from "../../functions/api";

const scheduleStyle = {
    width: "calc(100% - 32px)",
    padding: "32px 32px 0px 0px",
    display: "inline-block"
};

const addEventStyle = {
    width: "200px"
};

class AgentSchedule extends React.Component {
    constructor(props) {
        super(props);
        this._onGetSchedule = this._onGetSchedule.bind(this);
        this._onGetScheduleCompleted = this._onGetScheduleCompleted.bind(this);
        this._onDateClick = this._onDateClick.bind(this);
        this._onEventClick = this._onEventClick.bind(this);
        this._onAddEvent = this._onAddEvent.bind(this);
        this.calendarRef = React.createRef();

        this.state = { text: "", guru: "", opened: false, calendarEvents: [], calendarWeekends: true };
    }

    componentDidMount() {
        this._onGetSchedule();
    }

    _onGetSchedule() {
        api(
            "get",
            "/users/" + this.props.item.id + "/schedule",
            null,
            this._onGetScheduleCompleted,
            this._onGetScheduleCompleted,
            {},
            "getting schedule"
        );
    }

    _onGetScheduleCompleted(results) {
        var allEvents = results.scheduled.map(item => {
            item.type = "shift";
            return item;
        });

        var calendarEvents = results.scheduled.map(item => {
            return {
                id: item.id,
                title: "Shift",
                start: new Date(item.start),
                end: new Date(item.end),
                backgroundColor: "rgb(70, 180, 175)"
            };
        });

        allEvents = allEvents.concat(
            results.timesheets.map(item => {
                item.type = "worked";
                return item;
            })
        );

        var timesheets = results.timesheets.map(item => {
            return {
                id: item.id,
                title: "Worked",
                start: new Date(item.start),
                end: new Date(item.end),
                backgroundColor: "orange"
            };
        });

        calendarEvents = calendarEvents.concat(timesheets);

        this.setState({ calendarEvents, allEvents });
    }

    _onDateClick(arg) {
        var calendarRef = this.calendarRef.current.getApi();
        calendarRef.changeView("timeGridDay", arg.dateStr);
    }

    _onEventClick(arg) {
        arg = arg.event;

        if (this.props.UserStore.canEditSchedule || this.props.UserStore.role === "admin") {
            var event = this.state.allEvents.find(item => item.id === arg.id);
            UIActions.showOverlay("schedule edit", { event, refetch: this._onGetSchedule });
        } else {
            var calendarRef = this.calendarRef.current.getApi();
            var day = arg.start.getFullYear() + "-" + (arg.start.getMonth() + 1) + "-" + arg.start.getDate();
            calendarRef.changeView("timeGridDay", day);
        }
    }

    _onAddEvent(field, location, value) {
        var event = {
            userId: this.props.item.id,
            type: value
        };

        UIActions.showOverlay("schedule edit", { event, refetch: this._onGetSchedule });
    }

    render() {
        return (
            <OuterScaffold spaceForHeader={false} verticalAlign={false}>
                <Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
                    <SectionCard title="SCHEDULE" subtext={"Schedule for " + this.props.item.name}>
                        <Dropdown
                            id="create-schedule-event"
                            ref="create-schedule-event"
                            updateFn={this._onAddEvent}
                            items={[
                                { text: "Scheduled shift", value: "shift" },
                                { text: "Worked shift", value: "worked" }
                            ]}
                            text="Add Event"
                            style={addEventStyle}
                            button={true}
                        />
                        <div style={scheduleStyle}>
                            <FullCalendar
                                ref={this.calendarRef}
                                defaultView="dayGridMonth"
                                header={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                }}
                                firstDay={1}
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                weekends={this.state.calendarWeekends}
                                events={this.state.calendarEvents}
                                dateClick={this._onDateClick}
                                eventClick={this._onEventClick}
                            />
                        </div>
                    </SectionCard>
                </Container>
            </OuterScaffold>
        );
    }
}

export default AgentSchedule;
