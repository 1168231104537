import React from "react";
import Dropzone from "react-dropzone";
import Ink from "react-ink";
import MDSpinner from "react-md-spinner";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import Button from "../_common/buttons/Button.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Toggle from "../_common/forms/Toggle.react";

// Headers
import H1 from "../_common/headers/H1.react";
import H2 from "../_common/headers/H2.react";

import * as storage from "../../functions/storage";

// Styles
const leftColumnStyle = {
	width: "calc(50% - 17px)",
	padding: "16px 8px 16px 0px",
	display: "inline-block",
	verticalAlign: "top",
	borderRight: "1px solid lightgrey",
	textAlign: "center"
};

const rightColumnStyle = {
	width: "calc(50% - 16px)",
	padding: "16px 0px 16px 8px",
	display: "inline-block",
	verticalAlign: "top",
	borderLeft: "1px solid lightgrey",
	marginLeft: "-1px"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "left",
	maxWidth: "1100px"
};

const rowStyle = {
	width: "calc(100% + 16px)",
	textAlign: "right",
	margin: "0px -24px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const topRowStyle = {
	width: "calc(100% + 48px)",
	textAlign: "right",
	margin: "-24px -24px 0px -24px",
	borderBottom: "1px solid lightgrey",
	padding: "0px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const rulePrimaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "0px 0px",
	textAlign: "left"
};

const ruleSummaryPrimaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "16px 8px",
	textAlign: "center"
};

const typeStyle = {
	width: "200px"
};

const closeIconStyle = {
	display: "inline-block",
	verticalAlign: "top",
	cursor: "pointer",
	marginTop: "20px"
};

const ruleContainerStyle = {
	width: "calc(100% - 40px)",
	display: "inline-block",
	verticalAlign: "middle"
};

const emptyStyle = {
	width: "calc(100% - 48px - 32px)",
	textAlign: "center",
	padding: "24px",
	fontSize: "20px",
	margin: "16px",
	border: "1px dashed lightgrey"
};

const detailsContainerStyle = {
	display: "inline-block",
	paddingLeft: "36px",
	width: "calc(100% - 36px)"
};

const detailStyle = {
	marginBottom: "8px"
};

const addButtonStyle = {
	marginTop: "16px"
};

const typeOptions = [
	{
		text: "Video",
		value: "video"
	},
	{
		text: "Image",
		value: "image"
	},
	{
		text: "Link",
		value: "link"
	}
];

const uploadButton = {
	display: "inline-block",
	padding: "11px 16px",
	textAlign: "center",
	verticalAlign: "middle",
	cursor: "pointer",
	marginLeft: "16px",
	position: "relative"
};

const uploadIconStyle = {
	verticalAlign: "middle",
	marginRight: "8px",
	marginTop: "-4px"
};

const toggleLabelStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	padding: "16px"
};

class Resource extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onDeleteRule = this._onDeleteRule.bind(this);
		this._onDrop = this._onDrop.bind(this);
		this._onUploadCompleted = this._onUploadCompleted.bind(this);

		this.state = { uploading: false };
	}

	_updateFn(field, location, value) {
		this.props.actions.setField(field, this.props.address, value, true);
	}

	_onDeleteRule() {
		this.props.actions.remove(this.props.address);
	}

	_onDrop(accepted, rejected) {
		console.log(rejected);
		if (accepted.length > 0) {
			this.setState({ uploading: true });
			storage.upload(accepted, "resources", "", [], this._onUploadCompleted);
		} else {
			UIActions.addMessage(
				"Sorry, none of your files can be uploaded. Please ensure that none of the files are bigger 100MB.",
				"error"
			);
		}
	}

	_onUploadCompleted(result, type) {
		this.props.actions.setField("link", this.props.address, result[0].url, true);
		this.setState({ uploading: false });
	}

	render() {
		return (
			<div style={rulePrimaryStyle}>
				<div style={ruleContainerStyle}>
					<Dropdown
						label="Type"
						id={"resource-type-" + this.props.item.id}
						items={typeOptions}
						field="type"
						value={this.props.item.type}
						updateFn={this._updateFn}
						style={typeStyle}
					/>
					{["video", "image"].indexOf(this.props.item.type) > -1 ? (
						<Dropzone
							style={uploadButton}
							onDrop={this._onDrop}
							accept={this.props.item.type + "/*"}
							maxSize={100000000}
							multiple={false}
						>
							{this.state.uploading ? (
								<MDSpinner size={20} singleColor="rgb(70, 180, 175)" />
							) : (
								<div>
									<i style={uploadIconStyle} className="material-icons">
										cloud_upload
									</i>
									Upload
								</div>
							)}
							<Ink />
						</Dropzone>
					) : (
						""
					)}
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onDeleteRule}>
					close
				</i>
				{this.props.item.type !== "" ? (
					<div style={detailsContainerStyle}>
						<Input
							label="Link"
							id={"resource-link-" + this.props.item.id}
							field="link"
							value={this.props.item.link}
							onBlur={this._updateFn}
							style={detailStyle}
						/>
						<Input
							label="Name"
							id={"resource-name-" + this.props.item.id}
							field="name"
							value={this.props.item.name}
							onBlur={this._updateFn}
							style={detailStyle}
						/>
						<InputMultiline
							label="Description"
							id={"resource-description-" + this.props.item.id}
							field="description"
							value={this.props.item.description}
							onBlur={this._updateFn}
							style={detailStyle}
						/>
						{["link"].indexOf(this.props.item.type) > -1 ? (
							<div>
								<div style={toggleLabelStyle}>Auto-open link: </div>
								<Toggle
									id={"auto-open-" + this.props.item.id}
									field="autoopen"
									value={this.props.item.autoopen}
									updateFn={this._updateFn}
								/>
							</div>
						) : (
							""
						)}
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

const summaryNameStyle = {
	width: "100%",
	display: "inline-block",
	textAlign: "left"
};

const summaryDescriptionStyle = {
	width: "100%",
	display: "inline-block",
	fontSize: "12px",
	textAlign: "left"
};

const mediaStyle = {
	maxWidth: "100%",
	maxHeight: "300px",
	display: "inline-block",
	margin: "8px 0px",
	textAlign: "left"
};

const summaryLinkStyle = {
	color: "rgb(70, 180, 175)",
	pointer: "cursor",
	display: "inline-block"
};

class ResourceSummary extends React.Component {
	render() {
		return (
			<div style={ruleSummaryPrimaryStyle}>
				<div style={summaryNameStyle}>
					{this.props.index + 1}
					{") "}
					{this.props.item.type === "link" ? (
						<a style={summaryLinkStyle} href={this.props.item.link} target="_blank">
							{this.props.item.name}
						</a>
					) : (
						this.props.item.name
					)}
				</div>
				{this.props.item.type === "image" ? (
					<img src={this.props.item.link} alt={this.props.item.name} style={mediaStyle} />
				) : (
					""
				)}
				{this.props.item.type === "video" ? (
					<video
						src={this.props.item.link}
						alt={this.props.item.name}
						style={mediaStyle}
						controls
						controlsList="nodownload"
					/>
				) : (
					""
				)}

				<div style={summaryDescriptionStyle} dangerouslySetInnerHTML={{ __html: this.props.item.description }} />
			</div>
		);
	}
}

class ResourcesOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onAdd = this._onAdd.bind(this);

		this.state = {};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		this.props.actions.setField(field, [this.props.item.id], value);
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_onAdd() {
		var obj = { type: "", link: "", name: "", description: "" };
		this.props.actions.add("resources", [this.props.item.id], obj);
	}

	render() {
		const resources = this.props.item.resources || [];
		return (
			<Overlay>
				<Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<div style={topRowStyle}>
							<H1 text="Setup Resources" textAlign="center" />
						</div>
						<div style={leftColumnStyle}>
							<H2 text="Edit Resources" textAlign="center" />
							{this.props.item.resources === undefined || this.props.item.resources.length === 0 ? (
								<div style={emptyStyle}>Card has no resources</div>
							) : (
								resources.map((item, index, arr) => (
									<Resource
										key={"rule-" + item.id}
										index={index}
										item={item}
										address={[this.props.item.id, item.id]}
										actions={this.props.actions}
										type={this.props.item.type}
									/>
								))
							)}

							<Button text="Add resource" onClick={this._onAdd} style={addButtonStyle} />
						</div>
						<div style={rightColumnStyle}>
							<H2 text="Resources Summary" textAlign="center" />
							{this.props.item.resources === undefined || this.props.item.resources.length === 0 ? (
								<div style={emptyStyle}>Card has no resources</div>
							) : (
								resources.map((item, index, arr) => (
									<ResourceSummary key={"rule-summary-" + item.id} item={item} index={index} />
								))
							)}
						</div>

						<div style={rowStyle}>
							<Button text="Save" style={buttonStyle} onClick={this._onClose} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ResourcesOverlay;
