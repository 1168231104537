function iterate_concepts(nakedTarget, concepts) {
	var temp;
	if (
		nakedTarget.indexOf("[") > -1 &&
		(nakedTarget.indexOf("[") < nakedTarget.indexOf(".") || nakedTarget.indexOf(".") === -1)
	) {
		var single = nakedTarget.substr(0, nakedTarget.indexOf("]") + 1);
		temp = single.substr(0, single.indexOf("["));
		if (concepts[temp] !== undefined) {
			if (Array.isArray(concepts[temp])) {
				single = nakedTarget.substr(
					nakedTarget.indexOf("[") + 1,
					nakedTarget.indexOf("]") - nakedTarget.indexOf("[") - 1
				);

				if (concepts[temp][Number(single)] === undefined) return "";
				else if (nakedTarget.indexOf(".") > -1)
					return iterate_concepts(nakedTarget.substr(nakedTarget.indexOf(".") + 1), concepts[temp][Number(single)]);
				else return concepts[temp][Number(single)];
			} else {
				if (nakedTarget.indexOf(".") > -1)
					return iterate_concepts(nakedTarget.substr(nakedTarget.indexOf(".") + 1), concepts[temp]);
				else return concepts[temp];
			}
		} else return undefined;
	} else if (nakedTarget.indexOf(".") > -1) {
		temp = nakedTarget.substr(0, nakedTarget.indexOf("."));
		if (concepts[temp] !== undefined)
			return iterate_concepts(nakedTarget.substr(nakedTarget.indexOf(".") + 1), concepts[temp]);
		else return undefined;
	} else {
		return concepts[nakedTarget];
	}
}

function single(text, Ticket, concepts) {
	if (text.indexOf("{{") > -1 && text.indexOf("}}") > -1 && text.indexOf("{{") < text.indexOf("}}")) {
		var target = text.substr(text.indexOf("{{"), text.indexOf("}}") - text.indexOf("{{") + 2);
		var nakedTarget = target.substr(2, target.length - 4);
		var re, temp;

		if (concepts !== undefined) {
			if (target.indexOf(".") === -1 && target.indexOf("[") === -1) {
				if (concepts[nakedTarget] !== undefined) {
					if (text === target) return concepts[nakedTarget];
					else {
						re = new RegExp(target, "g");
						text = text.replace(re, concepts[nakedTarget]);
					}
				}
			} else {
				var conceptResult = iterate_concepts(nakedTarget, concepts);
				if (conceptResult !== undefined) {
					if (text === target) return conceptResult;
					else {
						re = new RegExp(target.replace(/\[/g, "\\[").replace(/\]/g, "\\]"), "g");
						text = text.replace(re, String(conceptResult));
					}
				}
			}
		}

		// Tackles build in shortcuts
		if (text.indexOf(target) > -1) {
			var now = new Date();
			text = text.replace(/{{now date}}/g, now.toLocaleDateString());
			text = text.replace(/{{now time}}/g, now.toLocaleTimeString());
			text = text.replace(/{{now}}/g, now.toLocaleString());
		}

		if (
			Ticket !== undefined &&
			Ticket.working !== undefined &&
			text.indexOf(target) > -1 &&
			text.indexOf("ticket") > -1
		) {
			var requesterName = "";
			var requesterFirstName = "";
			var requesterLastName = "";
			const assigneeName = (Ticket.working.external_assignee_name || "").split(" ");

			Ticket.working.requester_name = Ticket.working.requester_name || "";
			if (Ticket.working.requester_name.indexOf(",") > -1) {
				requesterName = Ticket.working.requester_name
					.replace(/,/g, " ")
					.replace(/ {2}/g, " ")
					.split(" ");
				if (requesterName.length > 1) {
					requesterLastName = requesterName[0].replace(/ /g, "");
					requesterFirstName = requesterName[1].replace(/ /g, "");
				}
			} else {
				requesterName = Ticket.working.requester_name.split(" ");
				if (requesterName.length > 1) {
					requesterFirstName = requesterName[0];
					requesterLastName = requesterName[1];
				}
			}

			// Checks comments
			if (nakedTarget.indexOf("ticket.comments") > -1) {
				nakedTarget = nakedTarget.replace("ticket.comments", "");
				if (nakedTarget.indexOf("[") === 0) {
					temp = Ticket.working.comments[nakedTarget.substr(1, nakedTarget.indexOf("]") - 1)];
					temp = temp[nakedTarget.substr(nakedTarget.indexOf("]") + 2, 10000)];
					if (temp !== undefined) {
						re = new RegExp(target.replace(/\[/g, "\\[").replace(/\]/g, "\\]"), "g");
						text = text.replace(re, String(temp));
					}
				}
			}

			// Supported fillins
			text = text.replace(/{{ticket.title}}/g, Ticket.working.title || "");
			text = text.replace(/{{ticket.description}}/g, Ticket.working.description || "");
			text = text.replace(
				/{{ticket.url}}/g,
				Ticket.working.external_url.replace(".json", "").replace("api/v2/", "") || ""
			);
			text = text.replace(/{{ticket.id}}/g, Ticket.working.external_id || "");
			text = text.replace(/{{ticket.private_id}}/g, Ticket.working.external_private_id || "");
			text = text.replace(/{{ticket.via}}/g, Ticket.working.source || "");
			text = text.replace(/{{ticket.status}}/g, Ticket.working.external_status || "");
			text = text.replace(/{{ticket.priority}}/g, Ticket.working.external_priority || "");
			text = text.replace(/{{ticket.ticket_type}}/g, Ticket.working.type || "");
			text = text.replace(/{{ticket.group.name}}/g, Ticket.working.external_group_name || "");
			text = text.replace(/{{ticket.assignee.email}}/g, Ticket.working.external_assignee_email || "");
			text = text.replace(/{{ticket.assignee.name}}/g, Ticket.working.external_assignee_name || "");
			text = text.replace(/{{ticket.assignee.first_name}}/g, assigneeName[0] || "");
			text = text.replace(/{{ticket.assignee.last_name}}/g, assigneeName[assigneeName.length - 1] || "");
			text = text.replace(/{{ticket.requester.name}}/g, requesterFirstName + " " + requesterLastName || "");
			text = text.replace(/{{ticket.requester.first_name}}/g, requesterFirstName || "");
			text = text.replace(/{{ticket.requester.last_name}}/g, requesterLastName || "");
			text = text.replace(/{{ticket.requester.email}}/g, Ticket.working.requester_email || "");
			text = text.replace(/{{ticket.tags}}/g, Ticket.reply.tags.toString().replace(/,/g, ", "));

			// Unsupported fill ins;
			text = text.replace(/{{ticket.brand.name}}/g, "");
			text = text.replace(/{{ticket.due_date}}/g, "");
			text = text.replace(/{{ticket.account}}/g, "");
			text = text.replace(/{{ticket.requester.language}}/g, "");
			text = text.replace(/{{ticket.requester.phone}}/g, "");
			text = text.replace(/{{ticket.requester.external_id}}/g, "");
			text = text.replace(/{{ticket.requester_field}}/g, "");
			text = text.replace(/{{ticket.requester.details}}/g, "");
			text = text.replace(/{{ticket.organization.name}}/g, "");
			text = text.replace(/{{ticket.organization.name}}/g, "");
			text = text.replace(/{{ticket.organization.external_id}}/g, "");
			text = text.replace(/{{ticket.organization.details}}/g, "");
			text = text.replace(/{{ticket.organization.notes}}/g, "");
			text = text.replace(/{{ticket.organization.cc_names}}/g, "");
			text = text.replace(/{{ticket.current_holiday_name}}/g, "");
			text = text.replace(/{{ticket.ticket_field_ID}}/g, "");
			text = text.replace(/{{ticket.ticket_field_option_title_ID}}/g, "");
			text = text.replace(/{{current_user.name}}/g, "");
			text = text.replace(/{{current_user.first_name}}/g, "");
			text = text.replace(/{{current_user.email}}/g, "");
			text = text.replace(/{{current_user.organization.name}}/g, "");
			text = text.replace(/{{current_user.organization.notes}}/g, "");
			text = text.replace(/{{current_user.organization.details}}/g, "");
			text = text.replace(/{{current_user.external_id}}/g, "");
			text = text.replace(/{{current_user.phone}}/g, "");
			text = text.replace(/{{current_user.details}}/g, "");
			text = text.replace(/{{current_user.notes}}/g, "");
			text = text.replace(/{{current_user.language}}/g, "");
			text = text.replace(/{{satisfaction.current_rating}}/g, "");
			text = text.replace(/{{satisfaction.current_comment}}/g, "");
		}

		if (text.indexOf(target) > -1) {
			re = new RegExp(target.replace(/\[/g, "\\[").replace(/\]/g, "\\]"), "g");
			text = text.replace(re, "");
		}

		return single(text, Ticket, concepts);
	} else return text;
}

function all(obj, Ticket, concepts) {
	if (Array.isArray(obj)) {
		for (var i = 0; i < obj.length; i++) all(obj[i], Ticket, concepts);
	} else if (typeof obj === "object" && obj !== null) {
		var myKeys = Object.keys(obj);
		for (var j = 0; j < myKeys.length; j++) {
			if (Array.isArray(obj[myKeys[j]]) || typeof obj[myKeys[j]] === "object") all(obj[myKeys[j]], Ticket, concepts);
			else if (typeof obj[myKeys[j]] === "string" && obj[myKeys[j]].indexOf("{{") > -1 && myKeys[j] !== "javascript")
				obj[myKeys[j]] = single(obj[myKeys[j]], Ticket, concepts);
		}
	}
}

export { all, single, iterate_concepts };
