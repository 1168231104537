import Reflux from "reflux";
import config from "../config.public";

import UserActions from "../actions/UserActions";
import UIActions from "../actions/UIActions";
import ClientActions from "../actions/ClientActions";
import AgentActions from "../actions/AgentActions";
import WorkflowActions from "../actions/WorkflowActions";
import MacroActions from "../actions/MacroActions";
import TicketActions from "../actions/TicketActions";
import ProductActions from "../actions/ProductActions";
import DocumentActions from "../actions/DocumentActions";
import TriageOptionsActions from "../actions/TriageOptionsActions";

import updateUrl from "../functions/update_url";
import guid from "../functions/guid";
import * as objects from "../functions/objects";
import * as permissions from "../functions/permissions";

var UI = {
    view: "loading",
    overlay: "",
    messages: [],
    feed: {
        updates: []
    },
    status: [],
    history: []
};

function handle_current() {
    const myRoute = document.location.hash.replace("#", "").split("/");

    if (UI.view.indexOf("client") > -1 && myRoute.length < 2) ClientActions.close();
    else if (UI.view === "workflow details") WorkflowActions.close();
    else if (UI.view === "macro details") MacroActions.close();
    else if (UI.view.indexOf("ticket") > -1) TicketActions.close();
    else if (UI.view === "agent details") AgentActions.close();
    else if (UI.view === "activetickets") TicketActions.unsubscriveActive();
}

function router() {
    UserActions.get(User => {
        console.log("hits router!!");

        // Handles history
        if (UI.history.length < 2) UI.history.push(document.location.href);
        else if (UI.history[UI.history.length - 2] !== document.location.href) UI.history.push(document.location.href);
        else UI.history.splice(UI.history.length - 1, 1);

        const myRoute = document.location.hash.replace("#", "").split("/");

        // Checks if current view needs to be saved
        handle_current();

        if (myRoute.length > 0 && myRoute[0] !== "") {
            // Handles first level routes
            if (myRoute[0] === "home") {
                ClientActions.loadSummary();
                UserActions.get(function(user) {
                    if (user.me.companyHome) {
                        // Runs defaults
                        UI.view = "home_manager";
                    } else {
                        // Runs defaults
                        UI.view = "home_guru";
                    }

                    UIActions.update();
                });
            } else {
                cycle_route(myRoute, User, config.pages, "");
            }

            /*
            else if (myRoute[0] === "clients") {
				// Deals with case where there is no client id

				// Loads client
				ClientActions.load({ id: myRoute[1] }, myRoute.length > 2);

				// Deals with case where there is no client details field to look at
				if (myRoute[2] === "details" || myRoute[2] === undefined) {
					AgentActions.loadSummary();
					UI.view = "client details";
				} else if (myRoute[2] === "workflows" && myRoute[3] !== undefined) {
					WorkflowActions.load({ id: myRoute[3] });
					UI.view = "workflow details";
				} else if (myRoute[2] === "workflows") {
					WorkflowActions.loadSummary();
					ClientActions.loadSubcollection("workflows");
					UI.view = "client workflows";
				} else if (myRoute[2] === "macros" && myRoute[3] !== undefined) {
					MacroActions.load({ id: myRoute[3] });
					UI.view = "macro details";
				} else if (myRoute[2] === "macros") {
					ClientActions.loadSubcollection("macros");
					UI.view = "client macros";
				} else if (myRoute[2] === "tickets" && myRoute[3] !== undefined) {
					TicketActions.load({ ticketId: myRoute[3], clientId: myRoute[1] });
					UI.view = "ticket details";
				} else if (myRoute[2] === "queued") {
					ClientActions.loadSubcollection("queued");
					UI.view = "client queued";
				} else if (myRoute[2] === "escalations") {
					ClientActions.loadSubcollection("escalations");
					UI.view = "client escalations";
				} else if (myRoute[2] === "csat") {
					ClientActions.loadSubcollection("csat");
					UI.view = "client csat";
				} else if (myRoute[2] === "tickets") {
					ClientActions.loadSubcollection("tickets");
					UI.view = "client tickets";
				} else if (myRoute[2] === "activetickets") {
					ClientActions.loadSubcollection("activetickets");
					UI.view = "client activetickets";
				} else if (myRoute[2] === "integrations") UI.view = "client integrations";
				else if (myRoute[2] === "settings") UI.view = "client settings";
				else if (myRoute[2] === "analytics") {
					AgentActions.loadSummary();
					UI.view = "client analytics";
				} else if (myRoute[2] === "export") {
					ClientActions.loadSubcollection("exports");
					UI.view = "client export";
				} else if (myRoute[2] === "searchtickets") UI.view = "client searchtickets";
				else if (myRoute[2] === "products" && myRoute[3] !== undefined) {
					ProductActions.load({ id: myRoute[3] });
					UI.view = "product details";
				} else if (myRoute[2] === "products") UI.view = "client products";
				else if (myRoute[2] === "documentation") {
					//DocumentActions.load();
					UI.view = "client documentation";
				} else UI.view = "client " + myRoute[2];
			} else if (myRoute[0] === "gurus") {
				// Loads client
				AgentActions.load({ id: myRoute[1] }, myRoute.length > 2);

				if (myRoute[2] === "details" || myRoute[2] === undefined) {
					UI.view = "guru details";
					ClientActions.loadSummary();
				} else if (myRoute[2] === "events") {
					AgentActions.loadSubcollection("events");
					UI.view = "guru events";
				} else if (myRoute[2] === "sessions") {
					AgentActions.loadSubcollection("sessions");
					UI.view = "guru sessions";
				} else if (myRoute[2] === "timechart") {
					AgentActions.loadTimechart();
					UI.view = "guru timechart";
				} else if (myRoute[2] === "analytics") {
					ClientActions.loadSummary();
					UI.view = "guru analytics";
				} else if (myRoute[2] === "settings") UI.view = "guru settings";
				else UI.view = "guru " + myRoute[2];
			} else if (myRoute[0] === "workflows") {
				if (myRoute[1] === undefined) {
					WorkflowActions.loadSummary();
					UI.view = "workflows";
				} else {
					// Loads client
					WorkflowActions.load({ id: myRoute[1] }, false);
					UI.view = "workflow details";
				}
			} else if (myRoute[0] === "tickets") {
				if (myRoute[1] === "workflows") {
					ClientActions.loadSummary();
					UI.view = "tickets/workflows";

					if (myRoute[2] !== undefined) TicketActions.getTicket("workflows", null, myRoute[2]);
				} else if (myRoute[1] === "triage") {
					ClientActions.loadSummary();
					UI.view = "tickets/triage";
				} else run_defaults();
			} else if (myRoute[0] === "activetickets") {
				ClientActions.loadSummary();
				TicketActions.loadActive();
				UI.view = "activetickets";
            } else run_defaults();
            */
        } else run_defaults();

        if (myRoute[0] !== "home") UIActions.update();
    });
}

var check_ticket_path = User => {
    const myRoute = document.location.hash.replace("#", "").split("/");

    if (myRoute.length !== 4) return false;
    if (myRoute[0] !== "clients" || myRoute[2] !== "tickets") return false;

    var myPermission = permissions.find(myRoute[1], User.permissions);
    if (User.me.role !== "admin" && myPermission === null) return false;

    return true;
};

var cycle_route = (myRoute, User, pages, view) => {
    // Finds page
    var target = myRoute.splice(0, 1)[0];
    var myPage, i, myActions;

    for (i = 0; i < pages.length; i++) {
        if (pages[i].value === target) {
            myPage = pages[i];
            view = view === "" ? myPage.value : view + "_" + myPage.value;
            break;
        }
    }

    if ((myPage === undefined || !permissions.full_check(User, view, myPage)) && !check_ticket_path(User)) {
        console.log("permissions failed");
        run_defaults();
        return;
    }

    // Does actions
    var actionValue;
    if (myPage.actions !== undefined && myRoute.length === 0) {
        for (i = 0; i < myPage.actions.length; i++) {
            myActions = get_actions(myPage.actions[i].type);
            //myActions[myPage.actions[i].action](myPage.actions[i].value);

            actionValue = myPage.actions[i].value === undefined ? null : autofill(myPage.actions[i].value);

            if (actionValue === null) myActions[myPage.actions[i].action]();
            else if (Array.isArray(actionValue)) myActions[myPage.actions[i].action](...actionValue);
            else myActions[myPage.actions[i].action](actionValue);
        }
    }

    if (myPage.pagesRequireID && myRoute.length > 0) {
        var targetID = myRoute.splice(0, 1)[0];
        var myPermission = ["clients"].indexOf(view) > -1 ? permissions.find(targetID, User.permissions) : {};

        if (User.me.role !== "admin" && myPermission === null) {
            run_defaults();
            return;
        }

        if (
            view === "clients" &&
            myRoute.length > 0 &&
            !permissions.check_client(User, view + "_" + myRoute[0], targetID) &&
            !check_ticket_path(User)
        ) {
            run_defaults();
            return;
        } else if (view === "clients" && myRoute.length === 0 && myPage.pages !== undefined) {
            for (i = 0; i < myPage.pages.length; i++) {
                if (
                    User.me.role === "admin" ||
                    permissions.check(myPermission || {}, view + "_" + myPage.pages[i].value)
                ) {
                    myRoute.push(myPage.pages[i].value);
                    break;
                } else if (i === myPage.pages - 1) {
                    run_defaults();
                    return;
                }
            }
        }

        if (myPage.idActions !== undefined && myRoute.length === 0) {
            // Does actions for ID
            for (i = 0; i < myPage.idActions.length; i++) {
                myActions = get_actions(myPage.idActions[i].type);

                actionValue = myPage.idActions[i].value === undefined ? null : autofill(myPage.idActions[i].value);
                if (actionValue === null) myActions[myPage.idActions[i].action](targetID);
                else if (Array.isArray(actionValue)) myActions[myPage.idActions[i].action](...actionValue);
                else myActions[myPage.idActions[i].action](actionValue);
            }
        }

        if (myRoute.length === 0 && view !== "ticketworkflows") {
            view = target + "_details";
        }
    }

    if (myRoute.length === 0) {
        UI.view = view;
        return;
    }

    cycle_route(myRoute, User, myPage.pages, view);
};

var autofill = text => {
    var isObject = false;
    if (typeof text === "object") {
        isObject = true;
        text = JSON.stringify(text);
    }

    var path = document.location.hash.replace("#", "").split("/");
    var re;
    for (var i = 0; i < path.length; i++) {
        re = new RegExp("{{path\\[" + i + "\\]}}", "g");
        text = text.replace(re, path[i]);
    }

    if (isObject) text = JSON.parse(text);

    return text;
};

var get_actions = page => {
    if (page === "ClientActions") return ClientActions;
    else if (page === "AgentActions") return AgentActions;
    else if (page === "WorkflowActions") return WorkflowActions;
    else if (page === "TicketActions") return TicketActions;
    else if (page === "MacroActions") return MacroActions;
    else if (page === "ProductActions") return ProductActions;
    else if (page === "DocumentActions") return DocumentActions;
    else if (page === "TriageOptionsActions") return TriageOptionsActions;

    return null;
};

function run_defaults() {
    UIActions.showRouter("home");
}

class UIStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { UIStore: UI };
        this.listenToMany(UIActions);
    }

    onMonitorRoutes() {
        // Handles navigation of browser
        window.onpopstate = function(event) {
            console.log("popstate");
            UIActions.route();
        };
    }

    onShow(view, url, data) {
        if (view !== UI.view || document.location.hash.indexOf(url) === -1) {
            UI.view = view;
            UI.viewData = data;
            if ([undefined, null].indexOf(url) === -1) updateUrl(url);
            this.notify();
        }
    }

    onShowRouter(url) {
        console.log("on show router");
        if (url !== undefined) updateUrl(url);
        UIActions.route();
    }

    onShowOverlay(view, details) {
        if (UI.overlay !== view || UI.overlayDetails !== details) {
            UI.overlay = view;
            UI.overlayDetails = details;
            this.notify();
        }
    }

    onAddMessage(text, type) {
        UI.messages = [{ id: guid(), type, text }];
        this.notify();
    }

    onRemoveMessage(id) {
        objects.remove(UI.messages, [id]);
        this.notify();
    }

    onAddFeedUpdate(message, type) {
        message.id = guid();
        UI.feed.updates.push(message);
        this.notify();
    }

    onRemoveFeedUpdate(id) {
        objects.remove(UI.feed.updates, [id]);
        this.notify();
    }

    onRoute() {
        router();
    }

    onGoBack() {
        if (UI.history.length > 1) window.history.go(-1);
        else {
            handle_current();
            run_defaults();
        }
    }

    onShowConfirmation(data) {
        UI.confirmation = data;
        UI.overlay = "confirmation";
        this.notify();
    }

    onRemoveConfirmation() {
        delete UI.confirmation;
        UI.overlay = "";
        this.notify();
    }

    onUpdate() {
        this.notify();
    }

    notify() {
        console.log("notify UI");
        this.setState({ UIStore: UI });
    }
}

export default UIStore;
