import React from "react";

// Functions
import m from "../../../functions/m";
import validate from "../../../functions/validate";
import errors from "../../../functions/errors";

class Datepicker extends React.Component {
	constructor(props) {
		super(props);
		this._onFocus = this._onFocus.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onKeyDown = this._onKeyDown.bind(this);
		this._onLabelClick = this._onLabelClick.bind(this);

		this.state = { focus: false, value: this.props.value || "", error: errors(this.props.errors, this.props.id) || "" };
	}

	componentWillReceiveProps(nextProps) {
		var setValue = false;
		var setError = false;

		if (nextProps.value !== undefined) setValue = nextProps.value;

		if (nextProps.validate && nextProps.validation !== undefined && Array.isArray(nextProps.validation))
			setError = validate(nextProps.validation, this.state.value) || "";

		if (nextProps.errors !== undefined && nextProps.errors.length > 0)
			setError = errors(nextProps.errors, nextProps.id) || setError;

		if (setValue !== false && setError !== false) this.setState({ value: setValue, error: setError });
		else if (setValue !== false) this.setState({ value: setValue });
		else if (setError !== false) this.setState({ error: setError });
	}

	_onFocus() {
		this.setState({ focus: true });
	}

	_onBlur(event) {
		var value = event.currentTarget.value;
		var error =
			this.props.validation !== undefined && Array.isArray(this.props.validation)
				? validate(this.props.validation, value)
				: "";

		this.setState({ focus: false, error: error });
		if (this.props.onBlur !== undefined) this.props.onBlur(this.props.field, this.props.location, value);
	}

	_onChange(event) {
		var value = event.currentTarget.value;
		var error =
			this.props.validation !== undefined && Array.isArray(this.props.validation) && this.state.error !== ""
				? validate(this.props.validation, value)
				: "";

		this.setState({ value: value, error: error });
		if (this.props.updateFn !== undefined) this.props.updateFn(this.props.field, this.props.location, value);
	}

	_onKeyDown(event) {
		if (this.props.keyDown === undefined) {
			var code = event.keyCode ? event.keyCode : event.which;
			if (code === 13 && this.props.onReturn !== undefined)
				this.props.onReturn(this.props.field, this.props.location, event.currentTarget.value, true);
		} else this.props.keyDown(event);
	}

	_onLabelClick() {
		if (!this.state.focus && this.state.value === "" && this.props.id !== undefined)
			document.getElementById(this.props.id).focus();
	}

	render() {
		const primaryStyle = {
			width: "100%",
			display: "inline-block",
			fontSize: "16px",
			height: "56px",
			marginBottom: "28px",
			position: "relative",
			backgroundColor: this.props.colored ? "rgba(0, 0, 0, 0.06)" : "transparent"
		};

		var labelColor = "rgba(0, 0, 0, 0.54)";
		if (this.state.error !== "") labelColor = "red";
		else if (this.state.focus || this.state.value) labelColor = "rgba(0, 0, 0, 0.38)";

		const labelStyle = {
			fontSize: "12px",
			color: labelColor,
			position: "absolute",
			transition: "0.2s all ease",
			left: "16px",
			top: "8px",
			fontWeight: "400"
		};

		const underlineColor = this.props.noLine
			? "linear-gradient(rgb(70, 180, 175),rgb(70, 180, 175)),linear-gradient(transparent,transparent)"
			: "linear-gradient(rgb(70, 180, 175),rgb(70, 180, 175)),linear-gradient(#D2D2D2,#D2D2D2)";

		const inputStyle = {
			padding: this.props.thin ? "8px 16px" : "28px 16px 8px",
			borderRadius: "4px",
			border: "none",
			fontSize: "16px",
			lineHeight: "16px",
			color: "rgba(0, 0, 0, 0.87)",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundImage:
				this.state.error !== "" ? "linear-gradient(red,red),linear-gradient(#D2D2D2,#D2D2D2)" : underlineColor
		};

		const underStyle = {
			position: "absolute",
			fontSize: "12px",
			top: "64px",
			left: "16px",
			color: this.state.error === "" ? "rgba(0, 0, 0, 0.54)" : "red",
			fontWeight: "400",
			transition: "0.2s all ease",
			opacity: this.state.error === "" && this.props.helpText === undefined ? "0" : "1"
		};

		return (
			<div style={m(primaryStyle, this)}>
				{this.props.label ? (
					<span style={labelStyle} onClick={this._onLabelClick}>
						{this.props.label}
					</span>
				) : (
					""
				)}
				<input
					id={this.props.id}
					style={inputStyle}
					type={this.props.both ? "datetime-local" : "date"}
					onFocus={this._onFocus}
					onBlur={this._onBlur}
					onChange={this._onChange}
					value={this.state.value}
					onKeyDown={this._onKeyDown}
					placeholder={this.props.placeholder || ""}
					className="mui-underline"
				/>
				<div style={underStyle}>
					{this.state.error === "" && this.props.helpText ? this.props.helpText : this.state.error}
				</div>
			</div>
		);
	}
}

export default Datepicker;
