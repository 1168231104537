import React from "react";

import MDSpinner from "react-md-spinner";

class SmallLoading extends React.Component {
	render() {
		const containerStyle = {
			width: "100%",
			display: "inline-block",
			textAlign: "center",
			verticalAlign: "middle"
		};

		const vertAlignStyle = {
			verticalAlign: "middle",
			display: "inline-block",
			width: "0px",
			height: this.props.height || "60vh"
		};

		return (
			<div style={containerStyle}>
				<div style={vertAlignStyle} />
				<div style={containerStyle}>
					<MDSpinner size={100} singleColor="rgb(70, 180, 175)" />
				</div>
			</div>
		);
	}
}

export default SmallLoading;
