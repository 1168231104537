import React from "react";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import Button from "../_common/buttons/Button.react";
import Dropdown from "../_common/forms/Dropdown.react";

// Editor controls
import HyperlinkButton from "../_common/editors/HyperlinkButton.react";

// Headers
import H1 from "../_common/headers/H1.react";

const autoFills = [
	{
		text: "Ticket title",
		value: "{{ticket.title}}"
	},
	{
		text: "Ticket description",
		value: "{{ticket.description}}"
	},
	{
		text: "Ticket url",
		value: "{{ticket.url}}"
	},
	{
		text: "Ticket id",
		value: "{{ticket.id}}"
	},
	{
		text: "Ticket source",
		value: "{{ticket.via}}"
	},
	{
		text: "Ticket status",
		value: "{{ticket.status}}"
	},
	{
		text: "Ticket priority",
		value: "{{ticket.priority}}"
	},
	{
		text: "Ticket type",
		value: "{{ticket.ticket_type}}"
	},
	{
		text: "Group name",
		value: "{{ticket.group.name}}"
	},
	{
		text: "Assignee email",
		value: "{{ticket.assignee.email}}"
	},
	{
		text: "Assignee name",
		value: "{{ticket.assignee.name}}"
	},
	{
		text: "Assignee first name",
		value: "{{ticket.assignee.first_name}}"
	},
	{
		text: "Assignee last name",
		value: "{{ticket.assignee.last_name}}"
	},
	{
		text: "Requester email",
		value: "{{ticket.requester.email}}"
	},
	{
		text: "Requester name",
		value: "{{ticket.requester.name}}"
	},
	{
		text: "Requester first name",
		value: "{{ticket.requester.first_name}}"
	},
	{
		text: "Requester last name",
		value: "{{ticket.requester.last_name}}"
	},
	{
		text: "Ticket tags",
		value: "{{ticket.tags}}"
	}
];

// Styles
const rightControlPanelStyle = {
	width: "800px",
	maxWidth: "800px",
	padding: "8px 0px 0px 0px",
	display: "inline-block",
	verticalAlign: "top"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "left",
	maxWidth: "1200px"
};

const rowStyle = {
	width: "calc(100% + 16px)",
	textAlign: "right",
	margin: "0px -24px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const controlsRowStyle = {
	display: "inline-block",
	padding: "8px",
	marginTop: "8px"
};

const autofillStyle = {
	width: "200px"
};

class CustomReplyEditorOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onInsertHyperlink = this._onInsertHyperlink.bind(this);
		this._onAddAutofill = this._onAddAutofill.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = {
			text: this.props.item.text || "",
			title: this.props.item.title || "",
			recipient: this.props.item.recipient,
			errors: []
		};
	}

	componentDidMount() {
		document.getElementById("recipient-box").focus();
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, [this.props.item.id], value);
	}

	_onClose() {
		UIActions.showOverlay("");
		WorkflowActions.update();
	}

	_onClick() {
		// Validates custom fields
		var errors = [];

		if (errors.length === 0) {
			this.setState({ errors });
			this._onClose();
		} else this.setState({ errors });
	}

	_onInsertHyperlink(value) {
		this.setState({ text: this.state.text + " " + value });
	}

	_onAddAutofill(field, location, value) {
		this.setState({ text: this.state.text + " " + value });
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="848px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<div style={rightControlPanelStyle}>
							<H1 text="Setup Custom Reply" textAlign="center" />
							<Input
								id={"recipient-box"}
								field="recipient"
								value={this.state.recipient}
								label="Recipient"
								noLine={false}
								updateFn={this._onBlur}
							/>
							<Input
								id={"recipient-box"}
								field="title"
								value={this.state.title}
								label="Title"
								noLine={false}
								updateFn={this._onBlur}
							/>
							<InputMultiline
								id={"response-box"}
								field="text"
								value={this.state.text}
								label="Body"
								class=""
								noLine={false}
								onBlur={this._onBlur}
								pasteCorrect={false}
							/>
							<div style={controlsRowStyle}>
								<HyperlinkButton updateFn={this._onInsertHyperlink} />
								<Dropdown
									id={"macro-autocompletes"}
									text="Add autocompletes"
									items={autoFills}
									button={true}
									style={autofillStyle}
									updateFn={this._onAddAutofill}
								/>
							</div>
						</div>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="Save" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default CustomReplyEditorOverlay;
