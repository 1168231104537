export default {
	name: "Front",
	type: "front",
	logo: "/images/logos/front.png",
	subsections: [
		{
			name: "API Connector",
			type: "api connector",
			icon: "compare_arrows",
			activeRules: [
				{
					target: "integration",
					field: "valid",
					value: true
				}
			],
			items: [
				{
					field: "token",
					label: "API Token",
					type: "text",
					validation: [
						{
							type: "min-length",
							value: 160
						}
					]
				},
				{
					field: "secret",
					label: "API Secret",
					type: "text",
					validation: [
						{
							type: "length",
							value: 32
						}
					]
				},
				{
					type: "webhook url"
				}
			]
		},
		{
			name: "Rules",
			type: "rules",
			icon: "call_split",
			activeRules: [
				{
					target: "integration",
					field: "rules",
					type: "arrayCheck"
				},
				{
					target: "integration",
					field: "rules",
					type: "length-greater-than",
					value: 0
				}
			]
		},
		{
			name: "Channels",
			type: "channels",
			icon: "contact_mail",
			activeRules: [
				{
					target: "component",
					field: "channels",
					type: "arrayCheck"
				},
				{
					target: "component",
					field: "channels",
					type: "length-greater-than",
					value: 0
				}
			]
		},
		{
			name: "Users for reassigning",
			type: "users",
			icon: "account_box",
			activeRules: [
				{
					target: "component",
					field: "clientAgents",
					type: "arrayCheck"
				},
				{
					target: "component",
					field: "clientAgents",
					type: "length-greater-than",
					value: 0
				}
			]
		},
		{
			name: "Tags",
			type: "tags",
			icon: "label",
			activeRules: []
		},
		{
			name: "Ticket signature",
			type: "signature",
			icon: "create",
			activeRules: [
				{
					target: "component",
					field: "signature",
					type: "exists"
				}
			]
		},
		{
			name: "Blacklisted words/expressions",
			type: "blacklist",
			icon: "block",
			activeRules: [
				{
					target: "component",
					field: "blacklist",
					type: "arrayCheck"
				},
				{
					target: "component",
					field: "blacklist",
					type: "length-greater-than",
					value: 0
				}
			]
		}
	],
	refreshableFields: [
		{
			text: "Front Teammates",
			value: "users"
		},
		{
			text: "Tags",
			value: "tags"
		},
		{
			text: "Check filters",
			value: "checkfilters"
		}
	],
	submitOptions: ["open", "pending", "solved"],
	ticketOptions: {
		merge: false,
		editTo: true,
		editCC: true,
		editBCC: true
	}
};
