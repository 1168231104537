import * as zendesk from "./zendesk";

var logic_test = (clause, value) => {
	var clauseValue = clause.value;

	if (String(clause.if).indexOf("priority") > -1) {
		value = zendesk.find_priority(value);
		clauseValue = zendesk.find_priority(clauseValue);
	} else if (String(clause.if).indexOf("status") > -1) {
		value = zendesk.find_status(value);
		clauseValue = zendesk.find_status(clauseValue);
	}

	if (clause.state === "is") return value === clauseValue;
	else if (clause.state === "is not") return value !== clauseValue;
	else if (clause.state === "greater than") return value > clauseValue;
	else if (clause.state === "less than") return value < clauseValue;

	return false;
};

var check_clause = (clause, obj, ticket) => {
	if (ticket !== undefined) {
		if (ticket["external_" + clause.if] !== undefined) return logic_test(clause, ticket["external_" + clause.if]);
		else if (ticket[clause.if] !== undefined) return logic_test(clause, ticket[clause.if]);
	}

	if (obj[clause.if] !== undefined) return logic_test(clause, obj[clause.if]);

	if (obj.custom_fields !== undefined) {
		for (var i = 0; i < obj.custom_fields.length; i++) {
			if (obj.custom_fields[i].id === clause.if) return logic_test(clause, obj.custom_fields[i].value);
		}
	}

	return false;
};

var check_rule = (rule, obj, ticket) => {
	for (var i = 0; i < rule.rules.length; i++) {
		if (!check_clause(rule.rules[i], obj, ticket)) return false;
	}

	return true;
};

var check = (rules, obj, ticket) => {
	if (rules === undefined || rules.length === 0) return true;

	for (var i = 0; i < rules.length; i++) {
		if (check_rule(rules[i], obj, ticket)) return rules[i].action === "appear";
	}

	return rules[0].action !== "appear";
};

export { check };
