import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import Input from "../forms/Input.react";
import InputMultiline from "../forms/InputMultiline.react";
import Radio from "../forms/Radio.react";
import Dropdown from "../forms/Dropdown.react";

const questionStyle = {
    marginBottom: "0px"
};

const addRemoveControlsContainerStyle = {
    width: "100%",
    textAlign: "center",
    padding: "0px 0px"
};

const iconStyle = {
    cursor: "pointer",
    color: "rgb(70, 180, 175)",
    fontSize: "30px",
    margin: "4px"
};

class Response extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onReturn = this._onReturn.bind(this);
        this._onBack = this._onBack.bind(this);
        this._onCheck = this._onCheck.bind(this);

        this.state = { text: this.props.item.text || "", hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
        else {
            WorkflowActions.setField(field, location, value, true);

            if (this.props.index < this.props.arrows.length) {
                WorkflowActions.setField("text", this.props.location.concat(this.props.arrows[this.props.index].id), value, true);

                WorkflowActions.setField("shiftCounter", this.props.location, this.props.shiftCounter + 1, true);
            }
        }
    }

    _onReturn(field, location, value) {
        this.props.onReturn(this.props.index);
    }

    _onBack(field, location, value) {
        this.props.onBack(this.props.index);
    }

    _onCheck() {
        this.props.onCheck("value", this.props.location, this.props.item.id);
    }

    render() {
        const primaryStyle = {
            marginLeft: "16px",
            padding: "8px 16px",
            backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.12)" : "rgba(0, 0, 0, 0.06)",
            borderRadius: "4px",
            marginBottom: "8px",
            width: "calc(100% - 88px)",
            transition: "all .3s ease"
        };

        const responseStyle = {
            borderRadius: "10px",
            width: "calc(100% - 32px)",
            marginLeft: "8px",
            marginBottom: "0px",
            minHeight: "",
            verticalAlign: "middle",
            cursor: this.props.agentMode ? "pointer" : ""
        };

        return (
            <div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
                <Radio
                    id={this.props.item.id}
                    location={this.props.location}
                    field="value"
                    value={this.props.value}
                    updateFn={this._onCheck}
                />
                <Input
                    id={"text-" + this.props.item.id}
                    label={this.props.item.concept || ""}
                    value={this.state.text}
                    field="text"
                    location={this.props.location.concat(this.props.item.id)}
                    onBlur={this._onBlur}
                    colored={false}
                    style={responseStyle}
                    thin={true}
                    noLine={true}
                    placeholder="Enter response text here..."
                    onReturn={this._onReturn}
                    readOnly={this.props.agentMode}
                    onClick={this.props.agentMode ? this._onCheck : undefined}
                    editorRefs={this.props.editorRefs}
                />
            </div>
        );
    }
}

class DropdownCard extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onBlurResponse = this._onBlurResponse.bind(this);
        this._onReturn = this._onReturn.bind(this);
        this._onPromptReturn = this._onPromptReturn.bind(this);
        this._onBack = this._onBack.bind(this);
        this._onAddResponse = this._onAddResponse.bind(this);
        this._onRemoveResponse = this._onRemoveResponse.bind(this);

        this.editorRefs = {};

        this.state = {
            text: this.props.item.prompts[0].text || "",
            value: this.props.response !== undefined ? this.props.response.value || "" : this.props.item.value[0] || "",
            valid: false,
            nextPath: 1,
            responseCount: this.props.item.responses.length
        };
    }

    componentDidUpdate() {
        if (this.props.item.responses.length !== this.state.responseCount) {
            this.setState({ responseCount: this.props.item.responses.length });
            const id = "text-" + this.props.item.responses[this.props.item.responses.length - 1].id;
            setTimeout(function() {
                document.getElementById(id).focus();
            }, 10);
        }
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        var valid = this.state.valid;
        if (field === "value") {
            if (value === this.props.item.responses[0].id) valid = true;
            else valid = false;
        }

        this.setState({ [field]: value, valid, nextPath: valid ? 0 : 1 });

        if (this.props.agentMode) {
            WorkflowActions.setFieldResponse(field, location, value, true);

            // Goes to next card
            for (var i = 0; i < this.props.item.responses.length; i++) {
                if (this.props.item.responses[i].id === value) {
                    WorkflowActions.nextCard(i);
                    break;
                }
            }
        } else WorkflowActions.setField(field, location, value, true);
    }

    _onBlurResponse(field, location, value) {
        console.log("SETTING FIELD");
        console.log(field);
        console.log(location);
        console.log(value);
        this.setState({ value });
        if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
    }

    _onPromptReturn() {
        document.getElementById("text-" + this.props.item.responses[0].id).focus();
    }

    _onReturn(index) {
        if (index < this.props.item.responses.length - 1)
            document.getElementById("text-" + this.props.item.responses[index + 1].id).focus();
        else this._onAddResponse();
    }

    _onBack(index) {
        if (index === 0) {
            this.editorRefs[this.props.item.prompts[0].id].focus();
            return;
        }

        document.getElementById("text-" + this.props.item.responses[index - 1].id).focus();
        if (index === this.props.item.responses.length - 1) this._onRemoveResponse();
    }

    _onAddResponse() {
        WorkflowActions.add("responses", [this.props.item.id], { text: "" });
        WorkflowActions.addPath([this.props.item.id]);
    }

    _onRemoveResponse() {
        if (this.props.item.responses.length > 1) {
            WorkflowActions.removePath([this.props.item.id], this.props.item.responses.length - 1);
            WorkflowActions.remove([this.props.item.id, this.props.item.responses[this.props.item.responses.length - 1].id]);
        }
    }

    render() {
        return (
            <Card
                id={this.props.item.id}
                item={this.props.item}
                picture={this.props.client !== undefined ? this.props.client.picture : ""}
                name={this.props.client !== undefined ? this.props.client.name : ""}
                first={this.props.first}
                onMoveView={this.props.onMoveView}
                targetFocus={this.props.targetFocus}
                agentMode={this.props.agentMode}
                onCardConnect={this.props.onCardConnect}
                connecting={this.props.connecting}
                valid={this.state.value !== ""}
                noNextButton={false}
                nextPath={this.state.nextPath}
            >
                <InputMultiline
                    id={this.props.item.prompts[0].id}
                    placeholder="Enter question text here..."
                    value={this.state.text}
                    field="text"
                    location={[this.props.item.id, this.props.item.prompts[0].id]}
                    onBlur={this._onBlur}
                    noLine={true}
                    style={questionStyle}
                    onReturn={this._onPromptReturn}
                    readOnly={this.props.agentMode}
                    editorRefs={this.editorRefs}
                />

                {!this.props.agentMode ? (
                    <div>
                        {this.props.item.responses.map((item, index, arr) => (
                            <Response
                                key={item.id}
                                item={item}
                                index={index}
                                location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
                                updateFn={this._onBlur}
                                value={this.state.value === item.id}
                                onReturn={this._onReturn}
                                onBack={this._onBack}
                                agentMode={this.props.agentMode}
                                onCheck={this._onBlur}
                                arrows={this.props.item.arrows}
                                shiftCounter={this.props.item.shiftCounter || 0}
                                editorRefs={this.editorRefs}
                            />
                        ))}
                        <div style={addRemoveControlsContainerStyle}>
                            <i style={iconStyle} className="material-icons" onClick={this._onRemoveResponse}>
                                remove_circle_outline
                            </i>
                            <i style={iconStyle} className="material-icons" onClick={this._onAddResponse}>
                                add_circle_outline
                            </i>
                        </div>
                    </div>
                ) : (
                    <Dropdown
                        id={"dropdown-" + this.props.item.prompts[0].id}
                        key={"dropdown-" + this.props.item.prompts[0].id}
                        label={this.props.item.concept}
                        updateFn={this._onBlurResponse}
                        field="value"
                        colored={true}
                        searchable={true}
                        location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
                        value={this.state.value || ""}
                        html={true}
                        items={this.props.item.responses.map(item => {
                            return { text: item.text, value: item.id };
                        })}
                    />
                )}
            </Card>
        );
    }
}

export default DropdownCard;
