import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Functions
import * as objects from "../../../functions/objects";

class CustomEmailCard extends React.Component {
	constructor(props) {
		super(props);
		this._onOpenReply = this._onOpenReply.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { nextPath: 0 };
	}

	_onOpenReply() {
		var agentMode = this.props.agentMode;
		var location = [this.props.item.id];
		WorkflowActions.get(function(Workflow) {
			var obj;
			if (agentMode) obj = Workflow.active.card;
			else {
				obj = objects.find(Workflow.working, null, location);
				if (obj !== null && obj.obj !== undefined) obj = obj.obj;
			}

			if ([undefined, null].indexOf(obj) === -1) UIActions.showOverlay("custom reply editor", obj);
			else UIActions.addMessage("Something went wrong :(", "error");
		});
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const responseTextPreviewStyle = {
			width: "calc(100% - 32px)",
			margin: "16px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			cursor: "pointer",
			fontStyle: "italic",
			color: "rgba(0, 0, 0, 0.54)",
			maxHeight: "250px"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
				nextPath={this.state.nextPath}
			>
				<div
					style={responseTextPreviewStyle}
					dangerouslySetInnerHTML={{
						__html: this.props.item.text || "Enter response text here"
					}}
					onClick={this._onOpenReply}
				/>
			</Card>
		);
	}
}

export default CustomEmailCard;
