import React from "react";

// Actions
import AgentActions from "../../actions/AgentActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import SettingsRow from "../_common/SettingsRow.react";

// Form items
import Input from "../_common/forms/Input.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Button from "../_common/buttons/Button.react";
import Toggle from "../_common/forms/Toggle.react";

// Functions
import * as state from "../../functions/state.js";

const subsectionNameStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(100% - 90px)"
};

const rowLeftStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "calc(100% - 280px)"
};

const rowRightStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    width: "200px",
    textAlign: "right"
};

const arrowIconStyle = {
    display: "inline-block",
    verticalAlign: "middle"
};

class Row extends React.Component {
    render() {
        const rowStyle = {
            borderBottom: "1px solid lightgrey",
            padding: "16px",
            borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
            position: "relative",
            transition: "background-color .3s ease",
            cursor: "pointer",
            display: "inline-block",
            width: "calc(100% - 32px)"
        };

        return <div style={rowStyle}>{this.props.children}</div>;
    }
}

class Icon extends React.Component {
    render() {
        const subsectionIconStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0px 24px 0px 8px",
            color: this.props.active ? "rgb(70, 180, 175)" : "grey"
        };

        return (
            <i style={subsectionIconStyle} className="material-icons">
                {this.props.icon}
            </i>
        );
    }
}

class SubsectionSelector extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);

        this.state = { hover: false };
    }

    _onMouseOver() {
        if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
        this.setState({ hover: true });
    }

    _onMouseOut() {
        if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
        this.setState({ hover: false });
    }

    _onClick() {
        this.props.updateFn(this.props.subsection, this.props.integration);
    }

    render() {
        const color = this.props.color || "transparent";

        const primaryStyle = {
            borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
            padding: "16px",
            borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
            position: "relative",
            backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
            transition: "background-color .3s ease",
            cursor: "pointer",
            display: "inline-block",
            width: "calc(100% - 32px)"
        };

        const subsectionIconStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0px 24px 0px 8px",
            color: this.props.active ? "rgb(70, 180, 175)" : "grey"
        };

        return (
            <div
                index={this.props.index}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                style={primaryStyle}
                onClick={this._onClick}
            >
                <i style={subsectionIconStyle} className="material-icons">
                    {this.props.icon}
                </i>
                <div style={subsectionNameStyle}>{this.props.text}</div>
                <i style={arrowIconStyle} className="material-icons">
                    keyboard_arrow_right
                </i>
            </div>
        );
    }
}

// Order options
const roleOptions = [
    {
        text: "Agent",
        value: "agent"
    },
    {
        text: "Manager",
        value: "manager"
    },
    {
        text: "Admin",
        value: "admin"
    }
];

const roleStyle = {
    width: "200px",
    marginBottom: "0px"
};

const stateFields = ["role", "sandboxMode"];

const groupOptions = [
    {
        text: "Group 1",
        value: 1
    },
    {
        text: "Group 2",
        value: 2
    },
    {
        text: "Group 3",
        value: 3
    },
    {
        text: "Group 4",
        value: 4
    }
];

class AgentSettings extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onSubsectionSelect = this._onSubsectionSelect.bind(this);
        this._onCloseSubsection = this._onCloseSubsection.bind(this);
        this._onRoleChange = this._onRoleChange.bind(this);
        this._onGroupChange = this._onGroupChange.bind(this);

        var myState = { subsection: "" };

        myState = state.set(myState, stateFields, this.props.item);
        this.state = myState;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        console.log("SETTING FIELD:" + field);
        AgentActions.setField(field, location, value, true);
    }

    _onSubsectionSelect(subsection, subsectionIntegration) {
        if (subsection === undefined || typeof subsection !== "string") {
            this.setState({ subsection: "", subsectionIntegration });
            var offset = this.state.pageOffset || 0;
            setTimeout(function() {
                window.scrollTo(0, offset);
            }, 10);
        } else {
            this.setState({ subsection, subsectionIntegration, pageOffset: window.pageYOffset });
            window.scrollTo(0, 0);
        }
    }

    _onCloseSubsection() {
        this.setState({ subsection: "", subsectionIntegration: null });
    }

    _onRoleChange(field, location, value) {
        this.setState({ [field]: value, validate: false });
        AgentActions.roleChange(field, location, value);
    }

    _onGroupChange(field, location, value) {
        this.setState({ [field]: value, validate: false });
        AgentActions.groupChange(field, location, value);
    }

    render() {
        return (
            <OuterScaffold spaceForHeader={false} verticalAlign={false}>
                {this.state.subsection === "" ? (
                    <Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
                        <SectionCard title="Role and Tickets">
                            <SettingsRow index={0} icon="change_history" active={true}>
                                <div>Guru role</div>
                                <Dropdown
                                    id={"agent-role"}
                                    style={roleStyle}
                                    value={this.state.role}
                                    field="role"
                                    items={roleOptions}
                                    updateFn={this._onRoleChange}
                                    thin={true}
                                />
                            </SettingsRow>
                            <SettingsRow
                                index={1}
                                icon="accessibility_new"
                                active={this.props.item.sandboxMode || false}
                            >
                                <div>
                                    Enable <b>sandbox mode</b> for guru:
                                </div>
                                <Toggle
                                    id={"sandboxMode-" + this.props.item.id}
                                    field="sandboxMode"
                                    updateFn={this._onBlur}
                                    value={this.props.item.sandboxMode || false}
                                />
                            </SettingsRow>

                            <SettingsRow index={1} icon="assignment_ind" active={this.props.item.canAssign || false}>
                                <div>
                                    Enable <b>assign tickets</b> to agents:
                                </div>
                                <Toggle
                                    id={"canAssign-" + this.props.item.id}
                                    field="canAssign"
                                    updateFn={this._onBlur}
                                    value={this.props.item.canAssign || false}
                                />
                            </SettingsRow>
                            <SettingsRow
                                index={1}
                                icon="calendar_today"
                                active={this.props.item.canEditSchedule || false}
                            >
                                <div>
                                    Enable <b>edit schedule</b> ability:
                                </div>
                                <Toggle
                                    id={"canEditSchedule-" + this.props.item.id}
                                    field="canEditSchedule"
                                    updateFn={this._onBlur}
                                    value={this.props.item.canEditSchedule || false}
                                />
                            </SettingsRow>
                            <SettingsRow
                                index={1}
                                icon="notification_important"
                                active={this.props.item.canNotify || false}
                            >
                                <div>
                                    Enable <b>notification</b> ability:
                                </div>
                                <Toggle
                                    id={"canNotify-" + this.props.item.id}
                                    field="canNotify"
                                    updateFn={this._onBlur}
                                    value={this.props.item.canNotify || false}
                                />
                            </SettingsRow>
                        </SectionCard>
                        <SectionCard title="GROUP">
                            <SettingsRow index={0} icon="group_work" active={true}>
                                <div>Client group</div>
                                <Dropdown
                                    id={"client-group"}
                                    style={roleStyle}
                                    value={this.props.item.group}
                                    field="group"
                                    items={groupOptions}
                                    updateFn={this._onGroupChange}
                                    thin={true}
                                />
                            </SettingsRow>
                        </SectionCard>
                        <SectionCard title="Pages Access">
                            <SettingsRow index={1} icon="visibility" active={this.props.item.activetickets || false}>
                                <div>
                                    Enable <b>active tickets</b> page:
                                </div>
                                <Toggle
                                    id={"activetickets-" + this.props.item.id}
                                    field="activetickets"
                                    updateFn={this._onBlur}
                                    value={this.props.item.activetickets || false}
                                />
                            </SettingsRow>
                            <SettingsRow index={1} icon="home" active={this.props.item.companyHome || false}>
                                <div>
                                    Enable <b>company wide</b> analytics at home:
                                </div>
                                <Toggle
                                    id={"companyHome-" + this.props.item.id}
                                    field="companyHome"
                                    updateFn={this._onBlur}
                                    value={this.props.item.companyHome || false}
                                />
                            </SettingsRow>
                            <SettingsRow index={1} icon="group" active={this.props.item.gurusPage || false}>
                                <div>
                                    Enable <b>gurus</b> page:
                                </div>
                                <Toggle
                                    id={"gurusPage-" + this.props.item.id}
                                    field="gurusPage"
                                    updateFn={this._onBlur}
                                    value={this.props.item.gurusPage || false}
                                />
                            </SettingsRow>
                            <SettingsRow index={1} icon="calendar_today" active={this.props.item.schedulePage || false}>
                                <div>
                                    Enable <b>schedule</b> page:
                                </div>
                                <Toggle
                                    id={"schedulePage-" + this.props.item.id}
                                    field="schedulePage"
                                    updateFn={this._onBlur}
                                    value={this.props.item.schedulePage || false}
                                />
                            </SettingsRow>
                        </SectionCard>
                    </Container>
                ) : (
                    ""
                )}
            </OuterScaffold>
        );
    }
}
export default AgentSettings;
