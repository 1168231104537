export default {
    name: "Gmail",
    type: "gmail",
    logo: "/images/logos/gmail.png",
    subsections: [
        {
            name: "API Connector",
            type: "api connector",
            icon: "compare_arrows",
            activeRules: [
                {
                    target: "integration",
                    field: "valid",
                    value: true
                }
            ],
            items: [
                {
                    field: "email",
                    label: "Gmail User Account",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 6
                        },
                        {
                            type: "contains",
                            value: "@"
                        },
                        {
                            type: "contains",
                            value: "."
                        }
                    ]
                },
                {
                    field: "client_id",
                    label: "Gmail Client ID",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "client_secret",
                    label: "Client Secret",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "project_id",
                    label: "Gmail Project ID",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "access_token",
                    label: "Gmail Access Token",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "refresh_token",
                    label: "Gmail Refresh Token",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "expiry_date",
                    label: "Expires",
                    type: "text"
                },
                {
                    type: "webhook url"
                }
            ]
        },
        {
            name: "Rules",
            type: "rules",
            icon: "call_split",
            activeRules: [
                {
                    target: "integration",
                    field: "rules",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "rules",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Users for reassigning",
            type: "users",
            icon: "account_box",
            activeRules: [
                {
                    target: "component",
                    field: "clientAgents",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "clientAgents",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Tags",
            type: "tags",
            icon: "label",
            activeRules: []
        },
        {
            name: "Ticket signature",
            type: "signature",
            icon: "create",
            activeRules: [
                {
                    target: "component",
                    field: "signature",
                    type: "exists"
                }
            ]
        },
        {
            name: "Blacklisted words/expressions",
            type: "blacklist",
            icon: "block",
            activeRules: [
                {
                    target: "component",
                    field: "blacklist",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "blacklist",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket fields",
            type: "ticket fields",
            icon: "radio_button_checked"
        }
    ],
    refreshableFields: [
        {
            text: "Labels",
            value: "tags"
        },
        {
            text: "Check filters",
            value: "checkfilters"
        }
    ],
    submitOptions: ["open", "solved"],
    ticketOptions: {
        merge: false,
        editTo: false,
        editCC: false,
        editBCC: false
    }
};
