import React from "react";
import Dropzone from "react-dropzone";

// Actions
import UIActions from "../../actions/UIActions";
import ProductActions from "../../actions/ProductActions";

// Scaffolding
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Card from "../_common/Card.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

const dropzoneStyle = {
	border: "none",
	display: "inline-block"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "center"
};

const rowStyle = {
	width: "100%",
	textAlign: "right",
	margin: "16px 0px 0px 0px"
};

const buttonStyle = {
	marginLeft: "8px"
};

class ProductImportOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._onDrop = this._onDrop.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	_onDrop(accepted, rejected) {
		if (accepted.length > 0 && accepted[0].name.toLowerCase().indexOf(".csv") > -1) {
			ProductActions.parseCSV(accepted[0]);
		} else {
			UIActions.addMessage(
				"Sorry, your files cannot be parsed. Please ensure it's not bigger than 10MB and is a CSV file.",
				"error"
			);
		}
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	render() {
		const uploadIconStyle = {
			fontSize: "200px",
			color: "rgb(102, 51, 153)",
			opacity: this.state.hover ? "1" : "0.7",
			cursor: "pointer",
			transition: "all .3s ease"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text="Upload your file" textAlign="center" />
						<Dropzone style={dropzoneStyle} onDrop={this._onDrop} maxSize={1000000} multiple={false}>
							<i
								className="material-icons"
								style={uploadIconStyle}
								onMouseOver={this._onMouseOver}
								onMouseOut={this._onMouseOut}
							>
								cloud_upload
							</i>
						</Dropzone>
						<div style={rowStyle}>
							<Button text="Close" style={buttonStyle} onClick={this._onClose} type="flat" />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ProductImportOverlay;
