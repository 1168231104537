import zendesk from "./zendesk";
import front from "./front";
import liveagent from "./liveagent";
import jira from "./jira";
import kana from "./kana";
import freshdesk from "./freshdesk";
import gorgias from "./gorgias";
import gmail from "./gmail";
import desk from "./desk";
import salesforce from "./salesforce";

export default [zendesk, front, liveagent, jira, kana, freshdesk, gorgias, gmail, desk, salesforce];
