import React from "react";
import config from "../../config.public";

// Actions
import UIActions from "../../actions/UIActions";
import AgentActions from "../../actions/AgentActions";

// Scaffolding
import OuterScaffold from "./OuterScaffold.react";
import SectionCard from "./SectionCard.react";
import Container from "./Container.react";
import Search from "./Search.react";
import UserResult from "./search/UserResult.react";
import SettingsRow from "./SettingsRow.react";

// Form items
import Button from "./buttons/Button.react";
import Toggle from "./forms/Toggle.react";
import Dropdown from "./forms/Dropdown.react";

// Rows
import AgentRow from "../agents/AgentRow.react";

// Headers
import H2 from "./headers/H2.react";

// Functions
import * as list from "../../functions/list";

const searchStyle = {
	width: "calc(100% -  32px)"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTicketTextStyle = {
	fontSize: "24px",
	fontWeight: "400"
};

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

// Order options
const orderStyle = {
	width: "250px",
	marginLeft: "calc(100% - 300px)"
};

const agentOrderOptions = [
	{
		text: "Name",
		value: "name"
	},
	{
		text: "Email",
		value: "email"
	}
];

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "200px",
	textAlign: "left"
};

const createdStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "400",
	width: "200px",
	textAlign: "center"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

const permissionsSectionStyle = {
	display: "inline-block",
	margin: "0px 0px 32px",
	width: "100%"
};

const deleteIntegrationButtonStyle = {
	position: "absolute",
	top: "32px",
	right: "16px"
};

class PermissionsSection extends React.Component {
	render() {
		return (
			<div style={permissionsSectionStyle}>
				<H2 text={this.props.item.text} margin="16px 0px 16px 0px" width="calc(100% - 8px)" />
				{this.props.item.items.map((item, index) => (
					<SettingsRow
						index={index}
						key={this.props.permissions.id + "-" + item.value}
						icon={item.icon}
						active={this.props.permissions[item.value] || false}
					>
						<div>{item.text}</div>
						<Toggle
							id={this.props.permissions.id + "-" + item.value}
							field={item.value}
							updateFn={this.props.updateFn}
							value={this.props.permissions[item.value] || false}
						/>
					</SettingsRow>
				))}
			</div>
		);
	}
}

class PermissionRow extends React.Component {
	render() {
		const activeStyle = {
			fontSize: "16px",
			padding: "2px",
			borderRadius: "50%",
			color: this.props.item.active ? "rgb(70, 180, 175)" : "rgba(0, 0, 0, 0.52)",
			border: "1px solid"
		};

		return (
			<SettingsRow
				index={this.props.index}
				picture={this.props.item.picture}
				name={this.props.item.name || this.props.item.email}
				updateFn={this.props.updateFn}
				subsection={this.props.item.permissions}
				integration={this.props.item}
			>
				<div style={nameStyle}>{this.props.item.name}</div>
				<div style={createdStyle}>
					<i style={activeStyle} className="material-icons">
						check
					</i>
					<span style={labelStyle}>{this.props.item.active ? "Active" : "Inactive"}</span>
				</div>
			</SettingsRow>
		);
	}
}

class Permissions extends React.Component {
	constructor(props) {
		super(props);
		this._onAdd = this._onAdd.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onClose = this._onClose.bind(this);
		this._setPermission = this._setPermission.bind(this);
		this._removePermission = this._removePermission.bind(this);

		var myState = {
			subsection: "",
			orderBy: "name",
			items: list.order(this.props.items, "name")
		};

		this.state = myState;
	}

	_onClick(subsection, target) {
		this.setState({ subsection, target, pageOffset: window.pageYOffset });
		window.scrollTo(0, 0);
	}

	_onClose(blockSave) {
		if (blockSave !== true) {
			var permissions = this.state.subsection;
			AgentActions.updatePermissions(permissions);
		}

		this.setState({ subsection: "" });
		var offset = this.state.pageOffset || 0;
		setTimeout(function() {
			window.scrollTo(0, offset);
		}, 10);
	}

	componentWillReceiveProps(nextProps) {
		var myState = { items: list.order(nextProps.items, this.state.orderBy) };
		this.setState(myState);
	}

	_onAdd(item) {
		var obj = {};
		if (this.props.type === "users") obj = { userId: item.id, clientId: this.props.clientId };
		else if (this.props.type === "clients") obj = { userId: this.props.clientId, clientId: item.id };

		AgentActions.addClient(obj);
	}

	_setPermission(field, location, value) {
		var permission = this.state.subsection;
		permission[field] = value;
		this.setState({ subsection: permission });
	}

	_removePermission() {
		AgentActions.removeClient(this.state.subsection.id);
		this._onClose(true);
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				{this.state.subsection === "" ? (
					<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
						<SectionCard title={this.props.title} subtext={this.props.subtext}>
							<Search
								placeholder={"Search " + this.props.type + " to add..."}
								id={"permissions-search"}
								onSelect={this._onAdd}
								type={this.props.type}
								min={2}
								fields={this.props.type === "users" ? ["name", "email"] : ["name"]}
								style={searchStyle}
								resultComponent={this.props.resultComponent}
								where={[{ field: "active", action: "=", value: true }]}
							/>
							{this.state.items.length === 0 ? (
								<div key="empty-agents" style={emptyStyle}>
									<div style={emptyTicketTextStyle}>There are currently no agents assigned to this account</div>
								</div>
							) : (
								<div style={integrationsStyle}>
									<Dropdown
										id={"order-agents"}
										label="Order"
										value={this.state.orderBy}
										items={agentOrderOptions}
										field="agents"
										updateFn={this._onOrder}
										style={orderStyle}
									/>
									{this.state.items.map((item, index, arr) => (
										<PermissionRow
											item={item}
											key={item.permissions.id}
											index={index}
											updateFn={this._onClick}
											type={this.props.type}
										/>
									))}
								</div>
							)}
						</SectionCard>
					</Container>
				) : (
					<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
						<SectionCard title={this.state.target.name.toUpperCase() + " PERMISSIONS"} onBack={this._onClose}>
							<Button text="Remove" onClick={this._removePermission} style={deleteIntegrationButtonStyle} type="flat" />
							{config.permissions.map((item, index) => (
								<PermissionsSection item={item} permissions={this.state.subsection} updateFn={this._setPermission} />
							))}
						</SectionCard>
					</Container>
				)}
			</OuterScaffold>
		);
	}
}

export default Permissions;
