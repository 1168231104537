import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import CardTag from "../_common/CardTag.react";
import Row from "../_common/Row.react";

// Form items
import ToggleWithLabel from "../_common/forms/ToggleWithLabel.react";
import Datepicker from "../_common/forms/Datepicker.react";

import Button from "../_common/buttons/Button.react";

// Functions
import * as time from "../../functions/time";
import * as state from "../../functions/state";
import * as client_components from "../../functions/client_components";
import * as firestore from "../../functions/firestore";

const dateRangeStyle = {
	width: "180px",
	marginLeft: "16px",
	display: "inline-block"
};

const runStyle = {
	marginLeft: "16px"
};

const toggleStyle = {
	marginRight: "16px",
	width: "200px"
};

const rowStyle = {
	width: "100%",
	display: "inline-block",
	padding: "16px 0px"
};

const labelStyle = {
	width: "100%",
	textAlign: "left",
	padding: "16px 0px",
	fontSize: "16px",
	display: "inline-block",
	fontWeight: "700"
};

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const headerStyle = {
	display: "inline-block",
	borderBottom: "1px solid cadetblue",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 1)",
	padding: "8px 0px"
};

const cellTinyStyle = {
	width: "60px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellSmallStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellSmallCenteredStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative",
	textAlign: "center"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const cellMediumCenteredStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative",
	textAlign: "center"
};

const cellLargeStyle = {
	width: "300px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const buttonRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const buttonStyle = {
	margin: "0px 8px"
};

const fields = [
	{
		text: "Update date",
		value: "external_updated"
	},
	{
		text: "Created date",
		value: "external_created"
	},
	{
		text: "Zendesk ID",
		value: "external_id"
	},
	{
		text: "Subject",
		value: "title"
	},
	{
		text: "Description",
		value: "description"
	},
	{
		text: "Requester Email",
		value: "requester_email"
	},
	{
		text: "Requester name",
		value: "requester_name"
	},
	{
		text: "Assignee Email",
		value: "external_assignee_email"
	},
	{
		text: "Assignee Name",
		value: "external_assignee_name"
	},
	{
		text: "Status",
		value: "external_status"
	},
	{
		text: "Type",
		value: "external_type"
	},
	{
		text: "Priority",
		value: "external_priority"
	},
	{
		text: "Tags",
		value: "tags"
	},
	{
		text: "Zendesk URL",
		value: "external_url"
	}
];

const stateFields = [];

function get_ticket_fields(client) {
	var componentFields = client_components.find(client.components || [], "zendesk");
	var ticketFields = [];
	var field;

	if (client !== undefined && componentFields !== null && componentFields.ticket_fields !== undefined) {
		componentFields = componentFields.ticket_fields;

		for (var j = 0; j < componentFields.length; j++) {
			field = componentFields[j];
			if (
				["subject", "description", "status", "group", "assignee", "type", "priority"].indexOf(field.type) === -1 &&
				field.active
			) {
				ticketFields.push({
					text: field.raw_title,
					value: field.id
				});
			}
		}
	}

	return ticketFields;
}

class ExportRow extends React.Component {
	constructor(props) {
		super(props);
		this._onHoverChange = this._onHoverChange.bind(this);
		this._onDownload = this._onDownload.bind(this);
		this.state = { hover: false };
	}

	_onHoverChange(hover) {
		this.setState({ hover });
	}

	_onDownload() {
		window.open(this.props.item.url, "_blank");
	}

	render() {
		return (
			<Row index={this.props.index} style={rowStyle} hoverChange={this._onHoverChange}>
				<div style={cellMediumStyle}>{time.parse_time(this.props.item.from)}</div>
				<div style={cellMediumStyle}>{time.parse_time(this.props.item.to)}</div>
				<div style={cellSmallStyle}>{this.props.item.status}</div>
				<div style={cellSmallCenteredStyle}>{this.props.item.count || 0}</div>
				{this.props.item.url !== undefined ? (
					<div style={cellMediumCenteredStyle}>
						<Button onClick={this._onDownload} text="DOWNLOAD" />
					</div>
				) : (
					""
				)}
			</Row>
		);
	}
}

class Export extends React.Component {
	constructor(props) {
		super(props);
		this._onUpdate = this._onUpdate.bind(this);
		this._onRun = this._onRun.bind(this);
		this._onDateRangeUpdated = this._onDateRangeUpdated.bind(this);
		this._onToggle = this._onToggle.bind(this);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this._onFilter = this._onFilter.bind(this);

		var to = new Date();
		var from = new Date();
		from.setUTCDate(from.getUTCDate() - 1);

		var toMonth = to.getUTCMonth() + 1 > 9 ? to.getUTCMonth() + 1 : "0" + (to.getUTCMonth() + 1);
		var fromMonth = from.getUTCMonth() + 1 > 9 ? from.getUTCMonth() + 1 : "0" + (from.getUTCMonth() + 1);

		var toDay = to.getUTCDate() > 9 ? to.getUTCDate() : "0" + to.getUTCDate();
		var fromDay = from.getUTCDate() > 9 ? from.getUTCDate() : "0" + from.getUTCDate();

		to = to.getUTCFullYear() + "-" + toMonth + "-" + toDay;
		from = from.getUTCFullYear() + "-" + fromMonth + "-" + fromDay;

		var myState = {
			scroll: 0,
			analytics: {
				to: to,
				from: from
			},
			fields: {},
			loading: false,
			custom_fields: get_ticket_fields(this.props.item)
		};

		myState = state.set(myState, stateFields, this.props.item);

		this.state = myState;
	}

	componentWillReceiveProps(nextProps) {
		var update = {};
		if (nextProps.item !== undefined) {
			if (
				this.props.item === undefined ||
				this.props.item.id !== nextProps.item.id ||
				this.props.item.components === undefined
			) {
				update.custom_fields = get_ticket_fields(nextProps.item);
			}
		}

		update.loading = nextProps.status.indexOf("running export") > -1;
		this.setState(update);
	}

	_onUpdate(field, location, value) {
		this.setState({ [field]: value });
	}

	_onDateRangeUpdated(field, location, value) {
		if (field === "to")
			this.setState({
				analytics: {
					to: value,
					from: this.state.analytics.from
				}
			});
		else
			this.setState({
				analytics: {
					to: this.state.analytics.to,
					from: value
				}
			});
	}

	_onRun() {
		if (new Date(this.state.analytics.to) < new Date(this.state.analytics.from))
			UIActions.addMessage("Your from date cannot be later than your to date.", "error");
		else {
			var message = {
				fields: this.state.fields,
				from: time.get_time(this.state.analytics.from, "America/Los_Angeles"),
				to: time.get_time(this.state.analytics.to, "America/Los_Angeles") + 24 * 60 * 60 * 1000
			};

			ClientActions.exportTickets(message);
			this.setState({ loading: true });
		}
	}

	_onToggle(field, location, value) {
		var stateFields = this.state.fields;
		stateFields[field] = value;
		this.setState({ fields: stateFields });
	}

	_onNextPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			query.startAfter = this.props.cursor.pointers[this.props.cursor.page];
			ClientActions.loadSubcollection(this.props.cursor.type, query, 0, 1);
			window.scrollTo(0, 0);
		}
	}

	_onPreviousPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			if (this.props.cursor.page === 1) delete query.startAfter;
			else query.startAfter = this.props.cursor.pointers[this.props.cursor.page - 2];
			ClientActions.loadSubcollection(this.props.cursor.type, query, 0, -1);
			window.scrollTo(0, 0);
		}
	}

	_onFilter(view, type) {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			delete query.startAfter;
			if (query.orderBy[0][0] === type) query.orderBy[0][1] = query.orderBy[0][1] === "desc" ? "asc" : "desc";
			else query.orderBy[0] = [type, "asc"];

			ClientActions.loadSubcollection(this.props.cursor.type, query, 0);
			window.scrollTo(0, 0);
		}
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="RUN EXPORT" subtext={"Select which tickets and fields to export."}>
						<Datepicker
							id={"analytics-from"}
							style={dateRangeStyle}
							field="from"
							label="From"
							value={this.state.analytics.from || ""}
							updateFn={this._onDateRangeUpdated}
							onBlur={this._onBlur}
						/>
						<Datepicker
							id={this.props.item.id}
							style={dateRangeStyle}
							field="to"
							label="To"
							value={this.state.analytics.to || ""}
							updateFn={this._onDateRangeUpdated}
							onBlur={this._onBlur}
						/>
						{this.state.type !== "" && new Date(this.state.analytics.from) <= new Date(this.state.analytics.to) ? (
							<Button style={runStyle} text="Run" onClick={this._onRun} loading={this.state.loading} />
						) : (
							""
						)}
						<div style={rowStyle}>
							<div style={labelStyle}>General Fields:</div>
							{fields.map((item, index) => (
								<ToggleWithLabel
									key={"field-" + index}
									style={toggleStyle}
									field={item.value}
									text={item.text}
									value={this.state.fields[item.value] || false}
									updateFn={this._onToggle}
								/>
							))}
						</div>
						<div style={rowStyle}>
							<div style={labelStyle}>Custom Fields:</div>

							{this.state.custom_fields.map((item, index) => (
								<ToggleWithLabel
									key={"custom_field-" + index}
									style={toggleStyle}
									field={item.value}
									text={item.text}
									value={this.state.fields[item.value] || false}
									updateFn={this._onToggle}
								/>
							))}
						</div>
					</SectionCard>
					<SectionCard
						title={"DOWNLOAD EXPORTS"}
						subtext={"Download your data using the links below"}
						maxWidth="calc(100% - 48px)"
						width="calc(100% - 48px)"
					>
						{this.props.exports === undefined || this.props.exports.length === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no exports</div>
							</div>
						) : (
							<div style={integrationsStyle}>
								<div style={headerStyle}>
									<div style={cellMediumStyle}>From</div>
									<div style={cellMediumStyle}>To</div>
									<div style={cellSmallStyle}>Status</div>
									<div style={cellSmallCenteredStyle}># of Tickets</div>
								</div>

								{this.props.exports.map((item, index, arr) => (
									<ExportRow key={"export-" + item.id} index={index} item={item} />
								))}
								<div style={buttonRow}>
									{this.props.cursor.page > 0 ? (
										<Button text={"Previous Page"} onClick={this._onPreviousPage} style={buttonStyle} />
									) : (
										""
									)}
									{this.props.exports.length === 25 ? (
										<Button text={"Next Page"} onClick={this._onNextPage} style={buttonStyle} />
									) : (
										""
									)}
								</div>
								<CardTag text={"Page " + ((this.props.cursor.page || 0) + 1)} color="orange" />
							</div>
						)}
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default Export;
