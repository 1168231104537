import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Button from "../_common/buttons/Button.react";
import Viewer from "../_common/Viewer.react";

// Headers
import H1 from "../_common/headers/H1.react";
import TicketActions from "../../actions/TicketActions";

const cardStyle = {
	width: "calc(100% - 100px)",
	padding: "24px",
	textAlign: "center",
	maxWidth: "800px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const buttonRowStyle = {
	marginTop: "16px",
	textAlign: "right"
};

const headerTextStyle = {
	fontSize: "24px",
	display: "inline-block",
	textAlign: "left",
	width: "100%",
	marginBottom: "16px"
};

const textStyle = {
	textAlign: "left",
	width: "100%",
	display: "inline-block",
	marginBottom: "16px"
};

class FlagTicketAcknowledgeOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onAcknowledge = this._onAcknowledge.bind(this);
		this._onOpenTicket = this._onOpenTicket.bind(this);
		this._onAcknowledgeAndClose = this._onAcknowledgeAndClose.bind(this);

		this.state = { text: "", guru: "", opened: false };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_onAcknowledge() {
		TicketActions.acknowledgeFlagTicket(this.props.item);
		UIActions.showOverlay("");
	}

	_onOpenTicket() {
		var win = window.open("#clients/" + this.props.item.clientId + "/tickets/" + this.props.item.ticketId, "_blank");
		win.focus();
		this.setState({ opened: true });
	}

	_onAcknowledgeAndClose() {
		this._onOpenTicket();
		this._onAcknowledge();
	}

	render() {
		const name =
			this.props.item !== undefined && this.props.item.client !== undefined ? this.props.item.client.name : "";

		var color = "inherit";
		if (this.props.item.reason === "problem") color = "indianred";
		else if (this.props.item.reason === "praise") color = "green";

		const reasonStyle = {
			marginLeft: "6px",
			color
		};

		return (
			<Overlay>
				<Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<H1 text={"Flagged Ticket"} textAlign="center" />
						<div style={headerTextStyle}>
							<Viewer item={this.props.item.client || {}} />
							{name}
							{this.props.item.reason !== undefined ? (
								<span style={reasonStyle}>
									{this.props.item.reason.charAt(0).toUpperCase() + this.props.item.reason.substr(1, 1000)}
								</span>
							) : (
								""
							)}
						</div>

						<div
							style={textStyle}
							dangerouslySetInnerHTML={{
								__html: this.props.item.text || ""
							}}
						/>
						<div style={buttonRowStyle}>
							<Button text="Open ticket" style={buttonStyle} onClick={this._onOpenTicket} type="flat" />
							<Button
								text={this.state.opened ? "Got it" : "Open ticket and close"}
								style={buttonStyle}
								onClick={this.state.opened ? this._onAcknowledge : this._onAcknowledgeAndClose}
							/>
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default FlagTicketAcknowledgeOverlay;
