import React from "react";
import integrations from "../../integrations";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

const cardStyle = {
    width: "calc(100% - 48px)",
    padding: "24px",
    textAlign: "left",
    maxWidth: "1000px",
};

const rowStyle = {
    width: "calc(100% + 16px)",
    textAlign: "right",
    margin: "0px -24px -24px -24px",
    borderTop: "1px solid lightgrey",
    padding: "16px",
};

const sectionStyle = {
    width: "100%",
    display: "inline-block",
};

const titleStyle = {
    padding: "16px 0px",
    width: "100%",
    fontWeight: "700",
    fontSize: "16px",
};

const questionCards = [
    "free response",
    "numeric",
    "multiple choice",
    "dropdown",
    "checkbox",
    "datetime",
    "form",
    "table",
    "new email",
    "products",
    "rpa",
    "custom api",
];

var options = [
    {
        title: "Questions",
        items: [
            {
                text: "Free response",
                value: "free response",
                icon: "text_format",
            },
            {
                text: "Number",
                value: "numeric",
                icon: "format_list_numbered",
            },
            {
                text: "Multiple choice",
                value: "multiple choice",
                icon: "radio_button_checked",
            },
            {
                text: "Checkbox",
                value: "checkbox",
                icon: "check_box",
            },
            {
                text: "Dropdown",
                value: "dropdown",
                icon: "arrow_drop_down",
            },
            {
                text: "Date/time",
                value: "datetime",
                icon: "date_range",
            },
            {
                text: "Form",
                value: "form",
                icon: "subject",
            },
            {
                text: "Table",
                value: "table",
                icon: "table_chart",
            },
            {
                text: "Products",
                value: "products",
                icon: "store",
            },
        ],
    },
    {
        title: "Automations",
        items: [
            {
                text: "Website UI Scripts",
                value: "rpa",
                icon: "open_in_browser",
            },
            {
                text: "Google Sheets",
                value: "google sheets",
                icon: "assignment",
            },
            {
                text: "Google Location",
                value: "google location",
                icon: "map",
            },
        ],
    },
    {
        title: "Integrations",
        items: [],
    },
    {
        title: "Email CRM",
        items: [
            {
                text: "Email Requester",
                value: "email",
                icon: "email",
            },
            {
                text: "Set Email Status",
                value: "set status",
                icon: "done_all",
            },
            {
                text: "New Ticket",
                value: "new email",
                icon: "fiber_new",
            },
            {
                text: "Set Field",
                value: "zendesk field",
                icon: "label",
            },
            {
                text: "Private Comment",
                value: "private comment",
                icon: "visibility_off",
            },
        ],
    },
    {
        title: "Workflow ending",
        items: [
            {
                text: "Ticket Solved!",
                value: "solved",
                icon: "done",
            },
            {
                text: "Internal note",
                value: "internal_note",
                icon: "note_add",
            },
            {
                text: "Escalate",
                value: "escalate",
                icon: "trending_up",
            },
        ],
    },
];

var i, j;

for (i = 0; i < options.length; i++) {
    if (options[i].title === "Integrations") {
        for (j = 0; j < integrations.length; j++) {
            options[i].items.push({
                text: integrations[j].name,
                value: integrations[j].type,
                icon: integrations[j].icon,
            });

            questionCards.push(integrations[j].type);
        }

        break;
    }
}

class CardType extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);

        this._onClick = this._onClick.bind(this);
        this.state = { hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onClick(event) {
        var value = this.props.item.value;

        var obj = {
            type: value,
            coordinates: this.props.target.coordinates,
            from:
                this.props.target.from !== undefined
                    ? [
                          {
                              id: this.props.target.from,
                              index: this.props.target.fromIndex,
                              coordinates: this.props.target.fromCoordinates,
                          },
                      ]
                    : [],
            arrows: [
                {
                    text: "Have it",
                },
                {
                    text: "Don't have it",
                },
            ],
            maxArrows: 2,
        };

        if (obj.from.length === 0) obj.start = true;

        if (value === "free response") {
            obj.prompts = [{ text: "" }];
            obj.value = "";
        } else if (value === "numeric") {
            obj.prompts = [{ text: "" }];
            obj.value = "";
        } else if (value === "datetime") {
            obj.prompts = [{ text: "" }];
            obj.value = "";
            obj.collectBoth = false;
        } else if (value === "multiple choice") {
            obj.prompts = [{ text: "" }];
            obj.responses = [{ text: "" }, { text: "" }];
            obj.value = [];
        } else if (value === "dropdown") {
            obj.prompts = [{ text: "" }];
            obj.responses = [{ text: "" }, { text: "" }];
            obj.value = "";
        } else if (value === "checkbox") {
            obj.prompts = [{ text: "" }];
            obj.responses = [{ text: "" }, { text: "" }];
            obj.value = [];
            obj.direction = "more than";
            obj.number = 1;
            obj.arrows = [
                {
                    text: "More than 1 checked",
                },
                {
                    text: "1 or less checked",
                },
            ];
        } else if (value === "table") {
            obj.prompts = [{ text: "" }];
            obj.concept = "table";
        } else if (value === "form") {
            obj.prompts = [{ text: "" }];
            obj.items = [];
            obj.value = {};
        } else if (value === "email") {
            obj.concept = "Email";
            obj.custom_fields = [];
            obj.public = true;
            obj.text = "";
            obj.tags = [];
            obj.arrows = [
                {
                    text: "Email sent",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "set status") {
            obj.concept = "Set email status";
            obj.public = false;
            obj.text = "";
            obj.arrows = [
                {
                    text: "Email sent",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "new email") {
            obj.concept = "New Ticket";
            obj.custom_fields = [];
            obj.text = "";
            obj.public = false;
            obj.requester = "";
            obj.tags = [];
            obj.arrows = [
                {
                    text: "Email sent",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "private comment") {
            obj.concept = "Make Comment Private";
            obj.value = "";
        } else if (value === "custom email") {
            obj.concept = "Custom Email";
            obj.text = "";
            obj.arrows = [
                {
                    text: "Email sent",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "zendesk field") {
            obj.prompts = [{ text: "" }];
            obj.value = "";
        } else if (value === "internal_note") {
            obj.concept = "Internal Note";
            obj.value = "";
            obj.blockdown = true;
            obj.zendesk = true;
            obj.arrows = [
                {
                    text: "Internal note sent",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "solved") {
            obj.concept = "Solved";
            obj.text = "";
            obj.noDown = true;
            obj.noRight = true;
            obj.arrows = [];
            obj.maxArrows = 0;
        } else if (value === "reassign") {
            obj.concept = "Reassign";
            obj.value = "";
            obj.noDown = true;
            obj.noRight = true;
            obj.arrows = [
                {
                    text: "Ticket resassigned",
                },
            ];
            obj.maxArrows = 1;
        } else if (value === "escalate") {
            obj.concept = "Escalate";
            obj.value = "internal";
            obj.blockright = true;
            obj.arrows = [];
            obj.maxArrows = 0;
        } else if (value === "google location") {
            obj.concept = "google location";
            obj.prompts = [{ text: "" }];
            obj.value = "";
        } else if (value === "products") {
            obj.prompts = [{ text: "" }];
            obj.value = "";
        } else if (value === "google sheets") {
            obj.concept = "Google Sheets";
            obj.blockdown = true;
        } else {
            // Handles integrations
            for (var i = 0; i < integrations.length; i++) {
                if (integrations[i].type === obj.type) {
                    obj.concept = integrations[i].name;
                    for (var j = 0; j < integrations[i].cardFields[j].length; j++) {
                        obj[integrations[i].cardFields[j].field] = "";
                    }
                }
            }
        }

        /*        
        else if (value === "database") {
			obj.concept = "Database";
		} else if (value === "stripe") {
			obj.concept = "stripe";
			obj.blockdown = true;
		}  else if (value === "shopify") {
			obj.concept = "Shopify";
			obj.blockdown = true;
		} else if (value === "spree") {
			obj.concept = "Spree";
			obj.blockdown = true;
		} else if (value === "shipwire") {
			obj.concept = "Shipwire";
			obj.blockdown = true;
		} else if (value === "shipstation") {
			obj.concept = "Shipstation";
		} else if (value === "shipping") {
			obj.concept = "Shipping";
		} else if (value === "fulfil") {
			obj.concept = "Fulfil";
		} else if (value === "custom api") {
			obj.concept = "Custom API";
        }
        */

        if (questionCards.indexOf(value) > -1) UIActions.showOverlay("new question card", obj);
        else {
            WorkflowActions.addCard(obj);
            UIActions.showOverlay("");
        }
    }

    render() {
        const primaryStyle = {
            padding: "16px",
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "400",
            width: "200px",
            backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
            transition: "all 0.3s ease",
            cursor: "pointer",
            wordWrap: "break-word",
            position: "relative",
            display: "inline-block",
        };

        const iconStyle = {
            verticalAlign: "middle",
            marginRight: "16px",
            color: this.props.item.iconColor !== undefined ? this.props.item.iconColor : "inherit",
            fontSize: "24px",
            transform: this.props.item.iconRotation !== undefined ? "rotate(" + this.props.item.iconRotation + "deg)" : "",
        };

        return (
            <div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
                <i style={iconStyle} className="material-icons">
                    {this.props.item.icon}
                </i>
                {this.props.item.text || ""}
            </div>
        );
    }
}

class Section extends React.Component {
    render() {
        return (
            <div style={sectionStyle}>
                <div style={titleStyle}>{this.props.item.title}</div>
                {this.props.item.items.map((item, index, arr) => (
                    <CardType key={"card-type-" + this.props.index + "-" + index} item={item} target={this.props.target} />
                ))}
            </div>
        );
    }
}

class AddCardOverlay extends React.Component {
    _onClose() {
        UIActions.showOverlay("");
    }

    render() {
        return (
            <Overlay>
                <Container maxWidth="1000px" key="new-workflow-container" marginBottom="0px">
                    <Card style={cardStyle}>
                        <H1 text="Choose Card" textAlign="center" />
                        {options.map((item, index, arr) => (
                            <Section key={"add-card-section-" + index} index={index} item={item} target={this.props.target} />
                        ))}
                        <div style={rowStyle}>
                            <Button text="Nevermind" onClick={this._onClose} type="flat" />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default AddCardOverlay;
