import React from "react";

import Row from "../_common/Row.react";
import Viewer from "../_common/Viewer.react";

// Functions
import * as time from "../../functions/time.js";

const viewerStyle = {
	width: "60px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px"
};

const cellSmallStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class TicketEventRow extends React.Component {
	constructor(props) {
		super(props);
		this._onToggleDetails = this._onToggleDetails.bind(this);
		this.state = { open: false };
	}

	_onToggleDetails() {
		this.setState({ open: this.state.open ? false : true });
	}

	render() {
		var statusColor = "#F5CA00";
		if (this.props.item.status === "pending") statusColor = "#59BBE0";
		else if (this.props.item.status === "open") statusColor = "#E82A2A";
		else if (this.props.item.status === "solved") statusColor = "#828282";
		else if (this.props.item.status === "closed") statusColor = "black";
		else if (this.props.item.status === "hold") statusColor = "black";

		const statusStyle = {
			color: statusColor,
			fontWeight: "400"
		};

		return (
			<Row
				index={this.props.index}
				style={rowStyle}
				href={
					this.props.showUrl
						? "#clients/" + this.props.item.clientId + "/tickets/" + this.props.item.ticketId
						: undefined
				}
				newTab={true}
			>
				<div style={viewerStyle}>
					<Viewer item={this.props.badge} />
				</div>
				<div style={cellSmallStyle}>
					<span style={statusStyle}>{this.props.item.status.toUpperCase()}</span>
				</div>
				<div style={cellMediumStyle}>{this.props.item.action}</div>
				<div style={cellMediumStyle}>{time.parse_time(this.props.item.start)}</div>
				<div style={cellSmallStyle}>{time.parse_diff(this.props.item.duration / 1000)}</div>
			</Row>
		);
	}
}

export default TicketEventRow;
