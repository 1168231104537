import Reflux from "reflux";
import integrations from "../integrations";
import crms from "../crms";

// Actions
import UIActions from "../actions/UIActions";
import UserActions from "../actions/UserActions";

import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";
import ensure_ids from "../functions/ensure_ids";
import * as autofill from "../functions/autofill";
import * as list from "../functions/list";
import * as dom from "../functions/dom";

import api from "../functions/api";

const TicketActions = require("../actions/TicketActions").default;
const ClientActions = require("../actions/ClientActions").default;
const WorkflowActions = require("../actions/WorkflowActions").default;
const moment = require("moment-business-days");
const shortid = require("shortid");

// Defines db for firestore
var db = firebase.firestore();

var Workflow = {
    status: [],
    active: {},
    direction: "forward",
};

function setup_live_updaters() {
    var subcollections = ["cards"];

    Workflow.workingUpdate = [];

    var ref;
    if (Workflow.clientSelected === undefined) {
        ref = db.collection("workflows").doc(Workflow.working.id);
    } else {
        ref = db
            .collection("clients")
            .doc(Workflow.clientSelected || url.get_client())
            .collection("workflows")
            .doc(Workflow.working.id);
    }

    // Sets up monitoring
    Workflow.workingUpdate.push(
        ref.onSnapshot(function (snapshot) {
            var newObj = firestore.extract_single(snapshot);
            newObj.editingVersion = Workflow.working.editingVersion;
            newObj.currentVersion = Workflow.working.currentVersion || 1;
            newObj.maxVersion = Workflow.working.maxVersion || newObj.currentVersion;

            for (var i = 0; i < subcollections.length; i++) {
                newObj[subcollections[i]] = Workflow.working[subcollections];
            }

            Workflow.working = newObj;

            if (Workflow.ticketWorkflow !== undefined) find_card();
            WorkflowActions.update();
        })
    );

    // Sets up subcollection monitoring
    //for (var j = 0; j < subcollections.length; j++) setup_live_updates_subcollections(subcollections[j]);
}
/*
function setup_live_updates_subcollections(subcollection) {
	Workflow.workingUpdate.push(
		db
			.collection("clients")
			.doc(Workflow.clientSelected || url.get_client())
			.collection("workflows")
			.doc(Workflow.working.id)
			.collection(subcollection)
			.onSnapshot(function(snapshot) {
				if (Workflow.working !== undefined) Workflow.working[subcollection] = firestore.extract(snapshot);
				if (Workflow.ticketWorkflow !== undefined) find_card();
				WorkflowActions.update();
			})
	);
}
*/

function save_cards() {
    const clientId = url.get_client();
    var temp;
    for (var i = 0; i < Workflow.working.cards.length; i++) {
        temp = objects.clone(Workflow.working.cards[i]);
        delete temp.id;

        db.collection("clients")
            .doc(clientId)
            .collection("workflows")
            .doc(Workflow.working.id)
            .collection("cards")
            .doc(Workflow.working.cards[i].id)
            .set(temp, { merge: true });
    }
}

// Figures out if inside of client
function client_check() {
    const myRoute = document.location.hash.replace("#", "").split("/");
    if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
    else return null;
}

function get_url() {
    const client = client_check();
    var url;
    if (client !== null) url = "clients/" + client + "/workflows";
    else url = "workflows";

    return url;
}

function find_card(newResponse) {
    if (Workflow.ticketWorkflow !== undefined && Workflow.working !== undefined && Workflow.working.cards !== undefined) {
        var i;

        for (i = 0; i < Workflow.working.cards.length; i++) {
            if (
                (Workflow.ticketWorkflow.current === undefined && Workflow.working.cards[i].start) ||
                Workflow.ticketWorkflow.current === Workflow.working.cards[i].id
            ) {
                Workflow.active.card = objects.clone(Workflow.working.cards[i]);
                break;
            }
        }

        if (Workflow.active.card === undefined) {
            delete Workflow.ticketWorkflow.current;
            find_card(newResponse);
            return;
        }

        // Adds in tags
        if (Workflow.active.card.type === "email") {
            TicketActions.get(function (Ticket) {
                if (Ticket.reply !== undefined) {
                    if (Workflow.active.card.tags === undefined) Workflow.active.card.tags = [];

                    for (i = 0; i < Ticket.reply.tags.length; i++) {
                        if (Workflow.active.card.tags.indexOf(Ticket.reply.tags[i]) === -1)
                            Workflow.active.card.tags.push(Ticket.reply.tags[i]);
                    }
                }
            });
        }

        // Runs javascript
        try {
            const evalResult = eval(Workflow.active.card.javascript || "");
            if (evalResult === true) return;
        } catch (e) {
            console.log("there was a javascript error!!");
            console.log(e);
        }

        // Adds in responses for populating table card
        if (Workflow.active.card.type === "table") {
            Workflow.active.card.responses = autofill.iterate_concepts(
                Workflow.active.card.field.replace("{{", "").replace("}}", ""),
                Workflow.ticketWorkflow.concepts
            );

            Workflow.active.card.responses.forEach((item) => {
                if (item.id === undefined) item.id = shortid.generate();
            });
        }

        TicketActions.get(function (Ticket) {
            autofill.all(Workflow.active.card, Ticket, Workflow.ticketWorkflow.concepts);

            var obj;
            if (!newResponse && Workflow.ticketWorkflow.responses.length > 0) {
                for (i = Workflow.ticketWorkflow.responses.length - 1; i > -1; i--) {
                    if (Workflow.ticketWorkflow.responses[i].card === Workflow.active.card.id) {
                        obj = Workflow.ticketWorkflow.responses[i];
                        break;
                    }
                }
            }

            if (obj === undefined) {
                UserActions.get(function (User) {
                    obj = {
                        card: Workflow.active.card.id,
                        value: Workflow.active.card.value || null,
                        start: new Date(),
                        agent: User.me.id,
                    };

                    ensure_ids(Workflow.working, obj);
                    Workflow.ticketWorkflow.responses.push(obj);
                    if ([null, undefined].indexOf(obj.value) === -1)
                        Workflow.ticketWorkflow.concepts[Workflow.active.card.concept] = obj.value;
                });
            }

            Workflow.active.response = obj;

            if (Workflow.direction !== "back") {
                // Checks for automations;
                for (i = 0; i < integrations.length; i++) {
                    if (
                        Workflow.active.card.type === integrations[i].type ||
                        (Workflow.active.card.type === "database" && integrations[i].type === "firebase")
                    ) {
                        run_integration_automation(integrations[i]);
                        break;
                    }
                }

                if (Workflow.active.card.type === "rpa") run_rpa_automation();
                else if (Workflow.active.card.type === "google sheets") run_google_sheets_automation();
                else if (Workflow.active.card.type === "private comment")
                    run_make_comment_private(Ticket.working.id, Ticket.working.clientId, Workflow.active.card.value);
            }

            /*
            if (Workflow.active.card.type === "database") run_database_automation();
			else if (Workflow.active.card.type === "stripe") run_stripe_automation();
			else if (Workflow.active.card.type === "shopify") run_shopify_automation();
			else if (Workflow.active.card.type === "spree") run_spree_automation();
			else if (Workflow.active.card.type === "fulfil") run_fulfil_automation();
			else if (Workflow.active.card.type === "shipwire") run_shipwire_automation();
			else if (Workflow.active.card.type === "shipstation") run_shipstation_automation();
			else if (Workflow.active.card.type === "custom api") run_custom_api_automation();
            else if (Workflow.active.card.type === "set status") WorkflowActions.nextCard(true);
            */
        });
    }
}

///////////////////////////////////////////////////////
// Card connections
///////////////////////////////////////////////////////

// Connects cards o one another on loop or connect

var connect_card = (connecting, index, targetID) => {
    var source = objects.find(Workflow.working, null, [connecting]);
    var target = objects.find(Workflow.working, null, [targetID]);

    if (source.obj !== undefined && target.obj !== undefined) {
        if (source.obj.to === undefined) source.obj.to = [];
        if (target.obj.from === undefined) target.obj.from = [];

        source.obj.to.push({
            connecting: true,
            id: targetID,
            coordinates: { x: target.obj.coordinates.x, y: target.obj.coordinates.y },
            index: index,
        });

        target.obj.from.push({
            connecting: true,
            id: connecting.id,
            index: index,
            coordinates: { x: source.obj.coordinates.x, y: source.obj.coordinates.y },
        });
    }
};

///////////////////////////////////////////////////////
// Add Card Checks
///////////////////////////////////////////////////////

var add_card_updates = (obj) => {
    if (obj.from !== undefined && obj.from.length > 0) {
        var source = objects.find(Workflow.working.cards, null, [obj.from[0].id]);
        if (source !== null) source = source.obj;
        else return;

        // First figures out if the source already has a connection on this path
        var betweenConnection = false;
        var i;
        if (source.to !== undefined) {
            for (i = 0; i < source.to.length; i++) {
                if (source.to[i].index === obj.from[0].index) {
                    betweenConnection = true;
                    obj.to = [objects.clone(source.to[i])];
                    obj.to[0].index = 0;
                    source.to.splice(i, 1);
                    break;
                }
            }
        }

        // Updates source;
        update_source_obj(obj, "add");

        if (betweenConnection) {
            var connected = objects.find(Workflow.working.cards, null, [obj.to[0].id]);
            if (connected !== null) connected = connected.obj;
            else return;

            if (connected.from !== undefined) {
                for (i = 0; i < connected.from.length; i++) {
                    if (connected.from[i].index === obj.from[0].index) {
                        connected.from[i] = {
                            index: obj.to[0].index,
                            id: obj.id,
                            coordinates: { x: obj.coordinates.x, y: obj.coordinates.y },
                        };
                        break;
                    }
                }
            }

            adjust_arrows_single(connected, obj);
            cascade_to_location(connected, 1);
        }

        const fromIndex = obj.from[0].index;
        const growth = obj.arrows.length > 0 ? obj.arrows.length - 1 : 0;
        const downGrowth = Math.ceil(growth / 2);
        const upGrowth = Math.floor(growth / 2);

        if (upGrowth > 0)
            adjust_coordinates("up", source, obj.id, obj.coordinates.y, obj.coordinates.x, fromIndex === 0 ? upGrowth : upGrowth);
        if (downGrowth > 0)
            adjust_coordinates("down", source, obj.id, obj.coordinates.y, obj.coordinates.x, fromIndex === 0 ? downGrowth : downGrowth);

        // Handles arrow growth
        adjust_arrows("up", obj.coordinates.y, obj.coordinates.x, upGrowth);
        adjust_arrows("down", obj.coordinates.y, obj.coordinates.x, downGrowth);

        source_adjust_cascade(source, fromIndex, upGrowth, downGrowth);
    }
};

var remove_card_updates = (obj) => {
    if (obj.from !== undefined && obj.from.length > 0) {
        var source = objects.find(Workflow.working.cards, null, [obj.from[0].id]);
        if (source !== null) source = source.obj;
        else return;

        var i;
        var sourceID = obj.id;
        // Figures out if object is in the middle of a workflow
        if (obj.to !== undefined && obj.to.length > 1) {
            UIActions.addMessage("Cannot delete a card with more than one connection.", "error");
            return;
        } else if (obj.to !== undefined && obj.to.length === 1 && (obj.from[0].index !== 0 || obj.from[0].index !== obj.to[0].index)) {
            UIActions.addMessage("Can only delete cards with a path left to right.", "error");
            return;
        } else if (obj.to !== undefined && obj.to.length === 1) {
            var connected = objects.find(Workflow.working.cards, null, [obj.to[0].id]);
            if (connected !== null) connected = connected.obj;
            else return;

            sourceID = connected.id;
            if (connected.from !== undefined) {
                for (i = 0; i < connected.from.length; i++) {
                    if (connected.from[i].index === obj.to[0].index) {
                        connected.from[i] = {
                            index: obj.to[0].index,
                            id: source.id,
                            coordinates: { x: source.coordinates.x, y: source.coordinates.y },
                        };
                        break;
                    }
                }
            }

            for (i = 0; i < source.to.length; i++) {
                if (source.to[i].id === obj.id) {
                    source.to.push({
                        index: source.to[i].index,
                        id: connected.id,
                        coordinates: { x: connected.coordinates.x, y: connected.coordinates.y },
                    });
                    break;
                }
            }

            //adjust_arrows_single(obj, source, true);
            cascade_to_location(connected, -1);
        }

        update_source_obj(obj, "remove");

        const fromIndex = obj.from[0].index;
        const growth = obj.arrows.length > 0 ? obj.arrows.length - 1 : 0;
        const downGrowth = Math.ceil(growth / 2) * -1;
        const upGrowth = Math.floor(growth / 2) * -1;

        if (upGrowth < 0) adjust_coordinates("up", source, sourceID, obj.coordinates.y, obj.coordinates.x - 1, upGrowth);
        if (downGrowth < 0) adjust_coordinates("down", source, sourceID, obj.coordinates.y, obj.coordinates.x - 1, downGrowth);

        // Handles arrow growth
        adjust_arrows("up", obj.coordinates.y, obj.coordinates.x, upGrowth);
        adjust_arrows("down", obj.coordinates.y, obj.coordinates.x, downGrowth);

        source_adjust_cascade(source, fromIndex, upGrowth, downGrowth);

        objects.remove(Workflow.working, [obj.id]);
    } else objects.remove(Workflow.working, [obj.id]);
};

var add_path = (obj) => {
    var newArrow = { text: "" };
    ensure_ids(Workflow.working, newArrow);
    obj.arrows.push(newArrow);

    const index = obj.arrows.length - 1;

    // Adjusts other cards
    if (obj.from === undefined || obj.from.length === 0) return;

    var source = objects.find(Workflow.working.cards, null, [obj.from[0].id]);
    if (source !== null) source = source.obj;
    else return;

    const fromIndex = obj.from[0].index;
    const downGrowth = index % 2 === 1 ? 1 : 0;
    const upGrowth = index % 2 === 0 ? 1 : 0;

    if (upGrowth > 0) adjust_coordinates("up", source, obj.id, obj.coordinates.y, obj.coordinates.x, upGrowth);
    if (downGrowth > 0) adjust_coordinates("down", source, obj.id, obj.coordinates.y, obj.coordinates.x, downGrowth);

    // Handles arrow growth
    adjust_arrows("up", obj.coordinates.y, obj.coordinates.x, upGrowth);
    adjust_arrows("down", obj.coordinates.y, obj.coordinates.x, downGrowth);

    source_adjust_cascade(source, fromIndex, upGrowth, downGrowth);
};

var remove_path = (obj, index) => {
    // Removes arrow
    if (obj.arrows.length - 1 < index) return;

    obj.arrows.splice(index, 1);
    if (obj.from === undefined || obj.from.length === 0) return;

    // Adjusts other cards
    var source = objects.find(Workflow.working.cards, null, [obj.from[0].id]);
    if (source !== null) source = source.obj;
    else return;

    const fromIndex = obj.from[0].index;
    const downGrowth = index % 2 === 1 ? -1 : 0;
    const upGrowth = index % 2 === 0 ? -1 : 0;

    if (upGrowth < 0) adjust_coordinates("up", source, obj.id, obj.coordinates.y, obj.coordinates.x, upGrowth);
    if (downGrowth < 0) adjust_coordinates("down", source, obj.id, obj.coordinates.y, obj.coordinates.x, downGrowth);

    // Handles arrow growth
    adjust_arrows("up", obj.coordinates.y, obj.coordinates.x, upGrowth);
    adjust_arrows("down", obj.coordinates.y, obj.coordinates.x, downGrowth);

    source_adjust_cascade(source, fromIndex, upGrowth, downGrowth);
};

var cascade_to_location = (obj, change) => {
    obj.coordinates.x += change;
    adjust_connections(obj);

    if (obj.to !== undefined) {
        for (var i = 0; i < obj.to.length; i++) {
            if (!obj.to[i].connecting) {
                var connected = objects.find(Workflow.working.cards, null, [obj.to[i].id]);
                if (connected !== null) {
                    connected = connected.obj;
                    cascade_to_location(connected, change);
                }
            }
        }
    }
};

var source_adjust_cascade = (source, fromIndex, upGrowth, downGrowth) => {
    if (fromIndex !== 0 && fromIndex % 2 === 0) adjust_arrows("up", source.coordinates.y, source.coordinates.x, upGrowth + downGrowth);
    if (fromIndex !== 0 && fromIndex % 2 === 1) adjust_arrows("down", source.coordinates.y, source.coordinates.x, upGrowth + downGrowth);

    if (fromIndex !== 0) {
        if (source.shift === undefined) source.shift = { up: 0, down: 0 };
        if (source.shift[fromIndex + 2] === undefined) source.shift[fromIndex + 2] = 0;
        source.shift[fromIndex + 2] += upGrowth + downGrowth;

        source.shiftCounter = (source.shiftCounter || 0) + 1;
    }

    if ([null, undefined].indexOf(source.from) > -1 || source.from.length === 0) return;

    var newSource = objects.find(Workflow.working.cards, null, [source.from[0].id]);

    if (newSource !== null) newSource = newSource.obj;
    else return;

    fromIndex = source.from[0].index;
    source_adjust_cascade(newSource, fromIndex, upGrowth, downGrowth);
};

var adjust_coordinates = (direction, obj, sourceID, y, x, value) => {
    if (direction === "up" && obj.coordinates.y < y) {
        obj.coordinates.y -= value;
        adjust_connections(obj);
    } else if (direction === "down" && obj.coordinates.y > y) {
        obj.coordinates.y += value;
        adjust_connections(obj);
    }

    var newObj, i;
    if (obj.from !== undefined) {
        for (i = 0; i < obj.from.length; i++) {
            if (obj.from[i].id !== sourceID && !obj.from[i].connecting) {
                newObj = objects.find(Workflow.working.cards, null, [obj.from[i].id]);
                if (newObj !== null) {
                    newObj = newObj.obj;
                    adjust_coordinates(direction, newObj, obj.id, y, x, value);
                }
            }
        }
    }

    if (obj.to !== undefined) {
        for (i = 0; i < obj.to.length; i++) {
            if (obj.to[i].id !== sourceID && !obj.to[i].connecting) {
                newObj = objects.find(Workflow.working.cards, null, [obj.to[i].id]);
                if (newObj !== null) {
                    newObj = newObj.obj;
                    adjust_coordinates(direction, newObj, obj.id, y, x, value);
                }
            }
        }
    }
};

var adjust_arrows = (direction, y, x, value) => {
    var card;

    for (var i = 0; i < Workflow.working.cards.length; i++) {
        if (Workflow.working.cards[i].coordinates.y === y && Workflow.working.cards[i].coordinates.x < x) {
            card = Workflow.working.cards[i];

            if (card.shift === undefined) card.shift = { up: 0, down: 0 };
            if (direction === "up") card.shift.up += value;
            else if (direction === "down") card.shift.down += value;
            card.shiftCounter = (card.shiftCounter || 0) + 1;
        }
    }
};

var adjust_arrows_single = (obj, card, toDelete) => {
    const shift = obj.shift || { up: 0, down: 0 };
    const change = toDelete ? -1 : 1;

    const growth = obj.arrows.length > 0 ? obj.arrows.length - 1 : 0;
    const downGrowth = Math.ceil(growth / 2);
    const upGrowth = Math.floor(growth / 2);

    if (card.shift === undefined) card.shift = { up: 0, down: 0 };
    card.shift.up = shift.up + upGrowth * change;
    card.shift.down = shift.down + downGrowth * change;
};

var adjust_connections = (obj) => {
    var j, card;
    for (var i = 0; i < Workflow.working.cards.length; i++) {
        card = Workflow.working.cards[i];
        if (card.from !== undefined && card.from.length > 0) {
            for (j = 0; j < card.from.length; j++) {
                if (card.from[j].id === obj.id) {
                    card.from[j].coordinates = { x: obj.coordinates.x, y: obj.coordinates.y };
                    card.shiftCounter = (card.shiftCounter || 0) + 1;
                }
            }
        }

        if (card.to !== undefined && card.to.length > 0) {
            for (j = 0; j < card.to.length; j++) {
                if (card.to[j].id === obj.id) {
                    card.to[j].coordinates = { x: obj.coordinates.x, y: obj.coordinates.y };
                    card.shiftCounter = (card.shiftCounter || 0) + 1;
                }
            }
        }
    }
};

// Figures out if updates need to happen to the card that spawned the current one
function update_source_obj(obj, action) {
    var source;
    if (obj.from !== undefined && obj.from.length > 0) {
        source = objects.find(Workflow.working, null, [obj.from[0].id]);
        if (source === null) return;

        if (source.obj !== undefined) {
            if (source.obj.to === undefined) source.obj.to = [];
            if ([null, undefined, "", "add"].indexOf(action) > -1) {
                source.obj.to.push({ index: obj.from[0].index, id: obj.id, coordinates: obj.coordinates });
            } else {
                for (var i = 0; i < source.obj.to.length; i++) {
                    if (source.obj.to[i].id === obj.id) {
                        if (action === "remove") source.obj.to.splice(i, 1);
                        else if (action === "replace")
                            source.obj.to[i] = { index: obj.from[0].index, id: obj.id, coordinates: obj.coordinates };
                        break;
                    }
                }
            }
        }
    }
}

function make_new(data) {
    var template = data.template;
    var name = data.name;
    var description = data.description;
    var tier = data.tier;
    delete data.template;

    if ([null, undefined, ""].indexOf(template) > -1) {
        data.maxID = 0;
        make_new_step2(data);
    } else {
        template = db.collection("workflows").doc(template);

        template
            .get()
            .then(function (snapshot) {
                data = firestore.extract_single(snapshot);
                delete data.id;
                data.name = name;
                data.description = description;
                data.tier = tier;

                template
                    .collection("versions")
                    .doc("version_" + (data.currentVersion || 1))
                    .get()
                    .then(function (snapshot) {
                        var version = firestore.extract(snapshot);
                        data.cards = version.cards || [];
                        make_new_step2(data);
                    })
                    .catch(WorkflowActions.error);
            })
            .catch(WorkflowActions.error);
    }
}

function make_new_step2(data) {
    data.active = true;
    data.created = new Date();

    var ref;
    if (document.location.href.indexOf("clients") === -1) {
        ref = db.collection("workflows").doc();
    } else {
        const clientId = url.get_client();
        ref = db.collection("clients").doc(clientId).collection("workflows").doc();
    }

    data.maxVersion = 1;
    data.currentVersion = 1;
    data.editingVersion = 1;

    var cards = data.cards;
    delete data.cards;

    ref.set(data)
        .then(function () {
            data.id = ref.id;
            ref.collection("versions")
                .doc("version_" + 1)
                .set({ version: 1, updated: new Date(), cards: cards || [] });

            // Does initial load
            UIActions.showRouter(get_url() + "/" + data.id);
        })
        .catch(WorkflowActions.loadFailed);
}

// Sends custom emails
function send_custom_email() {
    get_integration_for_automation(null, function (integrationId) {
        var obj = {
            recipient: Workflow.active.card.recipient,
            text: Workflow.active.card.text,
            title: Workflow.active.card.title,
        };

        api(
            "post",
            "/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/customemail",
            obj,
            WorkflowActions.update,
            WorkflowActions.error,
            Workflow,
            "send custom email"
        );
    });
}

// Automations
var run_integration_automation = (integration) => {
    get_integration_for_automation(null, function (integrationId) {
        var obj = {};
        for (var i = 0; i < integration.cardFields.length; i++) {
            obj[integration.cardFields[i].field] = Workflow.active.card[integration.cardFields[i].field] || "";
        }

        if (obj.integrationId !== undefined) integrationId = obj.integrationId;

        api(
            "post",
            "/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/" + integration.type,
            obj,
            run_database_automation_result,
            WorkflowActions.error,
            Workflow,
            "integration event"
        );
    });
};

function run_google_sheets_automation() {
    var obj = {
        action: Workflow.active.card.action,
        sheet: Workflow.active.card.sheet,
        range: Workflow.active.card.range,
        data: Workflow.active.card.data,
    };

    api(
        "post",
        "/clients/" + Workflow.clientSelected + "/integrations/none/googlesheets",
        obj,
        run_database_automation_result,
        WorkflowActions.error,
        Workflow,
        "google sheets event"
    );
}

/*
function run_database_automation() {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			db: Workflow.active.card.db,
			subtype: Workflow.active.card.subtype,
			fieldType: Workflow.active.card.fieldType,
			query: Workflow.active.card.query,
			collection: Workflow.active.card.collection,
			value: Workflow.active.card.value,
			order: Workflow.active.card.order,
			limit: Workflow.active.card.limit
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/database",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"integration event"
		);
	});
}



var run_custom_api_automation = () => {
	var obj = {
		method: Workflow.active.card.method || "GET",
        url: Workflow.active.card.url || "",
        responseType: Workflow.active.card.responseType || "string",
	};

	api(
		"post",
		"/clients/" + Workflow.clientSelected + "/integrations/" + Workflow.active.card.integrationId + "/customapi",
		obj,
		run_database_automation_result,
		WorkflowActions.error,
		Workflow,
		"custom api event"
	);
};

function run_stripe_automation() {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action,
			customerId: Workflow.active.card.customerId,
			chargeId: Workflow.active.card.chargeId,
			note: Workflow.active.card.note,
			reason: Workflow.active.card.reason,
			amount: Workflow.active.card.amount,
			charges: Workflow.active.card.charges
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/stripe",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"integration event"
		);
	});
}

function run_shipwire_automation() {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/shipwire",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"integration event"
		);
	});
}

function run_shipstation_automation() {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action,
			orderId: Workflow.active.card.orderId,
			tagId: Workflow.active.card.tagId || null,
			address: Workflow.active.card.address || null
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/shipstation",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"integration event"
		);
	});
}

function run_shopify_automation() {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action,
			value: Workflow.active.card.value,
			fieldType: Workflow.active.card.fieldType,
			orderUpdate: Workflow.active.card.orderUpdate,
			note: Workflow.active.card.note,
			transaction: Workflow.active.card.transaction,
			amount: Workflow.active.card.amount
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/shopify",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"shopify event"
		);
	});
}

var run_spree_automation = () => {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action,
			value: Workflow.active.card.value,
			fieldType: Workflow.active.card.fieldType,
			recordType: Workflow.active.card.recordType,
			recordId: Workflow.active.card.recordId
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/spree",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"spree event"
		);
	});
};

var run_fulfil_automation = () => {
	get_integration_for_automation(null, function(integrationId) {
		var obj = {
			action: Workflow.active.card.action,
			value: Workflow.active.card.value,
			fieldType: Workflow.active.card.fieldType,
			recordType: Workflow.active.card.recordType,
			recordId: Workflow.active.card.recordId
		};

		api(
			"post",
			"/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/fulfil",
			obj,
			run_database_automation_result,
			WorkflowActions.error,
			Workflow,
			"spree event"
		);
	});
};
*/

////////////////////////////////////////////////////
// Utilities
////////////////////////////////////////////////////

var fix_underscores = (obj) => {
    if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            if (Array.isArray(obj[i])) obj[i] = "omitted array";
            else fix_underscores(obj[i]);
        }
    } else if ([null, undefined].indexOf(obj) === -1 && typeof obj === "object") {
        var myKeys = Object.keys(obj);

        for (var j = 0; j < myKeys.length; j++) {
            if (myKeys[j][0] === "_" || myKeys[j][myKeys[j].length - 1] === "_") delete obj[myKeys[j]];
            else if (obj[myKeys[j]] === undefined) delete obj[myKeys[j]];
            else if (typeof obj[myKeys[j]] === "object") fix_underscores(obj[myKeys[j]]);
        }
    }
};

var run_rpa_automation = () => {
    var obj = {
        code: Workflow.active.card.code,
    };

    api(
        "post",
        "/rpa/" + Workflow.clientSelected + "/integrations/" + Workflow.active.card.integrationId + "/rpa",
        obj,
        run_database_automation_result,
        WorkflowActions.error,
        Workflow,
        "rpa event"
    );
};

var run_make_comment_private = (ticketId, clientId, commentId) => {
    api(
        "get",
        "/clients/" + clientId + "/tickets/" + ticketId + "/comments/" + commentId + "/make_private",
        null,
        run_database_automation_result,
        WorkflowActions.error,
        Workflow,
        "make comment private"
    );
};

function run_database_automation_result(result) {
    console.log("GOT TO RUN DATABASE AUTOMATION");
    console.log(result);
    if (Workflow.active.card.type === "new email") Workflow.active.card.done = true;

    Workflow.ticketWorkflow.concepts[Workflow.active.card.concept] = result.result;

    console.log("still here");
    WorkflowActions.nextCard(true);
}

function run_new_email_automation() {
    get_crm_integration_for_automation(function (integrationId) {
        console.log(Workflow.active.card);
        var message = {
            external_status: Workflow.active.card.external_status,
            subject: Workflow.active.card.subject,
            tags: Workflow.active.card.tags,
            text: Workflow.active.card.text,
            assignee_id: Workflow.active.card.assignee_id,
            requester_name: Workflow.active.card.requester_name,
            requester_email: Workflow.active.card.requester_email,
            custom_fields: Workflow.active.card.custom_fields || [],
            cc: Workflow.active.card.cc,
            external_type: Workflow.active.card.external_type,
            external_priority: Workflow.active.card.external_priority,
            public: Workflow.active.card.public !== undefined ? Workflow.active.card.public : true,
            attachments: Workflow.active.card.attachments,
        };

        api(
            "post",
            "/clients/" + Workflow.clientSelected + "/integrations/" + integrationId + "/newemail",
            message,
            run_database_automation_result,
            WorkflowActions.error,
            Workflow,
            "new email event"
        );
    });
}

var get_integration_for_automation = (type, callback) => {
    ClientActions.get(function (Client) {
        var integrationId;

        type = type || Workflow.active.card.type;
        if (Workflow.active.card.type === "database") type = "firebase";

        for (var i = 0; i < Client.working.integrationsList.length; i++) {
            if (Client.working.integrationsList[i].type === type) {
                integrationId = Client.working.integrationsList[i].integrationId;
                break;
            }
        }

        callback(integrationId);
    });
};

var crmList = crms.map((item) => item.type);

var get_crm_integration_for_automation = (callback) => {
    ClientActions.get(function (Client) {
        var integrationId;
        for (var i = 0; i < Client.working.integrationsList.length; i++) {
            if (crmList.indexOf(Client.working.integrationsList[i].type) > -1) {
                integrationId = Client.working.integrationsList[i].integrationId;
                break;
            }
        }

        callback(integrationId);
    });
};

var next_card_part2 = (nextPath) => {
    // Figures out whether the current card should trigger a stop
    var endAfterCard = false;
    if (
        Workflow.active.card.awaitResponse ||
        ["solved", "escalate", "reassign"].indexOf(Workflow.active.card.type) > -1 ||
        Workflow.active.card.to === undefined ||
        Workflow.active.card.to.length === 0
    )
        endAfterCard = true;

    var workflowStatus = Workflow.active.card.awaitResponse ? "awaiting response" : "complete";

    // Gets next card
    if (Workflow.active.card !== undefined && Workflow.active.card.to !== undefined) {
        Workflow.ticketWorkflow.current = null;
        var indexZeroId;
        for (var i = 0; i < Workflow.active.card.to.length; i++) {
            if (Workflow.active.card.to[i].index === nextPath) {
                Workflow.ticketWorkflow.current = Workflow.active.card.to[i].id;
                break;
            } else if (Workflow.active.card.to[i].index === 0) indexZeroId = Workflow.active.card.to[i].id;
        }

        if (Workflow.ticketWorkflow.current === null) Workflow.ticketWorkflow.current = indexZeroId;

        Workflow.direction = "forward";
        find_card(true);
    }

    if (endAfterCard) {
        WorkflowActions.stopWorkflow(workflowStatus);
    } else WorkflowActions.update();
};

class WorkflowStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { WorkflowStore: Workflow };
        this.listenToMany(WorkflowActions);
    }

    onLoadSummary() {
        if (Workflow.summaryUpdate === undefined) {
            Workflow.summaryUpdate = db.collection("workflows").onSnapshot(function (snapshot) {
                Workflow.summary = firestore.extract(snapshot);
                WorkflowActions.update();
            });
        }
    }

    onNew(data) {
        make_new(data);
    }

    onLoad(id, data = {}, blockView, ticketlessMode) {
        var ref;
        const clientId = data.clientId || url.get_client();

        if (clientId === null) {
            ref = db.collection("workflows").doc(id);
        } else {
            Workflow.clientSelected = clientId;

            ref = db.collection("clients").doc(clientId).collection("workflows").doc(id);
        }

        ref.get()
            .then(function (snapshot) {
                Workflow.working = firestore.extract_single(snapshot);
                Workflow.working.currentVersion = Workflow.working.currentVersion || 1;
                Workflow.working.maxVersion = Workflow.working.maxVersion || Workflow.working.currentVersion;

                console.log("loaded workflow!");
                console.log(Workflow.working);

                if (Workflow.working.maxID === undefined) Workflow.working.maxID = 0;
                if (!blockView) {
                    UIActions.showOverlay("");
                }

                if (Workflow.ticketWorkflow !== undefined) Workflow.ticketWorkflow.version = Workflow.working.currentVersion;

                // Gets cards

                ref.collection("versions")
                    .doc("version_" + Workflow.working.currentVersion)
                    .get()
                    .then((snapshot) => {
                        var version = firestore.extract_single(snapshot);
                        Workflow.working.cards =
                            [null, undefined].indexOf(version) === -1 && version.cards !== undefined ? version.cards : [];

                        Workflow.working.editingVersion =
                            [null, undefined].indexOf(version) === -1 && version.version !== undefined
                                ? version.version
                                : Workflow.working.currentVersion;

                        Workflow.working.cards = list.order(Workflow.working.cards, "x shortest", "coordinates");
                        Workflow.working.cards = list.order(Workflow.working.cards, "y shortest", "coordinates");

                        if (Workflow.workingUpdate === undefined) setup_live_updaters();
                        if (Workflow.ticketWorkflow !== undefined) find_card();
                        WorkflowActions.update();

                        if (ticketlessMode) {
                            UserActions.get((User) => {
                                var newRef = db.collection("clients").doc(clientId).collection("appliedWorkflows").doc();

                                var appliedWorkflow = {
                                    id: newRef.id,
                                    workflowId: id,
                                    clientId: clientId,
                                    userId: User.me.id,
                                    date: new Date(),
                                    ticketless: true,
                                    concepts: {},
                                    responses: [],
                                };

                                WorkflowActions.setTicketWorflow(appliedWorkflow, {
                                    clientId: clientId,
                                });
                            });
                        }
                    });

                /*
				ref
					.collection("cards")
					.get()
					.then(function(snapshot) {
						Workflow.working.cards = firestore.extract(snapshot);
						Workflow.working.cards = list.order(Workflow.working.cards, "x shortest", "coordinates");
						Workflow.working.cards = list.order(Workflow.working.cards, "y shortest", "coordinates");
                        
                        Workflow.working.editingVersion = 1;

						if (Workflow.workingUpdate === undefined) setup_live_updaters();
						if (Workflow.ticketWorkflow !== undefined) find_card();
						WorkflowActions.update();
					})
                    .catch(WorkflowActions.loadFailed);
                     */
            })
            .catch(WorkflowActions.error);
    }

    onLoadVersion(version) {
        var ref;
        const clientId = url.get_client();

        if (clientId === null) {
            ref = db.collection("workflows").doc(Workflow.working.id);
        } else {
            Workflow.clientSelected = clientId;

            ref = db.collection("clients").doc(clientId).collection("workflows").doc(Workflow.working.id);
        }

        Workflow.working.editingVersion = version;

        ref.collection("versions")
            .doc("version_" + Workflow.working.editingVersion)
            .get()
            .then((snapshot) => {
                var version = firestore.extract_single(snapshot);
                Workflow.working.cards = [null, undefined].indexOf(version) === -1 && version.cards !== undefined ? version.cards : [];

                Workflow.working.editingVersion = version.version;

                Workflow.working.cards = list.order(Workflow.working.cards, "x shortest", "coordinates");
                Workflow.working.cards = list.order(Workflow.working.cards, "y shortest", "coordinates");

                if (Workflow.workingUpdate === undefined) setup_live_updaters();
                if (Workflow.ticketWorkflow !== undefined) find_card();
                WorkflowActions.update();
            });
    }

    onSave(type, newVersion, switchToNewVersion) {
        if (newVersion) {
            Workflow.working.maxVersion++;
            Workflow.working.editingVersion = Workflow.working.maxVersion;
        }
        if (switchToNewVersion) Workflow.working.currentVersion = Workflow.working.maxVersion;

        var temp = objects.clone(Workflow.working);
        var tempCards = objects.clone(temp.cards);
        temp.updated = new Date();
        delete temp.id;
        delete temp.cards;

        var ref;
        if (document.location.href.indexOf("clients") === -1 || type === "template") {
            if (["new", "template"].indexOf(type) > -1) ref = db.collection("workflows").doc();
            else ref = db.collection("workflows").doc(Workflow.working.id);
        } else {
            const clientId = url.get_client();
            if (["new"].indexOf(type) > -1) ref = db.collection("clients").doc(clientId).collection("workflows").doc();
            else ref = db.collection("clients").doc(clientId).collection("workflows").doc(Workflow.working.id);
        }

        if (["new", "template"].indexOf(type) > -1) temp.created = new Date();

        ref.set(temp, { merge: true })
            .then(function () {
                if (type === "new") {
                    Workflow.working.id = ref.id;
                    UIActions.showRouter(get_url() + "/" + ref.id);
                }
            })
            .catch(WorkflowActions.saveFailed);

        if (tempCards !== undefined) {
            var obj = {
                version: Workflow.working.editingVersion,
                cards: tempCards,
                updated: new Date(),
            };

            ref.collection("versions")
                .doc("version_" + obj.version)
                .set(obj, { merge: true })
                .then(() => {
                    WorkflowActions.saveCompleted();
                });
            /*
			for (var i = 0; i < tempCards.length; i++) {
				temp = objects.clone(tempCards[i]);
				delete temp.id;

				ref
					.collection("cards")
					.doc(Workflow.working.cards[i].id)
					.set(temp, { merge: true })
					.then(function() {
						counter--;
						if (counter === 0) 
					});
            }
            */
        } else WorkflowActions.saveCompleted();
    }

    onSaveCompleted(result) {
        UIActions.addMessage("Workflow saved!", "success");
        this.notify();
    }

    onSaveFailed(result) {
        UIActions.addMessage("Couldn't save workflow :(.", "error");
        this.notify();
    }

    onDelete() {
        WorkflowActions.unsubscribe();
        const clientId = url.get_client();
        const id = Workflow.working.id;
        delete Workflow.working;

        var ref;
        if (document.location.href.indexOf("clients") === -1) {
            ref = db.collection("workflows").doc(id);
        } else {
            ref = db.collection("clients").doc(clientId).collection("workflows").doc(id);
        }

        ref.delete().then(WorkflowActions.deleteCompleted).catch(WorkflowActions.deleteFailed);

        UIActions.showRouter(get_url());
        this.notify();
    }

    onDeleteCompleted(result) {
        UIActions.addMessage("Workflow deleted!", "success");
        this.notify();
    }

    onDeleteFailed(result) {
        UIActions.addMessage("Couldn't delete workflow :(.", "error");
        this.notify();
    }

    onAdd(field, location, obj) {
        //ensure_ids(Workflow.working, obj);
        //update_source_obj(obj);
        //update_offsets(obj, 1);

        if (document.location.hash.indexOf("ticketworkflows") > -1) objects.add(Workflow.active.card, field, location, obj);
        else objects.add(Workflow.working, field, location, obj);

        //if (obj.coordinates !== undefined) update_max_offsets();

        this.notify();
    }

    onAddCard(obj) {
        if (Workflow.working.cards === undefined) Workflow.working.cards = [];

        const clientId = url.get_client();

        var ref = db.collection("clients").doc(clientId).collection("workflows").doc(Workflow.working.id).collection("cards").doc();

        obj.id = ref.id;
        ensure_ids(Workflow.working, obj);
        add_card_updates(obj);
        //if (obj.type !== "solved") update_offsets(obj, 1);

        Workflow.working.cards.push(obj);
        Workflow.working.cards = list.order(Workflow.working.cards, "x shortest", "coordinates");
        Workflow.working.cards = list.order(Workflow.working.cards, "y shortest", "coordinates");

        this.notify();
        //WorkflowActions.save();
    }

    onAddPath(location) {
        var result = objects.find(Workflow.working, null, location.concat());
        if (result.obj !== undefined) result = result.obj;
        add_path(result);
        this.notify();
    }

    onRemove(location) {
        objects.remove(Workflow.working, location.concat());
        this.notify();
    }

    onRemoveCard(location) {
        var result = objects.find(Workflow.working, null, location.concat());
        if (result.obj !== undefined) result = objects.clone(result.obj);

        //update_offsets_remove(result);
        remove_card_updates(result);
        var clientId = url.get_client();

        save_cards();

        db.collection("clients").doc(clientId).collection("workflows").doc(Workflow.working.id).collection("cards").doc(result.id).delete();

        this.notify();
    }

    onRemovePath(location, index) {
        var result = objects.find(Workflow.working, null, location.concat());
        if (result.obj !== undefined) result = result.obj;
        remove_path(result, index);
        this.notify();
    }

    onSetField(field, location, value, shouldNotify) {
        if (document.location.hash.indexOf("ticketworkflows") > -1 || document.location.hash.indexOf("ticketlessworkflows") > -1)
            setField(Workflow.active.card, field, location, value);
        else setField(Workflow.working, field, location, value);

        if (shouldNotify) this.notify();
    }

    onSetFieldResponse(field, location, value, shouldNotify) {
        console.log("SETTING FIELD!!!!");
        console.log(field);
        console.log(value);

        setField(Workflow.active.response, field, location, value);

        if (["internal_note"].indexOf(Workflow.active.card.type) === -1) {
            if (["multiple choice", "checkbox", "dropdown"].indexOf(Workflow.active.card.type) > -1) {
                var textValue = Array.isArray(value) ? [] : "";
                for (var i = 0; i < Workflow.active.card.responses.length; i++) {
                    if (Array.isArray(value) && value.indexOf(Workflow.active.card.responses[i].id) > -1)
                        textValue.push(dom.strip_outer(Workflow.active.card.responses[i].text));
                    else if (!Array.isArray(value) && value === Workflow.active.card.responses[i].id)
                        textValue += dom.strip_outer(Workflow.active.card.responses[i].text);
                }

                Workflow.ticketWorkflow.concepts[Workflow.active.card.concept] = textValue;
            } else {
                Workflow.ticketWorkflow.concepts[Workflow.active.card.concept] = dom.strip_outer(Workflow.active.response.value || value);
            }
        }

        if (shouldNotify) this.notify();
    }

    onConnectCard(connecting, index, targetID) {
        connect_card(connecting, index, targetID);
        this.notify();
    }

    onDisconnectCard(id, targetID, index) {
        var target = objects.find(Workflow.working, null, [id]);
        var i;
        if (target.obj !== undefined && target.obj.to !== undefined && Array.isArray(target.obj.to)) {
            for (i = 0; i < target.obj.to.length; i++) {
                if (target.obj.to[i].id === targetID && target.obj.to[i].index === index) {
                    target.obj.to.splice(i, 1);
                    break;
                }
            }
        }

        target = objects.find(Workflow.working, null, [targetID]);
        if (target.obj !== undefined && target.obj.from !== undefined && Array.isArray(target.obj.from)) {
            for (i = 0; i < target.obj.from.length; i++) {
                if (target.obj.from[i].id === targetID && target.obj.from[i].index === index) {
                    target.obj.from.splice(i, 1);
                    break;
                }
            }
        }
        this.notify();
    }

    onSetTicketWorflow(obj, active) {
        if (Workflow.ticketWorkflow === undefined || Workflow.active.ticketId !== active.ticketId) {
            Workflow.ticketWorkflow = obj;
            if (Workflow.working !== undefined) Workflow.ticketWorkflow.version = Workflow.working.currentVersion;
            Workflow.active = active;
            find_card();
            this.notify();
        }
    }

    onSetAttachment(type, attachments, attachmentTokens) {
        if (Workflow.active.card !== undefined) {
            if (type === "zendesk") {
                Workflow.active.card.attachments = Workflow.active.card.attachments || [];
                Workflow.active.card.attachmentTokens = Workflow.active.card.attachmentTokens || [];

                Workflow.active.card.attachments = Workflow.active.card.attachments.concat(attachments);
                Workflow.active.card.attachmentTokens = Workflow.active.card.attachmentTokens.concat(attachmentTokens);
            } else {
                Workflow.active.card.attachments = Workflow.active.card.attachments || [];
                Workflow.active.card.attachments = Workflow.active.card.attachments.concat(attachments);
            }
        }

        this.notify();
    }

    onNextCard(nextPath) {
        Workflow.active.response.finish = new Date();

        if (Workflow.active.card.type === "escalate") {
            if (Workflow.active.card.value === "internal") TicketActions.escalate("escalate", "", true);
            else if (Workflow.active.card.value === "external") TicketActions.escalate("escalate external", "", true);
            return;
        }

        // Handles all submissions from current card
        if (Workflow.active.card.type === "internal_note") {
            if (!Workflow.active.card.zendesk) TicketActions.addInternalNote({ text: Workflow.active.response.value, escalate: false });
            else {
                TicketActions.setField("public", undefined, false, false, true);
                TicketActions.setField("internal", undefined, false, false, true);
                TicketActions.setField("text", undefined, Workflow.active.response.value, false, true);
                TicketActions.submit(null, "workflows", false);
            }
        } else if (Workflow.active.card.type === "reassign") {
            var obj = {
                text: "",
                assignee_id: Workflow.active.card.value,
            };

            TicketActions.setFullReply(obj);
        } else if (["set status", "email"].indexOf(Workflow.active.card.type) > -1) {
            TicketActions.setFullReply(Workflow.active.card);
        } else if (Workflow.active.card.type === "new email" && !Workflow.active.card.done) {
            Workflow.active.card.done = false;
            run_new_email_automation();
            this.notify();
            return null;
        }

        // Handles ticket submit
        if (["reassign"].indexOf(Workflow.active.card.type) > -1) {
            TicketActions.submit("open", "workflows", false, nextPath);
        } else if (["reassign", "email", "set status"].indexOf(Workflow.active.card.type) > -1) {
            TicketActions.submit(Workflow.active.card.external_status, "workflows", false, nextPath);
        } else if (Workflow.active.card.type === "custom email") send_custom_email();

        if (["reassign", "email", "set status"].indexOf(Workflow.active.card.type) === -1) next_card_part2(nextPath);
    }

    onContinueNextCard(nextPath) {
        next_card_part2(nextPath);
    }

    onPreviousCard() {
        Workflow.ticketWorkflow.responses.splice(Workflow.ticketWorkflow.responses.length - 1, 1);
        Workflow.ticketWorkflow.current = Workflow.ticketWorkflow.responses[Workflow.ticketWorkflow.responses.length - 1].card;
        Workflow.direction = "back";
        find_card(false);
        this.notify();
    }

    onUnsubscribe() {
        if (Workflow.workingUpdate !== undefined) for (var i = 0; i < Workflow.workingUpdate.length; i++) Workflow.workingUpdate[i]();

        delete Workflow.workingUpdate;
    }

    onStopWorkflow(status) {
        TicketActions.unsubscribe();
        WorkflowActions.unsubscribe();
        delete Workflow.working;

        if (!Workflow.ticketWorkflow.ticketless) {
            db.collection("clients")
                .doc(Workflow.active.clientId)
                .collection("tickets")
                .doc(Workflow.active.ticketId)
                .update({ active_agent: firebase.firestore.FieldValue.delete() })
                .then(function () {
                    TicketActions.saveAppliedWorkflow(Workflow.ticketWorkflow);
                    TicketActions.stopWorkflow(status);
                });
        } else {
            UserActions.addEvent(
                {
                    clientId: Workflow.ticketWorkflow.clientId,
                    ticketId: "",
                    action: "stop ticketless workflow",
                    status: "",
                    start: Workflow.ticketWorkflow.date,
                    value: Workflow.ticketWorkflow.id,
                },
                0
            );

            api(
                "post",
                "/clients/" + Workflow.ticketWorkflow.clientId + "/ticketlessworkflows/" + Workflow.ticketWorkflow.id,
                {
                    id: Workflow.ticketWorkflow.id,
                    clientId: Workflow.ticketWorkflow.clientId,
                    workflowId: Workflow.ticketWorkflow.workflowId,
                    userId: Workflow.ticketWorkflow.userId,
                    date: Workflow.ticketWorkflow.date,
                },
                WorkflowActions.update,
                WorkflowActions.error,
                Workflow
            );

            fix_underscores(Workflow.ticketWorkflow);

            db.collection("clients")
                .doc(Workflow.ticketWorkflow.clientId)
                .collection("appliedWorkflows")
                .doc(Workflow.ticketWorkflow.id)
                .set(Workflow.ticketWorkflow)
                .then(() => {
                    delete Workflow.ticketWorkflow;
                    WorkflowActions.update();
                });
        }
    }

    onClose(mode) {
        WorkflowActions.unsubscribe();
        Workflow.loadedParts = [];
        Workflow.allLoaded = false;
        delete Workflow.working;
        this.notify();
    }

    onGet(callback) {
        callback(Workflow, this.notify);
    }

    onUpdate() {
        this.notify();
    }

    onError(error) {
        UIActions.addMessage(error, "error");
    }

    notify() {
        console.log("notify workflow");
        var working = Workflow.working !== undefined ? objects.clone(Workflow.working) : undefined;
        var active = Workflow.active !== undefined ? objects.clone(Workflow.active) : undefined;
        var ticketWorkflow = Workflow.ticketWorkflow !== undefined ? objects.clone(Workflow.ticketWorkflow) : undefined;

        this.setState({
            WorkflowStore: {
                summary: Workflow.summary,
                status: Workflow.status,
                working: working,
                active,
                ticketWorkflow,
                direction: Workflow.direction,
            },
        });
    }
}

export default WorkflowStore;
