import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Toggle from "../forms/Toggle.react";
import Dropdown from "../forms/Dropdown.react";
import Input from "../forms/Input.react";

// Functions
import * as objects from "../../../functions/objects";

const toggleLabelStyle = {
    fontSize: "12px",
    marginLeft: "8px",
    display: "inline-block",
    verticalAlign: "middle",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "4px",
};

const toggleRowStyle = {
    display: "inline-block",
    marginLeft: "16px",
    textAlign: "left",
    padding: "0px 8px 16px",
};

const rowStyle = {
    width: "calc(100% - 16px)",
    display: "inline-block",
    textAlign: "center",
    padding: "0px 8px",
};

const options = [
    {
        text: "Submit as new",
        value: "new",
    },
    {
        text: "Submit as open",
        value: "open",
    },
    {
        text: "Submit as pending",
        value: "pending",
    },
    {
        text: "Submit as on hold",
        value: "hold",
    },
    {
        text: "Submit as solved",
        value: "solved",
    },
];

const submitStyle = {
    width: "300px",
    display: "inline-block",
};

const checkingStyle = {
    width: "100%",
    padding: "0px 0px 16px",
    textAlign: "center",
};

const responseStyle = {
    borderRadius: "10px",
    width: "calc(100% - 48px - 32px)",
    marginLeft: "24px",
};

class EmailCard extends React.Component {
    constructor(props) {
        super(props);
        this._onOpenReply = this._onOpenReply.bind(this);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this.state = {
            nextPath: 0,
            customTicketID: this.props.item.customTicketID,
            requester_email: this.props.item.requester_email,
            requester_name: this.props.item.requester_name,
            subject: this.props.item.subject,
        };
    }

    componentDidMount() {
        var obj = this;
        if (this.props.agentMode)
            setTimeout(function () {
                obj._onOpenReply();
            }, 300);
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onOpenReply() {
        var agentMode = this.props.agentMode;
        var location = [this.props.item.id];
        WorkflowActions.get(function (Workflow) {
            var obj;
            if (agentMode) obj = Workflow.active.card;
            else {
                obj = objects.find(Workflow.working, null, location);
                if (obj !== null && obj.obj !== undefined) obj = obj.obj;
            }

            if ([undefined, null].indexOf(obj) === -1) UIActions.showOverlay("reply editor", obj);
            else UIActions.addMessage("Something went wrong :(", "error");
        });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        WorkflowActions.setField(field, location, value, true);
    }

    render() {
        const responseTextPreviewStyle = {
            width: "calc(100% - 32px)",
            margin: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
            fontStyle: "italic",
            color: "rgba(0, 0, 0, 0.54)",
            maxHeight: "250px",
        };

        return (
            <Card
                id={this.props.item.id}
                item={this.props.item}
                picture={this.props.client !== undefined ? this.props.client.picture : ""}
                name={this.props.client !== undefined ? this.props.client.name : ""}
                first={this.props.first}
                onMoveView={this.props.onMoveView}
                targetFocus={this.props.targetFocus}
                agentMode={this.props.agentMode}
                onCardConnect={this.props.onCardConnect}
                connecting={this.props.connecting}
                valid={true}
                nextPath={this.state.nextPath}
                noNextButton={this.props.status.indexOf("submitting ticket") > -1}
            >
                {this.props.status.indexOf("submitting ticket") > -1 ? (
                    <div>
                        <Loading height="200px" />
                        <div style={checkingStyle}>Submitting ticket...</div>
                    </div>
                ) : (
                    <div>
                        {!this.props.agentMode ? (
                            <div>
                                <Input
                                    id={"custom-ticket-id-" + this.props.item.id}
                                    label={"Custom Ticket ID"}
                                    value={this.state.customTicketID}
                                    field="customTicketID"
                                    location={[this.props.item.id]}
                                    updateFn={this._updateFn}
                                    onBlur={this._onBlur}
                                    colored={true}
                                />
                                <Input
                                    id={"requester-" + this.props.item.id}
                                    label={"Requester email"}
                                    value={this.state.requester_email}
                                    field="requester_email"
                                    location={[this.props.item.id]}
                                    updateFn={this._updateFn}
                                    onBlur={this._onBlur}
                                    colored={true}
                                />
                                <Input
                                    id={"requester-" + this.props.item.id}
                                    label={"Requester name"}
                                    value={this.state.requester_name}
                                    field="requester_name"
                                    location={[this.props.item.id]}
                                    updateFn={this._updateFn}
                                    onBlur={this._onBlur}
                                    colored={true}
                                />
                                <Input
                                    id={"subject-" + this.props.item.id}
                                    label={"Subject"}
                                    value={this.state.subject}
                                    field="subject"
                                    location={[this.props.item.id]}
                                    updateFn={this._updateFn}
                                    onBlur={this._onBlur}
                                    colored={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {!this.props.agentMode ? (
                            <div style={toggleRowStyle}>
                                <Toggle
                                    id={"toggle-" + this.props.item.id}
                                    field="awaitResponse"
                                    location={[this.props.item.id]}
                                    updateFn={this._onBlur}
                                    value={this.props.item.awaitResponse || false}
                                />
                                <div style={toggleLabelStyle}>
                                    {this.props.item.awaitResponse
                                        ? "Wait for response before proceeding"
                                        : "Proceed to next card immediately"}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div style={toggleRowStyle}>
                            <Toggle
                                id={"public-" + this.props.item.id}
                                field="public"
                                location={[this.props.item.id]}
                                updateFn={this._onBlur}
                                value={this.props.item.public || false}
                            />
                            <div style={toggleLabelStyle}>
                                {this.props.item.public ? "Email will be sent to requester" : "Will be posted as Internal note"}
                            </div>
                        </div>
                        <div
                            style={responseTextPreviewStyle}
                            dangerouslySetInnerHTML={{
                                __html: this.props.item.text || "Enter response text here",
                            }}
                            onClick={this._onOpenReply}
                        />
                        {this.props.agentMode ? (
                            ""
                        ) : (
                            <div style={rowStyle}>
                                <Dropdown
                                    id={"email-status-dropdown-" + this.props.item.id}
                                    field="external_status"
                                    label="Submit As"
                                    location={[this.props.item.id]}
                                    value={this.props.item.external_status || ""}
                                    items={options}
                                    updateFn={this._onBlur}
                                    style={submitStyle}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Card>
        );
    }
}

export default EmailCard;
