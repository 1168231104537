import React from "react";

// Functions
import m from "../../../functions/m";

class Checkbox extends React.Component {
	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
		this.state = { value: this.props.value === undefined ? false : this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value) this.setState({ value: nextProps.value });
	}

	_onChange(event) {
		this.setState({ value: this.state.value ? false : true });
		if (this.props.updateFn !== undefined) this.props.updateFn(this.props.field, this.props.location, this.props.id);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "16px",
			height: "16px",
			border: "1px solid rgb(70, 180, 175)",
			borderRadius: "4px",
			cursor: "pointer",
			transition: "all .3s ease"
		};

		const checkStyle = {
			fontSize: "16px",
			color: this.state.value ? "rgb(70, 180, 175)" : "transparent",
			transition: "all .3s ease"
		};

		return (
			<div id={this.props.id} style={m(primaryStyle, this)} onClick={this._onChange}>
				<i style={checkStyle} className="material-icons">
					check
				</i>
			</div>
		);
	}
}

export default Checkbox;
