import React from "react";

// Scaffolding
import Container from "./Container.react";
import OuterScaffold from "./OuterScaffold.react";
import SectionCard from "./SectionCard.react";
import Button from "./buttons/Button.react";
import CardTag from "./CardTag.react";
import Filter from "./Filter.react";
import Row from "./Row.react";
import Cell from "./Cell.react";
import Viewer from "./Viewer.react";

// Functions
import * as time from "../../functions/time.js";
import * as firestore from "../../functions/firestore";

const headerStyle = {
	display: "inline-block",
	borderBottom: "1px solid cadetblue",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 1)",
	padding: "8px 0px"
};

const buttonRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const buttonStyle = {
	margin: "0px 8px"
};

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class FlaggedSingleTicket extends React.Component {
	render() {
		const iconStyle = {
			color: this.props.item.userReviewed ? "rgb(70, 180, 175)" : "grey",
			marginLeft: "14px",
			padding: "2px",
			border: "1px solid",
			borderRadius: "50%",
			fontSize: "14px"
		};

		var color = "inherit";
		if (this.props.item.reason === "problem") color = "indianred";
		else if (this.props.item.reason === "praise") color = "green";

		var reasonStyle = {
			color
		};

		return (
			<Row
				index={this.props.index}
				style={rowStyle}
				href={
					this.props.showUrl
						? "#clients/" + this.props.item.clientId + "/tickets/" + this.props.item.ticketId
						: undefined
				}
				newTab={true}
			>
				<Cell size="xs" wrap={true}>
					<Viewer item={this.props.badge || {}} />
				</Cell>
				<Cell size="xl" wrap={true}>
					<div
						dangerouslySetInnerHTML={{
							__html: this.props.item.text || "Enter response text here"
						}}
					/>
				</Cell>
				<Cell size="xs">
					<i className="material-icons" style={iconStyle}>
						check
					</i>
				</Cell>
				<Cell size="s">
					{this.props.item.reason !== undefined ? (
						<span style={reasonStyle}>
							{this.props.item.reason.charAt(0).toUpperCase() + this.props.item.reason.substr(1, 1000)}
						</span>
					) : (
						""
					)}
				</Cell>
				<Cell size="s">{time.parse_time(this.props.item.date)}</Cell>
			</Row>
		);
	}
}

class FlaggedTickets extends React.Component {
	constructor(props) {
		super(props);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this._onFilter = this._onFilter.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNextPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			query.startAfter = this.props.cursor.pointers[this.props.cursor.page];
			this.props.actions.loadSubcollection(this.props.cursor.type, query, 0, 1);
			window.scrollTo(0, 0);
		}
	}

	_onPreviousPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			if (this.props.cursor.page === 1) delete query.startAfter;
			else query.startAfter = this.props.cursor.pointers[this.props.cursor.page - 2];
			this.props.actions.loadSubcollection(this.props.cursor.type, query, 0, -1);
			window.scrollTo(0, 0);
		}
	}

	_onFilter(view, type) {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			delete query.startAfter;
			if (query.orderBy[0][0] === type) query.orderBy[0][1] = query.orderBy[0][1] === "desc" ? "asc" : "desc";
			else query.orderBy[0] = [type, "asc"];

			this.props.actions.loadSubcollection(this.props.cursor.type, query, 0);
			window.scrollTo(0, 0);
		}
	}

	render() {
		const integrationsStyle = {
			width: "100%",
			textAlign: "left",
			margin: "24px 0px",
			display: "inline-block"
		};

		const emptyStyle = {
			textAlign: "center",
			display: "inline-block",
			verticalAlign: "middle",
			margin: "24px 0px",
			width: "calc(100% - 48px - 32px)",
			padding: "24px",
			border: "1px dashed lightgrey",
			borderRadius: "4px"
		};

		const emptyTextStyle = {
			fontSize: "24px",
			fontWeight: "400",
			marginBottom: "20px"
		};

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={false} verticalAlign="top" marginTop="-150px">
					<SectionCard
						title={"Flagged Tickets"}
						subtext={"Showing " + this.props.tickets.length + " flagged tickets."}
						maxWidth="calc(100% - 48px)"
						width="calc(100% - 48px)"
					>
						{this.props.tickets === undefined || this.props.tickets.length === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no flagged tickets</div>
							</div>
						) : (
							<div style={integrationsStyle}>
								<div style={headerStyle}>
									<Cell size="xs">
										{this.props.badgeField.charAt(0).toUpperCase() + this.props.badgeField.substr(1, 1000)}
									</Cell>
									<Cell size="xl">Note</Cell>
									<Cell size="xs">Viewed</Cell>
									<Cell size="s">Reason</Cell>
									<Cell size="s">
										Date <Filter type="start" view={this.props.cursor.type} onClick={this._onFilter} />
									</Cell>
								</div>

								{this.props.tickets.map((item, index, arr) => (
									<FlaggedSingleTicket
										key={item.id + "-" + index}
										index={index}
										item={item}
										showUrl={true}
										badge={item[this.props.badgeField] || {}}
									/>
								))}
								<div style={buttonRow}>
									{this.props.cursor.page > 0 ? (
										<Button text={"Previous Page"} onClick={this._onPreviousPage} style={buttonStyle} />
									) : (
										""
									)}
									{this.props.tickets.length === 25 ? (
										<Button text={"Next Page"} onClick={this._onNextPage} style={buttonStyle} />
									) : (
										""
									)}
								</div>
								<CardTag text={"Page " + ((this.props.cursor.page || 0) + 1)} color="orange" />
							</div>
						)}
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default FlaggedTickets;
