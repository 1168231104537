import * as firebase from "firebase/app";
import "firebase/firestore";
import * as objects from "./objects";

// Defines db for firestore
var db = firebase.firestore();

var extract = snapshot => {
	var data = [];
	var obj;
	snapshot.forEach(function(doc) {
		obj = doc.data();
		obj.id = doc.id;
		data.push(obj);
	});

	return data;
};

var extract_single = snapshot => {
	var data = snapshot.data();
	if (data !== undefined) data.id = snapshot.id;

	return data;
};

var get_subcollections = (ref, obj, subcollections, callback, callbackProp) => {
	if (subcollections.length > 0) {
		var single = subcollections.splice(0, 1)[0];
		ref
			.collection(single)
			.get()
			.then(function(snapshot) {
				obj[single] = extract(snapshot);
				get_subcollections(ref, obj, subcollections, callback, callbackProp);
			});
	} else callback(obj, callbackProp);
};

var clone_query = query => {
	var newQuery = {
		path: query.path
	};

	if (query.where !== undefined) newQuery.where = objects.clone(query.where);
	if (query.orderBy !== undefined) newQuery.orderBy = objects.clone(query.orderBy);
	if (query.startAfter !== undefined) newQuery.startAfter = query.startAfter;
	if (query.limit !== undefined) newQuery.limit = query.limit;
	return newQuery;
};

var query_builder = query => {
	console.log(query);
	var path = query.path.split("/");
	var ref = db.collection(path[0]);
	var i;

	console.log("QUERY BUILDER");
	console.log(query);
	console.log(path);
	// Sets  path
	for (i = 1; i < path.length; i++) {
		if (i % 2 === 0) ref = ref.collection(path[i]);
		else ref = ref.doc(path[i]);
	}

	if (query.where !== undefined) {
		for (i = 0; i < query.where.length; i++) ref = ref.where(query.where[i][0], query.where[i][1], query.where[i][2]);
	}

	if (query.orderBy !== undefined) {
		for (i = 0; i < query.orderBy.length; i++) ref = ref.orderBy(query.orderBy[i][0], query.orderBy[i][1]);
	}

	if (query.startAfter !== undefined) ref = ref.startAfter(query.startAfter);
	if (query.limit !== undefined) ref = ref.limit(query.limit);

	return ref;
};

var query_compare = (oldQuery, newQuery) => {
	if (oldQuery === undefined || newQuery === undefined) return false;
	if (oldQuery.path !== newQuery.path) return false;
	if (oldQuery.limit !== newQuery.limit) return false;
	if (JSON.stringify(oldQuery.where) !== JSON.stringify(newQuery.where)) return false;
	if (JSON.stringify(oldQuery.orderBy) !== JSON.stringify(newQuery.orderBy)) return false;

	return true;
};

export { extract, extract_single, get_subcollections, query_builder, query_compare, clone_query };
