import integrations from "../integrations";

// Cards
import FreeResponseCard from "../components/_common/cards/FreeResponseCard.react";
import NumericCard from "../components/_common/cards/NumericCard.react";
import MultipleChoiceCard from "../components/_common/cards/MultipleChoiceCard.react";
import DropdownCard from "../components/_common/cards/DropdownCard.react";
import CheckboxCard from "../components/_common/cards/CheckboxCard.react";
import EmailCard from "../components/_common/cards/EmailCard.react";
import DateTimeCard from "../components/_common/cards/DateTimeCard.react";
import InternalNoteCard from "../components/_common/cards/InternalNoteCard.react";
import ReassignCard from "../components/_common/cards/ReassignCard.react";
import EscalateCard from "../components/_common/cards/EscalateCard.react";
import TicketSolvedCard from "../components/_common/cards/TicketSolvedCard.react";
import TableCard from "../components/_common/cards/TableCard.react";
import ZendeskNewTicket from "../components/_common/cards/ZendeskNewTicket.react";
import EmailSetStatus from "../components/_common/cards/EmailSetStatus.react";
import ZendeskField from "../components/_common/cards/ZendeskField.react";
import ProductsCard from "../components/_common/cards/ProductsCard.react";
import FormCard from "../components/_common/cards/FormCard.react";
import RPACard from "../components/_common/cards/RPACard.react";
import GoogleSheetsCard from "../components/_common/cards/GoogleSheetsCard.react";
import GoogleLocationCard from "../components/_common/cards/GoogleLocationCard.react";
import CustomEmailCard from "../components/_common/cards/CustomEmailCard.react";
import MakeCommentPrivateCard from "../components/_common/cards/MakeCommentPrivateCard.react";

import IntegrationCard from "../components/_common/cards/IntegrationCard.react";

/*
import DatabaseCard from "../components/_common/cards/DatabaseCard.react";
import StripeCard from "../components/_common/cards/StripeCard.react";
import ShopifyCard from "../components/_common/cards/ShopifyCard.react";
import SpreeCard from "../components/_common/cards/SpreeCard.react";
import ShipwireCard from "../components/_common/cards/ShipwireCard.react";
import ShipstationCard from "../components/_common/cards/ShipstationCard.react";

import ShippingCard from "../components/_common/cards/ShippingCard.react";
import FulfilCard from "../components/_common/cards/FulfilCard.react";
import CustomAPICard from "../components/_common/cards/CustomAPICard.react";
*/

function get_card(type) {
    if (type === "free response") return FreeResponseCard;
    else if (type === "numeric") return NumericCard;
    else if (type === "datetime") return DateTimeCard;
    else if (type === "multiple choice") return MultipleChoiceCard;
    else if (type === "dropdown") return DropdownCard;
    else if (type === "checkbox") return CheckboxCard;
    else if (type === "email") return EmailCard;
    else if (type === "form") return FormCard;
    else if (type === "internal_note") return InternalNoteCard;
    else if (type === "reassign") return ReassignCard;
    else if (type === "solved") return TicketSolvedCard;
    else if (type === "escalate") return EscalateCard;
    else if (type === "table") return TableCard;
    else if (type === "new email") return ZendeskNewTicket;
    else if (type === "set status") return EmailSetStatus;
    else if (type === "zendesk field") return ZendeskField;
    else if (type === "products") return ProductsCard;
    else if (type === "rpa") return RPACard;
    else if (type === "google location") return GoogleLocationCard;
    else if (type === "google sheets") return GoogleSheetsCard;
    else if (type === "custom email") return CustomEmailCard;
    else if (type === "database") return IntegrationCard;
    else if (type === "private comment") return MakeCommentPrivateCard;
    // To support old card db card
    else {
        for (var i = 0; i < integrations.length; i++) {
            if (integrations[i].type === type) return IntegrationCard;
        }
    }

    return null;
    /*
    else if (type === "database") return DatabaseCard;
	else if (type === "stripe") return StripeCard;
	else if (type === "shopify") return ShopifyCard;
	else if (type === "spree") return SpreeCard;
	else if (type === "shipwire") return ShipwireCard;
	else if (type === "shipstation") return ShipstationCard;
	
	else if (type === "shipping") return ShippingCard;
	else if (type === "fulfil") return FulfilCard;
    else if (type === "custom api") return CustomAPICard;
    	else if (type === "custom email") return CustomEmailCard;

    else return null;
    */
}

export default get_card;
