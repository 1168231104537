import React from "react";

import Row from "../_common/Row.react";

// Functions
import * as time from "../../functions/time.js";

const cellSmallStyle = {
	width: "100px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis"
};

const rowStyle = {
	color: "rgba(0, 0, 0, 0.87)",
	padding: "16px 0px",
	fontSize: "14px",
	width: "100%"
};

class AgentSessionRow extends React.Component {
	constructor(props) {
		super(props);
		this._onToggleDetails = this._onToggleDetails.bind(this);
		this.state = { open: false };
	}

	_onToggleDetails() {
		this.setState({ open: this.state.open ? false : true });
	}

	render() {
		const start = time.parse_time(this.props.item.start);
		const end = time.parse_time(this.props.item.end);
		const duration = start !== "" && end !== "" ? time.duration(start, end) : "";

		return (
			<Row index={this.props.index} style={rowStyle}>
				<div style={cellMediumStyle}>{start}</div>
				<div style={cellMediumStyle}>{end}</div>
				<div style={cellSmallStyle}>{duration}</div>
			</Row>
		);
	}
}

export default AgentSessionRow;
