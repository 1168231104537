import React from "react";

const menuTextStyle = {
	fontSize: "16px",
	display: "inline-block",
	verticalAlign: "middle"
};

class MenuOption extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onSectionChange(this.props.item.value);
	}

	render() {
		const primaryStyle = {
			padding: "16px 0px",
			fontSize: "24px",
			color: this.props.selected || this.state.hover ? "rgb(70, 180, 175)" : "inherit",
			transition: "all .3s ease",
			cursor: "pointer",
			display: "inline-block"
		};

		const circleStyle = {
			backgroundColor: this.props.selected || this.state.hover || this.props.done ? "rgb(0, 0, 0, .3)" : "white",
			color: this.props.selected || this.state.hover || this.props.done ? "white" : "rgb(102, 51, 153)",
			fontSize: "14px",
			padding: "4px",
			display: "inline-block",
			verticalAlign: "middle",
			marginRight: "8px",
			borderRadius: "50%",
			width: "16px",
			textAlign: "center",
			transition: "all .3s ease",
			fontWeight: "700"
		};

		const lineStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			height: "2px",
			width: "150px",
			backgroundColor: this.props.done ? "rgb(0, 0, 0, .3)" : "white",
			borderRadius: "2px",
			margin: "0px 8px"
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.done ? (
					<i class="material-icons" style={circleStyle}>
						check
					</i>
				) : (
					<div style={circleStyle}>{this.props.index + 1}</div>
				)}
				<div style={menuTextStyle}>{this.props.item.text}</div>
				{!this.props.last ? <div style={lineStyle} /> : ""}
			</div>
		);
	}
}

class Stepper extends React.Component {
	render() {
		var selectedIndex = 0;
		for (var i = 0; i < this.props.items.length; i++) {
			if (this.props.items[i].value === this.props.value) {
				selectedIndex = i;
				break;
			}
		}

		const primaryStyle = {
			display: "inline-block",
			margin: "0px",
			verticalAlign: "middle"
		};

		console.log("SELECTED INDEX IS " + selectedIndex);

		return (
			<div style={primaryStyle}>
				{this.props.items.map((item, index) => (
					<MenuOption
						key={"stepper-option-" + index}
						item={item}
						selected={this.props.value === item.value}
						index={index}
						onSectionChange={this.props.onSectionChange}
						last={index === this.props.items.length - 1}
						done={selectedIndex > index}
						color={this.props.color}
						selectedColor={this.props.selectedColor}
					/>
				))}
			</div>
		);
	}
}

export default Stepper;
