import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import CardTag from "../_common/CardTag.react";
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import DataTag from "../_common/DataTag.react";

// Form items
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";

// Functions
import * as list from "../../functions/list";
import * as time from "../../functions/time";
import * as state from "../../functions/state";

const ticketSummaryRow = {
	width: "100%",
	display: "inline-block",
	margin: "32px 0px 0px",
	textAlign: "left",
	borderTop: "1px solid lightgrey",
	paddingTop: "32px"
};

const stateFields = ["website", "description"];

const inputStyle = {
	maxWidth: "800px"
};

class ClientDetails extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onOrder = this._onOrder.bind(this);
		this._onAddNewQueueRule = this._onAddNewQueueRule.bind(this);

		var myState = {
			scroll: 0,
			agentsOrder: "name",
			agents: list.order(this.props.item.agents, "name")
		};

		myState = state.set(myState, stateFields, this.props.item);
		this.state = myState;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillReceiveProps(nextProps) {
		var myState = { agents: list.order(nextProps.item.agents, this.state.agentsOrder) };
		myState = state.update(myState, stateFields, this.props.item, nextProps.item, this.state);
		this.setState(myState);
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value, validate: false });

		if (field === "active") ClientActions.toggleActive(field, location, value, true);
		else ClientActions.setField(field, location, value, true);
	}

	_onNewIntegration() {
		ClientActions.add("integrations", [], { type: "", apiKey: "" });
	}

	_onOrder(field, location, value) {
		var property;
		if (field === "viewing" && (value === "name" || value === "email")) property = "agent";

		var data = this.props.item[field];

		this.setState({ [field + "Order"]: value, [field]: list.order(data, value, property) });
	}

	_onAddNewQueueRule() {
		ClientActions.add("queueRules", undefined, {
			type: "requester_updated",
			direction: "asc"
		});
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="ABOUT" subtext={"Details about " + this.props.item.name}>
						<Input
							id="website"
							label="Website"
							field="website"
							updateFn={this._updateFn}
							onBlur={this._onBlur}
							value={this.state.website}
							style={inputStyle}
						/>
						<InputMultiline
							id="description"
							label="Description"
							field="description"
							onBlur={this._onBlur}
							value={this.state.description}
							style={inputStyle}
						/>
						<CardTag
							text={"A client since " + time.parse_time(this.props.item.created, true).toLocaleDateString()}
							color="orange"
						/>
						<div style={ticketSummaryRow}>
							<DataTag label="Queued tickets" value={this.props.item.queueCount} color="teal" width="160px" />
							<DataTag label="Escalated tickets" value={this.props.item.escalateCount} color="purple" width="160px" />
							<DataTag label="SLA expiring" value={this.props.item.slaCount} color="#59BBE0" width="160px" />
							<DataTag
								label="Queued workflows"
								value={this.props.item.workflowQueueCount}
								color="orange"
								width="160px"
							/>
							<DataTag
								label="CSAT Awaiting Review"
								value={this.props.item.csatCheckCount}
								color="indianred"
								width="180px"
							/>
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}
export default ClientDetails;
