import React from "react";

import Pill from "./Pill.react";
import Input from "./Input.react";

// Functions
import m from "../../../functions/m";

class Tokenizer extends React.Component {
	constructor(props) {
		super(props);
		this._onAddToken = this._onAddToken.bind(this);
		this._onClose = this._onClose.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { input: "" };
	}

	_onClose(field, location, value) {
		var items = this.props.value;
		for (var i = 0; i < items.length; i++) {
			if (items[i] === value) {
				items.splice(i, 1);
				break;
			}
		}

		this.props.updateFn(this.props.field, this.props.location, items);
	}

	_onAddToken(field, location, value, onReturn) {
		var lastChar = value.charAt(value.length - 1);
		var breakHit = false;

		if (
			this.props.breakCharacters !== undefined &&
			(this.props.breakCharacters.indexOf(lastChar) > -1 ||
				(onReturn && this.props.breakCharacters.indexOf("return") > -1))
		) {
			breakHit = true;
		} else if (
			this.props.breakCharacters === undefined &&
			(lastChar === " " || lastChar === "," || lastChar === ";" || onReturn)
		)
			breakHit = true;

		if (breakHit) {
			if (onReturn) value = value.substr(0, value.length);
			else value = value.substr(0, value.length - 1);

			if (this.props.value.indexOf(value) === -1 && value !== "")
				this.props.updateFn(this.props.field, this.props.location, this.props.value.concat(value));

			this.setState({ input: "" });
		} else this.setState({ input: value });
	}

	_onBlur(event) {
		if (this.state.input !== "") this._onAddToken(this.props.field, this.props.location, this.state.input, true);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "100%",
			minHeight: "38px"
		};

		const inputStyle = {
			width: "200px",
			margin: "0px",
			height: "auto"
		};

		var value = Array.isArray(this.props.value) ? this.props.value : [];

		return (
			<div style={m(primaryStyle, this)}>
				{value.map((item, index, arr) => (
					<Pill
						key={this.props.id + "-" + index}
						field={this.props.field}
						location={this.props.location}
						text={item}
						onClose={this._onClose}
						value={item.value || item}
					/>
				))}
				{!this.props.noEntry ? (
					<Input
						id={this.props.id + "-input"}
						style={inputStyle}
						placeholder={this.props.label}
						field={this.props.field}
						location={this.props.location}
						value={this.state.input}
						updateFn={this._onAddToken}
						onReturn={this._onAddToken}
						onBlur={this._onBlur}
						thin={true}
						noLine={true}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}

export default Tokenizer;
