import React from "react";

const rowLeftStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "calc(100% - 280px)"
};

const rowRightStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "200px",
	textAlign: "right"
};

const imageStyle = {
	height: "40px",
	width: "40px",
	display: "inline-block",
	verticalAlign: "middle",
	marginRight: "16px",
	borderRadius: "50%",
	cursor: "pointer",
	fontWeight: "900",
	fontFamily: "Roboto",
	fontSize: "36px",
	textAlign: "center"
};

const imageTextStyle = {
	fontSize: "20px",
	height: "20px",
	width: "40px",
	textAlign: "center",
	padding: "10px 0px",
	backgroundColor: "rgba(0, 0, 0, 0.62)",
	color: "white",
	cursor: "pointer",
	borderRadius: "50%",
	verticalAlign: "middle",
	display: "inline-block",
	lineHeight: "20px",
	marginRight: "16px"
};

class SettingsRow extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.updateFn(this.props.subsection, this.props.integration);
	}

	render() {
		const color = this.props.color || "transparent";

		const rowStyle = {
			borderBottom: "1px solid lightgrey",
			padding: "16px",
			borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
			position: "relative",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : color,
			transition: "background-color .3s ease",
			cursor: this.props.updateFn !== undefined ? "pointer" : "inherit",
			display: "inline-block",
			width: "calc(100% - 32px)"
		};

		const iconStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "0px 24px 0px 8px",
			color: this.props.active ? "rgb(70, 180, 175)" : "grey",
			transition: "color .3s ease",
			width: "24px"
		};

		if (this.props.updateFn !== undefined)
			return (
				<div onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} style={rowStyle} onClick={this._onClick}>
					{this.props.picture !== undefined ? <img style={imageStyle} src={this.props.picture} alt="" /> : ""}
					{this.props.picture === undefined && this.props.name !== undefined ? (
						<div style={imageTextStyle}>{this.props.name.toUpperCase().substr(0, 1)}</div>
					) : (
						""
					)}
					{this.props.icon !== undefined ? (
						<i style={iconStyle} className="material-icons">
							{this.props.icon}
						</i>
					) : (
						""
					)}
					<div style={rowLeftStyle}>{this.props.children}</div>
					<div style={rowRightStyle}>
						<i className="material-icons">keyboard_arrow_right</i>
					</div>
				</div>
			);
		else
			return (
				<div style={rowStyle}>
					{this.props.picture !== undefined ? (
						<img style={imageStyle} src={this.props.picture} alt="" />
					) : (
						<i style={iconStyle} className="material-icons">
							{this.props.icon}
						</i>
					)}
					<div style={rowLeftStyle}>{this.props.children[0]}</div>
					<div style={rowRightStyle}>{this.props.children[1]}</div>
				</div>
			);
	}
}

export default SettingsRow;
