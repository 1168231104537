import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import InputMultiline from "../forms/InputMultiline.react";
import Toggle from "../forms/Toggle.react";

const toggleLabelStyle = {
	fontSize: "12px",
	marginRight: "8px",
	display: "inline-block",
	verticalAlign: "middle",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)",
	marginTop: "4px"
};

const toggleRowStyle = {
	width: "calc(100% - 16px)",
	display: "inline-block",
	textAlign: "right",
	padding: "0px 8px"
};

class InternalNoteCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = { value: this.props.item.value || "", nextPath: 0 };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const questionStyle = {
			marginBottom: "16px"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={toggleRowStyle}>
						<div style={toggleLabelStyle}>{this.props.item.zendesk ? "Zendesk" : "HiOperator only"}</div>
						<Toggle
							id={"toggle-" + this.props.item.id}
							field="zendesk"
							location={[this.props.item.id]}
							updateFn={this._onBlur}
							value={this.props.item.zendesk || false}
						/>
					</div>
				) : (
					""
				)}
				<InputMultiline
					id={this.props.item.id}
					label="Internal note"
					value={this.state.value}
					field="value"
					location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
					onBlur={this._onBlur}
					noLine={false}
					style={questionStyle}
				/>
			</Card>
		);
	}
}

export default InternalNoteCard;
