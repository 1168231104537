import React from "react";

class SectionLabel extends React.Component {
	render() {
		const primaryStyle = {
			display: "inline-block",
			width: this.props.width || "100%",
			fontFamily: "Roboto",
			fontWeight: "700",
			fontSize: this.props.fontSize || "18px",
			textAlign: this.props.textAlign || "left",
			margin: this.props.margin || "8px 0px"
		};

		return <h1 style={primaryStyle}>{this.props.text}</h1>;
	}
}

export default SectionLabel;
