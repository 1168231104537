import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Container from "../Container.react";
import Overlay from "../Overlay.react";
import Card from "../Card.react";

// Form items
import Input from "../forms/Input.react";
import Dropdown from "../forms/Dropdown.react";
import Button from "../buttons/Button.react";

// Headers
import H1 from "../headers/H1.react";

// Sets validation
const nameValidation = [
	{
		type: "min-length",
		value: 2
	}
];

const inputTypes = [
	{
		text: "Text",
		value: "text"
	},
	{
		text: "Number",
		value: "number"
	},
	{
		text: "Date",
		value: "date"
	}
];

const sizeTypes = [
	{
		text: "Extra small",
		value: "xs"
	},
	{
		text: "Small",
		value: "s"
	},
	{
		text: "Medium",
		value: "m"
	},
	{
		text: "Large",
		value: "l"
	},
	{
		text: "Extra Large",
		value: "xl"
	}
];

class TableFieldOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onDelete = this._onDelete.bind(this);

		this.state = {
			text: this.props.item.obj.text || "",
			value: this.props.item.obj.value || "",
			type: this.props.item.obj.type || "",
			size: this.props.item.obj.size || "",
			validate: false
		};
	}

	componentDidMount() {
		document.getElementById("field-text").focus();
	}

	_onClose() {
		WorkflowActions.update();
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
		WorkflowActions.setField(field, this.props.item.location.concat(this.props.item.obj.id), value, false);
	}

	_onDelete() {
		WorkflowActions.remove(this.props.item.location.concat(this.props.item.obj.id));
		UIActions.showOverlay("");
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text={"Setup Field"} textAlign="center" />
						<Input
							id="field-text"
							label={"Label"}
							field="text"
							value={this.state.text}
							updateFn={this._updateFn}
							validation={nameValidation}
							helpText={"E.g., Product SKU"}
						/>
						<Input
							id="field-value"
							label={"Value"}
							field="value"
							value={this.state.value}
							updateFn={this._updateFn}
							validation={nameValidation}
							helpText={"E.g., product_sku"}
						/>
						<Dropdown
							id="field-type"
							label={"Type"}
							field="type"
							items={inputTypes}
							value={this.state.type}
							updateFn={this._updateFn}
						/>
						<Dropdown
							id="field-size"
							label={"Size"}
							field="size"
							items={sizeTypes}
							value={this.state.size}
							updateFn={this._updateFn}
						/>
						<div style={rowStyle}>
							<Button text="Delete" onClick={this._onDelete} type="flat" />
							<Button text="Close" style={buttonStyle} onClick={this._onClose} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default TableFieldOverlay;
