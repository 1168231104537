export default {
    name: "Shipstation",
    type: "shipstation",
    category: "Shipping",
    icon: "local_shipping",
    apiFields: [
        {
            field: "apiKey",
            label: "API Key",
            type: "text"
        },
        {
            field: "apiSecret",
            label: "API Secret",
            type: "text"
        }
    ],
    cardFields: [
        {
            field: "action",
            label: "Shipstation Action",
            type: "dropdown",
            items: [
                {
                    text: "Get order",
                    value: "get order"
                },
                {
                    text: "Add tag",
                    value: "add tag"
                },
                {
                    text: "Restore order",
                    value: "restore"
                },
                {
                    text: "Update order",
                    value: "update order"
                }
            ]
        },
        {
            field: "orderId",
            label: "Order ID",
            type: "text",
            helpText: "e.g., 94113592",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "get order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "add tag"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "restore"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update order"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "cancel order"
                        }
                    ]
                }
            ]
        },
        {
            field: "tagId",
            label: "Tag ID",
            type: "text",
            helpText: "e.g., 1234",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "add tag"
                        }
                    ]
                }
            ]
        },
        {
            field: "address",
            label: "Address",
            type: "text",
            helpText: "Should be a JSON object",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "updating shipping address"
                        }
                    ]
                }
            ]
        },
        {
            field: "updateJSON",
            label: "Update JSON",
            type: "text",
            helpText: "Should be a JSON object",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "action",
                            state: "is",
                            value: "update order"
                        }
                    ]
                }
            ]
        }
    ]
};
