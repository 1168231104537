import async from "async";
import _ from "lodash";
import firebase from "firebase/app";
import "firebase/firestore";
import * as firestore from "./firestore";

// Defines db for firestore
var db = firebase.firestore();

var Viewing = {
	ids: [],
	users: [],
	clientIds: [],
	clients: []
};

function update(viewing, target, field, updateAction) {
	var users = [];
	var i;
	for (i = 0; i < viewing.length; i++) {
		if (users.indexOf(viewing[i].userId) === -1) users.push(viewing[i].userId);
	}

	async.waterfall(
		[
			function(callback) {
				iterate_users(users, [], callback);
			}
		],
		function(err, results) {
			target[field] = results;
			if (updateAction !== undefined) updateAction();
		}
	);
}

function update_by_group(viewing, groupBy, master_callback) {
	console.log("updating and grouping!");
	var grouped = _.groupBy(viewing, item => item.viewing[groupBy]);

	var tickets = [];
	var single, i, j;
	var myKeys = Object.keys(grouped);
	var users = [];

	for (i = 0; i < myKeys.length; i++) {
		single = { ticketId: myKeys[i], clientId: grouped[myKeys[i]][0].viewing.clientId, userIDs: [], users: [] };

		for (j = 0; j < grouped[myKeys[i]].length; j++) {
			if (single.userIDs.indexOf(grouped[myKeys[i]][j].userId) === -1)
				single.userIDs.push(grouped[myKeys[i]][j].userId);

			if (users.indexOf(grouped[myKeys[i]][j].userId) === -1) users.push(grouped[myKeys[i]][j].userId);
		}

		tickets.push(single);
	}

	async.waterfall(
		[
			function(callback) {
				iterate_users(users, [], callback);
			},
			function(results, callback) {
				for (i = 0; i < tickets.length; i++) tickets[i].users = find_users(tickets[i].userIDs, results);
				callback(null);
			}
		],
		function(err) {
			master_callback(null, tickets);
		}
	);
}

function find_users(ids, users) {
	var results = [];
	var j;
	for (var i = 0; i < ids.length; i++) {
		for (j = 0; j < users.length; j++) {
			if (ids[i] === users[j].id) {
				results.push(users[j]);
				break;
			}
		}
	}

	return results;
}

function iterate_users(users, results, master_callback) {
	async.map(
		users,
		function(single, callback) {
			if (Viewing.ids.indexOf(single) > -1) {
				results.push(Viewing.users[Viewing.ids.indexOf(single)]);
				callback(null, Viewing.users[Viewing.ids.indexOf(single)]);
			} else {
				db.collection("users")
					.doc(single)
					.get()
					.then(function(snapshot) {
						var item = firestore.extract_single(snapshot);
						item = {
							id: item.id,
							name: item.name,
							picture: item.picture
						};

						Viewing.ids.push(single);
						Viewing.users.push(item);

						callback(null, item);
					})
					.catch(callback);
			}
		},
		function(err, results) {
			console.log("GOT ALL USERSSSSS");
			var final = [];
			for (var i = 0; i < results.length; i++) {
				if (results[i] !== undefined) final.push(results[i]);
			}

			master_callback(err, final);
		}
	);

	/*
	if (users.length > 0) {
		var single = users.splice(0, 1)[0];
		if (Viewing.ids.indexOf(single) > -1) {
			results.push(Viewing.users[Viewing.ids.indexOf(single)]);
			iterate_users(users, results, master_callback);
		} else {
			db.collection("users")
				.doc(single)
				.get()
				.then(function(snapshot) {
					var item = firestore.extract_single(snapshot);
					item = {
						id: item.id,
						name: item.name,
						picture: item.picture
					};

					results.push(item);
					Viewing.ids.push(single);
					Viewing.users.push(item);

					iterate_users(users, results, master_callback);
				})
				.catch(master_callback);
		}
    } else master_callback(null, results);
    */
}

var get_user = (id, callback) => {
	db.collection("users")
		.doc(id)
		.get()
		.then(function(snapshot) {
			var item = firestore.extract_single(snapshot);
			item = {
				id: item.id,
				name: item.name,
				picture: item.picture
			};

			callback(null, item);
		})
		.catch(callback);
};

var ensure_users = (items, results, idField, userField, master_callback) => {
	async.map(
		items,
		function(single, callback) {
			if (Viewing.ids.indexOf(single) > -1) {
				single[userField] = Viewing.users[Viewing.ids.indexOf(single[idField])];
				callback(null, single);
			} else {
				db.collection("users")
					.doc(single[idField])
					.get()
					.then(function(snapshot) {
						var item = firestore.extract_single(snapshot);
						item = {
							id: item.id,
							name: item.name,
							picture: item.picture
						};

						single[userField] = item;
						Viewing.ids.push(single[idField]);
						Viewing.users.push(item);

						callback(null, single);
					})
					.catch(callback);
			}
		},
		function(err, results) {
			var final = [];
			for (var i = 0; i < results.length; i++) {
				if (results[i] !== undefined) final.push(results[i]);
			}

			master_callback(err, final);
		}
	);

	/*
	if (items.length > 0) {
		var single = items.splice(0, 1)[0];

		if (Viewing.ids.indexOf(single[idField]) > -1) {
			single[userField] = Viewing.users[Viewing.ids.indexOf(single[idField])];
			results.push(single);
			ensure_users(items, results, idField, userField, master_callback);
		} else {
			db.collection("users")
				.doc(single[idField])
				.get()
				.then(function(snapshot) {
					var item = firestore.extract_single(snapshot);
					item = {
						id: item.id,
						name: item.name,
						picture: item.picture
					};

					single[userField] = item;
					results.push(single);
					Viewing.ids.push(single[idField]);
					Viewing.users.push(item);

					ensure_users(items, results, idField, userField, master_callback);
				})
				.catch(master_callback);
		}
    } else master_callback(null, results);
    */
};

var ensure_clients = (items, results, idField, userField, master_callback) => {
	async.map(
		items,
		function(single, callback) {
			console.log(single);
			if (Viewing.ids.indexOf(single) > -1) {
				single[userField] = Viewing.clients[Viewing.clientIds.indexOf(single[idField])];
				callback(null, single);
			} else if (single === undefined) {
				callback(null, undefined);
			} else {
				db.collection("clients")
					.doc(single[idField])
					.get()
					.then(function(snapshot) {
						var item = firestore.extract_single(snapshot);

						if (item === undefined) {
							callback(null, undefined);
						} else {
							item = {
								id: item.id,
								name: item.name,
								picture: item.picture
							};

							single[userField] = item;
							Viewing.clientIds.push(single[idField]);
							Viewing.clients.push(item);

							callback(null, single);
						}
					})
					.catch(callback);
			}
		},
		function(err, results) {
			console.log("done ensuring clients");
			console.log(err);
			console.log(results);

			var final = [];
			for (var i = 0; i < results.length; i++) {
				if (results[i] !== undefined) final.push(results[i]);
			}

			master_callback(err, final);
		}
	);

	/*
	if (items.length > 0) {
		var single = items.splice(0, 1)[0];

		if (Viewing.clientIds.indexOf(single[idField]) > -1) {
			single[userField] = Viewing.clients[Viewing.clientIds.indexOf(single[idField])];
			results.push(single);
			ensure_clients(items, results, idField, userField, master_callback);
		} else {
			db.collection("clients")
				.doc(single[idField])
				.get()
				.then(function(snapshot) {
					var item = firestore.extract_single(snapshot);
					item = {
						id: item.id,
						name: item.name,
						picture: item.picture
					};

					single[userField] = item;
					results.push(single);
					Viewing.clientIds.push(single[idField]);
					Viewing.clients.push(item);

					ensure_clients(items, results, idField, userField, master_callback);
				})
				.catch(master_callback);
		}
    } else master_callback(null, results);
    */
};

export { update, update_by_group, ensure_users, ensure_clients };
