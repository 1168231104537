import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SmallLoading from "../_common/SmallLoading.react";

// Summary parts
import SummaryRow from "../_common/summary/SummaryRow.react";
import Name from "../_common/summary/Name.react";
import PictureBadge from "../_common/summary/PictureBadge.react";
import Active from "../_common/summary/Active.react";
import Statistic from "../_common/summary/Statistic.react";

// Headers
import H2 from "../_common/headers/H2.react";

// Form items
import Button from "../_common/buttons/Button.react";
import FAB from "../_common/buttons/FAB.react";
import Dropdown from "../_common/forms/Dropdown.react";

import * as list from "../../functions/list.js";
import url_creator from "../../functions/url_creator.js";

class Row extends React.Component {
	render() {
		var color = this.props.item.slaCount !== undefined && this.props.item.slaCount > 0 ? "yellow" : "white";
		return (
			<SummaryRow actions={ClientActions} href={url_creator("clients", this.props.item.id)} color={color}>
				<PictureBadge item={this.props.item} />
				<Name item={this.props.item} />
				<Active item={this.props.item} />
				<Statistic text={this.props.item.queueCount} label="In queue" />
				<Statistic text={this.props.item.escalateCount} label="Escalated" />
				<Statistic text={this.props.item.slaCount} label="SLA expiring" />
				<Statistic text={this.props.item.workflowQueueCount} label="In workflow queue" />
				<Statistic text={this.props.item.csatCheckCount} label="CSAT awaiting" />
			</SummaryRow>
		);
	}
}

class ClientsSummary extends React.Component {
	constructor(props) {
		super(props);
		this._onNewClient = this._onNewClient.bind(this);
		this._onOrder = this._onOrder.bind(this);

		this.state = {
			filter: "name",
			summary: list.order(this.props.summary, "name")
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ summary: list.order(nextProps.summary, this.state.filter) });
	}

	_onOrder(field, location, value) {
		this.setState({ filter: value, summary: list.order(this.props.summary, value) });
	}

	_onNewClient() {
		UIActions.showOverlay("new client");
	}

	render() {
		const vertAlignStyle = {
			verticalAlign: "middle",
			display: "inline-block",
			width: "0px",
			height: "70vh"
		};

		const emptyStyle = {
			textAlign: "center",
			display: "inline-block",
			verticalAlign: "middle"
		};

		const emptyTextStyle = {
			fontSize: "40px",
			fontWeight: "400",
			marginBottom: "20px"
		};

		// Order options
		const filterStyle = {
			width: "250px"
		};

		const filterOptions = [
			{
				text: "Name",
				value: "name"
			},
			{
				text: "Date Created (newest first)",
				value: "created newest"
			},
			{
				text: "Date Created (oldest first)",
				value: "created oldest"
			},
			{
				text: "Last updated (newest first)",
				value: "updated newest"
			},
			{
				text: "Last updated (oldest first)",
				value: "updated oldest"
			}
		];

		var toRender = [];
		if (this.props.status.indexOf("getting summary") > -1) {
			toRender.push(<SmallLoading key="loading" />);
		} else if (this.state.summary.length === 0) {
			toRender.push(
				<div key="empty-clients" style={emptyStyle}>
					<div style={emptyTextStyle}>There are currently no clients</div>
					<Button text="Add New Client" onClick={this._onNewClient} />
				</div>
			);
			toRender.push(<div key="vertical-aligner-clients" style={vertAlignStyle} />);
		} else {
			toRender.push(
				<H2 key="clients-header" text="Our clients" textAlign="left" margin="24px" width="calc(100% - 300px)" />
			);
			toRender.push(
				<Dropdown
					key="order"
					id="order"
					label="Order"
					value={this.state.filter}
					items={filterOptions}
					field="summary"
					updateFn={this._onOrder}
					style={filterStyle}
				/>
			);
			this.state.summary.map((item, index, arr) => {
				toRender.push(<Row key={"client-" + index} item={item} />);
				return true;
			});

			toRender.push(<FAB key="fab-clients" onClick={this._onNewClient} />);
		}

		return (
			<OuterScaffold spaceForHeader={true}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top">
					{toRender}
				</Container>
			</OuterScaffold>
		);
	}
}
export default ClientsSummary;
