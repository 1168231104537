import React from "react";
import shortid from "shortid";

// Actions
import UIActions from "../../actions/UIActions";
import ProductActions from "../../actions/ProductActions";

// Scaffolding
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Card from "../_common/Card.react";

// Form items
import Datepicker from "../_common/forms/Datepicker.react";
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Functions
import api from "../../functions/api";

var format_date = date => {
	if (date !== "") date = new Date(date);

	if (typeof date === "object" && typeof date.getMonth === "function") {
		var minutes = date.getMinutes();
		minutes = minutes > 9 ? minutes : "0" + String(minutes);

		var hours = date.getHours();
		hours = hours > 9 ? hours : "0" + String(hours);

		var month = date.getMonth() + 1;
		month = month > 9 ? month : "0" + String(month);

		var day = date.getDate();
		day = day > 9 ? day : "0" + String(day);

		return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes;
	}

	return date;
};

class ScheduleEditOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onDelete = this._onDelete.bind(this);
		this._onClose = this._onClose.bind(this);
		this._onSave = this._onSave.bind(this);
		this._onSaveComplete = this._onSaveComplete.bind(this);

		var start = format_date(this.props.item.event.start || "");
		var end = format_date(this.props.item.event.end || "");

		this.state = {
			type: this.props.item.event.type,
			start,
			end,
			validate: false
		};
	}

	_onClose() {
		//ProductActions.update();
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		//ProductActions.setField(field, [this.props.item.id], value, false);
	}

	_onSave() {
		if ([null, undefined, ""].indexOf(this.state.start) > -1 || [null, undefined, ""].indexOf(this.state.end) > -1) {
			UIActions.addMessage("Please make sure to specify a start and end date.", "error");
		} else {
			var start = new Date(this.state.start);
			var end = new Date(this.state.end);

			if (start > end) {
				UIActions.addMessage("Please make sure to specify the start date is earlier than the end date.", "error");
			} else {
				var obj = {
					id: this.props.item.event.id || shortid.generate(),
					userId: this.props.item.event.userId,
					day: start.toLocaleDateString(),
					start,
					end
				};

				api(
					"post",
					"/users/" + obj.userId + "/schedule/" + this.state.type,
					obj,
					this._onSaveComplete,
					this._onSaveComplete,
					{},
					"updating schedule"
				);
			}
		}
	}

	_onSaveComplete() {
		this.props.item.refetch();
		this._onClose();
	}

	_onDelete() {
		api(
			"delete",
			"/users/" + this.props.item.event.userId + "/schedule/" + this.state.type + "/" + this.props.item.event.id,
			{},
			this._onSaveComplete,
			this._onSaveComplete,
			{},
			"updating schedule"
		);
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		var header = this.state.type === "shift" ? "Edit Scheduled Shift" : "Edit Worked Shift";

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text={header} textAlign="center" />
						<Datepicker
							field="start"
							label="Shift Start"
							value={this.state.start}
							both={true}
							updateFn={this._updateFn}
							onBlur={this._updateFn}
						/>
						<Datepicker
							field="end"
							label="Shift End"
							value={this.state.end}
							both={true}
							updateFn={this._updateFn}
							onBlur={this._updateFn}
						/>

						<div style={rowStyle}>
							<Button text="Delete" onClick={this._onDelete} type="flat" />
							<Button text="Save" style={buttonStyle} onClick={this._onSave} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ScheduleEditOverlay;
