function parse_single(obj) {
	if (obj.workflows !== undefined) {
		var tempKeys = Object.keys(obj.workflows);
		if (tempKeys.length === 0) return "";
		else {
			for (var i = 0; i < tempKeys.length; i++) {
				if (obj.workflows[tempKeys[i]].status !== "complete") return "working";
				else if (i === tempKeys.length - 1) return "complete";
			}
		}
	}

	return "";
}

function status_parse(data) {
	for (var i = 0; i < data.length; i++) data[i].workflowsStatus = parse_single(data[i]);

	return data;
}

export { status_parse };
