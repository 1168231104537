import React from "react";

// Actions
import ClientActions from "../../../actions/ClientActions";

// Scaffolding
import Row from "../../_common/Row.react";

// Toggle
import Toggle from "../../_common/forms/Toggle.react";

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "300px",
	textAlign: "left"
};

const imageTextStyle = {
	fontSize: "20px",
	height: "20px",
	width: "40px",
	textAlign: "center",
	padding: "10px 0px",
	backgroundColor: "rgba(0, 0, 0, 0.62)",
	color: "white",
	cursor: "pointer",
	borderRadius: "50%",
	verticalAlign: "middle",
	display: "inline-block",
	lineHeight: "20px",
	marginRight: "16px"
};

const roleStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "20px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "400",
	width: "200px",
	textAlign: "center"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

const closeIconStyle = {
	marginLeft: "48px",
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer"
};

const emailStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.67)",
	fontWeight: "400",
	width: "200px",
	textAlign: "center"
};

class ClientAgentRow extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this.state = { name: this.props.item.name };
	}

	_onClick() {
		ClientActions.remove(this.props.location);
	}

	_onToggle(field, location, value) {
		ClientActions.setField(field, location, value, true);
	}

	render() {
		return (
			<Row index={this.props.index}>
				<div style={imageTextStyle}>{this.state.name.toUpperCase().substr(0, 1)}</div>
				<div style={nameStyle}>{this.state.name}</div>
				<div style={emailStyle}>{this.props.item.email}</div>
				<div style={roleStyle}>
					<Toggle
						id={"hioperator-agent-" + this.props.item.id}
						field="hioperatorAgent"
						location={this.props.location}
						updateFn={this._onToggle}
						value={this.props.item.hioperatorAgent || false}
					/>
					<span style={labelStyle}>
						{this.props.item.hioperatorAgent ? "Hioperator agent" : "Not hioperator agent"}
					</span>
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onClick}>
					close
				</i>
			</Row>
		);
	}
}

export default ClientAgentRow;
