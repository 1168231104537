import * as objects from "./objects";

function set_field(obj, field, location, value, setUpdated) {
    if (location !== null && location !== undefined && Array.isArray(location) && location.length > 0) {
        var result = objects.find(obj, null, location.concat());

        if (result !== null && result.obj !== undefined) obj = result.obj;
        else obj = null;
    }

    if (obj !== null) {
        var split = field.split(".");

        for (var i = 0; i < split.length; i++) {
            if (obj[split[i]] === undefined && i < split.length - 1) obj[split[i]] = {};

            if (i < split.length - 1) obj = obj[split[i]];
            else {
                obj[split[i]] = value;
                if (setUpdated) obj.updated = new Date();
            }
        }
    }
}

export default set_field;
