import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import Loading from "../SmallLoading.react";

// Form items
import Input from "../forms/Input.react";

// Functions
import * as objects from "../../../functions/objects";

const checkingStyle = {
	width: "100%",
	padding: "0px 0px 16px",
	textAlign: "center"
};

const directionControlsStyle = {
	width: "100%",
	marginTop: "0px",
	marginBottom: "24px",
	textAlign: "center",
	display: "inline-block"
};

const rpaTextPreviewStyle = {
	width: "calc(100% - 32px)",
	margin: "16px",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	cursor: "pointer",
	fontStyle: "italic",
	color: "rgba(0, 0, 0, 0.54)",
	maxHeight: "250px",
	textAlign: "left"
};

class RPACard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onOpenCodeEditor = this._onOpenCodeEditor.bind(this);

		this.state = {
			valid: true,
			nextPath: 0
		};
	}

	componentDidMount() {
		if (this.props.direction === "back") setTimeout(WorkflowActions.previousCard, 500);
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	_onOpenCodeEditor() {
		var location = [this.props.item.id];
		WorkflowActions.get(function(Workflow) {
			var obj = objects.find(Workflow.working, null, location);
			if ([null, undefined].indexOf(obj) === -1 && obj.obj !== undefined) {
				obj = obj.obj;
				if ([undefined, null].indexOf(obj) === -1) UIActions.showOverlay("rpa editor", obj);
				else UIActions.addMessage("Something went wrong :(", "error");
			}
		});
	}

	render() {
		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				noNextButton={true}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={directionControlsStyle}>
						<Input
							id={"integrationId-" + this.props.item.id}
							field={"integrationId"}
							location={[this.props.item.id]}
							onBlur={this._updateFn}
							value={this.props.item.integrationId}
							label={"Integration ID"}
						/>
						<div
							style={rpaTextPreviewStyle}
							dangerouslySetInnerHTML={{
								__html: this.props.item.code || "Enter RPA code here"
							}}
							onClick={this._onOpenCodeEditor}
						/>
					</div>
				) : (
					<div>
						<Loading height="200px" />
						<div style={checkingStyle}>Running UI script...</div>
					</div>
				)}
			</Card>
		);
	}
}

export default RPACard;
