import React from "react";

// Our actions
import MacroActions from "../../actions/MacroActions";

// Scaffolding
import SettingsScaffold from "../_common/SettingsScaffold.react";
import H2 from "../_common/headers/H2.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import Dropdown from "../_common/forms/Dropdown.react";

const tierOptions = [
	{
		text: "Tier 1: Basic",
		value: 1
	},
	{
		text: "Tier 2: Moderate",
		value: 2
	},
	{
		text: "Tier 3: Advanced",
		value: 3
	}
];

const basicPrimaryStyle = {
	width: "600px",
	maxWidth: "100%",
	display: "inline-block",
	textAlign: "left",
	padding: "16px 0px"
};

const tierStyle = {
	width: "300px"
};

class Basic extends React.Component {
	_onUpdateFn(field, location, value) {
		MacroActions.setField(field, location, value, true);
	}

	render() {
		return (
			<div style={basicPrimaryStyle}>
				<H2 text={"Macro Description"} />
				<InputMultiline
					field="description"
					value={this.props.item.description || ""}
					onBlur={this._onUpdateFn}
					label="Description"
				/>
			</div>
		);
	}
}

const menuOptions = [
	{
		text: "Basic",
		value: "basic"
	}
];

class MacroSettings extends React.Component {
	constructor(props) {
		super(props);
		this._onSectionChange = this._onSectionChange.bind(this);
		this.state = { section: "basic" };
	}

	_onSectionChange(section) {
		this.setState({ section });
	}

	render() {
		var toRender = [];
		if (this.state.section === "basic") toRender = <Basic item={this.props.item} />;

		return (
			<SettingsScaffold
				menuOptions={menuOptions}
				section={this.state.section}
				onSectionChange={this._onSectionChange}
				actions={MacroActions}
				save={false}
			>
				{toRender}
			</SettingsScaffold>
		);
	}
}

export default MacroSettings;
