import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Input from "../_common/forms/Input.react";
import Button from "../_common/buttons/Button.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Datepicker from "../_common/forms/Datepicker.react";

// Headers
import H1 from "../_common/headers/H1.react";
import H2 from "../_common/headers/H2.react";

// Styles
const leftColumnStyle = {
	width: "calc(50% - 17px)",
	padding: "16px 8px 16px 0px",
	display: "inline-block",
	verticalAlign: "top",
	borderRight: "1px solid lightgrey",
	textAlign: "center"
};

const rightColumnStyle = {
	width: "calc(50% - 16px)",
	padding: "16px 0px 16px 8px",
	display: "inline-block",
	verticalAlign: "top"
};

const cardStyle = {
	width: "calc(100% - 48px)",
	padding: "24px",
	textAlign: "left",
	maxWidth: "1100px"
};

const rowStyle = {
	width: "calc(100% + 16px)",
	textAlign: "right",
	margin: "0px -24px -24px -24px",
	borderTop: "1px solid lightgrey",
	padding: "16px"
};

const topRowStyle = {
	width: "calc(100% + 48px)",
	textAlign: "right",
	margin: "-24px -24px 0px -24px",
	borderBottom: "1px solid lightgrey",
	padding: "0px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const rulePrimaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "0px 0px",
	textAlign: "left"
};

const ruleSummaryPrimaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "16px 8px"
};

const typeStyle = {
	width: "200px",
	verticalAlign: "middle"
};

const verbStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	margin: "0px 16px",
	fontSize: "24px"
};

var numericRules = [
	"min-length",
	"max-length",
	"min-value",
	"max-value",
	"min-days-value",
	"max-days-value",
	"min-months-value",
	"max-months-value",
	"length"
];

var dateRules = ["min-date", "max-date"];

var textRules = ["contains", "not-contain"];
var dropdownRules = ["type"];

const ruleValueTypeOptions = [
	{
		text: "String",
		value: "string"
	},
	{
		text: "Number",
		value: "number"
	},
	{
		text: "Date/Time",
		value: "datetime"
	}
];

const closeIconStyle = {
	display: "inline-block",
	verticalAlign: "top",
	cursor: "pointer",
	marginTop: "20px"
};

const ruleContainerStyle = {
	width: "calc(100% - 40px)",
	display: "inline-block",
	verticalAlign: "middle"
};

const emptyStyle = {
	width: "calc(100% - 48px - 32px)",
	textAlign: "center",
	padding: "24px",
	fontSize: "20px",
	margin: "16px",
	border: "1px dashed lightgrey"
};

const addButtonStyle = {
	marginTop: "16px"
};

class Rule extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onDeleteRule = this._onDeleteRule.bind(this);
	}

	_updateFn(field, location, value) {
		this.props.actions.setField(field, this.props.address, value, true);
	}

	_onDeleteRule() {
		this.props.actions.remove(this.props.address);
	}

	render() {
		var typeOptions = [];
		if (["free response"].indexOf(this.props.type) > -1) {
			typeOptions = typeOptions.concat([
				{
					text: "Minimum length",
					value: "min-length"
				},
				{
					text: "Maximum length",
					value: "max-length"
				},
				{
					text: "Contains",
					value: "contains"
				},
				{
					text: "Does not contain",
					value: "not-contain"
				},
				{
					text: "Length",
					value: "length"
				},
				{
					text: "Type",
					value: "type"
				}
			]);
		}

		if (["numeric"].indexOf(this.props.type) > -1) {
			typeOptions = typeOptions.concat([
				{
					text: "Minimum value",
					value: "min-value"
				},
				{
					text: "Maximum value",
					value: "max-value"
				}
			]);
		}

		if (["datetime"].indexOf(this.props.type) > -1) {
			typeOptions = typeOptions.concat([
				{
					text: "Earliest date",
					value: "min-date"
				},
				{
					text: "Latest date",
					value: "max-date"
				},
				{
					text: "Days before today",
					value: "min-days-value"
				},
				{
					text: "Days after today",
					value: "max-days-value"
				},
				{
					text: "Months before today",
					value: "min-months-value"
				},
				{
					text: "Months after today",
					value: "max-months-value"
				}
			]);
		}

		return (
			<div style={rulePrimaryStyle}>
				<div style={ruleContainerStyle}>
					<Dropdown
						label="Type"
						id={"rule-type-" + this.props.item.id}
						items={typeOptions}
						field="type"
						value={this.props.item.type}
						updateFn={this._updateFn}
						style={typeStyle}
					/>
					{this.props.item.type !== "" ? <div style={verbStyle}> is </div> : ""}
					{numericRules.indexOf(this.props.item.type) > -1 || textRules.indexOf(this.props.item.type) > -1 ? (
						<Input
							label="Value"
							id={"rule-value-" + this.props.item.id}
							field="value"
							value={this.props.item.value}
							onBlur={this._updateFn}
							style={typeStyle}
							type={numericRules.indexOf(this.props.item.type) > -1 ? "number" : "text"}
						/>
					) : (
						""
					)}
					{dateRules.indexOf(this.props.item.type) > -1 ? (
						<Datepicker
							label="Value"
							id={"rule-value-" + this.props.item.id}
							field="value"
							value={this.props.item.value}
							updateFn={this._updateFn}
							style={typeStyle}
						/>
					) : (
						""
					)}

					{dropdownRules.indexOf(this.props.item.type) > -1 ? (
						<Dropdown
							label="Value"
							id={"rule-value-" + this.props.item.id}
							items={ruleValueTypeOptions}
							field="value"
							value={this.props.item.value}
							updateFn={this._updateFn}
							style={typeStyle}
						/>
					) : (
						""
					)}
				</div>
				<i style={closeIconStyle} className="material-icons" onClick={this._onDeleteRule}>
					close
				</i>
			</div>
		);
	}
}

class RuleSummary extends React.Component {
	render() {
		var text = "";
		if (this.props.item.type === "min-length") text = "Must be at least " + this.props.item.value + " characters.";
		else if (this.props.item.type === "max-length")
			text = "Must be no more than " + this.props.item.value + " characters.";
		else if (this.props.item.type === "min-value") text = "Must be at least " + this.props.item.value + ".";
		else if (this.props.item.type === "max-value") text = "Must be no more than " + this.props.item.value + ".";
		else if (this.props.item.type === "min-date") text = "Must be at least " + this.props.item.value + ".";
		else if (this.props.item.type === "max-date") text = "Must be no more than " + this.props.item.value + ".";
		else if (this.props.item.type === "min-days-value")
			text = "Must be at least " + this.props.item.value + " days before today.";
		else if (this.props.item.type === "max-days-value")
			text = "Must be no more than " + this.props.item.value + " days after today.";
		else if (this.props.item.type === "min-months-value")
			text = "Must be at least " + this.props.item.value + " days before today.";
		else if (this.props.item.type === "max-months-value")
			text = "Must be no more than " + this.props.item.value + " days after today.";
		else if (this.props.item.type === "length") text = "Must be " + this.props.item.value + " characters long.";
		else if (this.props.item.type === "contains") text = 'Must contain "' + this.props.item.value + '".';
		else if (this.props.item.type === "type") text = "Must be a " + this.props.item.value + ".";

		return (
			<div style={ruleSummaryPrimaryStyle}>
				{this.props.index + 1}
				{") "}
				{text}
			</div>
		);
	}
}

class ValidationOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onAddRule = this._onAddRule.bind(this);

		this.state = {};
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		this.props.actions.setField(field, [this.props.item.id], value);
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_onAddRule() {
		var obj = { type: "", value: "" };
		this.props.actions.add("validation", [this.props.item.id], obj);
	}

	render() {
		const rules = this.props.item.validation || [];
		return (
			<Overlay>
				<Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<div style={topRowStyle}>
							<H1 text="Setup Validation" textAlign="center" />
						</div>
						<div style={leftColumnStyle}>
							<H2 text="Edit Rules" textAlign="center" />
							{this.props.item.validation === undefined || this.props.item.validation.length === 0 ? (
								<div style={emptyStyle}>Card has no validation rules</div>
							) : (
								rules.map((item, index, arr) => (
									<Rule
										key={"rule-" + item.id}
										index={index}
										item={item}
										address={[this.props.item.id, item.id]}
										actions={this.props.actions}
										type={this.props.item.type}
									/>
								))
							)}

							<Button text="Add rule" onClick={this._onAddRule} style={addButtonStyle} />
						</div>
						<div style={rightColumnStyle}>
							<H2 text="Rules Summary" textAlign="center" />
							{this.props.item.validation === undefined || this.props.item.validation.length === 0 ? (
								<div style={emptyStyle}>Card has no validation rules</div>
							) : (
								rules.map((item, index, arr) => (
									<RuleSummary key={"rule-summary-" + item.id} item={item} index={index} />
								))
							)}
						</div>

						<div style={rowStyle}>
							<Button text="Save" style={buttonStyle} onClick={this._onClose} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default ValidationOverlay;
