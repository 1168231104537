import React from "react";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";

// Form items
import Button from "../_common/buttons/Button.react";

// Creator tools
import CreatorDropdown from "./CreatorDropdown.react";

import * as objects from "../../functions/objects";

const connectorsStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	position: "relative",
	width: "200px",
	marginRight: "32px"
};

var connector_position = index => {
	if (index === 0) return 0;
	else if (index % 2 === 0) return -1;
	else return 1;
};

class AddBetween extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		var target = {
			coordinates: this.props.coordinates,
			fromCoordinates: this.props.fromCoordinates,
			from: this.props.from,
			fromIndex: this.props.fromIndex,
			direction: this.props.direction,
			between: true
		};

		UIActions.showOverlay("add card", target);
	}

	render() {
		const primaryStyle = {
			position: "absolute",
			top: this.props.direction <= 0 ? "6px" : "",
			bottom: this.props.direction > 0 ? "6px" : "",
			left: "86px",
			padding: "4px",
			borderRadius: "50%",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.62)" : "transparent",
			color: this.state.hover ? "white" : "rgba(0, 0, 0, 0.87)",
			transition: "all 0.3s ease",
			cursor: "pointer",
			zIndex: "3"
		};

		return (
			<i
				style={primaryStyle}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				onClick={this._onClick}
				className="material-icons"
			>
				add
			</i>
		);
	}
}

class SingleConnector extends React.Component {
	constructor(props) {
		super(props);
		this._onDisconnect = this._onDisconnect.bind(this);
	}

	_onDisconnect() {
		WorkflowActions.disconnectCard(this.props.cardId, this.props.item.to.id, this.props.item.to.index);
	}

	render() {
		const direction = connector_position(this.props.index);
		const adjustedIndex =
			this.props.item.to !== undefined && !this.props.item.to.connecting
				? Math.abs(this.props.item.to.coordinates.y - this.props.coordinates.y)
				: Math.ceil(this.props.index / 2) + (this.props.item.shift || 0);
		const amount = adjustedIndex > 0 ? "124px" : "124px";
		const stemPosition = Math.ceil(this.props.index / 2) + (this.props.item.shift || 0);

		var connectorStyle = {
			position: "absolute",
			display: "block",
			left: "16px",
			//top: "calc(0px + " + adjustedIndex + " * " + amount + " * " + direction + ")",
			height: this.props.index === 0 ? "0px" : "calc(" + adjustedIndex + "* " + amount + ")",
			width: this.props.index === 0 ? "200px" : "196px",
			borderLeft: this.props.index !== 0 ? "4px solid rgb(70, 180, 175)" : ""
		};

		var creatorStyle = {
			position: "absolute",
			left: "calc(100% + 16px",
			marginBottom: "0px"
		};

		var labelStyle = {
			width: "184px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			position: "absolute",
			left: "8px",
			textAlign: "center"
		};

		var removeButtonStyle = {
			position: "absolute",
			left: "8px",
			zIndex: "2"
		};

		var arrowStyle = {
			position: "absolute",
			color: "rgb(70, 180, 175)",
			right: "-24px",
			fontSize: "34px"
		};

		if (direction > 0) {
			connectorStyle.top = this.props.index === 0 ? "0px" : "-4px";
			connectorStyle.borderRadius = "0% 0% 0% 50%";
			connectorStyle.borderBottom = "4px solid rgb(70, 180, 175)";
			creatorStyle.bottom = "-28px";
			labelStyle.top = "calc(100% + 16px)";
			removeButtonStyle.top = "calc(100% - 48px)";
		} else {
			connectorStyle.borderRadius = this.props.index === 0 ? "" : "50% 0% 0% 0%";
			connectorStyle.bottom = this.props.index === 0 ? "0px" : "-4px";
			connectorStyle.borderTop = "4px solid rgb(70, 180, 175)";
			creatorStyle.top = "-24px";
			labelStyle.bottom = "calc(100% + 16px)";
			removeButtonStyle.bottom = "calc(100% - 48px)";
		}

		var connectingStemStyle = {
			position: "absolute"
		};

		// Sets position of stem
		if (direction > 0) connectingStemStyle.bottom = "-4px";
		else if (direction < 0) connectingStemStyle.top = "-4px";

		var connectingCurveStyle = {
			position: "absolute",
			width: "200px",
			zIndex: "-1"
		};

		if (this.props.item.to !== undefined && this.props.item.to.connecting) {
			var x = this.props.item.to.coordinates.x - this.props.coordinates.x - 1;
			var y = this.props.item.to.coordinates.y - this.props.coordinates.y;
			var heightAdjuster = (y >= 0 && direction < 0) || (y <= 0 && direction > 0) ? 2 * stemPosition : 0;
			var heightCorrection = (y > 0 && direction < 0) || (y < 0 && direction > 0) ? "0px" : "0px";

			// Adjusts the regular turner
			if (x < 0) {
				if (this.props.index === 0) {
					connectorStyle.width = "100px";
					connectorStyle.borderBottom = "4px solid rgb(70, 180, 175";
					connectorStyle.height = "50px";
					connectorStyle.borderTop = "4px solid rgb(70, 180, 175)";
					connectorStyle.borderBottom = "4px solid rgb(70, 180, 175)";
					connectorStyle.borderRight = "4px solid rgb(70, 180, 175)";
					connectorStyle.borderRadius = "0% 50% 50% 0%";

					if (y < 0) {
						connectorStyle.bottom = "0px";
						connectingStemStyle.top = "-4px";
					} else {
						connectorStyle.top = "-4px";
						connectingStemStyle.bottom = "-4px";
					}
				} else {
					connectorStyle.left = "-180px";
					connectorStyle.borderRight = "4px solid rgb(70, 180, 175)";
					connectorStyle.borderLeft = "";
					connectorStyle.borderRadius = direction > 0 ? "0% 0% 50% 0%" : "0% 50% 0% 0%";
				}
			} else {
				if (direction === 0 && x > -1) connectingStemStyle.bottom = "0px";
			}

			if (y < 0) {
				arrowStyle.top = "-19px";

				if (this.props.index === 0) {
					connectingCurveStyle.height =
						x < 0
							? "calc(" + Math.abs(y) + "* (" + amount + ")  - 54px)"
							: "calc(" + Math.abs(y) + "* (" + amount + "))";
				} else
					connectingCurveStyle.height =
						"calc(" +
						(Math.abs(y) + heightAdjuster - adjustedIndex) +
						"* (" +
						amount +
						") - 0px + " +
						heightCorrection +
						")";

				connectingCurveStyle.bottom = "0px";
				connectingCurveStyle.borderRadius = x < 0 ? "0% 50% 0%" : "0% 0% 50%";
				connectingCurveStyle.borderBottom = "4px solid rgb(70, 180, 175)";
			} else if (y === 0) {
				arrowStyle.top = "-19px";
				connectingCurveStyle.height =
					this.props.index === 0 ? "50px" : "calc(" + (heightAdjuster - adjustedIndex) + "* (" + amount + "))";

				if (this.props.index === 0 || direction > 0) {
					connectingCurveStyle.bottom = this.props.index === 0 ? "0px" : "0px";
					connectingCurveStyle.borderRadius = x < 0 ? "0% 0% 0% 50%" : "0% 0% 50% 0%";
					connectingCurveStyle.borderBottom = "4px solid rgb(70, 180, 175)";
				} else {
					connectingCurveStyle.top = this.props.index === 0 ? "0px" : "-4px";
					connectingCurveStyle.borderRadius = x < 0 ? "50% 0% 0% 0%" : "0% 50% 0% 0%";
					connectingCurveStyle.borderTop = "4px solid rgb(70, 180, 175)";
				}
			} else if (y > 0) {
				arrowStyle.bottom = "-19px";
				if (this.props.index === 0) {
					connectingCurveStyle.height =
						x < 0
							? "calc(" + Math.abs(y) + "* (" + amount + ")  - 50px)"
							: "calc(" + Math.abs(y) + "* (" + amount + ") )";
				} else
					connectingCurveStyle.height =
						"calc(" +
						(Math.abs(y) + heightAdjuster - adjustedIndex) +
						"* (" +
						amount +
						") - 4px + " +
						heightCorrection +
						")";

				connectingCurveStyle.top = "-4px";
				connectingCurveStyle.borderRadius = x < 0 ? "50% 0% 0%" : "0% 50% 0%";
				connectingCurveStyle.borderTop = "4px solid rgb(70, 180, 175)";
			}

			if (x > 0) {
				connectingStemStyle.width = "calc(466px * " + x + " - 196px - 20px)";
				connectingStemStyle.left = this.props.index === 0 ? "200px" : "196px";
				connectingCurveStyle.right = "-196px";
				connectingStemStyle.borderTop = "4px solid rgb(70, 180, 175)";
				connectingCurveStyle.borderRight = "4px solid rgb(70, 180, 175)";
			} else if (x === 0) {
				connectorStyle.width = "96px";
				connectingStemStyle.width = "0px";
				//connectingStemStyle.left = this.props.index === 0 ? "100px" : "96px";
				connectingCurveStyle.width = this.props.index === 0 ? "80px" : "76px";
				connectingCurveStyle.right = this.props.index === 0 ? "-180px" : "-176px";
				connectingStemStyle.borderTop = "4px solid transparent";
				connectingCurveStyle.borderRight = "4px solid rgb(70, 180, 175)";
			} else if (x < 0) {
				if (this.props.index === 0)
					connectingStemStyle.width =
						x === -1
							? "calc(466px * " + (x * -1 - 1) + " + 204px + 20px)"
							: "calc(466px * " + (x * -1 - 1) + " + 170px + 20px)";
				else connectingStemStyle.width = "calc(466px * " + (x * -1 - 1) + " - 26px + 20px)";

				connectingStemStyle.right = this.props.index === 0 ? "100px" : "196px";
				connectingStemStyle.borderTop =
					x === -1 && this.props.index > 0 ? "4px solid transparent" : "4px solid rgb(70, 180, 175)";
				connectingCurveStyle.borderLeft = "4px solid rgb(70, 180, 175)";
				connectingCurveStyle.width = x === -1 ? "96px" : "196px";
				connectingCurveStyle.left = x === -1 ? "-66px" : "-100px";
			}
		} else {
			if (direction < 1) arrowStyle.top = "-19px";
			else if (direction > 0) arrowStyle.bottom = "-19px";
		}

		return (
			<div style={connectorStyle}>
				{this.props.item.to !== undefined && !this.props.item.to.connecting ? (
					<AddBetween
						direction={direction}
						coordinates={{ x: this.props.coordinates.x + 1, y: this.props.coordinates.y + adjustedIndex * direction }}
						fromCoordinates={this.props.coordinates}
						fromIndex={this.props.index}
						from={this.props.cardId}
					/>
				) : (
					""
				)}
				<div
					style={labelStyle}
					dangerouslySetInnerHTML={{
						__html: this.props.item.text
					}}
				/>

				{this.props.item.to !== undefined && this.props.item.to.connecting ? (
					<div style={connectingStemStyle}>
						<div style={connectingCurveStyle} />
					</div>
				) : (
					<i style={arrowStyle} className="material-icons">
						keyboard_arrow_right
					</i>
				)}
				{this.props.item.to !== undefined && this.props.item.to.connecting ? (
					<Button text="Remove" type="flat" style={removeButtonStyle} onClick={this._onDisconnect} />
				) : (
					""
				)}
				{this.props.item.to === undefined ? (
					<CreatorDropdown
						style={creatorStyle}
						id={"creator-" + this.props.id}
						onClick={this._onCreatorDownClick}
						coordinates={{ x: this.props.coordinates.x + 1, y: this.props.coordinates.y + adjustedIndex * direction }}
						fromCoordinates={this.props.coordinates}
						fromIndex={this.props.index}
						from={this.props.cardId}
						onCardConnect={this.props.onCardConnect}
						last={this.props.last}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}

class Connectors extends React.Component {
	render() {
		return (
			<div style={connectorsStyle}>
				{this.props.arrows.map((item, index, arr) => (
					<SingleConnector
						key={this.props.id + "-" + index}
						id={this.props.id + "-" + index}
						item={item}
						index={index}
						coordinates={this.props.coordinates}
						cardId={this.props.cardId}
						last={index === arr.length - 1}
						onCardConnect={this.props.onCardConnect}
					/>
				))}
			</div>
		);
	}
}

var update_arrows = obj => {
	var arrows = objects.clone(obj.item.arrows || []);
	if (arrows.length === 0 || obj.item.to === undefined) return arrows;
	var i;
	var shift = obj.item.shift || { up: 0, down: 0 };

	for (i = 0; i < obj.item.to.length; i++) arrows[obj.item.to[i].index].to = obj.item.to[i];

	var upShift = shift.up;
	var downShift = shift.down;

	for (i = 0; i < arrows.length; i++) {
		if (shift[i] !== undefined && i % 2 === 0) {
			upShift += shift[i];
		} else if (shift[i] !== undefined && i % 2 !== 0) {
			downShift += shift[i];
		}

		arrows[i].shift = i % 2 === 0 ? upShift : downShift;
	}
	return arrows;
};

class WorkflowQuestion extends React.Component {
	constructor(props) {
		super(props);
		this._onFocus = this._onFocus.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = {
			hover: false,
			concept: this.props.item.concept,
			arrows: update_arrows(this.props),
			toCount: this.props.item.to !== undefined ? this.props.item.to.length || 0 : 0
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			(nextProps.item.to !== undefined && nextProps.item.to.length !== this.state.toCount) ||
			(nextProps.item.arrows !== undefined && nextProps.item.arrows.length !== this.state.arrows.length) ||
			nextProps.item.shiftCounter !== this.props.item.shiftCounter
		)
			this.setState({
				arrows: update_arrows(nextProps),
				toCount: nextProps.item.to !== undefined ? nextProps.item.to.length || 0 : 0
			});
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	_onFocus() {
		this.setState({ hover: false });
		this.props.onMoveView(this.props.item.id);
	}

	_updateFn(field, location, value) {
		var agentMode = this.props.agentMode;
		location = [this.props.item.id];

		if (value === "delete") {
			if (this.props.item.to !== undefined && this.props.item.to.length > 1)
				UIActions.addMessage("You can only delete a card if it has 0 or 1 branches. You currently have 2.", "error");
			else {
				UIActions.showConfirmation({
					title: "Delete card",
					text: "Are you sure you want to delete this card?",
					buttonText: "Delete Card",
					callback: this._onDeleteCard
				});
			}
		} else if (["validation", "resources", "javascript"].indexOf(value) > -1) {
			WorkflowActions.get(function(Workflow) {
				var obj;
				if (agentMode) obj = Workflow.active.card;
				else {
					obj = objects.find(Workflow.working, null, location);
					if (obj !== null && obj.obj !== undefined) obj = obj.obj;
				}

				if ([undefined, null].indexOf(obj) === -1) UIActions.showOverlay(value, obj);
				else UIActions.addMessage("Something went wrong :(", "error");
			});
		} else if (value === "internal note") {
			UIActions.showOverlay("internal note", { text: "" });
		} else if (value === "escalate") {
			UIActions.showOverlay("internal note", {
				escalate: true,
				workflow: true,
				text:
					"What my issue is: <br><br> What I have tried: <br><br> Proposed solution: <br><br>Is this in the ticket guide/what row in the ticket guide did you get stuck on?"
			});
		} else if (value === "wrong workflow") {
			UIActions.showOverlay("internal note", {
				eject: true,
				workflow: true,
				text: "Why is this the wrong workflow?"
			});
		}
	}

	render() {
		var defaultColor = "white";
		if (this.props.item.type === "email") defaultColor = "lightgoldenrodyellow";
		else if (this.props.item.type === "solved") defaultColor = "springgreen";
		else if (this.props.item.type === "escalate") defaultColor = "indianred";

		defaultColor = !this.props.connecting || this.props.connecting === this.props.item.id ? defaultColor : "powderblue";

		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			position: "relative",
			marginLeft: this.props.first ? this.props.item.coordinates.x * 466 + "px" : ""
		};

		const simpleCardStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: "200px",
			padding: "16px",
			fontSize: "16px",
			lineHeight: "16px",
			color: !this.state.hover && defaultColor === "indianred" ? "white" : "rgba(0, 0, 0, 0.62)",
			textAlign: "center",
			borderRadius: "50px",
			cursor: "pointer",
			transition: "all .3s ease",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			border: "1px solid rgb(70, 180, 175)",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : defaultColor,
			zIndex: "2"
		};

		return (
			<div style={primaryStyle}>
				<div
					id={this.props.id}
					key={this.props.id}
					style={simpleCardStyle}
					onClick={this._onFocus}
					onMouseOver={this._onMouseOver}
					onMouseOut={this._onMouseOut}
				>
					{this.props.item.concept}
				</div>
				<Connectors
					id={"connectors-" + this.props.id}
					key={"connectors-" + this.props.id}
					agentMode={this.props.agentMode}
					arrows={this.state.arrows}
					cardId={this.props.item.id}
					coordinates={this.props.item.coordinates}
					onCardConnect={this.props.onCardConnect}
				/>
			</div>
		);
	}
}

export default WorkflowQuestion;
