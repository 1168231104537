//import guid from "./guid";
import shortid from "shortid";

function ensure_ids(target, obj) {
	if (Array.isArray(obj)) {
		for (var i = 0; i < obj.length; i++) ensure_ids(target, obj[i]);
	} else if (typeof obj === "object" && obj !== null) {
		/*
        if (obj.id === undefined && target.maxID !== undefined) obj.id = ++target.maxID;
		else if (obj.id === undefined) obj.id = guid();
        */

		if (obj.id === undefined) obj.id = shortid.generate();
		var keys = Object.keys(obj);

		for (var j = 0; j < keys.length; j++) ensure_ids(target, obj[keys[j]]);
	}
}

export default ensure_ids;
