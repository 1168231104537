import React from "react";

import * as time from "../../../functions/time";

const nameStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "250px",
	textAlign: "left",
	textDecoration: "none"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

class Name extends React.Component {
	render() {
		return (
			<div style={nameStyle}>
				{this.props.item.email || this.props.item.name}
				<span style={labelStyle}>Last updated: {time.parse_time(this.props.item.updated)}</span>
			</div>
		);
	}
}

export default Name;
