import React from "react";

// Actions
import TicketActions from "../../actions/TicketActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Row from "../_common/Row.react";

// Form items
import Input from "../_common/forms/Input.react";
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";
import H2 from "../_common/headers/H2.react";

// Functions
import validate from "../../functions/validate";
import * as time from "../../functions/time";

class Ticket extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		this.props.updateFn(this.props.item.external_id);
	}

	render() {
		const nameStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "16px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "500",
			cursor: "pointer",
			width: "150px",
			textAlign: "center",
			marginRight: "24px"
		};

		const titleStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "400",
			width: "546px",
			textAlign: "left",
			cursor: "pointer"
		};

		const labelStyle = {
			width: "100%",
			display: "inline-block",
			marginTop: "4px",
			fontSize: "12px",
			fontWeight: "400",
			color: "rgba(0, 0, 0, 0.87)"
		};

		return (
			<Row index={this.props.index}>
				<div style={nameStyle} onClick={this._onClick}>
					{this.props.item.external_id}
					<span style={labelStyle}>{time.parse_time(this.props.item.external_updated)}</span>
				</div>
				<div style={titleStyle} onClick={this._onClick}>
					{this.props.item.title}
				</div>
			</Row>
		);
	}
}

// Sets validation
const idValidation = [
	{
		type: "min-length",
		value: 1
	}
];

const commentValidation = [
	{
		type: "min-length",
		value: 4
	}
];

class Merge extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);
		this._onChooseTicket = this._onChooseTicket.bind(this);

		this.state = { id: "", target_comment: "", source_comment: "", loading: false };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ loading: nextProps.status.indexOf("merging ticket") > -1 });
	}

	_onClick() {
		var idError = validate(idValidation, this.state.id);
		var targetError = validate(commentValidation, this.state.target_comment);
		var sourceError = validate(commentValidation, this.state.source_comment);

		if (idError === "" && targetError === "" && sourceError === "") {
			this.setState({ validate: false, loading: true });

			TicketActions.merge({
				id: this.state.id,
				source_comment: this.state.source_comment,
				target_comment: this.state.target_comment
			});
		} else this.setState({ validate: true });
	}

	_onChooseTicket(id) {
		this.setState({
			id,
			target_comment: "Combining with #" + this.props.ticket.external_id,
			source_comment: "Merged in favor of #" + id
		});
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "left"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		const ticketIdStyle = {
			width: "300px"
		};

		const labelStyle = {
			width: "100%",
			textAlign: "center",
			fontSize: "20px",
			margin: "24px 0px",
			fontWeight: "700"
		};

		const containerStyle = {
			display: "inline-block",
			width: "100%"
		};

		return (
			<Overlay>
				<Container maxWidth="800px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text="Merge ticket" textAlign="center" />
						<Input
							id="ticket_id"
							style={ticketIdStyle}
							label="Enter ticket ID to merge into"
							field="id"
							updateFn={this._updateFn}
							value={this.state.id}
							helpText="Zendesk ticket id (e.g., 182302)"
							validate={this.state.validate}
							validation={idValidation}
						/>
						{this.state.id === "" ? (
							<div style={containerStyle}>
								<div style={labelStyle}>OR CHOOSE TICKET BELOW</div>
								{this.props.ticket.requesterTickets.map((item, index, arr) => (
									<Ticket item={item} key={"merge-" + item.id} updateFn={this._onChooseTicket} index={index} />
								))}
							</div>
						) : (
							<div style={containerStyle}>
								<H2
									text="Comments"
									textAlign="left"
									fontSize="20px"
									lineHeight="26px"
									margin="16px"
									width="calc(100% - 32px)"
								/>
								<Input
									id="merge-target-comment"
									label="Private comment to add to target ticket"
									field="target_comment"
									updateFn={this._updateFn}
									value={this.state.target_comment}
									validate={this.state.validate}
									validation={commentValidation}
								/>
								<Input
									id="merge-source-comment"
									label="Private comment to add to current ticket (one being merged)"
									field="source_comment"
									updateFn={this._updateFn}
									value={this.state.source_comment}
									validate={this.state.validate}
									validation={commentValidation}
								/>
							</div>
						)}
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="NEXT" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default Merge;
