import Reflux from "reflux";
import * as async from "async";
import firebase from "firebase/app";
import "firebase/firestore";

// Actions
import TicketActions from "../actions/TicketActions";
import ClientActions from "../actions/ClientActions";
import UIActions from "../actions/UIActions";
import UserActions from "../actions/UserActions";
import WorkflowActions from "../actions/WorkflowActions";

// Functions
import api from "../functions/api";
import * as status from "../functions/status";
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import clone from "../functions/clone";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";

import * as storage from "../functions/storage";
import * as list from "../functions/list";
import * as zendesk from "../functions/zendesk";
import * as slack from "../functions/slack";
import * as autofill from "../functions/autofill";
import * as workflows from "../functions/workflows";
import * as viewing from "../functions/viewing";
import * as client_components from "../functions/client_components";
import * as dom from "../functions/dom";
import * as time from "../functions/time";

// Defines db for firestore
var db = firebase.firestore();

var Ticket = {
    status: [],
    allLoaded: false,
    loadedParts: [],
    mode: "play",
};

// Minute pings
//setTimeout(minute_pings, 60000);
/*
function minute_pings() {
	UserActions.get(function(User) {
		if (User.me !== undefined) {
			var obj = {
				ticketId: Ticket.working !== undefined ? Ticket.working.id : null,
				clientId: Ticket.clientSelected || null,
				userId: User.me.id
			};

			api("post", "/agents/" + User.me.id + "/minute_pings", obj, null, null, Ticket, "minute ping");
		}
	});

	setTimeout(minute_pings, 60000);
}*/

const subcollections = ["events", "requesterTickets", "comments", "appliedWorkflows"];

function setup_live_updaters() {
    Ticket.workingUpdate = [];

    // Sets up monitoring
    Ticket.workingUpdate.push(
        db
            .collection("clients")
            .doc(Ticket.clientSelected)
            .collection("tickets")
            .doc(Ticket.working.id)
            .onSnapshot(function (snapshot) {
                var newObj = firestore.extract_single(snapshot);
                for (var i = 0; i < subcollections.length; i++) {
                    newObj[subcollections[i]] = Ticket.working[subcollections[i]];
                }

                newObj.viewing = Ticket.working.viewing;
                newObj.clientId = Ticket.working.clientId;

                Ticket.working = newObj;
                var oldReply = objects.clone(Ticket.reply);
                setup_reply(true);

                Ticket.reply.internal = oldReply.internal;
                Ticket.reply.public = oldReply.public;
                Ticket.reply.text = oldReply.text;

                if (Ticket.allLoaded) TicketActions.update();
            })
    );

    // Sets up subcollection monitoring
    for (var j = 0; j < subcollections.length; j++) setup_live_updates_subcollections(subcollections[j]);
}

function setup_live_updates_subcollections(subcollection) {
    if (subcollection === "requesterTickets") {
        var now = new Date();
        now.setDate(now.getDate() - 2);
        now = new Date(now);

        Ticket.workingUpdate.push(
            db
                .collection("clients")
                .doc(Ticket.clientSelected)
                .collection("tickets")
                .where("requester_email", "==", Ticket.working.requester_email) //.where("external_updated", ">", now)
                .orderBy("external_updated", "desc")
                .limit(10)
                .onSnapshot(function (snapshot) {
                    if (Ticket.working !== undefined) {
                        var results = firestore.extract(snapshot);
                        for (var i = 0; i < results.length; i++) {
                            if (results[i].id === Ticket.working.id) {
                                results.splice(i, 1);
                                break;
                            }
                        }

                        Ticket.working[subcollection] = workflows.status_parse(results);
                    }

                    check_all_loaded(subcollection);
                    if (Ticket.allLoaded) TicketActions.update();
                })
        );
    } else if (subcollection === "events") {
        Ticket.workingUpdate.push(
            db
                .collection("events")
                .where("ticketId", "==", Ticket.working.id)
                .orderBy("start", "desc")
                .onSnapshot(function (snapshot) {
                    //if (Ticket.working !== undefined) Ticket.working[subcollection] = firestore.extract(snapshot);
                    update_events(firestore.extract(snapshot) || []);
                    check_all_loaded(subcollection);
                    if (Ticket.allLoaded) TicketActions.update();
                })
        );
    } else if (subcollection === "appliedWorkflows") {
        Ticket.workingUpdate.push(
            db
                .collection("clients")
                .doc(Ticket.clientSelected)
                .collection("appliedWorkflows")
                .where("ticketId", "==", Ticket.working.id)
                .onSnapshot(function (snapshot) {
                    Ticket.working.appliedWorkflows = firestore.extract(snapshot);
                    check_all_loaded(subcollection);
                    setup_active_workflow();
                    if (Ticket.allLoaded) TicketActions.update();
                })
        );
    } else {
        Ticket.workingUpdate.push(
            db
                .collection("clients")
                .doc(Ticket.clientSelected)
                .collection("tickets")
                .doc(Ticket.working.id)
                .collection(subcollection)
                .onSnapshot(function (snapshot) {
                    if (Ticket.working !== undefined) Ticket.working[subcollection] = firestore.extract(snapshot);
                    check_all_loaded(subcollection);

                    if (subcollection === "comments") {
                        Ticket.working[subcollection] = list.order(Ticket.working[subcollection], "date oldest");
                        setup_reply_recipients();
                    }

                    if (subcollection === "comments" && Ticket.working.source === "front") {
                        for (var i = 0; i < Ticket.working.comments.length; i++) {
                            Ticket.working.comments[i].text = Ticket.working.comments[i].text.replace(
                                /\/api\/1/g,
                                "https://app.frontapp.com/api/1"
                            );
                        }
                    }

                    if (Ticket.allLoaded) TicketActions.update();
                })
        );
    }
}

function update_events(events) {
    if (events !== undefined) {
        async.waterfall(
            [
                function (callback) {
                    viewing.ensure_users(events, [], "userId", "agent", callback);
                },
            ],
            function (err, results) {
                console.log(results);
                Ticket.working.events = results;
                TicketActions.update();
            }
        );
    }
}

function check_all_loaded(subcollection) {
    if (Ticket.loadedParts.indexOf(subcollection) === -1) Ticket.loadedParts.push(subcollection);
    if (Ticket.loadedParts.length === subcollections.length) Ticket.allLoaded = true;
}

function setup_reply(ticketAlreadyOpen) {
    if (Ticket.working.tags.indexOf("hioperator") === -1) Ticket.working.tags.push("hioperator");

    Ticket.reply = {
        text: "",
        to: ticketAlreadyOpen ? Ticket.reply.to : [],
        cc: ticketAlreadyOpen ? Ticket.reply.cc : [],
        bcc: ticketAlreadyOpen ? Ticket.reply.bcc : [],
        external_priority: Ticket.working.external_priority || null,
        external_type: Ticket.working.external_type || Ticket.working.type || null,
        public: true,
        tags: clone(Ticket.working.tags),
        start: new Date(),
        custom_fields: Ticket.working.custom_fields || [],
        status: Ticket.working.external_status,
        clientId: Ticket.clientSelected,
        assignee_id: ticketAlreadyOpen ? Ticket.reply.assignee_id || "" : "",
    };

    if (!ticketAlreadyOpen || [null, undefined, ""].indexOf(Ticket.reply.assignee_id) > -1) run_ticket_checks();
}

var setup_reply_recipients = () => {
    if (Ticket.working.source === "zendesk") {
        if (Ticket.reply.to.length === 0) Ticket.reply.to.push(Ticket.working.requester_email || "");
    } else if (Ticket.working.source === "front") {
        if (Ticket.component === undefined || Ticket.component.channels === undefined) return;

        var comments = list.order(Ticket.working.comments, "date newest");
        var j, channel;

        for (var i = 0; i < comments.length; i++) {
            channel = get_channel(comments[i].channel);
            if (
                (comments[i].channel === "sms" && comments[i].is_inbound) ||
                ([null, undefined].indexOf(channel) === -1 &&
                    channel.emails !== undefined &&
                    channel.emails.indexOf(comments[i].author_email) === -1)
            ) {
                if (Ticket.reply.to.length === 0) Ticket.reply.to.push(comments[i].reply_to || comments[i].author_email);
                if (Ticket.reply.cc.length === 0 && comments[i].to !== undefined) {
                    for (j = 0; j < comments[i].to.length; j++) {
                        if (
                            [null, undefined].indexOf(channel) === -1 &&
                            channel.emails !== undefined &&
                            channel.emails.indexOf(comments[i].to[j].email) === -1
                        )
                            Ticket.reply.cc.push(comments[i].to[j].email);
                    }
                }

                break;
            }
        }
    } else {
        if (Ticket.reply.to.length === 0) Ticket.reply.to.push(Ticket.working.requester_email || "");
    }
};

var get_channel = (type) => {
    if (Ticket.component === undefined || Ticket.component.channels === undefined) return null;
    for (var i = 0; i < Ticket.component.channels.length; i++) {
        if (Ticket.component.channels[i].type === type) return Ticket.component.channels[i];
    }

    return null;
};

function setup_live_updates_viewing() {
    Ticket.workingUpdate.push(
        db
            .collection("activeSessions")
            .where("viewing.ticketId", "==", Ticket.working.id)
            .onSnapshot(function (snapshot) {
                if (Ticket.working.viewing === undefined) Ticket.working.viewing = [];
                viewing.update(firestore.extract(snapshot), Ticket.working, "viewing", TicketActions.update);
            })
    );
}

/////////////////////////////////////////////////////////////
// Funcitons for dealing with messagesdev
/////////////////////////////////////////////////////////////

// Figures out if inside of client
function client_check() {
    const myRoute = document.location.hash.replace("#", "").split("/");
    if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
    else return null;
}

function get_api() {
    const client = client_check();

    if (client !== null) return "/clients/" + client;
    else if (document.location.hash.indexOf("triage") > -1) return "/traige";
    else return "";
}

function update_client() {
    const client = client_check();
    if (client !== null) {
        ClientActions.get(function (Client, onNotify) {
            if (Client.working.Tickets === undefined) Client.working.Tickets = [];

            Ticket.working.updated = new Date();

            var pass = true;
            for (var i = 0; i < Client.working.tickets.length; i++) {
                if (Client.working.Tickets[i].id === Ticket.working.id) {
                    Client.working.Tickets[i] = objects.clone(Ticket.working);
                    pass = false;
                    break;
                }
            }

            if (pass) Client.working.Tickets.push(objects.clone(Ticket.working));

            onNotify();
        });
    }
}

// Deals with updating reply
function set_reply(obj, overwrite, beforeSignature) {
    console.log("HIT SET REPLY");
    if (obj.body !== undefined)
        Ticket.reply.text = overwrite
            ? autofill.single(dom.fix_bad_html(obj.body), Ticket)
            : Ticket.reply.text + autofill.single(dom.fix_bad_html(obj.body), Ticket);

    if (beforeSignature) fill_signature();
    var assignee_group, assignee_person, j, i, tags, field;

    if (obj.actions !== undefined) {
        for (i = 0; i < obj.actions.length; i++) {
            if (obj.actions[i].field === "subject") Ticket.reply.subject = obj.actions[i].value;
            else if (obj.actions[i].field === "status") Ticket.reply.status = obj.actions[i].value;
            else if (obj.actions[i].field === "type") Ticket.reply.external_type = obj.actions[i].value;
            else if (obj.actions[i].field === "priority") Ticket.reply.external_priority = obj.actions[i].value;
            else if (obj.actions[i].field === "group_id") assignee_group = obj.actions[i].value;
            else if (obj.actions[i].field === "assignee_id") assignee_person = obj.actions[i].value;
            else if (["set_tags", "current_tags"].indexOf(obj.actions[i].field) > -1) {
                tags = obj.actions[i].value.split(" ");
                for (j = 0; j < tags.length; j++) {
                    if (Ticket.reply.tags.indexOf(tags[j]) === -1) Ticket.reply.tags.push(tags[j]);
                }
            } else if (obj.actions[i].field === "remove_tags") {
                tags = obj.actions[i].value.split(" ");
                for (j = 0; j < tags.length; j++) {
                    if (Ticket.reply.tags.indexOf(tags[j]) > -1) {
                        Ticket.reply.tags.splice(Ticket.reply.tags.indexOf(tags[j]), 1);
                    }
                }
            } else if (obj.actions[i].field === "cc") {
                if (!Array.isArray(Ticket.reply.cc)) Ticket.reply.cc = [];
                if (Ticket.reply.cc.indexOf(obj.actions[i].value) === -1) Ticket.reply.cc.push(obj.actions[i].value);
            } else if (obj.actions[i].field === "comment_mode_is_public") Ticket.reply.public = obj.actions[i].value === "true";
            else if (obj.actions[i].field.indexOf("custom_fields") > -1) {
                field = obj.actions[i].field.replace("custom_fields_", "");
                for (j = 0; j < Ticket.reply.custom_fields.length; j++) {
                    if (String(Ticket.reply.custom_fields[j].id) === field) {
                        if (Array.isArray(Ticket.reply.custom_fields[j].value))
                            Ticket.reply.custom_fields[j].value.push(obj.actions[i].value);
                        else Ticket.reply.custom_fields[j].value = obj.actions[i].value;
                    }
                }
            }
        }
    }

    // New format for macros
    if (obj.tags !== undefined) Ticket.reply.tags = Ticket.reply.tags.concat(obj.tags);
    if (obj.assignee_id !== undefined) Ticket.reply.assignee_id = obj.assignee_id;
    if (obj.external_priority !== undefined) Ticket.reply.external_priority = obj.external_priority;
    if (obj.external_status !== undefined) Ticket.reply.external_status = obj.external_status;
    if (obj.custom_fields !== undefined) {
        for (i = 0; i < obj.custom_fields.length; i++) {
            for (j = 0; j < Ticket.reply.custom_fields.length; j++) {
                if (String(Ticket.reply.custom_fields[j].id) === obj.custom_fields[i].id) {
                    if (Array.isArray(Ticket.reply.custom_fields[j].value))
                        Ticket.reply.custom_fields[j].value.push(obj.custom_fields[i].value);
                    else Ticket.reply.custom_fields[j].value = obj.custom_fields[i].value;
                }
            }
        }
    }
}

// Handles adding attachments
function add_attachments(files, type) {
    if (Ticket.working.source === "zendesk") {
        const statusCode = "adding attachments";
        if (!status.has(Ticket, statusCode))
            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/attachments",
                { files },
                TicketActions.addAttachmentsCompleted,
                TicketActions.addAttachmentsFailed,
                Ticket,
                statusCode
            );
    } else {
        var attachments = [];
        for (var i = 0; i < files.length; i++) {
            attachments.push({
                content_url: files[i].url,
                file_name: files[i].filename,
                content_type: files[i].contentType,
            });
        }

        if (Ticket.isWorkflowCard) {
            WorkflowActions.setAttachment("front", attachments);
        } else {
            if (Ticket.reply.attachments === undefined) Ticket.reply.attachments = [];
            Ticket.reply.attachments = Ticket.reply.attachments.concat(attachments);
            TicketActions.update();
        }
    }
}

// Handles submit checks
function check_cc() {
    if (Ticket.reply.cc === undefined || Ticket.reply.cc.length === 0) return true;
    else {
        var item;
        for (var i = 0; i < Ticket.reply.cc.length; i++) {
            item = Ticket.reply.cc[i];
            if (item.indexOf("@") === -1 || item.indexOf(".") === -1) {
                UIActions.addMessage("Please make sure all CC emails are formatted correctly", "error");
                return false;
            }
        }

        return true;
    }
}

// Gets integration
function run_ticket_checks() {
    ClientActions.get(function (Client) {
        if (Ticket.working !== undefined && Client.working !== undefined && [null, undefined, ""].indexOf(Ticket.reply.assignee_id) > -1) {
            var i;
            var component = client_components.find(Client.working.components || [], Ticket.working.source);

            if (component !== null && component.clientAgents !== undefined) {
                for (i = 0; i < component.clientAgents.length; i++) {
                    if (component.clientAgents[i].hioperatorAgent) {
                        Ticket.reply.assignee_id = component.clientAgents[i].id;
                        break;
                    }
                }
            }

            fill_signature(component);

            Ticket.component = component || {};
            setup_reply_recipients();
        }
    });
}

var fill_signature = (component) => {
    if (component !== undefined) {
        if (component !== null && component.signature) {
            const sig = autofill.single("<div></div>" + dom.fix_bad_html(component.signature), Ticket);
            Ticket.reply.text = Ticket.reply.text.replace(sig, "") + sig;
        }

        ClientActions.update();
    } else {
        ClientActions.get(function (Client) {
            if (Ticket.working !== undefined && Client.working !== undefined) {
                var component = client_components.find(Client.working.components || [], Ticket.working.source);
                fill_signature(component);
            }
        });
    }
};

function reset_ticket_in_queue() {
    if (Ticket.message !== undefined) {
        const statusCode = "resetting ticket";
        if (!status.has(Ticket, statusCode))
            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.message.ticketId + "/reset",
                Ticket.message,
                null,
                null,
                Ticket,
                statusCode
            );
    }
}

function setup_active_workflow() {
    var fields = ["requester_email", "requester_name", "external_status"];
    if (Ticket.ticketWorkflow !== undefined && Ticket.working !== undefined && Ticket.working.appliedWorkflows !== undefined) {
        for (var i = 0; i < Ticket.working.appliedWorkflows.length; i++) {
            if (Ticket.ticketWorkflow === Ticket.working.appliedWorkflows[i].id) {
                if (Ticket.specificWorkflow) {
                    Ticket.working.appliedWorkflows[i].concepts = {};
                    Ticket.working.appliedWorkflows[i].responses = [];
                    delete Ticket.working.appliedWorkflows[i].current;
                }

                for (var j = 0; j < fields.length; j++) Ticket.working.appliedWorkflows[i].concepts[fields[j]] = Ticket.working[fields[j]];

                WorkflowActions.setTicketWorflow(Ticket.working.appliedWorkflows[i], {
                    clientId: Ticket.clientSelected,
                    ticketId: Ticket.working.id,
                });
                break;
            }
        }
    }
}

function place_ticket_back_in_queue() {
    var ref = db.collection("clients").doc(Ticket.clientSelected).collection("queue").doc(Ticket.working.id);

    const message = {
        clientId: Ticket.clientSelected,
        integrationId: Ticket.working.integrationId,
        ticketId: Ticket.working.id,
        stage: "prod",
        date: new Date(),
        priority: zendesk.find_priority(Ticket.working.external_priority),
        claimed: false,
    };

    async.waterfall(
        [
            function (callback) {
                ref.get()
                    .then(function (snapshot) {
                        var doc = firestore.extract_single(snapshot);
                        if (doc === undefined || doc.id === undefined) callback(null, true);
                        else callback(null, false);
                    })
                    .catch(callback);
            },
            function (addTicket, callback) {
                db.collection("clients")
                    .doc(message.clientId)
                    .collection("queue")
                    .doc(message.ticketId)
                    .set(message)
                    .then(function () {
                        callback(null, addTicket);
                    })
                    .catch(callback);
            },
            function (addTicket, callback) {
                if (addTicket) {
                    ClientActions.get(function (client) {
                        var queueCount = client.working.queueCount || 0;
                        queueCount++;

                        db.collection("clients")
                            .doc(message.clientId)
                            .update({ queueCount: queueCount })
                            .then(function () {
                                callback(null);
                            })
                            .catch(callback);
                    });
                } else callback(null, null);
            },
        ],
        function (err) {
            console.log("add ticket to queue!");
        }
    );
}

function add_internal_note(data) {
    if (data.text !== undefined && Ticket.working !== undefined && Ticket.clientSelected !== undefined) {
        UserActions.get(function (User) {
            db.collection("clients")
                .doc(Ticket.clientSelected)
                .collection("tickets")
                .doc(Ticket.working.id)
                .collection("comments")
                .doc()
                .set({
                    text: data.text,
                    date: new Date(),
                    internal: true,
                    public: false,
                    author_name: User.me.name,
                    author_email: User.me.email,
                    author_id: User.me.id,
                });
        });

        if (data.escalate) TicketActions.escalate("escalate", data.text, data.workflow);
        else if (data.eject) WorkflowActions.stopWorkflow("complete");
    }
}

function get_ticket(type, currentTicket, messageId) {
    TicketActions.reset();
    if (type === undefined) type = document.location.hash.indexOf("workflows") > -1 ? "workflows" : "triage";

    const statusCode = "getting ticket from queue";
    var url = type;

    delete Ticket.specificWorkflow;

    if (messageId !== undefined) {
        url += "/message/" + messageId;
        Ticket.specificWorkflow = true;
    } else if (currentTicket !== undefined) url += "/" + currentTicket;

    if (!status.has(Ticket, statusCode)) {
        api(
            "get",
            "/clients/" + Ticket.clientSelected + "/queue/" + url,
            null,
            TicketActions.getTicketCompleted,
            TicketActions.getTicketFailed,
            Ticket,
            statusCode,
            type
        );
    }
}

////////////////////////////////////////////////////
// Time tracking
////////////////////////////////////////////////////

function ticket_timer(id) {
    var now = new Date();
    now.setMinutes(now.getMinutes() - 10);

    if (
        Ticket.mode !== "pause" &&
        Ticket.working !== undefined &&
        Ticket.reply !== undefined &&
        Ticket.message !== undefined &&
        Ticket.reply.start < now &&
        id !== Ticket.working.id
    ) {
        id = Ticket.working.id;
        UserActions.get(function (User) {
            slack.send(
                User.me.email +
                    " has been working on ticket " +
                    document.location.origin +
                    document.location.pathname +
                    "/#clients/" +
                    Ticket.clientSelected +
                    "/tickets/" +
                    Ticket.working.id +
                    " for more than 10 minutes. Team lead, maybe check in to see if you can help?",
                Ticket.clientSelected
            );
        });
    }

    setTimeout(function () {
        ticket_timer(id);
    }, 60000);
}

ticket_timer(null);

var assign_agent = (value, workflowId) => {
    if (workflowId === undefined) {
        Ticket.working.active_agent = value;

        api(
            "put",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/assign/" + value,
            null,
            TicketActions.assignAgentCompleted,
            TicketActions.assignAgentFailed,
            Ticket,
            "assigning ticket"
        );
    } else {
        Ticket.working.workflows[workflowId].active_agent = value;
        api(
            "put",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/workflows/" + workflowId + "/assign/" + value,
            null,
            TicketActions.assignAgentCompleted,
            TicketActions.assignAgentFailed,
            Ticket,
            "assigning ticket"
        );
    }

    TicketActions.update();
};

var parse_active_tickets = (tickets) => {
    var newTickets;
    async.waterfall(
        [
            function (callback) {
                var temp = objects.clone(tickets);
                tickets = [];
                for (var i = 0; i < temp.length; i++) {
                    if (temp[i].viewing !== undefined && temp[i].viewing.clientId !== undefined) tickets.push(temp[i]);
                }

                viewing.update_by_group(tickets, "ticketId", callback);
            },
            function (results, callback) {
                var ids = [];
                for (var i = 0; i < results.length; i++) ids.push({ ticketId: results[i].ticketId, clientId: results[i].clientId });
                newTickets = results;

                load_active_tickets(ids, [], callback);
            },
        ],
        function (err, results) {
            for (var i = 0; i < results.length; i++) results[i].viewing = newTickets[i].users;

            Ticket.active = results;
            TicketActions.update();
        }
    );
};

var load_active_tickets = (ids, results, master_callback) => {
    async.map(
        ids,
        function (id, callback) {
            db.collection("clients")
                .doc(id.clientId)
                .collection("tickets")
                .doc(id.ticketId)
                .get()
                .then(function (snapshot) {
                    var ticket = firestore.extract_single(snapshot);
                    if (ticket !== undefined) callback(null, ticket);
                    else callback(null);
                })
                .catch(callback);
        },
        function (err, results) {
            var final = [];
            for (var i = 0; i < results.length; i++) {
                if (results[i] !== undefined) final.push(results[i]);
            }

            master_callback(err, final);
        }
    );

    /*
	if (ids.length > 0) {
		var single = ids.splice(0, 1)[0];
		db.collection("clients")
			.doc(single.clientId)
			.collection("tickets")
			.doc(single.ticketId)
			.get()
			.then(function(snapshot) {
				var ticket = firestore.extract_single(snapshot);
				results.push(ticket);
				load_active_tickets(ids, results, master_callback);
			})
			.catch(master_callback);
    } else master_callback(null, results); */
};

////////////////////////////////////////////////////
// Utilities
////////////////////////////////////////////////////

var fix_underscores = (obj) => {
    if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            if (Array.isArray(obj[i])) obj[i] = "omitted array";
            else fix_underscores(obj[i]);
        }
    } else if ([null, undefined].indexOf(obj) === -1 && typeof obj === "object") {
        var myKeys = Object.keys(obj);

        for (var j = 0; j < myKeys.length; j++) {
            if (myKeys[j][0] === "_" || myKeys[j][myKeys[j].length - 1] === "_") delete obj[myKeys[j]];
            else if (obj[myKeys[j]] === undefined) delete obj[myKeys[j]];
            else if (typeof obj[myKeys[j]] === "object") fix_underscores(obj[myKeys[j]]);
        }
    }
};

////////////////////////////////////////////////////
// Actual store
////////////////////////////////////////////////////

class TicketStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { TicketStore: Ticket };
        this.listenToMany(TicketActions);
    }

    onLoad(data, blockView) {
        status.add(Ticket, "getting ticket");
        if (Ticket.working === undefined || Ticket.working.id !== data.ticketId) {
            TicketActions.unsubscribe();
            Ticket.allLoaded = false;
            Ticket.loadedParts = [];
            delete Ticket.working;

            const clientId = url.get_client() || Ticket.clientSelected;
            Ticket.clientSelected = clientId;

            db.collection("clients")
                .doc(clientId)
                .collection("tickets")
                .doc(data.ticketId || data.id)
                .get()
                .then(function (snapshot) {
                    Ticket.working = firestore.extract_single(snapshot);
                    TicketActions.loadCompleted(Ticket.working, blockView);

                    UserActions.startViewing(data);
                })
                .catch(TicketActions.loadFailed);

            if (data.workflowId !== undefined)
                WorkflowActions.load(data.workflowId, { clientId: clientId, specificWorkflow: Ticket.specificWorkflow || false }, true);
        }
    }

    onLoadCompleted(result, blockView) {
        status.remove(Ticket, "getting ticket");
        status.remove(Ticket, "submitting ticket");
        Ticket.working.cliendId = Ticket.clientSelected;

        if (Ticket.working.tags === undefined) Ticket.working.tags = [];

        setup_reply(false);

        if (Ticket.workingUpdate === undefined) setup_live_updaters();
        setup_live_updates_viewing();

        if (!blockView) {
            UIActions.showOverlay("");
        }

        this.notify();
    }

    onLoadFailed(error) {
        status.remove(Ticket, "loading ticket");
        UIActions.addMessage("Couldn't load ticket", "error");
        this.notify();
    }

    onLoadActive() {
        var now = new Date();
        now = now.setHours(now.getHours() - 12);
        now = new Date(now);

        Ticket.activeUpdate = db
            .collection("activeSessions")
            .where("start", ">", now)
            .onSnapshot(function (snapshot) {
                parse_active_tickets(firestore.extract(snapshot));
            });
    }

    onReleaseTicket(type) {
        const statusCode = "releasing ticket from queue " + Ticket.working.id;
        if (!status.has(Ticket, statusCode)) {
            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/reset",
                Ticket.message,
                TicketActions.releaseTicketCompleted,
                TicketActions.error,
                Ticket,
                statusCode,
                type
            );
        }
    }

    onReleaseTicketCompleted(result, type) {
        if (type === "triage") TicketActions.getTicket(type);
    }

    onGetTicket(type, currenTicket, messageId) {
        console.log("GETTING TICKET " + new Date().toISOString());
        get_ticket(type, currenTicket, messageId);
    }

    onGetTicketCompleted(result, type) {
        console.log("TICKET RECEIVED " + new Date().toISOString());
        if (result.ticketId !== undefined) {
            if (Ticket.specificWorkflow) {
                Ticket.clientSelected = result.clientId;
                ClientActions.load(Ticket.clientSelected, true);
                ClientActions.loadSubcollection("agents", {
                    path: "permissions",
                    where: [["clientId", "==", Ticket.clientSelected]],
                });
                ClientActions.loadSubcollection("components", { path: "clients/" + Ticket.clientSelected + "/components" });
                ClientActions.loadSubcollection("products", {
                    path: "clients/" + Ticket.clientSelected + "/products",
                    where: [["active", "==", true]],
                });
            }

            Ticket.message = result;
            TicketActions.load(result, true);

            if (result.workflowId !== undefined) {
                Ticket.ticketWorkflow = result.id;
                setup_active_workflow();
            }
        } else {
            status.remove(Ticket, "getting ticket");
            setTimeout(function () {
                if (Ticket.working === undefined) {
                    TicketActions.getTicket(type);
                }
            }, 30000);

            this.notify();
        }
    }

    onGetTicketFailed(error) {
        status.remove(Ticket, "loading ticket");
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onSave() {
        const statusCode = "saving ticket";
        if (!status.has(Ticket, statusCode))
            api(
                "post",
                "/tickets/" + Ticket.working.id + get_api(),
                Ticket.working,
                TicketActions.saveCompleted,
                TicketActions.error,
                Ticket,
                statusCode
            );

        this.notify();
    }

    onSaveCompleted(result) {
        UIActions.addMessage("Ticket saved!", "success");
        update_client();
        this.notify();
    }

    onDelete() {
        const statusCode = "deleting ticket";
        if (!status.has(Ticket, statusCode))
            api(
                "delete",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id,
                null,
                TicketActions.deleteCompleted,
                TicketActions.error,
                Ticket,
                statusCode
            );

        TicketActions.close();
        UIActions.goBack();
    }

    onDeleteCompleted() {
        UIActions.addMessage("Ticket succesfully deleted!", "success");
    }

    onSaveAppliedWorkflow(obj) {
        var id = obj.id;
        delete obj.id;

        fix_underscores(obj);

        obj.ticketId = Ticket.working.id;

        db.collection("clients").doc(Ticket.clientSelected).collection("appliedWorkflows").doc(id).set(obj);
    }

    onStopWorkflow(status) {
        delete Ticket.message.claimed;
        Ticket.message.status = status;

        const statusCode = "editing workflow to ticket";
        api(
            "put",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/workflows/" + Ticket.message.id,
            Ticket.message,
            TicketActions.update,
            TicketActions.error,
            Ticket,
            statusCode
        );

        var ticketUpdate = {
            ["workflows." + Ticket.message.id + ".status"]: status,
            ["workflows." + Ticket.message.id + ".active_agent"]: firebase.firestore.FieldValue.delete(),
            ["workflows." + Ticket.message.id + ".assignedManually"]: firebase.firestore.FieldValue.delete(),
            active_agent: firebase.firestore.FieldValue.delete(),
        };

        if (status === "awaiting response") {
            ticketUpdate["workflows." + Ticket.message.id + ".awaitingResponse"] = true;

            for (var i = Ticket.working.comments.length - 1; i > -1; i--) {
                if (Ticket.working.comments[i].author_email === Ticket.working.requester_email) {
                    ticketUpdate.last_requester_comment = Number(Ticket.working.comments[i].id);
                    break;
                }
            }
        }

        // Adds event
        var duration = Ticket.working.duration || 0;
        UserActions.addEvent(
            {
                clientId: Ticket.clientSelected,
                ticketId: Ticket.working.id,
                action: "stop workflow",
                status: Ticket.reply.status,
                start: Ticket.reply.start,
                value: {
                    status: status,
                },
            },
            duration
        );

        /*
		UserActions.addAnalytics({
			clientId: Ticket.clientSelected,
			ticketId: Ticket.working.id,
			action: "stop workflow",
			status: Ticket.reply.status,
			start: Ticket.reply.start
        });
        */

        db.collection("clients").doc(Ticket.message.clientId).collection("tickets").doc(Ticket.working.id).update(ticketUpdate);

        WorkflowActions.close("agent");
        delete Ticket.working;
        delete Ticket.message;

        if (!Ticket.specificWorkflow) TicketActions.getTicket("workflows");
    }

    onSetField(field, location, value, shouldNotify, reply) {
        if (field === "chosen-client") {
            reset_ticket_in_queue();
            Ticket.clientSelected = value;
            ClientActions.load(value, true);
            ClientActions.loadSubcollection("agents", { path: "permissions", where: [["clientId", "==", value]] });
            ClientActions.loadSubcollection("components", { path: "clients/" + value + "/components" });

            if (document.location.hash.indexOf("ticketworkflows") > -1) {
                ClientActions.loadSubcollection("products", {
                    path: "clients/" + value + "/products",
                    where: [["active", "==", true]],
                });
            } else {
                ClientActions.loadSubcollection("workflows", {
                    path: "clients/" + value + "/workflows",
                    where: [["active", "==", true]],
                });
                ClientActions.loadSubcollection("macros", {
                    path: "clients/" + value + "/macros",
                    where: [["active", "==", true]],
                });
            }
        } else {
            setField(reply ? Ticket.reply : Ticket.working, field, location, value);
            if (shouldNotify) this.notify();
        }
    }

    onSetCustomField(obj) {
        var pass = true;

        for (var i = 0; i < Ticket.reply.custom_fields.length; i++) {
            if (Ticket.reply.custom_fields[i].id === obj.id) {
                Ticket.reply.custom_fields[i].value = obj.value;
                pass = false;
                break;
            }
        }

        if (pass) Ticket.reply.custom_fields.push(obj);

        this.notify();
    }

    onSetSystemField(field, ticketField, value) {
        Ticket.reply[field] = value;
        Ticket.working[ticketField] = value;
        this.notify();
    }

    onSetReply(obj, overwrite, beforeSignature) {
        set_reply(obj, overwrite, beforeSignature);
        this.notify();
    }

    onSetFullReply(obj) {
        var fields = [
            "text",
            "external_priority",
            "external_type",
            "public",
            "tags",
            "custom_fields",
            "status",
            "assignee_id",
            "attachments",
            "attachmentTokens",
            "customTicketID",
            "cc",
            "bcc",
            "requester_email",
            "requester_name",
            "subject",
        ];

        for (var i = 0; i < fields.length; i++)
            if (obj[fields[i]] !== undefined) {
                if (fields[i] === "text")
                    Ticket.reply[fields[i]] = obj[fields[i]].replace(/\n/g, "<br />").replace(/<p>/g, "<div>").replace(/<\/p>/g, "</div>");
                else Ticket.reply[fields[i]] = obj[fields[i]];
            }
    }

    onSubmit(value, type, getTicket, nextPath) {
        value = value || Ticket.working.external_status;

        if (value === "solved" && Ticket.working.source === "front") value = "archived";
        if (value === "stay") {
            getTicket = false;
            value = "open";
        }

        const statusCode = "submitting ticket";
        if (check_cc()) {
            var passThrough = {
                blockView: false,
                getTicket,
                type,
                nextPath,
            };

            Ticket.reply.status = value;
            Ticket.reply.action = "submit";

            if (Ticket.reply.status === "new") delete Ticket.reply.assignee_id;
            if (Ticket.reply.status === "new" && Ticket.working.source === "front") Ticket.reply.status = "open";
            if (Ticket.reply.status === "archived" && Ticket.working.source === "front" && Ticket.reply.tags.indexOf("done") === -1)
                Ticket.reply.tags.push("done");

            // Figures out tags
            var i;
            Ticket.reply.addedTags = [];
            Ticket.reply.removedTags = [];

            for (i = 0; i < Ticket.reply.tags.length; i++) {
                if (Ticket.working.tags.indexOf(Ticket.reply.tags[i]) === -1) Ticket.reply.addedTags.push(Ticket.reply.tags[i]);
            }

            for (i = 0; i < Ticket.working.tags.length; i++) {
                if (Ticket.reply.tags.indexOf(Ticket.working.tags[i]) === -1) Ticket.reply.removedTags.push(Ticket.working.tags[i]);
            }

            // Finishes reply
            var reply = objects.clone(Ticket.reply);
            reply.text = dom.strip_outer(Ticket.reply.text) === "" ? "" : dom.correct_for_submit(Ticket.reply.text);

            // Adds event
            var duration = Ticket.working.duration || 0;
            UserActions.addEvent(
                {
                    clientId: Ticket.clientSelected,
                    ticketId: Ticket.working.id,
                    action: Ticket.reply.action,
                    status: Ticket.reply.status,
                    start: Ticket.reply.start,
                },
                duration
            );

            Ticket.reply.start = new Date();

            UserActions.get((User) => {
                var permission = {};
                for (i = 0; i < User.permissions.length; i++) {
                    if (User.permissions[i].clientId === Ticket.working.clientId) {
                        permission = User.permissions[i];
                        break;
                    }
                }

                if (User.me.sandboxMode || permission.sandbox) reply.sandbox = true;

                api(
                    "post",
                    "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/submit",
                    reply,
                    TicketActions.submitCompleted,
                    TicketActions.submitFailed,
                    Ticket,
                    statusCode,
                    passThrough,
                    this.notify
                );
            });

            //if (passThrough.getTicket) TicketActions.getTicket(passThrough.type, Ticket.working.id);
        }
    }

    onSubmitCompleted(result, passThrough) {
        if (passThrough.getTicket) TicketActions.getTicket(passThrough.type, Ticket.working.id);
        if (document.location.href.indexOf("tickettriage") === -1 && document.location.href.indexOf("ticketworkflows") === -1)
            this.notify();
        if (passThrough.nextPath !== undefined) WorkflowActions.continueNextCard(passThrough.nextPath);
    }

    onSubmitFailed(error) {
        UIActions.addMessage(String(error), "error");
        this.notify();
    }

    onEscalate(type, message, inWorkflow) {
        var ref = db.collection("clients").doc(Ticket.clientSelected).collection("tickets").doc(Ticket.working.id);

        var obj = { ticketId: Ticket.working.id };

        if (type === "escalate") {
            ref.update({
                escalate: "internal",
                isEscalated: true,
                active_agent: firebase.firestore.FieldValue.delete(),
                external_updated: new Date(),
            })
                .then(function () {})
                .catch(TicketActions.submitFailed);

            obj.escalate = "internal";
            UserActions.get(function (User) {
                slack.send(
                    User.me.email +
                        " escalated ticket " +
                        document.location.origin +
                        document.location.pathname +
                        "/#clients/" +
                        Ticket.clientSelected +
                        "/tickets/" +
                        Ticket.working.id +
                        "\n\n" +
                        (message || ""),
                    Ticket.clientSelected
                );
            });
        } else if (type === "escalate external") {
            ref.update({
                escalate: "external",
                isEscalated: true,
                active_agent: firebase.firestore.FieldValue.delete(),
                external_updated: new Date(),
            })
                .then(function () {})
                .catch(TicketActions.submitFailed);

            obj.escalate = "external";
        } else if (type === "deescalate") {
            ref.update({
                escalate: "",
                isEscalated: false,
                external_updated: new Date(),
            })
                .then(function () {})
                .catch(TicketActions.submitFailed);

            if (
                (Ticket.working.deleted === false || Ticket.working.deleted === undefined) &&
                ["new", "open"].indexOf(Ticket.working.external_status) > -1
            )
                place_ticket_back_in_queue();
        }

        // Adds event
        var duration = Ticket.working.duration || 0;
        UserActions.addEvent(
            {
                clientId: Ticket.clientSelected,
                ticketId: Ticket.working.id,
                action: type,
                status: Ticket.reply.status,
                start: Ticket.reply.start,
            },
            duration
        );

        Ticket.reply.start = new Date();

        api(
            "post",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/escalate",
            obj,
            null,
            null,
            Ticket,
            "escalating ticket"
        );

        this.notify();

        if (inWorkflow) {
            WorkflowActions.stopWorkflow("complete");
        }
    }

    onMerge(obj, type) {
        const statusCode = "merging ticket";
        if (!status.has(Ticket, statusCode)) {
            const triage = document.location.hash.indexOf("triage") > -1;
            var passThrough = {
                triage: triage,
            };

            obj.start = Ticket.reply.start;

            if (triage) obj.triage = true;

            var duration = Ticket.working.duration || 0;

            UserActions.addEvent(
                {
                    clientId: Ticket.clientSelected,
                    ticketId: Ticket.working.id,
                    action: "merge",
                    status: Ticket.reply.status,
                    start: Ticket.reply.start,
                },
                duration
            );

            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/merge",
                obj,
                TicketActions.mergeCompleted,
                TicketActions.mergeFailed,
                Ticket,
                statusCode,
                passThrough
            );
        }

        this.notify();
    }

    onMergeCompleted(result, passThrough) {
        UIActions.showOverlay("");
        if (passThrough.triage) {
            TicketActions.getTicket("triage");
        } else this.notify();
    }

    onMergeFailed(error) {
        UIActions.addMessage(String(error), "error");
        this.notify();
    }

    onSpam() {
        const triage = document.location.hash.indexOf("triage") > -1;
        var passThrough = {
            triage: triage,
        };

        const statusCode = "merging ticket";
        if (!status.has(Ticket, statusCode))
            api(
                "get",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/spam",
                null,
                TicketActions.spamCompleted,
                TicketActions.error,
                Ticket,
                statusCode,
                passThrough
            );

        this.notify();
    }

    onSpamCompleted(passThrough) {
        UIActions.showOverlay("");
        if (passThrough.triage) {
            TicketActions.getTicket("triage");
        } else this.notify();
    }

    onAddWorkflow(obj) {
        if (Ticket.working.workflows === undefined || Ticket.working.workflows[obj.id] === undefined) {
            var ref = db.collection("clients").doc(Ticket.clientSelected).collection("appliedWorkflows").doc();

            db.collection("clients")
                .doc(Ticket.clientSelected)
                .collection("tickets")
                .doc(Ticket.working.id)
                .update({
                    ["workflows." + ref.id]: {
                        status: "queued",
                        created: new Date(),
                        workflowId: obj.id,
                    },
                });

            const newItem = {
                claimed: false,
                clientId: Ticket.clientSelected,
                date: new Date(),
                priority: zendesk.find_priority(Ticket.working.external_priority),
                stage: "prod",
                ticketId: Ticket.working.id,
                workflowId: obj.id,
                workflow_status: "queued",
                escalate: Ticket.working.escalate,
                status: Ticket.working.external_status,
                deleted: Ticket.working.deleted,
                requester_updated: Ticket.working.external_requester_updated || null,
                sla: Ticket.working.sla || null,
                external_sla: Ticket.working.external_sla || null,
            };

            // Adds event
            var duration = Ticket.working.duration || 0;
            UserActions.addEvent(
                {
                    clientId: Ticket.clientSelected,
                    ticketId: Ticket.working.id,
                    action: "add workflow",
                    status: Ticket.reply.status,
                    start: Ticket.reply.start,
                    value: {
                        workflowId: obj.id,
                    },
                },
                duration
            );

            /*
			UserActions.addAnalytics({
				clientId: Ticket.clientSelected,
				ticketId: Ticket.working.id,
				action: "add workflow",
				status: Ticket.reply.status,
				start: Ticket.reply.start
            });
            */

            const statusCode = "adding workflow to ticket";
            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/workflows/" + ref.id,
                newItem,
                TicketActions.update,
                TicketActions.error,
                Ticket,
                statusCode
            );

            // Resets time
            Ticket.reply.start = new Date();

            if (Ticket.working.external_status === "new") {
                var tempReply = Ticket.reply.text;
                Ticket.reply.text = "";
                TicketActions.submit("open");
                Ticket.reply.text = tempReply;
            }
        }
    }

    onRemoveWorkflow(obj) {
        const statusCode = "removing workflow to ticket";

        // Adds event
        var duration = Ticket.working.duration || 0;
        UserActions.addEvent(
            {
                clientId: Ticket.clientSelected,
                ticketId: Ticket.working.id,
                action: "remove workflow",
                status: Ticket.reply.status,
                start: Ticket.reply.start,
                value: {
                    workflowId: obj.id,
                },
            },
            duration
        );

        api(
            "delete",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/workflows/" + obj.id,
            null,
            TicketActions.update,
            TicketActions.error,
            Ticket,
            statusCode
        );

        // Resets time
        Ticket.reply.start = new Date();
    }

    onAddAttachments(files, isWorkflowCard) {
        const statusCode = "adding attachments";
        if (isWorkflowCard) Ticket.isWorkflowCard = true;

        if (!status.has(Ticket, statusCode)) storage.upload(files, "attachments", "", [], TicketActions.addAttachmentsCompleted);
    }

    onAddAttachmentsCompleted(files, type) {
        var attachments = [];
        for (var i = 0; i < files.length; i++) {
            attachments.push({
                url: files[i].url,
                filename: files[i].filename,
                content_type: files[i].contentType,
            });
        }

        if (Ticket.reply !== undefined) Ticket.reply.attachments = Ticket.reply.attachments || [];

        if (Ticket.isWorkflowCard) {
            WorkflowActions.setAttachment((Ticket.working || {}).source, attachments);
        } else {
            Ticket.reply.attachments = Ticket.reply.attachments.concat(attachments);
        }

        this.notify();
    }

    onAddAttachmentsFailed(error) {
        UIActions.addMessage(String(error), "error");
        this.notify();
    }

    onAddInternalNote(data) {
        add_internal_note(data);
    }

    onAssignAgent(value, workflowId) {
        assign_agent(value, workflowId);
    }

    onAssignAgentCompleted(result) {
        UIActions.addMessage("Successfully assigned user to ticket", "success");
    }

    onAssignAgentFailed(error) {
        UIActions.addMessage(String(error), "error");
        this.notify();
    }

    onRemove(location) {
        objects.remove(Ticket.reply, location.concat());
        this.notify();
    }

    onSetClientOptions(triageClients, workflowClients) {
        Ticket.triageClients = triageClients;
        Ticket.workflowClients = workflowClients;
        this.notify();
    }

    onTicketCheck() {
        if (Ticket.working !== undefined && [null, undefined, ""].indexOf(Ticket.reply.assignee_id) > -1) run_ticket_checks();
        this.notify();
    }

    onChangeMode() {
        if (Ticket.mode === "play") Ticket.mode = "pause";
        else Ticket.mode = "play";

        if (Ticket.mode === "pause" && Ticket.working !== undefined && Ticket.working.id !== undefined) {
            Ticket.pauseTime = new Date();
        } else if (Ticket.mode === "play" && Ticket.working !== undefined && Ticket.working.id !== undefined) {
            Ticket.reply.start = new Date(Number(Ticket.reply.start) + Number(new Date()) - Number(Ticket.pauseTime));
            delete Ticket.pauseTime;
        }

        this.notify();
    }

    onSetCSATCategory(field, location, value) {
        const statusCode = "setting CSAT";

        Ticket.working[field] = value;

        const obj = {
            CSAT_check: Ticket.working.CSAT_check || null,
            CSAT_guru: Ticket.working.CSAT_guru || null,
            csatId: Ticket.working.csat_rating.id,
            date: new Date(time.parse_time(Ticket.working.csat_rating.updated_at)),
            rating: Ticket.working.csat_rating.score,
        };

        api(
            "post",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/csat",
            obj,
            TicketActions.update,
            TicketActions.error,
            Ticket,
            statusCode
        );
    }

    onSetSandboxResponse(commentId, value) {
        db.collection("clients")
            .doc(Ticket.clientSelected)
            .collection("tickets")
            .doc(Ticket.working.id)
            .collection("comments")
            .doc(commentId)
            .update({ sandboxRating: value });

        var myComment = Ticket.working.comments.find((item) => item.id === commentId);

        UserActions.get((User) => {
            var ref = db.collection("sandboxRatings").doc();

            const message = {
                clientId: Ticket.clientSelected,
                ticketId: Ticket.working.id,
                commentId: commentId,
                userId: myComment.author_id,
                raterId: User.me.id,
                rating: value,
                date: new Date(),
            };

            ref.set(message);
            message.id = ref.id;

            const statusCode = "setting sandbox rating";

            api(
                "post",
                "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/sandboxrating",
                message,
                TicketActions.refreshCompleted,
                TicketActions.error,
                Ticket,
                statusCode
            );
        });
    }

    onRefresh() {
        const statusCode = "refreshing ticket";
        var message = {
            type: Ticket.working.source,
            external_id: Ticket.working.external_id,
            clientId: Ticket.clientSelected,
            ticketId: Ticket.working.id,
            integrationId: Ticket.working.integrationId,
        };

        api(
            "post",
            "/clients/" + Ticket.clientSelected + "/tickets/" + Ticket.working.id + "/refresh",
            message,
            TicketActions.refreshCompleted,
            TicketActions.error,
            Ticket,
            statusCode
        );

        UIActions.addMessage("Starting ticket refresh", "success");
    }

    onRefreshCompleted() {}

    onFlagTicket(obj) {
        obj.ticketId = Ticket.working.id;
        obj.clientId = Ticket.working.clientId;
        obj.date = new Date();
        obj.userReviewed = false;

        UserActions.get((User) => {
            obj.flaggerId = User.me.id;
        });

        db.collection("flaggedTickets").doc().set(obj).catch(TicketActions.error);
    }

    onAcknowledgeFlagTicket(obj) {
        db.collection("flaggedTickets").doc(obj.id).update({ userReviewed: true }).catch(TicketActions.error);
    }

    onUnsubscribe() {
        if (Ticket.workingUpdate !== undefined) for (var i = 0; i < Ticket.workingUpdate.length; i++) Ticket.workingUpdate[i]();

        delete Ticket.workingUpdate;

        if (Ticket.working !== undefined) UserActions.stopViewing(Ticket.working.id);
    }

    onUnsubscribeActive() {
        if (Ticket.activeUpdate !== undefined) Ticket.activeUpdate();
        delete Ticket.activeUpdate;
        delete Ticket.active;
        delete Ticket.component;
        this.notify();
    }

    onReset() {
        TicketActions.unsubscribe();
        Ticket.loadedParts = [];
        Ticket.allLoaded = false;
        delete Ticket.message;
        delete Ticket.working;
    }

    onClose() {
        TicketActions.unsubscribe();
        Ticket.loadedParts = [];
        Ticket.allLoaded = false;
        delete Ticket.working;

        reset_ticket_in_queue();
        delete Ticket.message;
        this.notify();
    }

    onGet(callback) {
        callback(Ticket);
    }

    onUpdate() {
        this.notify();
    }

    onError(error) {
        UIActions.addMessage(error, "error");
    }

    notify() {
        console.log("notify ticket");
        var working = Ticket.working !== undefined ? objects.clone(Ticket.working) : undefined;
        var reply = Ticket.reply !== undefined ? objects.clone(Ticket.reply) : undefined;
        this.setState({
            TicketStore: {
                status: Ticket.status,
                allLoaded: Ticket.allLoaded,
                mode: Ticket.mode,
                clientSelected: Ticket.clientSelected,
                triageClients: Ticket.triageClients,
                workflowClients: Ticket.workflowClients,
                active: Ticket.active || [],
                working,
                reply,
            },
        });
    }
}

export default TicketStore;
