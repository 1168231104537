export default {
    name: "Desk",
    type: "desk",
    logo: "/images/logos/desk_logo.png",
    subsections: [
        {
            name: "API Connector",
            type: "api connector",
            icon: "compare_arrows",
            activeRules: [
                {
                    target: "integration",
                    field: "valid",
                    value: true
                }
            ],
            items: [
                {
                    field: "email",
                    label: "Desk Email",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 6
                        },
                        {
                            type: "contains",
                            value: "@"
                        },
                        {
                            type: "contains",
                            value: "."
                        }
                    ]
                },
                {
                    field: "password",
                    label: "Desk password",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 4
                        }
                    ]
                },
                {
                    field: "url",
                    label: "Desk URL",
                    type: "text",
                    validation: [
                        {
                            type: "contains",
                            value: "desk.com"
                        },
                        {
                            type: "contains",
                            value: "https://"
                        }
                    ]
                },
                {
                    type: "webhook url"
                }
            ]
        },
        {
            name: "Rules",
            type: "rules",
            icon: "call_split",
            activeRules: [
                {
                    target: "integration",
                    field: "rules",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "rules",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Channels",
            type: "channels",
            icon: "contact_mail",
            activeRules: [
                {
                    target: "component",
                    field: "channels",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "channels",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Users for reassigning",
            type: "users",
            icon: "account_box",
            activeRules: [
                {
                    target: "component",
                    field: "clientAgents",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "clientAgents",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket fields",
            type: "ticket fields",
            icon: "radio_button_checked",
            activeRules: [
                {
                    target: "component",
                    field: "ticket_fields",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "ticket_fields",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Blacklisted words/expressions",
            type: "blacklist",
            icon: "block",
            activeRules: [
                {
                    target: "component",
                    field: "blacklist",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "blacklist",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket signature",
            type: "signature",
            icon: "create",
            activeRules: [
                {
                    target: "component",
                    field: "signature",
                    type: "exists"
                }
            ]
        }
    ],
    refreshableFields: [
        {
            text: "Desk users/groups",
            value: "users"
        },
        {
            text: "Ticket fields",
            value: "fields"
        },
        {
            text: "Tags",
            value: "tags"
        },
        {
            text: "Macros",
            value: "macros"
        },

        {
            text: "Check filters",
            value: "checkfilters"
        }
    ],
    submitOptions: ["new", "open", "pending", "hold", "solved"],
    ticketOptions: {
        merge: true,
        editTo: false,
        editCC: true,
        editBCC: false
    }
};
