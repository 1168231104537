import React from "react";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";

// Clients
import AddIntegrationOverlay from "../clients/AddIntegrationOverlay.react";
import TicketFieldEditorOverlay from "../clients/integrations/TicketFieldEditorOverlay.react";
import RulesEditorOverlay from "../clients/integrations/RulesEditorOverlay.react";

import AboutClient from "../clients/AboutClient.react";

// Workflows
import AddCardOverlay from "../workflows/AddCardOverlay.react";
import WorkflowSettings from "../workflows/WorkflowSettings.react";

// Macro settings
import MacroSettings from "../macros/MacroSettings.react";

// Merge
import Merge from "../tickets/Merge.react";

// Products
import ProductFieldOverlay from "../products/ProductFieldOverlay.react";
import ProductImportOverlay from "../products/ProductImportOverlay.react";
import ProductSearchOverlay from "../products/ProductSearchOverlay.react";

// Creation wizards
import NewClient from "./creation/clients/NewClient.react";
import NewAgent from "./creation/agents/NewAgent.react";
import NewWorkflow from "./creation/workflows/NewWorkflow.react";
import NewQuestionCard from "./creation/cards/NewQuestionCard.react";
import NewMacro from "./creation/macros/NewMacro.react";
import NewObject from "./NewObject.react";

// Events feed
import EventsFeedOverlay from "./EventsFeedOverlay.react";

// Confirmation
import ConfirmationBox from "./ConfirmationBox.react";

// Overlays
import ImageOverlay from "./ImageOverlay.react";
import HyperlinkOverlay from "./HyperlinkOverlay.react";
import ReplyEditorOverlay from "../workflows/ReplyEditorOverlay.react";
import CustomReplyEditorOverlay from "../workflows/CustomReplyEditorOverlay.react";
import ValidationOverlay from "../workflows/ValidationOverlay.react";
import ResourcesOverlay from "../workflows/ResourcesOverlay.react";
import JavascriptOverlay from "../workflows/JavascriptOverlay.react";
import InternalNoteOverlay from "./InternalNoteOverlay.react";
import RPAOverlay from "../workflows/RPAOverlay.react";
import FormItemBuilderOverlay from "./cards/FormItemBuilderOverlay.react";
import ScheduleOverlay from "../agents/ScheduleOverlay.react";
import ScheduleEditOverlay from "../agents/ScheduleEditOverlay.react";

import FlagTicketOverlay from "../tickets/FlagTicketOverlay.react";
import FlagTicketAcknowledgeOverlay from "../agents/FlagTicketAcknowledgeOverlay.react";

import TableFieldOverlay from "./cards/TableFieldOverlay.react";
import ClientTriageOptionsOverlay from "../clients/ClientTriageOptionsOverlay.react";
import NewNotificationOverlay from "./NewNotificationOverlay.react";

class OverlayLayer extends React.Component {
    render() {
        var overlay = [];

        // Overlay layer
        switch (this.props.UIStore.overlay) {
            case "new client":
                overlay = <NewClient key="new-client-overlay" status={this.props.ClientStore.status || []} />;
                break;
            case "new guru":
                overlay = <NewAgent key="new-guru-overlay" status={this.props.AgentStore.status || []} />;
                break;
            case "new workflow":
                overlay = (
                    <NewWorkflow
                        key="new-workflow-overlay"
                        status={this.props.WorkflowStore.status || []}
                        templates={this.props.WorkflowStore.summary}
                    />
                );
                break;
            case "new macro":
                overlay = <NewMacro key="new-macro-overlay" status={this.props.MacroStore.status || []} />;
                break;
            case "new question card":
                overlay = (
                    <NewQuestionCard
                        key="new-question-card-overlay"
                        status={this.props.WorkflowStore.status || []}
                        data={this.props.UIStore.overlayDetails}
                    />
                );
                break;
            case "new object":
                overlay = <NewObject key="new-object-overlay" item={this.props.UIStore.overlayDetails} />;
                break;
            case "product field":
                overlay = <ProductFieldOverlay key="product-field-overlay" item={this.props.UIStore.overlayDetails} />;
                break;
            case "product import":
                overlay = <ProductImportOverlay key="product-import-overlay" />;
                break;
            case "product search":
                overlay = (
                    <ProductSearchOverlay
                        key="product-import-overlay"
                        item={this.props.UIStore.overlayDetails}
                        products={this.props.ClientStore.working.products}
                        ticketWorkflow={this.props.WorkflowStore.ticketWorkflow}
                        agentMode={
                            document.location.hash.indexOf("ticketworkflows") > -1 ||
                            document.location.hash.indexOf("ticketlessworkflows") > -1
                        }
                    />
                );
                break;

            case "table field":
                overlay = <TableFieldOverlay key="table-field-overlay" item={this.props.UIStore.overlayDetails} />;
                break;
            case "confirmation":
                overlay = <ConfirmationBox key="confirmation-overlay" data={this.props.UIStore.confirmation} />;
                break;
            case "image overlay":
                overlay = <ImageOverlay key="image-overlay" item={this.props.UIStore.overlayDetails} />;
                break;
            case "hyperlink overlay":
                overlay = <HyperlinkOverlay key="hyperlink-overlay" />;
                break;
            case "merge":
                overlay = (
                    <Merge
                        key="merge-overlay"
                        ticket={this.props.UIStore.overlayDetails}
                        status={this.props.TicketStore.status || []}
                    />
                );
                break;
            case "reply editor":
                overlay = (
                    <ReplyEditorOverlay
                        key="reply-editor-overlay"
                        item={this.props.UIStore.overlayDetails}
                        client={this.props.ClientStore.working}
                        ticket={this.props.TicketStore.working || {}}
                        user={this.props.UserStore.me}
                        reply={this.props.TicketStore.reply}
                        agentMode={document.location.hash.indexOf("ticketworkflows") > -1}
                    />
                );
                break;
            case "custom reply editor":
                overlay = (
                    <CustomReplyEditorOverlay
                        key="custom-reply-editor-overlay"
                        item={this.props.UIStore.overlayDetails}
                        client={this.props.ClientStore.working}
                        user={this.props.UserStore.me}
                        agentMode={document.location.hash.indexOf("ticketworkflows") > -1}
                    />
                );
                break;
            case "add card":
                overlay = <AddCardOverlay key="add-card-overlay" target={this.props.UIStore.overlayDetails} />;
                break;
            case "add integration":
                overlay = (
                    <AddIntegrationOverlay key="add-integration-overlay" target={this.props.UIStore.overlayDetails} />
                );
                break;
            case "validation":
                overlay = (
                    <ValidationOverlay
                        key="validation-overlay"
                        item={this.props.UIStore.overlayDetails}
                        actions={WorkflowActions}
                    />
                );
                break;
            case "resources":
                overlay = (
                    <ResourcesOverlay
                        key="resources-overlay"
                        item={this.props.UIStore.overlayDetails}
                        actions={WorkflowActions}
                    />
                );
                break;
            case "javascript":
                overlay = (
                    <JavascriptOverlay
                        key="javascript-overlay"
                        item={this.props.UIStore.overlayDetails}
                        actions={WorkflowActions}
                    />
                );
                break;
            case "internal note":
                overlay = <InternalNoteOverlay key="internal-note-overlay" item={this.props.UIStore.overlayDetails} />;
                break;
            case "rpa editor":
                overlay = (
                    <RPAOverlay key="rpa-overlay" item={this.props.UIStore.overlayDetails} actions={WorkflowActions} />
                );
                break;
            case "events feed":
                overlay = (
                    <EventsFeedOverlay
                        key="events-feed-overlay"
                        events={this.props.UserStore.events || []}
                        clients={this.props.ClientStore.summary || []}
                        agents={this.props.AgentStore.summary || []}
                    />
                );
                break;
            case "workflow settings":
                overlay = <WorkflowSettings key="workflow-settings-overlay" item={this.props.WorkflowStore.working} />;
                break;
            case "form item builder":
                overlay = (
                    <FormItemBuilderOverlay key="form-item-builder-overlay" item={this.props.UIStore.overlayDetails} />
                );
                break;
            case "macro settings":
                overlay = <MacroSettings key="macro-settings-overlay" item={this.props.MacroStore.working} />;
                break;
            case "ticket field editor":
                overlay = (
                    <TicketFieldEditorOverlay
                        key="ticket-field-editor-overlay"
                        item={this.props.UIStore.overlayDetails}
                    />
                );
                break;
            case "rules editor":
                overlay = (
                    <RulesEditorOverlay
                        key="rules-editor-overlay"
                        item={this.props.UIStore.overlayDetails}
                        workflows={this.props.ClientStore.working.workflows || []}
                    />
                );
                break;
            case "triageoption editor":
                overlay = (
                    <ClientTriageOptionsOverlay
                        key={"client-triage-option-editor-" + this.props.UIStore.overlayDetails.target.id}
                        item={this.props.UIStore.overlayDetails}
                        client={this.props.ClientStore.working}
                        user={this.props.UserStore.me}
                    />
                );
                break;
            case "about client":
                overlay = (
                    <AboutClient
                        key="about-client"
                        target={this.props.UIStore.overlayDetails}
                        client={this.props.ClientStore.working}
                        user={this.props.UserStore.me}
                    />
                );
                break;
            case "flag ticket":
                overlay = (
                    <FlagTicketOverlay
                        key="flag-ticket-overlay"
                        target={this.props.UIStore.overlayDetails}
                        client={this.props.ClientStore.working}
                        ticket={this.props.TicketStore.working}
                        user={this.props.UserStore.me}
                    />
                );
                break;
            case "flag ticket acknowledge":
                overlay = (
                    <FlagTicketAcknowledgeOverlay
                        key="flag-ticket-acknowledge-overlay"
                        user={this.props.UserStore.me}
                        item={this.props.UserStore.me.flaggedTickets[0] || {}}
                    />
                );
                break;
            case "schedule":
                overlay = <ScheduleOverlay key="schedule-overlay" user={this.props.UserStore.me} />;
                break;
            case "new notification":
                overlay = (
                    <NewNotificationOverlay
                        key="new-notification-overlay"
                        user={this.props.UserStore.me}
                        gurus={this.props.AgentStore.summary || []}
                    />
                );
                break;
            case "schedule edit":
                overlay = (
                    <ScheduleEditOverlay
                        key="schedule-edit-overlay"
                        user={this.props.UserStore.me}
                        item={this.props.UIStore.overlayDetails}
                    />
                );
                break;
            default:
                overlay = [];
                break;
        }

        return overlay;
    }
}

export default OverlayLayer;
