import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import ProductActions from "../../actions/ProductActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import H2 from "../_common/headers/H2.react";
import HeaderRow from "../_common/HeaderRow.react";
import Cell from "../_common/Cell.react";
import CardTag from "../_common/CardTag.react";

// Buttons
import Button from "../_common/buttons/Button.react";

const pillStyle = {
	display: "inline-block",
	borderRadius: "32px",
	fontSize: "12px",
	padding: "8px 12px",
	margin: "3px",
	color: "white",
	backgroundColor: "rgb(70, 180, 175)",
	cursor: "pointer"
};

const buttonRow = {
	width: "100%",
	padding: "16px 0px",
	display: "inline-block"
};

const buttonBottomRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const rowStyle = {
	borderBottom: "1px solid lightgrey",
	padding: "16px 0px",
	position: "relative",
	display: "inline-block",
	width: "100%"
};

const subrowStyle = {
	borderBottom: "1px solid lightgrey",
	padding: "8px 0px",
	position: "relative",
	display: "inline-block",
	width: "100%"
};

const subitemsContainerStyle = {
	width: "calc(100% - 120px)",
	display: "inline-block",
	margin: "8px 0px 8px 120px"
};

const inputStyle = {
	padding: "0px",
	border: "none",
	fontSize: "14px",
	lineHeight: "14px",
	fontFamily: "Roboto",
	fontWeight: "400",
	outline: "0",
	width: "100%"
};

const closeIconStyle = {
	position: "absolute",
	right: "12px",
	top: "8px",
	cursor: "pointer"
};

const addStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer",
	color: "rgba(0, 0, 0, 0.62)",
	fontSize: "30px"
};

const addItemStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer",
	color: "rgba(0, 0, 0, 0.62)",
	fontSize: "30px",
	marginTop: "8px"
};

const buttonStyle = {
	margin: "0px 8px"
};

class FieldPill extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		UIActions.showOverlay("product field", this.props.item);
	}

	render() {
		return (
			<div style={pillStyle} onClick={this._onClick}>
				{this.props.item.text}
			</div>
		);
	}
}

class CellBuilder extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { value: this.props.value };
	}

	_updateFn(event) {
		var value = event.currentTarget.value;
		ProductActions.setField(this.props.item.value, this.props.location, value, false);
		this.setState({ value });
	}

	render() {
		return (
			<Cell size={this.props.item.size}>
				<input
					id={this.props.id}
					placeholder={this.props.item.text}
					type={this.props.item.type || "text"}
					value={this.state.value}
					onChange={this._updateFn}
					style={inputStyle}
				/>
			</Cell>
		);
	}
}

class CatalogSubitem extends React.Component {
	constructor(props) {
		super(props);
		this._onRemove = this._onRemove.bind(this);
	}

	_onRemove() {
		ProductActions.remove(this.props.location.concat(this.props.item.id));
	}

	render() {
		return (
			<div style={subrowStyle}>
				{this.props.subfields.map((item, index) => (
					<CellBuilder
						key={this.props.item.id + "-" + item.id}
						id={this.props.item.id + "-" + item.id}
						item={item}
						value={this.props.item[item.value] || ""}
						location={this.props.location.concat(this.props.item.id)}
					/>
				))}
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
					close
				</i>
			</div>
		);
	}
}

class CatalogItem extends React.Component {
	constructor(props) {
		super(props);
		this._onAddItem = this._onAddItem.bind(this);
		this._onRemove = this._onRemove.bind(this);
	}

	_onAddItem() {
		var obj = {};
		ProductActions.add("subitems", [this.props.item.id], obj);
	}

	_onRemove() {
		ProductActions.remove([this.props.item.id]);
	}

	render() {
		var subitems = this.props.item.subitems || [];

		return (
			<div style={rowStyle}>
				{this.props.fields.map((item, index) => (
					<CellBuilder
						key={this.props.item.id + "-" + item.id}
						id={this.props.item.id + "-" + item.id}
						item={item}
						value={this.props.item[item.value] || ""}
						location={[this.props.item.id]}
					/>
				))}
				{this.props.subfields.length > 0 ? (
					<div style={subitemsContainerStyle}>
						<HeaderRow>
							{this.props.subfields.map((item, index) => (
								<Cell key={"header-" + this.props.item.id + "-" + item.id} size={item.size}>
									{item.text}
								</Cell>
							))}
						</HeaderRow>
						{subitems.map((item, arr) => (
							<CatalogSubitem
								key={"subitem-" + item.id}
								item={item}
								location={[this.props.item.id]}
								subfields={this.props.subfields}
							/>
						))}
						<i className="material-icons" onClick={this._onAddItem} style={addItemStyle}>
							add_circle
						</i>
					</div>
				) : (
					""
				)}
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
					close
				</i>
			</div>
		);
	}
}

class Catalog extends React.Component {
	constructor(props) {
		super(props);
		this._onAddField = this._onAddField.bind(this);
		this._onAddSubfield = this._onAddSubfield.bind(this);
		this._onAddItem = this._onAddItem.bind(this);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this.state = { page: 0 };
	}

	_onAddField() {
		var obj = {
			text: "",
			value: "",
			type: "text",
			size: "m"
		};

		ProductActions.add("fields", [], obj);
		UIActions.showOverlay("product field", obj);
	}

	_onAddSubfield() {
		var obj = {
			text: "",
			value: "",
			type: "text",
			size: "m"
		};

		ProductActions.add("subfields", [], obj);
		UIActions.showOverlay("product field", obj);
	}

	_onAddItem() {
		var obj = {};
		ProductActions.add("items", [], obj);
	}

	_onShowImport() {
		UIActions.showOverlay("product import");
	}

	_onNextPage() {
		this.setState({ page: this.state.page + 1 });
		window.scrollTo(0, 0);
	}

	_onPreviousPage() {
		this.setState({ page: this.state.page - 1 });
		window.scrollTo(0, 0);
	}

	render() {
		const fields = this.props.item.fields || [];
		const subfields = this.props.item.subfields || [];
		var items = (this.props.item.items || []).slice(this.state.page * 10, 10 * (this.state.page + 1));

		return (
			<OuterScaffold spaceForHeader={true} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={false} verticalAlign="top">
					<SectionCard title="FIELDS">
						<H2 text="Items" margin="16px 0px" />
						{fields.map((item, index) => (
							<FieldPill key={"field-" + item.id} item={item} />
						))}
						<i className="material-icons" onClick={this._onAddField} style={addStyle}>
							add_circle
						</i>
						<H2 text="Subitems" margin="16px 0px" />
						{subfields.map((item, index) => (
							<FieldPill key={"field-" + item.id} item={item} />
						))}
						<i className="material-icons" onClick={this._onAddSubfield} style={addStyle}>
							add_circle
						</i>

						<div style={buttonRow}>
							<Button text={"Import data"} onClick={this._onShowImport} />
						</div>
					</SectionCard>
					{fields.length > 0 ? (
						<SectionCard title="ITEMS">
							<HeaderRow>
								{fields.map((item, index) => (
									<Cell key={"header-" + item.id} size={item.size}>
										{item.text}
									</Cell>
								))}
							</HeaderRow>
							{items.map((item, index) => (
								<CatalogItem key={"item-" + item.id} item={item} fields={fields} subfields={subfields} />
							))}
							<div style={buttonRow}>
								<i className="material-icons" onClick={this._onAddItem} style={addItemStyle}>
									add_circle
								</i>
							</div>

							<div style={buttonBottomRow}>
								{this.state.page > 0 ? (
									<Button text={"Previous Page"} onClick={this._onPreviousPage} style={buttonStyle} />
								) : (
									""
								)}
								{items.length === 10 ? (
									<Button text={"Next Page"} onClick={this._onNextPage} style={buttonStyle} />
								) : (
									""
								)}
							</div>
							<CardTag text={"Page " + (this.state.page + 1)} color="orange" />
						</SectionCard>
					) : (
						""
					)}
				</Container>
			</OuterScaffold>
		);
	}
}

export default Catalog;
