import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "./Container.react";
import Overlay from "./Overlay.react";
import Card from "./Card.react";

// Form items
import Input from "./forms/Input.react";
import Button from "./buttons/Button.react";

// Headers
import H1 from "./headers/H1.react";

// Functions
import validate from "../../functions/validate";

// Sets validation
const nameValidation = [
	{
		type: "min-length",
		value: 4
	}
];

class NewObject extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = {
			name: "",
			validate: false,
			loading: this.props.item.status.indexOf("creating " + this.props.item.type) > -1
		};
	}

	componentDidMount() {
		document.getElementById("new-name").focus();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ loading: nextProps.item.status.indexOf("creating " + this.props.item.type) > -1 });
	}

	_onClick() {
		var nameError = validate(nameValidation, this.state.name);

		if (nameError === "") {
			this.setState({ validate: false, loading: true });
			this.props.item.actions.new({ name: this.state.name, active: true });
		} else this.setState({ validate: true });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text={"Add new " + this.props.item.type} textAlign="center" />
						<Input
							id="new-name"
							label={this.props.item.label + " name"}
							field="name"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={nameValidation}
							onReturn={this._onClick}
							errors={this.props.errors}
						/>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="NEXT" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default NewObject;
