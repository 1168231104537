import React from "react";

// Actions
//import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

const solvedStyle = {
	fontSize: "40px",
	width: "100%",
	padding: "40px 0px",
	textAlign: "center",
	display: "inline-block"
};

class TicketSolvedCard extends React.Component {
	render() {
		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
			>
				<div style={solvedStyle}>Ticket solved!</div>
			</Card>
		);
	}
}

export default TicketSolvedCard;
