import React from "react";

import Pill from "./Pill.react";
import Input from "./Input.react";

// Functions
import m from "../../../functions/m";

const pillStyle = {
	display: "inline-block",
	fontSize: "14px",
	padding: "4px 8px",
	border: "1px solid lightgrey",
	borderRadius: "4px",
	verticalAlign: "middle",
	fontWeight: "400",
	margin: "0px 4px 0px 0px",
	color: "black",
	background: "transparent"
};

const primaryStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	width: "100%"
};

const inputStyle = {
	width: "200px",
	margin: "0px",
	height: "auto",
	padding: "0px"
};

class Tokenizer extends React.Component {
	constructor(props) {
		super(props);
		this._onAddToken = this._onAddToken.bind(this);
		this._onClose = this._onClose.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { input: "" };
	}

	_onClose(field, location, value) {
		var items = this.props.value;
		for (var i = 0; i < items.length; i++) {
			if (items[i] === value) {
				items.splice(i, 1);
				break;
			}
		}

		this.props.updateFn(this.props.field, this.props.location, items);
	}

	_onAddToken(field, location, value, onReturn) {
		var lastChar = value.charAt(value.length - 1);
		if (lastChar === " " || lastChar === "," || lastChar === ";" || onReturn) {
			if (onReturn) value = value.substr(0, value.length);
			else value = value.substr(0, value.length - 1);

			if (this.props.value.indexOf(value) === -1 && value !== "")
				this.props.updateFn(this.props.field, this.props.location, this.props.value.concat(value));

			this.setState({ input: "" });
		} else this.setState({ input: value });
	}

	_onBlur(event) {
		if (this.state.input !== "") this._onAddToken(this.props.field, this.props.location, this.state.input, true);
	}

	render() {
		return (
			<div style={m(primaryStyle, this)}>
				{this.props.value.map((item, index, arr) => (
					<Pill
						key={this.props.id + "-" + index}
						field={this.props.field}
						location={this.props.location}
						text={item}
						onClose={this._onClose}
						value={item.value || item}
						style={pillStyle}
					/>
				))}
				{!this.props.noEntry ? (
					<Input
						id={this.props.id + "-input"}
						style={inputStyle}
						placeholder={this.props.label}
						field={this.props.field}
						location={this.props.location}
						value={this.state.input}
						updateFn={this._onAddToken}
						onReturn={this._onAddToken}
						onBlur={this._onBlur}
						padding="0px 8px"
						noLine={true}
					/>
				) : (
					""
				)}
			</div>
		);
	}
}

export default Tokenizer;
