import Reflux from "reflux";

// Actions
import MacroActions from "../actions/MacroActions";
import UIActions from "../actions/UIActions";
import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as url from "../functions/url";
import * as firestore from "../functions/firestore";

// Defines db for firestore
var db = firebase.firestore();

var Macro = {
	status: []
};

function setup_live_updaters() {
	Macro.workingUpdate = [];

	// Sets up monitoring
	Macro.workingUpdate.push(
		db
			.collection("clients")
			.doc(url.get_client())
			.collection("macros")
			.doc(Macro.working.id)
			.onSnapshot(function(snapshot) {
				var newObj = firestore.extract_single(snapshot);
				Macro.working = newObj;
				MacroActions.update();
			})
	);
}

// Figures out if inside of client
function client_check() {
	const myRoute = document.location.hash.replace("#", "").split("/");
	if (myRoute[0] === "clients" && myRoute[1] !== undefined) return myRoute[1];
	else return null;
}

function get_url() {
	const client = client_check();
	var url;
	if (client !== null) url = "clients/" + client + "/macros";
	else url = "macros";

	return url;
}

class MacroStore extends Reflux.Store {
	constructor() {
		super();
		this.notify = this.notify.bind(this);
		this.state = { MacroStore: Macro };
		this.listenToMany(MacroActions);
	}

	onNew(data) {
		data.active = true;
		data.created = new Date();

		const clientId = url.get_client();

		var ref = db
			.collection("clients")
			.doc(clientId)
			.collection("macros")
			.doc();

		db.collection("clients")
			.doc(clientId)
			.collection("macros")
			.doc(ref.id)
			.set(data)
			.then(function() {
				data.id = ref.id;

				// Does initial load
				UIActions.showRouter(get_url() + "/" + ref.id);
			})
			.catch(MacroActions.loadFailed);
	}

	onLoad(id, blockView) {
		const clientId = url.get_client();
		db.collection("clients")
			.doc(clientId)
			.collection("macros")
			.doc(id)
			.get()
			.then(function(snapshot) {
				MacroActions.loadCompleted(firestore.extract_single(snapshot), false);
			})
			.catch(MacroActions.loadFailed);
	}

	onLoadCompleted(result, blockView) {
		Macro.working = result;

		if (!blockView) {
			UIActions.showOverlay("");
			this.notify();
		}

		if (Macro.workingUpdate === undefined) setup_live_updaters();
	}

	onLoadFailed(result) {
		this.notify();
	}

	onSave() {
		var temp = objects.clone(Macro.working);
		delete temp.id;
		temp.updated = new Date();

		const clientId = url.get_client();

		db.collection("clients")
			.doc(clientId)
			.collection("macros")
			.doc(Macro.working.id)
			.set(temp, { merge: true })
			.then(MacroActions.saveCompleted)
			.catch(MacroActions.saveFailed);
	}

	onSaveCompleted(result) {
		UIActions.addMessage("Preset email response saved!", "success");
		this.notify();
	}

	onSaveFailed(error) {
		UIActions.addMessage("Couldn't save preset email response :(.", "error");
		this.notify();
	}

	onDelete() {
		MacroActions.unsubscribe();
		const clientId = url.get_client();
		const id = Macro.working.id;
		delete Macro.working;

		db.collection("clients")
			.doc(clientId)
			.collection("macros")
			.doc(id)
			.delete()
			.then(MacroActions.deleteCompleted)
			.catch(MacroActions.deleteFailed);

		UIActions.showRouter(get_url());
		this.notify();
	}

	onDeleteCompleted(result) {
		UIActions.addMessage("Preset email response deleted!", "success");
		this.notify();
	}

	onDeleteFailed(result) {
		UIActions.addMessage("Couldn't delete preset email response :(.", "error");
		this.notify();
	}

	onSetField(field, location, value, shouldNotify) {
		setField(Macro.working, field, location, value);
		if (shouldNotify) this.notify();
	}

	onAdd(field, location, obj) {
		objects.add(Macro.working, field, location, obj);
		this.notify();
	}

	onUnsubscribe() {
		if (Macro.workingUpdate !== undefined)
			for (var i = 0; i < Macro.workingUpdate.length; i++) Macro.workingUpdate[i]();

		delete Macro.workingUpdate;
	}

	onClose() {
		MacroActions.unsubscribe();
		Macro.loadedParts = [];
		Macro.allLoaded = false;
		delete Macro.working;
		this.notify();
	}

	onUpdate() {
		this.notify();
	}

	notify() {
		console.log("notify macro");
		var working = Macro.working !== undefined ? objects.clone(Macro.working) : undefined;
		this.setState({ MacroStore: { summary: Macro.summary, status: Macro.status, working } });
	}
}

export default MacroStore;
