import React from "react";

// Actions
import UIActions from "../../actions/UIActions";
import WorkflowActions from "../../actions/WorkflowActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Search from "../_common/Search.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Functions
import ensure_ids from "../../functions/ensure_ids";

// Styles
const middleRowStyle = {
    width: "100%",
    padding: "16px 0px",
    display: "inline-block",
    verticalAlign: "top",
    textAlign: "center",
    minHeight: "400px"
};

const cardStyle = {
    width: "calc(100% - 48px)",
    padding: "24px",
    textAlign: "left",
    maxWidth: "1100px"
};

const buttonRowStyle = {
    width: "calc(100% + 16px)",
    textAlign: "right",
    margin: "0px -24px -24px -24px",
    borderTop: "1px solid lightgrey",
    padding: "16px"
};

const topRowStyle = {
    width: "calc(100% + 48px)",
    textAlign: "right",
    margin: "-24px -24px 0px -24px",
    borderBottom: "1px solid lightgrey",
    padding: "0px"
};

const buttonStyle = {
    marginLeft: "8px"
};

const closeIconStyle = {
    position: "absolute",
    right: "12px",
    top: "8px",
    cursor: "pointer"
};

const rowStyle = {
    borderBottom: "1px solid lightgrey",
    padding: "16px 0px",
    position: "relative",
    display: "inline-block",
    width: "100%",
    textAlign: "left"
};

const subitemsContainerStyle = {
    width: "calc(100% - 120px)",
    display: "inline-block",
    margin: "8px 0px 8px 120px"
};

const buttonItemStyle = {
    width: "100%",
    padding: "16px 0px 0px"
};

var find_catalog = (components, id) => {
    for (var i = 0; i < components.length; i++) {
        if (components[i].id === id) return components[i];
    }

    return null;
};

class CatalogSubitem extends React.Component {
    constructor(props) {
        super(props);
        this._onRemove = this._onRemove.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);

        this.state = { hover: false };
    }

    _onRemove() {
        this.props.onRemove(this.props.parentIndex, this.props.index);
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onClick() {
        this.props.onSubitemSelect(this.props.parentIndex, this.props.index, this.props.item.isSelected !== true);
    }

    render() {
        const backgroundColor = this.props.item.isSelected ? "lightblue" : "transparent";

        const subrowStyle = {
            borderBottom: "1px solid lightgrey",
            padding: "8px 0px",
            position: "relative",
            display: "inline-block",
            backgroundColor: this.state.hover && !this.props.item.isSelected ? "rgb(220, 220, 220)" : backgroundColor,
            transition: ".3s all ease",
            width: "100%",
            cursor: "pointer"
        };

        return (
            <div
                style={subrowStyle}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                onClick={this._onClick}
            >
                {this.props.subfields.map((item, index) => (
                    <Cell
                        key={this.props.item.id + "-" + item.id}
                        id={this.props.item.id + "-" + item.id}
                        size={item.size}
                        wrap={true}
                    >
                        {this.props.item[item.value]}
                    </Cell>
                ))}
                <i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
                    close
                </i>
            </div>
        );
    }
}

class CatalogItem extends React.Component {
    constructor(props) {
        super(props);
        this._onRemove = this._onRemove.bind(this);
        this._onClearUnselected = this._onClearUnselected.bind(this);
    }

    _onRemove() {
        this.props.onRemove(this.props.index);
    }

    _onClearUnselected() {
        this.props.onClearUnselected(this.props.index);
    }

    render() {
        var subitems = this.props.item.subitems || [];
        console.log(this.props.fields);
        return (
            <div style={rowStyle}>
                {this.props.fields.map((item, index) => (
                    <Cell
                        key={this.props.item.id + "-" + item.id}
                        id={this.props.item.id + "-" + item.id}
                        size={item.size}
                        wrap={true}
                    >
                        {this.props.item[item.value]}
                    </Cell>
                ))}
                {this.props.subfields !== undefined && this.props.subfields.length > 0 && this.props.showSubitems ? (
                    <div style={subitemsContainerStyle}>
                        <HeaderRow>
                            {this.props.subfields.map((item, index) => (
                                <Cell key={"header-" + this.props.item.id + "-" + item.id} size={item.size}>
                                    {item.text}
                                </Cell>
                            ))}
                        </HeaderRow>
                        {subitems.map((item, index) => (
                            <CatalogSubitem
                                key={"subitem-" + item.id}
                                item={item}
                                index={index}
                                parentIndex={this.props.index}
                                location={[this.props.item.id]}
                                subfields={this.props.subfields}
                                onRemove={this.props.onRemove}
                                onSubitemSelect={this.props.onSubitemSelect}
                            />
                        ))}
                        <div style={buttonItemStyle}>
                            <Button text={"Clear Unselected"} onClick={this._onClearUnselected} />
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <i style={closeIconStyle} className="material-icons" onClick={this._onRemove}>
                    close
                </i>
            </div>
        );
    }
}

class CustomProductComponent extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);
        this.state = { hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onClick() {
        this.props.onClick(this.props.item);
    }

    render() {
        const primaryStyle = {
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "400",
            width: "calc(100% - 32px)",
            backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
            transition: "all 0.3s ease",
            cursor: "pointer",
            padding: "16px"
        };

        return (
            <li
                style={primaryStyle}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                onClick={this._onClick}
            >
                {this.props.custom.map((item, index) => (
                    <Cell key={this.props.item.id + "-" + item.id} size={item.size} wrap={true}>
                        {this.props.item[item.value]}
                    </Cell>
                ))}
            </li>
        );
    }
}

var find_catalog_concept = (source, ticketWorkflow) => {
    if (
        ticketWorkflow === undefined ||
        ticketWorkflow.concepts === undefined ||
        ticketWorkflow.concepts[source] === undefined
    )
        return { fields: [], subfields: [], items: [] };
    return ticketWorkflow.concepts[source];
};

class ProductSearchOverlay extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._onRemove = this._onRemove.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._onSubitemSelect = this._onSubitemSelect.bind(this);
        this._onClearUnselected = this._onClearUnselected.bind(this);

        var catalog = JSON.parse(
            JSON.stringify(
                this.props.item.sourceConcept
                    ? find_catalog_concept(this.props.item.sourceConcept, this.props.ticketWorkflow)
                    : find_catalog(this.props.products || [], this.props.item.source) || {}
            )
        );
        ensure_ids({}, catalog.items);

        this.state = {
            catalog,
            value: this.props.item.value || []
        };
    }

    componentDidMount() {
        document.getElementById("search-product-search").focus();
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onClose() {
        UIActions.showOverlay("");
    }

    _onSelect(obj) {
        var value = this.state.value || [];
        value.push(obj);
        this.setState({ value });

        if (this.props.agentMode) WorkflowActions.setFieldResponse("value", this.props.item.location, value, true);
        else WorkflowActions.setField("value", this.props.item.location, value, true);
    }

    _onRemove(index, subIndex) {
        var value = this.state.value;
        if (subIndex === undefined) value.splice(index, 1);
        else value[index].subitems.splice(subIndex, 1);

        this.setState({ value });

        if (this.props.agentMode) WorkflowActions.setFieldResponse("value", this.props.item.location, value, true);
        else WorkflowActions.setField("value", this.props.item.location, value, true);
    }

    _onSubitemSelect(index, subIndex, isSelected) {
        var value = this.state.value;
        value[index].subitems[subIndex].isSelected = isSelected;
        this.setState({ value });
    }

    _onClearUnselected(index) {
        var value = this.state.value;
        var item = value[index];
        var subitems = [];
        for (var i = 0; i < item.subitems.length; i++) {
            if (item.subitems[i].isSelected) subitems.push(item.subitems[i]);
        }

        item.subitems = subitems;
        this.setState({ value });

        if (this.props.agentMode) WorkflowActions.setFieldResponse("value", this.props.item.location, value, true);
        else WorkflowActions.setField("value", this.props.item.location, value, true);
    }

    render() {
        return (
            <Overlay>
                <Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
                    <Card style={cardStyle}>
                        <div style={topRowStyle}>
                            <H1 text="Search Products" textAlign="center" />
                        </div>
                        <div style={middleRowStyle}>
                            <Search
                                id="product-search"
                                onSelect={this._onSelect}
                                items={this.state.catalog.items || []}
                                fields={this.props.item.fields.split(",")}
                                min={1000}
                                preload={false}
                                customForComponent={this.state.catalog.fields}
                                resultComponent={CustomProductComponent}
                            />
                            <br />
                            {this.state.value.length > 0 ? (
                                <HeaderRow>
                                    {this.state.catalog.fields.map((item, index) => (
                                        <Cell key={"header-" + item.id} size={item.size}>
                                            {item.text}
                                        </Cell>
                                    ))}
                                </HeaderRow>
                            ) : (
                                ""
                            )}
                            {this.state.value.map((item, index) => (
                                <CatalogItem
                                    key={"item-" + item.id + "-" + index}
                                    item={item}
                                    index={index}
                                    fields={this.state.catalog.fields || []}
                                    subfields={this.state.catalog.subfields || []}
                                    showSubitems={this.props.item.showSubitems || false}
                                    onRemove={this._onRemove}
                                    onSubitemSelect={this._onSubitemSelect}
                                    onClearUnselected={this._onClearUnselected}
                                />
                            ))}
                        </div>
                        <div style={buttonRowStyle}>
                            <Button
                                text="Close"
                                style={buttonStyle}
                                onClick={this._onClose}
                                loading={this.state.loading}
                            />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default ProductSearchOverlay;
