import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import InputMultiline from "../forms/InputMultiline.react";
import Checkbox from "../forms/Checkbox.react";
import Dropdown from "../forms/Dropdown.react";

class Response extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onReturn = this._onReturn.bind(this);
		this._onBack = this._onBack.bind(this);
		this._onCheck = this._onCheck.bind(this);

		this.state = { text: this.props.item.text || "", hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	_onReturn(field, location, value) {
		this.props.onReturn(this.props.index);
	}

	_onBack(field, location, value) {
		this.props.onBack(this.props.index);
	}

	_onClick() {
		WorkflowActions.nextCard(this.state.valid);
	}

	_onCheck() {
		this.props.onCheck("value", this.props.location, this.props.item.id);
	}

	render() {
		const primaryStyle = {
			marginLeft: "16px",
			padding: "8px 16px",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.12)" : "rgba(0, 0, 0, 0.06)",
			borderRadius: "4px",
			marginBottom: "8px",
			width: "calc(100% - 88px)",
			transition: "all .3s ease"
		};

		const responseStyle = {
			borderRadius: "10px",
			width: "calc(100% -  32px)",
			marginLeft: "8px",
			marginBottom: "0px",
			minHeight: "",
			verticalAlign: "middle",
			cursor: this.props.agentMode ? "pointer" : ""
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
				<Checkbox
					id={this.props.item.id}
					location={this.props.location}
					field="value"
					value={this.props.value}
					updateFn={this.props.onCheck}
				/>
				<InputMultiline
					id={"text-" + this.props.item.id}
					label={this.props.item.concept || ""}
					value={this.state.text}
					field="text"
					location={this.props.location.concat(this.props.item.id)}
					onBlur={this._onBlur}
					colored={false}
					style={responseStyle}
					thin={true}
					noLine={true}
					placeholder="Enter response text here..."
					onReturn={this._onReturn}
					onClick={this.props.agentMode ? this._onCheck : undefined}
					readOnly={this.props.agentMode}
					editorRefs={this.props.editorRefs}
				/>
			</div>
		);
	}
}

class CheckboxCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onCheck = this._onCheck.bind(this);
		this._onReturn = this._onReturn.bind(this);
		this._onPromptReturn = this._onPromptReturn.bind(this);
		this._onBack = this._onBack.bind(this);
		this._onAddResponse = this._onAddResponse.bind(this);
		this._onRemoveResponse = this._onRemoveResponse.bind(this);

		var valid = false;
		var myValues = this.props.agentMode ? this.props.response.value || [] : this.props.item.value || [];

		if (this.props.item.direction === "more than" && myValues.length > this.props.item.number) valid = true;
		else if (this.props.item.direction === "less than" && myValues.length < this.props.item.number) valid = true;

		this.editorRefs = {};

		this.state = {
			text: this.props.item.prompts[0].text || "",
			value: myValues,
			responseCount: this.props.item.responses.length,
			valid,
			nextPath: valid ? 0 : 1
		};
	}

	componentDidUpdate() {
		if (this.props.item.responses.length !== this.state.responseCount) {
			this.editorRefs["text-" + this.props.item.responses[this.props.item.responses.length - 1].id].focus();
			var myRef = this.editorRefs["text-" + this.props.item.responses[this.props.item.responses.length - 1].id];
			setTimeout(function() {
				myRef.focus();
			}, 10);

			this.setState({ responseCount: this.props.item.responses.length });

			if (this.props.item.responses.length < this.props.item.number)
				WorkflowActions.setField("number", [this.props.item.id], this.props.item.responses.length, true);
		}
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	_onCheck(field, location, value) {
		var myValues = this.props.agentMode ? this.props.response.value || [] : this.props.item.value || [];
		const index = myValues.indexOf(value);
		if (index > -1) myValues.splice(index, 1);
		else myValues.push(value);

		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, myValues, true);
		else WorkflowActions.setField(field, location, myValues, true);

		var valid = false;
		if (this.props.item.direction === "more than" && myValues.length > this.props.item.number) valid = true;
		else if (this.props.item.direction === "less than" && myValues.length < this.props.item.number) valid = true;
		this.setState({ valid, nextPath: valid ? 0 : 1 });
	}

	_onPromptReturn() {
		this.editorRefs["text-" + this.props.item.responses[0].id].focus();
	}

	_onReturn(index) {
		if (index < this.props.item.responses.length - 1)
			this.editorRefs["text-" + this.props.item.responses[index + 1].id].focus();
		else this._onAddResponse();
	}

	_onBack(index) {
		if (index === 0) {
			this.editorRefs[this.props.item.prompts[0].id].focus();
			return;
		}

		if (index === this.props.item.responses.length - 1) this._onRemoveResponse();
		this.editorRefs["text-" + this.props.item.responses[index - 1].id].focus();
	}

	_onAddResponse() {
		WorkflowActions.add("responses", [this.props.item.id], { text: "" });
	}

	_onRemoveResponse() {
		if (this.props.item.responses.length > 1)
			WorkflowActions.remove([this.props.item.id, this.props.item.responses[this.props.item.responses.length - 1].id]);
	}

	render() {
		const questionStyle = {
			marginBottom: "0px"
		};

		const addRemoveControlsContainerStyle = {
			width: "100%",
			textAlign: "center",
			padding: "0px 0px"
		};

		const directionControlsStyle = {
			width: "100%",
			marginTop: "0px",
			marginBottom: "-24px",
			textAlign: "center"
		};

		const iconStyle = {
			cursor: "pointer",
			color: "rgb(70, 180, 175)",
			fontSize: "30px",
			margin: "4px"
		};

		const directionOptions = [
			{
				text: "More than",
				value: "more than"
			},
			{
				text: "Less than",
				value: "less than"
			}
		];

		const directionStyle = {
			width: "150px"
		};

		var numberOptions = [{ text: "0", value: 0 }];
		for (var i = 0; i < this.props.item.responses.length; i++) {
			numberOptions.push({ text: String(i + 1), value: i + 1 });
		}

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				nextPath={this.state.nextPath}
			>
				{!this.props.agentMode ? (
					<div style={directionControlsStyle}>
						<div>Go right if number of selected responses is</div>
						<Dropdown
							id={"direction-" + this.props.item.id}
							items={directionOptions}
							field={"direction"}
							location={[this.props.item.id]}
							updateFn={this._onBlur}
							value={this.props.item.direction}
							label="Direction"
							style={directionStyle}
						/>
						<Dropdown
							id={"number-" + this.props.item.id}
							items={numberOptions}
							field={"number"}
							location={[this.props.item.id]}
							updateFn={this._onBlur}
							value={this.props.item.number}
							label="Amount"
							style={directionStyle}
						/>
					</div>
				) : (
					""
				)}
				<InputMultiline
					id={this.props.item.prompts[0].id}
					placeholder="Enter question text here..."
					value={this.state.text}
					field="text"
					location={[this.props.item.id, this.props.item.prompts[0].id]}
					onBlur={this._onBlur}
					noLine={true}
					style={questionStyle}
					onReturn={this._onPromptReturn}
					readOnly={this.props.agentMode}
					editorRefs={this.editorRefs}
				/>
				{this.props.item.responses.map((item, index, arr) => (
					<Response
						key={item.id}
						item={item}
						index={index}
						location={[this.props.agentMode ? this.props.response.id : this.props.item.id]}
						updateFn={this._onBlur}
						value={
							this.props.agentMode
								? this.props.response.value.indexOf(item.id) > -1
								: this.props.item.value.indexOf(item.id) > -1
						}
						onCheck={this._onCheck}
						onReturn={this._onReturn}
						onBack={this._onBack}
						agentMode={this.props.agentMode}
						editorRefs={this.editorRefs}
					/>
				))}
				{!this.props.agentMode ? (
					<div style={addRemoveControlsContainerStyle}>
						<i style={iconStyle} className="material-icons" onClick={this._onRemoveResponse}>
							remove_circle_outline
						</i>
						<i style={iconStyle} className="material-icons" onClick={this._onAddResponse}>
							add_circle_outline
						</i>
					</div>
				) : (
					""
				)}
			</Card>
		);
	}
}

export default CheckboxCard;
