import React from "react";

// Actions
import TriageOptionsActions from "../../actions/TriageOptionsActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";
import Input from "../_common/forms/Input.react";
import Dropdown from "../_common/forms/Dropdown.react";
import SectionSelector from "../_common/SectionSelector.react";
import SectionLabel from "../_common/SectionLabel.react";
import SettingsRow from "../_common/SettingsRow.react";

// Form items
import Button from "../_common/buttons/Button.react";

// Headers
import H1 from "../_common/headers/H1.react";

// Functions
import * as objects from "../../functions/objects";

// Styles
const cardStyle = {
    width: "calc(100% - 48px)",
    padding: "24px 0px 24px 24px",
    textAlign: "left",
    maxWidth: "1200px"
};

const rowStyle = {
    width: "calc(100% - 8px)",
    textAlign: "right",
    margin: "0px 0px -24px -24px",
    borderTop: "1px solid lightgrey",
    padding: "16px"
};

const buttonStyle = {
    marginLeft: "8px"
};

const emptyStyle = {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "24px 0px",
    width: "calc(100% - 48px - 32px)",
    padding: "24px",
    border: "1px dashed lightgrey",
    borderRadius: "4px"
};

const emptyTextStyle = {
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "20px"
};

const bigInputStyle = {
    marginBottom: "8px"
};

const integrationsStyle = {
    width: "100%",
    textAlign: "left",
    margin: "24px 0px",
    display: "inline-block"
};

const buttonRowStyle = {
    width: "100%",
    marginTop: "32px",
    textAlign: "left"
};

class Row extends React.Component {
    render() {
        const rowStyle = {
            display: "inline-block",
            width: "calc(100% - 16px)",
            padding: "16px 0px 16px 16px",
            borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
            borderBottom: this.props.last ? "" : "1px solid lightgrey"
        };

        return <div style={rowStyle}>{this.props.children}</div>;
    }
}

const ActionOptions = [
    {
        text: "Apply macro",
        value: "apply macro"
    },
    {
        text: "Apply workflow",
        value: "apply workflow"
    }
];

const dropdownStyle = {
    width: "300px",
    marginRight: "16px"
};

const closeIconStyle = {
    marginLeft: "70px",
    marginTop: "8px",
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer"
};

class Action extends React.Component {
    constructor(props) {
        super(props);
        this._onUpdateFn = this._onUpdateFn.bind(this);
        this._onRemoveAction = this._onRemoveAction.bind(this);
    }

    _onUpdateFn(field, location, value) {
        this.props.item[field] = value;

        TriageOptionsActions.setField(field, location, String(value), true);
    }

    _onRemoveAction() {
        TriageOptionsActions.remove(this.props.location.concat(this.props.item.id));
    }

    render() {
        const primaryStyle = {
            borderBottom: !this.props.noBorder ? "1px solid lightgrey" : "",
            padding: "16px",
            borderTop: this.props.index === 0 && !this.props.noBorder ? "1px solid lightgrey" : "",
            position: "relative",
            transition: "background-color .3s ease",
            cursor: "pointer",
            display: "inline-block",
            width: "calc(100% - 32px)"
        };

        return (
            <div style={primaryStyle}>
                <Dropdown
                    id={"type-" + this.props.item.id}
                    value={this.props.item.type}
                    field="type"
                    location={this.props.location.concat(this.props.item.id)}
                    label="Action Type"
                    items={ActionOptions}
                    updateFn={this._onUpdateFn}
                    style={dropdownStyle}
                />
                {this.props.item.type === "apply macro" ? (
                    <Dropdown
                        id={"macro-" + this.props.item.id}
                        value={this.props.item.value}
                        field="value"
                        location={this.props.location.concat(this.props.item.id)}
                        label="Macro"
                        items={this.props.macroOptions}
                        updateFn={this._onUpdateFn}
                        style={dropdownStyle}
                        searchable={true}
                    />
                ) : (
                    ""
                )}
                {this.props.item.type === "apply workflow" ? (
                    <Dropdown
                        id={"workflow-" + this.props.item.id}
                        value={this.props.item.value}
                        field="value"
                        location={this.props.location.concat(this.props.item.id)}
                        label="Workflow"
                        items={this.props.workflowOptions}
                        updateFn={this._onUpdateFn}
                        style={dropdownStyle}
                        searchable={true}
                    />
                ) : (
                    ""
                )}
                <i style={closeIconStyle} className="material-icons" onClick={this._onRemoveAction}>
                    close
                </i>
            </div>
        );
    }
}

class TriageOptionsOverlay extends React.Component {
    constructor(props) {
        super(props);
        this._onBlur = this._onBlur.bind(this);
        this._onDelete = this._onDelete.bind(this);
        this._onAddTriageOption = this._onAddTriageOption.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onAddAction = this._onAddAction.bind(this);
    }

    _onBlur(field, location, value) {
        TriageOptionsActions.setField(field, location, value, true);
    }

    _onClose() {
        var location = this.props.item.location.concat();
        if (location.length === 0) UIActions.showOverlay("");
        else {
            TriageOptionsActions.get(TriageOptions => {
                var obj = objects.find(TriageOptions.working.options, null, location.concat());
                if (obj) {
                    location.splice(location.length - 1, 1);
                    UIActions.showOverlay("triageoption editor", {
                        target: obj.obj,
                        location: location
                    });
                }
            });
        }
    }

    _onDelete() {
        TriageOptionsActions.remove(this.props.item.location.concat(this.props.item.target.id));
        this._onClose();
    }

    _onAddTriageOption() {
        var newObj = { text: "", actions: [], options: [] };
        TriageOptionsActions.add("options", this.props.item.location.concat(this.props.item.target.id), newObj);

        UIActions.showOverlay("triageoption editor", {
            target: newObj,
            location: this.props.item.location.concat(this.props.item.target.id)
        });
    }

    _onAddAction() {
        var newObj = { type: "apply macro", value: "" };
        TriageOptionsActions.add("actions", this.props.item.location.concat(this.props.item.target.id), newObj, true);
    }

    _onSelect(field, location, value) {
        var obj = (this.props.item.target.options || []).find(item => item.id === value);
        if (obj)
            UIActions.showOverlay("triageoption editor", {
                target: obj,
                location: this.props.item.location.concat(this.props.item.target.id)
            });
    }

    render() {
        var actions = this.props.item.target.actions || [];
        var options = this.props.item.target.options || [];
        var location = this.props.item.location.concat(this.props.item.target.id);
        var workflowOptions = (this.props.client.workflows || []).map(item => {
            return { text: item.name, value: String(item.id) };
        });

        var macroOptions = (this.props.client.macros || []).map(item => {
            return { text: item.title, value: String(item.id) };
        });

        return (
            <Overlay>
                <Container maxWidth="848px" key="ticket-field-editor-container" marginBottom="0px">
                    <Card style={cardStyle}>
                        <H1 text="Triage Option" textAlign="center" />
                        <Input
                            value={this.props.item.target.text || ""}
                            onBlur={this._onBlur}
                            location={location}
                            label={"Option Text"}
                            field="text"
                            colored={false}
                            style={bigInputStyle}
                        />
                        {actions.length === 0 ? (
                            <Row index={2} last={options.length > 0}>
                                <SectionLabel text="Nested Options" />
                                {options.length === 0 ? (
                                    <div key="empty-workflows" style={emptyStyle}>
                                        <div style={emptyTextStyle}>There are currently no nested options</div>
                                        <Button text="Add Option" onClick={this._onAddTriageOption} />
                                    </div>
                                ) : (
                                    <div style={integrationsStyle}>
                                        {options.map((item, index) => (
                                            <SectionSelector
                                                id={item.id}
                                                key={item.id}
                                                text={item.text}
                                                value={item.id}
                                                index={index}
                                                location={[]}
                                                onClick={this._onSelect}
                                            />
                                        ))}
                                        <div style={buttonRowStyle}>
                                            <Button text="Add Option" onClick={this._onAddTriageOption} />
                                        </div>
                                    </div>
                                )}
                            </Row>
                        ) : (
                            ""
                        )}

                        {options.length === 0 ? (
                            <Row index={3} last={true}>
                                <SectionLabel text="Actions" />
                                {actions.length === 0 ? (
                                    <div key="empty-workflows" style={emptyStyle}>
                                        <div style={emptyTextStyle}>There are currently no actions</div>
                                        <Button text="Add Action" onClick={this._onAddAction} />
                                    </div>
                                ) : (
                                    <div style={integrationsStyle}>
                                        {actions.map((item, index) => (
                                            <Action
                                                id={item.id}
                                                key={item.id}
                                                item={item}
                                                location={location}
                                                index={index}
                                                workflowOptions={workflowOptions}
                                                macroOptions={macroOptions}
                                            />
                                        ))}
                                        <div style={buttonRowStyle}>
                                            <Button text="Add Action" onClick={this._onAddAction} />
                                        </div>
                                    </div>
                                )}
                            </Row>
                        ) : (
                            ""
                        )}

                        <div style={rowStyle}>
                            <Button text="Delete" style={buttonStyle} onClick={this._onDelete} type="flat" />
                            <Button
                                text={this.props.item.location.length > 0 ? "Back" : "Close"}
                                style={buttonStyle}
                                onClick={this._onClose}
                            />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default TriageOptionsOverlay;
