import React from "react";

class Cell extends React.Component {
    render() {
        var size = "60px";
        if (this.props.size === "xs") size = "60px";
        else if (this.props.size === "s") size = "140px";
        else if (this.props.size === "m") size = "200px";
        else if (this.props.size === "l") size = "300px";
        else if (this.props.size === "xl") size = "400px";

        var textAlign = this.props.textAlign || "left";

        var primaryStyle = {
            width: this.props.width || size,
            display: "inline-block",
            verticalAlign: this.props.verticalAlign || "middle",
            padding: this.props.padding || "0px 8px",
            textAlign: this.props.center ? "center" : textAlign,
            whiteSpace: !this.props.wrap ? "nowrap" : "",
            overflow: !this.props.wrap ? "hidden" : "",
            textOverflow: !this.props.wrap ? "ellipsis" : "",
            fontWeight: this.props.weight || ""
        };

        return <div style={primaryStyle}>{this.props.children}</div>;
    }
}

export default Cell;
