import React from "react";
import Dropzone from "react-dropzone";
import MDSpinner from "react-md-spinner";

import crms from "../../crms";

// Actions
import TicketActions from "../../actions/TicketActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Menu from "./Menu.react";
import Button from "./buttons/Button.react";

// Form items
import InputMultiline from "./forms/InputMultiline.react";
import Dropdown from "./forms/Dropdown.react";
import NavHorizontal from "./forms/NavHorizontal.react";
import Tokenizer from "./forms/TokenizerEmail.react";
import Pill from "./forms/Pill.react";
import Viewer from "./Viewer.react";

// Ticket parts
import TriageWorkflow from "../tickets/TriageWorkflow.react";

// Functions
import clone from "../../functions/clone";
import scroll from "../../functions/scroll";
import * as time from "../../functions/time";
import * as list from "../../functions/list";

class ControlsRibbon extends React.Component {
	constructor(props) {
		super(props);
		this._onDropItem = this._onDropItem.bind(this);
		this.state = { uploading: false };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.status.indexOf("adding attachments") > -1 && !this.state.uploading)
			this.setState({ uploading: true });
		else if (nextProps.status.indexOf("adding attachments") === -1 && this.state.uploading)
			this.setState({ uploading: false });
	}

	_onClickLink() {
		UIActions.showOverlay("hyperlink overlay");
	}

	_onDropItem(accepted, rejected) {
		if (accepted.length > 0) {
			this.setState({ uploading: true });
			TicketActions.addAttachments(accepted);
		} else {
			UIActions.addMessage(
				"Sorry, none of your files can be uploaded. Please ensure that none of the files are bigger 100MB.",
				"error"
			);
		}
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle"
		};

		const iconStyle = {
			fontSize: "20px",
			cursor: "pointer",
			display: "inline-block",
			marginRight: "8px",
			color: "rgba(0, 0, 0, 0.62)",
			verticalAlign: "middle"
		};

		const loaderStyle = {
			display: "inline-block",
			marginRight: "12px",
			verticalAlign: "middle",
			marginTop: "-4px"
		};

		const dropZoneStyle = {
			marginTop: "-4px",
			display: "inline-block",
			verticalAlign: "middle"
		};

		return (
			<div style={primaryStyle}>
				{this.state.uploading ? (
					<div style={loaderStyle}>
						<MDSpinner size={16} singleColor="rgb(70, 180, 175)" />
					</div>
				) : (
					<Dropzone style={dropZoneStyle} onDrop={this._onDropItem} maxSize={10000000} multiple={true}>
						<i style={iconStyle} className="material-icons">
							attach_file
						</i>
					</Dropzone>
				)}
			</div>
		);
	}
}

class Attachment extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onRemove = this._onRemove.bind(this);
	}

	_onClick() {
		UIActions.showOverlay("image overlay", {
			image: this.props.item,
			ticket: this.props.ticketId,
			comment: this.props.commentId
		});
	}

	_onRemove() {
		TicketActions.remove([this.props.item.id]);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			margin: "8px",
			textAlign: "center"
		};

		const imageStyle = {
			height: "60px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		const urlStyle = {
			fontSize: "12px",
			display: "inline-block",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		const removeStyle = {
			display: "block",
			marginTop: "6px",
			fontSize: "12px",
			cursor: "pointer"
		};

		var source = "";

		console.log(this.props.item);
		if (this.props.item.content_type.indexOf("image") > -1) {
			if (this.props.item.thumbnails !== undefined && this.props.item.thumbnails.length > 0)
				source = this.props.item.thumbnails[0].content_url;
			else source = this.props.item.content_url || this.props.item.url;
		}

		var filename = this.props.item.file_name || this.props.item.filename;

		return (
			<div style={primaryStyle}>
				{this.props.item.content_type.indexOf("image") > -1 ? (
					<img style={imageStyle} src={source} onClick={this._onClick} alt={filename || "Attachment"} />
				) : (
					<a style={urlStyle} href={this.props.item.mapped_content_url || this.props.item.url} target="_blank">
						{filename || "Attachment"}
					</a>
				)}
				{this.props.inReply ? (
					<div style={removeStyle} onClick={this._onRemove}>
						Remove
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

const zendeskStatusOptions = [
	{
		text: "Submit and Stay",
		value: "stay"
	},
	{ divider: true },
	{
		text: "Submit as open",
		value: "open"
	},
	{
		text: "Submit as pending",
		value: "pending"
	},
	{
		text: "Submit as on hold",
		value: "hold"
	},
	{
		text: "Submit as solved",
		value: "solved"
	}
];

const frontStatusOptons = [
	{
		text: "Submit and Stay",
		value: "stay"
	},
	{ divider: true },

	{
		text: "Submit as new",
		value: "new"
	},
	{
		text: "Submit as open",
		value: "open"
	},
	{
		text: "Submit as archived",
		value: "archived"
	}
];

var highRoles = ["admin", "manager"];

const sandboxModeLabelStyle = {
	display: "inline-block",
	width: "calc(100% + 32px)",
	textAlign: "center",
	padding: "16px 0px",
	fontSize: "30px",
	backgroundColor: "lightgoldenrodyellow",
	color: "darkorange",
	margin: "8px 0px -16px -16px"
};

const addressRowStyle = {
	width: "100%",
	fontSize: "14px",
	marginBottom: "8px"
};

const addressLabelStyle = {
	color: "rgba(0, 0, 0, 0.67)",
	marginRight: "8px"
};

const addressTokenizerStyle = {
	verticalAlign: "middle",
	width: "calc(100% - 42px)"
};

const simpleAddressStyle = {
	display: "inline-block",
	fontSize: "14px",
	cursor: "pointer"
};

const simpleRecipientStyle = {
	display: "inline-block",
	fontSize: "14px",
	padding: "4px 8px",
	border: "1px solid lightgrey",
	borderRadius: "4px",
	verticalAlign: "middle",
	fontWeight: "400",
	marginRight: "4px"
};

const attachmentsStyle = {
	width: "calc(100% - 16px)",
	textAlign: "left",
	padding: "8px"
};

class Reply extends React.Component {
	constructor(props) {
		super(props);
		this._onUpdateReplyBody = this._onUpdateReplyBody.bind(this);
		this._menuClick = this._menuClick.bind(this);
		this._onUpdateTokens = this._onUpdateTokens.bind(this);
		this._onInputChange = this._onInputChange.bind(this);
		this._expandAddress = this._expandAddress.bind(this);

		this.state = { errors: [], loading: false, characterCount: 0, addressExpanded: false };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.response) {
			if (nextProps.loading !== this.state.loading) this.setState({ loading: nextProps.loading });
		}
	}

	_onUpdateReplyBody(field, location, value) {
		TicketActions.setReply({ body: value }, true);
	}

	_onUpdate(field, location, value) {
		TicketActions.setField(field, location, value, true, true);
	}

	_onUpdateTokens(field, location, value) {
		TicketActions.setField(field, location, value, true, true);
	}

	_onUpdateType(field, location, value) {
		if (value === "public") {
			TicketActions.setField("public", location, true, false, true);
			TicketActions.setField("internal", location, false, true, true);
		} else if (value === "hioperator") {
			TicketActions.setField("public", location, false, false, true);
			TicketActions.setField("internal", location, true, true, true);
			TicketActions.setReply(
				{
					body:
						"What have I tried: <br/><br/> What's getting me stuck: <br/><br/> Here's my proposed response: <br/><br/>"
				},
				false
			);
		} else {
			TicketActions.setField("public", location, false, false, true);
			TicketActions.setField("internal", location, false, true, true);
		}
	}

	_menuClick(field, location, value) {
		if (value === "view on external") {
			var win = window.open(this.props.ticket.external_url.replace(".json", "").replace("api/v2/", ""), "_blank");
			win.focus();
		} else if (value === "copy external link") {
			var el = document.createElement("textarea");
			// Set value (string to be copied)
			el.value = this.props.ticket.external_url.replace(".json", "").replace("api/v2/", "");
			// Set non-editable to avoid focus and move outside of view
			el.setAttribute("readonly", "");
			el.style = { position: "absolute", left: "-9999px" };
			document.body.appendChild(el);
			// Select text inside element
			el.select();
			// Copy text to clipboard
			document.execCommand("copy");
			// Remove temporary element
			document.body.removeChild(el);
		} else if (value === "flag") UIActions.showOverlay("flag ticket");
		else if (value === "merge") UIActions.showOverlay("merge", this.props.ticket);
		else if (value === "spam") {
			UIActions.showConfirmation({
				title: "Mark ticket as spam",
				text: "Are you sure you want to mark this ticket as spam?",
				buttonText: "Mark as SPAM",
				callback: TicketActions.spam
			});
		} 
		else if (value === "escalate")
			UIActions.showOverlay("internal note", {
				escalate: true,
				text:
					"What my issue is: <br><br> What I have tried: <br><br> Proposed solution: <br><br>Is this in the ticket guide/what row in the ticket guide did you get stuck on?"
			});
		else if (value.indexOf("escalate") > -1) TicketActions.escalate(value);
		else if (value.indexOf("refresh") > -1) TicketActions.refresh();
		else if (value === "delete") {
			UIActions.showConfirmation({
				title: "Delete ticket",
				text: "Are you sure you want to delete this ticket?",
				buttonText: "Delete ticket",
				callback: TicketActions.delete
			});
		} else TicketActions.submit(value);
	}

	_onGetTicket() {
		TicketActions.releaseTicket("triage");
	}

	_onInputChange(field, location, value) {
		var el = document.getElementById(this.props.id + "-response-box");
		this.setState({ characterCount: el.innerText.indexOf("Enter response here") === 0 ? 0 : el.innerText.length - 1 });
	}

	_onSubmitAsOpen() {
		TicketActions.submit("open", "triage", false);
	}

	_expandAddress() {
		this.setState({ addressExpanded: true });
	}


	render() {
		const isPublic = this.props.reply !== undefined && this.props.reply.public ? true : false;

		var backgroundColor = "white";

		if (!isPublic) backgroundColor = this.props.reply.internal ? "rgba(244, 134, 66, 0.3)" : "rgba(135, 206, 250, 0.3)";

		const primaryStyle = {
			width: "calc(100% - 32px)",
			display: "inline-block",
			padding: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "20px",
			borderBottom: this.props.last ? "" : "1px solid rgba(0, 0, 0, 0.12)",
			backgroundColor: backgroundColor,
			borderTop: this.props.borderTop ? "1px solid rgb(70, 180, 175)" : ""
		};

		const headerLeftStyle = {
			width: "calc(100% - 32px)",
			display: "inline-block",
			verticalAlign: "top"
		};

		const headerRightStyle = {
			width: "32px",
			display: "inline-block",
			textAlign: "right"
		};

		const leftContainerStyle = {
			width: "58px",
			display: "inline-block",
			textAlign: "center",
			verticalAlign: "top"
		};

		const rightContainerStyle = {
			width: "calc(100% - 78px)",
			marginLeft: "20px",
			display: "inline-block",
			verticalAlign: "top"
		};

		const badgeStyle = {
			display: "inline-block",
			backgroundColor: "indianred",
			borderRadius: "50%",
			color: "white",
			padding: "4px",
			marginTop: "8px",
			fontSize: "32px"
		};

		const imageStyle = {
			height: "40px",
			width: "40px",
			display: "inline-block",
			borderRadius: "50%",
			cursor: "pointer",
			fontWeight: "900",
			fontFamily: "Roboto",
			fontSize: "36px",
			textAlign: "center",
			marginTop: "8px"
		};

		const responseControlsStyle = {
			width: "100%",
			display: "inline-block",
			marginTop: "16px"
		};

		const responseOuterStyle = {
			minHeight: "",
			borderTop: "1px solid rgba(0, 0, 0, 0.17)",
			paddingTop: "16px",
			marginBottom: "0px"
		};

		const responseInputStyle = {
			padding: "8px 0px",
			width: "100%"
		};

		// Find scaffold
		var crmScaffold = {};
		var i;
		for (i = 0; i < crms.length; i++) {
			if (crms[i].type === this.props.ticket.source) {
				crmScaffold = crms[i];
				break;
			}
		}

		// Sets submit options
		var options = [
			{
				text: "Submit and Stay",
				value: "stay"
			},
			{ divider: true }
		];
		for (i = 0; i < crmScaffold.submitOptions.length; i++) {
			if (
				(crmScaffold.submitOptions[i] === "new" && this.props.ticket.external_status === "new") ||
				crmScaffold.submitOptions[i] !== "new"
			) {
				options.push({
					text: "Submit as " + crmScaffold.submitOptions[i],
					value: crmScaffold.submitOptions[i]
				});
			}
		}

		const characterCountStyle = {
			textAlign: "right",
			fontSize: "14px",
			padding: "8px 0px",
			width: "100%",
			display: "inline-block",
			color:
				!isNaN(this.props.ticket.characterLimit) && this.state.characterCount > Number(this.props.ticket.characterLimit)
					? "red"
					: "inherit"
		};

		const submitStyle = {
			width: "220px",
			marginBottom: "0px",
			height: "50px",
			verticalAlign: "middle",
			marginRight: "16px"
		};

		const viewersStyle = {
			width: "calc(100% - 16px)",
			textAlign: "right",
			padding: "8px"
		};

		const typeOptions = [
			{
				text: "Public response",
				value: "public"
			},
			{
				text:
					"Internal note (" +
					this.props.ticket.source.charAt(0).toUpperCase() +
					this.props.ticket.source.substr(1, 1000) +
					")",
				value: "internal"
			},
			{
				text: "Internal note (HiOperator only)",
				value: "hioperator"
			}
		];

		var messageType = "internal";
		if (this.props.response) {
			if (this.props.reply.public) messageType = "public";
			else if (this.props.reply.internal) messageType = "hioperator";
		} else {
			if (this.props.item.public) messageType = "public";
			else if (this.props.item.internal) messageType = "hioperator";
		}

		// Configures menu options for eesponse
		var menuOptions = [
			{
				text: "View ticket on " + this.props.ticket.source,
				value: "view on external"
			},
			{
				text: "Copy " + this.props.ticket.source + " link",
				value: "copy external link"
			},
			{ divider: true },
			{
				text: "Escalate",
				value: "escalate"
			}
		];

		if (highRoles.indexOf(this.props.user.role) > -1 || this.props.permission.clients_escalations) {
			menuOptions = menuOptions.concat([
				{
					text: "Escalate external",
					value: "escalate external"
				},
				{
					text: "Deescalate",
					value: "deescalate"
				}
			]);
		}

		if (crmScaffold.ticketOptions.merge) menuOptions.push({ text: "Merge into another ticket", value: "merge" });
		if (crmScaffold.ticketOptions.markAsSpam) menuOptions.push({ text: "Mark as spam", value: "spam" });

		menuOptions = menuOptions.concat([
			{
				text: "Flag ticket",
				value: "flag"
			},
			{
				text: "Refresh ticket",
				value: "refresh"
			}
		]);

		if (highRoles.indexOf(this.props.user.role) > -1 || this.props.permission.clients_tickets) {
			menuOptions = menuOptions.concat([
				{
					divider: true
				},
				{
					text: "Delete ticket",
					value: "delete"
				}
			]);
		}

		var hasWorkflow = false;
		if (this.props.ticket.workflows !== undefined) {
			var tempKeys = Object.keys(this.props.ticket.workflows);
			if (tempKeys.length > 0) hasWorkflow = true;
		}

		// Sets submit text
		var submitText = "SUBMIT";
		if (
			[null, undefined].indexOf(this.props.channel) === -1 &&
			[null, undefined, ""].indexOf(this.props.channel.label) === -1
		)
			submitText = "SUBMIT via " + this.props.channel.label;
		else if (this.props.ticket.via !== undefined && this.props.ticket.via.indexOf("twitter") > -1)
			submitText = "SUBMIT via TWITTER";
		// Chracter count

		return (
			<Card style={primaryStyle}>
				<div style={leftContainerStyle}>
					{this.props.user !== undefined && this.props.user.picture !== undefined ? (
						<img style={imageStyle} src={this.props.user.picture} alt="" />
					) : (
						<i style={badgeStyle} className="material-icons">
							person_outline
						</i>
					)}
				</div>
				<div style={rightContainerStyle}>
					{!this.props.reply.public ? <div style={headerLeftStyle} /> : ""}
					{this.props.reply.public && !this.state.addressExpanded ? (
						<div style={headerLeftStyle}>
							<div style={simpleAddressStyle} onClick={this._expandAddress}>
								<span style={addressLabelStyle}>To</span>
								{this.props.reply.to.map((item, index) => (
									<div key={"to-" + item} style={simpleRecipientStyle}>
										{item}
									</div>
								))}
								{this.props.reply.cc.map((item, index) => (
									<div key={"cc-" + item} style={simpleRecipientStyle}>
										{item}
									</div>
								))}
								{this.props.reply.bcc.map((item, index) => (
									<div key={"bcc-" + item} style={simpleRecipientStyle}>
										{item}
									</div>
								))}
							</div>
						</div>
					) : (
						""
					)}
					{this.props.reply.public && this.state.addressExpanded ? (
						<div style={headerLeftStyle}>
							<div style={addressRowStyle}>
								<span style={addressLabelStyle}>From</span>
								{this.props.user.name}
								{" <"}
								{this.props.user.email}
								{">"}
							</div>
							<div style={addressRowStyle}>
								<span style={addressLabelStyle}>To</span>
								{crmScaffold.ticketOptions.editTo ? (
									<Tokenizer
										id={"reply-to"}
										value={this.props.reply.to}
										updateFn={this._onUpdateTokens}
										field="to"
										style={addressTokenizerStyle}
										label="Add to"
									/>
								) : (
									<span>{this.props.reply.to[0]}</span>
								)}
							</div>
							<div style={addressRowStyle}>
								<span style={addressLabelStyle}>Cc</span>
								{crmScaffold.ticketOptions.editCC ? (
									<Tokenizer
										id={"reply-cc"}
										value={this.props.reply.cc}
										updateFn={this._onUpdateTokens}
										field="cc"
										style={addressTokenizerStyle}
										label="Add cc"
									/>
								) : (
									<span>{this.props.reply.cc || ""}</span>
								)}
							</div>
							<div style={addressRowStyle}>
								<span style={addressLabelStyle}>Bcc</span>
								{crmScaffold.ticketOptions.editBCC ? (
									<Tokenizer
										id={"reply-bcc"}
										value={this.props.reply.bcc}
										updateFn={this._onUpdateTokens}
										field="bcc"
										style={addressTokenizerStyle}
										label="Add bcc"
									/>
								) : (
									<span>{this.props.reply.bcc || ""}</span>
								)}
							</div>
						</div>
					) : (
						""
					)}

					<div style={headerRightStyle}>
						<Menu id={"menu-email-response"} items={menuOptions} updateFn={this._menuClick} menuWidth="210px" />
					</div>
					<NavHorizontal id="response-nav" items={typeOptions} value={messageType} updateFn={this._onUpdateType} />

					<InputMultiline
						id={this.props.id + "-response-box"}
						field="text"
						value={this.props.reply.text || ""}
						placeholder="Enter response here"
						style={responseOuterStyle}
						inputStyle={responseInputStyle}
						class=""
						noLine={true}
						onBlur={this._onUpdateReplyBody}
						updateFn={this._onInputChange}
						predict={true}
					/>

					<div style={characterCountStyle}>
						{this.state.characterCount} characters long{" "}
						{this.props.response &&
						!isNaN(this.props.ticket.characterLimit) &&
						this.state.characterCount > Number(this.props.ticket.characterLimit)
							? ". Maximum number allowed is " + this.props.ticket.characterLimit
							: ""}
					</div>

					<div style={responseControlsStyle}>
						{this.props.ticket !== undefined &&
						([undefined, null, false, ""].indexOf(this.props.ticket.escalate) === -1 || hasWorkflow) &&
						!this.props.forManagers &&
						!this.state.loading ? (
							<Button text="Get Next Ticket" type="flat" onClick={this._onGetTicket} />
						) : (
							""
						)}
						{this.props.ticket !== undefined &&
						!this.props.forManagers &&
						!this.state.loading &&
						[null, undefined, ""].indexOf(this.props.channel) === -1 &&
						!isNaN(this.props.channel.length) &&
						this.props.channel.length > 0 ? (
							<Button text="Submit as Open and Stay on Ticket" type="flat" onClick={this._onSubmitAsOpen} />
						) : (
							""
						)}
						<Dropdown
							id={this.props.id + "-reply-button"}
							text={submitText}
							style={submitStyle}
							items={options}
							button={true}
							updateFn={this.props.onSend}
							loading={this.state.loading}
						/>
						<ControlsRibbon status={this.props.status} />
					</div>

					{this.props.item !== undefined &&
					this.props.item.attachments !== undefined &&
					this.props.item.attachments.length > 0 ? (
						<div style={attachmentsStyle}>
							{this.props.item.attachments.map((item, index, arr) => (
								<Attachment
									item={item}
									key={this.props.item.id + "-" + index}
									ticketId={this.props.ticket.id}
									commentId={this.props.item.id}
								/>
							))}
						</div>
					) : (
						""
					)}
					{this.props.reply.attachments !== undefined && this.props.reply.attachments.length > 0 ? (
						<div style={attachmentsStyle}>
							{this.props.reply.attachments.map((item, index, arr) => (
								<Attachment item={item} key={this.props.id + "-" + index} inReply={true} />
							))}
						</div>
					) : (
						""
					)}
					{this.props.ticket.viewing !== undefined ? (
						<div style={viewersStyle}>
							{this.props.ticket.viewing.map((item, index) => (
								<Viewer key={this.props.id + "-" + item.id} item={item} />
							))}
						</div>
					) : (
						""
					)}
				</div>
				{this.props.user.sandboxMode || this.props.permission.sandbox ? (
					<div style={sandboxModeLabelStyle}>SANDBOX MODE (REPLY WONT BE SENT)</div>
				) : (
					""
				)}
			</Card>
		);
	}
}

const sandboxRatingItems = [
	{
		text: "NA",
		value: null
	},
	{
		text: "1",
		value: 1
	},
	{
		text: "2",
		value: 2
	},
	{
		text: "3",
		value: 3
	},
	{
		text: "4",
		value: 4
	},
	{
		text: "5",
		value: 5
	}
];

const sandboxRatingRowStyle = {
	width: "100%",
	textAlign: "center",
	padding: "0px 0px",
	display: "inline-block"
};

class Comment extends React.Component {
	constructor(props) {
		super(props);
		this._onRateSandboxResponse = this._onRateSandboxResponse.bind(this);
	}

	_onRateSandboxResponse(field, location, value) {
		TicketActions.setSandboxResponse(this.props.item.id, value);
	}

	render() {
		const isPublic = this.props.item !== undefined && this.props.item.public ? true : false;

		var backgroundColor = "white";

		if (this.props.item !== undefined && this.props.item.sandbox) backgroundColor = "lightgoldenrodyellow";
		else if (!isPublic)
			backgroundColor = this.props.reply.internal ? "rgba(244, 134, 66, 0.3)" : "rgba(135, 206, 250, 0.3)";

		const primaryStyle = {
			width: "calc(100% - 32px)",
			display: "inline-block",
			padding: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "20px",
			borderBottom: this.props.last ? "" : "1px solid rgba(0, 0, 0, 0.12)",
			backgroundColor: backgroundColor,
			borderTop: this.props.borderTop ? "1px solid rgb(70, 180, 175)" : ""
		};

		const headerLeftStyle = {
			width: "calc(100% - 260px)",
			display: "inline-block",
			verticalAlign: "top"
		};

		const headerRightStyle = {
			width: "260px",
			display: "inline-block",
			textAlign: "right"
		};

		const nameStyle = {
			fontSize: "14px",
			display: "inline-block",
			fontWeight: "700",
			color: "rgba(0, 0, 0. 0.87)"
		};

		const emailStyle = {
			margin: "0px 8px 0px 4px",
			fontSize: "14px",
			display: "inline-block",
			color: "rgba(0, 0, 0. 0.87)"
		};

		const dateStyle = {
			marginLeft: "16px",
			display: "inline-block",
			fontSize: "14px",
			fontWeight: "400",
			marginRight: "16px"
		};

		const leftContainerStyle = {
			width: "58px",
			display: "inline-block",
			textAlign: "center",
			verticalAlign: "top"
		};

		const rightContainerStyle = {
			width: "calc(100% - 78px)",
			marginLeft: "20px",
			display: "inline-block",
			verticalAlign: "top"
		};

		const badgeStyle = {
			display: "inline-block",
			backgroundColor: this.props.item.author_email === this.props.ticket.requester_email ? "teal" : "indianred",
			borderRadius: "50%",
			color: "white",
			padding: "4px",
			marginTop: "8px",
			fontSize: "32px"
		};

		const bodyStyle = {
			width: "100%",
			fontSize: "14px",
			fontWeight: "400",
			marginTop: "16px",
			wordWrap: "break-word"
		};

		const badgeLabelStyle = {
			width: "100%",
			textAlign: "center",
			fontSize: "12px",
			fontWeight: "400",
			marginTop: "8px"
		};

		var created = this.props.item !== undefined ? time.parse_time(this.props.item.date) : "";

		const attachmentsStyle = {
			width: "calc(100% - 16px)",
			textAlign: "left",
			padding: "8px"
		};

		return (
			<Card style={primaryStyle}>
				<div style={leftContainerStyle}>
					<i style={badgeStyle} className="material-icons">
						person_outline
					</i>
				</div>
				<div style={rightContainerStyle}>
					<div style={headerLeftStyle}>
						<div style={nameStyle}>{this.props.response ? this.props.user.name : this.props.item.author_name}</div>
						<div style={emailStyle}>
							{"<"}
							{this.props.response ? this.props.user.email : this.props.item.author_email}
							{">"}
						</div>
					</div>
					<div style={headerRightStyle}>{created === "" ? "" : <div style={dateStyle}>{created}</div>}</div>
					<div
						style={bodyStyle}
						dangerouslySetInnerHTML={{
							__html: (this.props.item.text || "").replace(/\n/g, "")
						}}
					/>

					{this.props.item !== undefined &&
					this.props.item.attachments !== undefined &&
					this.props.item.attachments.length > 0 ? (
						<div style={attachmentsStyle}>
							{this.props.item.attachments.map((item, index, arr) => (
								<Attachment
									item={item}
									key={this.props.item.id + "-" + index}
									ticketId={this.props.ticket.id}
									commentId={this.props.item.id}
								/>
							))}
						</div>
					) : (
						""
					)}
				</div>
				{this.props.item !== undefined && this.props.item.sandbox ? (
					<div style={sandboxModeLabelStyle}>
						SANDBOX MODE (REPLY WAS NOT SENT)
						<div style={sandboxRatingRowStyle}>
							<Dropdown
								id={"sandbox-rating-" + this.props.item.id}
								style={csatCauseStyle}
								label="Response Rating"
								field="sandboxRating"
								value={this.props.item.sandboxRating}
								items={sandboxRatingItems}
								updateFn={this._onRateSandboxResponse}
							/>
						</div>
					</div>
				) : (
					""
				)}
			</Card>
		);
	}
}

const eventTextStyle = {
	display: "inline-block",
	verticalAlign: "middle",
	maxWidth: "calc(100% - 60px)"
};

class Event extends React.Component {
	render() {
		var agent = this.props.item.agent || {};
		var statusColor;
		if (this.props.item.status === "pending") statusColor = "#59BBE0";
		else if (["open", "assigned"].indexOf(this.props.item.status) > -1) statusColor = "#E82A2A";
		else if (["solved", "archived"].indexOf(this.props.item.status) > -1) statusColor = "#828282";
		else if (this.props.item.status === "closed") statusColor = "black";
		else if (this.props.item.status === "hold") statusColor = "black";
		else statusColor = "#F5CA00";

		const eventStyle = {
			width: "calc(100% - 72px)",
			padding: this.props.previousIsEvent ? "0px 16px 24px 64px" : "24px 16px 24px 64px",
			display: "inline-block",
			textAlign: "left",
			fontStyle: "italic"
		};

		const statusStyle = {
			color: statusColor,
			fontWeight: "400"
		};

		return (
			<div style={eventStyle}>
				<Viewer item={agent} />
				<div style={eventTextStyle}>
					{agent.name || ""} {this.props.item.action} as{" "}
					<span style={statusStyle}>{this.props.item.status.toUpperCase()}</span> at{" "}
					{time.parse_time(this.props.item.start)} / <b>{time.parse_diff(this.props.item.duration / 1000)}</b>
				</div>
			</div>
		);
	}
}

const csatRatingStyle = {
	fontSize: "24px",
	width: "calc(100% - 200px)",
	marginBottom: "16px",
	display: "inline-block"
};

const csatContainerStyle = {
	width: "calc(100% - 100px)",
	display: "inline-block",
	verticalAlign: "top"
};

const csatReasonStyle = {
	marginLeft: "16px",
	opacity: "0.7",
	fontSize: "16px",
	marginBottom: "4px",
	verticalAlign: "middle",
	display: "inline-block"
};

const csatCommentStyle = {
	width: "100%",
	display: "inline-block",
	fontStyle: "italic"
};

const csatDateStyle = {
	display: "inline-block",
	textAlign: "right",
	width: "200px",
	fontSize: "14px"
};

const csatCauseStyle = {
	width: "300px",
	marginTop: "16px",
	marginRight: "16px"
};

const csatReasonOptions = [
	{
		text: "HiOperator dropped the ball",
		value: "people"
	},
	{
		text: "Product issue",
		value: "product"
	},
	{
		text: "Client policy",
		value: "policy"
	},
	{
		text: "Tech issue",
		value: "tech"
	},
	{
		text: "Noise (no one really at fault)",
		value: "noise"
	}
];

class CSAT extends React.Component {
	render() {
		const primaryStyle = {
			maxWidth: "calc(100% - 32px)",
			width: "1000px",
			padding: "16px",
			display: "inline-block",
			textAlign: "left",
			margin: "16px 0px",
			backgroundColor: "white"
		};

		const csatUpperStyle = {
			width: "calc(100%)",
			padding: "16px",
			marginLeft: "-16px",
			marginTop: "-16px",
			marginBottom: "16px",
			backgroundColor: this.props.item.score === "bad" ? "indianred" : "lightseagreen",
			color: "white",
			fontSize: "24px"
		};

		const csatIconStyle = {
			fontSize: "40px",
			marginRight: "32px",
			marginLeft: "16px",
			color: this.props.item.score === "bad" ? "indianred" : "lightseagreen"
		};

		var agents = [];
		if (this.props.agents !== undefined) {
			for (var i = 0; i < this.props.agents.length; i++) {
				agents.push({
					text: this.props.agents[i].name,
					value: this.props.agents[i].id
				});
			}
		}

		return (
			<Card style={primaryStyle}>
				<div style={csatUpperStyle}>CSAT RATING</div>
				<i className="material-icons" style={csatIconStyle}>
					{this.props.item.score === "bad" ? "sentiment_very_dissatisfied" : "sentiment_very_satisfied"}
				</i>
				<div style={csatContainerStyle}>
					<div style={csatRatingStyle}>
						<b>{this.props.item.score.toUpperCase()}</b> <span style={csatReasonStyle}> {this.props.item.reason}</span>
					</div>
					<div style={csatDateStyle}>{time.parse_time(this.props.item.date)}</div>
					<div style={csatCommentStyle}> {this.props.item.comment || "No comment provided"}</div>
					<Dropdown
						id={"dropdown-" + this.props.item.id}
						style={csatCauseStyle}
						items={csatReasonOptions}
						label="Reason for negative review"
						field={"CSAT_check"}
						value={this.props.value || ""}
						updateFn={TicketActions.setCSATCategory}
						searchable={true}
					/>
					{this.props.value === "people" ? (
						<Dropdown
							id={"dropdown-" + this.props.item.id + "-guru"}
							style={csatCauseStyle}
							items={agents}
							label="Guru responsible"
							field={"CSAT_guru"}
							value={this.props.person || ""}
							updateFn={TicketActions.setCSATCategory}
							searchable={true}
						/>
					) : (
						""
					)}
				</div>
			</Card>
		);
	}
}

const internalStyle = {
	width: "100%",
	display: "inline-block",
	margin: "24px 0px",
	textAlign: "left"
};

const internalMetaRowStyle = {
	textAlign: "left",
	padding: "0px 0px 8px",
	fontSize: "12px",
	opacity: "0.7"
};

const internalTypeStyle = {
	width: "200px",
	display: "inline-block"
};

const internalTimeStyle = {
	width: "calc(100% - 232px)",
	display: "inline-block",
	textAlign: "right"
};

class Internal extends React.Component {
	render() {
		var backgroundColor = this.props.item.internal ? "rgba(244, 134, 66, 0.3)" : "rgba(135, 206, 250, 0.3)";

		if (this.props.item !== undefined && this.props.item.sandbox) backgroundColor = "lightgoldenrodyellow";

		const bodyStyle = {
			display: "inline-block",
			borderRadius: "16px",
			padding: "16px",
			width: "calc(100% - 76px)",
			textAlign: "left",
			backgroundColor: backgroundColor,
			marginLeft: "8px",
			verticalAlign: "top",
			boxShadow:
				"rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
		};

		var created = this.props.item !== undefined ? time.parse_time(this.props.item.date) : "";
		var agent = { name: this.props.item.author_name };
		for (var i = 0; i < this.props.agents.length; i++) {
			if (this.props.agents[i].email.toLowerCase() === this.props.item.author_email) {
				agent = this.props.agents[i];
				break;
			}
		}

		return (
			<div style={internalStyle}>
				<Viewer item={agent} margin={"10px 4px 0px 0px"} />
				<div style={bodyStyle}>
					<div style={internalMetaRowStyle}>
						<div style={internalTypeStyle}>
							{this.props.item.internal
								? "HiOperator only"
								: "Visible on " + this.props.source.charAt(0).toUpperCase() + this.props.source.substr(1, 1000)}
						</div>
						<div style={internalTimeStyle}>{created}</div>
					</div>
					<div
						dangerouslySetInnerHTML={{
							__html: this.props.item.text.replace(/\n/g, "")
						}}
					/>
					{this.props.item !== undefined &&
					this.props.item.attachments !== undefined &&
					this.props.item.attachments.length > 0 ? (
						<div style={attachmentsStyle}>
							{this.props.item.attachments.map((item, index, arr) => (
								<Attachment
									item={item}
									key={this.props.item.id + "-" + index}
									ticketId={this.props.ticket.id}
									commentId={this.props.item.id}
								/>
							))}
						</div>
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

var harmonize_comments_events = ticket => {
	var events = clone(ticket.events || []);
	var comments = clone(ticket.comments || []);
	var csat =
		ticket.csat_rating !== undefined && ["good", "bad"].indexOf(ticket.csat_rating.score) > -1
			? [clone(ticket.csat_rating)]
			: [];

	var i;

	for (i = 0; i < events.length; i++) {
		events[i].date = events[i].start;
		events[i].isEvent = true;
	}

	for (i = 0; i < csat.length; i++) {
		csat[i].date = csat[i].updated_at || new Date();
		csat[i].isCSAT = true;
	}

	comments = comments.concat(events);
	comments = comments.concat(csat);
	comments = list.order(comments, "date newest");

	return comments;
};

class Email extends React.Component {
	constructor(props) {
		super(props);
		this._onToggleOpen = this._onToggleOpen.bind(this);
		
		this.state = {
			errors: [],
			comments_events: harmonize_comments_events(this.props.ticket || {}),
			open: true,
		};
	}

	componentWillReceiveProps(nextProps) {
		var count =
			(nextProps.ticket.comments || []).length +
			(nextProps.ticket.events || []).length +
			(nextProps.ticket.csat_rating !== undefined && ["good", "bad"].indexOf(nextProps.ticket.csat_rating.score) > -1
				? 1
				: 0);

		if (nextProps.client !== undefined && this.props.client === undefined)
			this.setState({
				comments_events: harmonize_comments_events(nextProps.ticket || {})
			});
		else if (count !== this.state.comments_events.length)
			this.setState({
				comments_events: harmonize_comments_events(nextProps.ticket || {})
			});
	}

	_onClickOtherTickets() {
		scroll("other-tickets", 200);
	}

	_onToggleOpen() {
		this.setState({ open: this.state.open ? false : true });
	}



	render() {
		const clientStyle = {
			padding: "16px 16px",
			width: "calc(100% - 32px)",
			display: "inline-block",
			textAlign: "left"
		};

		const clientNameContainerStyle = {
			width: "360px",
			display: "inline-block"
		};

		const totalDurationStyle = {
			display: "inline-block",
			width: "calc(100% - 360px)",
			textAlign: "right"
		};

		const subjectStyle = {
			width: "calc(100% - 32px)",
			display: "inline-block",
			padding: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "20px"
		};

		const emailStyle = {
			marginBottom: this.props.displayOnly ? "24px" : "48px",
			width: "1000px",
			maxWidth: "100%",
			display: "inline-block"
		};

		const subjectTextStyle = {
			width: "calc(100% - 270px)",
			display: "inline-block",
			verticalAlign: "middle"
		};

		const toggleContainerStyle = {
			width: "270px",
			display: "inline-block",
			verticalAlign: "middle",
			textAlign: "right"
		};

		const toggleStyle = {
			display: "inline-block",
			color: "rgba(0, 0, 0, 0.54)",
			fontSize: "30px",
			cursor: "pointer",
			verticalAlign: "middle"
		};

		// Sets color of status
		var statusStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "20px",
			margin: "8px 8px"
		};

		if (this.props.ticket.external_status === "pending") statusStyle.color = "#59BBE0";
		else if (["open", "assigned"].indexOf(this.props.ticket.external_status) > -1) statusStyle.color = "#E82A2A";
		else if (["solved", "archived"].indexOf(this.props.ticket.external_status) > -1) statusStyle.color = "#828282";
		else if (this.props.ticket.external_status === "closed") statusStyle.color = "black";
		else if (this.props.ticket.external_status === "hold") statusStyle.color = "black";
		else if (this.props.ticket.external_status === "deleted") statusStyle.color = "black";
		else statusStyle.color = "#F5CA00";

		return (
			<div style={emailStyle}>
				{!this.props.displayOnly ? (
					<div style={clientStyle}>
						<div style={clientNameContainerStyle}>External #: {this.props.ticket.external_id}</div>
						<div style={totalDurationStyle}>
							Total duration: <b>{time.parse_diff((this.props.ticket.duration || 0) / 1000)}</b>
						</div>
					</div>
				) : (
					""
				)}

				<Card style={subjectStyle}>
					<div style={subjectTextStyle}>{this.props.ticket.title}</div>
					<div style={toggleContainerStyle}>
						{this.props.ticket.escalate !== "" && this.props.ticket.escalate !== undefined ? (
							<Pill text={this.props.ticket.escalate + " escalation"} />
						) : (
							""
						)}
						<div style={statusStyle}>{this.props.ticket.external_status.toUpperCase()}</div>
						{this.props.collapsable ? (
							<i className="material-icons" style={toggleStyle} onClick={this._onToggleOpen}>
								{this.state.open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
							</i>
						) : (
							""
						)}
					</div>
				</Card>

				{/*Reply box */}
				{!this.props.displayOnly && !this.props.triageWorkflowMode ? (
					<Reply
						id={this.props.ticket.id}
						key={this.props.ticket.id + "-reply"}
						user={this.props.user}
						response={true}
						reply={this.props.reply}
						status={this.props.status}
						agents={this.props.client !== undefined ? this.props.client.agents : undefined}
						ticket={this.props.ticket}
						channel={this.props.ticket.channel || "email"}
						onSend={this.props.onSend}
						assignee={this.props.assignee}
						loading={this.props.loading}
						forManagers={this.props.forManagers}
						permission={this.props.permission || {}}
						borderTop={true}
						requesterTickets={
							this.props.ticket !== undefined && this.props.ticket.requesterTickets !== undefined
								? this.props.ticket.requesterTickets.length || 0
								: 0
						}
					/>
				) : (
					""
				)}

				{/*Triage Options */}
				{!this.props.displayOnly && this.props.triageWorkflowMode ? (
					<TriageWorkflow
						id={this.props.ticket.id + "-triage-workflow"}
						key={this.props.ticket.id + "-triage-workflow"}
						user={this.props.user}
						ticket={this.props.ticket}
								client={this.props.client}
						channel={this.props.ticket.channel || "email"}
						onRunTriageActions={this.props.onRunTriageActions}
					/>
				) : (
					""
				)}

				{/*Previous emails on the thread */}
				{this.state.open || !this.props.collapsable ? (
					<div>
						{this.state.comments_events.map((item, index, arr) => {
							if (item.isEvent && this.props.showEvents)
								return (
									<Event
										item={item}
										index={index}
										key={item.id}
										badge={item.agent || {}}
										previousIsEvent={
											index > 0 && (arr[index - 1].isEvent || arr[index - 1].public === false) ? true : false
										}
									/>
								);
							else if (item.isEvent && !this.props.showEvents) return "";
							else if (item.isCSAT)
								return (
									<CSAT
										item={item}
										index={index}
										key={item.id}
										value={this.props.ticket.CSAT_check}
										person={this.props.ticket.CSAT_guru}
										agents={this.props.client.agents}
									/>
								);
							else if (!item.public)
								return (
									<Internal
										item={item}
										index={index}
										key={item.id}
										ticket={this.props.ticket}
										agents={this.props.client.agents || []}
										source={this.props.ticket.source}
									/>
								);
							else
								return (
									<Comment
										id={this.props.ticket.id}
										borderTop={index === 0 && this.props.displayOnly ? true : false}
										key={this.props.ticket.id + "-" + index}
										item={item}
										ticket={this.props.ticket}
										last={index === arr.length - 1}
									/>
								);
						})}
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}

export default Email;
