import React from "react";

// Functions
import m from "../../../functions/m";

class MenuItem extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		this.props.updateFn(this.props.item.value);
	}

	render() {
		const primaryStyle = {
			padding: this.props.padding || "8px",
			borderBottom: this.props.selected ? "3px solid rgb(70, 180, 175)" : "3px solid transparent",
			transition: "all .3s ease",
			cursor: "pointer",
			display: "inline-block",
			verticalAlign: "middle"
		};

		return (
			<div style={primaryStyle} onClick={this._onClick}>
				{this.props.item.text}
			</div>
		);
	}
}

class NavHorizontal extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
		this._updateFn = this._updateFn.bind(this);
		this.state = { value: this.props.value || "" };
	}

	componentWillUpdate(nextProps) {
		if (nextProps.value !== this.state.value) this.setState({ value: nextProps.value });
	}

	_onClose() {
		this.props.updateFn(this.props.field, this.props.location, this.props.value);
	}

	_updateFn(value) {
		this.setState({ value: value });
		this.props.updateFn(this.props.field, this.props.location, value);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			width: "100%",
			marginTop: "16px",
			fontSize: "16px"
		};

		return (
			<div style={m(primaryStyle, this)}>
				{this.props.items.map((item, index) => (
					<MenuItem
						key={this.props.id + "-" + index}
						item={item}
						padding={this.props.itemPadding}
						selected={this.state.value === item.value}
						updateFn={this._updateFn}
					/>
				))}
			</div>
		);
	}
}

export default NavHorizontal;
