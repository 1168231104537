import React from 'react';

import MDSpinner from "react-md-spinner";

// Scaffolding
import OuterScaffold from './OuterScaffold.react';

class Loading extends React.Component {
    render () {
        const primaryStyle = {
            height: "150px",
            marginBottom: "50px",
            display: "inline-block"
        };

        const containerStyle = {
            width: "100%",
            display: "inline-block",
            textAlign: "center",
            verticalAlign: "middle"
        };

        return (
            <OuterScaffold>
                <div style={containerStyle}>
                    <img src={"/images/logo_white_bg@2x.png"} style={primaryStyle} alt=""/>
                    <div style={containerStyle}>
                        <MDSpinner size={60} singleColor="rgb(70, 180, 175)" />
                    </div>
                </div>
            </OuterScaffold>
        );
    }
}

export default Loading;
