export default {
    name: "Zendesk to Jira Link",
    type: "jiralink",
    category: "Other",
    icon: "link",
    apiFields: [
        {
            field: "email",
            label: "Zendesk Email",
            type: "text",
            validation: [
                {
                    type: "min-length",
                    value: 6
                },
                {
                    type: "contains",
                    value: "@"
                },
                {
                    type: "contains",
                    value: "."
                }
            ]
        },
        {
            field: "url",
            label: "Zendesk URL",
            type: "text",
            validation: [
                {
                    type: "contains",
                    value: "zendesk.com"
                },
                {
                    type: "contains",
                    value: "https://"
                }
            ]
        },
        {
            field: "apiKey",
            label: "Zendesk API key",
            type: "text",
            validation: [
                {
                    type: "length",
                    value: 40
                }
            ]
        }
    ],
    cardFields: [
        {
            field: "integrationId",
            label: "Integration ID",
            type: "text"
        },
        {
            field: "ticketId",
            label: "Zendesk Ticket ID",
            type: "text"
        },
        {
            field: "issueId",
            label: "Jira Issue ID",
            type: "text"
        },
        {
            field: "issueKey",
            label: "Jira Issue Key",
            type: "text"
        }
    ]
};
