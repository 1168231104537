import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Cell from "../_common/Cell.react";
import HeaderRow from "../_common/HeaderRow.react";
import Filter from "../_common/Filter.react";

// Rows
import ActiveTicketRow from "./ActiveTicketRow.react";

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

class ActiveTickets extends React.Component {
	constructor(props) {
		super(props);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNextPage() {
		ClientActions.loadSubcollection(this.props.view.replace("clients_", ""), 1);
		window.scrollTo(0, 0);
	}

	_onPreviousPage() {
		ClientActions.loadSubcollection(this.props.view.replace("clients_", ""), -1);
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={true}>
				<Container width="1200px" maxWidth="1200px" menuSpace={true} verticalAlign="top">
					{this.props.tickets === undefined ? (
						<div />
					) : (
						<SectionCard
							title={this.props.title || "ACTIVE TICKETS"}
							subtext={"Showing " + this.props.tickets.length + " tickets."}
							maxWidth="calc(100% - 48px)"
							width="calc(100% - 48px)"
							margin="8px 0px 250px 0px"
						>
							{this.props.tickets === undefined || this.props.tickets.length === 0 ? (
								<div key="empty-workflows" style={emptyStyle}>
									<div style={emptyTextStyle}>There are currently no tickets</div>
								</div>
							) : (
								<div style={integrationsStyle}>
									<HeaderRow>
										<Cell size="xs" width="50px">
											Client
										</Cell>
										<Cell size="s" width="90px">
											External #
										</Cell>
										<Cell size="s" width="70px">
											Status
										</Cell>
										<Cell size="m">Requester</Cell>
										<Cell size="l">Subject</Cell>
										<Cell size="m">Last Updated</Cell>
									</HeaderRow>

									{this.props.tickets.map((item, index, arr) => (
										<ActiveTicketRow
											key={item.id + "-" + index}
											index={index}
											clients={this.props.clients}
											item={item}
										/>
									))}
								</div>
							)}
						</SectionCard>
					)}
				</Container>
			</OuterScaffold>
		);
	}
}

export default ActiveTickets;
