import React from "react";

// Functions
import m from "../../../functions/m";

class Radio extends React.Component {
	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
		this.state = { value: this.props.value === undefined ? false : this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value) this.setState({ value: nextProps.value });
	}

	_onChange(event) {
		this.setState({ value: this.state.value ? false : true });
		if (this.props.updateFn !== undefined)
			this.props.updateFn(this.props.field, this.props.location, this.state.value ? "" : this.props.id);
	}

	render() {
		const primaryStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			width: this.state.value ? "8px" : "16px",
			height: this.state.value ? "8px" : "16px",
			border: this.state.value ? "5px solid rgb(70, 180, 175)" : "1px solid rgb(70, 180, 175)",
			borderRadius: "50%",
			cursor: "pointer",
			transition: "all .3s ease"
		};

		return <div id={this.props.id} style={m(primaryStyle, this)} onClick={this._onChange} />;
	}
}

export default Radio;
