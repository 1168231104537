import config from "../config.public";

var find_iterate = (myRoute, pages) => {
	const single = myRoute.splice(0, 1)[0];

	for (var i = 0; i < pages.length; i++) {
		if (pages[i].value === single) {
			if (myRoute.length === 0 || pages[i].pages === undefined) return pages[i];
			else return find_iterate(myRoute, pages[i].pages);
		}
	}

	return {};
};

var find = page => {
	var myRoute = page.split(" ");
	return find_iterate(myRoute, config.pages);
};

export { find };
