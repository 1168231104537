const config = {
    core: {
        name: "Hioperator",
        logo: "/images/simple_logo.png",
        primaryColor: "rgba(0, 0, 0, 0.87)",
        secondaryColor: "rgba(0, 0, 0, 0)",
        titleFont: "Roboto",
        appBarBackgroundColor: "white",
        appBarFontColor: "rgba(0, 0, 0, 0.87)",
    },
    registration: {
        allowFirebaseLogin: false,
        allowSignup: false,
        allowGoogle: true,
    },
    pages: [
        {
            text: "Clients",
            value: "clients",
            icon: "loyalty",
            iconColor: "#067ab8",
            roles: ["admin"],
            pagesRequireID: true,
            actions: [
                { type: "ClientActions", action: "loadSummary" },
                { type: "ClientActions", action: "close" },
            ],
            idActions: [{ type: "ClientActions", action: "load", value: "{{path[1]}}" }],
            pages: [
                {
                    text: "Overview",
                    value: "details",
                    icon: "details",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "AgentActions", action: "loadSummary" },
                    ],
                },
                {
                    text: "Permissions",
                    value: "permissions",
                    icon: "lock_open",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "agents",
                                {
                                    path: "permissions",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Search tickets",
                    value: "searchtickets",
                    icon: "search",
                    iconColor: "#067ab8",
                    badgeColor: "teal",
                    roles: ["admin"],
                    actions: [{ type: "ClientActions", action: "load", value: "{{path[1]}}" }],
                },
                {
                    text: "Active tickets",
                    value: "activetickets",
                    icon: "visibility",
                    iconColor: "#067ab8",
                    badgeColor: "orange",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "agents",
                                {
                                    path: "permissions",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "activetickets",
                                {
                                    path: "activeSessions",
                                    where: [["viewing.clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Queued tickets",
                    value: "queued",
                    icon: "work_outline",
                    iconColor: "#067ab8",
                    badgeColor: "teal",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "queued",
                                {
                                    path: "clients/{{path[1]}}/tickets",
                                    where: [
                                        ["isOpen", "==", true],
                                        ["deleted", "==", false],
                                        ["isEscalated", "==", false],
                                    ],
                                    orderBy: [["external_requester_updated", "asc"]],
                                    limit: 25,
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "activetickets",
                                {
                                    path: "activeSessions",
                                    where: [["viewing.clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Escalations",
                    value: "escalations",
                    icon: "error_outline",
                    iconColor: "#067ab8",
                    badgeColor: "indianred",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "escalations",
                                {
                                    path: "clients/{{path[1]}}/tickets",
                                    where: [
                                        ["deleted", "==", false],
                                        ["isEscalated", "==", true],
                                    ],
                                    orderBy: [["external_requester_updated", "asc"]],
                                    limit: 25,
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "activetickets",
                                {
                                    path: "activeSessions",
                                    where: [["viewing.clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "CSAT review",
                    value: "csat",
                    icon: "sentiment_satisfied_alt",
                    iconColor: "#067ab8",
                    badgeColor: "purple",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "csats",
                                {
                                    path: "clients/{{path[1]}}/tickets",
                                    where: [
                                        ["deleted", "==", false],
                                        ["CSAT_check", "==", "needed"],
                                    ],
                                    orderBy: [["external_requester_updated", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Events",
                    value: "events",
                    icon: "event_note",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "events",
                                {
                                    path: "events",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                    orderBy: [["start", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Sandbox ratings",
                    value: "sandbox",
                    icon: "markunread_mailbox",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "sandboxRatings",
                                {
                                    path: "sandboxRatings",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                    orderBy: [["date", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Flagged tickets",
                    value: "flagged",
                    icon: "outlined_flag",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "flaggedTickets",
                                {
                                    path: "flaggedTickets",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                    orderBy: [["date", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "All tickets",
                    value: "tickets",
                    icon: "line_weight",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "tickets",
                                {
                                    path: "clients/{{path[1]}}/tickets",
                                    where: [["deleted", "==", false]],
                                    orderBy: [["external_requester_updated", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "activetickets",
                                {
                                    path: "activeSessions",
                                    where: [["viewing.clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                    pagesRequireID: true,
                    idActions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "TicketActions", action: "load", value: { ticketId: "{{path[3]}}", clientId: "{{path[1]}}" } },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "components",
                                {
                                    path: "clients/{{path[1]}}/components",
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "agents",
                                {
                                    path: "permissions",
                                    where: [["clientId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "macros",
                                {
                                    path: "clients/{{path[1]}}/macros",
                                    where: [["active", "==", true]],
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "workflows",
                                {
                                    path: "clients/{{path[1]}}/workflows",
                                    where: [["active", "==", true]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Export",
                    value: "export",
                    icon: "import_export",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "exports",
                                {
                                    path: "clients/{{path[1]}}/exports",
                                    orderBy: [["created", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Analytics",
                    value: "analytics",
                    icon: "bar_chart",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [{ type: "ClientActions", action: "load", value: "{{path[1]}}" }],
                },
                {
                    text: "Documentation",
                    value: "documentation",
                    icon: "help",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "DocumentActions", action: "load" },
                    ],
                },
                {
                    text: "Triage Options",
                    value: "triageoptions",
                    icon: "work",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "TriageOptionsActions", action: "load" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "workflows",
                                {
                                    path: "clients/{{path[1]}}/workflows",
                                    where: [["active", "==", true]],
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "macros",
                                {
                                    path: "clients/{{path[1]}}/macros",
                                    where: [["active", "==", true]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Workflows",
                    value: "workflows",
                    icon: "call_split",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "WorkflowActions", action: "loadSummary" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "workflows",
                                {
                                    path: "clients/{{path[1]}}/workflows",
                                    orderBy: [["name", "asc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                    pagesRequireID: true,
                    idActions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "WorkflowActions", action: "load", value: "{{path[3]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "components",
                                {
                                    path: "clients/{{path[1]}}/components",
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Macros",
                    value: "macros",
                    icon: "email",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "macros",
                                {
                                    path: "clients/{{path[1]}}/macros",
                                    orderBy: [["title", "asc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                    pagesRequireID: true,
                    idActions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "MacroActions", action: "load", value: "{{path[3]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "components",
                                {
                                    path: "clients/{{path[1]}}/components",
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Products",
                    value: "products",
                    icon: "store",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    pagesRequireID: true,
                    idActions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        { type: "ProductActions", action: "load", value: "{{path[3]}}" },
                    ],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "products",
                                {
                                    path: "clients/{{path[1]}}/products",
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Settings",
                    value: "settings",
                    icon: "settings",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    actions: [
                        { type: "ClientActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "integrations",
                                {
                                    path: "clients/{{path[1]}}/integrations",
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "components",
                                {
                                    path: "clients/{{path[1]}}/components",
                                },
                            ],
                        },
                        {
                            type: "ClientActions",
                            action: "loadSubcollection",
                            value: [
                                "workflows",
                                {
                                    path: "clients/{{path[1]}}/workflows",
                                    where: [["active", "==", true]],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            text: "Gurus",
            value: "gurus",
            icon: "group",
            iconColor: "#067ab8",
            roles: ["admin"],
            globalPermissions: ["gurusPage"],
            pagesRequireID: true,
            actions: [
                { type: "AgentActions", action: "loadSummary" },
                { type: "AgentActions", action: "close" },
            ],
            idActions: [{ type: "AgentActions", action: "load", value: "{{path[1]}}" }],
            pages: [
                {
                    text: "Overview",
                    value: "details",
                    icon: "details",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [{ type: "AgentActions", action: "load", value: "{{path[1]}}" }],
                },
                {
                    text: "Permissions",
                    value: "permissions",
                    icon: "lock_open",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [
                        { type: "AgentActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "AgentActions",
                            action: "loadSubcollection",
                            value: [
                                "clients",
                                {
                                    path: "permissions",
                                    where: [["userId", "==", "{{path[1]}}"]],
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Events",
                    value: "events",
                    icon: "event",
                    iconColor: "#067ab8",
                    badgeColor: "teal",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [
                        { type: "AgentActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "AgentActions",
                            action: "loadSubcollection",
                            value: [
                                "events",
                                {
                                    path: "events",
                                    where: [["userId", "==", "{{path[1]}}"]],
                                    orderBy: [["start", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Sandbox ratings",
                    value: "sandbox",
                    icon: "markunread_mailbox",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [
                        { type: "AgentActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "AgentActions",
                            action: "loadSubcollection",
                            value: [
                                "sandboxRatings",
                                {
                                    path: "sandboxRatings",
                                    where: [["userId", "==", "{{path[1]}}"]],
                                    orderBy: [["date", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Flagged tickets",
                    value: "flagged",
                    icon: "outlined_flag",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [
                        { type: "AgentActions", action: "load", value: "{{path[1]}}" },
                        {
                            type: "AgentActions",
                            action: "loadSubcollection",
                            value: [
                                "flaggedTickets",
                                {
                                    path: "flaggedTickets",
                                    where: [["userId", "==", "{{path[1]}}"]],
                                    orderBy: [["date", "desc"]],
                                    limit: 25,
                                },
                            ],
                        },
                    ],
                },
                {
                    text: "Analytics",
                    value: "analytics",
                    icon: "bar_chart",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [{ type: "AgentActions", action: "load", value: "{{path[1]}}" }],
                },
                {
                    text: "Schedule",
                    value: "schedule",
                    icon: "calendar_today",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [{ type: "AgentActions", action: "load", value: "{{path[1]}}" }],
                },
                {
                    text: "Settings",
                    value: "settings",
                    icon: "settings",
                    iconColor: "#067ab8",
                    roles: ["admin"],
                    globalPermissions: ["gurusPage"],
                    actions: [{ type: "AgentActions", action: "load", value: "{{path[1]}}" }],
                },
            ],
        },
        {
            text: "Ticket Triage",
            value: "tickettriage",
            icon: "work",
            iconColor: "#067ab8",
            roles: ["admin"],
            actions: [
                { type: "ClientActions", action: "loadSummary" },
                { type: "ClientActions", action: "close" },
                { type: "TicketActions", action: "close" },
            ],
        },
        {
            text: "Ticket Workflows",
            value: "ticketworkflows",
            icon: "done",
            iconColor: "#067ab8",
            roles: ["admin"],
            pagesRequireID: true,
            actions: [
                { type: "ClientActions", action: "loadSummary" },
                { type: "ClientActions", action: "close" },
                { type: "TicketActions", action: "close" },
                { type: "WorkflowActions", action: "close" },
            ],
            idActions: [
                { type: "ClientActions", action: "close" },
                { type: "TicketActions", action: "close" },
                { type: "WorkflowActions", action: "close" },
                { type: "TicketActions", action: "getTicket", value: ["workflows", null, "{{path[1]}}"] },
            ],
        },
        {
            text: "Ticketless Workflows",
            value: "ticketlessworkflows",
            icon: "phone",
            iconColor: "#067ab8",
            roles: ["admin"],
            pagesRequireID: true,
            actions: [
                { type: "ClientActions", action: "loadSummary" },
                { type: "ClientActions", action: "close" },
                { type: "TicketActions", action: "close" },
                { type: "WorkflowActions", action: "close" },
            ],
        },
        {
            text: "Active Tickets",
            value: "activetickets",
            icon: "remove_red_eye",
            iconColor: "#067ab8",
            roles: ["admin"],
            globalPermissions: ["activetickets"],
            actions: [
                { type: "ClientActions", action: "loadSummary" },
                { type: "TicketActions", action: "loadActive" },
            ],
        },
        {
            text: "Schedule",
            value: "schedule",
            icon: "calendar_today",
            iconColor: "#067ab8",
            roles: ["admin"],
            globalPermissions: ["schedulePage"],
            actions: [{ type: "AgentActions", action: "loadSummary" }],
        },
    ],
    userMenu: [
        {
            text: "Settings",
            value: "settings",
        },
        {
            text: "Clock Out",
            value: "clockout",
        },
        {
            text: "Logout",
            value: "logout",
        },
    ],
    gcf: {
        prod: {
            apiKey: "AIzaSyBdSfr9zEippJ8294JXaC6XqOmgTMUMNUQ",
            authDomain: "app.hioperator.com",
            databaseURL: "https://hioperator-prod.firebaseio.com",
            projectId: "hioperator-prod",
            storageBucket: "hioperator-prod.appspot.com",
            messagingSenderId: "329936486573",
            appId: "1:329936486573:web:36a516b8b87ece14",
        },
        dev: {
            apiKey: "AIzaSyAINJ2cSaSN1KWOicR8V5-rwORjGY9Urao",
            authDomain: "hioperator-dev.firebaseapp.com",
            databaseURL: "https://hioperator-dev.firebaseio.com",
            projectId: "hioperator-dev",
            storageBucket: "hioperator-dev.appspot.com",
            messagingSenderId: "720080792237",
        },
        staging: {
            apiKey: "AIzaSyD0VQ6-IKIy3R4YZmvg8X2rcRUArB7XTjY",
            authDomain: "hioperator-staging.firebaseapp.com",
            databaseURL: "https://hioperator-staging.firebaseio.com",
            projectId: "hioperator-staging",
            storageBucket: "hioperator-staging.appspot.com",
            messagingSenderId: "686732170043",
        },
    },
    permissions: [
        {
            text: "Full Access",
            page: "clients",
            items: [
                {
                    icon: "done_all",
                    text: "Full access (can do anything)",
                    value: "full_access",
                },
            ],
        },
        {
            text: "Tickets",
            items: [
                {
                    icon: "done",
                    text: "Workflows",
                    value: "workflows",
                    page: "ticketworkflows",
                },
                {
                    icon: "phone",
                    text: "Ticketless Workflows",
                    value: "ticketlessworkflows",
                    page: "ticketlessworkflows",
                },
                {
                    icon: "work",
                    text: "Triage",
                    value: "triage",
                    page: "tickettriage",
                },
                {
                    icon: "accessibility_new",
                    text: "Sandbox",
                    value: "sandbox",
                },
            ],
        },
        {
            text: "Client Page",
            page: "clients",
            items: [
                {
                    icon: "details",
                    text: "Overview",
                    value: "clients_details",
                },
                {
                    icon: "lock_open",
                    text: "Permissions",
                    value: "clients_permissions",
                },
                {
                    icon: "search",
                    text: "Search tickets",
                    value: "clients_searchtickets",
                },
                {
                    icon: "visibility",
                    text: "Active tickets",
                    value: "clients_activetickets",
                },
                {
                    icon: "work_outline",
                    text: "Queue",
                    value: "clients_queued",
                },
                {
                    icon: "error_outline",
                    text: "Escalations",
                    value: "clients_escalations",
                },
                {
                    icon: "sentiment_satisfied_alt",
                    text: "CSAT review",
                    value: "clients_csat",
                },
                {
                    icon: "event_note",
                    text: "Events",
                    value: "clients_events",
                },
                {
                    icon: "markunread_mailbox",
                    text: "Sandbox Ratings",
                    value: "clients_sandbox",
                },
                {
                    icon: "outlined_flag",
                    text: "Flagged tickets",
                    value: "clients_flagged",
                },
                {
                    icon: "line_weight",
                    text: "All tickets",
                    value: "clients_tickets",
                },
                {
                    icon: "import_export",
                    text: "Export",
                    value: "clients_export",
                },
                {
                    icon: "bar_chart",
                    text: "Analytics",
                    value: "clients_analytics",
                },
                {
                    icon: "help",
                    text: "Documentation",
                    value: "clients_documentation",
                },
                {
                    icon: "work",
                    text: "Triage Options",
                    value: "clients_triageoptions",
                },
                {
                    icon: "call_split",
                    text: "Workflows",
                    value: "clients_workflows",
                },
                {
                    icon: "email",
                    text: "Macros",
                    value: "clients_macros",
                },
                {
                    icon: "store",
                    text: "Products",
                    value: "clients_products",
                },
                {
                    icon: "settings",
                    text: "Settings",
                    value: "clients_settings",
                },
            ],
        },
    ],
};

export default config;
