import React from "react";

// Actions
import TriageOptionsActions from "../../actions/TriageOptionsActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Button from "../_common/buttons/Button.react";
import SectionSelector from "../_common/SectionSelector.react";
import UIActions from "../../actions/UIActions";

// Functions
import * as objects from "../../functions/objects";

const integrationsStyle = {
    width: "100%",
    textAlign: "left",
    margin: "24px 0px",
    display: "inline-block"
};

const buttonRowStyle = {
    width: "100%",
    marginTop: "32px",
    textAlign: "left"
};

const emptyStyle = {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "24px 0px",
    width: "calc(100% - 48px - 32px)",
    padding: "24px",
    border: "1px dashed lightgrey",
    borderRadius: "4px"
};

const emptyTextStyle = {
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "20px"
};

class TriageOptions extends React.Component {
    constructor(props) {
        super(props);
        this._onAddTriageOption = this._onAddTriageOption.bind(this);
        this._onSelect = this._onSelect.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    _onAddTriageOption() {
        var newObj = { text: "", actions: [], options: [] };
        TriageOptionsActions.add("options", [], newObj);

        UIActions.showOverlay("triageoption editor", { target: newObj, location: [] });
    }

    _onSelect(field, location, value) {
        var obj = (this.props.item.options || []).find(item => item.id === value);
        if (obj) UIActions.showOverlay("triageoption editor", { target: obj, location: [] });
    }

    render() {
        var options = this.props.item.options || [];
        return (
            <OuterScaffold spaceForHeader={false} verticalAlign={false}>
                <Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
                    <SectionCard title="TRIAGE OPTIONS">
                        {options.length === 0 ? (
                            <div key="empty-workflows" style={emptyStyle}>
                                <div style={emptyTextStyle}>There are currently no triage options</div>
                                <Button text="Add Option" onClick={this._onAddTriageOption} />
                            </div>
                        ) : (
                            <div style={integrationsStyle}>
                                {options.map((item, index) => (
                                    <SectionSelector
                                        id={item.id}
                                        key={item.id}
                                        text={item.text}
                                        value={item.id}
                                        index={index}
                                        location={[]}
                                        onClick={this._onSelect}
                                    />
                                ))}
                                <div style={buttonRowStyle}>
                                    <Button text="Add Option" onClick={this._onAddTriageOption} />
                                </div>
                            </div>
                        )}
                    </SectionCard>
                </Container>
            </OuterScaffold>
        );
    }
}

export default TriageOptions;
