import Reflux from "reflux";
import async from "async";

import AgentActions from "../actions/AgentActions";
import UIActions from "../actions/UIActions";
import ClientActions from "../actions/ClientActions";

import firebase from "firebase/app";
import "firebase/firestore";

// Functions
import api from "../functions/api";
import * as status from "../functions/status";
import setField from "../functions/set_field";
import * as objects from "../functions/objects";
import * as firestore from "../functions/firestore";
import * as storage from "../functions/storage";
import * as list from "../functions/list";
import * as time from "../functions/time";
import * as search from "../functions/search";
import * as viewing from "../functions/viewing";

// Defines db for firestore
var db = firebase.firestore();

var Agent = {
    summary: [],
    summaryIDs: [],
    extraLoaded: [],
    status: [],
    errors: [],
    allLoaded: false,
    loadedParts: [],
    workingUpdate: {}
};

// Handles errors
function add_error(error) {
    if (!Array.isArray(Agent.errors)) Agent.errors = [];

    error = String(error).substr(String(error).indexOf(":") + 1, 10000);

    if (error.indexOf("Sorry, another user already has this") > -1) {
        var field = error.substr(error.indexOf("this") + 5, 1000);
        Agent.errors.push({ id: field, text: error });
    }
}

/////////////////////////////////////////////////////////////
// Functions for live updating
/////////////////////////////////////////////////////////////

function setup_live_updaters_summary_analytics(id) {
    if (Agent.summaryIDs.indexOf(id) === -1) {
        Agent.summaryIDs.push(id);
        var myDate = new Date();
        myDate.setDate(myDate.getDate() - 40);
        db.collection("users")
            .doc(id)
            .collection("analytics")
            .where("month", ">", myDate)
            .orderBy("month", "desc")
            .limit(1)
            .onSnapshot(function(snapshot) {
                var docs = firestore.extract(snapshot);
                var today = new Date();
                var split = today.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }).split("/");
                var myDay = split[1];

                if (docs.length > 0) {
                    docs = docs[0];
                    for (var i = 0; i < Agent.summary.length; i++) {
                        if (Agent.summary[i].id === id) {
                            Agent.summary[i].analytics = docs;
                            Agent.summary[i].eventsN = docs.days[myDay] !== undefined ? docs.days[myDay].n || 0 : 0;
                            break;
                        }
                    }
                }

                console.log("FIRE UPDATER");
                AgentActions.update();
            });
    }
}

const subcollections = ["analytics", "clients", "events", "sessions", "sandboxRatings"];

function setup_live_updaters() {
    Agent.workingUpdate = [];

    // Sets up monitoring
    Agent.workingUpdate.push(
        db
            .collection("users")
            .doc(Agent.working.id)
            .onSnapshot(function(snapshot) {
                var newObj = firestore.extract_single(snapshot);
                for (var i = 0; i < subcollections.length; i++) {
                    newObj[subcollections[i]] = Agent.working[subcollections[i]];
                }

                Agent.working = newObj;
                AgentActions.update();
            })
    );

    // Sets up subcollection monitoring
    //for (var j = 0; j < subcollections.length; j++) setup_live_updates_subcollections(subcollections[j]);
}

function setup_live_updates_subcollections(subcollection) {
    var myDate = new Date();
    myDate.setDate(myDate.getDate() - 90);

    if (subcollection === "clients") {
        Agent.workingUpdate.push(
            db
                .collection("permissions")
                .where("userId", "==", Agent.working.id)
                .onSnapshot(function(snapshot) {
                    var permissions = firestore.extract(snapshot);
                    Agent.working.clients = [];

                    for (var i = 0; i < permissions.length; i++) get_client_for_permissions(permissions[i], permissions.length);

                    if (permissions.length === 0) AgentActions.update();
                })
        );
    } else if (subcollection === "sessions") {
        Agent.workingUpdate.push(
            db
                .collection("users")
                .doc(Agent.working.id)
                .collection(subcollection)
                .where("global", "==", true)
                .where("start", ">", myDate)
                .onSnapshot(function(snapshot) {
                    if (Agent.working !== undefined) Agent.working[subcollection] = parse_sessions(firestore.extract(snapshot));
                    if (Agent.working !== undefined) Agent.working.events = parse_events(Agent.working[subcollection]);

                    check_all_loaded(subcollection);
                    if (Agent.allLoaded) AgentActions.update();
                })
        );
    } else if (subcollection === "analytics") {
        Agent.workingUpdate.push(
            db
                .collection("users")
                .doc(Agent.working.id)
                .collection(subcollection)
                .where("month", ">", myDate)
                .onSnapshot(function(snapshot) {
                    if (Agent.working !== undefined) Agent.working[subcollection] = firestore.extract(snapshot);

                    check_all_loaded(subcollection);
                    if (Agent.allLoaded) AgentActions.update();
                })
        );
    } else {
        Agent.workingUpdate.push(
            db
                .collection("users")
                .doc(Agent.working.id)
                .collection(subcollection)
                .onSnapshot(function(snapshot) {
                    if (Agent.working !== undefined) Agent.working[subcollection] = firestore.extract(snapshot);

                    check_all_loaded(subcollection);
                    if (Agent.allLoaded) AgentActions.update();
                })
        );
    }
}

var get_client_for_permissions = (permission, total) => {
    ClientActions.getSingle(permission.clientId, function(Client) {
        if ([null, undefined, ""].indexOf(Client) === -1 && Client.id !== undefined) {
            Client.permissions = permission;
            Agent.working.clients.push(Client);
        }

        if (Agent.working.clients.length === total) {
            AgentActions.update();
        }
    });
};

function check_all_loaded(subcollection) {
    if (Agent.loadedParts.indexOf(subcollection) === -1) Agent.loadedParts.push(subcollection);
    if (Agent.loadedParts.length === subcollections.length) Agent.allLoaded = true;
}

function parse_sessions(docs) {
    if (docs === undefined || docs.length === 0) return [];
    else {
        var i;
        for (i = 0; i < docs.length; i++) {
            if (docs[i].end !== undefined) docs[i].duration = docs[i].end.seconds - docs[i].start.seconds;
            else docs[i].duration = 0;
            docs[i].date = time.parse_time(docs[i].start);
            docs[i].timestamp = time.parse_time(docs[i].start, true);
        }

        // Groups by day
        var byDate = list.group(docs, "date");
        for (i = 0; i < byDate.length; i++) {
            byDate[i].timestamp = new Date(byDate.date);
            byDate[i].duration = list.sum(byDate[i].data, "duration");
            byDate[i].data = list.order(byDate[i].data, "start newest");
        }

        byDate = list.order(byDate, "date newest");

        return byDate;
    }
}

var setup_working_subcollection = (type, query, pageChange) => {
    console.log("UPDATING " + type);
    if (Agent.workingUpdate[type] !== undefined && Agent.workingUpdate[type].updater !== undefined) Agent.workingUpdate[type].updater();

    var pointers = Agent.workingUpdate[type] !== undefined && query.limit !== undefined ? Agent.workingUpdate[type].pointers || [] : [];

    // Sets page;
    var page = Agent.workingUpdate[type] !== undefined ? Agent.workingUpdate[type].page || 0 : 0;

    // Sets cursor
    Agent.workingUpdate[type] = { query, pointers, type, page };

    // Updates page
    Agent.workingUpdate[type].page =
        pageChange !== undefined ? (Agent.workingUpdate[type].page || 0) + pageChange : Agent.workingUpdate[type].page || 0;

    var ref = firestore.query_builder(query);

    var i;
    Agent.workingUpdate[type].updater = ref.onSnapshot(function(snapshot) {
        if (Agent.working === undefined) {
            Agent.workingUpdate[type].updater();
            return;
        }

        if (type === "clients") {
            var permissions = firestore.extract(snapshot);
            Agent.working.clients = [];

            for (var i = 0; i < permissions.length; i++) get_client_for_permissions(permissions[i], permissions.length);
        } else if (type === "sessions") {
            Agent.working[type] = parse_sessions(firestore.extract(snapshot));
            Agent.working.events = parse_events(Agent.working[type]);
        } else {
            Agent.working[type] = firestore.extract(snapshot);
        }

        if (type === "flaggedTickets") ensure_clients("flaggedTickets");
        if (type === "events") ensure_clients("events");
        if (type === "sandboxRatings") ensure_clients("sandboxRatings");

        if (query.limit !== undefined) {
            Agent.workingUpdate[type].pointers[Agent.workingUpdate[type].page] = snapshot.docs[snapshot.docs.length - 1];
        }

        AgentActions.update();
    });
};

function ensure_clients(field) {
    if (Agent.working[field] !== undefined) {
        async.waterfall(
            [
                function(callback) {
                    viewing.ensure_clients(Agent.working[field], [], "clientId", "client", callback);
                }
            ],
            function(err, results) {
                Agent.working[field] = results;
                AgentActions.update();
            }
        );
    }
}

function setup_working_subcollection_OG(type, pageChange, orderBy) {
    // Figures out page
    var page = Agent.cursor !== undefined ? Agent.cursor.page || 0 : 0;
    if (pageChange === undefined && orderBy === undefined) page = 0;
    else if (!isNaN(pageChange)) page += pageChange;

    // Sets initial
    if (Agent.cursor === undefined || (page === 0 && orderBy === undefined))
        Agent.cursor = { order: "start", direction: "desc", pointers: [] };

    Agent.cursor.page = page;

    if (orderBy !== undefined) {
        Agent.cursor.page = 0;
        page = 0;

        if (orderBy === Agent.cursor.order) Agent.cursor.direction = Agent.cursor.direction === "desc" ? "asc" : "desc";
        else {
            Agent.cursor.order = orderBy;
            Agent.cursor.direction = ["external_updated", "external_id"].indexOf(orderBy) > -1 ? "desc" : "asc";
        }
    }

    const myRoute = document.location.hash.replace("#", "").split("/");
    const agentId = myRoute[1];

    var ref;

    if (type === "sessions") {
        ref = db
            .collection("users")
            .doc(agentId)
            .collection("sessions")
            .where("global", "==", true)
            .orderBy(Agent.cursor.order, Agent.cursor.direction);
    } else if (type === "events") {
        ref = db
            .collection("events")
            .where("userId", "==", agentId)
            .orderBy(Agent.cursor.order, Agent.cursor.direction);
    }

    if (page > 0 && Agent.cursor !== undefined && Agent.cursor.pointers !== undefined && Agent.cursor.pointers[page - 1] !== undefined)
        ref = ref.startAfter(Agent.cursor.pointers[page - 1]);

    ref = ref.limit(25);

    Agent.subcollectionUpdate = ref.onSnapshot(function(snapshot) {
        Agent.workingSubcollection = firestore.extract(snapshot);

        if (page === 0) Agent.cursor.pointers = [snapshot.docs[snapshot.docs.length - 1]];
        else Agent.cursor.pointers.push(snapshot.docs[snapshot.docs.length - 1]);

        AgentActions.update();
    });
}

function parse_events(docs) {
    var clients = Object.keys(Agent.working.clients);
    var day, i, j, myKeys, k, temp, dayTemp;
    var events = [];

    // Extracts clients
    for (i = 0; i < docs.length; i++) {
        day = docs[i];
        dayTemp = [];
        for (j = 0; j < day.data.length; j++) {
            myKeys = Object.keys(day.data[j]);
            for (k = 0; k < myKeys.length; k++) {
                if (clients.indexOf(myKeys[k]) > -1) {
                    day.data[j][myKeys[k]].clientId = myKeys[k];
                    temp = strip_out_events(day.data[j][myKeys[k]]);
                    dayTemp = dayTemp.concat(temp);
                }
            }
        }

        day.eventsN = dayTemp.length;
        day.eventsTime = list.sum(dayTemp, "duration");

        // Breaks down by client
        day.clients = list.group(dayTemp, "clientId");

        for (j = 0; j < day.clients.length; j++) {
            day.clients[j].n = day.clients[j].data.length;
            day.clients[j].duration = list.sum(day.clients[j].data, "duration");
            temp = 0;
            for (k = 0; k < day.clients[j].data.length; k++) {
                if (["escalate", "escalate external"].indexOf(day.clients[j].data[k].action) > -1) temp++;
            }

            day.clients[j].escalations = temp;

            delete day.clients[j].data;
        }

        events = events.concat(dayTemp);
    }

    return events;
}

function strip_out_events(data) {
    var myKeys = Object.keys(data);
    var temp = [];
    var i, j;

    for (j = 0; j < myKeys.length; j++) {
        if (myKeys[j] !== "clientId") {
            data[myKeys[j]].clientId = data.clientId;
            data[myKeys[j]].ticketId = myKeys[j];
            temp.push(data[myKeys[j]]);
        }
    }

    var events = [];
    for (i = 0; i < temp.length; i++) {
        myKeys = Object.keys(temp[i]);
        for (j = 0; j < myKeys.length; j++) {
            if (["clientId", "ticketId"].indexOf(myKeys[j]) === -1) {
                temp[i][myKeys[j]].clientId = temp[i].clientId;
                temp[i][myKeys[j]].ticketId = temp[i].ticketId;
                events.push(temp[i][myKeys[j]]);
            }
        }
    }

    return events;
}

/////////////////////////////////////////////////////////////
// Functions for analytics
/////////////////////////////////////////////////////////////

var parse_analytics = data => {
    // Totals
    const totals = {
        submitted: data.totalSubmits[0].Submits,
        submittedDuration: data.totalSubmits[0].duration / (60 * 1000),
        escalated: data.totalEscalations[0].Submits,
        escalatedDuration: data.totalEscalations[0].duration / (60 * 1000),
        submittedPerUnit: data.totalSubmits[0].Submits / data.submitsByDate.length,
        addWorkflows: data.totalAddWorkflows[0].Submits,
        addWorkflowsDuration: data.totalAddWorkflows[0].duration / (60 * 1000),
        removeWorkflows: data.totalRemoveWorkflows[0].Submits,
        removeWorkflowsDuration: data.totalRemoveWorkflows[0].duration / (60 * 1000)
    };

    var temp, timeChart, timeDurationChart, i, j;

    if (data.type === "24h") {
        // Time Chart
        timeChart = [["Hour", "Solved", "Escalated"]];
        timeDurationChart = [["Hour", "Solved Average Duration", "Escalated Average Duration"]];
        var timeArray = [];
        var now = new Date();
        now = now.setDate(now.getDate() - 1);
        now = new Date(now);

        for (i = 0; i < 25; i++) {
            timeChart.push([now.getHours() + ":00", 0, 0]);
            timeDurationChart.push([now.getHours() + ":00", 0, 0]);
            timeArray.push(
                now.getFullYear() +
                    "-" +
                    (now.getUTCMonth() < 9 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1) +
                    "-" +
                    (now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate()) +
                    " " +
                    (now.getUTCHours() < 10 ? "0" + now.getUTCHours() : now.getUTCHours())
            );

            now = now.setHours(now.getHours() + 1);
            now = new Date(now);
        }

        for (i = 0; i < data.submitsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][1] = data.submitsByDate[i].Submits;
            timeDurationChart[timeArray.indexOf(data.submitsByDate[i].ForDate) + 1][1] = data.submitsByDate[i].duration / (60 * 1000);
        }

        for (i = 0; i < data.escalationsByDate.length; i++) {
            timeChart[timeArray.indexOf(data.escalationsByDate[i].ForDate) + 1][2] = data.escalationsByDate[i].Submits;
            timeDurationChart[timeArray.indexOf(data.escalationsByDate[i].ForDate) + 1][2] =
                data.escalationsByDate[i].duration / (60 * 1000);
        }
    } else {
        // Time Chart
        timeChart = [["Day", "Solved", "Escalated"]];
        timeDurationChart = [["Day", "Solved Average Duration", "Escalated Average Duration"]];

        var tempDuration, day, simpleDay;
        for (i = 0; i < data.submitsByDate.length; i++) {
            temp = [];
            tempDuration = [];
            day = data.submitsByDate[i].ForDate;
            simpleDay = new Date(day);

            temp = [simpleDay.toLocaleDateString(), data.submitsByDate[i].Submits, 0];
            tempDuration = [simpleDay.toLocaleDateString(), data.submitsByDate[i].duration / (60 * 1000), 0];

            for (j = 0; j < data.escalationsByDate.length; j++) {
                if (data.escalationsByDate[j].ForDate === day) {
                    temp[2] = data.escalationsByDate[j].Submits;
                    tempDuration[2] = data.escalationsByDate[j].duration / (60 * 1000);
                    break;
                }
            }

            timeChart.push(temp);
            timeDurationChart.push(tempDuration);
        }
    }

    // HiOperator vesus not chart
    var submitsVersusEscalations = [
        ["Type", "Total"],
        ["Submitted", data.totalSubmits[0].Submits],
        ["Escalated", data.totalEscalations[0].Submits]
    ];

    // HiOperator splits
    var eventSplit = [
        ["Type", "Total time"],
        ["Submitted", data.totalSubmits[0].Submits],
        ["Escalated", data.totalEscalations[0].Submits],
        ["Add Workflows", data.totalAddWorkflows[0].Submits],
        ["Remove Workflows", data.totalRemoveWorkflows[0].Submits]
    ];

    var timeSplit = [
        ["Type", "Total time"],
        ["Submitted", (data.totalSubmits[0].Submits * data.totalSubmits[0].duration) / (60 * 1000 * 60)],
        ["Escalated", (data.totalEscalations[0].Submits * data.totalEscalations[0].duration) / (60 * 1000 * 60)],
        ["Add Workflows", (data.totalAddWorkflows[0].Submits * data.totalAddWorkflows[0].duration) / (60 * 1000 * 60)],
        ["Remove Workflows", (data.totalRemoveWorkflows[0].Submits * data.totalRemoveWorkflows[0].duration) / (60 * 1000 * 60)]
    ];

    // Agent Chart
    var orderedClients = list.order(data.submitByClient, "Submits biggest");
    var submitByClient = [["Client", "Tickets Submitted"]];
    for (i = 0; i < orderedClients.length; i++) {
        submitByClient.push([orderedClients[i].name, orderedClients[i].Submits]);
    }

    orderedClients = list.order(orderedClients, "duration smallest");

    var timeByClient = [["Client", "Average Duration"]];
    for (i = 0; i < orderedClients.length; i++) {
        timeByClient.push([orderedClients[i].name, orderedClients[i].duration / (60 * 1000)]);
    }

    Agent.working.customAnalytics = {
        totals,
        timeChart,
        timeDurationChart,
        submitsVersusEscalations,
        submitByClient,
        timeByClient,
        timeSplit,
        eventSplit
    };

    AgentActions.update();
};

/////////////////////////////////////////////////////////////
// Functions for editing agent details
/////////////////////////////////////////////////////////////

function check_field_auth(field, value, notify) {
    const statusCode = "checking field validity";
    if (!status.has(Agent, statusCode)) {
        const payload = {
            field,
            value
        };

        api(
            "post",
            "/agents/" + Agent.working.id + "/fieldcheck",
            payload,
            AgentActions.fieldCheckCompleted,
            AgentActions.fieldCheckFailed,
            Agent,
            statusCode,
            notify
        );
    }
}

class AgentStore extends Reflux.Store {
    constructor() {
        super();
        this.notify = this.notify.bind(this);
        this.state = { AgentStore: Agent };
        this.listenToMany(AgentActions);
    }

    onLoadSummary() {
        if (Agent.summaryUpdate === undefined) {
            Agent.summaryUpdate = db.collection("users").onSnapshot(function(snapshot) {
                var docs = firestore.extract(snapshot);

                //for (var i = 0; i < docs.length; i++) setup_live_updaters_summary_analytics(docs[i].id);
                AgentActions.loadSummaryCompleted(docs);
            });
        }
    }

    onLoadSummaryCompleted(result) {
        var j, add;
        for (var i = 0; i < result.length; i++) {
            add = true;
            for (j = 0; j < Agent.summary.length; j++) {
                if (result[i].id === Agent.summary[j].id) {
                    result[i].analytics = Agent.summary[j].analytics;
                    result[i].eventsN = Agent.summary[j].eventsN;
                    Agent.summary[j] = result[i];
                    add = false;
                }
            }

            if (add) {
                result[i].eventsN = 0;
                Agent.summary.push(result[i]);
            }
        }

        Agent.summary = result;
        this.notify();
    }

    onLoadSummaryFailed(result) {
        this.notify();
    }

    onLoadTimechart() {
        var end = new Date();
        var start = new Date();
        start = start.setDate(start.getDate() - 1);

        const statusCode = "getting timechart";
        if (Agent.working !== undefined) {
            if (!status.has(Agent, statusCode))
                api(
                    "get",
                    "/agents/" + Agent.working.id + "/timechart/" + Number(start) + "/" + Number(end),
                    null,
                    AgentActions.loadTimechartCompleted,
                    AgentActions.error,
                    Agent,
                    statusCode
                );
        } else
            setTimeout(function() {
                AgentActions.loadTimechart();
            }, 1000);
    }

    onLoadTimechartCompleted(data) {
        var newData = [["Time", "Active"]];
        var temp;
        var value;
        for (var i = 0; i < data.length; i++) {
            temp = new Date(data[i].created);
            value = ["null", null, undefined].indexOf(data[i].ticketId) === -1 ? 2 : 1;
            newData.push([
                new Date(Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate(), temp.getHours(), temp.getMinutes())),
                value
            ]);
        }

        Agent.timeChart = newData;
        this.notify();
    }

    onNew(data) {
        data.role = "agent";
        const statusCode = "creating agent";
        if (!status.has(Agent, statusCode))
            api("post", "/agents/new", data, AgentActions.newCompleted, AgentActions.loadFailed, Agent, statusCode);
    }

    onNewCompleted(data) {
        UIActions.showOverlay("");
        UIActions.showRouter("agents/" + data.id);
    }

    onLoad(id, blockView) {
        if (Agent.working === undefined || id !== Agent.working.id) {
            AgentActions.unsubscribe();
            Agent.loadedParts = [];
            Agent.allLoaded = false;

            db.collection("users")
                .doc(id)
                .get()
                .then(function(snapshot) {
                    if (snapshot.id !== "undefined") AgentActions.loadCompleted(firestore.extract_single(snapshot), blockView);
                    else AgentActions.loadFailed("Couldn't find user");
                });

            if (!blockView && Agent.working !== undefined && Agent.working.id === id) {
                UIActions.showOverlay("");
                this.notify();
            }

            this.notify();
        }
    }

    onLoadCompleted(result, blockView) {
        Agent.working = result;
        Agent.working.clients = [];

        if (!blockView) {
            UIActions.showOverlay("");
        }

        if (Agent.workingUpdate === undefined) setup_live_updaters();

        this.notify();
    }

    onLoadFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onLoadSubcollection(type, query, attempt, pageChange) {
        if (Agent.working === undefined && (isNaN(attempt) || attempt < 10)) {
            setTimeout(function() {
                attempt = attempt || 0;
                AgentActions.loadSubcollection(type, query, ++attempt);
            }, 100 * attempt);
        } else if (
            Agent.working !== undefined &&
            (Agent.workingUpdate[type] === undefined ||
                !firestore.query_compare(Agent.workingUpdate[type].query, query) ||
                pageChange !== undefined)
        ) {
            setup_working_subcollection(type, query, pageChange);
        }
    }

    onSave() {
        var temp = objects.clone(Agent.working);
        temp.updated = new Date();
        for (var i = 0; i < subcollections.length; i++) delete temp[subcollections[i]];

        delete temp.events;
        delete temp.clients;

        db.collection("users")
            .doc(Agent.working.id)
            .set(temp, { merge: true })
            .then(AgentActions.saveCompleted)
            .catch(AgentActions.saveFailed);

        search.update("users", {
            id: Agent.working.id,
            name: Agent.working.name || "",
            role: Agent.working.role || "",
            picture: Agent.working.picture || "",
            email: Agent.working.email || "",
            active: Agent.working.active || false
        });
    }

    onSaveCompleted(result) {
        UIActions.addMessage("Agent saved!", "success");
        this.notify();
    }

    onSaveFailed(result) {
        UIActions.addMessage("Couldn't save agents :(.", "error");
        this.notify();
    }

    onDelete() {
        AgentActions.unsubscribe();

        const statusCode = "deleting agent";
        if (!status.has(Agent, statusCode)) {
            api(
                "delete",
                "/agents/" + Agent.working.id,
                {},
                AgentActions.deleteCompleted,
                AgentActions.deleteFailed,
                Agent,
                statusCode,
                this.notify
            );
        }

        UIActions.showRouter("agents");
    }

    onDeleteCompleted(result) {
        UIActions.addMessage("Agent deleted!", "success");
        this.notify();
    }

    onDeleteFailed(result) {
        UIActions.addMessage("Couldn't delete agents :(.", "error");
        this.notify();
    }

    onGetSingle(id, callback) {
        var i;
        for (i = 0; i < Agent.summary.length; i++) {
            if (Agent.summary[i].id === id) {
                callback(Agent.summary[i]);
                return;
            }
        }

        if (Agent.extraLoaded === undefined) Agent.extraLoaded = [];

        for (i = 0; i < Agent.extraLoaded.length; i++) {
            if (Agent.extraLoaded[i] !== undefined && Agent.extraLoaded[i].id === id) {
                callback(Agent.extraLoaded[i]);
                return;
            }
        }

        db.collection("users")
            .doc(id)
            .get()
            .then(snapshot => {
                const single = firestore.extract_single(snapshot);
                Agent.extraLoaded.push(single);
                callback(single);
                return;
            })
            .catch(AgentActions.error);
    }

    onAdd(field, location, obj) {
        objects.add(Agent.working, field, location, obj);
        this.notify();
    }

    onRemove(location) {
        objects.remove(Agent.working, location.concat());
        this.notify();
    }

    onSetField(field, location, value, shouldNotify) {
        var notify;
        if (shouldNotify) notify = this.notify;

        if (field === "email" && Agent.working[field] !== value) check_field_auth(field, value, notify);
        if (field === "phoneNumber" && Agent.working[field] !== value) check_field_auth(field, value, notify);

        setField(Agent.working, field, location, value);

        if (shouldNotify) notify();
    }

    onUpload(files, type) {
        storage.upload(files, "images", type, [], AgentActions.uploadCompleted);
    }

    onUploadCompleted(result, type) {
        if (result[0] !== undefined) Agent.working[type] = result[0].url;

        this.notify();
    }

    onUploadFailed() {
        UIActions.addMessage("Couldn't upload image :(. Try again.", "error");
        this.notify();
    }

    onToggleActive(field, location, value, shouldNotify) {
        Agent.working.active = value;
        const statusCode = "toggling active for agent";
        if (!status.has(Agent, statusCode))
            api(
                "put",
                "/agents/" + Agent.working.id + "/" + (value ? "activate" : "deactivate"),
                null,
                AgentActions.toggleActiveCompleted,
                AgentActions.toggleActiveFailed,
                Agent,
                statusCode,
                this.notify
            );
    }

    onToggleActiveCompleted(result) {
        UIActions.addMessage("Agent " + result.status, "success");
        this.notify();
    }

    onToggleActiveFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onFieldCheckCompleted(result) {
        this.notify();
    }

    onFieldCheckFailed(error) {
        add_error(error);
        this.notify();
    }

    onRoleChange(field, location, value) {
        setField(Agent.working, field, location, value);
        const statusCode = "changing role";
        if (!status.has(Agent, statusCode))
            api(
                "put",
                "/agents/" + Agent.working.id + "/" + Agent.working.uid + "/role/" + Agent.working.role,
                null,
                AgentActions.roleChangeCompleted,
                AgentActions.roleChangeFailed,
                Agent,
                statusCode,
                this.notify
            );
    }

    onPhoneChange(field, location, value) {
        const statusCode = "changing phone number";
        if (!status.has(Agent, statusCode))
            api(
                "put",
                "/agents/" + Agent.working.id + "/" + Agent.working.uid + "/phone/" + value,
                null,
                AgentActions.update,
                AgentActions.roleChangeFailed,
                Agent,
                statusCode,
                this.notify
            );
    }

    onRoleChangeCompleted(result) {
        UIActions.addMessage("Role successfully updated!", "success");
        this.notify();
    }

    onRoleChangeFailed(error) {
        UIActions.addMessage(error, "error");
        this.notify();
    }

    onAddClient(obj) {
        db.collection("permissions")
            .where("clientId", "==", obj.clientId)
            .where("userId", "==", obj.userId)
            .get()
            .then(snapshot => {
                const results = firestore.extract(snapshot);
                if (results.length === 0) {
                    db.collection("permissions")
                        .doc()
                        .set(obj);
                }
            })
            .catch(AgentActions.error);
    }

    onRemoveClient(id) {
        db.collection("permissions")
            .doc(id)
            .delete()
            .catch(AgentActions.error);
    }

    onUpdatePermissions(obj) {
        var id = obj.id;
        delete obj.id;

        db.collection("permissions")
            .doc(id)
            .set(obj);
    }

    onRunAnalytics(message) {
        const statusCode = "running analytics";

        if (!status.has(Agent, statusCode))
            api(
                "post",
                "/agents/" + Agent.working.id + "/analytics",
                message,
                AgentActions.runAnalyticsCompleted,
                AgentActions.error,
                Agent,
                statusCode
            );
    }

    onRunAnalyticsCompleted(results) {
        parse_analytics(results);
    }

    onGroupChange(field, location, value) {
        setField(Agent.working, field, location, value);
        const statusCode = "changing group";
        if (!status.has(Agent, statusCode))
            api(
                "put",
                "/agents/" + Agent.working.id + "/group",
                { group: value },
                AgentActions.update,
                AgentActions.error,
                Agent,
                statusCode,
                this.notify
            );
    }

    onUnsubscribe() {
        if (Agent.workingUpdate === undefined) return;

        var myKeys = Object.keys(Agent.workingUpdate);
        for (var i = 0; i < myKeys.length; i++) {
            Agent.workingUpdate[myKeys[i]].updater();
            delete Agent.workingUpdate[myKeys[i]];
        }

        Agent.workingUpdate = {};
    }

    onClose() {
        AgentActions.unsubscribe();
        Agent.loadedParts = [];
        Agent.allLoaded = false;
        delete Agent.working;
        this.notify();
    }

    onUpdate() {
        this.notify();
    }

    onGet(callback) {
        callback(Agent, this.notify);
    }

    notify() {
        console.log("notify agents");
        var working = Agent.working !== undefined ? objects.clone(Agent.working) : undefined;

        this.setState({
            AgentStore: {
                summary: Agent.summary,
                status: Agent.status,
                workingSubcollection: Agent.workingSubcollection,
                cursor: Agent.cursor,
                workingUpdate: Agent.workingUpdate,
                working
            }
        });
    }
}

export default AgentStore;
