import React from "react";

const primaryStyle = {
	display: "inline-block",
	verticalAlign: "top",
	fontSize: "16px",
	color: "rgba(0, 0, 0, 0.87)",
	fontWeight: "500",
	cursor: "pointer",
	width: "80px",
	textAlign: "center"
};

const labelStyle = {
	width: "100%",
	display: "inline-block",
	marginTop: "4px",
	fontSize: "12px",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)"
};

class Active extends React.Component {
	render() {
		const activeStyle = {
			fontSize: "16px",
			padding: "2px",
			borderRadius: "50%",
			color: this.props.item.active ? "rgb(70, 180, 175)" : "rgba(0, 0, 0, 0.52)",
			border: "1px solid"
		};

		return (
			<div style={primaryStyle}>
				<i style={activeStyle} className="material-icons">
					check
				</i>
				<span style={labelStyle}>{this.props.item.active ? "Active" : "Inactive"}</span>
			</div>
		);
	}
}

export default Active;
