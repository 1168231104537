import React from "react";
import config from "../../config.public";

// Actions
import AgentActions from "../../actions/AgentActions";

// Generic pieces
import AppBar from "../_common/AppBar.react";
import SideNavigation from "../_common/SideNavigation.react";
import Loading from "../_common/Loading.react";
import Permissions from "../_common/Permissions.react";
import ClientResult from "../_common/search/ClientResult.react";

// Agent specific
import AgentHeader from "./AgentHeader.react";

import AgentDetails from "./AgentDetails.react";
import AgentEvents from "./AgentEvents.react";
import AgentSandboxRatings from "./AgentSandboxRatings.react";
import AgentSessions from "./AgentSessions.react";
import AgentAnalytics from "./Analytics.react";
import AgentSettings from "./AgentSettings.react";
import AgentSchedule from "./AgentSchedule.react";

import FlaggedTickets from "../_common/FlaggedTickets.react";

// Menu settings
const menuOptions = [
	{
		text: "Save",
		value: "save"
	},
	{
		text: "Save as New",
		value: "save as new"
	},
	{
		divider: true
	},
	{
		text: "Delete",
		value: "delete"
	}
];

var guruPages = [];
for (var i = 0; i < config.pages.length; i++) {
	if (config.pages[i].value === "gurus") {
		guruPages = config.pages[i].pages;
		break;
	}
}

class Agent extends React.Component {
	render() {
		var toRender = [];
		switch (this.props.view) {
			case "gurus_details":
				toRender.push(
					<AgentDetails
						key={
							this.props.AgentStore.working === undefined
								? "guru-details"
								: "guru-details-" + this.props.AgentStore.working.id
						}
						item={this.props.AgentStore.working}
						status={this.props.AgentStore.status}
						errors={this.props.AgentStore.errors}
						clients={this.props.ClientStore.summary}
					/>
				);
				break;
			case "gurus_permissions":
				toRender.push(
					<Permissions
						key={
							this.props.AgentStore.working === undefined
								? "guru-permissions"
								: "guru-permissions-" + this.props.AgentStore.working.id
						}
						title={"AGENT'S CLIENTS"}
						subtext={"Select the clients who " + this.props.AgentStore.working.name + " works on."}
						type={"clients"}
						clientId={this.props.AgentStore.working.id}
						items={this.props.AgentStore.working.clients || []}
						status={this.props.AgentStore.status}
						UserStore={this.props.UserStore.me}
						resultComponent={ClientResult}
					/>
				);
				break;
			case "gurus_events":
				toRender.push(
					<AgentEvents
						key={
							this.props.AgentStore.working === undefined
								? "guru-events"
								: "guru-events-" + this.props.AgentStore.working.id
						}
						agent={this.props.AgentStore.working}
						events={this.props.AgentStore.working.events || []}
						status={this.props.AgentStore.status}
						title={"Events"}
						errors={this.props.AgentStore.errors}
						cursor={this.props.AgentStore.workingUpdate.events || {}}
						view={this.props.view}
					/>
				);
				break;
			case "gurus_sandbox":
				toRender.push(
					<AgentSandboxRatings
						key={
							this.props.AgentStore.working === undefined
								? "guru-sandboxRatings"
								: "guru-sandboxRatings-" + this.props.AgentStore.working.id
						}
						agent={this.props.AgentStore.working}
						sandboxRatings={this.props.AgentStore.working.sandboxRatings || []}
						status={this.props.AgentStore.status}
						title={"SANDBOX RATINGS"}
						errors={this.props.AgentStore.errors}
						cursor={this.props.AgentStore.workingUpdate.sandboxRatings || {}}
						view={this.props.view}
					/>
				);
				break;

			case "gurus_flagged":
				toRender.push(
					<FlaggedTickets
						key={
							this.props.AgentStore.working === undefined
								? "guru-flagged"
								: "guru-flagged-" + this.props.AgentStore.working.id
						}
						client={this.props.AgentStore.working}
						tickets={this.props.AgentStore.working.flaggedTickets || []}
						status={this.props.AgentStore.status}
						errors={this.props.AgentStore.errors}
						cursor={this.props.AgentStore.workingUpdate.flaggedTickets || {}}
						view={this.props.view}
						actions={AgentActions}
						badgeField="client"
					/>
				);
				break;
			case "gurus_analytics":
				toRender.push(
					<AgentAnalytics
						key={
							this.props.AgentStore.working === undefined
								? "guru-analytics"
								: "guru-analytics-" + this.props.AgentStore.working.id
						}
						item={this.props.AgentStore.working}
						status={this.props.AgentStore.status}
					/>
				);
				break;
			case "gurus_sessions":
				toRender.push(
					<AgentSessions
						key={
							this.props.AgentStore.working === undefined
								? "guru-sessions"
								: "guru-sessions-" + this.props.AgentStore.working.id
						}
						agent={this.props.AgentStore.working}
						sessions={this.props.AgentStore.working.sessions || []}
						status={this.props.AgentStore.status}
						title={"Sessions"}
						errors={this.props.AgentStore.errors}
						cursor={this.props.AgentStore.workingUpdate.events || {}}
						view={this.props.view}
					/>
				);
				break;
			case "gurus_schedule":
				toRender.push(
					<AgentSchedule
						key={
							this.props.AgentStore.working === undefined
								? "guru-schedule"
								: "guru-schedule-" + this.props.AgentStore.working.id
						}
						item={this.props.AgentStore.working}
						status={this.props.AgentStore.status}
						UserStore={this.props.UserStore.me}
					/>
				);
				break;
			case "gurus_settings":
				toRender.push(
					<AgentSettings
						key={
							this.props.AgentStore.working === undefined
								? "guru-settings"
								: "guru-settings-" + this.props.AgentStore.working.id
						}
						item={this.props.AgentStore.working}
						status={this.props.AgentStore.status}
						UserStore={this.props.UserStore.me}
					/>
				);
				break;
			default:
				toRender.push(<Loading key="loading-view" />);
				break;
		}

		return (
			<div>
				<AppBar
					key={this.props.AgentStore.working === undefined ? "app-bar" : "app-bar-" + this.props.AgentStore.working.id}
					UserStore={this.props.UserStore.me}
					text={this.props.AgentStore.working.name}
					dynamic={true}
					actions={AgentActions}
					target="gurus"
					active={this.props.AgentStore.working !== undefined ? this.props.AgentStore.working.active : false}
					backURL="gurus"
					menuOptions={menuOptions}
				/>
				<AgentHeader key="guru-header" item={this.props.AgentStore.working} />
				<SideNavigation
					key="side-navigation-guru"
					title={"Guru Sections"}
					user={this.props.UserStore.me}
					view={this.props.UIStore.view}
					pages={guruPages}
					absolute={true}
					top={"420px"}
					viewPrefix="gurus_"
					urlPrefix={"gurus/" + this.props.AgentStore.working.id + "/"}
				/>
				{toRender}
			</div>
		);
	}
}

export default Agent;
