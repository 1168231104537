import Reflux from "reflux";

var TriageOptionsActions = Reflux.createActions([
    "new",
    "load",
    "setWorking",
    "save",
    "delete",
    "setField",
    "add",
    "remove",
    "close",
    "get",
    "update",
    "error"
]);

export default TriageOptionsActions;
