import React from "react";

// Actions
import UIActions from "../../../actions/UIActions";
import ClientActions from "../../../actions/ClientActions";

// Scaffolding
import Card from "../../_common/Card.react";
import Container from "../../_common/Container.react";
import Overlay from "../../_common/Overlay.react";
import SettingsRow from "../../_common/SettingsRow.react";

// Form items
import Input from "../../_common/forms/Input.react";
import Button from "../../_common/buttons/Button.react";
import Dropdown from "../../_common/forms/Dropdown.react";

// Headers
import H1 from "../../_common/headers/H1.react";
import SectionLabel from "../../_common/SectionLabel.react";

import Toggle from "../../_common/forms/Toggle.react";

// Styles
const cardStyle = {
    width: "calc(100% - 48px)",
    padding: "24px 0px 24px 24px",
    textAlign: "left",
    maxWidth: "1200px"
};

const rowStyle = {
    width: "calc(100% - 8px)",
    textAlign: "right",
    margin: "0px 0px -24px -24px",
    borderTop: "1px solid lightgrey",
    padding: "16px"
};

const buttonStyle = {
    marginLeft: "8px"
};

const emptyStyle = {
    textAlign: "center",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "24px 0px",
    width: "calc(100% - 48px - 32px)",
    padding: "24px",
    border: "1px dashed lightgrey",
    borderRadius: "4px"
};

const emptyTextStyle = {
    fontSize: "24px",
    fontWeight: "400",
    marginBottom: "20px"
};

const fieldLabelStyle = {
    width: "calc(100% - 80px)",
    marginRight: "16px",
    display: "inline-block",
    verticalAlign: "middle"
};

const filterButtonContainerStyle = {
    margin: "16px 0px",
    width: "100%",
    textAlign: "center"
};

const simpleStateOptions = [
    {
        text: "Is",
        value: "is"
    },
    {
        text: "Is not",
        value: "is not"
    }
];

const complexStateOptions = [
    {
        text: "Is",
        value: "is"
    },
    {
        text: "Is not",
        value: "is not"
    },
    {
        text: "Less than",
        value: "less than"
    },
    {
        text: "Greater than",
        value: "greater than"
    }
];

const textStateOptions = [
    {
        text: "Is",
        value: "is"
    },
    {
        text: "Is not",
        value: "is not"
    },
    {
        text: "Contains",
        value: "contains"
    },
    {
        text: "Does not contain",
        value: "does not contain"
    }
];

const actionOptions = [
    {
        text: "Appear",
        value: "appear"
    },
    {
        text: "Not appear",
        value: "not appear"
    }
];

const bigInputStyle = {
    marginBottom: "8px"
};

const textFields = ["subject", "description"];
const complexFields = ["status", "priority", "date", "decimal", "integer"];

var generate_dropdown_items = (fields, section) => {
    var options = [];

    for (var i = 0; i < fields.length; i++) {
        options.push({
            id: fields[i].value || fields[i].name,
            text: fields[i].name,
            value: fields[i].value || fields[i].name,
            layer: fields[i].layer,
            section: section.concat(fields[i].value || fields[i].name)
        });

        if (fields[i].items !== undefined)
            options[options.length - 1].items = generate_dropdown_items(
                fields[i].items,
                options[options.length - 1].section
            );
    }

    return options;
};

class RuleRow extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onRemoveRule = this._onRemoveRule.bind(this);

        this.state = {};
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        ClientActions.setField(field, location, value, true);
    }

    _onRemoveRule() {
        if (this.props.totalRules === 1) {
            ClientActions.remove(this.props.location);
        } else {
            ClientActions.remove(this.props.location.concat(this.props.item.id));
        }
    }

    render() {
        const primaryStyle = {
            width: "100%",
            display: "inline-block",
            padding: "8px 0px"
        };

        const typeStyle = {
            width: "170px",
            marginBottom: "0px",
            marginRight: "16px",
            verticalAlign: "bottom"
        };

        const stateStyle = {
            width: "100px",
            marginBottom: "0px",
            marginRight: "16px",
            verticalAlign: "bottom"
        };

        const leftColumnStyle = {
            display: "inline-block",
            width: "calc(100% - 60px)",
            verticalAlign: "middle"
        };

        const closeIconStyle = {
            marginLeft: "16px",
            marginTop: "8px",
            display: "inline-block",
            verticalAlign: "middle",
            cursor: "pointer"
        };

        // Figures out value options
        var valueOptions = [];
        if ([null, undefined, ""].indexOf(this.props.item.if) === -1) {
            var selectedIf;
            for (var i = 0; i < this.props.ticket_fields_full.length; i++) {
                if (
                    this.props.ticket_fields_full[i].id === this.props.item.if ||
                    this.props.ticket_fields_full[i].type === this.props.item.if
                ) {
                    selectedIf = this.props.ticket_fields_full[i];
                    break;
                }
            }

            if (selectedIf !== undefined) {
                if (selectedIf.type === "tagger")
                    valueOptions = generate_dropdown_items(selectedIf.custom_field_options, []);
                else if (["tickettype", "priority"].indexOf(selectedIf.type) > -1)
                    valueOptions = generate_dropdown_items(selectedIf.system_field_options, []);
            }
        }

        // Figures out state options
        var location = this.props.location.concat(this.props.item.id);
        var stateOptions = [];
        if (selectedIf === undefined) stateOptions = [];
        else if (textFields.indexOf(selectedIf.type) > -1) stateOptions = textStateOptions;
        else if (complexFields.indexOf(selectedIf.type) > -1) stateOptions = complexStateOptions;
        else stateOptions = simpleStateOptions;

        return (
            <div style={primaryStyle}>
                <div style={leftColumnStyle}>
                    <Dropdown
                        id={"filter-type-" + this.props.parentIndex + "-" + this.props.index}
                        style={typeStyle}
                        value={this.props.item.if}
                        field="if"
                        location={location}
                        label="Field"
                        items={this.props.ticket_fields || []}
                        updateFn={this._onBlur}
                        searchable={true}
                        noHelp={true}
                    />
                    <Dropdown
                        id={"filter-state-" + this.props.parentIndex + "-" + this.props.index}
                        style={stateStyle}
                        value={this.props.item.state}
                        field="state"
                        location={location}
                        label="State"
                        items={stateOptions}
                        updateFn={this._onBlur}
                        searchable={true}
                        noHelp={true}
                    />
                    {valueOptions.length > 0 ? (
                        <Dropdown
                            id={"filter-value-" + this.props.parentIndex + "-" + this.props.index}
                            style={typeStyle}
                            value={this.props.item.value}
                            field="value"
                            location={location}
                            label="Value"
                            items={valueOptions}
                            updateFn={this._onBlur}
                            searchable={true}
                            noHelp={true}
                        />
                    ) : (
                        <Input
                            id={"filter-value-" + this.props.parentIndex + "-" + this.props.index}
                            style={typeStyle}
                            value={this.state.value || this.props.item.value}
                            field="value"
                            location={location}
                            label="Value"
                            updateFn={this._updateFn}
                            onBlur={this._onBlur}
                            noHelp={true}
                        />
                    )}
                    {this.props.index === 0 ? (
                        <Dropdown
                            id={"result-" + this.props.parentIndex + "-" + this.props.index}
                            style={typeStyle}
                            value={this.props.rule.action}
                            field="action"
                            location={this.props.location}
                            label="Action"
                            items={actionOptions}
                            updateFn={this._onBlur}
                            searchable={true}
                            noHelp={true}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <i style={closeIconStyle} className="material-icons" onClick={this._onRemoveRule}>
                    close
                </i>
            </div>
        );
    }
}

class Rule extends React.Component {
    constructor(props) {
        super(props);
        this._onAddOr = this._onAddOr.bind(this);
    }

    _onAddOr() {
        ClientActions.add("rules", this.props.location.concat(this.props.item.id), {
            if: "",
            state: "is",
            value: ""
        });
    }

    render() {
        const outerStyle = {
            width: "100%",
            display: "inline-block",
            margin: "16px 0px 8px"
        };

        const primaryStyle = {
            display: "inline-block",
            width: "calc(100% - 16px)",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            padding: "8px",
            marginBottom: this.props.last ? "0px" : "16px"
        };

        const labelStyle = {
            width: "100%",
            textAlign: "center",
            fontSize: "20px",
            margin: "8px 0px",
            fontWeight: "700"
        };

        var rows =
            Array.isArray(this.props.item.rules) && this.props.item.rules.length > 0 ? this.props.item.rules : [];
        return (
            <div style={outerStyle}>
                <div style={primaryStyle}>
                    {rows.map((item, index, arr) => (
                        <RuleRow
                            key={this.props.item.id + "-" + index}
                            item={item}
                            index={index}
                            parentIndex={this.props.index}
                            location={this.props.location.concat(this.props.item.id)}
                            totalRules={arr.length}
                            ticket_fields={this.props.ticket_fields}
                            ticket_fields_full={this.props.ticket_fields_full}
                            target={this.props.target}
                            rule={this.props.item}
                        />
                    ))}
                    <div style={filterButtonContainerStyle}>
                        <Button text="Add AND Clause" onClick={this._onAddOr} />
                    </div>
                </div>

                {!this.props.last ? <div style={labelStyle}>OR</div> : ""}
            </div>
        );
    }
}

class Row extends React.Component {
    render() {
        const rowStyle = {
            display: "inline-block",
            width: "calc(100% - 48px)",
            padding: "16px 32px 16px 16px",
            borderTop: this.props.index === 0 ? "1px solid lightgrey" : "",
            borderBottom: "1px solid lightgrey"
        };

        return <div style={rowStyle}>{this.props.children}</div>;
    }
}

const systemFields = ["subject", "status", "description", "tickettype", "priority", "group", "assignee"];
var make_ticket_field_option = obj => {
    if (systemFields.indexOf(obj.type) > -1) return { text: obj.title, value: obj.type };
    else return { text: obj.title, value: obj.id };
};

class TicketFieldEditorOverlay extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onAddRule = this._onAddRule.bind(this);

        this.state = {
            active: this.props.item.field.active || false,
            required: this.props.item.field.required || false
        };
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value });
    }

    _onBlur(field, location, value) {
        this.setState({ [field]: value });
        ClientActions.setField(field, ["zendesk", this.props.item.field.id], value, true);
    }

    _onClose() {
        UIActions.showOverlay("");
        ClientActions.update();
    }

    _onAddRule() {
        ClientActions.add("displaylogic", ["zendesk", this.props.item.field.id], {
            rules: [{ if: "", state: "is", value: "" }],
            action: "appear"
        });
    }

    render() {
        var ticketFields = [];
        for (var i = 0; i < this.props.item.ticket_fields.length; i++) {
            if (
                this.props.item.ticket_fields[i].id !== this.props.item.field.id &&
                this.props.item.ticket_fields[i].active
            )
                ticketFields.push(make_ticket_field_option(this.props.item.ticket_fields[i]));
        }

        return (
            <Overlay>
                <Container maxWidth="848px" key="ticket-field-editor-container" marginBottom="0px">
                    <Card style={cardStyle}>
                        <H1 text="Ticket Field" textAlign="center" />
                        <Input
                            value={this.props.item.field.title || ""}
                            onBlur={this._onBlur}
                            location={["zendesk", this.props.item.field.id]}
                            label={"Field Name"}
                            field="title"
                            colored={false}
                            style={bigInputStyle}
                        />
                        <SettingsRow index={0} icon="visibility" active={this.state.active}>
                            <div>Field is active (will appear for agents)</div>
                            <Toggle
                                value={this.state.active}
                                field="active"
                                updateFn={this._onBlur}
                                location={["zendesk", this.props.item.field.id]}
                            />
                        </SettingsRow>
                        <SettingsRow index={1} icon="star_rate" active={this.state.required}>
                            <div>Should the field be required?</div>
                            <Toggle
                                value={this.state.required}
                                field="required"
                                updateFn={this._onBlur}
                                location={["zendesk", this.props.item.field.id]}
                            />
                        </SettingsRow>
                        <Row index={2}>
                            <SectionLabel text="Conditional Display Rules" />
                            {this.props.item.field.displaylogic !== undefined &&
                            this.props.item.field.displaylogic.length > 0 ? (
                                <div>
                                    {this.props.item.field.displaylogic.map((item, index) => (
                                        <Rule
                                            key={"rule-" + index}
                                            item={item}
                                            index={index}
                                            target={this.props.item.field}
                                            location={["zendesk", this.props.item.field.id]}
                                            ticket_fields={ticketFields}
                                            ticket_fields_full={this.props.item.ticket_fields}
                                        />
                                    ))}
                                    <div style={filterButtonContainerStyle}>
                                        <Button text="Add OR clause" onClick={this._onAddRule} />
                                    </div>
                                </div>
                            ) : (
                                <div style={emptyStyle}>
                                    <div style={emptyTextStyle}>There are currently no conditional display rules</div>
                                    <Button text="Add Conditional Display Rule" onClick={this._onAddRule} />
                                </div>
                            )}
                        </Row>
                        <div style={rowStyle}>
                            <Button text="Close" style={buttonStyle} onClick={this._onClose} />
                        </div>
                    </Card>
                </Container>
            </Overlay>
        );
    }
}

export default TicketFieldEditorOverlay;
