import Reflux from "reflux";

var ProductActions = Reflux.createActions([
	"new",
	"load",
	"save",
	"delete",
	"setField",
	"add",
	"remove",
	"parseCSV",
	"close",
	"update",
	"error"
]);

export default ProductActions;
