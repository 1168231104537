import React from "react";
import MDSpinner from "react-md-spinner";

// Form items
import m from "../../functions/m";

import * as searchHelper from "../../functions/search";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._onSearchResult = this._onSearchResult.bind(this);
        this._onPreloadResult = this._onPreloadResult.bind(this);

        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);

        this.state = {
            term: "",
            focus: false,
            hover: false,
            searching: false,
            defaults: this.props.items || [],
            items: this.props.items || []
        };
    }

    componentDidMount() {
        if (this.props.preload) searchHelper.preload(this.props.type, this.props.where || [], this._onPreloadResult);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.defaults.length === 0 && this.state.items.length === 0 && this.state.term === "")
            this.setState({ items: nextProps.items || [], defaults: nextProps.items || [] });
        else if (this.state.defaults.length === 0 && this.state.items.length === 0)
            this.setState({
                defaults: nextProps.items || [],
                items: searchHelper.find_items(this.state.term, nextProps.items, this.props.fields || []) || []
            });
    }

    _onSearchResult(items) {
        this.setState({ items, searching: false });
        if (this.props.searchResults !== undefined) this.props.searchResults(items);
    }

    _onPreloadResult(items) {
        this.setState({
            defaults: items,
            items: searchHelper.find_items(this.state.term, items, this.props.fields || []) || []
        });
    }

    _updateFn(event) {
        if (
            this.props.type !== undefined &&
            (this.props.min === undefined || event.currentTarget.value.length >= this.props.min)
        ) {
            this.setState({ searching: true, term: event.currentTarget.value });
            searchHelper.query(
                event.currentTarget.value,
                this.props.type,
                this.props.fields || [],
                this.props.where || [],
                this._onSearchResult
            );
        } else {
            var items =
                searchHelper.find_items(event.currentTarget.value, this.state.defaults, this.props.fields || []) || [];
            this.setState({ term: event.currentTarget.value, items });
            if (this.props.searchResults !== undefined) this.props.searchResults(items);
        }

        if (this.props.items === undefined && this.props.actions !== undefined) {
            this.props.actions.setField("search", [], event.currentTarget.value, true);
        }
    }

    _onFocus() {
        this.setState({ focus: true });
        if (this.props.onFocus) this.props.onFocus();
    }

    _onBlur() {
        this.setState({ focus: false });
        if (this.props.onBlur) this.props.onBlur();
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onSelect(obj) {
        this.setState({
            term: "",
            focus: false,
            hover: false,
            items: this.state.defaults
        });
        this.props.onSelect(obj);
    }

    render() {
        const open = this.state.focus || this.state.hover ? true : false;

        const primaryStyle = {
            width: "100%",
            position: "relative",
            textAlign: "center",
            height: "56px"
        };

        const searchStyle = {
            position: "absolute",
            top: "0px",
            left: "0px",
            backgroundColor: open ? "white" : "rgba(0, 0, 0, 0.12)",
            width: "100%",
            display: "inline-block",
            textAlign: "left",
            borderRadius: "2px",
            cursor: this.props.onClick !== undefined ? "pointer" : "",
            color: "rgba(0, 0, 0, 0.87)",
            transition: "box-shadow .3s ease",
            boxShadow: open
                ? "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4)"
                : "",
            zIndex: "2"
        };

        const inputStyle = {
            width: "calc(100% - 100px)",
            backgroundColor: "transparent",
            border: "none",
            fontFamily: "Roboto",
            fontSize: "20px",
            color: "inherit",
            display: "inline-block",
            verticalAlign: "middle",
            outline: "none",
            padding: "16px 0px"
        };

        const iconStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0px 16px"
        };

        const spinnerStyle = {
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0px 16px",
            marginRight: "20px"
        };

        const listStyle = {
            display: open && !this.props.hideResults ? "inline-block" : "none",
            top: "100%",
            left: "0px",
            zIndex: "-1",
            padding: "0px",
            margin: "0px",
            fontSize: "14px",
            textAlign: "left",
            listStyle: "none",
            borderRadius: "0px",
            color: "rgba(0, 0, 0, 0.87)",
            maxHeight: "40vh",
            overflowY: "auto",
            width: "100%",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)"
        };

        const noResultsStyle = {
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "400",
            width: "calc(100% - 32px)",
            backgroundColor: "transparent",
            cursor: "pointer",
            padding: "16px"
        };

        var items = [];

        if (this.state.items === undefined || this.state.items.length === 0) {
            items.push(
                <div key={"no-results-" + this.props.id} style={noResultsStyle}>
                    No results found
                </div>
            );
        } else if (this.state.items !== undefined && this.props.resultComponent !== undefined) {
            var ResultComponent = this.props.resultComponent;
            items = this.state.items.map((item, index, arr) => (
                <ResultComponent
                    key={"search-result-" + item.id}
                    item={item}
                    onClick={this._onSelect}
                    custom={this.props.customForComponent}
                    searchTerm={this.state.term}
                />
            ));
        }

        return (
            <div style={m(primaryStyle, this)}>
                <div id={this.props.id} style={searchStyle}>
                    {this.state.searching ? (
                        <div style={spinnerStyle}>
                            <MDSpinner size={20} singleColor="rgb(70, 180, 175)" />
                        </div>
                    ) : (
                        <i style={iconStyle} className="material-icons">
                            search
                        </i>
                    )}
                    <input
                        id={"search-" + this.props.id}
                        value={this.state.term}
                        onChange={this._updateFn}
                        placeholder={this.props.placeholder}
                        style={inputStyle}
                        onFocus={this._onFocus}
                        onBlur={this._onBlur}
                        autoComplete="off"
                    />
                    {this.state.items !== undefined ? (
                        <ul style={listStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut}>
                            {items}
                        </ul>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }
}

export default Search;
