import React from "react";
import config from "../../config.public";

// Actions
import ClientActions from "../../actions/ClientActions";

// Generic pieces
import AppBar from "../_common/AppBar.react";
import SideNavigation from "../_common/SideNavigation.react";
import Loading from "../_common/Loading.react";
import Permissions from "../_common/Permissions.react";
import UserResult from "../_common/search/UserResult.react";

// Client specific
import ClientHeader from "./ClientHeader.react";

import ClientDetails from "./ClientDetails.react";
import ClientAnalytics from "./Analytics.react";
import ClientExport from "./Export.react";
import Products from "./Products.react";
import ClientSettings from "./ClientSettings.react";
import ClientTriageOptions from "./ClientTriageOptions.react";
import ClientWorkflows from "../workflows/ClientWorkflows.react";
import ClientMacros from "../macros/ClientMacros.react";
import ClientTickets from "../tickets/ClientTickets.react";
import ClientTicketsSearch from "../tickets/ClientTicketsSearch.react";
import ClientEvents from "./ClientEvents.react";
import ClientSandboxRatings from "./ClientSandboxRatings.react";

import GenericIntegration from "./integrations/GenericIntegration.react";
import GenericCRM from "./integrations/GenericCRM.react";
import QueueOrder from "./QueueOrder.react";

import Documents from "../documents/Documents.react";
import FlaggedTickets from "../_common/FlaggedTickets.react";

// Functions
import * as permissions from "../../functions/permissions";

const clientMenuOptions = [
    {
        text: "Save",
        value: "save"
    },
    {
        text: "Save as New",
        value: "save as new"
    },
    {
        text: "About Client",
        value: "about client"
    },
    {
        divider: true
    },
    {
        text: "Delete",
        value: "delete"
    }
];

var clientPages = [];
for (var i = 0; i < config.pages.length; i++) {
    if (config.pages[i].value === "clients") {
        clientPages = config.pages[i].pages;
        break;
    }
}

const settingsSubsection = ["clients_generic_integration", "clients_generic_crm", "clients_queue_order"];

class Client extends React.Component {
    render() {
        var toRender = [];

        switch (this.props.view) {
            case "clients_details":
                toRender.push(
                    <ClientDetails
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-details"
                                : "client-details-" + this.props.ClientStore.working.id
                        }
                        item={this.props.ClientStore.working}
                        status={this.props.ClientStore.status}
                        searchItems={this.props.AgentStore.summary}
                        UserStore={this.props.UserStore.me}
                    />
                );
                break;
            case "clients_permissions":
                toRender.push(
                    <Permissions
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-permissions"
                                : "client-permissions-" + this.props.ClientStore.working.id
                        }
                        title={"ASSIGNED AGENTS"}
                        subtext={
                            "Select the agents who work on the " + this.props.ClientStore.working.name + " account."
                        }
                        type={"users"}
                        clientId={this.props.ClientStore.working.id}
                        items={this.props.ClientStore.working.agents || []}
                        status={this.props.ClientStore.status}
                        searchItems={this.props.AgentStore.summary}
                        UserStore={this.props.UserStore.me}
                        resultComponent={UserResult}
                    />
                );
                break;
            case "clients_analytics":
                toRender.push(
                    <ClientAnalytics
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-analytics"
                                : "client-analytics-" + this.props.ClientStore.working.id
                        }
                        item={this.props.ClientStore.working}
                        status={this.props.ClientStore.status}
                    />
                );
                break;
            case "clients_export":
                toRender.push(
                    <ClientExport
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-exports"
                                : "client-exports-" + this.props.ClientStore.working.id
                        }
                        item={this.props.ClientStore.working}
                        status={this.props.ClientStore.status}
                        exports={this.props.ClientStore.working.exports}
                        cursor={this.props.ClientStore.workingUpdate.exports || {}}
                    />
                );
                break;
            case "clients_products":
                toRender.push(
                    <Products
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-products"
                                : "client-products-" + this.props.ClientStore.working.id
                        }
                        items={this.props.ClientStore.working.products || []}
                        clientId={this.props.ClientStore.working.id}
                        status={this.props.ProductStore.status}
                    />
                );
                break;
            case "clients_documentation":
                toRender.push(
                    <Documents
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-documentation"
                                : "client-documentation-" + this.props.ClientStore.working.id
                        }
                        item={this.props.DocumentStore.working || {}}
                        clientId={this.props.ClientStore.working.id}
                        status={this.props.ProductStore.status}
                    />
                );
                break;
            case "clients_triageoptions":
                toRender.push(
                    <ClientTriageOptions
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-triageoptions"
                                : "client-triageoptions-" + this.props.ClientStore.working.id
                        }
                        item={this.props.TriageOptionsStore.working || {}}
                        clientId={this.props.ClientStore.working.id}
                        status={this.props.TriageOptionsStore.status}
                    />
                );
                break;
            case "clients_workflows":
                toRender.push(
                    <ClientWorkflows
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-workflows"
                                : "client-workflows-" + this.props.ClientStore.working.id
                        }
                        workflows={this.props.ClientStore.working.workflows || []}
                        status={this.props.ClientStore.status}
                        title={"Workflows"}
                        cursor={this.props.ClientStore.workingUpdate.workflows || {}}
                        view={this.props.view}
                        client={this.props.ClientStore.working}
                    />
                );
                break;
            case "clients_macros":
                toRender.push(
                    <ClientMacros
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-macros"
                                : "client-macros-" + this.props.ClientStore.working.id
                        }
                        status={this.props.ClientStore.status}
                        macros={this.props.ClientStore.working.macros || []}
                        title={"Macros"}
                        cursor={this.props.ClientStore.workingUpdate.macros || {}}
                        view={this.props.view}
                        client={this.props.ClientStore.working}
                    />
                );
                break;
            case "clients_queued":
                toRender.push(
                    <ClientTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets-queued"
                                : "client-tickets-queued-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.queued || []}
                        status={this.props.ClientStore.status}
                        title={"Queued Tickets"}
                        cursor={this.props.ClientStore.workingUpdate.queued || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_escalations":
                toRender.push(
                    <ClientTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets-escalations"
                                : "client-tickets-escalations-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.escalations || []}
                        status={this.props.ClientStore.status}
                        title={"Escalated Tickets"}
                        cursor={this.props.ClientStore.workingUpdate.escalations || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_csat":
                toRender.push(
                    <ClientTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets-csat"
                                : "client-tickets-csat-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.csats || []}
                        status={this.props.ClientStore.status}
                        title={"Negative CSATs Awaiting Review"}
                        cursor={this.props.ClientStore.workingUpdate.csats || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_tickets":
                toRender.push(
                    <ClientTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets"
                                : "client-tickets-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.tickets || []}
                        status={this.props.ClientStore.status}
                        title={"All Tickets"}
                        cursor={this.props.ClientStore.workingUpdate.tickets || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_activetickets":
                toRender.push(
                    <ClientTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets-active"
                                : "client-tickets-active-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.activeTickets || []}
                        status={this.props.ClientStore.status}
                        title={"Active Tickets"}
                        cursor={this.props.ClientStore.workingUpdate.activetickets || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_searchtickets":
                toRender.push(
                    <ClientTicketsSearch
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-tickets-search"
                                : "client-tickets-search-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        status={this.props.ClientStore.status}
                        title={"Search Tickets"}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_events":
                toRender.push(
                    <ClientEvents
                        key={
                            this.props.ClientStore.working === undefined
                                ? "clients-events"
                                : "clients-events-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        events={this.props.ClientStore.working.events || []}
                        status={this.props.ClientStore.status}
                        title={"Events"}
                        errors={this.props.ClientStore.errors}
                        cursor={this.props.ClientStore.workingUpdate.events || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_sandbox":
                toRender.push(
                    <ClientSandboxRatings
                        key={
                            this.props.ClientStore.working === undefined
                                ? "clients-sandboxRatings"
                                : "clients-sandboxRatings-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        sandboxRatings={this.props.ClientStore.working.sandboxRatings || []}
                        status={this.props.ClientStore.status}
                        title={"SANDBOX RATINGS"}
                        errors={this.props.ClientStore.errors}
                        cursor={this.props.ClientStore.workingUpdate.sandboxRatings || {}}
                        view={this.props.view}
                    />
                );
                break;
            case "clients_flagged":
                toRender.push(
                    <FlaggedTickets
                        key={
                            this.props.ClientStore.working === undefined
                                ? "clients-flagged"
                                : "clients-flagged-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        tickets={this.props.ClientStore.working.flaggedTickets || []}
                        status={this.props.ClientStore.status}
                        errors={this.props.ClientStore.errors}
                        cursor={this.props.ClientStore.workingUpdate.flaggedTickets || {}}
                        view={this.props.view}
                        actions={ClientActions}
                        badgeField="agent"
                    />
                );
                break;
            case "clients_settings":
                toRender.push(
                    <ClientSettings
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-settings"
                                : "client-settings-" + this.props.ClientStore.working.id
                        }
                        item={this.props.ClientStore.working}
                        status={this.props.ClientStore.status}
                        searchItems={this.props.AgentStore.summary}
                        UserStore={this.props.UserStore.me}
                    />
                );
                break;
            case "clients_generic_integration":
                toRender.push(
                    <GenericIntegration
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-generic-integration"
                                : "client-generic-integration-" + this.props.ClientStore.working.id
                        }
                        item={this.props.UIStore.viewData.item}
                        client={this.props.ClientStore.working}
                        UserStore={this.props.UserStore.me}
                    />
                );
                break;
            case "clients_generic_crm":
                toRender.push(
                    <GenericCRM
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-generic-crm"
                                : "client-generic-crm-" + this.props.ClientStore.working.id
                        }
                        client={this.props.ClientStore.working}
                        integration={this.props.UIStore.viewData.integration}
                        component={this.props.UIStore.viewData.component}
                        scaffold={this.props.UIStore.viewData.scaffold}
                        UserStore={this.props.UserStore.me}
                    />
                );
                break;
            case "clients_queue_order":
                toRender.push(
                    <QueueOrder
                        key={
                            this.props.ClientStore.working === undefined
                                ? "client-queue-order"
                                : "client-queue-order-" + this.props.ClientStore.working.id
                        }
                        item={this.props.ClientStore.working}
                        UserStore={this.props.UserStore.me}
                    />
                );
                break;
            default:
                toRender.push(<Loading key="loading-view" />);
                break;
        }

        return (
            <div>
                <AppBar
                    key={
                        this.props.ClientStore.working === undefined
                            ? "app-bar"
                            : "app-bar-" + this.props.ClientStore.working.id
                    }
                    UserStore={this.props.UserStore.me}
                    text={this.props.ClientStore.working.name}
                    dynamic={true}
                    actions={ClientActions}
                    target="clients"
                    active={this.props.ClientStore.working.active}
                    backView={settingsSubsection.indexOf(this.props.view) > -1 ? "clients_settings" : undefined}
                    backURL="clients"
                    menuOptions={clientMenuOptions}
                />
                <SideNavigation
                    key="side-navigation-clients"
                    title={"Client Sections"}
                    user={this.props.UserStore.me}
                    view={this.props.UIStore.view}
                    pages={clientPages || []}
                    userPermission={permissions.find(
                        this.props.ClientStore.working.id,
                        this.props.UserStore.permissions
                    )}
                    absolute={true}
                    top={"420px"}
                    viewPrefix="clients_"
                    urlPrefix={"clients/" + this.props.ClientStore.working.id + "/"}
                />

                <ClientHeader key="client-header" item={this.props.ClientStore.working} />
                {toRender}
            </div>
        );
    }
}

export default Client;
