export default {
    name: "Salesforce",
    type: "salesforce",
    logo: "/images/logos/salesforce.png",
    subsections: [
        {
            name: "API Connector",
            type: "api connector",
            icon: "compare_arrows",
            activeRules: [
                {
                    target: "integration",
                    field: "valid",
                    value: true
                }
            ],
            items: [
                {
                    field: "username",
                    label: "Username",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 3
                        }
                    ]
                },
                {
                    field: "password",
                    label: "Password",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 3
                        }
                    ]
                },
                {
                    field: "clientId",
                    label: "Client ID",
                    type: "text",
                    validation: [
                        {
                            type: "length",
                            value: 85
                        }
                    ]
                },
                {
                    field: "clientSecret",
                    label: "Client Secret",
                    type: "text",
                    validation: [
                        {
                            type: "length",
                            value: 64
                        }
                    ]
                }
            ]
        },
        {
            name: "Filters",
            type: "filters",
            icon: "filter_list",
            activeRules: [
                {
                    target: "integration",
                    field: "filters",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "filters",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Automatic workflows",
            type: "automatic workflows",
            icon: "wb_auto",
            activeRules: [
                {
                    target: "integration",
                    field: "autoworkflows",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "autoworkflows",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "SLA rules",
            type: "slas",
            icon: "av_timer",
            activeRules: [
                {
                    target: "integration",
                    field: "sla",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "sla",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Channels",
            type: "channels",
            icon: "call_split",
            activeRules: [
                {
                    target: "component",
                    field: "channels",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "channels",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Users for reassigning",
            type: "users",
            icon: "account_box",
            activeRules: [
                {
                    target: "component",
                    field: "clientAgents",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "clientAgents",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket fields",
            type: "ticket fields",
            icon: "radio_button_checked",
            activeRules: [
                {
                    target: "component",
                    field: "ticket_fields",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "ticket_fields",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket signature",
            type: "signature",
            icon: "create",
            activeRules: [
                {
                    target: "component",
                    field: "signature",
                    type: "exists"
                }
            ]
        }
    ],
    refreshableFields: [
        {
            text: "Salesforce users",
            value: "users"
        },
        {
            text: "Macros",
            value: "macros"
        },
        {
            text: "Check filters",
            value: "checkfilters"
        }
    ],
    submitOptions: ["new", "open", "pending", "hold", "solved"],
    ticketOptions: {
        merge: true,
        editTo: false,
        editCC: true,
        editBCC: false
    }
};
