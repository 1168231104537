import firebase from "./firebase";
import shopify from "./shopify";
import spree from "./spree";
import shipwire from "./shipwire";
import shipstation from "./shipstation";
import fulfil from "./fulfil";
import customapi from "./customapi";
import stripe from "./stripe";
import customemail from "./customemail";
import genericlogin from "./genericlogin";
import returnly from "./returnly";
import jiralink from "./jiralink";

export default [firebase, shopify, spree, shipwire, shipstation, fulfil, customapi, stripe, customemail, genericlogin, returnly, jiralink];
