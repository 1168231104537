import Reflux from "reflux";

var UserActions = Reflux.createActions([
    "login",
    "loginCompleted",
    "loginFailed",
    "loginWithGoogle",
    "verifyCode",
    "authenticateCompleted",
    "authenticateFailed",
    "resetPassword",
    "resetPasswordCompleted",
    "resetPasswordFailed",
    "logout",
    "startViewing",
    "stopViewing",
    "addEvent",
    "addAnalytics",
    "runAnalytics",
    "runAnalyticsCompleted",
    "loadUsersClients",
    "loadUsersClientsCompleted",
    "createNotification",
    "createNotificationCompleted",
    "get",
    "getApi",
    "clockIn",
    "clockOut",
    "update",
    "error",
]);

export default UserActions;
