import React from "react";

// Actions
import ClientActions from "../../actions/ClientActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";

// Form items
import Dropdown from "../_common/forms/Dropdown.react";
import Button from "../_common/buttons/Button.react";

const emptyStyle = {
	textAlign: "center",
	display: "inline-block",
	verticalAlign: "middle",
	margin: "24px 0px",
	width: "calc(100% - 48px - 32px)",
	padding: "24px",
	border: "1px dashed lightgrey",
	borderRadius: "4px"
};

const queueRulePrimaryStyle = {
	width: "100%",
	display: "inline-block",
	padding: "8px 0px"
};
const queueStyle = {
	width: "300px",
	marginRight: "8px",
	marginBottom: "0px"
};

const closeIconStyle = {
	marginLeft: "16px",
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer"
};

const emptyTextStyle = {
	fontSize: "24px",
	fontWeight: "400",
	marginBottom: "20px"
};

const integrationsStyle = {
	width: "100%",
	textAlign: "left",
	margin: "24px 0px",
	display: "inline-block"
};

const filterButtonContainerStyle = {
	margin: "16px 0px",
	width: "100%",
	textAlign: "center"
};

const queueFields = [
	{
		text: "Status",
		value: "status"
	},
	{
		text: "Priority",
		value: "priority"
	},
	{
		text: "Last Requester Update",
		value: "requester_updated"
	},
	{
		text: "SLA",
		value: "sla"
	},
	{
		text: "External SLA",
		value: "external_sla"
	}
];

const queueDirections = [
	{
		text: "Ascending",
		value: "asc"
	},
	{
		text: "Descending",
		value: "desc"
	}
];

class QueueOrderRule extends React.Component {
	constructor(props) {
		super(props);
		this._onRemoveRule = this._onRemoveRule.bind(this);
	}

	_updateFn(field, location, value) {
		ClientActions.setField(field, location, value, true);
	}

	_onRemoveRule() {
		ClientActions.remove([this.props.item.id]);
	}

	render() {
		return (
			<div style={queueRulePrimaryStyle}>
				<Dropdown
					id={"queue-type-" + this.props.index}
					style={queueStyle}
					value={this.props.item.type}
					location={[this.props.item.id]}
					field="type"
					label="Type"
					items={queueFields}
					updateFn={this._updateFn}
				/>
				<Dropdown
					id={"queue-direction-" + this.props.index}
					style={queueStyle}
					value={this.props.item.direction}
					location={[this.props.item.id]}
					field="direction"
					label="Direction"
					items={queueDirections}
					updateFn={this._updateFn}
				/>
				<i style={closeIconStyle} className="material-icons" onClick={this._onRemoveRule}>
					close
				</i>
			</div>
		);
	}
}

class QueueOrder extends React.Component {
	constructor(props) {
		super(props);
		this._onAddNewQueueRule = this._onAddNewQueueRule.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onAddNewQueueRule() {
		ClientActions.add("queueRules", undefined, {
			type: "requester_updated",
			direction: "asc"
		});
	}

	_onBack() {
		UIActions.show("clients_settings");
	}

	render() {
		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="-150px">
					<SectionCard title="QUEUE ORDER RULES" onBack={this._onBack}>
						<div>
							{this.props.item.queueRules === undefined || this.props.item.queueRules.length === 0 ? (
								<div style={emptyStyle}>
									<div style={emptyTextStyle}>There are currently no queue rules</div>
									<Button text="Add New Queue Rule" onClick={this._onAddNewQueueRule} />
								</div>
							) : (
								<div style={integrationsStyle}>
									{this.props.item.queueRules.map((item, index) => (
										<QueueOrderRule item={item} key={"queue-rule-" + index} index={index} />
									))}
									<div style={filterButtonContainerStyle}>
										<Button text="Add New Queue Rule" onClick={this._onAddNewQueueRule} />
									</div>
								</div>
							)}
						</div>
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default QueueOrder;
