/*global google*/
import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import Input from "../forms/Input.react";
import InputMultiline from "../forms/InputMultiline.react";

const address_components = [
	{
		type: "street_number",
		value: "street_number"
	},
	{
		type: "route",
		value: "street"
	},
	{
		type: "locality",
		value: "city"
	},
	{
		type: "administrative_area_level_1",
		value: "province"
	},
	{
		type: "country",
		value: "country"
	},
	{
		type: "postal_code",
		value: "postal_code"
	}
];

class GoogleLocationCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onAddressChange = this._onAddressChange.bind(this);
		this._onBlur = this._onBlur.bind(this);

		this.state = {
			text: this.props.item.prompts[0].text || "",
			value: "",
			valid: false,
			nextPath: 1
		};
	}

	componentDidMount() {
		var autocomplete = new google.maps.places.Autocomplete(
			document.getElementById("autocomplete-" + this.props.item.id)
		);

		// When the user selects an address from the dropdown, populate the address
		// fields in the form.
		var callback = this._onAddressChange;
		autocomplete.addListener("place_changed", function() {
			var place = autocomplete.getPlace();
			callback(place);
		});

		if ([undefined, null, ""].indexOf(this.state.value) === -1 && this.props.agentMode)
			this._onBlur("value", this.props.agentMode ? [this.props.response.id] : [this.props.item.id], this.state.value);
	}

	_onAddressChange(place) {
		if (place.name === "") {
			this.setState({ valid: false, nextPath: 1 });
			if (this.props.agentMode) WorkflowActions.setFieldResponse("address", [this.props.response.id], {}, true);
		} else {
			this.setState({ value: place.formatted_address, valid: true, nextPath: 0 });
			var newAddress = {};
			var j;
			for (var i = 0; i < place.address_components.length; i++) {
				for (j = 0; j < address_components.length; j++) {
					if (place.address_components[i].types.indexOf(address_components[j].type) > -1) {
						newAddress[address_components[j].value] = place.address_components[i].long_name;
						if (address_components[j].type === "country")
							newAddress.country_code = place.address_components[i].short_name;
					}
				}
			}

			if (this.props.agentMode) WorkflowActions.setFieldResponse("address", [this.props.response.id], newAddress, true);
		}
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const questionStyle = {
			marginBottom: "0px"
		};

		const responseStyle = {
			borderRadius: "10px",
			width: "calc(100% - 48px - 32px)",
			marginLeft: "24px"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				nextPath={this.state.nextPath}
			>
				<InputMultiline
					id={this.props.item.prompts[0].id}
					placeholder="Enter question text here..."
					value={this.state.text}
					field="text"
					location={[this.props.item.id, this.props.item.prompts[0].id]}
					onBlur={this._onBlur}
					noLine={true}
					style={questionStyle}
					readOnly={this.props.agentMode}
				/>
				<Input
					id={"autocomplete-" + this.props.item.id}
					label={"Search for location"}
					value={this.state.value}
					field="value"
					location={this.props.agentMode ? [this.props.response.id] : [this.props.item.id]}
					colored={true}
					style={responseStyle}
					updateFn={this._updateFn}
				/>
			</Card>
		);
	}
}

export default GoogleLocationCard;
