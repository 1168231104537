import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import UIActions from "../../actions/UIActions";

// Components
import Viewer from "./Viewer.react";

class Message extends React.Component {
	constructor(props) {
		super(props);
		this._onCloseMessage = this._onCloseMessage.bind(this);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this.state = { hover: false };
	}

	componentDidMount() {
		setTimeout(this._onCloseMessage, 5000);
	}

	_onCloseMessage() {
		UIActions.removeFeedUpdate(this.props.item.id);
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	render() {
		const primaryStyle = {
			position: "fixed",
			right: "10px",
			top: "calc(80px + 80px * " + this.props.index + ")",
			margin: "0px 0px 16px",
			padding: "16px",
			width: "calc(300px - 32px)",
			fontFamily: "Roboto",
			fontWeight: "400",
			fontSize: "16px",
			boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
			borderRadius: "2px",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : "white",
			transition: "all .3s ease",
			color: "rgba(0, 0, 0, 0.87)",
			textAlign: "left",
			display: "inline-block"
		};

		const textStyle = {
			width: "calc(100% - 74px)",
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "20px",
			marginLeft: "8px"
		};

		var client = {};
		for (var i = 0; i < this.props.clients.length; i++) {
			if (this.props.clients[i].id === this.props.item.clientId) {
				client = this.props.clients[i];
				break;
			}
		}

		return (
			<a
				style={primaryStyle}
				href={this.props.item.url}
				target="_blank"
				onClick={this._onCloseMessage}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				<Viewer item={client} />
				<div style={textStyle}>{this.props.item.text}</div>
			</a>
		);
	}
}

var parse_clients = clients => {
	var simpleClients = [];
	for (var i = 0; i < clients.length; i++) {
		simpleClients.push({
			id: clients[i].id,
			name: clients[i].name,
			picture: clients[i].picture
		});
	}

	return simpleClients;
};

class Messages extends React.Component {
	constructor(props) {
		super(props);
		this.state = { clients: parse_clients(this.props.clients || []), items: [] };
	}

	componentWillReceiveProps(nextProps) {
		var clients =
			nextProps.clients.length !== this.state.clients.length ? parse_clients(nextProps.clients) : this.state.clients;
		this.setState({ clients, items: nextProps.items });
	}

	componentDidMount() {
		this.setState({ items: this.props.items });
	}

	render() {
		const primaryStyle = {
			position: "fixed",
			zIndex: "4",
			right: "10px",
			top: "80px",
			width: "300px",
			height: "calc(100vh - 80px)"
		};

		return (
			<div style={primaryStyle}>
				<ReactCSSTransitionGroup
					key="message-transition-group"
					transitionName="message"
					transitionAppear={true}
					transitionLeave={true}
					transitionEnter={true}
					transitionEnterTimeout={1000}
					transitionAppearTimeout={1000}
					transitionLeaveTimeout={300}
				>
					{this.state.items.map((item, index, arr) => (
						<Message key={"message-" + item.id} item={item} index={index} clients={this.state.clients} />
					))}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
}

export default Messages;
