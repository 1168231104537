function url_creator(type, id) {
	const client = client_check();

	if (client !== null) return client + "/" + type + "/" + id;
	else return "#" + type + "/" + id;
}

function client_check() {
	const myRoute = document.location.hash.replace("#", "").split("/");
	if (myRoute[0] === "clients" && myRoute[1] !== undefined) return "#clients/" + myRoute[1];
	else return null;
}

export default url_creator;
