import * as list from "./list";

function agents(data, to, from) {
	if (!Array.isArray(data)) data = [data];

	var results = [];
	to = new Date(to);
	from = new Date(from);
	to.setUTCDate(to.getUTCDate() + 1);

	var month, myKeys, i, j, day, timestamp;
	for (i = 0; i < data.length; i++) {
		month = data[i];
		myKeys = Object.keys(month.days);

		for (j = 0; j < myKeys.length; j++) {
			day = month.days[myKeys[j]];
			day.id = myKeys[j];
			timestamp = new Date(month.id.replace(".", "/" + day.id + "/"));

			if (timestamp >= from && timestamp < to) results.push(day);
		}
	}

	var clients = [];

	for (i = 0; i < results.length; i++) {
		myKeys = Object.keys(results[i].clients);
		for (j = 0; j < myKeys.length; j++) {
			results[i].clients[myKeys[j]].clientId = myKeys[j];
			clients.push(results[i].clients[myKeys[j]]);
		}
	}

	var output = {
		n: list.sum(results, "n"),
		duration: list.sum(results, "duration"),
		escalations: list.sum(results, "escalations"),
		clients: []
	};

	clients = list.group(clients, "clientId");

	for (i = 0; i < clients.length; i++) {
		output.clients.push({
			clientId: clients[i].clientId,
			n: list.sum(clients[i].data, "n"),
			duration: list.sum(clients[i].data, "duration"),
			escalations: list.sum(clients[i].data, "escalations")
		});
	}

	return output;
}

export { agents };
