import React from "react";

// Actions
import WorkflowActions from "../../actions/WorkflowActions";
import UIActions from "../../actions/UIActions";

// Form items
import Dropdown from "../_common/forms/Dropdown.react";

// Functions
import m from "../../functions/m";

class CreatorDropdown extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
	}

	_updateFn(field, location, value) {
		if (value === "connect") {
			this.props.onCardConnect([this.props.from], this.props.fromIndex);
		} else if (value === "remove") {
			WorkflowActions.removePath([this.props.from], this.props.fromIndex);
		} else if (value === "add card") {
			var target = {
				coordinates: this.props.coordinates,
				fromCoordinates: this.props.fromCoordinates,
				from: this.props.from,
				fromIndex: this.props.fromIndex,
				direction: this.props.direction
			};

			UIActions.showOverlay("add card", target);
		}
	}

	render() {
		var options = [
			{
				text: "Add card",
				value: "add card",
				icon: "add"
			}
		];

		options = options.concat([
			{
				text: "Connect to Card",
				value: "connect",
				icon: "subdirectory_arrow_right",
				iconRotation: "-90"
			}
		]);

		if (this.props.last && this.props.fromIndex > 0) {
			options.push({ divider: true });
			options.push({
				text: "Remove path",
				value: "remove",
				icon: "delete_outline"
			});
		}

		const creatorStyle = {
			width: "200px"
		};

		return (
			<Dropdown
				id={"creator-dropdown-" + this.props.id}
				button={this.props.mini ? false : true}
				style={m(creatorStyle, this)}
				field="type"
				items={options}
				text={"Add New Card/Event"}
				updateFn={this._updateFn}
				onClick={this.props.onClick}
				maxListHeight="70vh"
				columns={1}
				mini={this.props.mini || false}
			/>
		);
	}
}

export default CreatorDropdown;
