export default {
    name: "Custom API",
    type: "customapi",
    category: "Other",
    icon: "data_usage",
    apiFields: [
        {
            field: "authentication",
            label: "Authentication Type",
            type: "dropdown",
            items: [
                {
                    text: "None",
                    value: "none"
                },
                {
                    text: "Authorization Header (no encryption)",
                    value: "authorization header no encryption"
                },
                {
                    text: "Custom header",
                    value: "custom header"
                },
                {
                    text: "URL key",
                    value: "url key"
                },
                {
                    text: "Basic Auth",
                    value: "basic auth"
                },
                {
                    text: "Firebase",
                    value: "firebase"
                },
                {
                    text: "Payload and get token",
                    value: "payload_then_token"
                }
            ]
        },
        {
            field: "authorizationHeader",
            label: "Authorization Header",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "authorization header no encryption"
                        }
                    ]
                }
            ]
        },
        {
            field: "authorizationHeader",
            label: "Authorization Header",
            type: "text",
            helpText: "Format as JSON",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "custom header"
                        }
                    ]
                }
            ]
        },
        {
            field: "url key",
            label: "URL Key",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "url key"
                        }
                    ]
                }
            ]
        },
        {
            field: "username",
            label: "Username",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "basic auth"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "firebase"
                        }
                    ]
                }
            ]
        },
        {
            field: "password",
            label: "Password",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "basic auth"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "firebase"
                        }
                    ]
                }
            ]
        },
        {
            field: "auth_url",
            label: "Authentication URL",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "payload_then_token"
                        }
                    ]
                }
            ]
        },
        {
            field: "payload",
            label: "Payload",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "payload_then_token"
                        }
                    ]
                }
            ]
        },
        {
            field: "apiKey",
            label: "apiKey",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "firebase"
                        }
                    ]
                }
            ]
        },
        {
            field: "authDomain",
            label: "authDomain",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "firebase"
                        }
                    ]
                }
            ]
        },
        {
            field: "projectId",
            label: "projectId",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "authentication",
                            state: "is",
                            value: "firebase"
                        }
                    ]
                }
            ]
        },
        {
            field: "url",
            label: "Url",
            type: "text"
        }
    ],
    cardFields: [
        {
            field: "integrationId",
            label: "Integration ID",
            type: "text"
        },
        {
            field: "url",
            label: "URL Addition",
            type: "text"
        },
        {
            field: "method",
            label: "Method",
            type: "dropdown",
            items: [
                {
                    text: "GET",
                    value: "GET"
                },
                {
                    text: "POST",
                    value: "POST"
                },
                {
                    text: "PUT",
                    value: "PUT"
                },
                {
                    text: "DELETE",
                    value: "DELETE"
                }
            ]
        },
        {
            field: "body",
            label: "Body",
            type: "text",
            displaylogic: [
                {
                    action: "appear",
                    rules: [
                        {
                            if: "method",
                            state: "is",
                            value: "POST"
                        }
                    ]
                },
                {
                    action: "appear",
                    rules: [
                        {
                            if: "method",
                            state: "is",
                            value: "PUT"
                        }
                    ]
                }
            ]
        },
        {
            field: "responseType",
            label: "Response Type",
            type: "dropdown",
            items: [
                {
                    text: "String",
                    value: "string"
                },
                {
                    text: "JSON",
                    value: "json"
                }
            ]
        }
    ]
};
