import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Overlay from "./Overlay.react";
import Container from "./Container.react";

// Buttons
import Button from "./buttons/Button.react";

const cardStyle = {
	width: "100%",
	padding: "0px",
	maxHeight: "85vh",
	overflowY: "auto"
};

const buttonRowStyle = {
	width: "calc(100% - 32px)",
	padding: "16px",
	borderTop: "1px solid lightgrey",
	textAlign: "right",
	display: "inline-block"
};

const buttonStyle = {
	marginLeft: "8px"
};

const menuContainerStyle = {
	display: "inline-block",
	borderBottom: "1px solid lightgrey",
	width: "100%"
};

const bodyContainerStyle = {
	display: "inline-block",
	padding: "0px 16px",
	width: "calc(100% - 32px)",
	backgroundColor: "rgba(0, 0, 0, 0.07)",
	textAlign: "center",
	minHeight: "50vh"
};

class MenuOption extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onSectionChange(this.props.item.value);
	}

	render() {
		const primaryStyle = {
			padding: "16px",
			fontSize: "24px",
			color: this.props.selected || this.state.hover ? "rgb(70, 180, 175)" : "inherit",
			borderBottom: this.props.selected || this.state.hover ? "3px solid rgb(70, 180, 175)" : "3px solid transparent",
			marginLeft: "16px",
			transition: "all .3s ease",
			cursor: "pointer",
			display: "inline-block"
		};

		return (
			<div style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				{this.props.item.text}
			</div>
		);
	}
}

class SettingsScaffold extends React.Component {
	constructor(props) {
		super(props);
		this._onClose = this._onClose.bind(this);
	}

	_onClose() {
		UIActions.showOverlay("");
		if (this.props.actions !== undefined) {
			if (this.props.save) this.props.actions.save();
			this.props.actions.update();
		}
	}

	render() {
		return (
			<Overlay>
				<Container maxWidth="1100px" verticalAlign={"top"} marginTop={this.props.marginTop || "7vh"}>
					<Card style={cardStyle}>
						<div style={menuContainerStyle}>
							{this.props.menuOptions.map((item, index) => (
								<MenuOption
									key={"menu-option-" + index}
									item={item}
									selected={this.props.section === item.value}
									index={index}
									onSectionChange={this.props.onSectionChange}
								/>
							))}
						</div>
						<div style={bodyContainerStyle}>{this.props.children}</div>
						<div style={buttonRowStyle}>
							<Button text={"Close"} style={buttonStyle} onClick={this._onClose} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default SettingsScaffold;
