import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";
import InputMultiline from "../forms/InputMultiline.react";
import Input from "../forms/Input.react";
import Toggle from "../forms/Toggle.react";
import UIActions from "../../../actions/UIActions";

const questionStyle = {
	marginBottom: "0px"
};

const toggleLabelStyle = {
	fontSize: "12px",
	marginRight: "8px",
	display: "inline-block",
	verticalAlign: "middle",
	fontWeight: "400",
	color: "rgba(0, 0, 0, 0.87)",
	marginTop: "4px"
};

const toggleRowStyle = {
	width: "calc(100% - 16px)",
	display: "inline-block",
	textAlign: "right",
	padding: "0px 8px 16px"
};

const centerRowStyle = {
	width: "100%",
	textAlign: "center",
	display: "inline-block"
};

const valueContainerStyle = {
	display: "inline-block",
	border: "2px dashed lightgrey",
	margin: "16px 0px 32px",
	padding: "24px 32px",
	fontSize: "20px",
	cursor: "pointer"
};

class ProductsCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this._onOpenSearch = this._onOpenSearch.bind(this);

		var itemsLength = this.props.agentMode ? this.props.response.value || [] : this.props.item.value;

		this.state = {
			text: this.props.item.prompts[0].text || "",
			source: this.props.item.source || "",
			sourceConcept: this.props.item.sourceConcept || "",
			fields: this.props.item.fields,
			valid: itemsLength.length > 0,
			nextPath: itemsLength.length > 0 ? 0 : 1
		};
	}

	componentDidUpdate() {
		var itemsLength = this.props.agentMode ? this.props.response.value || [] : this.props.item.value;

		if (itemsLength.length > 0 && !this.state.valid) this.setState({ valid: true, nextPath: 0 });
		else if (itemsLength.length === 0 && this.state.valid) this.setState({ valid: false, nextPath: 1 });
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onBlur(field, location, value) {
		this.setState({ [field]: value });

		if (this.props.agentMode) WorkflowActions.setFieldResponse(field, location, value, true);
		else WorkflowActions.setField(field, location, value, true);
	}

	_onOpenSearch() {
		this.props.item.location = this.props.agentMode ? [this.props.response.id] : [this.props.item.id];
		this.props.item.value = this.props.agentMode ? this.props.response.value || [] : this.props.item.value;
		UIActions.showOverlay("product search", this.props.item);
	}

	render() {
		var itemsLength = this.props.agentMode ? this.props.response.value || [] : this.props.item.value;

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={this.state.valid}
				nextPath={this.state.nextPath}
			>
				<InputMultiline
					id={this.props.item.prompts[0].id}
					placeholder="Enter question text here..."
					value={this.state.text}
					field="text"
					location={[this.props.item.id, this.props.item.prompts[0].id]}
					onBlur={this._onBlur}
					noLine={true}
					style={questionStyle}
					readOnly={this.props.agentMode}
				/>
				{!this.props.agentMode ? (
					<Input
						id={this.props.item.id + "-source"}
						field="source"
						value={this.state.source}
						updateFn={this._updateFn}
						onBlur={this._onBlur}
						location={[this.props.item.id]}
						label="Product Catalog ID"
					/>
				) : (
					""
				)}
				{!this.props.agentMode ? (
					<Input
						id={this.props.item.id + "-sourceConcept"}
						field="sourceConcept"
						value={this.state.sourceConcept}
						updateFn={this._updateFn}
						onBlur={this._onBlur}
						location={[this.props.item.id]}
						label="Load from concept"
						helpText="Don't include the {{ }}"
					/>
				) : (
					""
				)}
				{!this.props.agentMode ? (
					<Input
						id={this.props.item.id + "-fields"}
						field="fields"
						value={this.state.fields}
						updateFn={this._onBlur}
						location={[this.props.item.id]}
						label="Search fields"
						helpText={"Separate fields by comma"}
					/>
				) : (
					""
				)}
				{!this.props.agentMode ? (
					<div style={toggleRowStyle}>
						<div style={toggleLabelStyle}>
							{this.props.item.showSubitems ? "Will show subitems" : "Won't show subitems"}
						</div>
						<Toggle
							id={"showSubitems-" + this.props.item.id}
							field="showSubitems"
							location={[this.props.item.id]}
							updateFn={this._onBlur}
							value={this.props.item.showSubitems || false}
						/>
					</div>
				) : (
					""
				)}
				<div style={centerRowStyle}>
					<div style={valueContainerStyle} onClick={this._onOpenSearch}>
						{itemsLength.length > 0 ? itemsLength.length + " items selected" : "Click me to add items"}
					</div>
				</div>
			</Card>
		);
	}
}

export default ProductsCard;
