import React from "react";

// Charts
import { Chart } from "react-google-charts";

// Actions
import UserActions from "../../actions/UserActions";
import UIActions from "../../actions/UIActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import Card from "../_common/Card.react";
import Button from "../_common/buttons/Button.react";
import DataTag from "../_common/DataTag.react";
import H2 from "../_common/headers/H2.react";
import H1 from "../_common/headers/H1.react";

// Form items
import Datepicker from "../_common/forms/Datepicker.react";
import Dropdown from "../_common/forms/Dropdown.react";

// Functions
import * as time from "../../functions/time";
import * as state from "../../functions/state";

const typeDropdownStyle = {
    width: "200px"
};

const dateRangeStyle = {
    width: "180px",
    marginLeft: "16px",
    display: "inline-block"
};

const runStyle = {
    marginLeft: "16px"
};

const coverStyle = {
    height: "100vh",
    width: "100%",
    backgroundImage: "url(/images/spring.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    textAlign: "center",
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: "-1"
};

const greetingStyle = {
    display: "inline-block",
    fontSize: "80px",
    verticalAlign: "middle",
    color: "black"
};

const vertAligner = {
    height: "60vh",
    display: "inline-block",
    verticalAlign: "middle"
};

const cardFlexSyle = {
    width: "auto",
    padding: "16px"
};

const cardTransparentStyle = {
    width: "1000px",
    padding: "16px",
    display: "inline-block",
    textAlign: "left"
};

const stateFields = [];

const timeOptions = [
    {
        text: "Last 24 hours",
        value: "24h"
    },
    {
        text: "This month",
        value: "month"
    },
    {
        text: "Custom time range",
        value: "custom"
    }
];

const pieOptions = {
    title: "",
    pieHole: 0.6,
    slices: [
        {
            color: "orange"
        },
        {
            color: "rgb(70, 180, 175)"
        },
        {
            color: "#007fad"
        },
        {
            color: "#e9a227"
        }
    ],
    legend: {
        position: "bottom",
        alignment: "center",
        textStyle: {
            color: "233238",
            fontSize: 14
        }
    },
    tooltip: {
        showColorCode: true
    },
    chartArea: {
        left: 0,
        top: 0,
        width: "100%",
        height: "80%"
    },
    fontName: "Roboto"
};

const dateLineOptions = {
    colors: ["purple", "rgb(70, 180, 175)", "orange"],
    vAxis: {
        title: "# of Tickets"
    }
};

const durationLineOptions = {
    colors: ["rgb(70, 180, 175)", "purple", "rgb(70, 180, 175)", "orange"],
    vAxis: {
        title: "Duration (in minutes)"
    }
};

const cardStyle = {
    width: "calc(100% - 32px)",
    padding: "16px",
    margin: "8px 0px"
};

class ManagerHome extends React.Component {
    constructor(props) {
        super(props);
        this._onUpdate = this._onUpdate.bind(this);
        this._onRun = this._onRun.bind(this);
        this._onDateRangeUpdated = this._onDateRangeUpdated.bind(this);

        var to = new Date();
        var from = new Date();
        from.setUTCDate(from.getUTCDate() - 1);

        var toMonth = to.getUTCMonth() + 1 > 9 ? to.getUTCMonth() + 1 : "0" + (to.getUTCMonth() + 1);
        var fromMonth = from.getUTCMonth() + 1 > 9 ? from.getUTCMonth() + 1 : "0" + (from.getUTCMonth() + 1);

        var toDay = to.getUTCDate() > 9 ? to.getUTCDate() : "0" + to.getUTCDate();
        var fromDay = from.getUTCDate() > 9 ? from.getUTCDate() : "0" + from.getUTCDate();

        to = to.getUTCFullYear() + "-" + toMonth + "-" + toDay;
        from = from.getUTCFullYear() + "-" + fromMonth + "-" + fromDay;

        var myState = {
            scroll: 0,
            type: "24h",
            analytics: {
                to: to,
                from: from
            },
            loading: false
        };

        myState = state.set(myState, stateFields, this.props.item);

        this.state = myState;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status.indexOf("running analytics") === -1 && this.state.loading) this.setState({ loading: false });
        else if (nextProps.status.indexOf("running analytics") > -1 && !this.state.loading) this.setState({ loading: true });
    }

    _onUpdate(field, location, value) {
        this.setState({ [field]: value });
    }

    _onDateRangeUpdated(field, location, value) {
        if (field === "to")
            this.setState({
                analytics: {
                    to: value,
                    from: this.state.analytics.from
                }
            });
        else
            this.setState({
                analytics: {
                    to: this.state.analytics.to,
                    from: value
                }
            });
    }

    _onRun() {
        if (this.state.type === "custom" && new Date(this.state.analytics.to) < new Date(this.state.analytics.from))
            UIActions.addMessage("Your from date cannot be later than your to date.", "error");
        else {
            var message = { analyticsType: "company" };
            var now = new Date();
            if (this.state.type === "24h") {
                now = now.setDate(now.getDate() - 1);
                now = new Date(now);
                message = {
                    type: this.state.type,
                    from: Number(now),
                    to: Number(new Date())
                };
            } else if (this.state.type === "month") {
                var month = now.getUTCMonth() + 1 > 9 ? now.getUTCMonth() + 1 : "0" + (now.getUTCMonth() + 1);

                message = {
                    type: this.state.type,
                    from: time.get_time(now.getUTCFullYear() + "-" + String(month) + "-01", "America/Los_Angeles"),
                    to: new Date()
                };
            } else if (this.state.type === "custom") {
                message = {
                    type: this.state.type,
                    from: time.get_time(this.state.analytics.from, "America/Los_Angeles"),
                    to: time.get_time(this.state.analytics.to, "America/Los_Angeles") + 24 * 60 * 60 * 1000
                };
            }

            UserActions.runAnalytics(message);
            this.setState({ loading: true });
        }
    }

    render() {
        const submittedBarOptions = {
            colors: ["rgb(70, 180, 175)", "orange"],
            hAxis: {
                title: "# of Tickets Submitted",
                minValue: 0
            },
            vAxis: {
                title: "Agent"
            },
            legend: { position: "none" },
            chartArea:
                this.props.item.customAnalytics !== undefined && this.props.item.customAnalytics.submitByUser !== undefined
                    ? { top: 8, height: (this.props.item.customAnalytics.submitByUser.length - 1) * 60 }
                    : {}
        };

        const durationBarOptions = {
            colors: ["rgb(70, 180, 175)", "orange"],
            hAxis: {
                title: "Duration (in minutes)",
                minValue: 0
            },
            vAxis: {
                title: "Agent"
            },
            legend: { position: "none" },
            titleTextStyle: {
                italic: false,
                fontSize: 14
            },
            chartArea:
                this.props.item.customAnalytics !== undefined && this.props.item.customAnalytics.timeByUser !== undefined
                    ? { top: 8, height: (this.props.item.customAnalytics.timeByUser.length - 1) * 60 }
                    : {}
        };

        const solvedByUserBarOptions = {
            colors: ["rgb(70, 180, 175)", "orange", "cornflowerblue"],
            hAxis: {
                title: "Number of tickets or events",
                minValue: 0
            },
            vAxis: {
                title: "Agent"
            },
            legend: { position: "none" },
            titleTextStyle: {
                italic: false,
                fontSize: 14
            },
            chartArea:
                this.props.item.customAnalytics !== undefined && this.props.item.customAnalytics.solvedByUser !== undefined
                    ? { top: 8, height: (this.props.item.customAnalytics.solvedByUser.length - 1) * 60 * 1.5 }
                    : {}
        };

        const submittedBarClientOptions = {
            colors: ["purple", "rgb(70, 180, 175)", "orange"],
            hAxis: {
                title: "# of Tickets Submitted",
                minValue: 0
            },
            vAxis: {
                title: "Agent"
            },
            legend: { position: "none" },
            chartArea:
                this.props.item.customAnalytics !== undefined && this.props.item.customAnalytics.submitByClient !== undefined
                    ? { top: 8, height: (this.props.item.customAnalytics.submitByClient.length - 1) * 60 * 1.5 }
                    : {}
        };

        const durationBarClientOptions = {
            colors: ["rgb(70, 180, 175)", "orange"],
            hAxis: {
                title: "Duration (in minutes)",
                minValue: 0
            },
            vAxis: {
                title: "Agent"
            },
            legend: { position: "none" },
            titleTextStyle: {
                italic: false,
                fontSize: 14
            },
            chartArea:
                this.props.item.customAnalytics !== undefined && this.props.item.customAnalytics.timeByClient !== undefined
                    ? { top: 8, height: (this.props.item.customAnalytics.timeByClient.length - 1) * 60 * 1 }
                    : {}
        };

        return [
            <div key="home-cover" style={coverStyle} onMouseEnter={this._onCoverMouseOver} onMouseLeave={this._onCoverMouseOut}>
                <div style={vertAligner} />
                <div style={greetingStyle}>
                    Oh hi, <b>{this.props.user.name.split(" ")[0]}!</b>
                </div>
            </div>,
            <OuterScaffold key="home-outer-scaffold" spaceForHeader={true} verticalHeight="90vh">
                <Container maxWidth="1000px" menuSpace={true} verticalAlign="top" marginTop="30vh">
                    <div style={cardTransparentStyle}>
                        <Dropdown
                            id="analytics-client-picker"
                            value={this.state.type}
                            items={timeOptions}
                            updateFn={this._onUpdate}
                            field={"type"}
                            label="Time Range"
                            style={typeDropdownStyle}
                        />
                        {this.state.type === "custom" ? (
                            <Datepicker
                                id={"analytics-from"}
                                style={dateRangeStyle}
                                field="from"
                                label="From"
                                value={this.state.analytics.from || ""}
                                updateFn={this._onDateRangeUpdated}
                                onBlur={this._onBlur}
                            />
                        ) : (
                            ""
                        )}
                        {this.state.type === "custom" ? (
                            <Datepicker
                                id={"analytics-to"}
                                style={dateRangeStyle}
                                field="to"
                                label="To"
                                value={this.state.analytics.to || ""}
                                updateFn={this._onDateRangeUpdated}
                                onBlur={this._onBlur}
                            />
                        ) : (
                            ""
                        )}
                        <Button style={runStyle} text="Run" onClick={this._onRun} loading={this.state.loading} />
                    </div>
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? <H1 text="Company Summary" /> : ""}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <div>
                                <DataTag
                                    label="Created Tickets"
                                    value={this.props.item.customAnalytics.totals.created}
                                    color="purple"
                                    width="200px"
                                />
                                <DataTag
                                    label="Submitted Tickets"
                                    value={this.props.item.customAnalytics.totals.submits}
                                    color="rgb(70, 180, 175)"
                                    width="200px"
                                />
                                <DataTag
                                    label="Solved Tickets"
                                    value={this.props.item.customAnalytics.totals.solved}
                                    color="orange"
                                    width="200px"
                                />
                                <DataTag
                                    label="Solved Duration"
                                    value={Math.round(this.props.item.customAnalytics.totals.duration * 100) / 100 + "m"}
                                    color="indianred"
                                    width="200px"
                                />
                            </div>
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Tickets Over Time" textAlign="center" />
                            <Chart
                                chartType="LineChart"
                                data={this.props.item.customAnalytics.timeChart}
                                options={dateLineOptions}
                                width="100%"
                                height="400px"
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Average Duration (in minutes) of Solved Tickets Over Time" textAlign="center" />
                            <Chart
                                chartType="LineChart"
                                data={this.props.item.customAnalytics.timeDurationChart}
                                options={durationLineOptions}
                                width="100%"
                                height="400px"
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Tickets Solved by HiOperator versus Other" textAlign="center" />
                            <Chart
                                chartType="PieChart"
                                data={this.props.item.customAnalytics.hioperatorVersus}
                                options={pieOptions}
                                width="100%"
                                height="300px"
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Tickets Submitted by Agent" textAlign="center" />
                            <Chart
                                chartType="BarChart"
                                data={this.props.item.customAnalytics.submitByUser}
                                options={submittedBarOptions}
                                width="100%"
                                height={(this.props.item.customAnalytics.submitByUser.length - 1) * 60 + 80 + "px"}
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Average Duration (in minutes) of Each Submit by Agent" textAlign="center" />
                            <Chart
                                chartType="BarChart"
                                data={this.props.item.customAnalytics.timeByUser}
                                options={durationBarOptions}
                                width="100%"
                                height={(this.props.item.customAnalytics.timeByUser.length - 1) * 60 + 80 + "px"}
                            />
                        </Card>
                    ) : (
                        ""
                    )}

                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Solved Tickets By User" textAlign="center" />
                            <Chart
                                chartType="BarChart"
                                data={this.props.item.customAnalytics.solvedByUser}
                                options={solvedByUserBarOptions}
                                width="100%"
                                height={(this.props.item.customAnalytics.solvedByUser.length - 1) * 60 * 1.5 + 80 + "px"}
                            />
                        </Card>
                    ) : (
                        ""
                    )}

                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Tickets Submitted and Solved by Client" textAlign="center" />
                            <Chart
                                chartType="BarChart"
                                data={this.props.item.customAnalytics.submitByClient}
                                options={submittedBarClientOptions}
                                width="100%"
                                height={(this.props.item.customAnalytics.submitByClient.length - 1) * 60 * 1.5 + 80 + "px"}
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                    {this.props.item !== undefined && this.props.item.customAnalytics !== undefined ? (
                        <Card style={cardStyle}>
                            <H2 text="Average Duration (in minutes) of Each Submit and Solved Ticket by Client" textAlign="center" />
                            <Chart
                                chartType="BarChart"
                                data={this.props.item.customAnalytics.timeByClient}
                                options={durationBarClientOptions}
                                width="100%"
                                height={(this.props.item.customAnalytics.timeByClient.length - 1) * 60 + 80 + "px"}
                            />
                        </Card>
                    ) : (
                        ""
                    )}
                </Container>
            </OuterScaffold>
        ];
    }
}

export default ManagerHome;
