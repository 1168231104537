import React from "react";

// Actions
import MacroActions from "../../../../actions/MacroActions";
import UIActions from "../../../../actions/UIActions";

// Scaffolding
import Card from "../../Card.react";
import Container from "../../Container.react";
import Overlay from "../../Overlay.react";

// Form items
import Input from "../../forms/Input.react";
import Button from "../../buttons/Button.react";

// Headers
import H1 from "../../headers/H1.react";

// Functions
import validate from "../../../../functions/validate";

// Sets validation
const nameValidation = [
	{
		type: "min-length",
		value: 4
	}
];

class NewMacro extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onClick = this._onClick.bind(this);

		this.state = { title: "", description: "", validate: false };
	}

	componentDidMount() {
		document.getElementById("title").focus();
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ loading: nextProps.status.indexOf("creating macro") > -1 });
	}

	_onClick() {
		var nameError = validate(nameValidation, this.state.title);

		if (nameError === "") {
			this.setState({ validate: false });
			MacroActions.new({ title: this.state.title, body: "", active: true });
		} else this.setState({ validate: true });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value, validate: false });
	}

	render() {
		const cardStyle = {
			width: "calc(100% - 48px)",
			padding: "24px",
			textAlign: "center"
		};

		const rowStyle = {
			width: "100%",
			textAlign: "right",
			margin: "16px 0px 0px 0px"
		};

		const buttonStyle = {
			marginLeft: "8px"
		};

		return (
			<Overlay>
				<Container maxWidth="600px" key="new-workflow-container">
					<Card style={cardStyle}>
						<H1 text="Add new preset email response" textAlign="center" />
						<Input
							id="title"
							label="Preset email response title"
							field="title"
							updateFn={this._updateFn}
							validate={this.state.validate}
							validation={nameValidation}
							onReturn={this._onClick}
							errors={this.props.errors}
						/>
						<div style={rowStyle}>
							<Button text="Nevermind" onClick={this._onClose} type="flat" />
							<Button text="NEXT" style={buttonStyle} onClick={this._onClick} loading={this.state.loading} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default NewMacro;
