import React from "react";
import config from "../../../config.public";

// Actions
import UserActions from "../../../actions/UserActions";
import UIActions from "../../../actions/UIActions";

// Scaffolding
import OuterScaffold from "../OuterScaffold.react";
import Card from "../Card.react";
import Container from "../Container.react";

// Form items
import Input from "../forms/Input.react";
import Button from "../buttons/Button.react";

// Functions
import validate from "../../../functions/validate";

// Sets validation
const emailValidation = [
    {
        type: "min-length",
        value: 6,
    },
    {
        type: "contains",
        value: "@",
    },
    {
        type: "contains",
        value: ".",
    },
];

const passwordValidation = [
    {
        type: "min-length",
        value: 8,
    },
];

const twoFactorValidation = [
    {
        type: "length",
        value: 6,
    },
];

const googleTextStyle = {
    color: "white",
    fontSize: "14px",
    padding: "12px 12px",
    float: "left",
    width: "calc(100% - 66px)",
};

const googleImageStyle = {
    height: "18px",
    float: "left",
    verticalAlign: "middle",
    backgroundColor: "white",
    padding: "11px 12px",
};

class GoogleLogin extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);

        this.state = { hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    render() {
        const googleButtonStyle = {
            width: "100%",
            maxWidth: "300px",
            display: "inline-block",
            margin: "16px 0px",
            boxShadow: this.state.hover ? "0 0 3px 3px rgba(66,133,244,.3)" : "0 2px 4px 0 rgba(0,0,0,.25)",
            textAlign: "left",
            cursor: "pointer",
            border: "1px solid #4285F4",
            backgroundColor: "#4285F4",
            borderRadius: "2px",
            transition: "all .3s ease",
        };

        return (
            <div
                style={googleButtonStyle}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                onClick={() => UserActions.loginWithGoogle()}
            >
                <img style={googleImageStyle} src="/images/google_g_logo.png" alt="Google" />
                <div style={googleTextStyle}>Sign in with Google</div>
            </div>
        );
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this._updateFn = this._updateFn.bind(this);
        this._onClick = this._onClick.bind(this);

        this.state = {
            email: "",
            password: "",
            verificationCode: "",
            loading: this.props.status.indexOf("logging in") > -1,
            validate: false,
        };
    }

    componentDidMount() {
        if (config.registration.allowFirebaseLogin) document.getElementById("email").focus();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ loading: nextProps.status.indexOf("logging in") > -1 });
    }

    _onClick() {
        if (this.props.UserStore.verificationId !== null) {
            var verificationError = validate(twoFactorValidation, this.state.verificationCode);

            if (verificationError === "") {
                this.setState({ loading: true, validate: false });
                UserActions.verifyCode({ verificationCode: this.state.verificationCode });
            } else this.setState({ validate: true });

            return;
        }

        var emailError = validate(emailValidation, this.state.email);
        var passwordError = validate(passwordValidation, this.state.password);

        if (emailError === "" && passwordError === "") {
            this.setState({ loading: true, validate: false });
            UserActions.login({ email: this.state.email, password: this.state.password });
        } else this.setState({ validate: true });
    }

    _updateFn(field, location, value) {
        this.setState({ [field]: value, validate: false });
    }

    _onEmailReturn() {
        document.getElementById("password").focus();
    }

    _onForgotPassword() {
        UIActions.show("reset password");
    }

    render() {
        const cardStyle = {
            width: "calc(100% - 32px)",
            padding: "16px",
            textAlign: "center",
        };

        const rowStyle = {
            width: "100%",
            textAlign: "right",
            margin: "16px 0px 0px 0px",
        };

        const logoStyle = {
            height: "100px",
            marginBottom: "30px",
        };

        const forgotStyle = {
            marginRight: "16px",
        };

        return (
            <OuterScaffold>
                <Container maxWidth="500px">
                    <img src={"/images/logo_white_bg@2x.png"} style={logoStyle} alt="" />
                    {config.registration.allowFirebaseLogin ? (
                        <Card style={cardStyle}>
                            {this.props.UserStore.verificationId === null ? (
                                <div>
                                    <Input
                                        id="email"
                                        label="Email"
                                        field="email"
                                        updateFn={this._updateFn}
                                        validate={this.state.validate}
                                        validation={emailValidation}
                                        onReturn={this._onEmailReturn}
                                        value={this.state.email}
                                        errors={this.props.errors}
                                    />
                                    <Input
                                        id="password"
                                        label="Password"
                                        type="password"
                                        field="password"
                                        updateFn={this._updateFn}
                                        validate={this.state.validate}
                                        validation={passwordValidation}
                                        value={this.state.password}
                                        onReturn={this._onClick}
                                        errors={this.props.errors}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <Input
                                        id="verificationCode"
                                        key="verificationCode"
                                        label="Two Factor Code"
                                        field="verificationCode"
                                        updateFn={this._updateFn}
                                        validate={this.state.validate}
                                        validation={twoFactorValidation}
                                        onReturn={this._onClick}
                                        value={this.state.verificationCode}
                                        errors={this.props.errors}
                                    />
                                </div>
                            )}

                            <div style={rowStyle}>
                                <div id="recaptcha-container" style={{ display: "inline-block" }}></div>

                                <Button text="Forgot password" style={forgotStyle} onClick={this._onForgotPassword} type="flat" />
                                <Button
                                    text={this.props.UserStore.verificationId === null ? "LOGIN" : "SUBMIT CODE"}
                                    onClick={this._onClick}
                                    loading={this.state.loading}
                                />
                            </div>
                        </Card>
                    ) : (
                        ""
                    )}

                    {config.registration.allowGoogle ? <GoogleLogin /> : ""}
                </Container>
            </OuterScaffold>
        );
    }
}

export default Login;
