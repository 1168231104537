import React from "react";

// Scaffolding
import Card from "../_common/Card.react";
import Button from "../_common/buttons/Button.react";
import Search from "../_common/Search.react";

import SectionSelector from "../_common/SectionSelector.react";

// Functions
import * as objects from "../../functions/objects";

var get_items = (client, location) => {
    var components = (client || {}).components || [];
    var obj = components.find(item => item.id === "triageoptions");

    if (obj === undefined) return [];
    var newObj = objects.find(obj, null, location.concat());
    if (newObj) {
        return newObj.obj.options;
    }
};

var get_flat_items = client => {
    var components = (client || {}).components || [];
    var obj = components.find(item => item.id === "triageoptions");

    if (obj === undefined) return [];
    var items = [];
    obj = JSON.parse(JSON.stringify(obj));
    obj.options.forEach(s => add_to_flat_items(s, items, ""));

    return items;
};

var add_to_flat_items = (obj, items, pretext) => {
    if (obj.options.length === 0 && obj.actions.length > 0) {
        obj.text = pretext + obj.text;
        items.push(obj);
    } else if (obj.options.length > 0) {
        obj.options.forEach(s => add_to_flat_items(s, items, pretext + obj.text + " > "));
    }
};

var now = new Date();

const searchStyle = {
    width: "calc(100%)"
};

class TriageSearchResult extends React.Component {
    constructor(props) {
        super(props);
        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseOut = this._onMouseOut.bind(this);
        this._onClick = this._onClick.bind(this);
        this.state = { hover: false };
    }

    _onMouseOver() {
        this.setState({ hover: true });
    }

    _onMouseOut() {
        this.setState({ hover: false });
    }

    _onClick() {
        this.props.onClick(this.props.item);
    }

    render() {
        const primaryStyle = {
            fontSize: "16px",
            fontFamily: "Roboto",
            fontWeight: "400",
            width: "calc(100% - 32px)",
            backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
            transition: "all 0.3s ease",
            cursor: "pointer",
            padding: "16px"
        };

        var text = this.props.item.text;
        var re = new RegExp(this.props.searchTerm, "g");
        text = text.replace(re, "<b>" + this.props.searchTerm + "</b>");

        if (now < new Date(this.props.item.updated))
            text +=
                '<span style="font-size: 10px; padding: 4px; border-radius: 4px; background-color: lightgreen; border: 1px solid grey; margin-left: 8px; vertical-align: middle">Newly Updated</span>';

        return (
            <li
                style={primaryStyle}
                onMouseOver={this._onMouseOver}
                onMouseOut={this._onMouseOut}
                onClick={this._onClick}
                dangerouslySetInnerHTML={{
                    __html: text
                }}
            ></li>
        );
    }
}

class TriageWorkflow extends React.Component {
    constructor(props) {
        super(props);
        this._onSelect = this._onSelect.bind(this);
        this._onNone = this._onNone.bind(this);
        this._onSearchSelect = this._onSearchSelect.bind(this);

        now = new Date();
        now = new Date(now.setHours(now.getHours() - 24 * 3));

        this.state = {
            location: [],
            items: get_items(this.props.client, []),
            flatItems: get_flat_items(this.props.client)
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: get_items(nextProps.client, this.state.location),
            flatItems: get_flat_items(nextProps.client)
        });
    }

    _onSelect(field, location, value) {
        if (value === "go back") {
            var newLocation = this.state.location.concat();
            newLocation.splice(newLocation.length - 1, 1);
            this.setState({ items: get_items(this.props.client, newLocation), location: newLocation });
        } else {
            var obj = this.state.items.find(item => item.id === value);
            if (obj.options.length > 0)
                this.setState({ items: obj.options, location: this.state.location.concat(obj.id) });
            else this.props.onRunTriageActions(obj.actions);
        }
    }

    _onSearchSelect(obj) {
        this.props.onRunTriageActions(obj.actions);
    }

    _onNone() {
        this.props.onRunTriageActions([]);
    }

    render() {
        const primaryStyle = {
            width: "calc(100% - 32px)",
            display: "inline-block",
            padding: "16px",
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "16px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            borderTop: "1px solid rgb(70, 180, 175)",
            textAlign: "center"
        };

        const innerStyle = {
            width: "100%",
            maxWidth: "600px",
            display: "inline-block",
            textAlign: "left"
        };

        const questionStyle = {
            width: "100%",
            padding: "16px 0px 24px",
            fontSize: "18px",
            display: "inline-block",
            fontWeight: "700"
        };

        const buttonRowStyle = {
            width: "100%",
            padding: "16px 0px 8px",
            textAlign: "center"
        };

        return (
            <Card style={primaryStyle}>
                <div style={innerStyle}>
                    <div style={questionStyle}>What is the customer writing in about?</div>
                    <Search
                        placeholder={"Search situations..."}
                        id={"triage-workflow-search"}
                        onSelect={this._onSearchSelect}
                        type={this.props.type}
                        min={1000}
                        fields={["text"]}
                        items={this.state.flatItems}
                        style={searchStyle}
                        resultComponent={TriageSearchResult}
                    />
                    {this.state.location.length > 0 ? (
                        <SectionSelector
                            key={"selector-go-back"}
                            text={"Go back"}
                            index={0}
                            onClick={this._onSelect}
                            value={"go back"}
                            reverseArrow={true}
                        />
                    ) : (
                        ""
                    )}
                    {this.state.items.map((item, index) => (
                        <SectionSelector
                            key={item.id}
                            text={item.text}
                            index={this.state.location.length === 0 ? index : index + 1}
                            onClick={this._onSelect}
                            value={item.id}
                            recentlyUpdated={now < new Date(item.updated)}
                        />
                    ))}
                    <div style={buttonRowStyle}>
                        <Button text="None of the above" type="flat" onClick={this._onNone} />
                    </div>
                </div>
            </Card>
        );
    }
}

export default TriageWorkflow;
