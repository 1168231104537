import React from "react";

// Actions
import WorkflowActions from "../../../actions/WorkflowActions";

// Scaffolding
import Card from "./QuestionCardScaffold.react";

// Form items
import Dropdown from "../forms/Dropdown.react";

const assigneeStyle = {
	width: "calc(100% - 16px)",
	maxWidth: "300px"
};

const options = [
	{
		text: "Internal",
		value: "internal"
	},
	{
		text: "External",
		value: "external"
	}
];

class EscalateCard extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { value: this.props.item.value };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
		WorkflowActions.setField(field, location, value, true);
	}

	render() {
		const rowStyle = {
			width: "100%",
			textAlign: "center",
			padding: "16px 0px",
			fontSize: this.props.agentMode ? "40px" : "inherit"
		};

		return (
			<Card
				id={this.props.item.id}
				item={this.props.item}
				picture={this.props.client !== undefined ? this.props.client.picture : ""}
				name={this.props.client !== undefined ? this.props.client.name : ""}
				first={this.props.first}
				onMoveView={this.props.onMoveView}
				targetFocus={this.props.targetFocus}
				agentMode={this.props.agentMode}
				onCardConnect={this.props.onCardConnect}
				connecting={this.props.connecting}
				valid={true}
				nextPath={this.state.nextPath}
			>
				<div style={rowStyle}>
					{this.props.agentMode ? (
						<div>Escalate ticket</div>
					) : (
						<Dropdown
							id={"escalate-dropdown-" + this.props.item.id}
							field="value"
							label="Escalation"
							value={this.state.value}
							location={[this.props.item.id]}
							items={options}
							updateFn={this._updateFn}
							style={assigneeStyle}
						/>
					)}
				</div>
			</Card>
		);
	}
}

export default EscalateCard;
