import React from "react";
import Dropzone from "react-dropzone";
import Ink from "react-ink";
import MDSpinner from "react-md-spinner";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "../_common/Card.react";
import Container from "../_common/Container.react";
import Overlay from "../_common/Overlay.react";

// Form items
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import Button from "../_common/buttons/Button.react";
import Dropdown from "../_common/forms/Dropdown.react";
import Toggle from "../_common/forms/Toggle.react";

// Headers
import H1 from "../_common/headers/H1.react";
import TicketActions from "../../actions/TicketActions";

const cardStyle = {
	width: "calc(100% - 100px)",
	padding: "24px",
	textAlign: "center",
	maxWidth: "800px"
};

const buttonStyle = {
	marginLeft: "8px"
};

const buttonRowStyle = {
	marginTop: "16px",
	textAlign: "right"
};

const textStyle = {
	textAlign: "left"
};

const reasons = [
	{
		text: "Problem",
		value: "problem"
	},
	{
		text: "Praise",
		value: "praise"
	},
	{
		text: "Feedback",
		value: "feedback"
	}
];

class FlagTicketOverlay extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._onSend = this._onSend.bind(this);

		this.state = { text: "", guru: "", reason: "" };
	}

	_updateFn(field, location, value) {
		this.setState({ [field]: value });
	}

	_onClose() {
		UIActions.showOverlay("");
	}

	_onSend() {
		if (this.state.text === "") {
			UIActions.addMessage("Please add a note", "error");
			return;
		}

		if (this.state.reason === "") {
			UIActions.addMessage("Please add a reason", "error");
			return;
		}

		if (this.state.guru === "") {
			UIActions.addMessage("Please add a guru who did the ticket", "error");
			return;
		}

		TicketActions.flagTicket({ userId: this.state.guru, text: this.state.text, reason: this.state.reason });
		UIActions.showOverlay("");
	}

	render() {
		var users = [];
		if (this.props.client !== undefined && this.props.client.agents !== undefined) {
			for (var i = 0; i < this.props.client.agents.length; i++) {
				users.push({
					text: this.props.client.agents[i].name,
					value: this.props.client.agents[i].id
				});
			}
		}

		return (
			<Overlay>
				<Container maxWidth="1100px" key="new-workflow-container" marginBottom="0px">
					<Card style={cardStyle}>
						<H1 text={"Flag Ticket"} textAlign="center" />
						<Dropdown
							id="flag-ticket-guru"
							items={users}
							field="guru"
							label={"Guru who did ticket"}
							value={this.state.guru}
							updateFn={this._updateFn}
							searchable={true}
						/>
						<Dropdown
							id="flag-ticket-reason"
							items={reasons}
							field="reason"
							label={"Reason"}
							value={this.state.reason}
							updateFn={this._updateFn}
							searchable={true}
						/>
						<InputMultiline
							id={"response-box"}
							field="text"
							value={this.state.text}
							label="Message"
							class=""
							noLine={false}
							onBlur={this._updateFn}
							style={textStyle}
							pasteCorrect={true}
						/>
						<div style={buttonRowStyle}>
							<Button text="Close" style={buttonStyle} type="flat" onClick={this._onClose} />
							<Button text="Send" style={buttonStyle} onClick={this._onSend} />
						</div>
					</Card>
				</Container>
			</Overlay>
		);
	}
}

export default FlagTicketOverlay;
