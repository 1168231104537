import React from "react";

// Actions
import TicketActions from "../../actions/TicketActions";

// Form items
import Dropdown from "../_common/forms/Dropdown.react";
import Multiselect from "../_common/forms/Multiselect.react";
import Input from "../_common/forms/Input.react";
import InputMultiline from "../_common/forms/InputMultiline.react";
import Toggle from "../_common/forms/Toggle.react";
import Datepicker from "../_common/forms/Datepicker.react";

// Functions
import * as displaylogic from "../../functions/displaylogic";

const textValidation = [
	{
		type: "min-length",
		value: 1
	}
];

const customValueStyle = {
	width: "calc(100% - 24px)",
	display: "inline-block",
	borderBottom: "1px solid lightgrey",
	padding: "0px 12px 12px",
	marginTop: "-12px"
};

function generate_dropdown_items(fields, section) {
	var options = [];

	for (var i = 0; i < fields.length; i++) {
		options.push({
			id: fields[i].value || fields[i].name,
			text: fields[i].name,
			value: fields[i].value || fields[i].name,
			layer: fields[i].layer,
			section: section.concat(fields[i].value || fields[i].name)
		});

		if (fields[i].items !== undefined)
			options[options.length - 1].items = generate_dropdown_items(fields[i].items, options[options.length - 1].section);
	}

	return options;
}

class CustomDropDown extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this.state = { value: this.props.value, items: generate_dropdown_items(this.props.options, []) };
	}

	componentDidMount() {
		if (this.state.value === "" || this.state.value === null) {
			var fields = this.state.items;

			for (var i = 0; i < fields.length; i++) {
				if (fields[i].default) {
					this.setState({ value: fields[i].value });
					this.props.updateFn({
						id: this.props.item.id,
						value: fields[i].value
					});
					break;
				}
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
		if (this.props.type === "tagger") this.props.updateFn({ id: field, value: value });
		else if (this.props.type === "tickettype") this.props.updateFnSystem("external_type", "type", value);
		else if (this.props.type === "priority") this.props.updateFnSystem("external_priority", "external_priority", value);
	}

	_onGetValue(id, data) {}

	render() {
		const primaryStyle = {
			width: this.props.half ? "calc(50% - 16px)" : "calc(100% - 16px)",
			marginRight: "16px",
			verticalAlign: "top",
			height: "",
			minHeight: "56px",
			marginBottom: "16px"
		};

		return (
			<Dropdown
				id={this.props.item.id}
				style={primaryStyle}
				items={this.state.items}
				field={this.props.item.id}
				label={this.props.item.title}
				value={this.state.value || ""}
				updateFn={this._onClick}
				helpText={this.props.item.required ? "Required" : undefined}
				error={this.props.error}
				searchable={true}
			/>
		);
	}
}

class CustomMultiselect extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this.state = { value: this.props.value || [], items: generate_dropdown_items(this.props.options, []) };
	}

	componentDidMount() {
		if (!Array.isArray(this.props.value)) this.props.updateFn({ id: this.props.item.id, value: [] });

		/*
        if (
			this.props.item.required &&
			(this.props.value === "" || this.props.value === null || this.props.value === undefined)
		) {
			this.setState({ value: false });
			TicketActions.setCustomField({
				id: this.props.item.id,
				value: []
			});
        }
        */
	}

	componentWillReceiveProps(nextProps) {
		if (!Array.isArray(this.props.value)) this.props.updateFn({ id: this.props.item.id, value: [] });
		this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
		this.props.updateFn({ id: field, value: value });
	}

	_onGetValue(id, data) {}

	render() {
		const primaryStyle = {
			width: this.props.half ? "calc(50% - 16px)" : "calc(100% - 16px)",
			marginRight: "16px",
			verticalAlign: "top",
			height: "",
			minHeight: "56px",
			marginBottom: "16px"
		};

		return (
			<Multiselect
				id={this.props.item.id}
				style={primaryStyle}
				items={this.state.items}
				field={this.props.item.id}
				label={this.props.item.title}
				value={this.state.value}
				updateFn={this._onClick}
				helpText={this.props.item.required ? "Required" : undefined}
				error={this.props.error}
				searchable={true}
			/>
		);
	}
}

class CustomInput extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { value: this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.props.value) this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
	}

	_onBlur(field, location, value) {
		if (this.props.type === "integer") value = Math.floor(value);
		else if (this.props.type === "decimal") value = Number(value);

		this.setState({ value: value });
		this.props.updateFn({ id: field, value: value });
	}

	render() {
		const primaryStyle = {
			width: "calc(100% - 16px)",
			marginRight: "16px",
			verticalAlign: "top",
			height: "",
			minHeight: "56px",
			marginBottom: "16px"
		};

		return (
			<Input
				id={this.props.item.id}
				style={primaryStyle}
				field={this.props.item.id}
				label={this.props.item.title}
				value={this.state.value || ""}
				updateFn={this._onClick}
				onBlur={this._onBlur}
				helpText={this.props.item.required ? "Required" : undefined}
				validate={this.props.error}
				validation={this.props.item.required ? textValidation : []}
			/>
		);
	}
}

class CustomInputMultiline extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { value: this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
	}

	_onBlur(field, location, value) {
		this.setState({ value: value });
		this.props.updateFn({ id: field, value: value.replace(/<br>/g, "\n") });
	}

	render() {
		const primaryStyle = {
			width: "calc(100% - 16px)",
			marginRight: "16px",
			verticalAlign: "top",
			height: "",
			minHeight: "56px",
			marginBottom: "16px"
		};

		return (
			<InputMultiline
				id={this.props.item.id}
				style={primaryStyle}
				field={this.props.item.id}
				label={this.props.item.title}
				value={
					this.state.value !== null && this.state.value !== undefined ? this.state.value.replace(/\n/g, "<br>") : ""
				}
				onBlur={this._onBlur}
				helpText={this.props.item.required ? "Required" : undefined}
				validate={this.props.error}
				validation={this.props.item.required ? textValidation : []}
			/>
		);
	}
}

class CustomCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onClickLabel = this._onClickLabel.bind(this);
		this.state = { value: this.props.value || false };
	}

	componentDidMount() {
		/*
		if (
			this.props.item.required &&
			(this.props.value === "" || this.props.value === null || this.props.value === undefined)
		) {
			this.setState({ value: false });
			TicketActions.setCustomField({
				id: this.props.item.id,
				value: false
			});
        }
        */
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
		this.props.updateFn({ id: field, value: value });
	}

	_onClickLabel() {
		this._onClick(this.props.item.id, null, this.state.value ? false : true);
	}

	render() {
		const primaryStyle = {
			width: "calc(100% - 32px)",
			marginRight: "16px",
			marginLeft: "16px",
			verticalAlign: "top",
			marginBottom: "16px",
			marginTop: "16px"
		};

		const labelStyle = {
			display: "inline-block",
			wordWrap: "word-break",
			width: "calc(100% - 46px)",
			marginLeft: "16px",
			verticalAlign: "middle",
			cursor: "pointer"
		};

		var helpText = this.props.item.required ? "Required" : undefined;

		const underStyle = {
			fontSize: "12px",
			marginTop: "8px",
			width: "100%",
			color: this.props.error ? "red" : "rgba(0, 0, 0, 0.54)",
			fontWeight: "400",
			transition: "0.2s all ease",
			opacity: !this.state.error && helpText === undefined ? "0" : "1"
		};

		var error = this.props.error ? "Please fill in this field" : "";

		return (
			<div style={primaryStyle}>
				<Toggle id={this.props.item.id} field={this.props.item.id} value={this.state.value} updateFn={this._onClick} />
				<div style={labelStyle} onClick={this._onClickLabel}>
					{this.props.item.title}
				</div>
				<div style={underStyle}>{!this.props.error && helpText ? helpText : error}</div>
			</div>
		);
	}
}

class CustomDatepicker extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
		this._onBlur = this._onBlur.bind(this);
		this.state = { value: this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: nextProps.value });
	}

	_onClick(field, location, value) {
		this.setState({ value: value });
	}

	_onBlur(field, location, value) {
		this.setState({ value: value });
		this.props.updateFn({ id: field, value: value });
	}

	render() {
		const primaryStyle = {
			width: "calc(100% - 16px)",
			marginRight: "16px",
			verticalAlign: "top",
			height: "",
			minHeight: "56px",
			marginBottom: "16px"
		};

		return (
			<Datepicker
				id={this.props.item.id}
				style={primaryStyle}
				field={this.props.item.id}
				label={this.props.item.title}
				value={this.state.value || ""}
				updateFn={this._onClick}
				onBlur={this._onBlur}
				helpText={this.props.item.required ? "Required" : undefined}
				validate={this.props.error}
				validation={this.props.item.required ? textValidation : []}
			/>
		);
	}
}
/*
function get_ticket_field_value(id, data) {
	for (var i = 0; i < data.length; i++) {
		if (data[i].value === id) return data[i].value;
	}

	return "";
}
*/

function get_value(obj) {
	if (obj.reply !== undefined) {
		var value, i;
		if (
			["tagger", "text", "textarea", "integer", "decimal", "regexp", "checkbox", "date", "multiselect"].indexOf(
				obj.item.type
			) > -1 &&
			obj.reply.custom_fields !== undefined
		) {
			for (i = 0; i < obj.reply.custom_fields.length; i++) {
				if (obj.reply.custom_fields[i].id === obj.item.id) value = obj.reply.custom_fields[i].value;
			}

			return value;
		} else if (obj.item.type === "tickettype") return obj.reply.external_type || obj.ticket.external_type;
		else if (obj.item.type === "priority") return obj.reply.external_priority || obj.ticket.external_priority;

		return null;
	} else return null;
}

class TicketField extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this._updateFnSystem = this._updateFnSystem.bind(this);
		this._updateFnCustom = this._updateFnCustom.bind(this);
		this.state = { type: this.props.item.type, value: get_value(this.props) };
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ value: get_value(nextProps), type: nextProps.item.type });
	}

	_updateFn(obj) {
		if (this.props.updateFn !== undefined) this.props.updateFn(obj);
		else TicketActions.setCustomField(obj);
	}

	_updateFnSystem(field, ticketField, value) {
		if (this.props.updateFnSystem !== undefined) this.props.updateFnSystem(field, ticketField, value);
		else TicketActions.setSystemField(field, ticketField, value);
	}

	_updateFnCustom(field, location, value) {
		this.setState({ value });
		if (
			["tagger", "text", "textarea", "integer", "decimal", "regexp", "checkbox", "date", "multiselect"].indexOf(
				this.state.type
			) > -1
		) {
			this._updateFn({ id: this.props.item.id, value });
		} else if (this.state.type === "tickettype") this._updateFnSystem("external_type", this.props.type, value);
		else if (this.state.type === "priority") this._updateFnSystem("external_priority", this.props.type, value);
	}

	render() {
		var toRender = [];

		if (
			!this.props.skipDisplayCheck &&
			!displaylogic.check(this.props.item.displaylogic, this.props.reply, this.props.ticket)
		)
			return "";

		if (this.state.type === "tagger") {
			toRender.push(
				<CustomDropDown
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					options={this.props.item.custom_field_options || []}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
					updateFnSystem={this._updateFnSystem}
				/>
			);
		} else if (this.state.type === "tickettype") {
			toRender.push(
				<CustomDropDown
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					options={this.props.item.system_field_options || []}
					half={true}
					field={""}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
					updateFnSystem={this._updateFnSystem}
				/>
			);
		} else if (this.state.type === "priority") {
			toRender.push(
				<CustomDropDown
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					options={this.props.item.system_field_options || []}
					half={true}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
					updateFnSystem={this._updateFnSystem}
				/>
			);
		} else if (["text", "integer", "decimal", "regexp"].indexOf(this.state.type) > -1) {
			toRender.push(
				<CustomInput
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
				/>
			);
		} else if (this.state.type === "textarea") {
			toRender.push(
				<CustomInputMultiline
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
				/>
			);
		} else if (this.state.type === "checkbox") {
			toRender.push(
				<CustomCheckbox
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
				/>
			);
		} else if (this.state.type === "date") {
			toRender.push(
				<CustomDatepicker
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
				/>
			);
		} else if (this.state.type === "multiselect") {
			toRender.push(
				<CustomMultiselect
					item={this.props.item}
					key={"ticket-field-" + this.props.ticket_id + "-" + this.props.item.position}
					options={this.props.item.custom_field_options || []}
					value={this.state.value}
					type={this.state.type}
					error={this.props.error}
					updateFn={this._updateFn}
				/>
			);
		} else return "";

		if (this.props.allowCustom && document.location.hash.indexOf("workflows") > -1) {
			toRender.push(
				<div style={customValueStyle} key={this.props.item.id + "-custom"}>
					<Input
						placeholder="Custom value"
						onBlur={this._updateFnCustom}
						value={this.state.value}
						thin={true}
						noLine={true}
					/>
				</div>
			);
		}

		return toRender;
	}
}

export default TicketField;
