import React from "react";

import Toggle from "./Toggle.react";

// Functions
import m from "../../../functions/m";

const primaryStyle = {
	display: "inline-block",
	textAlign: "left",
	verticalAlign: "middle",
	lineHeight: "24px"
};

const labelStyle = {
	marginLeft: "16px",
	fontSize: "16px",
	color: "inherit",
	display: "inline-block",
	verticalAlign: "middle",
	cursor: "pointer"
};

class ToggleWithLabel extends React.Component {
	constructor(props) {
		super(props);
		this._updateFn = this._updateFn.bind(this);
		this.state = { value: this.props.value === undefined ? false : this.props.value };
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value) this.setState({ value: nextProps.value });
	}

	_updateFn(field, location, value) {
		this.setState({ value: this.state.value ? false : true });
		if (this.props.updateFn !== undefined)
			this.props.updateFn(this.props.field, this.props.location, this.state.value ? false : true);
	}

	render() {
		return (
			<div style={m(primaryStyle, this)}>
				<Toggle
					id={this.props.id}
					value={this.state.value}
					location={this.props.location}
					field={this.props.field}
					updateFn={this._updateFn}
				/>
				<div style={labelStyle} onClick={this._updateFn}>
					{this.props.text}
				</div>
			</div>
		);
	}
}

export default ToggleWithLabel;
