import React from "react";

class ExternalUserResult extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);
		this._onClick = this._onClick.bind(this);
		this.state = { hover: false };
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	_onClick() {
		this.props.onClick(this.props.item);
	}

	render() {
		const primaryStyle = {
			fontSize: "16px",
			fontFamily: "Roboto",
			fontWeight: "400",
			width: "calc(100% - 32px)",
			backgroundColor: this.state.hover ? "rgba(0, 0, 0, 0.05)" : "transparent",
			transition: "all 0.3s ease",
			cursor: "pointer",
			padding: "16px"
		};

		const imageTextStyle = {
			fontSize: "20px",
			height: "20px",
			width: "40px",
			textAlign: "center",
			padding: "10px 0px",
			backgroundColor: "rgba(0, 0, 0, 0.62)",
			color: "white",
			cursor: "pointer",
			borderRadius: "50%",
			verticalAlign: "middle",
			display: "inline-block",
			lineHeight: "20px",
			marginRight: "16px"
		};

		const nameStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "16px",
			color: "rgba(0, 0, 0, 0.87)",
			fontWeight: "500",
			cursor: "pointer",
			width: "300px",
			textAlign: "left"
		};

		const roleStyle = {
			display: "inline-block",
			verticalAlign: "middle",
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.67)",
			fontWeight: "400",
			width: "300px",
			textAlign: "center"
		};

		const name = this.props.item.name;

		return (
			<li style={primaryStyle} onMouseOver={this._onMouseOver} onMouseOut={this._onMouseOut} onClick={this._onClick}>
				<div style={imageTextStyle}>{name.toUpperCase().substr(0, 1)}</div>
				<div style={nameStyle}>{name}</div>
				<div style={roleStyle}>{this.props.item.email}</div>
			</li>
		);
	}
}

export default ExternalUserResult;
