import React from "react";

// Actions
import AgentActions from "../../actions/AgentActions";

// Scaffolding
import Container from "../_common/Container.react";
import OuterScaffold from "../_common/OuterScaffold.react";
import SectionCard from "../_common/SectionCard.react";
import Button from "../_common/buttons/Button.react";
import CardTag from "../_common/CardTag.react";

import AgentSessionRow from "./AgentSessionRow.react";

import * as firestore from "../../functions/firestore";

const headerStyle = {
	display: "inline-block",
	borderBottom: "1px solid cadetblue",
	width: "100%",
	fontWeight: "400",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 1)",
	padding: "8px 0px"
};

const cellMediumStyle = {
	width: "200px",
	display: "inline-block",
	verticalAlign: "middle",
	padding: "0px 8px",
	position: "relative"
};

const buttonRow = {
	width: "calc(100% + 48px)",
	padding: "16px 0px",
	textAlign: "center",
	display: "inline-block",
	marginLeft: "-48px"
};

const buttonStyle = {
	margin: "0px 8px"
};

const filterStyle = {
	cursor: "pointer",
	fontSize: "16px",
	display: "inline-block",
	marginLeft: "8px",
	verticalAlign: "middle"
};

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		AgentActions.loadSubcollection(this.props.view.replace("gurus_", ""), undefined, this.props.type);
	}

	render() {
		return (
			<i className="material-icons" style={filterStyle} onClick={this._onClick}>
				filter_list
			</i>
		);
	}
}

class AgentSessions extends React.Component {
	constructor(props) {
		super(props);
		this._onNextPage = this._onNextPage.bind(this);
		this._onPreviousPage = this._onPreviousPage.bind(this);
		this._onFilter = this._onFilter.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	_onNextPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			query.startAfter = this.props.cursor.pointers[this.props.cursor.page];
			AgentActions.loadSubcollection(this.props.cursor.type, query, 0, 1);
			window.scrollTo(0, 0);
		}
	}

	_onPreviousPage() {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			if (this.props.cursor.page === 1) delete query.startAfter;
			else query.startAfter = this.props.cursor.pointers[this.props.cursor.page - 2];
			AgentActions.loadSubcollection(this.props.cursor.type, query, 0, -1);
			window.scrollTo(0, 0);
		}
	}

	_onFilter(view, type) {
		var query = firestore.clone_query(this.props.cursor.query);
		if (query !== undefined && this.props.cursor.type !== undefined) {
			delete query.startAfter;
			if (query.orderBy[0][0] === type) query.orderBy[0][1] = query.orderBy[0][1] === "desc" ? "asc" : "desc";
			else query.orderBy[0] = [type, "asc"];

			AgentActions.loadSubcollection(this.props.cursor.type, query, 0);
			window.scrollTo(0, 0);
		}
	}

	render() {
		const integrationsStyle = {
			width: "100%",
			textAlign: "left",
			margin: "24px 0px",
			display: "inline-block"
		};

		const emptyStyle = {
			textAlign: "center",
			display: "inline-block",
			verticalAlign: "middle",
			margin: "24px 0px",
			width: "calc(100% - 48px - 32px)",
			padding: "24px",
			border: "1px dashed lightgrey",
			borderRadius: "4px"
		};

		const emptyTextStyle = {
			fontSize: "24px",
			fontWeight: "400",
			marginBottom: "20px"
		};

		return (
			<OuterScaffold spaceForHeader={false} verticalAlign={false}>
				<Container maxWidth="1000px" menuSpace={false} verticalAlign="top" marginTop="-150px">
					<SectionCard
						title={this.props.title || "SESSIONS"}
						subtext={"Showing " + this.props.sessions.length + " sessions."}
						maxWidth="calc(100% - 48px)"
						width="calc(100% - 48px)"
					>
						{this.props.sessions === undefined || this.props.sessions.length === 0 ? (
							<div key="empty-workflows" style={emptyStyle}>
								<div style={emptyTextStyle}>There are currently no sessions</div>
							</div>
						) : (
							<div style={integrationsStyle}>
								<div style={headerStyle}>
									<div style={cellMediumStyle}>
										Start <Filter type="start" view={this.props.cursor.type} onClick={this._onFilter} />
									</div>
									<div style={cellMediumStyle}>
										Finish <Filter type="end" view={this.props.cursor.type} onClick={this._onFilter} />
									</div>
									<div style={cellMediumStyle}>Duration</div>
								</div>

								{this.props.sessions.map((item, index, arr) => (
									<AgentSessionRow key={item.id + "-" + index} index={index} item={item} />
								))}
								<div style={buttonRow}>
									{this.props.page > 0 ? (
										<Button text={"Previous Page"} onClick={this._onPreviousPage} style={buttonStyle} />
									) : (
										""
									)}
									{this.props.sessions.length === 25 ? (
										<Button text={"Next Page"} onClick={this._onNextPage} style={buttonStyle} />
									) : (
										""
									)}
								</div>
								<CardTag text={"Page " + ((this.props.cursor.page || 0) + 1)} color="orange" />
							</div>
						)}
					</SectionCard>
				</Container>
			</OuterScaffold>
		);
	}
}

export default AgentSessions;
