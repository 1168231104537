import React from "react";

import Ink from "react-ink";
import MDSpinner from "react-md-spinner";

// Functions
import m from "../../../functions/m";

class FAB extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = { hover: false };
	}

	componentDidMount() {
		this.setState({ width: this.button.clientWidth - 32 + "px" });
	}

	_onMouseOver() {
		this.setState({ hover: true });
	}

	_onMouseOut() {
		this.setState({ hover: false });
	}

	render() {
		const loading = this.props.loading || false;

		const primaryStyle = {
			position: "fixed",
			right: "50px",
			bottom: "50px",
			height: "56px",
			width: "56px",
			borderRadius: "50%",
			fontSize: "14px",
			cursor: "pointer",
			backgroundColor: this.state.hover || loading ? "transparent" : "rgba(0, 0, 0, 0.62)",
			color: this.state.hover || loading ? "rgba(0, 0, 0, 0.87)" : "white",
			border: this.state.hover || loading ? "1px solid black" : "1px solid transparent",
			transition: ".2s all ease",
			boxShadow: "0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28)",
			textAlign: "center"
		};

		const iconStyle = {
			fontSize: "30px",
			transform: "rotate(45deg)",
			margin: "13px 0px"
		};

		return (
			<div
				ref={input => {
					this.button = input;
				}}
				style={m(primaryStyle, this)}
				onClick={this.props.onClick}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
			>
				{loading ? (
					<MDSpinner size={14} singleColor="rgb(70, 180, 175)" />
				) : (
					<i style={iconStyle} className="material-icons">
						close
					</i>
				)}
				<Ink />
			</div>
		);
	}
}

export default FAB;
