import React from "react";

// Actions
import UIActions from "../../actions/UIActions";

// Scaffolding
import Card from "./Card.react";
import Viewer from "./Viewer.react";

// Headers
import H1 from "./headers/H1.react";

// Functions
import * as time from "../../functions/time";
import ClientActions from "../../actions/ClientActions";
import AgentActions from "../../actions/AgentActions";

const primaryStyle = {
	backgroundColor: "rgba(0, 0, 0, 0.7)",
	width: "calc(100% - 332px)",
	height: "100vh",
	display: "inline-block",
	textAlign: "center",
	position: "fixed",
	top: "0px",
	left: "0px",
	zIndex: "4",
	overflowY: "auto"
};

const cardStyle = {
	position: "fixed",
	width: "400px",
	top: "0px",
	right: "0px",
	height: "100vh",
	padding: "0px 16px 16px",
	textAlign: "center",
	overflow: "auto"
};

const closeIconStyle = {
	cursor: "pointer",
	position: "absolute",
	left: "16px",
	top: "16px"
};

const textRowStyle = {
	display: "inline-block",
	width: "calc(100% - 82px)",
	marginLeft: "8px",
	verticalAlign: "middle"
};

const titleStyle = {
	display: "inline-block",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	color: "rgba(0, 0, 0, 0.87)",
	maxWidth: "100%"
};

const timeLabelStyle = {
	display: "inline-block",
	marginTop: "4px",
	fontSize: "14px",
	color: "rgba(0, 0, 0, 0.64)",
	width: "100%"
};

var get_client = (id, clients) => {
	for (var i = 0; i < clients.length; i++) {
		if (clients[i].id === id) {
			return clients[i];
		}
	}

	return {};
};

var get_agent = (id, agents) => {
	for (var i = 0; i < agents.length; i++) {
		if (agents[i].id === id) {
			return agents[i];
		}
	}

	return {};
};

class EventRow extends React.Component {
	constructor(props) {
		super(props);
		this._onMouseOver = this._onMouseOver.bind(this);
		this._onMouseOut = this._onMouseOut.bind(this);

		this.state = {
			hover: false,
			client: get_client(this.props.item.clientId, this.props.clients),
			agent: get_client(this.props.item.userId, this.props.agents)
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.clients.length !== this.props.clients.length || nextProps.agents.length !== this.props.agents.length)
			this.setState({
				client: get_client(nextProps.item.clientId, nextProps.clients),
				agent: get_agent(nextProps.item.userId, nextProps.agents || [])
			});
	}

	_onMouseOver() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(true);
		this.setState({ hover: true });
	}

	_onMouseOut() {
		if (this.props.hoverChange !== undefined) this.props.hoverChange(false);
		this.setState({ hover: false });
	}

	render() {
		var eventStyle = {
			margin: "8px 0px 0px",
			width: "calc(100% - 32px)",
			display: "inline-block",
			padding: "16px",
			border: "1px solid lightgrey",
			borderRadius: "4px",
			cursor: "pointer",
			backgroundColor: this.state.hover ? "rgb(220, 220, 220)" : "transparent",
			transition: "background-color .3s ease",
			textAlign: "left"
		};

		var statusColor = "#F5CA00";
		if (this.props.item.status === "pending") statusColor = "#59BBE0";
		else if (["assigned", "open"].indexOf(this.props.item.status) > -1) statusColor = "#E82A2A";
		else if (["archived", "solved"].indexOf(this.props.item.status) > -1) statusColor = "#828282";
		else if (["closed", "deleted"].indexOf(this.props.item.status) > -1) statusColor = "black";
		else if (this.props.item.status === "hold") statusColor = "black";

		var statusStyle = {
			color: statusColor
		};

		return (
			<a
				href={"#clients/" + this.props.item.clientId + "/tickets/" + this.props.item.ticketId}
				style={eventStyle}
				onMouseOver={this._onMouseOver}
				onMouseOut={this._onMouseOut}
				target="_blank"
			>
				<Viewer item={this.state.client} />
				<div style={textRowStyle}>
					<div style={titleStyle}>
						{this.props.item.action.substr(0, 1).toUpperCase() + this.props.item.action.substr(1, 1000)} as{" "}
						<span style={statusStyle}>{this.props.item.status}</span>
					</div>
					<div style={timeLabelStyle}>
						{time.parse_time(this.props.item.start)} / <b>{time.parse_diff(this.props.item.duration / 1000)}</b>
					</div>
				</div>
				<Viewer item={this.state.agent} />
			</a>
		);
	}
}

var parse_clients = clients => {
	var simpleClients = [];
	for (var i = 0; i < clients.length; i++) {
		simpleClients.push({
			id: clients[i].id,
			name: clients[i].name,
			picture: clients[i].picture
		});
	}

	return simpleClients;
};

var parse_agents = agents => {
	var simpleAgents = [];
	for (var i = 0; i < agents.length; i++) {
		simpleAgents.push({
			id: agents[i].id,
			name: agents[i].name,
			picture: agents[i].picture
		});
	}

	return simpleAgents;
};

class EventsFeedOverlay extends React.Component {
	constructor(props) {
		super(props);
		this.state = { clients: parse_clients(this.props.clients || []), agents: parse_agents(this.props.agents || []) };
		if (this.props.clients.length === 0) ClientActions.loadSummary();
		if (this.props.agents.length === 0) AgentActions.loadSummary();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.clients.length !== this.state.clients.length || nextProps.agents.length !== this.state.agents.length)
			this.setState({ clients: parse_clients(nextProps.clients), agents: parse_agents(nextProps.agents || []) });
	}

	componentDidMount() {
		document.documentElement.style.overflow = "hidden";
	}

	_onClose() {
		UIActions.showOverlay("", {});
		document.documentElement.style.overflow = "auto";
	}

	render() {
		return (
			<div style={primaryStyle} onClick={this._onClose}>
				<Card style={cardStyle}>
					<i onClick={this._onClose} style={closeIconStyle} className="material-icons">
						close
					</i>
					<H1 text={"Events"} textAlign="center" />
					{this.props.events.map((item, index) => (
						<EventRow
							key={"event-feed-" + item.id}
							item={item}
							index={index}
							clients={this.state.clients}
							agents={this.state.agents}
						/>
					))}
				</Card>
			</div>
		);
	}
}

export default EventsFeedOverlay;
