import React from "react";

const filterStyle = {
	cursor: "pointer",
	fontSize: "16px",
	display: "inline-block",
	marginLeft: "8px",
	verticalAlign: "middle"
};

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this._onClick = this._onClick.bind(this);
	}

	_onClick() {
		if (this.props.onClick !== undefined) this.props.onClick(this.props.view, this.props.type);
		else if (this.props.actions !== undefined)
			this.props.actions.loadSubcollection(this.props.view, undefined, this.props.type);
	}

	render() {
		return (
			<i className="material-icons" style={filterStyle} onClick={this._onClick}>
				filter_list
			</i>
		);
	}
}

export default Filter;
