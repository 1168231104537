export default {
    name: "Gorgias",
    type: "gorgias",
    logo: "/images/logos/gorgias.png",
    subsections: [
        {
            name: "API Connector",
            type: "api connector",
            icon: "compare_arrows",
            activeRules: [
                {
                    target: "integration",
                    field: "valid",
                    value: true
                }
            ],
            items: [
                {
                    field: "email",
                    label: "Gorgias User Account",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 6
                        },
                        {
                            type: "contains",
                            value: "@"
                        },
                        {
                            type: "contains",
                            value: "."
                        }
                    ]
                },
                {
                    field: "url",
                    label: "Gorgias URL",
                    type: "text",
                    validation: [
                        {
                            type: "contains",
                            value: "gorgias.io"
                        },
                        {
                            type: "contains",
                            value: "https://"
                        }
                    ]
                },
                {
                    field: "supportEmail",
                    label: "Gorgias Support Email",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 6
                        },
                        {
                            type: "contains",
                            value: "@"
                        },
                        {
                            type: "contains",
                            value: "."
                        }
                    ]
                },
                {
                    field: "apiKey",
                    label: "Gorgias API key",
                    type: "text",
                    validation: [
                        {
                            type: "min-length",
                            value: 10
                        }
                    ]
                },
                {
                    field: "viewId",
                    label: "Gorgias View ID",
                    type: "text"
                },
                {
                    type: "webhook url"
                }
            ]
        },
        {
            name: "Rules",
            type: "rules",
            icon: "call_split",
            activeRules: [
                {
                    target: "integration",
                    field: "rules",
                    type: "arrayCheck"
                },
                {
                    target: "integration",
                    field: "rules",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Users for reassigning",
            type: "users",
            icon: "account_box",
            activeRules: [
                {
                    target: "component",
                    field: "clientAgents",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "clientAgents",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Tags",
            type: "tags",
            icon: "label",
            activeRules: []
        },
        {
            name: "Ticket signature",
            type: "signature",
            icon: "create",
            activeRules: [
                {
                    target: "component",
                    field: "signature",
                    type: "exists"
                }
            ]
        },
        {
            name: "Blacklisted words/expressions",
            type: "blacklist",
            icon: "block",
            activeRules: [
                {
                    target: "component",
                    field: "blacklist",
                    type: "arrayCheck"
                },
                {
                    target: "component",
                    field: "blacklist",
                    type: "length-greater-than",
                    value: 0
                }
            ]
        },
        {
            name: "Ticket fields",
            type: "ticket fields",
            icon: "radio_button_checked"
        }
    ],
    refreshableFields: [
        {
            text: "Agents",
            value: "users"
        },
        {
            text: "Tags",
            value: "tags"
        },
        {
            text: "Macros",
            value: "macros"
        },
        {
            text: "Check filters",
            value: "checkfilters"
        }
    ],
    submitOptions: ["open", "solved"],
    ticketOptions: {
        merge: false,
        editTo: false,
        editCC: false,
        editBCC: false
    }
};
